// LeadUploadForm.tsx
import React, { useState } from 'react';
import { TextField, TextareaAutosize, Button } from '@mui/material';

const LeadUploadForm = ({ headers, onSubmit }) => {
  const [formData, setFormData] = useState({});

  const handleChange = (fieldId, value) => {
    setFormData({ ...formData, [fieldId]: value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    onSubmit(formData);
  };

  return (
    <form onSubmit={handleSubmit}>
      {headers && headers.map((header) => {
        let inputComponent;

        // Customize input based on header type
        switch (header.type) {
          case 'email':
          case 'phone':
            inputComponent = (
              <TextField
                key={header.fieldId}
                label={header.name}
                type={header.type === 'email' ? 'email' : 'tel'}
                variant="outlined"
                fullWidth
                margin="normal"
                onChange={(e) => handleChange(header.fieldId, e.target.value)}
              />
            );
            break;
          case 'paragraph':
            inputComponent = (
              <TextareaAutosize
                key={header.fieldId}
                aria-label={header.name}
                minRows={3}
                placeholder={header.name}
                style={{ width: '100%' }}
                onChange={(e) => handleChange(header.fieldId, e.target.value)}
              />
            );
            break;
          // Add more cases as needed for different types
          default:
            inputComponent = (
              <TextField
                key={header.fieldId}
                label={header.name}
                variant="outlined"
                fullWidth
                margin="normal"
                onChange={(e) => handleChange(header.fieldId, e.target.value)}
              />
            );
        }

        return inputComponent;
      })}
      <Button type="submit" variant="contained" color="primary">
        Submit
      </Button>
    </form>
  );
};

export default LeadUploadForm;

