import {
    Box,
    Button,
    Card,
    CardContent,
    Divider,
    Grid,
    List,
    ListItemButton,
    ListItemText,
    TextField,
    Typography,
} from '@mui/material';
import axios from 'axios';
import React, { useEffect } from 'react';

interface SavedSearch {
    _id: string;
    searchName: string;
}

interface SaveSearchProps {
    selectedIndustry: string[];
    setSelectedIndustry: (value: string[]) => void;
    excludedJobTitles: string[];
    setExcludedJobTitles: (value: string[]) => void;
    selectedJobTitle: string[];
    setSelectedJobTitle: (value: string[]) => void;
    selectedCountries: { code: string; name: string; }[];
    setSelectedCountries: (value: { code: string; name: string; }[]) => void;
    selectedContinent: string[];
    setSelectedContinent: (value: string[]) => void;
    selectedRegion: string[];
    setSelectedRegion: (value: string[]) => void;
    excludedRegion: string[];
    setExcludedRegion: (value: string[]) => void;
    selectedGender: string;
    setSelectedGender: (value: string) => void;
    hobbies: string[];
    setHobbies: (value: string[]) => void;
    skills: string[];
    setSkills: (value: string[]) => void;
    foundedBefore: string;
    setFoundedBefore: (value: string) => void;
    foundedAfter: string;
    setFoundedAfter: (value: string) => void;
    actualPage: number;
    setActualPage: (value: number) => void;
    userVisiblePage: number;
    setUserVisiblePage: (value: number) => void;
    selectedLeads: string[];
    setSelectedLeads: (value: string[]) => void;
    activeSearchId: string;
    activeSearchName: string;
    onActiveSearchIdChange: (id: string) => void;
    onActiveSearchNameChange: (name: string) => void;
}

const SaveSearchComponent: React.FC<SaveSearchProps> = ({
    selectedIndustry,
    setSelectedIndustry,
    excludedJobTitles,
    setExcludedJobTitles,
    selectedJobTitle,
    setSelectedJobTitle,
    selectedCountries,
    setSelectedCountries,
    selectedContinent,
    setSelectedContinent,
    selectedRegion,
    setSelectedRegion,
    excludedRegion,
    setExcludedRegion,
    selectedGender,
    setSelectedGender,
    hobbies,
    setHobbies,
    skills,
    setSkills,
    foundedBefore,
    setFoundedBefore,
    foundedAfter,
    setFoundedAfter,
    actualPage,
    setActualPage,
    userVisiblePage,
    setUserVisiblePage,
    selectedLeads,
    setSelectedLeads,
    activeSearchId,
    activeSearchName,
    onActiveSearchIdChange,
    onActiveSearchNameChange
}) => {

    const [searchName, setSearchName] = React.useState('');
    const [savedSearches, setSavedSearches] = React.useState<SavedSearch[]>([]);
    const [selectedSearchId, setSelectedSearchId] = React.useState("");


    const handleLoadSearch = async () => {


        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/advancedleadgen/search/search/${selectedSearchId}`);
            const searchParams = response.data.searchParams;

            // Update states with the fetched search parameters
            setSelectedIndustry(searchParams.selectedIndustry);
            setExcludedJobTitles(searchParams.excludedJobTitles);
            setSelectedJobTitle(searchParams.selectedJobTitle);
            setSelectedCountries(searchParams.selectedCountries);
            setSelectedContinent(searchParams.selectedContinent);
            setSelectedRegion(searchParams.selectedRegion);
            setExcludedRegion(searchParams.excludedRegion);
            setSelectedGender(searchParams.selectedGender);
            setHobbies(searchParams.hobbies);
            setSkills(searchParams.skills);
            setFoundedBefore(searchParams.foundedBefore);
            setFoundedAfter(searchParams.foundedAfter);
            setActualPage(response.data.actualPage);
            setUserVisiblePage(response.data.userVisiblePage);
            setSelectedLeads(response.data.selectedLeads);
            onActiveSearchNameChange(response.data.searchName);
            onActiveSearchIdChange(response.data._id);

        } catch (error) {
            console.error('Error loading search:', error);
        }
    };


    const handleSaveSearch = async () => {
        if (!searchName.trim()) {
            alert("Please enter a name for the search.");
            return;
        }
        const saveData = {
            searchName: searchName,
            searchParams: {
                selectedIndustry,
                excludedJobTitles,
                selectedJobTitle,
                selectedCountries,
                selectedContinent,
                selectedRegion,
                excludedRegion,
                selectedGender,
                hobbies,
                skills,
                foundedBefore,
                foundedAfter
            },
            actualPage,
            userVisiblePage,
            selectedLeads
        };

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/advancedleadgen/save-search`, saveData);
            console.log('Search Saved:', response.data);
            // Additional actions on successful save

            // Refresh the saved searches list
            fetchSavedSearches();
        } catch (error) {
            console.error('Error saving search:', error);
            // Error handling
        }
    };

    const fetchSavedSearches = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/advancedleadgen/saved-searches`);
            setSavedSearches(response.data);
        } catch (error) {
            console.error('Error fetching saved searches:', error);
        }
    };

    useEffect(() => {


        fetchSavedSearches();
    }, []);


    // updating: 


    // Call the passed callback when activeSearchId changes
    useEffect(() => {
        onActiveSearchIdChange(activeSearchId);
    }, [activeSearchId, onActiveSearchIdChange]);




    // Example usage of a setState function
    // setSelectedRowIds([...selectedRowIds, 'newId']); // Modify as needed for your logic
    return (
        <Box sx={{ flexGrow: 1, p: 3 }}>
            <Grid container spacing={5}>
                <Grid item xs={12} md={6} >

                    <Card>
                        <CardContent>
                            <Typography variant="h6" gutterBottom>
                                Saved Searches
                            </Typography>
                            <List component="nav" aria-label="saved searches">
                                {savedSearches.map((search, index) => (
                                    <React.Fragment key={search._id}>
                                        {index > 0 && <Divider />}
                                        <ListItemButton
                                            selected={selectedSearchId === search._id}
                                            onClick={() => setSelectedSearchId(search._id)}
                                        >
                                            <ListItemText primary={search.searchName} />
                                        </ListItemButton>
                                    </React.Fragment>
                                ))}
                            </List>
                            <Button
                                variant='text'
                                color="secondary"
                                sx={{ mt: 2 }}
                                onClick={handleLoadSearch}
                                disabled={!selectedSearchId}
                            >
                                Load Search
                            </Button>
                        </CardContent>
                    </Card>
                </Grid>


                <Grid item xs={12} md={6}>
                    <Card>
                        <CardContent>
                            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                                <Typography variant="subtitle1" gutterBottom>
                                    Enter Search Name
                                </Typography>
                                <TextField
                                    label="Search Name"
                                    variant="outlined"
                                    value={searchName}
                                    onChange={(e) => setSearchName(e.target.value)}
                                    fullWidth
                                />
                                <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                                    <Button
                                        variant='text'
                                        color="primary"
                                        onClick={handleSaveSearch}>
                                        Save As
                                    </Button>
                                </Box>
                            </Box>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>

        </Box>
    );
}
export default SaveSearchComponent;
