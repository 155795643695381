import React from 'react';
import { Button, Typography, Container, Box } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { Link } from 'react-router-dom';

const GoogleSuccessPage = () => {
    return (
        <Container component="main" maxWidth="xs" sx={{ mt: 8 }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <CheckCircleOutlineIcon sx={{ m: 1, fontSize: 60, color: 'success.main' }} />
                <Typography component="h1" variant="h5" sx={{ mb: 2 }}>
                    Google Workspace Account Connected
                </Typography>
                <Typography variant="body1" sx={{ textAlign: 'center', mb: 3 }}>
                    Your Google Workspace account has been successfully connected. You can now enjoy all the features.
                </Typography>
                <Link to="/dashboard" style={{ textDecoration: 'none' }}>
                    <Button type="button" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
                        Go to Dashboard
                    </Button>
                </Link>
            </Box>
        </Container>
    );
};

export default GoogleSuccessPage;
