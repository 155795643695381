import DoDisturbIcon from '@mui/icons-material/DoDisturb';
import HistoryToggleOffSharpIcon from '@mui/icons-material/HistoryToggleOffSharp';
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import LinkOffIcon from '@mui/icons-material/LinkOff';
import MarkEmailUnreadSharpIcon from '@mui/icons-material/MarkEmailUnreadSharp';
import MarkunreadSharpIcon from '@mui/icons-material/MarkunreadSharp';
import WatchSharpIcon from '@mui/icons-material/WatchSharp';
import {
    Box,
    Button,
    Checkbox,
    Chip,
    Container,
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Paper,
    Radio,
    RadioGroup,
    Select,
    SelectChangeEvent,
    Skeleton,
    Slider,
    SliderThumb,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { FaReplyd, FaSignature } from 'react-icons/fa';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { animated, useSpring } from 'react-spring';

export interface IEmailPrioritization {
    prioritizeTypes: EmailType[];
    avoidTypes: EmailType[];
    prioritizeValidity: EmailValidity[];
    avoidValidity: EmailValidity[];
}

export interface IPhonePrioritization {
    prioritizeTypes: PhoneType[];
    avoidTypes: PhoneType[];
    prioritizeValidity: PhoneValidity[];
    avoidValidity: PhoneValidity[];
}

export enum EmailType {
    Any = "any",
    Personal = "personal",
    Professional = "professional"
}

export enum EmailValidity {
    Any = "any",
    Valid = "valid",
    Catchall = "catchall",
    Unknown = "unknown",
    Invalid = "invalid"
}

export enum PhoneType {
    Any = "any",
    Mobile = "mobile",
    Landline = "landline"
}

export enum PhoneValidity {
    Any = "any",
    Valid = "valid",
    Unknown = "unknown",
    Invalid = "invalid"
}


interface IContactHandlingStrategy {
    email: {
        ifEmailMissing: "skip" | "process";
        prioritization: IEmailPrioritization;
    };
    sms: {
        ifPhoneMissing: "skip" | "process";
        prioritization: IPhonePrioritization;
    };
}

interface CampaignSettings {
    stopOnReply: boolean;
    openTracking: boolean;
    linkTracking: boolean;
    sendingInterval: {
        min: number;
        max: number;
    };
    includeSignature: boolean; // New setting
    strongReplyDetection: boolean; // New setting
    timezoneAware: boolean; // New setting
    contactHandlingStrategy: IContactHandlingStrategy;
}


interface EmailCampaignSettingsProps {
    selectedCampaignId: string | null;
}

interface UpdateSendingAccountData {
    campaignId: string;
    emailAccountId: string;
}


const EmailCampaignSettings: React.FC<EmailCampaignSettingsProps> = ({ selectedCampaignId }) => {
    const queryClient = useQueryClient();
    const theme = useTheme();

    const [stopOnReply, setStopOnReply] = useState<boolean>(false);
    const [openTracking, setOpenTracking] = useState<boolean>(false);
    const [linkTracking, setLinkTracking] = useState<boolean>(false);
    const [sendingInterval, setSendingInterval] = useState<[number, number]>([5, 10]);

    const [allSendingAccounts, setAllSendingAccounts] = useState<{ id: string; sendingAccount: string }[]>([]);
    const [selectedSendingAccounts, setSelectedSendingAccounts] = useState<{ accountId: string; sendingAccount: string }[]>([]);

    const [allPhoneNumbers, setAllPhoneNumbers] = useState<{ id: string; phoneNumber: string; active: boolean }[]>([]);
    const [selectedPhoneNumbers, setSelectedPhoneNumbers] = useState<string[]>([]);
    const [fetchSuccess, setFetchSuccess] = useState({
        phoneNumbers: false,
        sendingAccounts: false,
        campaignData: false,
    });

    const [refreshCounter, setRefreshCounter] = useState(0);

    const [includeSignature, setIncludeSignature] = useState<boolean>(false);
    const [strongReplyDetection, setStrongReplyDetection] = useState<boolean>(false);
    const [timezoneAware, setTimezoneAware] = useState<boolean>(false);

    const [showAdvanced, setShowAdvanced] = useState<boolean>(false);

    const [emailHandlingStrategy, setEmailHandlingStrategy] = useState<'skip' | 'process'>('process');
    const [smsHandlingStrategy, setSmsHandlingStrategy] = useState<'skip' | 'process'>('process');

    const [emailPrioritizeTypes, setEmailPrioritizeTypes] = useState<EmailType[]>([EmailType.Any]);
    const [emailAvoidTypes, setEmailAvoidTypes] = useState<EmailType[]>([]);
    const [emailPrioritizeValidity, setEmailPrioritizeValidity] = useState<EmailValidity[]>([EmailValidity.Any]);
    const [emailAvoidValidity, setEmailAvoidValidity] = useState<EmailValidity[]>([]);

    const [smsPrioritizeTypes, setSmsPrioritizeTypes] = useState<PhoneType[]>([PhoneType.Any]);
    const [smsAvoidTypes, setSmsAvoidTypes] = useState<PhoneType[]>([]);
    const [smsPrioritizeValidity, setSmsPrioritizeValidity] = useState<PhoneValidity[]>([PhoneValidity.Any]);
    const [smsAvoidValidity, setSmsAvoidValidity] = useState<PhoneValidity[]>([]);



    const updateCampaignSettingsMutation = useMutation(
        async (campaignSettings: CampaignSettings) => {
            return axios.put(`${process.env.REACT_APP_API_URL}/campaign/update/${selectedCampaignId}`, campaignSettings);
        }, {
        onSuccess: () => {
            // Invalidate and refetch data as needed
            queryClient.invalidateQueries(['campaignData', selectedCampaignId]);

        },
        onError: (error) => {
            console.error('Error updating campaign settings', error);
        }
    }
    );





    const handlePhoneNumberChange = (event: SelectChangeEvent<typeof allPhoneNumbers[number]['phoneNumber'][]>) => {
        const { value } = event.target;
        const newSelectedPhoneNumbers = typeof value === 'string' ? [value] : value;

        console.log("Phone number change event value:", value);
        console.log("New selected phone numbers:", newSelectedPhoneNumbers);

        const changedPhoneNumber = newSelectedPhoneNumbers.find(phoneNumber => !selectedPhoneNumbers.includes(phoneNumber))
            || selectedPhoneNumbers.find(phoneNumber => !newSelectedPhoneNumbers.includes(phoneNumber));

        console.log("Changed phone number:", changedPhoneNumber);

        if (changedPhoneNumber && selectedCampaignId) {
            assignPhoneNumberMutation.mutate({ campaignId: selectedCampaignId, phoneNumber: changedPhoneNumber });
        }

        setSelectedPhoneNumbers(newSelectedPhoneNumbers);
    };


    // fetch phone numbers 
    // Assuming selectedCampaignId is the ID of the campaign for which you want to fetch unique phone numbers
    useEffect(() => {
        // Fetch phone numbers associated with the selected campaign
        const fetchPhoneNumbers = async () => {
            if (!selectedCampaignId) return;
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/phone/campaign/${selectedCampaignId}/uniquePhoneNumbers`);
                const uniquePhoneNumbers = response.data.uniquePhoneNumbers;

                // Log fetched phone numbers
                console.log("Fetched phone numbers:", uniquePhoneNumbers);

                if (uniquePhoneNumbers.length === 0) {
                    // If no phone numbers, still mark fetch success as true
                    setFetchSuccess(prevState => ({ ...prevState, phoneNumbers: true }));
                    setAllPhoneNumbers([]); // Ensure allPhoneNumbers is set to an empty array
                } else {
                    // Set the state with the fetched phone numbers
                    setAllPhoneNumbers(uniquePhoneNumbers.map(phoneNumber => ({
                        id: phoneNumber, // Assuming the phoneNumber itself can be a unique ID
                        phoneNumber: phoneNumber,
                        active: true // Assuming all fetched numbers are active
                    })));

                    // Update the fetch success state
                    setFetchSuccess(prevState => ({ ...prevState, phoneNumbers: true }));
                }
            } catch (error) {
                console.error("Failed to fetch phone numbers", error);
                setFetchSuccess(prevState => ({ ...prevState, phoneNumbers: false }));
            }
        };

        // Fetch sending accounts
        const fetchSendingAccounts = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/account/emailaccounts/sending-account-list`);
                const sendingAccounts = response.data.sendingAccounts;

                if (sendingAccounts.length === 0) {
                    // If no sending accounts, still mark fetch success as true
                    setFetchSuccess(prevState => ({ ...prevState, sendingAccounts: true }));
                    setAllSendingAccounts([]); // Ensure allSendingAccounts is set to an empty array
                } else {
                    setAllSendingAccounts(sendingAccounts.map(account => ({
                        id: account.id, // Adjust according to actual ID field in your data
                        sendingAccount: account.sendingAccount, // Adjust based on actual field names
                    })));

                    // Update the fetch success state
                    setFetchSuccess(prevState => ({ ...prevState, sendingAccounts: true }));
                }
            } catch (error) {
                console.error("Failed to fetch sending accounts", error);
                setFetchSuccess(prevState => ({ ...prevState, sendingAccounts: false }));
            }
        };

        // Execute fetch operations
        fetchPhoneNumbers();
        fetchSendingAccounts();
    }, [selectedCampaignId, refreshCounter]); // Re-fetch when `selectedCampaignId` changes

    useEffect(() => {
        console.log("Fetched phone numbers:", allPhoneNumbers);
        console.log("Selected phone numbers:", selectedPhoneNumbers);
    }, [allPhoneNumbers, selectedPhoneNumbers]);


    useEffect(() => {
        console.log("allPhoneNumbers state updated:", allPhoneNumbers);
    }, [allPhoneNumbers]);

    // Fetch campaign data
    const { data: campaignData, isLoading: isLoadingCampaign } = useQuery(['campaignData', selectedCampaignId], async () => {
        if (!selectedCampaignId) return;
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/campaign/${selectedCampaignId}`);
            // Update fetch success state for campaign data
            setFetchSuccess(prev => ({ ...prev, campaignData: true }));
            return response.data;
        } catch (error) {
            console.error("Failed to fetch campaign data", error);
            setFetchSuccess(prev => ({ ...prev, campaignData: false }));
            throw error;
        }
    }, {
        enabled: !!selectedCampaignId
    });


    useEffect(() => {
        console.log("useEffect for updating campaign settings called");
        console.log("Current state:", { stopOnReply, openTracking, linkTracking, sendingInterval, selectedSendingAccounts, selectedPhoneNumbers });

        // Ensure there's a selected campaign ID before attempting to update

        if (!selectedCampaignId) {
            console.log("No selectedCampaignId, exiting useEffect");
            return;
        }
        const allFetchedSuccessfully = Object.values(fetchSuccess).every(status => status === true);
        console.log("All fetched successfully:", allFetchedSuccessfully);

        if (!allFetchedSuccessfully) {
            console.log("Not all data fetched successfully");
            return;
        }
        if (!selectedCampaignId) {
            console.log("No selectedCampaignId");
            return;
        }

        // Prepare the updated campaign settings based on the current state
        const updatedCampaignSettings: CampaignSettings = {
            stopOnReply,
            openTracking,
            linkTracking,
            sendingInterval: {
                min: sendingInterval[0],
                max: sendingInterval[1],
            },
            includeSignature,
            strongReplyDetection,
            timezoneAware,
            contactHandlingStrategy: {
                email: {
                    ifEmailMissing: emailHandlingStrategy,
                    prioritization: {
                        prioritizeTypes: emailPrioritizeTypes,
                        avoidTypes: emailAvoidTypes,
                        prioritizeValidity: emailPrioritizeValidity,
                        avoidValidity: emailAvoidValidity,
                    }
                },
                sms: {
                    ifPhoneMissing: smsHandlingStrategy,
                    prioritization: {
                        prioritizeTypes: smsPrioritizeTypes,
                        avoidTypes: smsAvoidTypes,
                        prioritizeValidity: smsPrioritizeValidity,
                        avoidValidity: smsAvoidValidity,
                    }
                }
            }
        };


        // Debounce or throttle this update if necessary to avoid too many requests
        const update = async () => {
            console.log("Updating campaign settings with", updatedCampaignSettings);
            try {
                await updateCampaignSettingsMutation.mutateAsync(updatedCampaignSettings);
                console.log("Campaign settings updated successfully.");
            } catch (error) {
                console.error("Error updating campaign settings", error);
            }
        };

        // Call the update function
        update();

        // Include all state variables that should trigger an update in the dependency array
    }, [
        stopOnReply,
        openTracking,
        linkTracking,
        includeSignature,
        strongReplyDetection,
        timezoneAware,
        sendingInterval,
        selectedSendingAccounts,
        selectedPhoneNumbers,
        emailHandlingStrategy,
        smsHandlingStrategy,
        emailPrioritizeTypes,
        emailAvoidTypes,
        emailPrioritizeValidity,
        emailAvoidValidity,
        smsPrioritizeTypes,
        smsAvoidTypes,
        smsPrioritizeValidity,
        smsAvoidValidity
    ]);






    useEffect(() => {
        const fetchCampaignSettings = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/campaign/${selectedCampaignId}`);
                const data = response.data;

                // Assuming the response data has the structure that matches your state
                setStopOnReply(data.stopOnReply);
                setOpenTracking(data.openTracking);
                setLinkTracking(data.linkTracking);
                setSendingInterval([data.sendingInterval.min, data.sendingInterval.max]);
                setSelectedPhoneNumbers(data.senderPhoneNumbers.map(phone => phone.phoneNumber));

                const initialSelectedAccounts = data.sendingAccounts.map(account => ({
                    accountId: account.accountId,
                    sendingAccount: allSendingAccounts.find(sa => sa.id === account.accountId)?.sendingAccount || '',
                }));
                setSelectedSendingAccounts(initialSelectedAccounts);
                setIncludeSignature(data.includeSignature);
                setStrongReplyDetection(data.strongReplyDetection);
                setTimezoneAware(data.timezoneAware);
                setEmailHandlingStrategy(data.contactHandlingStrategy.email.ifEmailMissing);
                setSmsHandlingStrategy(data.contactHandlingStrategy.sms.ifPhoneMissing);

                // Set the prioritization settings
                setEmailPrioritizeTypes(data.contactHandlingStrategy.email.prioritization.prioritizeTypes);
                setEmailAvoidTypes(data.contactHandlingStrategy.email.prioritization.avoidTypes);
                setEmailPrioritizeValidity(data.contactHandlingStrategy.email.prioritization.prioritizeValidity);
                setEmailAvoidValidity(data.contactHandlingStrategy.email.prioritization.avoidValidity);

                setSmsPrioritizeTypes(data.contactHandlingStrategy.sms.prioritization.prioritizeTypes);
                setSmsAvoidTypes(data.contactHandlingStrategy.sms.prioritization.avoidTypes);
                setSmsPrioritizeValidity(data.contactHandlingStrategy.sms.prioritization.prioritizeValidity);
                setSmsAvoidValidity(data.contactHandlingStrategy.sms.prioritization.avoidValidity);

                // Update other state variables accordingly
            } catch (error) {
                console.error("Failed to fetch campaign data", error);
                // Handle error state
            }
        };

        if (selectedCampaignId) {
            fetchCampaignSettings();
        }
    }, [selectedCampaignId, refreshCounter]); // Dependency array


    const handleSendingAccountsChange = (event: SelectChangeEvent<string[]>) => {
        const newValue = event.target.value as string[];
        const changedAccountId = newValue.find(accountId => !selectedSendingAccounts.map(a => a.accountId).includes(accountId))
            || selectedSendingAccounts.find(account => !newValue.includes(account.accountId))?.accountId;

        if (changedAccountId && selectedCampaignId) {
            updateSendingAccountsMutation.mutate({
                campaignId: selectedCampaignId,
                emailAccountId: changedAccountId
            });
        }

        // Optimistic update
        const newSelectedAccounts = newValue.map(accountId => {
            const account = allSendingAccounts.find(account => account.id === accountId);
            return account ? { accountId: account.id, sendingAccount: account.sendingAccount } : null;
        }).filter(account => account !== null) as { accountId: string; sendingAccount: string }[];

        setSelectedSendingAccounts(newSelectedAccounts);
    };



    const updateSendingAccountsMutation = useMutation<void, Error, UpdateSendingAccountData, { previousSelectedAccounts: typeof selectedSendingAccounts }>(
        ({ campaignId, emailAccountId }) => axios.post(`${process.env.REACT_APP_API_URL}/campaign/assign-senders`, {
            campaignId,
            emailAccountId
        }), {
        onMutate: async (variables) => {
            // Capture the current state before the mutation is attempted
            return { previousSelectedAccounts: [...selectedSendingAccounts] };
        },
        onError: (error, variables, context) => {
            console.error('Error updating sending accounts', error);
            // Rollback to the previous state on error
            if (context?.previousSelectedAccounts) {
                setSelectedSendingAccounts(context.previousSelectedAccounts);
            }
        },
        onSuccess: () => {
            queryClient.invalidateQueries(['campaignData', selectedCampaignId]);
        },
    });

    const assignPhoneNumberMutation = useMutation(
        async ({ campaignId, phoneNumber }: { campaignId: string; phoneNumber: string }) => {
            return axios.post(`${process.env.REACT_APP_API_URL}/campaign/assign-phone-number`, { campaignId, phoneNumber });
        }, {
        onSuccess: () => {
            // Invalidate and refetch data as needed
            queryClient.invalidateQueries(['campaignData', selectedCampaignId]);
        },
        onError: (error) => {
            console.error('Error assigning phone number to campaign', error);
        }
    }
    );




    function CreativeThumbComponent(props) {
        const { children, ...other } = props;
        return (
            <SliderThumb {...other}>
                {children}
                <HistoryToggleOffSharpIcon /> {/* This is your custom icon */}
            </SliderThumb>
        );
    }


    const springProps = useSpring({
        opacity: 1,
        transform: 'translateY(0px)',
        from: { opacity: 0, transform: 'translateY(-20px)' },
    });

    const toggleAdvancedOptions = () => {
        setShowAdvanced(!showAdvanced);
    };


    const refreshData = () => {
        setFetchSuccess({
            phoneNumbers: false,
            sendingAccounts: false,
            campaignData: false,
        });
        setRefreshCounter((prev) => prev + 1); // Increment the counter to trigger re-fetch
    };


    return (
        <animated.div style={springProps}>
            <Container>
                <Grid container spacing={3}>
                    {fetchSuccess.sendingAccounts ? (
                        <Grid item xs={12}>
                            <InputLabel
                                variant='standard' id="sending-accounts-label">Sending Accounts</InputLabel>
                            <Select
                                labelId="sending-accounts-label"
                                id="sending-accounts"
                                multiple
                                fullWidth
                                variant='standard'
                                value={selectedSendingAccounts.map(account => account.accountId)}
                                onChange={handleSendingAccountsChange}
                                input={<OutlinedInput id="select-multiple-chip" label="Sending Accounts" />}
                                renderValue={(selected) => (
                                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                        {selected.map(accountId => (
                                            <Chip
                                                variant='filled'
                                                color='primary'
                                                sx={{ fontSize: "15px", height: "35px", borderRadius: "0px" }}
                                                key={accountId}
                                                label={allSendingAccounts.find(account => account.id === accountId)?.sendingAccount || ''}
                                            />
                                        ))}
                                    </Box>
                                )}
                            >
                                {allSendingAccounts.length === 0 ? (
                                    <MenuItem disabled>
                                        <em>No sending accounts available</em>
                                    </MenuItem>
                                ) : (
                                    allSendingAccounts.map((account) => (
                                        <MenuItem key={account.id} value={account.id}>
                                            <Checkbox checked={selectedSendingAccounts.some(selectedAccount => selectedAccount.accountId === account.id)} />
                                            {account.sendingAccount}
                                        </MenuItem>
                                    ))
                                )}
                            </Select>
                        </Grid>
                    ) : (
                        <Grid item xs={12}>
                            <Skeleton variant="rectangular" width="100%" height={60} />
                        </Grid>
                    )}

                    {fetchSuccess.phoneNumbers ? (
                        //@ts-ignore
                        <Grid item xs={12}>
                            <InputLabel variant='standard' id="phone-numbers-label">Phone Numbers</InputLabel>
                            <Select
                                labelId="phone-numbers-label"
                                id="twilio-phone-numbers"
                                multiple
                                fullWidth
                                variant='standard'
                                value={selectedPhoneNumbers} // Use selectedPhoneNumbers directly
                                onChange={handlePhoneNumberChange}
                                input={<OutlinedInput id="select-multiple-chip-phone" label="Twilio Phone Numbers" />}
                                renderValue={(selected) => (
                                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                        {selected.map((phoneNumber) => (
                                            <Chip
                                                key={phoneNumber}
                                                label={phoneNumber}
                                                variant='filled'
                                                color='secondary'
                                                sx={{ fontSize: "15px", height: "35px", color: "#fff", borderRadius: "0px" }}
                                            />
                                        ))}
                                    </Box>
                                )}
                            >
                                {allPhoneNumbers.length === 0 ? (
                                    <MenuItem disabled>
                                        <em>No phone numbers available</em>
                                    </MenuItem>
                                ) : (
                                    allPhoneNumbers.map((phone) => (
                                        <MenuItem key={phone.phoneNumber} value={phone.phoneNumber}>
                                            <Checkbox checked={selectedPhoneNumbers.includes(phone.phoneNumber)} />
                                            {phone.phoneNumber}
                                        </MenuItem>
                                    ))
                                )}
                            </Select>
                            {console.log("Render - allPhoneNumbers:", allPhoneNumbers)}
                            {console.log("Render - selectedPhoneNumbers:", selectedPhoneNumbers)}

                        </Grid>

                    ) : (
                        <Grid item xs={12}>
                            <Skeleton variant="rectangular" width="100%" height={60} />
                        </Grid>
                    )}

                    <Grid item xs={6}>
                        <FormControlLabel
                            control={<Checkbox checked={stopOnReply}
                                icon={
                                    <DoDisturbIcon
                                        style={{
                                            fontSize: '35px'
                                        }}
                                    />
                                }
                                checkedIcon={< DoDisturbIcon style={{
                                    fontSize: '35px'
                                }}
                                />
                                }
                                onChange={(e) => setStopOnReply(e.target.checked)} />}
                            label="Stop on reply"
                        />
                    </Grid>
                    <Grid item xs={6} >
                        <FormControlLabel
                            control={<Checkbox checked={openTracking}
                                icon={<MarkunreadSharpIcon style={{ fontSize: "35px" }} />}
                                checkedIcon={<MarkEmailUnreadSharpIcon style={{ fontSize: "35px" }} />}
                                onChange={(e) => setOpenTracking(e.target.checked)} />}
                            label="Open tracking"
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <FormControlLabel
                            control={<Checkbox checked={linkTracking}
                                icon={<LinkOffIcon style={{ fontSize: "35px" }} />}
                                checkedIcon={<InsertLinkIcon style={{ fontSize: "35px" }} />}
                                onChange={(e) => setLinkTracking(e.target.checked)} />}
                            label="Link tracking"
                        />
                    </Grid>

                    <>

                        <Grid item xs={6}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        icon={<FaSignature style={{ fontSize: "35px" }} />}
                                        checkedIcon={<FaSignature style={{ fontSize: "35px" }} />}
                                        checked={includeSignature}
                                        onChange={(e) => setIncludeSignature(e.target.checked)}
                                    />
                                }

                                label="Include Signature"
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        icon={<FaReplyd style={{ fontSize: "35px" }} />}
                                        checkedIcon={<FaReplyd style={{ fontSize: "35px" }} />}
                                        checked={strongReplyDetection}
                                        onChange={(e) => setStrongReplyDetection(e.target.checked)}
                                    />

                                }
                                label="Strong Reply Detection"
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        icon={<WatchSharpIcon style={{ fontSize: "35px" }} />}
                                        checkedIcon={<WatchSharpIcon style={{ fontSize: "35px" }} />}
                                        checked={timezoneAware}
                                        onChange={(e) => setTimezoneAware(e.target.checked)}
                                    />
                                }
                                label="Timezone Aware"
                            />
                        </Grid>

                        <Grid item xs={12} marginTop={3}>

                            <InputLabel id="sending-interval-label">Sending Interval (min - max)</InputLabel>
                            <Slider
                                value={sendingInterval}
                                onChange={(_, newValue) => setSendingInterval(newValue as [number, number])}
                                min={0}
                                max={60}
                                step={1}
                                valueLabelDisplay="auto"
                                valueLabelFormat={(value) => `${value} mins`}
                                slots={{ thumb: CreativeThumbComponent }}

                            />

                        </Grid>

                        <Grid container spacing={3} marginTop={3} marginBottom={8}>
                            <Grid item xs={12} sm={6}>
                                <Paper elevation={3} style={{ padding: '16px' }}>
                                    <FormControl component="fieldset" sx={{ marginTop: "7px", marginBottom: "20px" }}>
                                        <FormLabel component="legend">If the lead doesn't have an email</FormLabel>
                                        <RadioGroup
                                            row
                                            aria-label="email-handling-strategy"
                                            name="emailHandlingStrategy"
                                            value={emailHandlingStrategy}
                                            onChange={(event) => setEmailHandlingStrategy(event.target.value as 'skip' | 'process')}
                                        >
                                            <FormControlLabel value="skip" control={<Radio />} label="Skip leads without an email" />
                                            <FormControlLabel value="process" control={<Radio />} label="Process with available methods" />
                                        </RadioGroup>
                                    </FormControl>

                                    <Grid container spacing={2}>
                                        <Grid item xs={12} md={6}>
                                            <FormControl fullWidth>
                                                <InputLabel id="email-prioritize-types-label">Prioritize Email Types</InputLabel>
                                                <Select
                                                    labelId="email-prioritize-types-label"
                                                    id="email-prioritize-types"
                                                    multiple
                                                    value={emailPrioritizeTypes}
                                                    onChange={(event) => setEmailPrioritizeTypes(event.target.value as EmailType[])}
                                                    renderValue={(selected) => selected.join(', ')}
                                                >
                                                    <MenuItem value={EmailType.Any}>Any</MenuItem>
                                                    <MenuItem value={EmailType.Personal}>Personal</MenuItem>
                                                    <MenuItem value={EmailType.Professional}>Professional</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>

                                        <Grid item xs={12} md={6}>
                                            <FormControl fullWidth>
                                                <InputLabel id="email-avoid-types-label">Avoid Email Types</InputLabel>
                                                <Select
                                                    labelId="email-avoid-types-label"
                                                    id="email-avoid-types"
                                                    multiple
                                                    value={emailAvoidTypes}
                                                    onChange={(event) => setEmailAvoidTypes(event.target.value as EmailType[])}
                                                    renderValue={(selected) => selected.join(', ')}
                                                >
                                                    <MenuItem value={EmailType.Personal}>Personal</MenuItem>
                                                    <MenuItem value={EmailType.Professional}>Professional</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>

                                        <Grid item xs={12} md={6}>
                                            <FormControl fullWidth>
                                                <InputLabel id="email-prioritize-validity-label">Prioritize Email Validity</InputLabel>
                                                <Select
                                                    labelId="email-prioritize-validity-label"
                                                    id="email-prioritize-validity"
                                                    multiple
                                                    value={emailPrioritizeValidity}
                                                    onChange={(event) => setEmailPrioritizeValidity(event.target.value as EmailValidity[])}
                                                    renderValue={(selected) => selected.join(', ')}
                                                >
                                                    <MenuItem value={EmailValidity.Any}>Any</MenuItem>
                                                    <MenuItem value={EmailValidity.Valid}>Valid</MenuItem>
                                                    <MenuItem value={EmailValidity.Catchall}>Catchall</MenuItem>
                                                    <MenuItem value={EmailValidity.Unknown}>Unknown</MenuItem>
                                                    <MenuItem value={EmailValidity.Invalid}>Invalid</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>

                                        <Grid item xs={12} md={6}>
                                            <FormControl fullWidth>
                                                <InputLabel id="email-avoid-validity-label">Avoid Email Validity</InputLabel>
                                                <Select
                                                    labelId="email-avoid-validity-label"
                                                    id="email-avoid-validity"
                                                    multiple
                                                    value={emailAvoidValidity}
                                                    onChange={(event) => setEmailAvoidValidity(event.target.value as EmailValidity[])}
                                                    renderValue={(selected) => selected.join(', ')}
                                                >
                                                    <MenuItem value={EmailValidity.Valid}>Valid</MenuItem>
                                                    <MenuItem value={EmailValidity.Catchall}>Catchall</MenuItem>
                                                    <MenuItem value={EmailValidity.Unknown}>Unknown</MenuItem>
                                                    <MenuItem value={EmailValidity.Invalid}>Invalid</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <Paper elevation={3} style={{ padding: '16px' }}>
                                    <FormControl component="fieldset" sx={{ marginTop: "7px", marginBottom: "20px" }}>
                                        <FormLabel component="legend">If the lead doesn't have a phone number</FormLabel>
                                        <RadioGroup
                                            row
                                            aria-label="sms-handling-strategy"
                                            name="smsHandlingStrategy"
                                            value={smsHandlingStrategy}
                                            onChange={(event) => setSmsHandlingStrategy(event.target.value as 'skip' | 'process')}
                                        >
                                            <FormControlLabel value="skip" control={<Radio />} label="Skip leads without a phone" />
                                            <FormControlLabel value="process" control={<Radio />} label="Process with available methods" />
                                        </RadioGroup>
                                    </FormControl>

                                    <Grid container spacing={2}>
                                        <Grid item xs={12} md={6}>
                                            <FormControl fullWidth>
                                                <InputLabel id="sms-prioritize-types-label">Prioritize SMS Types</InputLabel>
                                                <Select
                                                    labelId="sms-prioritize-types-label"
                                                    id="sms-prioritize-types"
                                                    multiple
                                                    value={smsPrioritizeTypes}
                                                    onChange={(event) => setSmsPrioritizeTypes(event.target.value as PhoneType[])}
                                                    renderValue={(selected) => (selected as PhoneType[]).join(', ')}
                                                >
                                                    <MenuItem value={PhoneType.Any}>Any</MenuItem>
                                                    <MenuItem value={PhoneType.Mobile}>Mobile</MenuItem>
                                                    <MenuItem value={PhoneType.Landline}>Landline</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>

                                        <Grid item xs={12} md={6}>
                                            <FormControl fullWidth>
                                                <InputLabel id="sms-avoid-types-label">Avoid SMS Types</InputLabel>
                                                <Select
                                                    labelId="sms-avoid-types-label"
                                                    id="sms-avoid-types"
                                                    multiple
                                                    value={smsAvoidTypes}
                                                    onChange={(event) => setSmsAvoidTypes(event.target.value as PhoneType[])}
                                                    renderValue={(selected) => (selected as PhoneType[]).join(', ')}
                                                >
                                                    <MenuItem value={PhoneType.Mobile}>Mobile</MenuItem>
                                                    <MenuItem value={PhoneType.Landline}>Landline</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>

                                        <Grid item xs={12} md={6}>
                                            <FormControl fullWidth>
                                                <InputLabel id="sms-prioritize-validity-label">Prioritize SMS Validity</InputLabel>
                                                <Select
                                                    labelId="sms-prioritize-validity-label"
                                                    id="sms-prioritize-validity"
                                                    multiple
                                                    value={smsPrioritizeValidity}
                                                    onChange={(event) => setSmsPrioritizeValidity(event.target.value as PhoneValidity[])}
                                                    renderValue={(selected) => (selected as PhoneValidity[]).join(', ')}
                                                >
                                                    <MenuItem value={PhoneValidity.Any}>Any</MenuItem>
                                                    <MenuItem value={PhoneValidity.Valid}>Valid</MenuItem>
                                                    <MenuItem value={PhoneValidity.Unknown}>Unknown</MenuItem>
                                                    <MenuItem value={PhoneValidity.Invalid}>Invalid</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>

                                        <Grid item xs={12} md={6}>
                                            <FormControl fullWidth>
                                                <InputLabel id="sms-avoid-validity-label">Avoid SMS Validity</InputLabel>
                                                <Select
                                                    labelId="sms-avoid-validity-label"
                                                    id="sms-avoid-validity"
                                                    multiple
                                                    value={smsAvoidValidity}
                                                    onChange={(event) => setSmsAvoidValidity(event.target.value as PhoneValidity[])}
                                                    renderValue={(selected) => (selected as PhoneValidity[]).join(', ')}
                                                >
                                                    <MenuItem value={PhoneValidity.Valid}>Valid</MenuItem>
                                                    <MenuItem value={PhoneValidity.Unknown}>Unknown</MenuItem>
                                                    <MenuItem value={PhoneValidity.Invalid}>Invalid</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>
                        </Grid>
                    </>
                    {!Object.values(fetchSuccess).every(Boolean) && (
                        <Grid item xs={12} style={{ textAlign: 'center' }}>
                            <Button variant="contained" onClick={refreshData}>Refresh Data</Button>
                        </Grid>
                    )}

                </Grid>
            </Container>
        </animated.div>
    );
};

export default EmailCampaignSettings;
