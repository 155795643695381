// src\app\pages\4-Campaigns\3-CampaignSequence\SequenceComponents\NewEditor\components\LexicalEditorWrapper\styles.tsx
import styled from "@emotion/styled";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";

export const MuiContentEditable = styled(ContentEditable)({
  minHeight: 200,
  width: "100%",
  // padding: "10px",
  color: "#5E5F64",
  top: 15,
  // left: 10,
  // right: 10,
  borderRadius: 5,
  padding: 20,
  position: "relative",
  outline: "none",
  h1: {
    color: "#5E5F64",
  },
  h2: {
    color: "#5E5F64",
  },
  h3: {
    color: "#5E5F64",
  },
  h4: {
    color: "#5E5F64",
  },
  h5: {
    color: "#5E5F64",
  },
  h6: {
    color: "#5E5F64",
  },


});

export const placeHolderSx = {
  position: "absolute",
  top: 15,
  left: 10,
  color: "#222",
  right: 10,
  userSelect: "none",
  display: "inline-block",
  pointerEvents: "none",
};
