import React, { useEffect, useState } from 'react';
import axios from 'axios';
import CollectionLeads from './CollectionLeads';
import CollectionList from './CollectionList';
import { motion, AnimatePresence } from 'framer-motion';
import { Grid } from '@mui/material';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, TextField } from '@mui/material';


function Collections() {
    const [data, setData] = useState<any[]>([]); // Change the type accordingly
    const [selectedCollectionId, setSelectedCollectionId] = useState<string | null>(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [newCollectionName, setNewCollectionName] = useState('');
    const [isListVisible, setIsListVisible] = useState(true);

    useEffect(() => {
        // Make the Axios GET request
        axios.get(`${process.env.REACT_APP_API_URL}/advancedleadgen/collections`)
            .then((response) => {
                setData(response.data.collections);
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });
    }, []);

    const handleOpenModal = () => {
        setIsModalOpen(true);
    };

    const handleCreateCollection = () => {
        axios.post(`${process.env.REACT_APP_API_URL}/advancedleadgen/collection`, {
            collectionName: newCollectionName
        }).then((response) => {
            // Refresh the collection list
            setData([...data, response.data]);
            setIsModalOpen(false);
        }).catch((error) => {
            console.error('Error creating collection:', error);
        });
    };


    const handleCollectionSelect = (collectionId: string) => {
        console.log(collectionId); // Log the selected collectionId
        setSelectedCollectionId(collectionId);
    };

    return (
        <Grid container spacing={3}>

            <Dialog open={isModalOpen} onClose={() => setIsModalOpen(false)}>
                <DialogTitle>Add New Collection</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        label="Collection Name"
                        fullWidth
                        variant="outlined"
                        value={newCollectionName}
                        onChange={(e) => setNewCollectionName(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setIsModalOpen(false)}>Cancel</Button>
                    <Button onClick={handleCreateCollection}>Create</Button>
                </DialogActions>
            </Dialog>


            {isListVisible && (
                <Grid item xs={3}>
                    <Button onClick={handleOpenModal}>Add New Collection</Button>
                    <motion.div
                        className="no-global-transition"

                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ duration: 1 }}
                    >
                        <CollectionList data={data} onCollectionSelect={handleCollectionSelect} />
                    </motion.div>
                </Grid>
            )}
            <Grid item xs={isListVisible ? 9 : 12}>

                <AnimatePresence exitBeforeEnter={false} mode='wait'>
                    {selectedCollectionId && (
                        <motion.div
                            className="no-global-transition"

                            key={selectedCollectionId}
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            transition={{ duration: 0.3 }}
                            exit={{ opacity: 0 }}
                        >
                            <CollectionLeads collectionId={selectedCollectionId} />
                        </motion.div>
                    )}
                </AnimatePresence>
            </Grid>

            <Button onClick={() => setIsListVisible(!isListVisible)}>
                {isListVisible ? "Hide List" : "Show List"}
            </Button>
        </Grid>

    );
}

export default Collections;
