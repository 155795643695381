import { useAuth } from '@/src/app/modules/auth';
import { getUserByToken } from '@/src/app/modules/auth/core/_requests';
import { EditSharp, SaveSharp } from '@mui/icons-material';
import { Avatar, Box, Grid, IconButton, TextField, Typography } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import axios from 'axios';
import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';

const fetchUserProfile = async () => {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/account/profile`);
    return response.data;
};

const ProfilePageHeader: React.FC = () => {
    const { data: userProfile, isLoading, isError, refetch } = useQuery(['userProfile'], fetchUserProfile);
    const { currentUser, setCurrentUser } = useAuth();
    const [currentTime, setCurrentTime] = useState<string>('');

    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [firstname, setFirstname] = useState<string>('');
    const [lastname, setLastname] = useState<string>('');
    const [selectedTimezone, setSelectedTimezone] = useState({ value: '', label: '' });

    useEffect(() => {
        if (userProfile) {
            setFirstname(userProfile.firstname);
            setLastname(userProfile.lastname);
            setSelectedTimezone({
                value: userProfile.timezone,
                label: `${userProfile.timezone} (GMT${moment.tz(userProfile.timezone).format('Z')}) ${moment.tz(userProfile.timezone).zoneAbbr()}`
            });
        }
    }, [userProfile]);

    useEffect(() => {
        if (currentUser) {
            const interval = setInterval(() => {
                setCurrentTime(moment().tz(currentUser.timezone).format('YYYY-MM-DD HH:mm:ss'));
            }, 1000);
            return () => clearInterval(interval);
        }
    }, [currentUser]);

    const handleEditToggle = () => {
        setIsEditing((prev) => !prev);
    };

    const handleSave = async () => {
        try {
            await axios.post(`${process.env.REACT_APP_API_URL}/account/update-profile`, {
                firstname,
                lastname,
                timezone: selectedTimezone.value
            });
            // Refetch user profile and update currentUser
            await refetch();
            const { data: updatedUser } = await getUserByToken();
            setCurrentUser(updatedUser);
            setIsEditing(false);  // Set isEditing to false after saving
        } catch (error) {
            console.error('Failed to update profile:', error);
        }
    };

    const timezoneOptions = moment.tz.names().map((zone) => ({
        value: zone,
        label: `${zone} (GMT${moment.tz(zone).format('Z')}) ${moment.tz(zone).zoneAbbr()}`,
    }));

    const modifiedTimezoneOptions = timezoneOptions.filter(option => option.value !== 'Israel').concat({ value: 'Palestine', label: 'Palestine (GMT+2) EET' });

    return (
        <Box>
            {isLoading && <div>Loading...</div>}
            {isError && <div>Error fetching user profile</div>}
            {userProfile && (
                <Box>
                    <Grid>
                        <Grid>
                            <Avatar src={userProfile.avatar} alt={`${userProfile.firstname} ${userProfile.lastname}`} sx={{ width: 160, height: 160 }} />
                        </Grid>
                        <Grid>
                            <Typography variant='h4' sx={{ color: 'text.primary', '&:hover': { color: 'primary.main' }, fontSize: '2rem', fontWeight: 'bold', marginRight: '0.5rem' }}>
                                {userProfile.firstname} {userProfile.lastname}
                            </Typography>
                            {/* Display current time in user's timezone */}
                            <Typography variant='body1' sx={{ color: 'text.secondary', fontSize: '1rem' }}>
                                Current time: {currentTime}
                            </Typography>
                            {/* Edit button */}
                            {isEditing ? (
                                <IconButton onClick={handleSave}>
                                    <SaveSharp sx={{ fontSize: "55px" }} />
                                </IconButton>
                            ) : (
                                <IconButton onClick={handleEditToggle}>
                                    <EditSharp sx={{ fontSize: "55px" }} />
                                </IconButton>
                            )}
                            {/* Editable text fields */}
                            {isEditing && (
                                <Box sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', gap: 2 }}>
                                    <TextField
                                        id="firstname"
                                        label="First Name"
                                        value={firstname}
                                        onChange={(e) => setFirstname(e.target.value)}
                                        disabled={!isEditing}
                                    />
                                    <TextField
                                        id="lastname"
                                        label="Last Name"
                                        value={lastname}
                                        onChange={(e) => setLastname(e.target.value)}
                                        disabled={!isEditing}
                                    />
                                    <Autocomplete
                                        value={selectedTimezone.label}
                                        onChange={(event, newValue) => {
                                            const timezone = timezoneOptions.find(option => option.label === newValue);
                                            setSelectedTimezone(timezone || { value: '', label: '' });
                                        }}
                                        options={timezoneOptions.map(option => option.label)}
                                        renderInput={params => <TextField {...params} label="Timezone" />}
                                        fullWidth
                                        disableClearable
                                    />
                                </Box>
                            )}
                        </Grid>
                    </Grid>
                </Box>
            )}
        </Box>
    );
};

export { ProfilePageHeader };
