// src\app\pages\3-CRM\UploadBox\CsvButton.tsx
import ClearSharpIcon from '@mui/icons-material/ClearSharp';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DeleteIcon from '@mui/icons-material/Delete';
import DeleteForeverSharpIcon from '@mui/icons-material/DeleteForeverSharp';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';

interface CsvButtonProps {
    onFileUpload: (files: File[]) => void;
    onRemoveFile: () => void;
}

interface DropzoneConfig {
    onDrop: (acceptedFiles: File[]) => void;
    noClick: boolean;
    noKeyboard: boolean;
    accept: string;
}


const CsvButton = ({ onFileUpload, onRemoveFile }) => {
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [openDialog, setOpenDialog] = useState(false); // State for dialog visibility
    const [unsavedChanges, setUnsavedChanges] = useState(false);
    const [openCloseConfirmDialog, setOpenCloseConfirmDialog] = useState(false);


    const handleRemoveClick = () => {
        setOpenDialog(true); // Show dialog when remove is clicked
    };

    const confirmRemove = () => {
        setOpenDialog(false); // Close dialog
        setSelectedFile(null);
        if (onRemoveFile) {
            onRemoveFile();
        }

        // Reset unsavedChanges when the file is removed
        setUnsavedChanges(false);
    };

    const handleAttemptClose = () => {
        if (unsavedChanges) {
            setOpenCloseConfirmDialog(true);
        } else {
            // Handle the module close logic if there are no unsaved changes
        }
    };

    const removeSelectedFile = () => {
        setSelectedFile(null);
        if (onRemoveFile) {
            onRemoveFile();
        }
    };

    const handleFileUpload = (files: File[]) => {
        const file = files[0];
        if (!file) return;

        setSelectedFile(file);
        onFileUpload(files);

        // Set unsavedChanges to true
        setUnsavedChanges(true);
    };

    useEffect(() => {
        const unloadListener = (e: BeforeUnloadEvent) => {
            if (unsavedChanges) {
                e.preventDefault();
                e.returnValue = ''; // Prompt the user with a warning message
            }
        };

        window.addEventListener('beforeunload', unloadListener);

        return () => {
            window.removeEventListener('beforeunload', unloadListener);
        };
    }, [unsavedChanges]);


    const { getRootProps, getInputProps } = useDropzone({
        onDrop: handleFileUpload,
        noClick: !!selectedFile,
        noKeyboard: !!selectedFile,
        accept: {
            'text/csv': ['.csv'],
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
            'application/vnd.ms-excel': ['.xls', '.xlsx']
        }
    });

    return (
        <Grid container justifyContent="center" alignItems="center" >
            <Grid item xs={12}>
                <Paper elevation={3} {...getRootProps()} sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: 2,

                    backgroundColor: selectedFile ? 'default' : 'var(--bs-bg-dark)',
                    border: selectedFile ? 'none' : '2px dashed primary.main',
                    borderRadius: 0,
                    cursor: 'pointer',
                    '&:hover': {
                        backgroundColor: selectedFile ? 'default' : 'var(--bs-primary)',
                    }
                }}>
                    {!selectedFile && <input {...getInputProps()} />}
                    {!selectedFile && (
                        <>
                            <CloudUploadIcon fontSize="large" color="secondary" sx={{ fontSize: '64px', mb: 1 }} />
                            <Typography variant="h6" color="secondary">Upload CSV File</Typography>
                            <Typography color="secondary">Drag 'n' drop a CSV file here, or click to select one.</Typography>
                        </>
                    )}
                    {selectedFile && (
                        <div style={{ textAlign: 'center' }}>
                            <Typography color="success.main">Selected file: {selectedFile.name}</Typography>
                            <IconButton onClick={handleRemoveClick} aria-label="Remove file" color="warning" size="small">
                                <DeleteIcon fontSize='large' />
                            </IconButton>
                        </div>
                    )}

                    {/* Warning Dialog */}
                    <Dialog
                        open={openDialog}
                        onClose={() => setOpenDialog(false)}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        style={{ zIndex: 9999999999999 }}
                    >
                        <DialogTitle id="alert-dialog-title">
                            {"Confirm Removal"}
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                Removing the file will reset and delete all changes done. Are you sure you want to proceed?
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <IconButton onClick={() => setOpenDialog(false)} color="success" size="large" autoFocus>
                                <ClearSharpIcon fontSize='large' />
                            </IconButton>

                            <IconButton onClick={confirmRemove} color="warning" size="large" autoFocus>
                                <DeleteForeverSharpIcon fontSize='large' />
                            </IconButton>
                        </DialogActions>
                    </Dialog>
                </Paper>
            </Grid>
        </Grid>
    );
};

export default CsvButton;