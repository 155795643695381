// src\app\pages\uploadcsv2\ColumnSettings.tsx
import React from 'react';
import { List, ListItem, ListItemText, Switch, Box, Button, FormControl, InputLabel, Typography, Divider } from '@mui/material';
import { Select, MenuItem } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import PhoneIphoneSharpIcon from '@mui/icons-material/PhoneIphoneSharp';
import EmailSharpIcon from '@mui/icons-material/EmailSharp';
import BusinessSharpIcon from '@mui/icons-material/BusinessSharp';
import PersonSharpIcon from '@mui/icons-material/PersonSharp';
import FormatTextdirectionLToRSharpIcon from '@mui/icons-material/FormatTextdirectionLToRSharp';
import LocationOnSharpIcon from '@mui/icons-material/LocationOnSharp';
import WorkSharpIcon from '@mui/icons-material/WorkSharp';
import InsertLinkSharpIcon from '@mui/icons-material/InsertLinkSharp';
import NumbersSharpIcon from '@mui/icons-material/NumbersSharp';
import { Header } from './interfaceHeader';
import CustomTooltip from '@/src/app/modules/CustomMaterialUiComponents/CustomTooltip';
import InfoSharpIcon from '@mui/icons-material/InfoSharp';
import { FaLinkedinIn } from "react-icons/fa";
import ArticleSharpIcon from '@mui/icons-material/ArticleSharp';

interface ColumnSettingsProps {
    editableHeaders: string[];
    setEditableHeaders: React.Dispatch<React.SetStateAction<string[]>>;
    originalHeaders: string[];
    visibility: boolean[];
    onVisibilityChange: (newVisibility: boolean[]) => void;
    onHeaderMappingChange: (index: number, newHeader: string) => void;
    headerTypeMappings: { [key: string]: string };
    setHeaderTypeMappings: React.Dispatch<React.SetStateAction<{ [key: string]: string }>>;
    emailTypesColumn: { [key: string]: string }; // Added prop
    setEmailTypesColumn: React.Dispatch<React.SetStateAction<{ [key: string]: string }>>; // Added prop
    phoneTypesColumn: { [key: string]: string }; // Added prop
    setPhoneTypesColumn: React.Dispatch<React.SetStateAction<{ [key: string]: string }>>; // Added prop
    selectedListHeaders: Header[];
    headerMappings: { [key: string]: string };
    setHeaderMappings: React.Dispatch<React.SetStateAction<{ [key: string]: string }>>;
    selectedListName: string;
    linkedInUrlColumn: string;
    setLinkedInUrlColumn: React.Dispatch<React.SetStateAction<string>>;
    icebreakerColumn: string;
    setIcebreakerColumn: React.Dispatch<React.SetStateAction<string>>;
    locationColumn: string;
    setLocationColumn: React.Dispatch<React.SetStateAction<string>>;
    companyNameColumn: string;
    setCompanyNameColumn: React.Dispatch<React.SetStateAction<string>>;
    fullNameColumn: string;
    setFullNameColumn: React.Dispatch<React.SetStateAction<string>>;
    firstNameColumn: string;
    setFirstNameColumn: React.Dispatch<React.SetStateAction<string>>;
    lastNameColumn: string;
    setLastNameColumn: React.Dispatch<React.SetStateAction<string>>;
    jobTitleColumn: string;
    setJobTitleColumn: React.Dispatch<React.SetStateAction<string>>;
}



const ColumnSettings: React.FC<ColumnSettingsProps> = ({
    editableHeaders,
    setEditableHeaders,
    originalHeaders,
    visibility,
    onVisibilityChange,
    onHeaderMappingChange,
    headerTypeMappings,
    setHeaderTypeMappings,
    emailTypesColumn,
    setEmailTypesColumn,
    phoneTypesColumn,
    setPhoneTypesColumn,
    selectedListHeaders,
    headerMappings,
    setHeaderMappings,
    selectedListName,
    linkedInUrlColumn,
    setLinkedInUrlColumn,
    icebreakerColumn,
    setIcebreakerColumn,
    locationColumn,
    setLocationColumn,
    companyNameColumn,
    setCompanyNameColumn,
    fullNameColumn,
    setFullNameColumn,
    firstNameColumn,
    setFirstNameColumn,
    lastNameColumn,
    setLastNameColumn,
    jobTitleColumn,
    setJobTitleColumn,
}) => {

    const [hoverIndex, setHoverIndex] = React.useState<number | null>(null);
    const [snackbarOpen, setSnackbarOpen] = React.useState(false);
    const [snackbarMessage, setSnackbarMessage] = React.useState('');

    const [focusedIndex, setFocusedIndex] = React.useState<number | null>(null);

    const handleSnackbar = (message) => {
        setSnackbarMessage(message);
        setSnackbarOpen(true);
    };

    React.useEffect(() => {
        const initialMappings = {};
        for (const header of originalHeaders) {
            initialMappings[header] = 'custom';
        }
        setHeaderTypeMappings(initialMappings);
    }, [originalHeaders]);


    const handleTypeChange = (index: number, type: string) => {
        const originalHeader = originalHeaders[index];
        const newVisibility = [...visibility];
        let resetEmailTypesColumn = { ...emailTypesColumn };
        let resetPhoneTypesColumn = { ...phoneTypesColumn };

        // Logic to reset email/phone type if the column's type changes
        if (emailTypesColumn[originalHeader]) {
            delete resetEmailTypesColumn[originalHeader];
            setEmailTypesColumn(resetEmailTypesColumn);
        }
        if (phoneTypesColumn[originalHeader]) {
            delete resetPhoneTypesColumn[originalHeader];
            setPhoneTypesColumn(resetPhoneTypesColumn);
        }
        if (type === 'linkedin') {
            if (linkedInUrlColumn) {
                // Check if another column is already set as LinkedIn URL
                handleSnackbar('Another column is already set as LinkedIn URL.');
                return;
            }
            setLinkedInUrlColumn(originalHeader);
        } else if (linkedInUrlColumn === originalHeader) {
            setLinkedInUrlColumn(''); // Reset if the current LinkedIn URL column type is changed
        }

        if (type === 'icebreaker') {
            if (icebreakerColumn) {
                handleSnackbar('Another column is already set as Icebreaker.');
                return;
            }
            setIcebreakerColumn(originalHeader);
        } else if (icebreakerColumn === originalHeader) {
            setIcebreakerColumn(''); // Reset if the current Icebreaker column type is changed
        }

        if (type === 'location') {
            if (locationColumn) {
                handleSnackbar('Another column is already set as Location.');
                return;
            }
            setLocationColumn(originalHeader);  // Assuming you have a setLocationColumn state hook
        } else if (locationColumn === originalHeader) {
            setLocationColumn('');  // Reset if the current location column type is changed
        }

        if (type === 'companyname') {
            if (companyNameColumn) {
                handleSnackbar('Another column is already set as Company Name.');
                return;
            }
            setCompanyNameColumn(originalHeader);
        } else if (companyNameColumn === originalHeader) {
            setCompanyNameColumn('');
        }

        if (type === 'fullname') {
            if (fullNameColumn) {
                handleSnackbar('Another column is already set as Full Name.');
                return;
            }
            setFullNameColumn(originalHeader);
        } else if (fullNameColumn === originalHeader) {
            setFullNameColumn('');
        }

        if (type === 'firstname') {
            if (firstNameColumn) {
                handleSnackbar('Another column is already set as First Name.');
                return;
            }
            setFirstNameColumn(originalHeader);
        } else if (firstNameColumn === originalHeader) {
            setFirstNameColumn('');
        }

        if (type === 'lastname') {
            if (lastNameColumn) {
                handleSnackbar('Another column is already set as Last Name.');
                return;
            }
            setLastNameColumn(originalHeader);
        } else if (lastNameColumn === originalHeader) {
            setLastNameColumn('');
        }

        if (type === 'jobtitle') {
            if (jobTitleColumn) {
                handleSnackbar('Another column is already set as Job Title.');
                return;
            }
            setJobTitleColumn(originalHeader);
        } else if (jobTitleColumn === originalHeader) {
            setJobTitleColumn('');
        }


        // Define the types that are exceptions and can be reused
        const exceptionTypes = new Set(['number', 'link', 'any', 'paragraph', "custom", "email", "phone"]);

        // Check if the type is already used in another column, excluding exception types
        const typeAlreadyUsed = !exceptionTypes.has(type) && Object.values(headerTypeMappings).some(
            (existingType, existingIndex) => existingType === type && existingIndex !== index
        );

        if (typeAlreadyUsed) {
            handleSnackbar(`"${type}" is already assigned to another column.`);
            return; // Prevent the change
        }

        // Update the mappings as the type is not already used or is an exception
        const newHeaderTypeMappings = { ...headerTypeMappings, [originalHeader]: type };
        setHeaderTypeMappings(newHeaderTypeMappings);

        // Automatically turn on the switch for this column
        newVisibility[index] = true;
        onVisibilityChange(newVisibility);

    };


    // Adjust the toggleAllVisibility function to reset email/phone types on "Unselect All"
    const toggleAllVisibility = (isVisible: boolean) => {
        let newVisibility = visibility.map(() => isVisible);
        onVisibilityChange(newVisibility);

        if (!isVisible) {
            // Reset all to 'custom' and clear type mappings
            setHeaderTypeMappings(originalHeaders.reduce((acc, header) => ({ ...acc, [header]: 'custom' }), {}));
            setEmailTypesColumn({});
            setPhoneTypesColumn({});
        }
    };


    const handleToggleVisibility = (index: number) => {
        const header = originalHeaders[index];
        let newVisibility = [...visibility];
        newVisibility[index] = !newVisibility[index];

        if (!newVisibility[index]) {
            // Reset type to 'custom' if the switch is turned off
            const originalHeader = originalHeaders[index];
            const newHeaderTypeMappings = { ...headerTypeMappings, [originalHeader]: 'custom' };
            setHeaderTypeMappings(newHeaderTypeMappings);

            // Reset type mappings if this column is being used as a type
            Object.entries(emailTypesColumn).forEach(([key, value]) => {
                if (value === header) {
                    let newEmailTypes = { ...emailTypesColumn };
                    delete newEmailTypes[key];
                    setEmailTypesColumn(newEmailTypes);
                }
            });
            Object.entries(phoneTypesColumn).forEach(([key, value]) => {
                if (value === header) {
                    let newPhoneTypes = { ...phoneTypesColumn };
                    delete newPhoneTypes[key];
                    setPhoneTypesColumn(newPhoneTypes);
                }
            });

        }



        onVisibilityChange(newVisibility);
    };


    const getIconForType = (type: string) => {
        switch (type) {
            case 'email':
                return <EmailSharpIcon color='secondary' />;
            case 'phone':
                return <PhoneIphoneSharpIcon color='secondary' />;
            case 'fullname':
                return <PersonSharpIcon color='secondary' />;
            case 'firstname':
                return <PersonSharpIcon color='secondary' />;
            case 'lastname':
                return <PersonSharpIcon color='secondary' />;
            case 'jobtitle':
                return <WorkSharpIcon color='secondary' />;
            case 'companyname':
                return <BusinessSharpIcon color='secondary' />;
            case 'location':
                return <LocationOnSharpIcon color='secondary' />;
            case 'link':
                return <InsertLinkSharpIcon color='secondary' />;
            case 'number':
                return <NumbersSharpIcon color='secondary' />;
            case 'paragraph':
                return <FormatTextdirectionLToRSharpIcon color='secondary' />;

            default:
                return null; // Or a default icon
        }
    };


    const handleTypeColumnChange = (header: string, type: string, isEmail: boolean) => {
        const newMappings = isEmail ? { ...emailTypesColumn, [header]: type } : { ...phoneTypesColumn, [header]: type };
        if (isEmail) {
            setEmailTypesColumn(newMappings);
        } else {
            setPhoneTypesColumn(newMappings);
        }

        // Automatically turn off the switch for the type column
        const headerIndex = originalHeaders.indexOf(type);
        if (headerIndex !== -1) {
            const newVisibility = [...visibility];
            newVisibility[headerIndex] = false;
            onVisibilityChange(newVisibility);
        }
    };

    const getExistingHeaderType = (selectedHeaderName: string, selectedListHeaders: Header[]): string => {
        const matchingHeader = selectedListHeaders.find(header => header.name === selectedHeaderName);
        return matchingHeader ? matchingHeader.type : 'custom';
    };

    const handleFocus = (index: number) => {
        setFocusedIndex(index);
    };

    const handleBlur = () => {
        setFocusedIndex(null);
    };



    return (
        <Box sx={{ maxHeight: '400px', overflowY: 'auto', width: '100%', bgcolor: 'background.paper' }}>
            <Button onClick={() => toggleAllVisibility(true)}>Select All</Button>
            <Button onClick={() => toggleAllVisibility(false)}>Unselect All</Button>
            <List component="nav" aria-label="Column settings">
                {editableHeaders.map((header, index) => (
                    <ListItem
                        key={`col-setting-${index}`}
                        button
                        onMouseEnter={() => setHoverIndex(index)}
                        onMouseLeave={() => setHoverIndex(null)}
                        sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', WebkitFlexDirection: 'column' }} // Use column layout
                    >
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>

                            <ListItemText primary={`${originalHeaders[index]}`} />


                            <Switch
                                edge="end"
                                checked={visibility[index]}
                                onChange={() => handleToggleVisibility(index)}
                                disabled={Object.values(emailTypesColumn).includes(originalHeaders[index]) || Object.values(phoneTypesColumn).includes(originalHeaders[index])}
                            />

                        </Box>

                        {hoverIndex === index || focusedIndex === index ? (
                            <Box sx={{ width: '100%' }} >
                                <Select
                                    value={headerTypeMappings[originalHeaders[index]] || ''}
                                    onChange={(e) => handleTypeChange(index, e.target.value as string)}
                                    fullWidth
                                    variant='outlined'
                                    disabled={Object.values(emailTypesColumn).includes(originalHeaders[index]) || Object.values(phoneTypesColumn).includes(originalHeaders[index])}
                                    MenuProps={{
                                        style: { zIndex: 2147483647 },
                                    }}
                                    sx={{ mt: 1 }} // Add some margin on top
                                    onFocus={() => handleFocus(index)}
                                    onBlur={handleBlur}
                                >

                                    <MenuItem  // Adjust the value as needed
                                        value="email">
                                        <Box  // Adjust the value as needed
                                            sx={{ display: 'flex', alignItems: 'center' }}>
                                            <EmailSharpIcon />
                                            <Box sx={{ width: 10 }} /> {/* This creates a spacer */}
                                            Email
                                        </Box>
                                    </MenuItem>
                                    <MenuItem value="phone">
                                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                            <PhoneIphoneSharpIcon /> {/* Replace with appropriate icon */}
                                            <Box sx={{ width: 10 }} />
                                            Phone
                                        </Box>
                                    </MenuItem>
                                    <MenuItem value="icebreaker">
                                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                            <ArticleSharpIcon />
                                            <Box sx={{ width: 10 }} />
                                            IceBreaker
                                        </Box>
                                    </MenuItem>
                                    <MenuItem value="linkedin">
                                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                            <FaLinkedinIn />
                                            <Box sx={{ width: 10 }} />
                                            Linkedin Url
                                        </Box>
                                    </MenuItem>
                                    <MenuItem value="fullname">
                                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                            <PersonSharpIcon /> {/* Replace with appropriate icon */}
                                            <Box sx={{ width: 10 }} />
                                            Full Name
                                        </Box>
                                    </MenuItem>
                                    {/* Repeat for other items */}
                                    <MenuItem value="firstname">
                                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                            <PersonSharpIcon /> {/* Replace with appropriate icon */}
                                            <Box sx={{ width: 10 }} />
                                            First Name
                                        </Box>
                                    </MenuItem>
                                    <MenuItem value="lastname">
                                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                            <PersonSharpIcon /> {/* Replace with appropriate icon */}
                                            <Box sx={{ width: 10 }} />
                                            Last Name
                                        </Box>
                                    </MenuItem>
                                    <MenuItem value="jobtitle">
                                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                            <WorkSharpIcon /> {/* Replace with appropriate icon */}
                                            <Box sx={{ width: 10 }} />
                                            Job Title
                                        </Box>
                                    </MenuItem>
                                    <MenuItem value="companyname">
                                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                            <BusinessSharpIcon /> {/* Replace with appropriate icon */}
                                            <Box sx={{ width: 10 }} />
                                            Company Name
                                        </Box>
                                    </MenuItem>
                                    <MenuItem value="location">
                                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                            <LocationOnSharpIcon />
                                            <Box sx={{ width: 10 }} />
                                            Location
                                        </Box>
                                    </MenuItem>

                                    <MenuItem value="link">
                                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                            <InsertLinkSharpIcon /> {/* Replace with appropriate icon */}
                                            <Box sx={{ width: 10 }} />
                                            Link
                                        </Box>
                                    </MenuItem>
                                    <MenuItem value="number">
                                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                            <NumbersSharpIcon /> {/* Replace with appropriate icon */}
                                            <Box sx={{ width: 10 }} />
                                            Number
                                        </Box>
                                    </MenuItem>
                                    <MenuItem value="paragraph">
                                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                            <FormatTextdirectionLToRSharpIcon /> {/* Replace with appropriate icon */}
                                            <Box sx={{ width: 10 }} />
                                            Paragraph
                                        </Box>
                                    </MenuItem>
                                    <MenuItem value="custom">
                                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                            <Box sx={{ width: 10 }} />
                                            Any
                                        </Box>
                                    </MenuItem>

                                </Select>
                                <Divider sx={{ height: 4 }} />

                                {hoverIndex === index && selectedListHeaders.length > 0 && (
                                    <>


                                        <CustomTooltip
                                            followCursor
                                            title="Map Uploaded File Headers to Chosen Existing List Headers"
                                            sx={{ zIndex: 2147483647 }}
                                        >

                                            <Typography variant="h6" gutterBottom>
                                                Add To Existing List Mapping
                                                <InfoSharpIcon />

                                            </Typography>

                                        </CustomTooltip>

                                        <FormControl fullWidth>

                                            <InputLabel id="demo-simple-select-label">{selectedListName ? `Mapping for: ${selectedListName}` : 'Select a list'}</InputLabel>

                                            <Select
                                                MenuProps={{
                                                    style: { zIndex: 2147483647 }
                                                }}
                                                label={selectedListName ? `Mapping for: ${selectedListName}` : 'Select a list'}
                                                fullWidth
                                                value={headerMappings[originalHeaders[index]] || ''}
                                                onChange={(e) => {
                                                    // Update headerMappings and headerTypeMappings here based on the selection
                                                    const newHeader = e.target.value;
                                                    const selectedDestinationHeaderName = e.target.value;
                                                    console.log("Selected destination header name:", selectedDestinationHeaderName); // Debugging line

                                                    const updatedHeaderMappings = {
                                                        ...headerMappings,
                                                        [originalHeaders[index]]: selectedDestinationHeaderName // Map original header to selected destination header
                                                    };
                                                    // Assume we have a function to get the type of the selected existing header
                                                    const headerType = getExistingHeaderType(newHeader, selectedListHeaders);
                                                    const updatedHeaderTypeMappings = { ...headerTypeMappings, [originalHeaders[index]]: headerType };

                                                    setHeaderMappings(updatedHeaderMappings);

                                                    // Create a new array with the updated header
                                                    const updatedEditableHeaders = [...editableHeaders];
                                                    updatedEditableHeaders[index] = selectedDestinationHeaderName;
                                                    setEditableHeaders(updatedEditableHeaders); // Update the editableHeaders state

                                                    setHeaderTypeMappings(updatedHeaderTypeMappings);
                                                }}
                                            >

                                                {selectedListHeaders.map((listHeader) => (

                                                    <MenuItem key={listHeader.fieldId} value={listHeader.name}>
                                                        {listHeader.name} ({listHeader.type})
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </>
                                )}



                            </Box>


                        ) : (
                            getIconForType(headerTypeMappings[originalHeaders[index]])
                        )}


                        {/* for specifying email amd phone types */}

                        {/* 

                        <Divider sx={{ height: 4 }} />

                        {
                            // Check if the current column is an email or phone column to conditionally render the next Select
                            ['email', 'phone'].includes(headerTypeMappings[originalHeaders[index]]) &&
                            !Object.values(emailTypesColumn).concat(Object.values(phoneTypesColumn)).includes(originalHeaders[index]) &&
                            hoverIndex === index && (

                                <>


                                    <CustomTooltip
                                        followCursor
                                        title="Optional: If your uploaded file contains a column indicating Email/Phone types (e.g., Professional/Personal), please choose it:"
                                        sx={{ zIndex: 2147483647 }}
                                    >

                                        <Typography variant="h6" gutterBottom>
                                            Email/Phone Type Column
                                            <InfoSharpIcon />
                                        </Typography>

                                    </CustomTooltip>


                                    <Select
                                        fullWidth
                                        value={headerTypeMappings[originalHeaders[index]] === 'email' ? emailTypesColumn[originalHeaders[index]] : phoneTypesColumn[originalHeaders[index]]}
                                        onChange={(e) => handleTypeColumnChange(originalHeaders[index], e.target.value, headerTypeMappings[originalHeaders[index]] === 'email')}
                                        variant='outlined'
                                        style={{ marginLeft: '10px' }} // Add some spacing between the two Selects
                                        MenuProps={{
                                            style: { zIndex: 2147483647 }
                                        }}
                                    >
                                        {originalHeaders.map((optionHeader, optionIndex) => (
                                            <MenuItem key={`type-option-${optionIndex}`} value={optionHeader}>
                                                {optionHeader}
                                            </MenuItem>
                                        ))}
                                    </Select>

                                </>
                            )
                        }
 */}


                    </ListItem>
                ))}
            </List>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={4000} // Adjust the duration as needed (in milliseconds)
                onClose={() => setSnackbarOpen(false)}
            >
                <MuiAlert
                    elevation={6}
                    variant="filled"
                    severity="error" // You can change the severity (error, warning, info, success) as needed
                    onClose={() => setSnackbarOpen(false)}
                    style={{ backgroundColor: 'var(--bs-warning)' }}
                >
                    {snackbarMessage}
                </MuiAlert>
            </Snackbar>

        </Box>
    );
};

export default ColumnSettings;