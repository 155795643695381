// CampaignStatusBar.tsx
import React, { useState, useEffect } from 'react';
import { Grid, IconButton, Typography, Button, CircularProgress, Box, useTheme } from '@mui/material';
import ChevronRightSharpIcon from '@mui/icons-material/ChevronRightSharp';
import { CircleSharp } from '@mui/icons-material';
import axios from 'axios';
import { useQuery, QueryClient, QueryClientProvider } from 'react-query';
import io from 'socket.io-client';
import { useAuth } from '../../../modules/auth';
import CustomTooltip from '../../../modules/CustomMaterialUiComponents/CustomTooltip';

const queryClient = new QueryClient();

const fetchCampaignStatus = async (selectedCampaignId) => {
    const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/campaign/status/${selectedCampaignId}`);
    return data;
};

interface CampaignStatusBarProps {
    selectedCampaignId: string | null;
}

const CampaignStatusBar: React.FC<CampaignStatusBarProps> = ({ selectedCampaignId }) => {
    const theme = useTheme();
    const [socketMessage, setSocketMessage] = useState('');
    const [socketError, setSocketError] = useState('');
    const [isToggling, setIsToggling] = useState(false);
    const [socketDisabled, setSocketDisabled] = useState(false);

    const { data: campaignStatusData, refetch } = useQuery(['campaignStatus', selectedCampaignId], () => fetchCampaignStatus(selectedCampaignId), {
        enabled: !!selectedCampaignId,
    });
    const campaignStatus = campaignStatusData?.status || 'Loading...';
    const { currentUser } = useAuth();
    const userId = currentUser?._id;

    useEffect(() => {
        //@ts-ignore
        const socket = io(process.env.REACT_APP_API_URL);

        if (selectedCampaignId && userId) {
            const roomDetails = { userId: userId, campaignId: selectedCampaignId };
            socket.emit('joinCampaignRoom', roomDetails);

            socket.on('campaignUpdate', (data) => {
                console.log('Socket Campaign Update:', data);
                setSocketMessage(data.message);
                setSocketError(data.error);

                const isProcessingMessage = data.message.includes("lead");
                setSocketDisabled(isProcessingMessage);

                refetch();
            });
        }

        return () => {
            if (selectedCampaignId && userId) {
                const roomDetails = { userId: userId, campaignId: selectedCampaignId };
                socket.emit('leaveCampaignRoom', roomDetails);
            }
            socket.disconnect();
        };
    }, [refetch, selectedCampaignId, userId]);

    useEffect(() => {
        setSocketMessage('');
        setSocketError('');
    }, [selectedCampaignId]);

    const toggleCampaignStatus = async () => {
        setIsToggling(true);
        try {
            await axios.put(`${process.env.REACT_APP_API_URL}/campaign/toggle-status/${selectedCampaignId}`);
            refetch();
        } catch (error) {
            console.error("Error toggling campaign status:", error);
        } finally {
            setIsToggling(false);
        }
    };

    if (selectedCampaignId === null) {
        return null; // Don't render anything if no campaign is selected
    }

    return (
        <QueryClientProvider client={queryClient}>
            <Box sx={{ borderRadius: "20px", padding: "2px 20px", marginTop: "0px", marginBottom: "0px", marginLeft: "60px", marginRight: "60px", display: 'flex', alignItems: 'center', justifyContent: 'space-between', background: theme.palette.background.paper, }}>
                <Grid container alignItems="center">


                    <Grid item xs={12}>
                        <CustomTooltip title={socketError || 'No additional details'}>
                            <Box display="flex" justifyContent="flex-end">
                                <Button size='large' variant='text' color="primary" onClick={toggleCampaignStatus} disabled={isToggling} style={{ marginBottom: 8 }}>
                                    <Box display="flex" alignItems="center" gap={1}>
                                        {campaignStatus === 'active' ? (
                                            <CircleSharp color='success' />
                                        ) : (
                                            <CircleSharp style={{ color: 'orange' }} />
                                        )}
                                        {isToggling ? (
                                            <>
                                                <CircularProgress size={24} />
                                                <Typography variant="body2">{socketMessage}</Typography>
                                            </>
                                        ) : socketMessage ? (
                                            <Typography variant="body2">{socketMessage}</Typography>
                                        ) : (
                                            <Typography variant="body2">
                                                Campaign {campaignStatus}
                                            </Typography>
                                        )}
                                    </Box>
                                </Button>
                            </Box>
                        </CustomTooltip>
                    </Grid>
                </Grid>
            </Box>
        </QueryClientProvider>
    );
};

export default CampaignStatusBar;