import { lazy, FC, Suspense } from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'
import { MasterLayout } from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import { DashboardWrapper } from '../pages/dashboard/DashboardWrapper'
import { getCSSVariableValue } from '../../_metronic/assets/ts/_utils'
import { WithChildren } from '../../_metronic/helpers'
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'
import { Somar } from "../pages/Somar"
import IntroPage from '../pages/intropage/IntroPage'
import ProfilePageParent from '../pages/0-Account/ProfilePage/ProfilePage'
import DashboardFamo from '../pages/1-DashBoard/DashboardPage'
import CampaignsPage from '../pages/4-Campaigns/CampaignsPage'
import AdvancedLeadGenPage from '../pages/2-GetLeads/AdvancedSearch/AdvancedLeadGenPage'
import LocalLeadGenPage from '../pages/2-GetLeads/LocalLeads/LocalLeadsPage'

import EmailAccountsPage from '../pages/0-Account/EmailAccounts/EmailAccountsPage'
import LeadsPage from '../pages/3-CRM/CRMPage'
import MailParnetPage from '../pages/5-Inbox/InboxPage'

const PrivateRoutes = () => {
  // const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  //const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  //const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  //const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  // const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  // const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}

        <Route path="/Somar" element={<Somar />} />
        {/*   <Route path="/intro" element={<IntroPage />} />  */}
        <Route path="/my-account" element={<ProfilePageParent />} />
        <Route path="/dashboard" element={<DashboardFamo />} />
        <Route path="/my-campaigns" element={<CampaignsPage />} />
        <Route path="/my-leads" element={<LeadsPage />} />
        <Route path="/advanced-lead-search" element={<AdvancedLeadGenPage />} />
        {/* <Route path="/local-lead-search" element={<LocalLeadGenPage />} /> */}

        <Route path="/email-accounts" element={<EmailAccountsPage />} />
        <Route path="/inbox" element={<MailParnetPage />} />


        {/* Lazy Modules */}
        {/* <Route
          path='crafted/pages/profile/*'
          element={
            <SuspensedView>
                    <ProfilePage />
            </SuspensedView>
          }
        /> */}


        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export { PrivateRoutes }
