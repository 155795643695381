// src\app\modules\auth\components\Registration.tsx
import React, { useState } from 'react';
import axios, { AxiosError } from 'axios';
import { Button, TextField, Box, Typography, Grid, Paper } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import SnackbarContent from '@mui/material/SnackbarContent';
import ReCAPTCHA from "react-google-recaptcha";
import PasswordValidator from "password-validator";
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Visibility from '@mui/icons-material/Visibility';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import CheckCircleOutline from '@mui/icons-material/CheckCircleOutline';
import CancelOutlined from '@mui/icons-material/CancelOutlined';
import ListItemText from '@mui/material/ListItemText';
import { Link } from 'react-router-dom';
import { motion, useAnimation, Variants } from "framer-motion";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import ArrowBackIosNewSharpIcon from '@mui/icons-material/ArrowBackIosNewSharp';
// ------
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Confetti from 'react-confetti';
import CloseSharpIcon from '@mui/icons-material/CloseSharp';


const MotionPaper = (props) => {
  const [isDragging, setIsDragging] = useState(false);

  const handleMouseDown = () => setIsDragging(true);
  const handleMouseUp = () => setIsDragging(false);

  return (
    <Paper
      {...props}
      component={motion.div}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      sx={{ cursor: isDragging ? 'grabbing' : 'grab' }}
    />
  );
};

const SuccessDialog = ({ open, onClose }) => {
  const dialogVariants = {
    hidden: { scale: 0.5, opacity: 0 },
    visible: { scale: 1, opacity: 1, transition: { type: 'spring', stiffness: 300 } }
  };

  return (
    <Dialog
      open={open}
      onBackdropClick={() => { }} // Add this line
      onClose={onClose}
      PaperComponent={MotionPaper}
      BackdropProps={{ style: { backgroundColor: 'transparent' } }}
      PaperProps={{
        variants: dialogVariants,
        initial: "hidden",
        animate: "visible",
        exit: "hidden",
        drag: true
      }}
      sx={{ width: 'auto', maxWidth: 'none', height: 'auto', maxHeight: 'none', zIndex: (theme) => theme.zIndex.modal + 1 }} // Adjust sizes as needed
    >
      <DialogTitle sx={{ fontSize: '2rem', color: 'primary.main', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <CheckCircleIcon sx={{ fontSize: '4rem', mr: 1 }} />
        Perfect !
      </DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ textAlign: 'center' }}>
          Check your email for a verification link.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{ position: 'absolute', right: 8, top: 8 }}
        >
          <CloseSharpIcon color='secondary' sx={{ background: "#fff", fontSize: "35px" }} />
        </IconButton>
      </DialogActions>
    </Dialog>
  );
};

const passwordSchema = new PasswordValidator();
passwordSchema
  .is()
  .min(8) // Minimum length 8
  .is()
  .max(100) // Maximum length 100
  .has()
  .uppercase() // Must have uppercase letters
  .has()
  .lowercase() // Must have lowercase letters
  .has()
  .digits() // Must have digits
  .not()
  .spaces(); // Should not have spaces

type RegistrationFormState = {
  firstname: string;
  lastname: string;
  email: string;
  password: string;
  passwordConfirmation: string;
  honeypot: string; // Add the honeypot field
  recaptchaResponse: string; // Add the reCAPTCHA response field
  registrationCode: string;  // Add this line
};

const RegistrationForm: React.FC = () => {

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [passwordErrors, setPasswordErrors] = useState<{ password?: string; passwordConfirmation?: string }>({});
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirmation, setShowPasswordConfirmation] = useState(false);
  const [successDialogOpen, setSuccessDialogOpen] = useState(false);
  const [passwordMeetsRequirements, setPasswordMeetsRequirements] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [loading, setLoading] = useState(false);
  const [passwordValidation, setPasswordValidation] = useState({
    length: false,
    uppercase: false,
    lowercase: false,
    digits: false,
    noSpaces: false,
    strength: 0, // Represents overall strength (0-100%)
  });

  const [formData, setFormData] = useState<RegistrationFormState>({
    firstname: '',
    lastname: '',
    email: '',
    password: '',
    passwordConfirmation: '',
    honeypot: '',
    recaptchaResponse: '',
    registrationCode: '',
  });

  const handleNextStep = () => {
    setCurrentStep((prevStep) => prevStep + 1);
  };

  const handlePrevStep = () => {
    setCurrentStep((prevStep) => prevStep - 1);
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      event.preventDefault(); // Prevent the default form submit action when on intermediary steps

      // If not on the last step, move to the next step. Otherwise, submit the form.
      if (currentStep < 4) {
        handleNextStep();
      } else {
        // Simulate form submission
        handleSubmit(event as unknown as React.FormEvent); // Adjust the type casting as necessary
      }
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const updatePasswordStrength = (password: string) => {
    const validation = { ...passwordValidation };
    let strength = 0;

    // Check each criterion
    validation.length = password.length >= 8;
    validation.uppercase = /[A-Z]/.test(password);
    validation.lowercase = /[a-z]/.test(password);
    validation.digits = /\d/.test(password);
    validation.noSpaces = !/\s/.test(password);

    // Calculate strength
    strength += validation.length ? 20 : 0;
    strength += validation.uppercase ? 20 : 0;
    strength += validation.lowercase ? 20 : 0;
    strength += validation.digits ? 20 : 0;
    strength += validation.noSpaces ? 20 : 0;

    validation.strength = strength;

    setPasswordValidation(validation);

    const meetsAllRequirements =
      validation.length &&
      validation.uppercase &&
      validation.lowercase &&
      validation.digits &&
      validation.noSpaces;

    setPasswordMeetsRequirements(meetsAllRequirements);
  };

  const handlePasswordValidation = (value: string) => {
    updatePasswordStrength(value);
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    if (formData.honeypot) {
      console.error("Bot detected (Honeypot triggered)");
      return;
    }
    setLoading(true); // Enable loading state

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/auth/register`, formData);
      console.log(response.data);
      setSuccessDialogOpen(true);
    } catch (error) {
      console.error(error);
      // Handle the error state
      if (axios.isAxiosError(error)) {
        const axiosError = error as AxiosError<any>;
        const errorMessage = axiosError.response?.data?.error || 'An error occurred';
        setSnackbarMessage(errorMessage);
        setSnackbarOpen(true);
      } else {
        setSnackbarMessage('An unexpected error occurred');
        setSnackbarOpen(true);
      }
    } finally {
      setLoading(false); // Disable loading state after request completion
    }
  };

  const handleRecaptchaChange = (value: string | null) => {
    // Store the reCAPTCHA response value in your form state
    if (value) {
      setFormData({ ...formData, recaptchaResponse: value });
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  // Generate random damping and stiffness values within specified ranges
  const minDamping = 15; // Minimum damping value
  const maxDamping = 20; // Maximum damping value
  const randomDamping = minDamping + Math.random() * (maxDamping - minDamping);

  const minStiffness = 100; // Minimum stiffness value
  const maxStiffness = 300; // Maximum stiffness value
  const randomStiffness = minStiffness + Math.random() * (maxStiffness - minStiffness);

  const animationVariants: Variants = {
    initial: { x: -100, opacity: 0, scale: 0.8, rotate: 0 },
    animate: {
      x: 0,
      opacity: 1,
      scale: 1,
      rotate: 0,
      transition: {
        type: "spring",
        stiffness: randomStiffness, // Use the randomly generated stiffness
        damping: randomDamping,     // Use the randomly generated damping
      },
    },
    exit: { x: 100, opacity: 0, scale: 0.8, rotate: 0 },
  };

  return (
    <Grid container style={{ height: '100vh', overflow: 'auto' }}>
      {successDialogOpen && (
        <div style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', zIndex: 999 }}>
          <Confetti width={window.innerWidth} height={window.innerHeight} />
        </div>
      )}
      <Grid item xs={12} sm={12} md={12} container alignItems="center" direction="column" justifyContent="center">
        <Paper
          sx={{
            backdropFilter: "blur(55px)",
            WebkitBackdropFilter: 'blur(55px)',
            bgcolor: "transparent",
            padding: "60px"
          }}
        >
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1, width: '100%', maxWidth: 400, mx: 'auto' }} onKeyDown={handleKeyDown} >
            {/* <Typography sx={{ textAlign: 'center', mb: 2, fontWeight: 'bold', color: 'secondary.main', fontSize: '2rem' }}>
              Welcome to
            </Typography> */}
            <Typography sx={{ textAlign: 'center', mb: 2, fontWeight: 'bold', color: 'primary.main', fontSize: '2.5rem' }}>
              Get a Client.ai!
            </Typography>
            <Typography sx={{ textAlign: 'center', mb: 3, fontWeight: 'bold', color: 'secondary.main', fontSize: '1.5rem' }}>
              Let's get you more clients.
            </Typography>

            {currentStep === 1 && (
              <motion.div
                className="no-global-transition"
                initial="initial"
                animate="animate"
                exit="exit"
                variants={animationVariants}
              >
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="firstname"
                  label="First Name"
                  value={formData.firstname}
                  name="firstname"
                  autoFocus
                  onChange={handleChange}
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="lastname"
                  value={formData.lastname}
                  label="Last Name"
                  name="lastname"
                  onChange={handleChange}
                />
                <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', mt: 2 }}>

                  <Button
                    sx={{
                      borderRadius: '20px', // matching rounded corners
                      minWidth: '100px', // consistent minimum width
                      boxShadow: '0 4px 12px rgba(0,0,0,0.15)' // matching soft shadow
                    }}
                    color="info"
                    variant="text"
                    endIcon={<ArrowForwardIosSharpIcon />}
                    onClick={handleNextStep}
                  >
                    Next
                  </Button>
                </Box>
              </motion.div>
            )}
            {currentStep === 2 && (
              <motion.div
                className="no-global-transition"
                initial="initial"
                animate="animate"
                exit="exit"
                variants={animationVariants}
              >
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  value={formData.email}
                  name="email"
                  autoComplete="email"
                  onChange={handleChange}
                />
                <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', mt: 2 }}>
                  <Button
                    sx={{
                      borderRadius: '20px', // more pronounced rounded corners
                      minWidth: '100px', // minimum width to keep buttons from being too wide
                      boxShadow: '0 4px 12px rgba(0,0,0,0.15)' // soft shadow for depth
                    }}
                    color="info"
                    variant="text"
                    startIcon={<ArrowBackIosNewSharpIcon />}
                    onClick={handlePrevStep}
                  >
                    Back
                  </Button>
                  <Button
                    sx={{
                      borderRadius: '20px', // matching rounded corners
                      minWidth: '100px', // consistent minimum width
                      boxShadow: '0 4px 12px rgba(0,0,0,0.15)' // matching soft shadow
                    }}
                    color="info"
                    variant="text"
                    endIcon={<ArrowForwardIosSharpIcon />}
                    onClick={handleNextStep}
                  >
                    Next
                  </Button>
                </Box>
              </motion.div>
            )}
            {currentStep === 3 && (
              <motion.div
                className="no-global-transition"
                initial="initial"
                animate="animate"
                exit="exit"
                variants={animationVariants}
              >
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="registrationCode"
                  label="Registration Code"
                  name="registrationCode"
                  value={formData.registrationCode}
                  onChange={handleChange}
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  value={formData.password}
                  type={showPassword ? 'text' : 'password'}
                  id="password"
                  autoComplete="current-password"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    handleChange(e);
                    handlePasswordValidation(e.target.value);
                  }}
                  error={!!passwordErrors.password}
                  helperText={passwordErrors.password}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setShowPassword(!showPassword)}
                          edge="end"
                        />
                      </InputAdornment>
                    ),
                  }}
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="passwordConfirmation"
                  value={formData.passwordConfirmation}
                  label="Confirm Password"
                  type={showPasswordConfirmation ? 'text' : 'password'}
                  id="password-confirmation"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    handleChange(e);
                    handlePasswordValidation(e.target.value);
                  }}
                  error={!!passwordErrors.passwordConfirmation}
                  helperText={passwordErrors.passwordConfirmation}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {formData.passwordConfirmation && formData.password === formData.passwordConfirmation ? (
                          <CheckCircleOutline color="success" />
                        ) : null}
                        <IconButton
                          aria-label="toggle password confirmation visibility"
                          onClick={() => setShowPasswordConfirmation(!showPasswordConfirmation)}
                          edge="end"
                        >
                          {showPasswordConfirmation ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                {/* Password strength indicators */}
                {formData.password && !passwordMeetsRequirements && (
                  <>
                    <Box sx={{ my: 2 }}>
                      <Typography variant="subtitle1" gutterBottom>
                        Password Requirements:
                      </Typography>
                      <List dense>
                        {Object.keys(passwordValidation).map(key => {
                          if (key !== 'strength') {
                            return (
                              <ListItem key={key} disableGutters>
                                <ListItemIcon>
                                  {passwordValidation[key] ? <CheckCircleOutline color="success" /> : <CancelOutlined color="error" />}
                                </ListItemIcon>
                                <ListItemText primary={key.charAt(0).toUpperCase() + key.slice(1).replace(/[A-Z]/g, ' $&')} />
                              </ListItem>
                            );
                          }
                          return null;
                        })}
                      </List>
                    </Box>
                  </>
                )}
                <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', mt: 2 }}>
                  <Button
                    sx={{
                      borderRadius: '20px', // more pronounced rounded corners
                      minWidth: '100px', // minimum width to keep buttons from being too wide
                      boxShadow: '0 4px 12px rgba(0,0,0,0.15)' // soft shadow for depth
                    }}
                    color="info"
                    variant="text"
                    startIcon={<ArrowBackIosNewSharpIcon />}
                    onClick={handlePrevStep}
                  >
                    Back
                  </Button>
                  <Button
                    sx={{
                      borderRadius: '20px', // matching rounded corners
                      minWidth: '100px', // consistent minimum width
                      boxShadow: '0 4px 12px rgba(0,0,0,0.15)' // matching soft shadow
                    }}
                    color="info"
                    variant="text"
                    endIcon={<ArrowForwardIosSharpIcon />}
                    onClick={handleNextStep}
                  >
                    Next
                  </Button>
                </Box>
                {/* Strength Bar */}
                <Box sx={{ width: '100%', bgcolor: 'background.paper', borderRadius: 1, overflow: 'hidden' }}>
                  <Box
                    sx={{
                      width: `${passwordValidation.strength}%`,
                      bgcolor: passwordValidation.strength > 75 ? 'success.main' : passwordValidation.strength > 50 ? 'warning.main' : 'error.main',
                      height: '10px',
                    }}
                  />
                </Box>
              </motion.div>
            )}
            {currentStep === 4 && (
              <motion.div
                className="no-global-transition"
                initial="initial"
                animate="animate"
                exit="exit"
                variants={animationVariants}
              >
                <Button
                  variant='contained'
                  type="submit"
                  color='secondary'
                  sx={{ fontSize: "35px", color: "white" }}
                  disabled={loading}
                >
                  {loading ? 'Creating Account...' : 'Create My Account'}
                </Button>
                {/* <ReCAPTCHA
                sitekey="6LcmJGEpAAAAAKiK4b_w5oDxX2GWKzUrkFK3m6Nq"
                onChange={handleRecaptchaChange}
              /> */}
                <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', mt: 2 }}>
                  <Button
                    sx={{
                      borderRadius: '20px', // more pronounced rounded corners
                      minWidth: '100px', // minimum width to keep buttons from being too wide
                      boxShadow: '0 4px 12px rgba(0,0,0,0.15)' // soft shadow for depth
                    }}
                    color="info"
                    variant="text"
                    startIcon={<ArrowBackIosNewSharpIcon />}
                    onClick={handlePrevStep}
                  >
                    Back
                  </Button>

                </Box>
              </motion.div>
            )}
            <Link to='/auth/login' className='link-primary'>
              <Button fullWidth sx={{ borderRadius: "0px" }} color='info' variant='text' ><CloseSharpIcon /></Button>
            </Link>
            <Snackbar
              open={snackbarOpen}
              autoHideDuration={6000}
              onClose={handleCloseSnackbar}
            >
              <SnackbarContent
                message={snackbarMessage}
                action={
                  <Button color="secondary" size="small" onClick={handleCloseSnackbar}>
                    Close
                  </Button>
                }
              />
            </Snackbar>
            <SuccessDialog open={successDialogOpen} onClose={() => setSuccessDialogOpen(false)} />
          </Box>
        </Paper>
      </Grid>
    </Grid >
  );
};

export default RegistrationForm;
