// src\app\modules\auth\core\AuthHelpers.ts
import axios from 'axios'
import {AuthModel} from './_models'
import {refreshToken as refreshAuthToken} from './_requests' // Ensure this is correctly implemented
import {Logout} from '../Logout'

const AUTH_LOCAL_STORAGE_KEY = 'kt-auth-react-v'
const getAuth = (): AuthModel | undefined => {
  if (!localStorage) {
    return
  }

  const lsValue: string | null = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY)
  if (!lsValue) {
    return
  }

  try {
    const auth: AuthModel = JSON.parse(lsValue) as AuthModel
    if (auth) {
      // You can easily check auth_token expiration also
      return auth
    }
  } catch (error) {
    console.error('AUTH LOCAL STORAGE PARSE ERROR', error)
  }
}

const setAuth = (auth: AuthModel) => {
  if (!localStorage) {
    return
  }

  try {
    const lsValue = JSON.stringify(auth)
    localStorage.setItem(AUTH_LOCAL_STORAGE_KEY, lsValue)
  } catch (error) {
    console.error('AUTH LOCAL STORAGE SAVE ERROR', error)
  }
}

const removeAuth = () => {
  if (!localStorage) {
    return
  }

  try {
    localStorage.removeItem(AUTH_LOCAL_STORAGE_KEY)
  } catch (error) {
    console.error('AUTH LOCAL STORAGE REMOVE ERROR', error)
  }
}

const setupAxios = (axiosInstance: typeof axios) => {
  axiosInstance.defaults.headers.Accept = 'application/json'

  let isRefreshing = false
  let failedQueue = []

  const processQueue = (error, token = null) => {
    failedQueue.forEach((prom) => {
      if (error) {
        //@ts-ignore
        prom.reject(error)
      } else {
        //@ts-ignore
        prom.resolve(token)
      }
    })

    failedQueue = []
  }

  axiosInstance.interceptors.response.use(
    (response) => response, // On success, just return the response
    async (error) => {
      const originalRequest = error.config
      if (error.response?.status === 457) {
        // Emit a custom event for logout
        const event = new CustomEvent('requireLogout', {
          detail: {message: 'Refresh token is invalid or not provided'},
        })
        window.dispatchEvent(event)
        return Promise.reject(error)
      } else if (
        error.response?.status === 401 ||
        (error.response?.status === 403 && !originalRequest._retry)
      ) {
        if (isRefreshing) {
          return new Promise(function (resolve, reject) {
            //@ts-ignore
            failedQueue.push({resolve, reject})
          })
            .then((token) => {
              originalRequest.headers['Authorization'] = 'Bearer ' + token
              return axiosInstance(originalRequest)
            })
            .catch((err) => {
              return Promise.reject(err)
            })
        }

        originalRequest._retry = true
        isRefreshing = true

        return new Promise((resolve, reject) => {
          refreshAuthToken()
            //@ts-ignore
            .then(({accessToken}) => {
              axiosInstance.defaults.headers.common['Authorization'] = 'Bearer ' + accessToken
              originalRequest.headers['Authorization'] = 'Bearer ' + accessToken
              processQueue(null, accessToken)
              resolve(axiosInstance(originalRequest))
            })
            .catch((refreshError) => {
              processQueue(refreshError, null)
              reject(refreshError)
            })
            .finally(() => {
              isRefreshing = false
            })
        })
      }

      return Promise.reject(error)
    }
  )
}

export {getAuth, setAuth, removeAuth, setupAxios, AUTH_LOCAL_STORAGE_KEY}
