// MultipleSMTP_IMAPForm.tsx
import CloseSharpIcon from '@mui/icons-material/CloseSharp';
import DeleteSharpIcon from '@mui/icons-material/DeleteSharp';
import EditSharpIcon from '@mui/icons-material/EditSharp';
import SaveSharpIcon from '@mui/icons-material/SaveSharp';
import { Alert, Box, Button, CircularProgress, Snackbar } from '@mui/material';
import { DataGridPremium, GridActionsCellItem, GridEventListener, GridRowModes } from '@mui/x-data-grid-premium';
import axios from 'axios';
import React, { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import FileUploadDialog from './multiconnectupload/UploadSMTPIMAP';


interface SnackbarMessage {
    key: number;
    message: string;
    severity: 'success' | 'error';
}

interface AccountRow {
    id: string;
    email: string;
    firstName: string;
    lastName: string;
    smtpHost: string;
    smtpPort: string;
    smtpPassword: string;
    imapHost: string;
    imapPort: string;
    imapPassword: string;
    isNew?: boolean; // Add this line
}


// Initial empty row example, adhering to the AccountRow interface
const emptyAccountRow: AccountRow = {
    id: uuidv4(),
    email: '',
    firstName: '',
    lastName: '',
    smtpHost: '',
    smtpPort: '',
    smtpPassword: '',
    imapHost: '',
    imapPort: '',
    imapPassword: '',
};

const createEmptyAccountRow = (): AccountRow => ({
    id: uuidv4(), // Unique ID for each row
    email: '',
    firstName: '',
    lastName: '',
    smtpHost: '',
    smtpPort: '',
    smtpPassword: '',
    imapHost: '',
    imapPort: '',
    imapPassword: '',
    isNew: true, // Assuming you want to mark new rows for potential special handling
});

const MultipleSMTP_IMAPForm = () => {
    // Use the interface to type our rows state
    const [rows, setRows] = useState<AccountRow[]>(Array.from({ length: 1 }, createEmptyAccountRow));
    const [loadingIds, setLoadingIds] = useState<string[]>([]);
    const [connectionStatus, setConnectionStatus] = useState<{ [key: string]: 'success' | 'failure' }>({});
    const [rowModesModel, setRowModesModel] = useState<Record<string, { mode: GridRowModes }>>({});
    const [snackbars, setSnackbars] = useState<SnackbarMessage[]>([]);
    const [uploadDialogOpen, setUploadDialogOpen] = useState(false);
    const [isConnectingAll, setIsConnectingAll] = useState(false);


    const columns = [
        { field: 'email', headerName: 'Email', width: 200, editable: true },
        { field: 'firstName', headerName: 'First Name', width: 150, editable: true },
        { field: 'lastName', headerName: 'Last Name', width: 150, editable: true },
        { field: 'smtpHost', headerName: 'SMTP Host', width: 150, editable: true },
        {
            field: 'smtpPort',
            headerName: 'SMTP Port',
            width: 110,
            editable: true,
            // Removed the renderEditCell method
        },
        { field: 'smtpPassword', headerName: 'SMTP Password', width: 150, editable: true, type: 'password' },
        { field: 'imapHost', headerName: 'IMAP Host', width: 150, editable: true },
        {
            field: 'imapPort',
            headerName: 'IMAP Port',
            width: 110,
            editable: true,
            // Removed the renderEditCell method
        },
        { field: 'imapPassword', headerName: 'IMAP Password', width: 150, editable: true, type: 'password' },
        {
            field: 'actions',
            type: 'actions',
            headerName: 'Actions',
            width: 100,
            getActions: (params) => {
                const isInEditMode = rowModesModel[params.id]?.mode === GridRowModes.Edit;
                if (isInEditMode) {
                    return [
                        <GridActionsCellItem
                            icon={<SaveSharpIcon color='secondary' sx={{ fontSize: "35px" }} />}
                            label="Save"
                            onClick={() => handleSaveClick(params.id)}
                        />,
                        <GridActionsCellItem
                            icon={<CloseSharpIcon color='info' sx={{ fontSize: "35px" }} />}
                            label="Cancel"
                            onClick={() => handleCancelClick(params.id)}
                        />,
                    ];
                }

                return [
                    <GridActionsCellItem
                        icon={<EditSharpIcon color='info' sx={{ fontSize: "35px" }} />}
                        label="Edit"
                        onClick={() => handleEditClick(params.id)}
                    />,
                    <GridActionsCellItem
                        icon={<DeleteSharpIcon color='info' sx={{ fontSize: "35px" }} />}
                        label="Delete"
                        onClick={() => handleDeleteClick(params.id)}
                    />,
                ];
            },
        },
        {
            field: 'connect',
            headerName: 'Connect',
            width: 130,
            renderCell: (params) => {
                const loading = loadingIds.includes(params.id);
                const connectionStatusIndicator = connectionStatus[params.id];
                let color = 'info'; // Default color
                if (connectionStatusIndicator === 'success') color = 'success';
                if (connectionStatusIndicator === 'failure') color = 'error';

                return (
                    <Button
                        variant="text"
                        //@ts-ignore
                        color={color}
                        size="small"
                        disabled={loading}
                        onClick={() => handleConnect(params.id)}
                        startIcon={loading ? <CircularProgress size={24} /> : undefined}
                    >
                        {loading ? 'Connecting...' : 'Connect'}
                    </Button>
                );
            },
        },

    ];

    const handleConnect = async (id: string) => {
        setLoadingIds(prev => [...prev, id]);

        const account = rows.find(row => row.id === id);
        if (!account) {
            console.error('Account not found');
            return;
        }

        const requestData = {
            sendingAccount: account.email,
            firstName: account.firstName,
            lastName: account.lastName,
            smtp: {
                host: account.smtpHost,
                port: parseInt(account.smtpPort), // Ensure port is an integer
                password: account.smtpPassword,
            },
            imap: {
                host: account.imapHost,
                port: parseInt(account.imapPort), // Ensure port is an integer
                password: account.imapPassword,
            },
        };

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/account/emailaccounts/connect`, requestData);
            const message = response.data.message || "Connection successful"; // Use the actual success message from the response
            enqueueSnackbar(message, 'success');
            setConnectionStatus(prev => ({ ...prev, [id]: 'success' }));
        } catch (error) {
            let errorMessage = "Connection failed"; // Default error message
            if (axios.isAxiosError(error) && error.response) {
                // Check if the error is an Axios error and has a response with data
                errorMessage = error.response.data.error || error.response.data.message || errorMessage;
            } else if (error instanceof Error) {
                // Fallback for non-Axios errors
                errorMessage = error.message;
            }
            enqueueSnackbar(errorMessage, 'error');
            setConnectionStatus(prev => ({ ...prev, [id]: 'failure' }));
        } finally {
            setLoadingIds(prev => prev.filter(loadingId => loadingId !== id));
        }
    };

    const handleConnectAll = async () => {
        setIsConnectingAll(true); // Start connecting
        for (const row of rows) {
            await handleConnect(row.id);
        }
        setIsConnectingAll(false); // Finished connecting
    };



    const processRowUpdate = (newRow: AccountRow) => {
        const updatedRows = rows.map((row) => (row.id === newRow.id ? { ...row, ...newRow, isNew: false } : row));
        setRows(updatedRows);
        // Optionally, send the update to the backend here
        return newRow;
    };
    const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
        // Your logic here, for example:
        if ((params.reason as string) === 'cellFocusOut') {
            event.defaultMuiPrevented = true;
        }
    };

    const handleEditClick = (id: string) => {
        setRowModesModel(prev => ({
            ...prev,
            [id]: { mode: GridRowModes.Edit },
        }));
    };

    const handleSaveClick = (id: string) => {
        // Logic to save the row's data, then:
        setRowModesModel(prev => ({
            ...prev,
            [id]: { mode: GridRowModes.View },
        }));
    };


    const handleCancelClick = (id: string) => {
        // Revert any changes by not updating the row, just switch back to view mode
        setRowModesModel(prev => ({
            ...prev,
            [id]: { mode: GridRowModes.View },
        }));

    };

    const handleDeleteClick = (id: string) => {
        // Optionally, add logic here to confirm deletion with the user

        // Remove the row from the state
        setRows(rows => rows.filter(row => row.id !== id));

        // Optionally, send a delete request to the backend
        // axios.delete(`${process.env.REACT_APP_API_URL}/account/emailaccounts/${id}`);
    };


    const enqueueSnackbar = (message: string, severity: 'success' | 'error') => {
        setSnackbars((prevSnackbars) => [
            ...prevSnackbars,
            { key: new Date().getTime(), message, severity },
        ]);
    };

    const removeSnackbar = (key: number) => {
        setSnackbars((prevSnackbars) => prevSnackbars.filter((snackbar) => snackbar.key !== key));
    };


    // Function to handle the dialog opening
    const handleOpenUploadDialog = () => setUploadDialogOpen(true);

    // Function to handle the dialog closing
    const handleCloseUploadDialog = () => setUploadDialogOpen(false);

    // Function to handle importing data from the dialog
    const handleImport = (importedData) => {
        // Transform and set the data as needed
        setRows([...rows, ...importedData.map(data => ({ ...data, id: uuidv4() }))]);
        handleCloseUploadDialog();
    };



    return (
        <Box sx={{ height: 600, width: '100%' }}>
            <Button color='info' onClick={handleOpenUploadDialog}>Upload CSV/XLSX</Button>
            <FileUploadDialog open={uploadDialogOpen} onClose={handleCloseUploadDialog} onImport={handleImport} />

            <Button
                variant="text"
                color='info'
                onClick={() =>
                    setRows((prevRows) => [
                        ...prevRows,
                        { id: uuidv4(), email: '', firstName: '', lastName: '', smtpHost: '', smtpPort: '', smtpPassword: '', imapHost: '', imapPort: '', imapPassword: '' },
                    ])
                }
            >
                Add
            </Button>
            <Button variant="text" color='info' onClick={handleConnectAll} disabled={isConnectingAll}>
                {isConnectingAll ? 'Connecting...' : 'Connect All'}
            </Button>

            <DataGridPremium
                rows={rows}
                columns={columns}
                //@ts-ignore    
                pageSize={5}
                rowsPerPageOptions={[5]}
                disableSelectionOnClick
                processRowUpdate={processRowUpdate}
                rowModesModel={rowModesModel}
                onRowModesModelChange={(newModel) => setRowModesModel(newModel)}
                hideFooter
                disableAggregation
                disableColumnReorder
                disableColumnFilter
                disableColumnMenu
                sx={{
                    height: "550px",
                    border: "none"
                }}
                onRowEditStop={handleRowEditStop}
                initialState={{ pinnedColumns: { left: ["email"], right: ['actions', "connect"] } }}
                slotProps={{
                    toolbar: { setRows },
                }}
            />

            {snackbars.map((snackbar) => (
                <Snackbar
                    key={snackbar.key}
                    open={true}
                    autoHideDuration={6000}
                    onClose={() => removeSnackbar(snackbar.key)}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                >
                    <Alert onClose={() => removeSnackbar(snackbar.key)} severity={snackbar.severity} sx={{ width: '100%' }}>
                        {snackbar.message}
                    </Alert>
                </Snackbar>
            ))}

        </Box>
    );
};

export default MultipleSMTP_IMAPForm;


