// src\app\pages\WarmupV2\Warmup\DataView\Chart\ChartSkeleton.tsx
import React from 'react';
import { Box, Skeleton } from '@mui/material';

const ChartSkeleton: React.FC = () => {
    const numberOfBars = 25;
    const maxBarHeight = 250;
    const barWidth = 20;

    // Function to generate heights with a gradual trend
    const generateHeights = () => {
        let heights: number[] = [];
        let currentHeight = 50; // Starting height for the first bar
        const increment = (maxBarHeight - currentHeight) / 10; // Increment for the first 10 bars

        for (let i = 0; i < numberOfBars; i++) {
            if (i < 10) {
                heights.push(currentHeight);
                currentHeight += increment;
            } else {
                const randomFluctuation = 0.05 * currentHeight;
                const randomizedHeight = currentHeight + (Math.random() - 0.5) * 2 * randomFluctuation;
                heights.push(randomizedHeight);
            }
        }
        return heights;
    };

    const barHeights = generateHeights();

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                p: 2,
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    gap: 0.5,
                    mt: 2,
                }}
            >
                {barHeights.map((height, index) => (
                    // Create a container for each skeleton with a fixed height
                    <Box key={index} sx={{ width: barWidth, height: maxBarHeight, display: 'flex', alignItems: 'flex-end' }}>
                        <Skeleton variant="rectangular" width={barWidth} height={height} />
                    </Box>
                ))}
            </Box>
            <Skeleton variant="text" width="100%" height={30} sx={{ mt: 2 }} />
        </Box>
    );
};

export default ChartSkeleton;
