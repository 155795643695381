import React from 'react';
import Messagesforreply from './reply/messagesfromreplysection';
// Opening of your component
const Reply: React.FC = () => {
  return (

    <div className="content flex-row-fluid" id="kt_content">
      {/*begin::Inbox App - View & Reply */}
      <div className="d-flex flex-column flex-lg-row">
        {/*begin::Content*/}
        <div className="flex-lg-row-fluid ms-lg-7 ms-xl-10">
          {/*begin::Card*/}
          <div className="card">
            <div className="card-header align-items-center py-5 gap-5">
              {/*begin::Actions*/}
              <div className="d-flex">
                {/*begin::Back*/}
                <a
                  href="../../demo2/dist/apps/inbox/listing.html"
                  className="btn btn-sm btn-icon btn-clear btn-active-light-primary me-3"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="Back"
                >
                  <i className="ki-duotone ki-arrow-left fs-1 m-0">
                    <span className="path1" />
                    <span className="path2" />
                  </i>
                </a>
                {/*end::Back*/}
                {/*begin::Archive*/}
                <a
                  href="#"
                  className="btn btn-sm btn-icon btn-light btn-active-light-primary me-2"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="Archive"
                >
                  <i className="ki-duotone ki-sms fs-2 m-0">
                    <span className="path1" />
                    <span className="path2" />
                  </i>
                </a>
                {/*end::Archive*/}
                {/*begin::Spam*/}
                <a
                  href="#"
                  className="btn btn-sm btn-icon btn-light btn-active-light-primary me-2"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="Spam"
                >
                  <i className="ki-duotone ki-information fs-2 m-0">
                    <span className="path1" />
                    <span className="path2" />
                    <span className="path3" />
                  </i>
                </a>
                {/*end::Spam*/}
                {/*begin::Delete*/}
                <a
                  href="#"
                  className="btn btn-sm btn-icon btn-light btn-active-light-primary me-2"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="Delete"
                >
                  <i className="ki-duotone ki-trash fs-2 m-0">
                    <span className="path1" />
                    <span className="path2" />
                    <span className="path3" />
                    <span className="path4" />
                    <span className="path5" />
                  </i>
                </a>
                {/*end::Delete*/}
                {/*begin::Mark as read*/}
                <a
                  href="#"
                  className="btn btn-sm btn-icon btn-light btn-active-light-primary me-2"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="Mark as read"
                >
                  <i className="ki-duotone ki-copy fs-2 m-0" />
                </a>
                {/*end::Mark as read*/}
                {/*begin::Move*/}
                <a
                  href="#"
                  className="btn btn-sm btn-icon btn-light btn-active-light-primary"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="Move"
                >
                  <i className="ki-duotone ki-entrance-left fs-2 m-0">
                    <span className="path1" />
                    <span className="path2" />
                  </i>
                </a>
                {/*end::Move*/}
              </div>
              {/*end::Actions*/}
              {/*begin::Pagination*/}
              <div className="d-flex align-items-center">
                {/*begin::Pages info*/}
                <span className="fw-semibold text-muted me-2">
                  1 - 50 of 235
                </span>
                {/*end::Pages info*/}
                {/*begin::Prev page*/}
                <a
                  href="#"
                  className="btn btn-sm btn-icon btn-light btn-active-light-primary me-3"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="Previous message"
                >
                  <i className="ki-duotone ki-left fs-2 m-0" />
                </a>
                {/*end::Prev page*/}
                {/*begin::Next page*/}
                <a
                  href="#"
                  className="btn btn-sm btn-icon btn-light btn-active-light-primary me-2"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="Next message"
                >
                  <i className="ki-duotone ki-right fs-2 m-0" />
                </a>
                {/*end::Next page*/}
                {/*begin::Settings menu*/}
                <div>
                  <a
                    href="#"
                    className="btn btn-sm btn-icon btn-light btn-active-light-primary me-2"
                    data-kt-menu-trigger="click"
                    data-kt-menu-placement="bottom-end"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="Settings"
                  >
                    <i className="ki-duotone ki-dots-square fs-2 m-0">
                      <span className="path1" />
                      <span className="path2" />
                      <span className="path3" />
                      <span className="path4" />
                    </i>
                  </a>
                  {/*begin::Menu*/}
                  <div
                    className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-250px py-4"
                    data-kt-menu="true"
                  >
                    {/*begin::Menu item*/}
                    <div className="menu-item px-3">
                      <a href="#" className="menu-link px-3">
                        <i className="ki-duotone ki-element-11 fs-3 me-3">
                          <span className="path1" />
                          <span className="path2" />
                          <span className="path3" />
                          <span className="path4" />
                        </i>
                        New Group
                      </a>
                    </div>
                    {/*end::Menu item*/}
                    {/*begin::Menu item*/}
                    <div className="menu-item px-3">
                      <a href="#" className="menu-link px-3">
                        <i className="ki-duotone ki-badge fs-3 me-3">
                          <span className="path1" />
                          <span className="path2" />
                          <span className="path3" />
                          <span className="path4" />
                          <span className="path5" />
                        </i>
                        Contacts
                      </a>
                    </div>
                    {/*end::Menu item*/}
                    {/*begin::Menu item*/}
                    <div className="menu-item px-3">
                      <a href="#" className="menu-link px-3">
                        <i className="ki-duotone ki-people fs-3 me-3">
                          <span className="path1" />
                          <span className="path2" />
                          <span className="path3" />
                          <span className="path4" />
                          <span className="path5" />
                        </i>
                        Groups
                        <span className="badge badge-light-primary ms-auto">
                          new
                        </span>
                      </a>
                    </div>
                    {/*end::Menu item*/}
                    {/*begin::Menu item*/}
                    <div className="menu-item px-3">
                      <a href="#" className="menu-link px-3">
                        <i className="ki-duotone ki-element-2 fs-3 me-3">
                          <span className="path1" />
                          <span className="path2" />
                        </i>
                        Calls
                      </a>
                    </div>
                    {/*end::Menu item*/}
                    {/*begin::Menu item*/}
                    <div className="menu-item px-3">
                      <a href="#" className="menu-link px-3">
                        <i className="ki-duotone ki-setting-2 fs-3 me-3">
                          <span className="path1" />
                          <span className="path2" />
                        </i>
                        Settings
                      </a>
                    </div>
                    {/*end::Menu item*/}
                    <div className="separator my-5" />
                    {/*begin::Menu item*/}
                    <div className="menu-item px-3">
                      <a href="#" className="menu-link px-3">
                        <i className="ki-duotone ki-magnifier fs-3 me-3">
                          <span className="path1" />
                          <span className="path2" />
                        </i>
                        Help
                      </a>
                    </div>
                    {/*end::Menu item*/}
                    {/*begin::Menu item*/}
                    <div className="menu-item px-3">
                      <a href="#" className="menu-link px-3">
                        <i className="ki-duotone ki-shield-tick fs-3 me-3">
                          <span className="path1" />
                          <span className="path2" />
                        </i>
                        Privacy
                        <span className="badge badge-light-danger ms-auto">
                          5
                        </span>
                      </a>
                    </div>
                    {/*end::Menu item*/}
                  </div>
                  {/*end::Menu*/}
                </div>
                {/*begin::Settings menu*/}
                {/*begin::Toggle*/}
                <a
                  href="#"
                  className="btn btn-sm btn-icon btn-color-primary btn-light btn-active-light-primary d-lg-none"
                  data-bs-toggle="tooltip"
                  data-bs-dismiss="click"
                  data-bs-placement="top"
                  title="Toggle inbox menu"
                  id="kt_inbox_aside_toggle"
                >
                  <i className="ki-duotone ki-burger-menu-2 fs-3 m-0">
                    <span className="path1" />
                    <span className="path2" />
                    <span className="path3" />
                    <span className="path4" />
                    <span className="path5" />
                    <span className="path6" />
                    <span className="path7" />
                    <span className="path8" />
                    <span className="path9" />
                    <span className="path10" />
                  </i>
                </a>
                {/*end::Toggle*/}
              </div>
              {/*end::Pagination*/}
            </div>
            <div className="card-body">
              {/*begin::Title*/}
              <div className="d-flex flex-wrap gap-2 justify-content-between mb-8">
                <div className="d-flex align-items-center flex-wrap gap-2">
                  {/*begin::Heading*/}
                  <h2 className="fw-semibold me-3 my-1">
                    Trip Reminder. Thank you for flying with us!
                  </h2>
                  {/*begin::Heading*/}
                  {/*begin::Badges*/}
                  <span className="badge badge-light-primary my-1 me-2">
                    inbox
                  </span>
                  <span className="badge badge-light-danger my-1">
                    important
                  </span>
                  {/*end::Badges*/}
                </div>
                <div className="d-flex">
                  {/*begin::Sort*/}
                  <a
                    href="#"
                    className="btn btn-sm btn-icon btn-light btn-active-light-primary me-2"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="Sort"
                  >
                    <i className="ki-duotone ki-arrow-up-down fs-2 m-0">
                      <span className="path1" />
                      <span className="path2" />
                    </i>
                  </a>
                  {/*end::Sort*/}
                  {/*begin::Print*/}
                  <a
                    href="#"
                    className="btn btn-sm btn-icon btn-light btn-active-light-primary me-2"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="Print"
                  >
                    <i className="ki-duotone ki-printer fs-2">
                      <span className="path1" />
                      <span className="path2" />
                      <span className="path3" />
                      <span className="path4" />
                      <span className="path5" />
                    </i>
                  </a>
                  {/*end::Print*/}
                </div>
              </div>
              {/*end::Title*/}
              {/*begin::Message accordion*/}
              <Messagesforreply />
              {/*end::Message accordion*/}
            </div>
          </div>
          {/*end::Card*/}
        </div>
        {/*end::Content*/}
      </div>
      {/*end::Inbox App - View & Reply */}
    </div>
  );
};

export default Reply;  