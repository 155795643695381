// src\app\pages\4-Campaigns\3-CampaignSequence\SequenceComponents\SequenceEditingArea.tsx
import { AddCircleSharp, DeleteOutlineSharp } from '@mui/icons-material';
import MouseSharpIcon from '@mui/icons-material/MouseSharp';
import SettingsSharpIcon from '@mui/icons-material/SettingsSharp';
import { Divider, Grid, IconButton, Tab, Tabs, TextField } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';

import DraggableDialog from '../../../../modules/CustomMaterialUiComponents/DraggableDialog';
import SaveButton from '../../../../modules/CustomMaterialUiComponents/SaveButton';
import { PersonalizationHeader } from '../SequenceSteps';
import { Step, Variation } from '../StepInterface';
import VariationSettings from '../VariationSettings';
import LexicalEditorWrapper from './NewEditor/components/LexicalEditorWrapper/LexicalEditorWrapper';

interface SequenceEditingAreaProps {
    steps: Step[];
    selectedStep: Step | null;
    setSelectedStep: (step: Step) => void;
    handleSubjectChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    handleBodyChange: (newBody: string) => void;
    localSubject: string;
    localBody: string;
    isLoading: boolean;
    isError: boolean;
    addStep: (afterStepId: string | null) => Promise<void>;
    selectStepById: (stepId: string) => void;
    saveChanges: () => void;
    onUpdate: any;
    setLocalSubject: React.Dispatch<React.SetStateAction<string>>;
    setLocalBody: React.Dispatch<React.SetStateAction<string>>;
    variations: Variation[];
    setVariations: React.Dispatch<React.SetStateAction<Variation[]>>;
    selectedCampaignId: string | null;
    handleDeleteVariation: (variationId: string) => void;
    handleAddVariation: () => void;
    personalizationHeaders: PersonalizationHeader[];
    activeTab: number;
    setActiveTab: React.Dispatch<React.SetStateAction<number>>;

}

interface NoStepSelectedPlaceholderProps {
    steps: Step[];
    addStep: (afterStepId: string | null) => Promise<void>;
    selectStepById: (stepId: string) => void;
}


const NoStepSelectedPlaceholder: React.FC<NoStepSelectedPlaceholderProps> = ({ steps, addStep, selectStepById }) => (
    <Grid
        style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '20px',
            cursor: 'pointer',
            height: 'auto',
            marginTop: "30px"
        }}
        sx={{ '&:hover': { opacity: 0.7 } }}

        onClick={async () => {
            if (steps.length === 0) {
                await addStep(null);
                if (steps.length > 0) {
                    selectStepById(steps[0]._id);
                }
            } else {
                selectStepById(steps[0]._id);
            }
        }}
    >
        <MouseSharpIcon
            sx={{
                fontSize: "240px",
                padding: "15px",
                borderRadius: "50%",
                color: "#c9c9c9",
                transition: "1s",
                "&:hover": {
                    fontSize: "320px",
                }
            }}

        />
    </Grid>
);


const SequenceEditingArea: React.FC<SequenceEditingAreaProps> = ({
    steps,
    selectedStep,
    setSelectedStep,
    handleSubjectChange,
    handleBodyChange,
    localSubject,
    localBody,
    isLoading,
    isError,
    addStep,
    selectStepById,
    saveChanges,
    onUpdate,
    setLocalBody,
    setLocalSubject,
    variations,
    setVariations,
    selectedCampaignId,
    handleDeleteVariation,
    handleAddVariation,
    personalizationHeaders,
    activeTab,
    setActiveTab,
}) => {


    const [isVariationSettingsOpen, setIsVariationSettingsOpen] = React.useState(false);
    const [activeVariationContent, setActiveVariationContent] = useState('');
    const [selectedVariationId, setSelectedVariationId] = useState<string | null>(null);


    const handleVariationPercentageChange = (value: number[], index: number) => {
        // Implement logic to update variation percentages based on the slider value
        // This logic will depend on how you manage state for variations and percentages
    };


    useEffect(() => {
        console.log("Selected step changed:", selectedStep);
        setActiveTab(0); // Reset to default tab when a new step is selected
    }, [selectedStep]);


    useEffect(() => {
        console.log("Active Tab Changed:", activeTab);
        console.log("Selected step changed:", selectedStep);
        console.log("Variations:", variations);

        if (activeTab > 0 && variations[activeTab - 1]) {
            console.log("Setting Active Variation Content for Tab:", activeTab);
            setActiveVariationContent(variations[activeTab - 1].body);
        } else if (activeTab === 0 && selectedStep) {
            console.log("Resetting to Default Body Content");
            setActiveVariationContent(selectedStep.body || '');
        }
    }, [activeTab, variations, selectedStep]);

    //savechanges on variation tab change
    useEffect(() => {
        saveChanges();
    }, [activeTab]);

    useEffect(() => {
        if (selectedStep) {
            setLocalSubject(selectedStep.subject || '');
            setLocalBody(selectedStep.body || '');
        }
        if (selectedStep && selectedStep.variations) {
            setVariations(selectedStep.variations);
        } else {
            setVariations([]);
        }
    }, [selectedStep]);


    const handleEditorContentChange = (newContent: string) => {
        console.log("Editor content changed for tab", activeTab, ":", newContent);
        if (activeTab === 0) {
            setLocalBody(newContent);
        } else {
            setActiveVariationContent(newContent);
            // Update the variation in the variations state without triggering a re-render of multiple editors
            handleVariationChange(activeTab - 1, 'body', newContent);
        }
    };

    // Function to handle changes in variations
    const handleVariationChange = useCallback((variationIndex: number, field: 'subject' | 'body', value: string) => {
        setVariations((currentVariations) => {
            const newVariations = [...currentVariations];
            if (newVariations[variationIndex]) {
                newVariations[variationIndex] = {
                    ...newVariations[variationIndex],
                    [field]: value,
                };
            }
            return newVariations;
        });
    }, []);

    const ids = variations.map(variation => variation._id);
    const uniqueIds = new Set(ids);
    console.log("ids:", ids.length, "unique ids:", uniqueIds.size); // If these numbers differ, there's a duplicate

    const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        console.log("Changing active tab from", activeTab, "to", newValue);
        setActiveTab(newValue);
        // Adjust this logic based on how your variations and tabs are structured
        const newSelectedVariationId = newValue > 0 ? variations[newValue - 1]._id : null;
        setSelectedVariationId(newSelectedVariationId);
    };


    const debouncedHandleBodyChange = useCallback(debounce(handleBodyChange, 300), [handleBodyChange]);
    const contentKey = selectedStep ? `${selectedStep._id}-${activeTab}` : "default-key";
    console.log("Current contentKey:", contentKey);


    return (
        <Grid container spacing={2} justifyContent="center">
            {selectedStep ? (
                <Grid style={{ padding: '10px', height: '100%', width: "100%" }}>
                    {selectedStep && selectedStep.variationsEnabled && variations.length > 0 && (
                        <>

                            <Grid item xs={12} container spacing={2}>
                                <Grid item xs={10}>

                                    <Tabs
                                        value={activeTab}
                                        onChange={handleTabChange}
                                        aria-label="Variation Tabs"
                                        variant="scrollable"
                                        scrollButtons
                                    >
                                        <Tab label="Default" />
                                        {variations.map((variation, index) => (
                                            <Tab
                                                key={variation._id}
                                                label={
                                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                                        {variation.label}

                                                        <IconButton size="small" onClick={() => handleDeleteVariation(variation._id)}>
                                                            <DeleteOutlineSharp />
                                                        </IconButton>
                                                    </div>
                                                }
                                            />
                                        ))}
                                    </Tabs>
                                    <Divider />

                                </Grid>
                                <Grid item xs={2} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <IconButton color="primary" onClick={() => handleAddVariation()}>
                                        <AddCircleSharp />
                                    </IconButton>
                                    <IconButton color="primary" onClick={() => setIsVariationSettingsOpen(true)}>
                                        <SettingsSharpIcon />
                                    </IconButton>
                                </Grid>
                                {/* DraggableDialog for VariationSettings */}
                                <DraggableDialog
                                    open={isVariationSettingsOpen}
                                    onClose={() => setIsVariationSettingsOpen(false)}
                                    content={
                                        <VariationSettings
                                            defaultPercentage={selectedStep?.defaultPercentage || 0}
                                            variations={variations}
                                            onChange={handleVariationPercentageChange}
                                        />
                                    }
                                    title="Variation Settings"
                                    height={100}
                                    width={200}
                                    showCloseWarning
                                    closeWarningMessage='Are You Sure?'
                                />

                            </Grid>

                        </>

                    )}

                    <Grid container spacing={2}>
                        {selectedStep.type !== 'sms' && (
                            <Grid item xs={10}>
                                <TextField
                                    label="Subject"
                                    value={localSubject}
                                    onChange={handleSubjectChange}
                                    variant="outlined"
                                    fullWidth
                                    margin="dense"
                                    style={activeTab === 0 ? {} : { display: 'none' }}
                                />
                                {variations.map((variation, index) => (
                                    <div key={variation._id} style={activeTab === index + 1 ? {} : { display: 'none' }}>
                                        <Grid container spacing={2}>
                                            {variation.type !== 'sms' && (
                                                <Grid item xs={12}>
                                                    <TextField
                                                        label="Subject"
                                                        value={variation.subject || ''}
                                                        onChange={(e) => handleVariationChange(index, 'subject', e.target.value)}
                                                        fullWidth
                                                        variant="outlined"
                                                    />
                                                </Grid>
                                            )}
                                        </Grid>
                                    </div>
                                ))}
                            </Grid>
                        )}
                        <Grid item xs={2} sx={{ display: 'flex', justifyContent: "flex-end" }}>
                            <SaveButton
                                onClick={saveChanges}
                                loading={isLoading}
                                success={!isLoading && !isError && !!onUpdate}
                                minimumLoadingTime={0}
                                successDisplayTime={2000}
                                size={45}
                            />
                        </Grid>
                    </Grid>



                    <div>
                        <LexicalEditorWrapper
                            initialContent={activeTab === 0 ? localBody : variations[activeTab - 1]?.body}
                            onContentChange={handleEditorContentChange}
                            contentKey={contentKey}
                            emailType={selectedStep.type === 'sms' ? 'plain' : (selectedStep.emailType || 'plain')}
                            personalizationHeaders={personalizationHeaders}
                        />
                    </div>


                    {/* {variations.map((variation, index) => (
                        <div key={variation._id} style={activeTab === index + 1 ? {} : { display: 'none' }}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    {variation.emailType === 'html' ? (
                                        <LexicalEditorWrapper
                                            initialContent={variation.body}
                                            onContentChange={(newBody) => handleVariationChange(index, 'body', newBody)}
                                            contentKey={contentKey}
                                            emailType={selectedStep?.emailType}
                                            personalizationHeaders={personalizationHeaders}
                                        />
                                    ) : (
                                        <TextField
                                            label="Message"
                                            value={variation.body}
                                            onChange={(e) => handleVariationChange(index, 'body', e.target.value)}
                                            fullWidth
                                            rows={10}
                                            multiline
                                            variant="outlined"
                                        />
                                    )}
                                </Grid>
                            </Grid>
                        </div>
                    ))} */}
                </Grid>
            ) : (

                <NoStepSelectedPlaceholder
                    steps={steps}
                    addStep={addStep}
                    selectStepById={selectStepById}
                />
            )}
        </Grid>
    );

};

export default SequenceEditingArea;

// utils/debounce.ts
export function debounce<T extends (...args: any[]) => void>(func: T, wait: number): (...args: Parameters<T>) => void {
    let timeout: NodeJS.Timeout;
    return function executedFunction(...args: Parameters<T>) {
        const later = () => {
            clearTimeout(timeout);
            func(...args);
        };

        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
    };
}
