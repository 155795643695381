// src/app/modules/auth/components/VerificationFailurePage.tsx
import React, { useContext, useEffect } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { Button, Typography, Container } from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { AuthContext } from '../core/Auth';

const VerificationFailurePage = () => {
    const [searchParams] = useSearchParams();
    const reason = searchParams.get('reason');
    const isSubaccount = searchParams.get('subaccount') === 'true';

    const getMessage = (reason, isSubaccount) => {
        switch (reason) {
            case 'intentNotFound':
                return isSubaccount
                    ? 'The subaccount registration intent not found. It may have expired or is invalid.'
                    : 'Registration intent not found. It may have expired or is invalid.';
            case 'missingLimits':
                return 'Allocated limits are required for subaccount creation.';
            case 'error':
                return isSubaccount
                    ? 'There was an error processing your verification. Please try again later.'
                    : 'There was an error processing your verification. Please try again later.';
            default:
                return 'An unknown error occurred during verification.';
        }
    };

    return (
        <Container
            component='main'
            maxWidth='xs'
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                marginTop: 8,
                textAlign: 'center',
            }}
        >
            <ErrorOutlineIcon
                sx={{
                    fontSize: '64px',
                    color: (theme) => theme.palette.error.main,
                    marginBottom: 2,
                }}
            />
            <Typography
                variant='h1'
                sx={{
                    fontSize: '32px',
                    fontWeight: 'bold',
                    marginBottom: 2,
                }}
            >
                Verification Failed
            </Typography>
            <Typography
                variant='body1'
                sx={{
                    fontSize: '18px',
                    color: (theme) => theme.palette.text.secondary,
                    marginBottom: 4,
                }}
            >
                {getMessage(reason, isSubaccount)}
            </Typography>
            <Link to={isSubaccount ? '/dashboard' : '/auth/registration'} style={{ textDecoration: 'none' }}>
                <Button
                    variant='contained'
                    color='primary'
                    size='large'
                    sx={{
                        fontSize: '20px',
                    }}
                >
                    {isSubaccount ? 'Go to Dashboard' : 'Try Again'}
                </Button>
            </Link>
        </Container>
    );
};

export default VerificationFailurePage;
