export default [
    {
      'id': 0,
      'handle': 'note',
      'title': 'PayPal',
      'color': 'purple lighten-3'
    },
    {
      'id': 1,
      'handle': 'paypal',
      'title': 'Upwork',
      'color': 'amber darken-2'
    },
    {
      'id': 2,
      'handle': 'invoice',
      'title': 'In-house',
      'color': 'green darken-1'
    },
    {
      'id': 3,
      'handle': 'amazon',
      'title': 'Clients',
      'color': 'light-blue darken-2'
    }
  ];