import React from 'react';
import { SnackbarProvider, useSnackbar } from 'notistack';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import WifiOffIcon from '@mui/icons-material/WifiOff';
import WifiIcon from '@mui/icons-material/Wifi';
import { useTheme } from '@mui/material';
import AutorenewSharpIcon from '@mui/icons-material/AutorenewSharp';

function MyApp() {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [snackbarId, setSnackbarId] = React.useState(null);
    const theme = useTheme(); // Access the theme to use MUI's secondary main color

    // Function to check connection manually
    const checkConnection = () => {
        // Example: Fetch a small data endpoint or just ping your server
        fetch('https://api.ipify.org?format=json')
            .then(response => {
                if (response.ok) {
                    handleOnline(); // If the API call succeeds, we assume we are online
                }
            })
            .catch(error => console.error('Connection check failed:', error));
    };

    // Function to notify about the loss of connection
    const handleOffline = () => {
        const id = enqueueSnackbar('No connection! Retry?', {
            persist: true,
            style: {
                backgroundColor: theme.palette.error.main,
                borderRadius: "0px",
                fontSize: "25px",
            },
            action: key => (
                <Button onClick={checkConnection} variant='text'>
                    <AutorenewSharpIcon sx={{ fontSize: "35px", color: "white" }} />
                </Button>
            ),
            anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'center',
            },
        });
        //@ts-ignore
        setSnackbarId(id);
    };

    // Function to notify about the restoration of connection
    const handleOnline = () => {
        if (snackbarId) {
            closeSnackbar(snackbarId); // Close the specific snackbar by id
        } else {
            closeSnackbar(); // Fallback to close all snackbars if no id is tracked
        }
        enqueueSnackbar('Back online!', {
            variant: 'success',
            autoHideDuration: 3000,
            style: {
                backgroundColor: theme.palette.success.main,
                borderRadius: "0px",
                fontSize: "25px",
            },
            anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'center',
            },
        });
    };

    React.useEffect(() => {
        // Set event listeners
        window.addEventListener('offline', handleOffline);
        window.addEventListener('online', handleOnline);

        // Return a cleanup function
        return () => {
            window.removeEventListener('offline', handleOffline);
            window.removeEventListener('online', handleOnline);
        };
    }, [snackbarId]); // Dependency on snackbarId ensures closure uses the latest id

    // Return JSX content
    return (
        <></>);
}

export default function ConnectionLostTracking() {
    return (
        <SnackbarProvider maxSnack={3}>
            <MyApp />
        </SnackbarProvider>
    );
}
