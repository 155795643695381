import { useTheme } from '@mui/material';
import { grey } from '@mui/material/colors';
import React from 'react';

const MenuBarDivider = ({ }) => {

    const theme = useTheme();

    const style = {
        height: '0.5px',
        backgroundColor: theme.palette.mode === 'dark' ? grey[800] : grey[200], // Use grey[400] for dark mode, grey[200] for light mode
        margin: '10px 0',
        color: "",
        border: 'none',
        borderRadius: '0px', // Optional: Rounds the corners slightly for a softer look
        // For a gradient instead of a solid color, uncomment the next line:
        // backgroundImage: 'linear-gradient(90deg, rgba(255,0,0,1) 0%, rgba(255,154,0,1) 50%, rgba(208,222,33,1) 100%)',
    };

    return (
        <div style={style} />
    );
};

export default MenuBarDivider;
