import React, { useEffect, useRef } from 'react';
import { Box, Button, Select, MenuItem, styled, Tooltip } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import LastPageIcon from '@mui/icons-material/LastPage';
import SettingsIcon from '@mui/icons-material/Settings';
import { motion } from 'framer-motion';

const ToolbarContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '20px',
    width: '100%',
    maxWidth: '100%',
}));

const Toolbar = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: theme.palette.background.paper,
    padding: '3px 16px',
    borderRadius: '28px',
    boxShadow: '0 4px 20px rgba(0, 0, 0, 0.08)',
    transition: 'all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1)',
    width: '100%',
    '&:hover': {
        boxShadow: '0 8px 30px rgba(0, 0, 0, 0.12)',
    },
}));

const ToolbarButton = styled(motion.div)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    border: 'none',
    borderRadius: '50%',
    width: '40px',
    height: '40px',
    margin: '0 4px',
    cursor: 'pointer',
    position: 'relative',
    overflow: 'visible',
    '&::before': {
        content: '""',
        position: 'absolute',
        top: '50%',
        left: '50%',
        width: '0',
        height: '0',
        backgroundColor: theme.palette.action.hover,
        borderRadius: '50%',
        transform: 'translate(-50%, -50%) scale(0)',
        transition: 'transform 0.3s ease',
    },
    '&:hover::before': {
        transform: 'translate(-50%, -50%) scale(1)',
    },
    '& svg': {
        width: '20px',
        height: '20px',
        fill: theme.palette.text.secondary,
        zIndex: 1,
    },
}));

interface PaginationToolbarProps {
    onPrevious: () => void;
    onNext: () => void;
    onFirstPage: () => void;
    onLastPage: () => void;
    onSettings: () => void;
    currentPage: number;  // Add this
    totalPages: number;   // Add this
    perPage: number;      // Add this
    totalItems: number;   // Add this
    onChangePerPage: (perPage: number) => void; // Add this
}

const PaginationToolbar: React.FC<PaginationToolbarProps> = ({
    onPrevious,
    onNext,
    onFirstPage,
    onLastPage,
    onSettings,
    currentPage,
    totalPages,
    perPage,
    totalItems,
    onChangePerPage,
}) => {
    const toolbarRef = useRef<HTMLDivElement>(null);

    return (
        <ToolbarContainer>
            <Toolbar role="toolbar" aria-label="Pagination controls">
                <Box display="flex" alignItems="center">
                    <Tooltip followCursor title="Previous page" arrow>
                        <ToolbarButton
                            whileHover={{ scale: 1.1 }}
                            whileTap={{ scale: 0.9 }}
                            onClick={onPrevious}
                            aria-label="Previous page"
                        >
                            <ArrowBackIcon />
                        </ToolbarButton>
                    </Tooltip>
                    <Box display="flex" alignItems="center" className="pagination-info">
                        <span>
                            {/* Calculate and display the range */}
                            {((currentPage - 1) * perPage + 1)}-
                            {Math.min(currentPage * perPage, totalItems)} of {totalItems}
                        </span>
                        <Select
                            size='small'
                            className="pagination-select"
                            aria-label="Items per page"
                            value={perPage} // Bind value to perPage prop
                            onChange={(e) => onChangePerPage(Number(e.target.value))} // Call onChangePerPage
                            variant='standard'
                            sx={{
                                minWidth: 60, // Adjust width as needed
                                '& .MuiSelect-select': {
                                    padding: '4px 8px', // Adjust padding for smaller size
                                    fontSize: '0.75rem', // Smaller font size
                                },
                                '& .MuiInputBase-root': {
                                    padding: '4px', // Adjust root padding for compactness
                                }
                            }}
                        >
                            <MenuItem value={25}>25 per page</MenuItem>
                            <MenuItem value={50}>50 per page</MenuItem>
                            <MenuItem value={100}>100 per page</MenuItem>
                        </Select>
                    </Box>
                    <Tooltip followCursor title="Next page" arrow>
                        <ToolbarButton
                            whileHover={{ scale: 1.1 }}
                            whileTap={{ scale: 0.9 }}
                            onClick={onNext}
                            aria-label="Next page"
                        >
                            <ArrowForwardIcon />
                        </ToolbarButton>
                    </Tooltip>
                </Box>
                <Box display="flex" alignItems="center">
                    <Tooltip followCursor title="First page" arrow>
                        <ToolbarButton
                            whileHover={{ scale: 1.1 }}
                            whileTap={{ scale: 0.9 }}
                            onClick={onFirstPage}
                            aria-label="First page"
                        >
                            <FirstPageIcon />
                        </ToolbarButton>
                    </Tooltip>
                    <Tooltip followCursor title="Last page" arrow>
                        <ToolbarButton
                            whileHover={{ scale: 1.1 }}
                            whileTap={{ scale: 0.9 }}
                            onClick={onLastPage}
                            aria-label="Last page"
                        >
                            <LastPageIcon />
                        </ToolbarButton>
                    </Tooltip>
                    <Box width="1px" height="24px" sx={{ backgroundColor: '#e0e0e0', mx: 1, opacity: 0.6 }} />
                    <Tooltip followCursor title="Settings" arrow>
                        <ToolbarButton
                            whileHover={{ scale: 1.1 }}
                            whileTap={{ scale: 0.9 }}
                            onClick={onSettings}
                            aria-label="Settings"
                        >
                            <SettingsIcon />
                        </ToolbarButton>
                    </Tooltip>
                </Box>
            </Toolbar>
        </ToolbarContainer>

    );
};

export default PaginationToolbar;
