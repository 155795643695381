import React from 'react';
import lables from '../../util/labels';
import { Checkbox, IconButton } from '@mui/material';

import './MailListItemStyles.css'; // Import the CSS file

const MailListItem = ({ mail, onMailSelect, onMailChecked, onStartSelect }) => {
  return (
    <div className="module-list-item mail-cell">
      <Checkbox
        className="mail-checkbox"
        checked={mail.selected}
        onClick={(event) => {
          event.stopPropagation();
          onMailChecked(mail);
        }}
        value="SelectMail"
      />
      <IconButton
        className="mail-star-button"
        type="button"
        onClick={() => {
          onStartSelect(mail);
        }}
      >
        {mail.starred ? (
          <i className="zmdi zmdi-star" />
        ) : (
          <i className="zmdi zmdi-star-outline" />
        )}
      </IconButton>
      <div className={`mail-user-info ${mail.from.avatar === '' ? 'bg-blue' : ''}`}>
        {mail.from.avatar === '' ? (
          <div className="avatar rounded-circle size-40 text-white text-center">
            {mail.from.name.charAt(0).toUpperCase()}
          </div>
        ) : (
          <img alt="Avatar" src={mail.from.avatar} className="rounded-circle avatar size-40" />
        )}
      </div>
      <div className="module-list-info" onClick={() => onMailSelect(mail)}>
        <div className="module-list-content">
          <div className="mail-user-info">
            <span className="sender-name text-dark">
              {mail.from.name}
            </span>
            <span className="toolbar-separator" />
            <span className="text-truncate text-dark">
              {mail.subject}
            </span>
            {mail.hasAttachments && <i className="zmdi zmdi-attachment" />}
            <div className="time">{mail.time}</div>
          </div>
          <div className="message mb-2">
            <p> {mail.message}</p>
          </div>
          <div className="labels">
            {lables.map((label, index) => {
              return mail.labels.includes(label.id) ? (
                <div
                  key={index}
                  className={`badge text-white bg-${label.color}`}
                >
                  {label.title}
                </div>
              ) : null;
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MailListItem;
