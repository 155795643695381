import React, { useEffect, useState } from 'react';
import { styled, useTheme, Box, Typography, TypographyProps, Button, Skeleton, Alert } from '@mui/material';
import DraggableDialog from '@/src/app/modules/CustomMaterialUiComponents/DraggableDialog';
import UploadCSV2 from '../../../3-CRM/UploadBox/UploadBox';
import axios from 'axios';
import AdvancedLeadGenParent from '../../../2-GetLeads/AdvancedSearch/Parent-LeadGenAdvanced';
import CampaignLeadsConnectExistingList from '../CampaignLeadsConnectExistingList';
import CreateNewList from './campaignnewlist';

// Define CSS variables for theming
const primaryColor = '#793A99';
const secondaryColor = '#F79010';
const bgLight = '#ebebeb';
const bgDark = '#252525';
const textLight = '#333';
const textDark = '#f0f0f0';
const boxBgLight = 'rgba(255, 255, 255, 0.8)';
const boxBgDark = 'rgba(37, 37, 37, 0.8)';

const document = '/campaign/document.svg';
const folder = '/campaign/folder.svg';
const magnifier = '/campaign/magnifier.svg';

const Container = styled(Box)({
    textAlign: 'center',
    maxWidth: 1200,
    padding: '2rem',
    position: 'relative',
});

const H1 = styled(Typography)({
    fontFamily: "'Playfair Display', serif",
    fontSize: '3.2rem',
    fontWeight: 700,
    marginBottom: '1rem',
    animation: 'fadeIn 1s ease-out forwards',
    color: primaryColor,
});

const P = styled(Typography)({
    fontSize: '1.3rem',
    marginBottom: '3rem',
    animation: 'fadeIn 1s ease-out 0.5s forwards',
});

const ButtonContainer = styled(Box)({
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))', // Use auto-fit for responsive columns
    gap: '2rem',
    justifyContent: 'center', // Center the items horizontally
    maxWidth: 1200,
    margin: '0 auto',
    transform: 'skew(-5deg)',
    '@media (max-width: 768px)': {
        gridTemplateColumns: '1fr', // Stack columns vertically on smaller screens
    },
});

const StyledBox = styled(Box)({
    width: '100%',
    height: 400,
    border: `3px dashed #666`,
    borderRadius: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    transition: 'all 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275)',
    // backdropFilter: 'blur(10px)',
    animation: 'fadeIn 1s ease-out 1s forwards, float 6s ease-in-out infinite',
    overflow: 'hidden',
    position: 'relative',
    transform: 'skew(5deg)',
    '&::before': {
        content: "''",
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        background: "#fff",
        opacity: 0,
        transition: 'opacity 0.3s ease',
        zIndex: -1,
    },
    '&:hover': {
        transform: 'skew(5deg) translateY(-15px) scale(1.05)',
        boxShadow: `0 20px 30px rgba(121, 58, 153, 0.3)`,
        borderStyle: 'solid',
    },
    '&:hover::before': {
        opacity: 0.1,
    },
    '&:hover .box-image': {
        transform: 'scale(1.1) rotate(3deg)',
    },
    '&:hover .box-content': {
        backgroundColor: `rgba(121, 58, 153, 0.9)`,
    },
    '&:hover .box-title, &:hover .box-description': {
        color: 'white', // Ensure the text turns white on hover
    },
    '@media (max-width: 768px)': {
        height: 350,
    },
});


const BoxImage = styled(Box)({
    width: '100%',
    height: '60%',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    transition: 'transform 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275)',
});

const BoxContent = styled(Box)(({ theme }) => ({
    width: '100%',
    height: '40%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    transition: 'background-color 0.5s ease, color 0.5s ease',

    // This block handles the hover state
    '&:hover': {
        color: theme.palette.mode === 'light' ? 'white' : textDark,
    },
}));

const BoxTitle = styled((props: TypographyProps) => (
    <Typography {...props} className="box-title" />
))({
    fontFamily: "'Playfair Display', serif",
    fontSize: '1.6rem',
    fontWeight: 700,
    marginBottom: '0.5rem',
    transition: 'color 0.5s ease', // Smooth transition for color change
});

const BoxDescription = styled((props: TypographyProps) => (
    <Typography {...props} className="box-description" />
))({
    fontSize: '1rem',
    padding: '0 1rem',
    transition: 'color 0.5s ease', // Smooth transition for color change
});





const Bubble = styled(Box)({
    position: 'absolute',
    backgroundColor: primaryColor,
    color: 'white',
    padding: '10px 15px',
    borderRadius: 0,
    fontSize: '0.9rem',
    fontWeight: 'bold',
    opacity: 0,
    transition: 'opacity 0.3s ease, transform 0.3s ease',
    pointerEvents: 'none',
    zIndex: 10,
    '&::after': {
        content: "''",
        position: 'absolute',
        width: 0,
        height: 0,
        borderStyle: 'solid',
    },
});

const BubbleLeft = styled(Bubble)({
    right: 'calc(100% + 20px)',
    top: '50%',
    transform: 'translateY(-50%)',
    '&::after': {
        left: '100%',
        top: '50%',
        transform: 'translateY(-50%)',
        borderWidth: '10px 0 10px 10px',
        borderColor: `transparent transparent transparent ${primaryColor}`,
    },
});

const BubbleTop = styled(Bubble)({
    bottom: 'calc(100% + 20px)',
    left: '50%',
    transform: 'translateX(-50%)',
    '&::after': {
        top: '100%',
        left: '50%',
        transform: 'translateX(-50%)',
        borderWidth: '10px 10px 0 10px',
        borderColor: `${primaryColor} transparent transparent transparent`,
    },
});

const BubbleRight = styled(Bubble)({
    left: 'calc(100% + 20px)',
    top: '50%',
    transform: 'translateY(-50%)',
    '&::after': {
        right: '100%',
        top: '50%',
        transform: 'translateY(-50%)',
        borderWidth: '10px 10px 10px 0',
        borderColor: `transparent ${primaryColor} transparent transparent`,
    },
});

const StatusBar = styled(Box)({
    top: 0,
    left: 0,
    width: '100%',
    backgroundColor: primaryColor,
    color: 'white',
    padding: '15px',
    fontSize: '1rem',
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    opacity: 0,
    transform: 'translateY(-100%)',
    transition: 'opacity 0.5s ease, transform 0.5s ease',
    zIndex: 999,
    boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
    '&.visible': {
        opacity: 1,
        transform: 'translateY(0)',
    },
});

const StatusItem = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '0 10px',
    borderRight: '1px solid rgba(255, 255, 255, 0.3)',
    '&:last-child': {
        borderRight: 'none',
    },
});

const StatusLabel = styled(Typography)({
    fontWeight: 'bold',
    marginBottom: '5px',
    textTransform: 'uppercase',
    fontSize: '0.8rem',
    letterSpacing: '1px',
});

const StatusValue = styled(Typography)({
    fontSize: '1.2rem',
    fontWeight: 600,
});
const Screen = styled(Box)(({ theme }) => ({
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    opacity: 0,
    pointerEvents: 'none',
    transition: 'opacity 0.5s ease, transform 0.5s ease',
    transform: 'scale(0.9)',
    overflow: 'auto', // Ensure overflow is handled
    '&.active': {
        opacity: 1,
        pointerEvents: 'all',
        transform: 'scale(1)',
    },
}));

const ScreenContent = styled(Box)({
    maxWidth: '90%', // Adjust the maxWidth to fit the content
    maxHeight: '85%', // Adjust the maxHeight to fit the content
    overflow: 'auto', // Ensure overflow is handled
    textAlign: 'center',
    opacity: 1,
    transform: 'translateY(20px)',
    transition: 'opacity 0.5s ease 0.2s, transform 0.5s ease 0.2s',
    '&.active > &': {
        opacity: 1,
        transform: 'translateY(0)',
    },
});


const BackButton = styled(Button)({
    position: 'fixed',
    top: 80,
    left: 20,
    background: primaryColor,
    color: 'white',
    border: 'none',
    borderRadius: 0,
    padding: '10px 20px',
    fontSize: '1rem',
    cursor: 'pointer',
    transition: 'background-color 0.3s ease, transform 0.3s ease',
    zIndex: 1001,
    '&:hover': {
        background: secondaryColor,
        transform: 'scale(1.05)',
    },
});

interface CampaignLeadsProps {
    selectedCampaignId: string | null;
}

const CampaignLeads: React.FC<CampaignLeadsProps> = ({ selectedCampaignId }) => {
    const theme = useTheme();
    const [activeScreen, setActiveScreen] = useState<string | null>(null);
    const [listAssigned, setListAssigned] = useState<boolean | null>(null);
    const [isLoading, setIsLoading] = useState(true); // Add a loading state
    const [errorMessage, setErrorMessage] = useState<string | null>(null); // Add error message state
    const [listStatus, setListStatus] = useState({
        name: '',
        leads: 0,
        emails: 0,
        phones: 0,
    });

    const [uploadDialogOpen, setUploadDialogOpen] = useState(false);
    const [findDialogOpen, setFindDialogOpen] = useState(false);
    const [existingDialogOpen, setExistingDialogOpen] = useState(false);
    const [newListDialogOpen, setNewListDialogOpen] = useState(false);


    const fetchCampaignListInfo = async () => {
        setIsLoading(true);
        setErrorMessage(null);

        try {
            const response = await axios.get(
                `${process.env.REACT_APP_API_URL}/campaign/${selectedCampaignId}/list-info`
            );

            const data = response.data;
            setListStatus({
                name: data.listName || '',
                leads: data.leads || 0,
                emails: data.emails || 0,
                phones: data.phoneNumbers || 0,
            });
            setListAssigned(true);
        } catch (error: any) {
            console.error('Error fetching campaign leads:', error);
            if (error.response && error.response.data && error.response.data.message) {
                const errorMessage = error.response.data.message;
                if (errorMessage === "Campaign not found.") {
                    setErrorMessage("Campaign not found. Please refresh or reselect the campaign.");
                } else if (errorMessage === "Campaign not connected to a list.") {
                    setErrorMessage(null);
                    setListAssigned(false);
                } else if (errorMessage === "List not found.") {
                    setErrorMessage("List not found. Please refresh or reselect the campaign.");
                } else {
                    setErrorMessage("An error occurred. Please try again later.");
                }
            } else {
                // Handle cases where the error doesn't have a response.data.message
                setErrorMessage("An error occurred. Please try again later.");
            }
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (selectedCampaignId) {
            fetchCampaignListInfo();
        } else {
            setErrorMessage("Please select a campaign.");
        }
    }, [selectedCampaignId]);

    useEffect(() => {
        if (selectedCampaignId) {
            fetchCampaignListInfo();
        } else {
            setErrorMessage("Please select a campaign.");
        }
    }, [selectedCampaignId]);

    const handleBoxClick = (screenName: string) => {
        switch (screenName) {
            case 'upload':
                setUploadDialogOpen(true);
                break;
            case 'find':
                setFindDialogOpen(true);
                break;
            case 'existing':
                setExistingDialogOpen(true);
                break;
            case 'newlist':
                setNewListDialogOpen(true);
                break;
        }
    };

    const handleBackClick = () => {
        setActiveScreen(null);
    };

    const handleListCreated = () => {
        setNewListDialogOpen(false);
        fetchCampaignListInfo(); // Reuse the fetching logic
    };


    const isDarkMode = theme.palette.mode === 'dark';

    return (
        <Box sx={{
            margin: 0,
            padding: 0,
            fontFamily: "'Montserrat', sans-serif",
            height: '100%',
            overflowX: 'hidden',
            transition: 'background-color 0.3s ease, color 0.3s ease',
            display: 'flex',
            justifyContent: 'center',
        }}>
            <Container>
                {errorMessage && (
                    <Alert severity="error" sx={{ mb: 2 }}>{errorMessage}</Alert>
                )}

                {!listAssigned && !isLoading && (
                    <Alert severity="info" sx={{ mb: 2 }}>
                        This campaign is not yet connected to a list. Please choose an option below to connect a list.
                    </Alert>
                )}

                {selectedCampaignId ? (
                    <>
                        {isLoading ? (
                            <Box sx={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', padding: '15px' }}>
                                <Skeleton variant="rectangular" width={100} height={20} />
                                <Skeleton variant="rectangular" width={100} height={20} />
                                <Skeleton variant="rectangular" width={100} height={20} />
                                <Skeleton variant="rectangular" width={100} height={20} />
                            </Box>
                        ) : (
                            listAssigned && (
                                <StatusBar className="visible">
                                    <StatusItem>
                                        <StatusLabel sx={{ color: "white" }} variant="body2">List Name</StatusLabel>
                                        <StatusValue sx={{ color: "white" }} variant="body2">{listStatus.name}</StatusValue>
                                    </StatusItem>
                                    <StatusItem>
                                        <StatusLabel sx={{ color: "white" }} variant="body2">Leads</StatusLabel>
                                        <StatusValue sx={{ color: "white" }} variant="body2">{listStatus.leads}</StatusValue>
                                    </StatusItem>
                                    <StatusItem>
                                        <StatusLabel sx={{ color: "white" }} variant="body2">Emails</StatusLabel>
                                        <StatusValue sx={{ color: "white" }} variant="body2">{listStatus.emails}</StatusValue>
                                    </StatusItem>
                                    <StatusItem>
                                        <StatusLabel sx={{ color: "white" }} variant="body2">Phone Numbers</StatusLabel>
                                        <StatusValue sx={{ color: "white" }} variant="body2">{listStatus.phones}</StatusValue>
                                    </StatusItem>
                                </StatusBar>
                            )
                        )}

                        <H1 variant="h1" mt="30px" >Who do you want to reach?</H1>
                        <P variant="body1">Choose how you want to add people to your campaign</P>

                        <ButtonContainer>
                            {!listAssigned ? (
                                <>
                                    <StyledBox
                                        tabIndex={0}
                                        role="button"
                                        aria-label="Create New Lead List"
                                        onClick={() => handleBoxClick('newlist')}
                                    >
                                        <BoxImage
                                            className="box-image"
                                            sx={{
                                                backgroundImage: `url(${folder})`,
                                                filter: isDarkMode ? 'invert(1)' : 'none'
                                            }}
                                        />
                                        <BoxContent className="box-content">
                                            <BoxTitle variant="h6">Create a New List</BoxTitle>
                                            <BoxDescription variant="body2">Create a new list for your campaign</BoxDescription>
                                        </BoxContent>
                                    </StyledBox>
                                    <StyledBox
                                        tabIndex={0}
                                        role="button"
                                        aria-label="Connect to Existing List"
                                        onClick={() => handleBoxClick('existing')}
                                    >
                                        <BoxImage
                                            className="box-image"
                                            sx={{
                                                filter: isDarkMode ? 'invert(1)' : 'none',
                                                backgroundImage: `url(${document})`,
                                            }}
                                        />
                                        <BoxContent className="box-content">
                                            <BoxTitle variant="h6">Connect to Existing List</BoxTitle>
                                            <BoxDescription variant="body2">
                                                Select from your saved contact lists
                                            </BoxDescription>
                                        </BoxContent>
                                    </StyledBox>
                                </>
                            ) : (
                                <>
                                    <StyledBox
                                        tabIndex={0}
                                        role="button"
                                        aria-label="Upload Contacts"
                                        onClick={() => handleBoxClick('upload')}
                                    >
                                        <BubbleLeft>Your Leads</BubbleLeft>
                                        <BoxImage
                                            className="box-image"
                                            sx={{
                                                backgroundImage: `url(${folder})`,
                                                filter: isDarkMode ? 'invert(1)' : 'none'
                                            }}
                                        />
                                        <BoxContent className="box-content">
                                            <BoxTitle variant="h6">Upload Contacts</BoxTitle>
                                            <BoxDescription variant="body2">Import your contacts from a file</BoxDescription>
                                        </BoxContent>
                                    </StyledBox>
                                    <StyledBox
                                        tabIndex={0}
                                        role="button"
                                        aria-label="Find Targeted People"
                                        onClick={() => handleBoxClick('find')}
                                    >
                                        <BubbleTop>Our Leads</BubbleTop>
                                        <BoxImage
                                            className="box-image"
                                            sx={{
                                                filter: isDarkMode ? 'invert(1)' : 'none',
                                                backgroundImage: `url(${magnifier})`,
                                            }}
                                        />
                                        <BoxContent className="box-content">
                                            <BoxTitle variant="h6">Find Targeted People</BoxTitle>
                                            <BoxDescription variant="body2">
                                                Search and select people from our database
                                            </BoxDescription>
                                        </BoxContent>
                                    </StyledBox>
                                </>
                            )}
                        </ButtonContainer>

                        {/* <Screen className={activeScreen === 'upload' ? 'active' : ''}>
                            <BackButton onClick={handleBackClick}>Back</BackButton>
                            <ScreenContent className="screen-content">
                                <UploadCSV2 />
                            </ScreenContent>
                        </Screen>
                        <Screen className={activeScreen === 'find' ? 'active' : ''}>
                            <BackButton onClick={handleBackClick}>Back</BackButton>
                            <ScreenContent className="screen-content">
                                <AdvancedLeadGenParent />
                            </ScreenContent>
                        </Screen>
                        <Screen className={activeScreen === 'existing' ? 'active' : ''}>
                            <BackButton onClick={handleBackClick}>Back</BackButton>
                            <ScreenContent className="screen-content">
                                <CampaignLeadsConnectExistingList selectedCampaignId={selectedCampaignId} />
                            </ScreenContent>
                        </Screen>
                        <Screen className={activeScreen === 'newlist' ? 'active' : ''}>
                            <BackButton onClick={handleBackClick}>Back</BackButton>
                            <CreateNewList />
                        </Screen> */}

                        <DraggableDialog
                            open={uploadDialogOpen}
                            onClose={() => setUploadDialogOpen(false)}
                            content={<UploadCSV2 />}
                            title="Upload Contacts"
                            enableExternalWindow={false}
                            closeWarningMessage='Unsaved Changes Will Be Lost'
                            showCloseWarning

                        />
                        <DraggableDialog
                            open={findDialogOpen}
                            onClose={() => setFindDialogOpen(false)}
                            content={<AdvancedLeadGenParent selectedCampaignId={selectedCampaignId} />}
                            title="Find Targeted People"
                            enableExternalWindow={false}
                            closeWarningMessage='Unsaved Changes Will Be Lost'
                            showCloseWarning

                        />
                        <DraggableDialog
                            open={existingDialogOpen}
                            onClose={() => setExistingDialogOpen(false)}
                            content={<CampaignLeadsConnectExistingList selectedCampaignId={selectedCampaignId} />}
                            title="Connect to Existing List"
                            enableExternalWindow={false}
                            closeWarningMessage='Unsaved Changes Will Be Lost'
                            showCloseWarning
                        />
                        <DraggableDialog
                            open={newListDialogOpen}
                            onClose={() => setNewListDialogOpen(false)}
                            content={<CreateNewList selectedCampaignId={selectedCampaignId} onListCreated={handleListCreated} />} title="Create New List"
                            enableExternalWindow={false}
                            closeWarningMessage='Unsaved Changes Will Be Lost'
                            showCloseWarning

                        />
                    </>
                ) : (
                    <Typography variant="body1">Select a campaign to continue.</Typography>
                )}
            </Container>
        </Box>
    );
};

export default CampaignLeads;