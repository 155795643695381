// src\app\pages\3-CRM\LeadlistsParent.tsx
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import NestedList from './ListsPanel';
import TableComponent from './TableRender';
import CreateList from './CreateListComponent';
import Grid from '@mui/material/Grid';
import UploadCSV2 from './UploadBox/UploadBox';
import DraggableDialog from '../../modules/CustomMaterialUiComponents/DraggableDialog';
import { Typography } from '@mui/material';
import LeadUploadForm from './UploadBox/SingleLeadUpload/LeadUploadForm';
import { motion } from 'framer-motion';
import { TableData } from './CRM-Table-Interfaces';
import { RiFileExcel2Fill } from 'react-icons/ri';


export interface ListItem {
    _id: string;
    name: string;
}

export interface FolderItem {
    folder: {
        _id: string;
        name: string;
    };
    lists: {
        _id: string;
        name: string;
    }[];
}

interface Header {
    fieldId: string;
    name: string;
    type: string;
    personalization: string;
    _id: string;
}





const CRMParentComponent = () => {
    const [selectedList, setSelectedList] = useState<string | null>(() => {
        // Initialize state from local storage or start with null if there's nothing stored
        return localStorage.getItem('selectedListId') || null;
    });
    const [showSkeleton, setShowSkeleton] = React.useState(false);
    const [foldersWithLists, setFoldersWithLists] = React.useState<FolderItem[]>([]); // Provide a type annotation for foldersWithLists
    const [listsWithoutFolder, setListsWithoutFolder] = React.useState<ListItem[]>([]); // Also provide a type annotation here
    const [loading, setLoading] = React.useState(false);
    const [selectedListHeaders, setSelectedListHeaders] = useState<Header[]>([]);
    const [selectedLeadIds, setSelectedLeadIds] = useState([]);

    const [leftPanelWidth, setLeftPanelWidth] = useState(() => {
        const savedWidth = localStorage.getItem('leftPanelWidth');
        console.log("Reading leftPanelWidth from localStorage:", savedWidth);
        return savedWidth ? JSON.parse(savedWidth) : 25;
    });
    const [rightPanelWidth, setRightPanelWidth] = useState(() => {
        const savedWidth = localStorage.getItem('rightPanelWidth');
        console.log("Reading rightPanelWidth from localStorage:", savedWidth);
        return savedWidth ? JSON.parse(savedWidth) : 75;
    });




    const [tableData, setTableData] = useState<TableData>({
        listName: '',
        listDetails: {
            _id: '',
            userId: '',
            name: '',
            headers: [],
            __v: 0,
        },
        leads: [],
        loading: true,
    });
    const initialCheckboxStates = [
        { email: false, phone: false, company: false, website: false },
        // ... more objects based on the number of rows or your specific logic
    ];

    const [open, setOpen] = useState(false);
    const toggleDialog = () => setOpen(!open);

    const [openSingleLeadUpload, setOpenSingleLeadUpload] = useState(false);
    const toggleDialogSingleLeadUpload = () => setOpenSingleLeadUpload(!openSingleLeadUpload);

    // Initialize state for column visibility and header mappings
    const [columnVisibility, setColumnVisibility] = useState<boolean[]>([]);
    const [headerMappings, setHeaderMappings] = useState<{ [key: string]: string }>({});


    useEffect(() => {
        console.log("Saving leftPanelWidth to localStorage:", leftPanelWidth);
        localStorage.setItem('leftPanelWidth', JSON.stringify(leftPanelWidth));
        console.log("Saving rightPanelWidth to localStorage:", rightPanelWidth);
        localStorage.setItem('rightPanelWidth', JSON.stringify(rightPanelWidth));
    }, [leftPanelWidth, rightPanelWidth]);

    useEffect(() => {
        console.log('Selected List Changed:', selectedList);
        setSelectedLeadIds([]);

        // Check if selectedList is not null before setting it in local storage
        if (selectedList) {
            localStorage.setItem('selectedListId', selectedList);
        } else {
            // If selectedList is null, remove the item from local storage
            localStorage.removeItem('selectedListId');
        }
    }, [selectedList]);

    // Fetch data for the selected list and update state for column visibility and header mappings
    useEffect(() => {
        if (selectedList) {
            axios.get(`${process.env.REACT_APP_API_URL}/leads/lists/leads/${selectedList}`)
                .then((response) => {
                    setTableData({ ...response.data, loading: false });
                    // Directly using response.data.listDetails.headers to set column visibility and mappings
                    setColumnVisibility(new Array(response.data.listDetails.headers.length).fill(true));

                    // Assuming each header's 'name' can serve as its mapping identifier
                    const mappings = response.data.listDetails.headers.reduce((acc, header) => {
                        acc[header.name] = header.name;
                        return acc;
                    }, {});
                    setHeaderMappings(mappings);
                })
                .catch((error) => {
                    console.error('Error fetching table data:', error);
                    // Resetting to initial state in case of error
                    setTableData({
                        listName: '',
                        listDetails: {
                            _id: '',
                            userId: '',
                            name: '',
                            headers: [],
                            __v: 0,
                        },
                        leads: [],
                        loading: false,
                    });
                });
        }
    }, [selectedList]);

    const togglePanelVisibility = () => {
        const newLeftWidth = leftPanelWidth > 0 ? 0 : 25;
        const newRightWidth = leftPanelWidth > 0 ? 100 : 75;
        console.log("Toggling panel widths:", newLeftWidth, newRightWidth);
        setLeftPanelWidth(newLeftWidth);
        setRightPanelWidth(newRightWidth);
    };


    const fetchData = async ({ noSkeleton = false } = {}) => {
        if (selectedList) {
            try {

                if (!noSkeleton) {
                    setTableData({
                        listName: '',
                        listDetails: {
                            _id: '',
                            userId: '',
                            name: '',
                            headers: [],
                            __v: 0,
                        },
                        leads: [],
                        loading: true,
                    });
                }


                const response = await axios.get(`${process.env.REACT_APP_API_URL}/leads/lists/leads/${selectedList}`);
                setTableData({ ...response.data, loading: false });
            } catch (error) {
                console.error('Error fetching table data:', error);
                setTableData({
                    listName: '', // Assuming you want to reset this as well
                    listDetails: {
                        _id: '',
                        userId: '',
                        name: '',
                        headers: [], // This now matches the expected Header[] type
                        __v: 0,
                    },
                    leads: [],
                    loading: false
                });
            }
        }
    };

    useEffect(() => {
        fetchData();
    }, [selectedList]);

    const RefreshLists = () => {
        setLoading(true);
        setShowSkeleton(true);

        setTimeout(() => setShowSkeleton(false), 0); // Set skeleton to hide after 2 seconds

        axios.get(`${process.env.REACT_APP_API_URL}/leads/folders-with-lists`)
            .then(response => {
                setFoldersWithLists(response.data.foldersWithLists);
                setListsWithoutFolder(response.data.listsWithoutFolder);
                setLoading(false);
            })
            .catch(error => {
                console.error('There was an error fetching the data:', error);
                setLoading(false);
            });
    };

    React.useEffect(() => {
        RefreshLists(); // Fetch data when the component mounts
    }, []);


    const handleFormSubmitSingleLead = async (formData) => {
        // Format emails and phones as arrays of objects
        const formattedEmails = formData.emails.map(email => ({
            emailaddress: email, // Assuming formData.emails is an array of email strings
            type: "unknown", // You can adjust this default value as necessary
            validated: false,
        }));

        const formattedPhones = formData.phones.map(phone => ({
            number: phone, // Assuming formData.phones is an array of phone strings
            type: "unknown", // You can adjust this default value as necessary
            validated: false,
        }));

        // Construct payload with formatted emails and phones
        const payload = {
            listId: selectedList,
            leadData: {
                ...formData,
                emails: formattedEmails,
                phones: formattedPhones,
            },
        };

        // Remove the original strings arrays if present to prevent confusion
        delete payload.leadData.emails;
        delete payload.leadData.phones;

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/leads/upload-lead`, payload);
            console.log('Lead uploaded successfully', response.data);
            alert('Lead uploaded successfully');
            // Optionally refresh data or reset form here...
        } catch (error: any) {
            console.error('Error uploading lead:', error);
            const errorMessage = error.response ? error.response.data : 'Unknown error';
            alert('Error uploading lead: ' + errorMessage);
        }
    };

    const uploadSingleLead = () => {
        toggleDialogSingleLeadUpload();  // Your existing function to handle single lead upload
    };

    const uploadFileList = () => {
        toggleDialog();  // Your existing function to handle file list upload
    };

    const containerVariants = {
        hidden: { opacity: 0 },
        visible: {
            opacity: 1,
            transition: {
                delayChildren: 0.3,
                staggerChildren: 0.2
            }
        }
    };

    const itemVariants = {
        hidden: { y: 20, opacity: 0 },
        visible: {
            y: 0,
            opacity: 1,
            transition: { type: 'spring', stiffness: 300 }
        },
        hover: {
            scale: 1.1,  // Scales up the element slightly on hover
            transition: {
                type: 'spring', // Use spring transition for hover effect
                stiffness: 500, // Adjust stiffness for a more springy effect
                damping: 20 // Adjust damping for a more bouncy effect
            }
        },
        press: {
            scale: 0.95,  // Scales down the element when pressed
            transition: {
                type: 'spring', // Use spring transition for press effect
                stiffness: 500, // Adjust stiffness for a more springy effect
                damping: 20 // Adjust damping for a more bouncy effect
            }
        }
    };


    return (
        <div className="root">



            <Grid container spacing={2} >
                <div style={{
                    width: `${leftPanelWidth}%`,
                    transition: 'width 0.5s',
                    overflow: 'auto',
                    minHeight: '70vh',
                    maxHeight: '90vh',
                }}>
                    <CreateList RefreshLists={RefreshLists} />
                    <NestedList
                        showSkeleton={showSkeleton}
                        foldersWithLists={foldersWithLists}
                        listsWithoutFolder={listsWithoutFolder}
                        onListSelect={(listId) => setSelectedList(listId)}
                    />
                </div>
                <div style={{
                    width: `${rightPanelWidth}%`,
                    transition: 'width 0.5s',
                    overflow: 'auto',
                    minHeight: '70vh',
                    maxHeight: '90vh',
                }}>
                    {selectedList ? (
                        <TableComponent
                            data={tableData}
                            headerMappings={headerMappings}
                            visibility={columnVisibility}
                            selectedListId={selectedList}
                            refreshData={fetchData}
                            selectedLeadIds={selectedLeadIds}
                            setSelectedLeadIds={setSelectedLeadIds}
                            togglePanelVisibility={togglePanelVisibility}
                            uploadSingleLead={uploadSingleLead}
                            uploadFileList={uploadFileList}
                        />
                    ) : (


                        <Grid container onClick={togglePanelVisibility} style={{ height: '80vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                            component={motion.div}
                            variants={containerVariants}
                            initial="hidden"
                            animate="visible"
                        >
                            <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
                                <motion.div
                                    variants={itemVariants}
                                    initial="hidden"
                                    animate="visible"
                                    whileHover="hover"   // This triggers the hover effect
                                    whileTap="press"     // This triggers the press effect
                                >
                                    <Typography variant="h2" color="textPrimary" component="h2" style={{ cursor: 'pointer', fontSize: '3rem', color: "#C9C9C9" }}>
                                        Select a list
                                    </Typography>
                                </motion.div>
                            </Grid>

                            <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <motion.div style={{ fontSize: '1.5rem', color: "#C9C9C9" }} variants={itemVariants}>OR</motion.div>
                            </Grid>

                            <Grid item xs={12}
                                onClick={(event) => {
                                    event.stopPropagation();
                                    uploadFileList();
                                }}
                                style={{ display: 'flex', justifyContent: 'center', alignItems: "center" }}>
                                <motion.div
                                    variants={itemVariants}
                                    initial="hidden"
                                    animate="visible"
                                    whileHover="hover"   // This triggers the hover effect
                                    whileTap="press"     // This triggers the press effect
                                >

                                    <RiFileExcel2Fill
                                        style={{
                                            color: "#C9C9C9",
                                            fontSize: '3rem',
                                            padding: '10px 20px',
                                            textTransform: 'none',
                                            cursor: 'pointer',
                                        }}
                                        size="5.5em"
                                    />
                                    <Typography sx={{ display: 'flex', alignItems: "center", justifyContent: "center" }} variant="h2" color="textPrimary" component="h2" style={{ cursor: 'pointer', fontSize: '3rem', color: "#C9C9C9" }}>
                                        Upload File
                                    </Typography>
                                </motion.div>
                            </Grid>
                        </Grid>
                    )
                    }
                </div >

                <DraggableDialog
                    open={open}
                    onClose={toggleDialog}
                    content={<UploadCSV2 />}
                    title="Upload Leads"
                    enableExternalWindow={false}
                    width={1200}
                    height={600}
                    showCloseWarning
                    closeWarningMessage='Unsaved Changes Will Be Lost'
                />

                <DraggableDialog
                    open={openSingleLeadUpload}
                    onClose={toggleDialogSingleLeadUpload}
                    content={<LeadUploadForm headers={selectedListHeaders} onSubmit={handleFormSubmitSingleLead} />}
                    title="Upload Single Lead"
                    enableExternalWindow={false}
                    width={1200}
                    height={600}
                    showCloseWarning
                    closeWarningMessage='Unsaved Changes Will Be Lost'
                />
            </Grid >
        </div>
    );
};

export default CRMParentComponent;

