export default [
    {
      'id': 0,
      'handle': 'inbox',
      'title': 'Inbox',
      'icon': 'inbox'
    },
    {
      'id': 1,
      'handle': 'sent',
      'title': 'Sent',
      'icon': 'mail-send'
    },
    {
      'id': 2,
      'handle': 'drafts',
      'title': 'Drafts',
      'icon': 'email-open'
    },
    {
      'id': 3,
      'handle': 'spam',
      'title': 'Spam',
      'icon': 'info'
    },
    {
      'id': 4,
      'handle': 'trash',
      'title': 'Trash',
      'icon': 'delete'
    }
  ];