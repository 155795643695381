import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { List, ListItemButton, ListItemAvatar, Avatar, Divider, IconButton, Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, CircularProgress, Skeleton, ListItemText, TextField } from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';  // Error icon
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import RestoreSharpIcon from '@mui/icons-material/RestoreSharp';
import FolderSharpIcon from '@mui/icons-material/FolderSharp';
import StarRateIcon from '@mui/icons-material/StarRate';
import AddSharpIcon from '@mui/icons-material/AddSharp';
import CloseSharpIcon from '@mui/icons-material/CloseSharp';
import DeleteSharp from '@mui/icons-material/DeleteSharp';
import CreateSharpIcon from '@mui/icons-material/CreateSharp';
interface Campaign {
    _id: string;
    campaignName: string;
    campaignImage: string;
    status: string;
}
interface CampaignsPanelProps {
    onCampaignSelect: (id: string | null) => void;
    selectedCampaignId: string | null;
}


const CampaignsPanel: React.FC<CampaignsPanelProps> = ({ onCampaignSelect, selectedCampaignId }) => {
    const [campaigns, setCampaigns] = useState<Campaign[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const [value, setValue] = React.useState('recents');

    const [isCreating, setIsCreating] = useState(false);
    const [newCampaignName, setNewCampaignName] = useState("");

    const [isEditMode, setIsEditMode] = useState(false);
    const [deleteCandidate, setDeleteCandidate] = useState<string | null>(null);


    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const fetchCampaigns = () => {

        setLoading(true); // Set loading to true whenever you start fetching
        axios.get(`${process.env.REACT_APP_API_URL}/campaign`)
            .then((response) => {
                const extractedData: Campaign[] = response.data.map((item: any) => ({
                    _id: item._id,
                    campaignName: item.campaignName,
                    campaignImage: item.campaignImage,
                    status: item.status
                }));

                setCampaigns(extractedData);
                setLoading(false);
            })
            .catch((err) => {
                setError('Error loading campaigns');
                setLoading(false);
            });
    };

    const handleCampaignSelect = (id: string) => {
        onCampaignSelect(id);
    };

    const labelStyle = { '& .MuiBottomNavigationAction-label': { fontSize: '1.4rem' } }; // Adjust the size as needed


    // ----------------------------------
    const createCampaign = () => {
        axios.post(`${process.env.REACT_APP_API_URL}/campaign/create`, {
            campaignName: newCampaignName
        }).then(response => {
            fetchCampaigns(); // Refetch the campaigns to update the list
            setIsCreating(false); // Hide the creation UI
            setNewCampaignName(""); // Reset the campaign name field
        }).catch(error => {
            // Handle the error
        });
    };

    useEffect(() => {
        fetchCampaigns();

        // Cleanup function to reset creating state on unmount
        return () => {
            setIsCreating(false);
        };
    }, []); // Make sure this effect only runs once on mount


    // ------------------

    const deleteCampaign = (campaignId) => {
        axios.delete(`${process.env.REACT_APP_API_URL}/campaign/delete/${campaignId}`)
            .then(() => {
                setDeleteCandidate(null); // Close the dialog
                fetchCampaigns(); // Refresh the list after deletion
                if (campaignId === selectedCampaignId) {
                    onCampaignSelect(null); // Update the parent component's state
                }
            })
            .catch((error) => {
                console.error("Failed to delete campaign", error);
            });
    };


    const SkeletonLoader = () => (
        <>
            {[...new Array(5)].map((_, index) => (
                <div key={index}>
                    <ListItemButton>
                        <ListItemAvatar>
                            <Skeleton variant="circular" width={40} height={40} animation="pulse" />
                        </ListItemAvatar>
                        <Skeleton variant="text" width="80%" height={30} animation="pulse" />
                    </ListItemButton>
                    <Divider variant="inset" component="li" />
                </div>
            ))}
        </>
    );

    const ErrorMessage = () => (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', marginTop: 20 }}>
            <ErrorOutlineIcon style={{ fontSize: 60, color: 'red' }} onClick={fetchCampaigns} />
            <p style={{ textAlign: 'center' }}>
                {error} <br />
                <Button variant="contained" color="primary" onClick={fetchCampaigns}>
                    Try Again
                </Button>
            </p>
        </div>
    );


    return (
        <div>
            <BottomNavigation sx={{ height: "150px" }} value={value} onChange={handleChange}>
                {/* <BottomNavigationAction label="Recents" value="recents" icon={<RestoreSharpIcon sx={{ fontSize: "35px" }} />} sx={labelStyle} />
                <BottomNavigationAction label="Starred" value="favorites" icon={<StarRateIcon sx={{ fontSize: "35px" }} />} sx={labelStyle} /> */}
                {/* <BottomNavigationAction label="All Campaigns" value="folder" icon={<FolderSharpIcon sx={{ fontSize: "35px" }} />} sx={labelStyle} /> */}
                <Button
                    fullWidth
                    onClick={() => setIsEditMode(!isEditMode)}>
                    <CreateSharpIcon color='info' sx={{ fontSize: "55px" }} />
                </Button>
            </BottomNavigation>

            <Divider sx={{ border: "dashed 2px" }} />

            {loading ? (
                <SkeletonLoader />
            ) : error ? (
                <ErrorMessage />
            ) : (
                <List>

                    {campaigns.map((campaign) => (
                        <div key={campaign._id}>
                            <ListItemButton
                                onClick={() => handleCampaignSelect(campaign._id)}
                                selected={campaign._id === selectedCampaignId}
                                sx={isEditMode ? { border: "dashed 3px orange", margin: "10px" } : {}}
                            >
                                <ListItemAvatar>
                                    <Avatar alt={campaign.campaignName} src={campaign.campaignImage} />
                                </ListItemAvatar>
                                <ListItemText
                                    primary={campaign.campaignName}
                                    secondary={`Status: ${campaign.status}`}
                                />
                                {/* <ListItemText secondary={`Status: ${campaign.status}`} /> */}
                                {isEditMode && (
                                    <IconButton edge="end" aria-label="delete" onClick={() => setDeleteCandidate(campaign._id)}>
                                        <DeleteSharp color='secondary' sx={{ fontSize: "40px" }} />
                                    </IconButton>
                                )}
                            </ListItemButton>
                            <Divider variant="inset" component="li" />
                        </div>
                    ))}

                    {isCreating && (
                        <>
                            <Divider sx={{ border: "dashed 4px" }} />
                            <ListItemButton>
                                <TextField
                                    variant="filled"
                                    placeholder="Enter campaign name"
                                    fullWidth
                                    value={newCampaignName}
                                    onChange={(e) => setNewCampaignName(e.target.value)}
                                />
                                <IconButton onClick={createCampaign}>
                                    <AddSharpIcon />
                                </IconButton>
                                <IconButton onClick={() => setIsCreating(false)}>
                                    <CloseSharpIcon />
                                </IconButton>

                            </ListItemButton>
                            <Divider sx={{ border: "dashed 4px" }} />
                        </>
                    )}
                    <Button
                        fullWidth
                        sx={{ height: "350px" }}
                        onClick={() => setIsCreating(true)}
                    >

                        <AddSharpIcon sx={{ fontSize: "50px" }} />
                    </Button>
                </List>


            )}

            <Dialog
                open={!!deleteCandidate}
                onClose={() => setDeleteCandidate(null)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Confirm Deletion"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to delete this campaign? All related data will be permanently removed.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setDeleteCandidate(null)}>Cancel</Button>
                    <Button onClick={() => deleteCampaign(deleteCandidate)} autoFocus>
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>

        </div>
    );
};

export default CampaignsPanel;
