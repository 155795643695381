// src\app\pages\uploadcsv2\UploadCSV2.tsx
import React, { useEffect, useState } from 'react';
import CsvButton from './CsvButton';
import CsvTable from './CsvTable';
import ColumnSettings from './ColumnSettings';
import * as XLSX from 'xlsx';
import { Box, Button, Grid, LinearProgress, Typography } from '@mui/material';
import ProgressIndicator from './ProgressIndicator';
import { ListSelectionForm } from './ListSelectionForm';
import { motion } from 'framer-motion';
import { useAuth } from '@/src/app/modules/auth'; // Ensure path is correct

import './UploadBox.css';
import { FoldersWithListsResponse } from './Interfacefolderslists';
import axios from 'axios';
import { Header } from './interfaceHeader';

import {
    ResizableHandle,
    ResizablePanel,
    ResizablePanelGroup,
} from '@/src/ui/resizable';


const UploadCSV2 = () => {
    const { currentUser } = useAuth();
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [previewData, setPreviewData] = useState<string[][]>([]);
    const [headerMappings, setHeaderMappings] = useState<{ [key: string]: string }>({});
    const [originalHeaders, setOriginalHeaders] = useState<string[]>([]);
    const [editableHeaders, setEditableHeaders] = useState<string[]>([]);
    const [visibility, setVisibility] = useState<boolean[]>([]);
    const [checkboxStates, setCheckboxStates] = useState<{
        email: boolean;
        phone: boolean;
        company: boolean;
        website: boolean;
    }[]>([]);
    const [filters, setFilters] = useState<{ [key: string]: string }>({});
    const [headerTypeMappings, setHeaderTypeMappings] = useState<{ [key: string]: string }>({});
    const [emailTypesColumn, setEmailTypesColumn] = React.useState<{ [key: string]: string }>({});
    const [phoneTypesColumn, setPhoneTypesColumn] = React.useState<{ [key: string]: string }>({});
    const [existingListsData, setExistingListsData] = useState<FoldersWithListsResponse | null>(null);
    const [selectedListHeaders, setSelectedListHeaders] = useState<Header[]>([]);
    const [selectedListName, setSelectedListName] = useState('');
    const [linkedInUrlColumn, setLinkedInUrlColumn] = useState<string>('');
    const [icebreakerColumn, setIcebreakerColumn] = useState<string>('');
    const [locationColumn, setLocationColumn] = useState<string>('');
    const [companyNameColumn, setCompanyNameColumn] = useState<string>('');
    const [fullNameColumn, setFullNameColumn] = useState<string>('');
    const [firstNameColumn, setFirstNameColumn] = useState<string>('');
    const [lastNameColumn, setLastNameColumn] = useState<string>('');
    const [jobTitleColumn, setJobTitleColumn] = useState<string>('');


    const loadSampleData = () => {
        const sampleData = [
            ['Name', 'Email', 'Phone', 'Company'],
            ['John Doe', 'john@example.com', '555-1234', 'Example Corp'],
            ['Jane Smith', 'jane@example.com', '555-5678', 'Sample Inc'],
        ];

        const worksheet = XLSX.utils.aoa_to_sheet(sampleData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
        const file = new File([blob], 'sampleData.xlsx', { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

        handleFileUpload([file]);
    };


    const handleListSelected = (listId: string) => {
        // Find the selected list from existingListsData
        const selectedList = existingListsData?.listsWithoutFolder.find(list => list._id === listId) ||
            existingListsData?.foldersWithLists.flatMap(folderWithList => folderWithList.lists).find(list => list._id === listId);
        if (selectedList) {
            setSelectedListHeaders(selectedList.headers);
            setSelectedListName(selectedList.name);
        }
    };


    // existing lists :
    useEffect(() => {
        const fetchData = async () => {
            const response = await axios.get<FoldersWithListsResponse>(
                `${process.env.REACT_APP_API_URL}/leads/folders-with-lists`
            );
            setExistingListsData(response.data);
        };

        fetchData();
    }, []);

    const handleFileUpload = (files: File[]) => {
        const file = files[0];
        if (!file) return;

        const reader = new FileReader();
        reader.onload = (e: ProgressEvent<FileReader>) => {
            const result = e.target?.result;
            if (!result) return;

            const workbook = XLSX.read(result, { type: 'binary' });
            const worksheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[worksheetName];
            const data = XLSX.utils.sheet_to_json(worksheet, { header: 1, range: 0, blankrows: false });
            const parsedData = data as string[][];

            // Check if parsedData is not undefined or empty
            if (!parsedData || parsedData.length === 0 || !parsedData[0] || parsedData[0].length === 0) {
                console.error('The uploaded file is empty or not properly formatted.');
                // Handle the empty or improperly formatted file appropriately
                // For example, you could reset the state or show an error message to the user
                resetAll(); // Reset the form or show an error message
                return;
            }

            setPreviewData(parsedData);
            setSelectedFile(file);
            setOriginalHeaders(parsedData[0]);
            setEditableHeaders(parsedData[0]);
            setVisibility(new Array(parsedData[0].length).fill(true));
            setCheckboxStates(
                parsedData[0].map(() => ({ email: false, phone: false, company: false, website: false }))
            );
            setVisibility(new Array(parsedData[0].length).fill(false)); // Switches off by default
        };

        reader.readAsBinaryString(file);
    };

    const handleHeaderMappingChange = (index: number, newHeader: string) => {
        const updatedMappings = { ...headerMappings };
        const originalHeader = originalHeaders[index];
        updatedMappings[originalHeader] = newHeader;
        setHeaderMappings(updatedMappings);

        const updatedHeaders = [...editableHeaders];
        updatedHeaders[index] = newHeader;
        setEditableHeaders(updatedHeaders);
    };

    const handleVisibilityChange = (newVisibility: boolean[]) => {
        setVisibility(newVisibility);
        // Update headerMappings based on visibility
        const updatedMappings = { ...headerMappings };
        originalHeaders.forEach((header, index) => {
            if (!newVisibility[index]) {
                delete updatedMappings[header];
            }
        });
        setHeaderMappings(updatedMappings);
    };


    const resetAll = () => {
        setSelectedFile(null);
        setPreviewData([]);
        setHeaderMappings({});
        setOriginalHeaders([]);
        setEditableHeaders([]);
        setVisibility([]);
        setCheckboxStates([]);
        setFilters({});
        setHeaderTypeMappings({});
        setEmailTypesColumn({});
        setPhoneTypesColumn({});
        setLinkedInUrlColumn('');
        setIcebreakerColumn('');
        setLocationColumn('');
        setCompanyNameColumn('');
        setFullNameColumn('');
        setFirstNameColumn('');
        setLastNameColumn('');
        setJobTitleColumn('');
    };


    return (
        <Grid container justifyContent="center" alignItems="center" style={{ overflowX: 'auto' }}>
            <Grid item xs={12} md={8} lg={11}>
                <Box>
                    <ProgressIndicator
                        selectedFile={selectedFile}
                        headerMappings={headerMappings}
                        headerTypeMappings={headerTypeMappings}
                        visibility={visibility}

                    />



                    <Grid style={{ padding: '00px' }}>
                        <motion.div
                            className="no-global-transition"
                            initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 1 }}>
                            <CsvButton onFileUpload={handleFileUpload} onRemoveFile={resetAll} />
                        </motion.div>
                    </Grid>


                    {currentUser && currentUser.admin && (

                        <Button onClick={loadSampleData} variant="contained" color="primary" style={{ marginTop: '20px' }}>
                            Load Sample Data
                        </Button>
                    )}


                    {previewData.length > 0 && (
                        <Grid xs={12} md={12} sx={{ marginTop: "20px", marginBottom: "20px" }} >

                            {/* <Grid item xs={12} md={4} sx={{ marginTop: "20px", marginBottom: "20px" }}> */}
                            <ResizablePanelGroup
                                direction="horizontal"
                            >
                                <ResizablePanel minSize={20} defaultSize={25}>

                                    <ColumnSettings
                                        editableHeaders={editableHeaders}
                                        setEditableHeaders={setEditableHeaders}
                                        originalHeaders={originalHeaders}
                                        visibility={visibility}
                                        onVisibilityChange={handleVisibilityChange}
                                        onHeaderMappingChange={handleHeaderMappingChange}
                                        headerTypeMappings={headerTypeMappings}
                                        setHeaderTypeMappings={setHeaderTypeMappings}
                                        emailTypesColumn={emailTypesColumn}
                                        setEmailTypesColumn={setEmailTypesColumn}
                                        phoneTypesColumn={phoneTypesColumn}
                                        setPhoneTypesColumn={setPhoneTypesColumn}
                                        selectedListHeaders={selectedListHeaders}
                                        headerMappings={headerMappings}
                                        setHeaderMappings={setHeaderMappings}
                                        selectedListName={selectedListName}
                                        linkedInUrlColumn={linkedInUrlColumn}
                                        setLinkedInUrlColumn={setLinkedInUrlColumn}
                                        icebreakerColumn={icebreakerColumn}
                                        setIcebreakerColumn={setIcebreakerColumn}
                                        locationColumn={locationColumn}
                                        setLocationColumn={setLocationColumn}
                                        companyNameColumn={companyNameColumn}
                                        setCompanyNameColumn={setCompanyNameColumn}
                                        fullNameColumn={fullNameColumn}
                                        setFullNameColumn={setFullNameColumn}
                                        firstNameColumn={firstNameColumn}
                                        setFirstNameColumn={setFirstNameColumn}
                                        lastNameColumn={lastNameColumn}
                                        setLastNameColumn={setLastNameColumn}
                                        jobTitleColumn={jobTitleColumn}
                                        setJobTitleColumn={setJobTitleColumn}

                                    />

                                </ResizablePanel>

                                <ResizableHandle withHandle />

                                {/* </Grid>



                            <Grid item xs={12} md={8} sx={{ marginTop: "20px", marginBottom: "20px" }}> */}

                                <ResizablePanel minSize={20} defaultSize={75}>


                                    <CsvTable
                                        selectedFile={selectedFile}
                                        previewData={previewData}
                                        editableHeaders={editableHeaders}
                                        visibility={visibility}
                                        filters={filters}
                                        setFilters={setFilters}
                                        onHeaderChange={handleHeaderMappingChange}
                                    />

                                </ResizablePanel>
                            </ResizablePanelGroup>

                            {/* </Grid> */}
                            <Grid style={{ padding: '00px', width: "100%", marginTop: "20px", marginBottom: "20px" }}>
                                <motion.div
                                    className="no-global-transition"
                                    initial={{ y: 50, opacity: 0 }} animate={{ y: 0, opacity: 1 }} transition={{ duration: 1 }}>
                                    <ListSelectionForm
                                        selectedFile={selectedFile}
                                        originalHeaders={originalHeaders}
                                        editableHeaders={editableHeaders}
                                        visibility={visibility}
                                        headerMappings={headerMappings}
                                        onListSelected={handleListSelected}
                                        headerTypeMappings={headerTypeMappings}
                                        emailTypesColumn={emailTypesColumn}
                                        setEmailTypesColumn={setEmailTypesColumn}
                                        phoneTypesColumn={phoneTypesColumn}
                                        setPhoneTypesColumn={setPhoneTypesColumn}
                                        existingListsData={existingListsData}
                                        linkedInUrlColumn={linkedInUrlColumn}
                                        setLinkedInUrlColumn={setLinkedInUrlColumn}
                                        icebreakerColumn={icebreakerColumn}
                                        setIcebreakerColumn={setIcebreakerColumn}
                                        locationColumn={locationColumn}
                                        setLocationColumn={setLocationColumn}
                                        companyNameColumn={companyNameColumn}
                                        setCompanyNameColumn={setCompanyNameColumn}
                                        fullNameColumn={fullNameColumn}
                                        setFullNameColumn={setFullNameColumn}
                                        firstNameColumn={firstNameColumn}
                                        setFirstNameColumn={setFirstNameColumn}
                                        lastNameColumn={lastNameColumn}
                                        setLastNameColumn={setLastNameColumn}
                                        jobTitleColumn={jobTitleColumn}
                                        setJobTitleColumn={setJobTitleColumn}

                                    />
                                </motion.div>
                            </Grid>

                        </Grid>
                    )}

                </Box>
            </Grid>
        </Grid>
    );
};

export default UploadCSV2;


