import React from 'react';

const InboxPanel: React.FC = () => {
    return (
        <div
            className="d-none d-lg-flex flex-column flex-lg-row-auto w-100 w-lg-275px"
            data-kt-drawer="true"
            data-kt-drawer-name="inbox-aside"
            data-kt-drawer-activate="{default: true, lg: false}"
            data-kt-drawer-overlay="true"
            data-kt-drawer-width="225px"
            data-kt-drawer-direction="start"
            data-kt-drawer-toggle="#kt_inbox_aside_toggle"
        >
            <div
                className="d-none d-lg-flex flex-column flex-lg-row-auto w-100 w-lg-275px"
                data-kt-drawer="true"
                data-kt-drawer-name="inbox-aside"
                data-kt-drawer-activate="{default: true, lg: false}"
                data-kt-drawer-overlay="true"
                data-kt-drawer-width="225px"
                data-kt-drawer-direction="start"
                data-kt-drawer-toggle="#kt_inbox_aside_toggle"
            >
                <div
                    className="card card-flush mb-0"
                    data-kt-sticky="false"
                    data-kt-sticky-name="inbox-aside-sticky"
                    data-kt-sticky-offset="{default: false, xl: '100px'}"
                    data-kt-sticky-width="{lg: '275px'}"
                    data-kt-sticky-left="auto"
                    data-kt-sticky-top="100px"
                    data-kt-sticky-animation="false"
                    data-kt-sticky-zindex={95}
                >
                    <div className="card-body">
                        <a
                            href="../../demo2/dist/apps/inbox/compose.html"
                            className="btn btn-primary fw-bold w-100 mb-8"
                        >
                            New Message
                        </a>
                        <div className="menu menu-column menu-rounded menu-state-bg menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary mb-10">
                            <div className="menu-item mb-3">
                                <span className="menu-link active">
                                    <span className="menu-icon">
                                        <i className="ki-duotone ki-sms fs-2 me-3">
                                            <span className="path1" />
                                            <span className="path2" />
                                        </i>
                                    </span>
                                    <span className="menu-title fw-bold">Inbox</span>
                                    <span className="badge badge-light-success">3</span>
                                </span>
                            </div>
                            <div className="menu-item mb-3">
                                <span className="menu-link">
                                    <span className="menu-icon">
                                        <i className="ki-duotone ki-abstract-23 fs-2 me-3">
                                            <span className="path1" />
                                            <span className="path2" />
                                        </i>
                                    </span>
                                    <span className="menu-title fw-bold">Marked</span>
                                </span>
                            </div>
                            <div className="menu-item mb-3">
                                <span className="menu-link">
                                    <span className="menu-icon">
                                        <i className="ki-duotone ki-file fs-2 me-3">
                                            <span className="path1" />
                                            <span className="path2" />
                                        </i>
                                    </span>
                                    <span className="menu-title fw-bold">Draft</span>
                                    <span className="badge badge-light-warning">5</span>
                                </span>
                            </div>
                            <div className="menu-item mb-3">
                                <span className="menu-link">
                                    <span className="menu-icon">
                                        <i className="ki-duotone ki-send fs-2 me-3">
                                            <span className="path1" />
                                            <span className="path2" />
                                        </i>
                                    </span>
                                    <span className="menu-title fw-bold">Sent</span>
                                </span>
                            </div>
                            <div className="menu-item">
                                <span className="menu-link">
                                    <span className="menu-icon">
                                        <i className="ki-duotone ki-trash fs-2 me-3">
                                            <span className="path1" />
                                            <span className="path2" />
                                            <span className="path3" />
                                            <span className="path4" />
                                            <span className="path5" />
                                        </i>
                                    </span>
                                    <span className="menu-title fw-bold">Trash</span>
                                </span>
                            </div>
                        </div>
                        <div className="menu menu-column menu-rounded menu-state-bg menu-state-title-primary">
                            <div className="menu-item mb-3">
                                <span className="menu-link">
                                    <span className="menu-icon">
                                        <i className="ki-duotone ki-abstract-8 fs-5 text-danger me-3 lh-0">
                                            <span className="path1" />
                                            <span className="path2" />
                                        </i>
                                    </span>
                                    <span className="menu-title fw-semibold">Custom Work</span>
                                    <span className="badge badge-light-danger">6</span>
                                </span>
                            </div>
                            <div className="menu-item mb-3">
                                <span className="menu-link">
                                    <span className="menu-icon">
                                        <i className="ki-duotone ki-abstract-8 fs-5 text-success me-3 lh-0">
                                            <span className="path1" />
                                            <span className="path2" />
                                        </i>
                                    </span>
                                    <span className="menu-title fw-semibold">Partnership</span>
                                </span>
                            </div>
                            <div className="menu-item mb-3">
                                <span className="menu-link">
                                    <span className="menu-icon">
                                        <i className="ki-duotone ki-abstract-8 fs-5 text-info me-3 lh-0">
                                            <span className="path1" />
                                            <span className="path2" />
                                        </i>
                                    </span>
                                    <span className="menu-title fw-semibold">In Progress</span>
                                </span>
                            </div>
                            <div className="menu-item">
                                <span className="menu-link">
                                    <span className="menu-icon">
                                        <i className="ki-duotone ki-plus fs-2 me-3 lh-0" />
                                    </span>
                                    <span className="menu-title fw-semibold">Add Label</span>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default InboxPanel;
