import { useState } from 'react';
import * as Yup from 'yup';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { useFormik } from 'formik';
import { requestPassword } from '../core/_requests';
import { TextField, Button, CircularProgress, Typography } from '@mui/material';
import { Grid } from '@mui/material';
import { Snackbar } from '@mui/material';
import axios, { AxiosError } from 'axios';

interface ResponseData {
  result: boolean;
  message: string;
}

const initialValues = {
  email: '',
};

const forgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
});

export function ForgotPassword() {
  const [loading, setLoading] = useState(false);
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined);
  const [resetSuccess, setResetSuccess] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const formik = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      setHasErrors(undefined);

      try {
        const response = await requestPassword(values.email);
        if (response.data.result) {
          setResetSuccess(true);
          handleSnackbarOpen('Password reset email sent. Please check your email.');
        } else {
          setHasErrors(true);
          handleSnackbarOpen(response.data.message || 'Error ');
        }
      } catch (error) {
        setHasErrors(true);
        // Assuming error is of type AxiosError
        if (error instanceof AxiosError && error.response) {
          const axiosError = error.response.data as { message?: string };
          const message = axiosError.message || 'An unknown error occurred';
          handleSnackbarOpen(message);
        } else {
          handleSnackbarOpen('An unknown error occurred');
        }
      }

      setLoading(false);
      setSubmitting(false);
    }

  });


  const handleSnackbarOpen = (message: string) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  const handleSnackbarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  return (
    <form
      className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
      noValidate
      id='kt_login_password_reset_form'
      onSubmit={formik.handleSubmit}
    >
      <div className='text-center mb-10'>
        {/* begin::Title */}
        <Typography variant='h1' className='text-dark fw-bolder mb-3'>
          Forgot Password?
        </Typography>
        {/* end::Title */}

        {/* begin::Link */}
        <Typography variant='body1' className='text-gray-500 fw-semibold fs-6'>
          Enter your email to reset your password.
        </Typography>
        {/* end::Link */}
      </div>

      {/* begin::Form group */}
      <div className='fv-row mb-8'>
        <TextField
          label='Email'
          placeholder=''
          autoComplete='off'
          {...formik.getFieldProps('email')}
          variant='outlined'
          fullWidth
          error={formik.touched.email && !!formik.errors.email}
          helperText={formik.touched.email && formik.errors.email}
        />
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <Grid container spacing={2} justifyContent='center' alignItems='center'>
        <Grid item xs={12} sm={6} md={6}>
          <Button
            type='submit'
            id='kt_password_reset_submit'
            variant='contained'
            color='primary'
            size='large'
            fullWidth
            sx={{ fontSize: '20px' }}
            disabled={formik.isSubmitting || !formik.isValid}
          >
            {!loading && <span className='indicator-label'>Submit</span>}
            {loading && (
              <span className='indicator-progress' style={{ display: 'block' }}>
                Please wait... <CircularProgress size={20} className='ms-2' />
              </span>
            )}
          </Button>
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <Link to='/auth/login'>
            <Button
              type='button'
              id='kt_login_password_reset_form_cancel_button'
              variant='contained'
              color='secondary'
              size='large'
              fullWidth
              sx={{ fontSize: '20px' }}
            >
              Cancel
            </Button>
          </Link>
        </Grid>
      </Grid>

      <>
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
          message={snackbarMessage}
        />
      </>

      {/* end::Form group */}
    </form>

  );
}
