// src/app/pages/4-Campaigns/5-CampaignReview/LeadsReviewList.tsx
import CustomTooltip from '@/src/app/modules/CustomMaterialUiComponents/CustomTooltip';
import {
    AssignmentTurnedInSharp,
    CheckCircleSharp,
    DensityLargeSharp,
    DensityMediumSharp,
    DensitySmallSharp,
    ErrorSharp,
    FilterAltSharp,
    HourglassEmptySharp,
    IndeterminateCheckBoxSharp,
    LibraryAddCheckSharp,
    LocalShippingSharp,
    PendingActionsSharp,
    ScheduleSendSharp,
    SearchSharp,
    ThumbDownAltSharp,
    ThumbUpAltSharp,
} from '@mui/icons-material';
import BusinessIcon from '@mui/icons-material/Business';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import PanoramaFishEyeSharpIcon from '@mui/icons-material/PanoramaFishEyeSharp';
import RecommendSharpIcon from '@mui/icons-material/RecommendSharp';
import {
    Alert,
    Button,
    Checkbox,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    FormControlLabel,
    FormGroup,
    Grid,
    IconButton,
    InputAdornment,
    Menu,
    MenuItem,
    Pagination,
    Paper,
    Select,
    Snackbar,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Tooltip,
} from '@mui/material';
import axios from 'axios';
import React, { useEffect, useMemo, useState } from 'react';
import io from 'socket.io-client';

import { useAuth } from '../../../modules/auth';
import { ILeadReviewInfo } from './CampaignReviewParent';


interface LeadsReviewListProps {
    leadReviewInfo: ILeadReviewInfo[];
    page: number;
    rowsPerPage: number;
    handleLeadClick: (leadId: string) => void;
    selectedLead: string | null;
    setPage: (page: number) => void;
    setRowsPerPage: (rowsPerPage: number) => void;
    selectedCampaignId: string | null;
    approveLeads: {
        mutate: (variables: { personalizedMessageIds: string[]; approve: boolean }) => void;
    };
    unqueueMessages: {
        mutate: (variables: { personalizedMessageIds: string[] }) => void;
    };
}

function formatSelectedPages(selectedPages: number[]): string {
    let formattedPages = "";
    let consecutivePages = [];

    for (let i = 0; i < selectedPages.length; i++) {
        let start = selectedPages[i] + 1; // Add 1 here for display
        let end = start;

        while (i + 1 < selectedPages.length && selectedPages[i + 1] === end) {
            end = selectedPages[i + 1] + 1; // Add 1 here for display
            i++;
        }

        if (start === end) {
            formattedPages += `${start}, `;
        } else {
            formattedPages += `${start}-${end}, `;
        }
    }

    return formattedPages.slice(0, -2); // Remove the trailing comma and space
}

const LeadsReviewList: React.FC<LeadsReviewListProps> = ({
    leadReviewInfo,
    page,
    rowsPerPage,
    handleLeadClick,
    selectedLead,
    setPage,
    setRowsPerPage,
    selectedCampaignId,
    approveLeads,
    unqueueMessages
}) => {
    const { currentUser } = useAuth();
    const userId = currentUser?._id;

    const startIndex = page * rowsPerPage;
    const endIndex = Math.min(startIndex + rowsPerPage, leadReviewInfo.length);
    const totalPageCount = Math.ceil(leadReviewInfo.length / rowsPerPage);
    const [inputPage, setInputPage] = useState(page + 1);
    const selectedCheckboxes = useMemo(() => new Set<string>(), []);
    const [selectedCheckboxCount, setSelectedCheckboxCount] = useState(0);
    const [selectedPages, setSelectedPages] = useState<number[]>([]);
    const [submenuAnchorEl, setSubmenuAnchorEl] = useState<null | HTMLElement>(null);
    const [searchQuery, setSearchQuery] = useState("");
    const [searchVisible, setSearchVisible] = useState(false);
    const [showApproved, setShowApproved] = useState(false);
    const [showPending, setShowPending] = useState(false);
    const [showQueued, setShowQueued] = useState(false);
    const [showCompleted, setShowCompleted] = useState(false);
    const [showReplied, setShowReplied] = useState(false);
    const [densityAnchorEl, setDensityAnchorEl] = useState<null | HTMLElement>(null);
    const [listItemDensity, setListItemDensity] = useState<'small' | 'medium' | 'large'>('small');
    const [moreAnchorEl, setMoreAnchorEl] = useState<null | HTMLElement>(null);

    const [dialogOpen, setDialogOpen] = useState(false);
    const [messageTypes, setMessageTypes] = useState<string[]>([]);
    const [firstStepOnly, setFirstStepOnly] = useState(false);
    const [delay, setDelay] = useState(20);
    const [instructions, setInstructions] = useState('');
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error'>('success');
    const [personalizedMessageAIFixJobCounts, setPersonalizedMessageAIFixJobCounts] = useState({
        active: 0,
        waiting: 0,
        completed: 0,
        delayed: 0,
        failed: 0,
        totalScheduled: 0,
    });

    const [jobStatus, setJobStatus] = useState<{ [key: string]: string }>({});

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    const handleMoreMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setMoreAnchorEl(event.currentTarget);
    };

    const handleMoreMenuClose = () => {
        setMoreAnchorEl(null);
    };

    const styles = {
        small: {
            height: '25px',
            padding: '2px 11px',
            fontSize: '1.125rem',
        },
        medium: {
            height: '30px',
            padding: '4px 14px',
            fontSize: '1.250rem',
        },
        large: {
            height: '60px',
            padding: '10px 16px',
            fontSize: '1.325rem',
        },
    };




    useEffect(() => {
        setInputPage(page + 1);
    }, [page]);

    let filteredLeads = leadReviewInfo;

    useEffect(() => {
        const newSelectedPages: number[] = [];
        selectedCheckboxes.forEach((leadId) => {
            const leadIndex = leadReviewInfo.findIndex((info) => info.leadId === leadId);
            const pageOfLead = Math.floor(leadIndex / rowsPerPage);
            if (!newSelectedPages.includes(pageOfLead)) {
                newSelectedPages.push(pageOfLead);
            }
        });

        setSelectedPages(newSelectedPages.sort((a, b) => a - b));
        setSelectedCheckboxCount(selectedCheckboxes.size);

        const filteredTotalPageCount = Math.ceil(filteredLeads.length / rowsPerPage);
        if (page >= filteredTotalPageCount) {
            setPage(filteredTotalPageCount - 1);
        }
    }, [rowsPerPage, selectedCheckboxes, leadReviewInfo, filteredLeads.length]);

    const handleGoButtonClick = () => {
        if (inputPage >= 1 && inputPage <= totalPageCount) {
            setPage(inputPage - 1);
        }
    };

    const handleSearchToggle = () => {
        setSearchVisible(!searchVisible);
    };

    const handleCheckboxToggle = (leadId: string) => {
        if (selectedCheckboxes.has(leadId)) {
            selectedCheckboxes.delete(leadId);
        } else {
            selectedCheckboxes.add(leadId);
        }

        const newSelectedPages: number[] = [];
        selectedCheckboxes.forEach((leadId) => {
            const leadIndex = leadReviewInfo.findIndex((info) => info.leadId === leadId);
            const pageOfLead = Math.floor(leadIndex / rowsPerPage);
            if (!newSelectedPages.includes(pageOfLead)) {
                newSelectedPages.push(pageOfLead);
            }
        });

        setSelectedPages(newSelectedPages.sort((a, b) => a - b));
        setSelectedCheckboxCount(selectedCheckboxes.size);
    };

    const handleSelectAll = () => {
        leadReviewInfo.forEach((info) => {
            selectedCheckboxes.add(info.leadId);
        });

        const newSelectedPages: number[] = [];
        selectedCheckboxes.forEach((leadId) => {
            const leadIndex = leadReviewInfo.findIndex((info) => info.leadId === leadId);
            const pageOfLead = Math.floor(leadIndex / rowsPerPage);
            if (!newSelectedPages.includes(pageOfLead)) {
                newSelectedPages.push(pageOfLead);
            }
        });

        setSelectedPages(newSelectedPages.sort((a, b) => a - b));
        setSelectedCheckboxCount(selectedCheckboxes.size);
    };

    const handleSelectAllInPage = () => {
        const currentPageStartIndex = page * rowsPerPage;
        const currentPageEndIndex = Math.min(currentPageStartIndex + rowsPerPage, filteredLeads.length);

        const leadsOnCurrentPage = filteredLeads.slice(currentPageStartIndex, currentPageEndIndex);
        leadsOnCurrentPage.forEach((info) => {
            selectedCheckboxes.add(info.leadId);
        });

        const newSelectedPages: number[] = [];
        selectedCheckboxes.forEach((leadId) => {
            const leadIndex = filteredLeads.findIndex((info) => info.leadId === leadId);
            const pageOfLead = Math.floor(leadIndex / rowsPerPage);
            if (!newSelectedPages.includes(pageOfLead)) {
                newSelectedPages.push(pageOfLead);
            }
        });

        setSelectedPages(newSelectedPages.sort((a, b) => a - b));
        setSelectedCheckboxCount(selectedCheckboxes.size);
    };

    const handleUnselectAllInPage = () => {
        const currentPageStartIndex = page * rowsPerPage;
        const currentPageEndIndex = Math.min(currentPageStartIndex + rowsPerPage, filteredLeads.length);

        const leadsOnCurrentPage = filteredLeads.slice(currentPageStartIndex, currentPageEndIndex);
        leadsOnCurrentPage.forEach((info) => {
            selectedCheckboxes.delete(info.leadId);
        });

        const newSelectedPages: number[] = [];
        selectedCheckboxes.forEach((leadId) => {
            const leadIndex = filteredLeads.findIndex((info) => info.leadId === leadId);
            if (leadIndex !== -1) {
                const pageOfLead = Math.floor(leadIndex / rowsPerPage);
                if (!newSelectedPages.includes(pageOfLead)) {
                    newSelectedPages.push(pageOfLead);
                }
            }
        });

        setSelectedPages(newSelectedPages.sort((a, b) => a - b));
        setSelectedCheckboxCount(selectedCheckboxes.size);
    };

    const handleUnselectAll = () => {
        selectedCheckboxes.clear();

        const newSelectedPages: number[] = [];
        selectedCheckboxes.forEach((leadId) => {
            const leadIndex = leadReviewInfo.findIndex((info) => info.leadId === leadId);
            const pageOfLead = Math.floor(leadIndex / rowsPerPage);
            if (!newSelectedPages.includes(pageOfLead)) {
                newSelectedPages.push(pageOfLead);
            }
        });

        setSelectedPages(newSelectedPages.sort((a, b) => a - b));
        setSelectedCheckboxCount(0);
    };

    const handleSubmenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
        setSubmenuAnchorEl(event.currentTarget);
    };

    const handleSubmenuClose = () => {
        setSubmenuAnchorEl(null);
    };

    const handleDensityMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
        setDensityAnchorEl(event.currentTarget);
    };

    const handleDensityMenuClose = () => {
        setDensityAnchorEl(null);
    };

    const handleDensityChange = (density: 'small' | 'medium' | 'large') => {
        setListItemDensity(density);
        localStorage.setItem('listItemDensity', density);
        handleDensityMenuClose();
    };

    useEffect(() => {
        const savedDensity = localStorage.getItem('listItemDensity');
        if (savedDensity) {
            setListItemDensity(savedDensity as 'small' | 'medium' | 'large');
        }
    }, []);


    const handleOpenDialog = () => {
        setDialogOpen(true);
    };

    const handleCloseDialog = () => {
        setDialogOpen(false);
    };

    const handleSubmitDialog = async () => {
        if (selectedCheckboxes.size > 0) {
            const personalizedMessageIds = Array.from(selectedCheckboxes).map(leadId => {
                const leadInfo = leadReviewInfo.find(info => info.leadId === leadId);
                return leadInfo ? leadInfo.personalizedMessageId : null;
            }).filter(id => id !== null) as string[];

            try {
                await axios.post(`${process.env.REACT_APP_API_URL}/campaign/personalization/enqueue-rewrite-bulk-messages`, {
                    personalizedMessageIds,
                    instructions,
                    delay,
                    types: messageTypes,
                    firstStepOnly,
                    campaignId: selectedCampaignId
                });
                setSnackbarMessage('All specified message contents enqueued successfully');
                setSnackbarSeverity('success');
                setSnackbarOpen(true);
            } catch (error: any) {
                setSnackbarMessage(error.response?.data?.error || 'An error occurred while enqueuing the message contents');
                setSnackbarSeverity('error');
                setSnackbarOpen(true);
            }
        }

        setDialogOpen(false);
    };

    if (searchQuery !== "") {
        filteredLeads = filteredLeads.filter((info) =>
            info.companyname?.toLowerCase().includes(searchQuery.toLowerCase()) ||
            info.email?.toLowerCase().includes(searchQuery.toLowerCase()) ||
            info.phone?.includes(searchQuery.toLowerCase())
        );
    }

    if (showApproved || showPending || showQueued || showCompleted || showReplied) {
        filteredLeads = filteredLeads.filter((info) => {
            const approvedMatch = showApproved && info.approved;
            const pendingMatch = showPending && info.leadStatus === 'pending';
            const queuedMatch = showQueued && info.leadStatus === 'queued';
            const completedMatch = showCompleted && info.leadStatus === 'completed';
            const repliedMatch = showReplied && info.replied;

            return approvedMatch || pendingMatch || queuedMatch || completedMatch || repliedMatch;
        });
    }

    useEffect(() => {
        const filteredTotalPageCount = Math.ceil(filteredLeads.length / rowsPerPage);
        if (page >= filteredTotalPageCount) {
            setPage(Math.max(filteredTotalPageCount - 1, 0)); // Ensure page is at least 0
        }
    }, [filteredLeads.length, rowsPerPage]);

    useEffect(() => {
        //@ts-ignore
        const socket = io(process.env.REACT_APP_API_URL);

        if (selectedCampaignId && userId) {
            socket.on('connect', () => {
                console.log('Connected to server');
                socket.emit('joinCampaignRoom', { userId, campaignId: selectedCampaignId });
            });

            socket.on('personalizedMessageAIFixJobCounts', (data) => {
                setPersonalizedMessageAIFixJobCounts(data);
            });

            socket.on('personalizedMessageAIFixProgress', (data) => {
                console.log('AI message regeneration progress:', data);
                setJobStatus(prevStatus => ({
                    ...prevStatus,
                    [data.personalizedMessageId]: data.status
                }));
            });

            return () => {
                socket.emit('leaveCampaignRoom', { userId, campaignId: selectedCampaignId });
                socket.disconnect();
            };
        }

        return () => { };
    }, [selectedCampaignId, userId]);


    const getStatusIcon = (status: "pending" | "queued" | "completed" | "inprogress"): React.ReactElement | null => {
        let icon: React.ReactElement | null = null;
        let tooltipText = '';

        switch (status) {
            case 'pending':
                icon = <PendingActionsSharp sx={{ fontSize: "25px" }} color="secondary" />;
                tooltipText = 'Pending';
                break;
            case 'queued':
                icon = <ScheduleSendSharp sx={{ fontSize: "25px" }} color="primary" />;
                tooltipText = 'Queued';
                break;
            case 'completed':
                icon = <AssignmentTurnedInSharp sx={{ fontSize: "25px" }} color="success" />;
                tooltipText = 'Completed';
                break;
            case 'inprogress':
                icon = <LocalShippingSharp sx={{ fontSize: "25px" }} color="secondary" />;
                tooltipText = 'In Progress';
                break;
            default:
                return null;
        }

        return icon ? (
            <CustomTooltip title={tooltipText} arrow>
                {icon}
            </CustomTooltip>
        ) : null;
    };



    if (!leadReviewInfo) {
        return <CircularProgress />;
    }

    return (
        <>
            <Grid container spacing={2} alignItems="center" justifyContent="space-between" style={{ marginBottom: '10px' }}>
                <Grid item>
                    <IconButton onClick={handleSearchToggle}>
                        <SearchSharp />
                    </IconButton>
                </Grid>
                <Grid item>
                    <Button
                        color="primary"
                        variant="text"
                        onClick={() => {
                            if (selectedCheckboxes.size > 0) {
                                const personalizedMessageIds = Array.from(selectedCheckboxes).map(leadId => {
                                    const leadInfo = leadReviewInfo.find(info => info.leadId === leadId);
                                    return leadInfo ? leadInfo.personalizedMessageId : null;
                                }).filter(id => id !== null) as string[];
                                approveLeads.mutate({ personalizedMessageIds, approve: true });
                            }
                        }}
                    >
                        <ThumbUpAltSharp />
                    </Button>
                </Grid>
                <Grid item>
                    <Button
                        color="secondary"
                        variant="text"
                        onClick={() => {
                            if (selectedCheckboxes.size > 0) {
                                const personalizedMessageIds = Array.from(selectedCheckboxes).map(leadId => {
                                    const leadInfo = leadReviewInfo.find(info => info.leadId === leadId);
                                    return leadInfo ? leadInfo.personalizedMessageId : null;
                                }).filter(id => id !== null) as string[];
                                approveLeads.mutate({ personalizedMessageIds, approve: false });
                            }
                        }}
                    >
                        <ThumbDownAltSharp />
                    </Button>
                </Grid>
                <Grid item>
                    <CustomTooltip title={"More..."} >
                        <IconButton
                            color="primary"
                            onClick={handleMoreMenuOpen}
                        >
                            <MoreVertIcon />
                        </IconButton>
                    </CustomTooltip>
                    <Menu
                        anchorEl={moreAnchorEl}
                        keepMounted
                        open={Boolean(moreAnchorEl)}
                        onClose={handleMoreMenuClose}
                    >
                        <MenuItem
                            onClick={() => {
                                handleMoreMenuClose();
                                if (selectedCheckboxes.size > 0) {
                                    const personalizedMessageIds = Array.from(selectedCheckboxes).map(leadId => {
                                        const leadInfo = leadReviewInfo.find(info => info.leadId === leadId);
                                        return leadInfo ? leadInfo.personalizedMessageId : null;
                                    }).filter(id => id !== null) as string[];
                                    unqueueMessages.mutate({ personalizedMessageIds });
                                }
                            }}
                        >
                            Unqueue Messages
                        </MenuItem>
                        <MenuItem
                            onClick={() => {
                                handleMoreMenuClose();
                                handleOpenDialog();
                            }}
                        >
                            Regenerate AI Content
                        </MenuItem>
                        <Divider />
                        <>
                            {(personalizedMessageAIFixJobCounts.active > 0 ||
                                personalizedMessageAIFixJobCounts.waiting > 0 ||
                                personalizedMessageAIFixJobCounts.completed > 0 ||
                                personalizedMessageAIFixJobCounts.delayed > 0 ||
                                personalizedMessageAIFixJobCounts.failed > 0 ||
                                personalizedMessageAIFixJobCounts.totalScheduled > 0) && (
                                    <div>
                                        <h4>AI Message Regeneration Status</h4>
                                        <p>Completed: {personalizedMessageAIFixJobCounts.completed}</p>
                                        <p>Delayed: {personalizedMessageAIFixJobCounts.delayed}</p>
                                        <p>Failed: {personalizedMessageAIFixJobCounts.failed}</p>
                                    </div>
                                )}
                        </>
                    </Menu>
                </Grid>



                <Grid item>
                    <Button
                        startIcon={<DensitySmallSharp />}
                        onClick={handleDensityMenuOpen}
                        aria-controls="density-menu"
                        aria-haspopup="true"
                    />
                    <Menu
                        id="density-menu"
                        anchorEl={densityAnchorEl}
                        keepMounted
                        open={Boolean(densityAnchorEl)}
                        onClose={handleDensityMenuClose}
                    >
                        <MenuItem onClick={() => handleDensityChange('small')}><DensitySmallSharp /></MenuItem>
                        <MenuItem onClick={() => handleDensityChange('medium')}><DensityMediumSharp /></MenuItem>
                        <MenuItem onClick={() => handleDensityChange('large')}><DensityLargeSharp /></MenuItem>
                    </Menu>
                </Grid>
                {searchVisible && (
                    <Grid item xs={12}>
                        <TextField
                            label="Search Leads"
                            variant="outlined"
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                            fullWidth
                        />
                    </Grid>
                )}
            </Grid>
            <TableContainer component={Paper} style={{ height: '56vh', overflowY: 'auto' }}>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell padding="checkbox">
                                <Grid item>
                                    <Button
                                        onClick={handleSubmenuOpen}
                                        aria-controls="submenu"
                                        aria-haspopup="true"
                                    >
                                        {selectedCheckboxCount === 0 ? (
                                            <FilterAltSharp color="primary" />
                                        ) : selectedCheckboxCount === filteredLeads.length ? (
                                            <LibraryAddCheckSharp color="primary" />
                                        ) : (
                                            <IndeterminateCheckBoxSharp color="secondary" />
                                        )}
                                    </Button>
                                    <Menu
                                        id="submenu"
                                        anchorEl={submenuAnchorEl}
                                        keepMounted
                                        open={Boolean(submenuAnchorEl)}
                                        onClose={handleSubmenuClose}
                                    >
                                        <MenuItem dense>
                                            <Checkbox
                                                checked={showApproved}
                                                onChange={(e) => setShowApproved(e.target.checked)}
                                                color="primary"
                                                size="small"
                                            />
                                            Approved
                                        </MenuItem>
                                        <MenuItem dense>
                                            <Checkbox
                                                checked={showPending}
                                                onChange={(e) => setShowPending(e.target.checked)}
                                                color="primary"
                                                size="small"
                                            />
                                            Pending
                                        </MenuItem>
                                        <MenuItem dense>
                                            <Checkbox
                                                checked={showQueued}
                                                onChange={(e) => setShowQueued(e.target.checked)}
                                                color="primary"
                                                size="small"
                                            />
                                            Queued
                                        </MenuItem>
                                        <MenuItem dense>
                                            <Checkbox
                                                checked={showCompleted}
                                                onChange={(e) => setShowCompleted(e.target.checked)}
                                                color="primary"
                                                size="small"
                                            />
                                            Completed
                                        </MenuItem>
                                        <MenuItem dense>
                                            <Checkbox
                                                checked={showReplied}
                                                onChange={(e) => setShowReplied(e.target.checked)}
                                                color="primary"
                                                size="small"
                                            />
                                            Replied
                                        </MenuItem>
                                        <Divider />
                                        <MenuItem onClick={handleSelectAll}>
                                            Select All
                                        </MenuItem>
                                        <Divider />
                                        <MenuItem onClick={handleSelectAllInPage}>
                                            Select All in This Page
                                        </MenuItem>
                                        <Divider />
                                        <MenuItem onClick={handleUnselectAll}>
                                            Unselect All
                                        </MenuItem>
                                        <Divider />
                                        <MenuItem onClick={handleUnselectAllInPage}>
                                            Unselect All in This Page
                                        </MenuItem>
                                    </Menu>
                                </Grid>
                            </TableCell>
                            <TableCell>Company Name</TableCell>
                            {/* <TableCell>Full Name</TableCell>
                            <TableCell>Email</TableCell>
                            <TableCell>Phone</TableCell> */}
                            <TableCell>Approved?</TableCell>
                            <TableCell>Status</TableCell>
                            {Object.keys(jobStatus).length > 0 && <TableCell>AI Generation</TableCell>}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredLeads.slice(startIndex, endIndex).map((info) => (
                            <TableRow
                                key={info.leadId}
                                hover
                                onClick={() => handleLeadClick(info.leadId)}
                                onDoubleClick={() => handleCheckboxToggle(info.leadId)}
                                selected={selectedLead === info.leadId}
                                style={styles[listItemDensity]} // Apply styles based on density
                            >
                                <TableCell padding="checkbox" style={styles[listItemDensity]}>
                                    <Checkbox
                                        color="primary"
                                        checked={selectedCheckboxes.has(info.leadId)}
                                        onChange={() => handleCheckboxToggle(info.leadId)}
                                    />
                                </TableCell>
                                <TableCell style={styles[listItemDensity]}>
                                    {info.companyname && info.companyname.trim() ? (
                                        <>
                                            <BusinessIcon color='secondary' /> {info.companyname}
                                        </>
                                    ) : null}
                                </TableCell>
                                <TableCell style={styles[listItemDensity]}>{info.approved ? <RecommendSharpIcon color='success' /> : <PanoramaFishEyeSharpIcon color="primary" />}</TableCell>
                                <TableCell style={styles[listItemDensity]}>
                                    {getStatusIcon(info.leadStatus)}
                                </TableCell>
                                {Object.keys(jobStatus).length > 0 && (
                                    <TableCell style={styles[listItemDensity]}>
                                        {jobStatus[info.personalizedMessageId] && (
                                            <>
                                                {jobStatus[info.personalizedMessageId] === 'completed' && (
                                                    <Tooltip title="AI Rewrite Completed">
                                                        <CheckCircleSharp color="success" />
                                                    </Tooltip>
                                                )}
                                                {jobStatus[info.personalizedMessageId] === 'delayed' && (
                                                    <Tooltip title="AI Rewrite Delayed">
                                                        <HourglassEmptySharp color="warning" />
                                                    </Tooltip>
                                                )}
                                                {jobStatus[info.personalizedMessageId] === 'failed' && (
                                                    <Tooltip title="AI Rewrite Failed">
                                                        <ErrorSharp color="error" />
                                                    </Tooltip>
                                                )}
                                            </>
                                        )}
                                    </TableCell>
                                )}
                            </TableRow>
                        ))}
                    </TableBody>



                </Table>
            </TableContainer>

            <Grid container alignItems="center" justifyContent="space-between" style={{ padding: '10px' }}>
                <Divider />
                <Grid item>
                    <Pagination
                        count={totalPageCount}
                        page={page + 1}
                        onChange={(event, newPage) => setPage(newPage - 1)}
                        showFirstButton
                        showLastButton
                        color="primary"
                        size="small"
                    />
                </Grid>
                <Divider />
                <div>
                    {selectedCheckboxCount} selected
                    {selectedCheckboxCount > 0 && (
                        <span style={{ marginLeft: '10px' }}>
                            in pages {formatSelectedPages(selectedPages)}
                        </span>
                    )}
                </div>
                <Grid item style={{ display: 'flex', alignItems: 'center', padding: '10px' }}>
                    <Select
                        variant='standard'
                        value={rowsPerPage}
                        onChange={(event) => setRowsPerPage(event.target.value as number)}
                        style={{ marginLeft: '10px', width: '80px' }}
                        size="small"
                    >
                        <h3>Per Page</h3>
                        <Divider />
                        {[10, 20, 30, 50, 100].map((num) => (
                            <MenuItem key={num} value={num}>
                                {num}
                            </MenuItem>
                        ))}
                    </Select>
                    <TextField
                        label="Go to"
                        type="number"
                        size="small"
                        value={inputPage}
                        onChange={(event) => setInputPage(Number(event.target.value))}
                        onKeyPress={(event) => {
                            if (event.key === 'Enter') {
                                handleGoButtonClick();
                            }
                        }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton onClick={handleGoButtonClick}>
                                        <NavigateNextIcon />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                        style={{ marginLeft: '10px', width: '140px' }}
                    />
                </Grid>
            </Grid>
            <Dialog open={dialogOpen} onClose={handleCloseDialog}>
                <DialogTitle>Regenerate AI Content</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Please specify the options for regenerating AI content.
                    </DialogContentText>
                    <FormGroup>
                        <TextField
                            label="Instructions"
                            fullWidth
                            multiline
                            rows={4}
                            variant="outlined"
                            value={instructions}
                            onChange={(e) => setInstructions(e.target.value)}
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={messageTypes.includes('email')}
                                    onChange={(e) => {
                                        const newTypes = e.target.checked
                                            ? [...messageTypes, 'email']
                                            : messageTypes.filter(type => type !== 'email');
                                        setMessageTypes(newTypes);
                                    }}
                                />
                            }
                            label="Email"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={messageTypes.includes('sms')}
                                    onChange={(e) => {
                                        const newTypes = e.target.checked
                                            ? [...messageTypes, 'sms']
                                            : messageTypes.filter(type => type !== 'sms');
                                        setMessageTypes(newTypes);
                                    }}
                                />
                            }
                            label="SMS"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={firstStepOnly}
                                    onChange={(e) => setFirstStepOnly(e.target.checked)}
                                />
                            }
                            label="First Step Only"
                        />
                        <TextField
                            label="Delay (seconds)"
                            type="number"
                            value={delay}
                            onChange={(e) => setDelay(Number(e.target.value))}
                            fullWidth
                        />
                    </FormGroup>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleSubmitDialog} color="primary">
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
            >
                <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </>
    );
};

export default LeadsReviewList;
