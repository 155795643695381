import './CampaignLeads.css';

import CloseIcon from '@mui/icons-material/Close';
import { DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Slide from '@mui/material/Slide';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import * as React from 'react';

import UploadCSV2 from '../../3-CRM/UploadBox/UploadBox';



interface ListItem {
    _id: string;
    name: string;
}



const CampaignLeadsDialog = ({ setShowCloseConfirmDialog, isDialogOpen, setIsDialogOpen, showCloseConfirmDialog }) => {





    const closeDialog = () => {
        setShowCloseConfirmDialog(true);
    };

    const handleConfirmCloseDialog = (confirm) => {
        setShowCloseConfirmDialog(false);
        if (confirm) {
            setIsDialogOpen(false);
        }
    };


    return (
        <>
            <Grid>

                <Dialog
                    fullScreen
                    open={isDialogOpen}
                    onClose={closeDialog}
                    TransitionComponent={Slide}
                >

                    {/* AppBar and Toolbar */}
                    <AppBar sx={{ position: 'relative' }}>
                        <Toolbar>


                            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                            </Typography>
                            {/* Add a button to close the dialog (if needed) */}
                            <IconButton
                                edge="start"
                                color="inherit"
                                onClick={closeDialog}
                                aria-label="close"
                            >
                                <CloseIcon />
                            </IconButton>
                        </Toolbar>
                    </AppBar>

                    {/* Dialog Content */}
                    <List>
                        {/* Add your UploadCSV2 component here */}
                        <ListItem>
                            <ListItemText primary={<UploadCSV2 />} />
                        </ListItem>
                    </List>
                </Dialog>
            </Grid>
            <Dialog
                open={showCloseConfirmDialog}
                onClose={() => setShowCloseConfirmDialog(false)}
            >
                <DialogTitle>{"Are you sure?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        If you close this dialog without uploading, you will lose all progress.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleConfirmCloseDialog(false)} color="success">
                        Cancel
                    </Button>
                    <Button onClick={() => handleConfirmCloseDialog(true)} color="error" autoFocus>
                        Close Anyway
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default CampaignLeadsDialog;
