export const getTimezoneCoordinates = (timezone: string) => {
  const timezoneCoordinates: {[key: string]: [number, number]} = {
    'Africa/Abidjan': [-4.03, 5.32],
    'Africa/Accra': [-0.2, 5.56],
    'Africa/Addis_Ababa': [38.74, 9.03],
    'Africa/Algiers': [3.05, 36.75],
    'Africa/Asmara': [38.93, 15.33],
    'Africa/Bamako': [-7.99, 12.65],
    'Africa/Bangui': [18.56, 4.36],
    'Africa/Banjul': [-16.59, 13.45],
    'Africa/Bissau': [-15.6, 11.86],
    'Africa/Blantyre': [34.99, -15.79],
    'Africa/Brazzaville': [15.27, -4.27],
    'Africa/Bujumbura': [29.36, -3.38],
    'Africa/Cairo': [31.24, 30.05],
    'Africa/Casablanca': [-7.61, 33.59],
    'Africa/Ceuta': [-5.31, 35.89],
    'Africa/Conakry': [-13.7, 9.51],
    'Africa/Dakar': [-17.45, 14.69],
    'Africa/Dar_es_Salaam': [39.28, -6.82],
    'Africa/Djibouti': [43.15, 11.59],
    'Africa/Douala': [9.7, 4.05],
    'Africa/El_Aaiun': [-13.22, 27.15],
    'Africa/Freetown': [-13.23, 8.48],
    'Africa/Gaborone': [25.91, -24.65],
    'Africa/Harare': [31.05, -17.82],
    'Africa/Johannesburg': [28.04, -26.2],
    'Africa/Juba': [31.6, 4.85],
    'Africa/Kampala': [32.58, 0.32],
    'Africa/Khartoum': [32.53, 15.59],
    'Africa/Kigali': [30.06, -1.95],
    'Africa/Kinshasa': [15.32, -4.32],
    'Africa/Lagos': [3.38, 6.45],
    'Africa/Libreville': [9.45, 0.39],
    'Africa/Lome': [1.22, 6.12],
    'Africa/Luanda': [13.23, -8.84],
    'Africa/Lubumbashi': [27.46, -11.67],
    'Africa/Lusaka': [28.3, -15.42],
    'Africa/Malabo': [8.78, 3.75],
    'Africa/Maputo': [32.59, -25.96],
    'Africa/Maseru': [27.48, -29.31],
    'Africa/Mbabane': [31.13, -26.32],
    'Africa/Mogadishu': [45.33, 2.04],
    'Africa/Monrovia': [-10.8, 6.31],
    'Africa/Nairobi': [36.82, -1.29],
    'Africa/Ndjamena': [15.05, 12.11],
    'Africa/Niamey': [2.11, 13.52],
    'Africa/Nouakchott': [-15.98, 18.08],
    'Africa/Ouagadougou': [-1.52, 12.37],
    'Africa/Porto-Novo': [2.61, 6.5],
    'Africa/Sao_Tome': [6.73, 0.34],
    'Africa/Tripoli': [13.18, 32.88],
    'Africa/Tunis': [10.18, 36.81],
    'Africa/Windhoek': [17.08, -22.57],
    'America/Adak': [-176.64, 51.88],
    'America/Anchorage': [-149.9, 61.22],
    'America/Anguilla': [-63.06, 18.22],
    'America/Antigua': [-61.85, 17.12],
    'America/Araguaina': [-48.2, -7.19],
    'America/Argentina/Buenos_Aires': [-58.42, -34.61],
    'America/Argentina/Catamarca': [-65.77, -28.47],
    'America/Argentina/Cordoba': [-64.18, -31.4],
    'America/Argentina/Jujuy': [-65.3, -24.18],
    'America/Argentina/La_Rioja': [-66.87, -29.41],
    'America/Argentina/Mendoza': [-68.83, -32.89],
    'America/Argentina/Rio_Gallegos': [-69.22, -51.62],
    'America/Argentina/Salta': [-65.41, -24.78],
    'America/Argentina/San_Juan': [-68.54, -31.54],
    'America/Argentina/San_Luis': [-66.34, -33.3],
    'America/Argentina/Tucuman': [-65.22, -26.82],
    'America/Argentina/Ushuaia': [-68.3, -54.8],
    'America/Aruba': [-70.03, 12.52],
    'America/Asuncion': [-57.66, -25.3],
    'America/Atikokan': [-91.62, 48.76],
    'America/Bahia': [-38.51, -12.97],
    'America/Bahia_Banderas': [-105.25, 20.74],
    'America/Barbados': [-59.61, 13.1],
    'America/Belem': [-48.5, -1.45],
    'America/Belize': [-88.2, 17.5],
    'America/Blanc-Sablon': [-57.13, 51.43],
    'America/Boa_Vista': [-60.66, 2.82],
    'America/Bogota': [-74.08, 4.61],
    'America/Boise': [-116.2, 43.62],
    'America/Cambridge_Bay': [-105.06, 69.11],
    'America/Campo_Grande': [-54.62, -20.45],
    'America/Cancun': [-86.83, 21.16],
    'America/Caracas': [-66.92, 10.5],
    'America/Cayenne': [-52.33, 4.93],
    'America/Cayman': [-81.25, 19.3],
    'America/Chicago': [-87.63, 41.88],
    'America/Chihuahua': [-106.08, 28.63],
    'America/Costa_Rica': [-84.08, 9.93],
    'America/Creston': [-116.5, 49.1],
    'America/Cuiaba': [-56.1, -15.6],
    'America/Curacao': [-68.9, 12.1],
    'America/Danmarkshavn': [-18.66, 76.77],
    'America/Dawson': [-139.42, 64.06],
    'America/Dawson_Creek': [-120.24, 59.76],
    'America/Denver': [-104.99, 39.74],
    'America/Detroit': [-83.04, 42.33],
    'America/Dominica': [-61.38, 15.3],
    'America/Edmonton': [-113.47, 53.55],
    'America/Eirunepe': [-69.87, -6.66],
    'America/El_Salvador': [-89.19, 13.69],
    'America/Fort_Nelson': [-122.7, 58.8],
    'America/Fortaleza': [-38.52, -3.72],
    'America/Glace_Bay': [-59.95, 46.2],
    'America/Goose_Bay': [-60.43, 53.3],
    'America/Grand_Turk': [-71.14, 21.46],
    'America/Grenada': [-61.75, 12.05],
    'America/Guadeloupe': [-61.53, 16.23],
    'America/Guatemala': [-90.52, 14.63],
    'America/Guayaquil': [-79.9, -2.2],
    'America/Guyana': [-58.16, 6.8],
    'America/Halifax': [-63.58, 44.65],
    'America/Havana': [-82.37, 23.12],
    'America/Hermosillo': [-110.97, 29.07],
    'America/Indiana/Indianapolis': [-86.16, 39.77],
    'America/Indiana/Knox': [-86.62, 41.3],
    'America/Indiana/Marengo': [-86.34, 38.37],
    'America/Indiana/Petersburg': [-87.28, 38.5],
    'America/Indiana/Tell_City': [-86.77, 37.95],
    'America/Indiana/Vevay': [-85.07, 38.75],
    'America/Indiana/Vincennes': [-87.53, 38.68],
    'America/Indiana/Winamac': [-86.6, 41.05],
    'America/Inuvik': [-133.72, 68.36],
    'America/Iqaluit': [-68.52, 63.74],
    'America/Jamaica': [-76.8, 18.02],
    'America/Juneau': [-134.41, 58.3],
    'America/Kentucky/Louisville': [-85.76, 38.25],
    'America/Kentucky/Monticello': [-84.85, 36.83],
    'America/Kralendijk': [-68.27, 12.15],
    'America/La_Paz': [-68.15, -16.5],
    'America/Lima': [-77.03, -12.04],
    'America/Los_Angeles': [-118.24, 34.05],
    'America/Lower_Princes': [-63.05, 18.05],
    'America/Maceio': [-35.71, -9.65],
    'America/Managua': [-86.29, 12.15],
    'America/Manaus': [-60.03, -3.1],
    'America/Marigot': [-63.08, 18.07],
    'America/Martinique': [-61.08, 14.61],
    'America/Matamoros': [-97.5, 25.87],
    'America/Mazatlan': [-106.42, 23.25],
    'America/Menominee': [-87.62, 45.11],
    'America/Merida': [-89.62, 20.97],
    'America/Metlakatla': [-131.57, 55.13],
    'America/Mexico_City': [-99.13, 19.43],
    'America/Miquelon': [-56.36, 46.77],
    'America/Moncton': [-64.77, 46.09],
    'America/Monterrey': [-100.32, 25.67],
    'America/Montevideo': [-56.18, -34.9],
    'America/Montreal': [-73.57, 45.5],
    'America/Montserrat': [-62.2, 16.75],
    'America/Nassau': [-77.35, 25.03],
    'America/New_York': [-74.01, 40.71],
    'America/Nipigon': [-88.26, 49.01],
    'America/Nome': [-165.41, 64.5],
    'America/Noronha': [-32.42, -3.85],
    'America/North_Dakota/Beulah': [-101.77, 47.27],
    'America/North_Dakota/Center': [-101.29, 47.11],
    'America/North_Dakota/New_Salem': [-101.41, 46.84],
    'America/Nuuk': [-51.72, 64.18],
    'America/Ojinaga': [-104.42, 29.56],
    'America/Panama': [-79.53, 8.97],
    'America/Pangnirtung': [-65.72, 66.15],
    'America/Paramaribo': [-55.17, 5.87],
    'America/Phoenix': [-112.07, 33.45],
    'America/Port-au-Prince': [-72.33, 18.54],
    'America/Port_of_Spain': [-61.52, 10.67],
    'America/Porto_Velho': [-63.9, -8.76],
    'America/Puerto_Rico': [-66.11, 18.47],
    'America/Punta_Arenas': [-70.91, -53.16],
    'America/Rainy_River': [-94.57, 48.72],
    'America/Rankin_Inlet': [-92.08, 62.82],
    'America/Recife': [-34.9, -8.05],
    'America/Regina': [-104.61, 50.45],
    'America/Resolute': [-94.83, 74.69],
    'America/Rio_Branco': [-67.8, -9.97],
    'America/Santarem': [-54.7, -2.43],
    'America/Santiago': [-70.65, -33.45],
    'America/Santo_Domingo': [-69.9, 18.48],
    'America/Sao_Paulo': [-46.63, -23.55],
    'America/Scoresbysund': [-21.97, 70.49],
    'America/Sitka': [-135.33, 57.05],
    'America/St_Barthelemy': [-62.85, 17.9],
    'America/St_Johns': [-52.71, 47.56],
    'America/St_Kitts': [-62.72, 17.3],
    'America/St_Lucia': [-61.01, 14.01],
    'America/St_Thomas': [-64.93, 18.35],
    'America/St_Vincent': [-61.2, 13.16],
    'America/Swift_Current': [-107.79, 50.28],
    'America/Tegucigalpa': [-87.21, 14.1],
    'America/Thule': [-68.77, 76.57],
    'America/Thunder_Bay': [-89.25, 48.39],
    'America/Tijuana': [-117.04, 32.53],
    'America/Toronto': [-79.38, 43.65],
    'America/Tortola': [-64.62, 18.42],
    'America/Vancouver': [-123.12, 49.28],
    'America/Whitehorse': [-135.06, 60.72],
    'America/Winnipeg': [-97.14, 49.89],
    'America/Yakutat': [-139.73, 59.55],
    'America/Yellowknife': [-114.37, 62.45],
    'Antarctica/Casey': [110.52, -66.28],
    'Antarctica/Davis': [77.97, -68.58],
    'Antarctica/DumontDUrville': [140.01, -66.67],
    'Antarctica/Macquarie': [158.95, -54.5],
    'Antarctica/Mawson': [62.87, -67.6],
    'Antarctica/Palmer': [-64.05, -64.77],
    'Antarctica/Rothera': [-68.13, -67.57],
    'Antarctica/Syowa': [39.59, -69],
    'Antarctica/Troll': [2.53, -72],
    'Antarctica/Vostok': [106.9, -78.47],
    'Arctic/Longyearbyen': [15.62, 78.22],
    'Asia/Aden': [45.04, 12.79],
    'Asia/Almaty': [76.95, 43.25],
    'Asia/Amman': [35.93, 31.95],
    'Asia/Anadyr': [177.51, 64.75],
    'Asia/Aqtau': [51.2, 44.52],
    'Asia/Aqtobe': [57.17, 50.28],
    'Asia/Ashgabat': [58.39, 37.95],
    'Asia/Atyrau': [51.88, 47.12],
    'Asia/Baghdad': [44.36, 33.34],
    'Asia/Bahrain': [50.58, 26.23],
    'Asia/Baku': [49.87, 40.37],
    'Asia/Bangkok': [100.52, 13.75],
    'Asia/Barnaul': [83.76, 53.36],
    'Asia/Beirut': [35.51, 33.9],
    'Asia/Bishkek': [74.59, 42.87],
    'Asia/Brunei': [114.93, 4.94],
    'Asia/Chita': [113.48, 52.03],
    'Asia/Choibalsan': [114.52, 48.08],
    'Asia/Colombo': [79.86, 6.93],
    'Asia/Damascus': [36.29, 33.51],
    'Asia/Dhaka': [90.41, 23.72],
    'Asia/Dili': [125.57, -8.56],
    'Asia/Dubai': [55.27, 25.2],
    'Asia/Dushanbe': [68.79, 38.54],
    'Asia/Famagusta': [33.96, 35.12],
    'Asia/Gaza': [34.46, 31.5],
    'Asia/Hebron': [35.1, 31.53],
    'Asia/Ho_Chi_Minh': [106.67, 10.77],
    'Asia/Hong_Kong': [114.17, 22.28],
    'Asia/Hovd': [91.65, 48],
    'Asia/Irkutsk': [104.3, 52.3],
    'Asia/Jakarta': [106.85, -6.21],
    'Asia/Jayapura': [140.71, -2.53],
    'Asia/Jerusalem': [35.21, 31.78],
    'Asia/Kabul': [69.18, 34.53],
    'Asia/Kamchatka': [158.65, 53.02],
    'Asia/Karachi': [67.08, 24.86],
    'Asia/Kathmandu': [85.32, 27.71],
    'Asia/Khandyga': [135.58, 62.65],
    'Asia/Kolkata': [88.36, 22.57],
    'Asia/Krasnoyarsk': [92.87, 56.01],
    'Asia/Kuala_Lumpur': [101.69, 3.14],
    'Asia/Kuching': [110.35, 1.55],
    'Asia/Kuwait': [47.98, 29.37],
    'Asia/Macau': [113.54, 22.2],
    'Asia/Magadan': [150.8, 59.56],
    'Asia/Makassar': [119.41, -5.14],
    'Asia/Manila': [120.98, 14.6],
    'Asia/Muscat': [58.59, 23.61],
    'Asia/Nicosia': [33.36, 35.17],
    'Asia/Novokuznetsk': [87.11, 53.76],
    'Asia/Novosibirsk': [82.93, 55.03],
    'Asia/Omsk': [73.37, 54.98],
    'Asia/Oral': [51.23, 51.23],
    'Asia/Phnom_Penh': [104.92, 11.56],
    'Asia/Pontianak': [109.33, -0.03],
    'Asia/Pyongyang': [125.75, 39.02],
    'Asia/Qatar': [51.53, 25.28],
    'Asia/Qostanay': [63.62, 53.22],
    'Asia/Qyzylorda': [65.47, 44.8],
    'Asia/Riyadh': [46.72, 24.65],
    'Asia/Sakhalin': [142.73, 46.96],
    'Asia/Samarkand': [66.98, 39.67],
    'Asia/Seoul': [126.98, 37.57],
    'Asia/Shanghai': [121.47, 31.23],
    'Asia/Singapore': [103.85, 1.29],
    'Asia/Srednekolymsk': [153.71, 67.46],
    'Asia/Taipei': [121.56, 25.03],
    'Asia/Tashkent': [69.24, 41.29],
    'Asia/Tbilisi': [44.78, 41.72],
    'Asia/Tehran': [51.42, 35.7],
    'Asia/Thimphu': [89.64, 27.48],
    'Asia/Tokyo': [139.69, 35.69],
    'Asia/Tomsk': [84.97, 56.5],
    'Asia/Ulaanbaatar': [106.92, 47.91],
    'Asia/Urumqi': [87.62, 43.83],
    'Asia/Ust-Nera': [143.2, 64.57],
    'Asia/Vientiane': [102.6, 17.97],
    'Asia/Vladivostok': [131.92, 43.12],
    'Asia/Yakutsk': [129.73, 62.03],
    'Asia/Yangon': [96.16, 16.78],
    'Asia/Yekaterinburg': [60.6, 56.85],
    'Asia/Yerevan': [44.52, 40.18],
    'Atlantic/Azores': [-25.67, 37.74],
    'Atlantic/Bermuda': [-64.78, 32.3],
    'Atlantic/Canary': [-15.43, 28.1],
    'Atlantic/Cape_Verde': [-23.51, 14.92],
    'Atlantic/Faroe': [-6.92, 62.02],
    'Atlantic/Madeira': [-16.91, 32.64],
    'Atlantic/Reykjavik': [-21.94, 64.14],
    'Atlantic/South_Georgia': [-36.59, -54.28],
    'Atlantic/St_Helena': [-5.71, -15.94],
    'Atlantic/Stanley': [-57.85, -51.7],
    'Australia/Adelaide': [138.6, -34.93],
    'Australia/Brisbane': [153.03, -27.47],
    'Australia/Broken_Hill': [141.45, -31.97],
    'Australia/Darwin': [130.84, -12.46],
    'Australia/Eucla': [128.87, -31.72],
    'Australia/Hobart': [147.32, -42.88],
    'Australia/Lindeman': [149.0, -20.27],
    'Australia/Lord_Howe': [159.08, -31.52],
    'Australia/Melbourne': [144.96, -37.81],
    'Australia/Perth': [115.86, -31.95],
    'Australia/Sydney': [151.21, -33.87],
    'Europe/Amsterdam': [4.89, 52.37],
    'Europe/Andorra': [1.52, 42.51],
    'Europe/Astrakhan': [48.04, 46.35],
    'Europe/Athens': [23.72, 37.98],
    'Europe/Belgrade': [20.46, 44.82],
    'Europe/Berlin': [13.41, 52.52],
    'Europe/Bratislava': [17.11, 48.15],
    'Europe/Brussels': [4.35, 50.85],
    'Europe/Bucharest': [26.1, 44.43],
    'Europe/Budapest': [19.04, 47.5],
    'Europe/Busingen': [8.69, 47.7],
    'Europe/Chisinau': [28.86, 47.01],
    'Europe/Copenhagen': [12.57, 55.68],
    'Europe/Dublin': [-6.26, 53.34],
    'Europe/Gibraltar': [-5.35, 36.14],
    'Europe/Guernsey': [-2.54, 49.46],
    'Europe/Helsinki': [24.94, 60.17],
    'Europe/Isle_of_Man': [-4.48, 54.15],
    'Europe/Istanbul': [28.98, 41.01],
    'Europe/Jersey': [-2.11, 49.21],
    'Europe/Kaliningrad': [20.45, 54.71],
    'Europe/Kiev': [30.52, 50.45],
    'Europe/Kirov': [49.66, 58.6],
    'Europe/Lisbon': [-9.14, 38.72],
    'Europe/Ljubljana': [14.51, 46.05],
    'Europe/London': [-0.13, 51.51],
    'Europe/Luxembourg': [6.13, 49.61],
    'Europe/Madrid': [-3.7, 40.42],
    'Europe/Malta': [14.51, 35.9],
    'Europe/Mariehamn': [19.93, 60.1],
    'Europe/Minsk': [27.57, 53.9],
    'Europe/Monaco': [7.42, 43.74],
    'Europe/Moscow': [37.62, 55.75],
    'Europe/Oslo': [10.75, 59.91],
    'Europe/Paris': [2.35, 48.85],
    'Europe/Podgorica': [19.27, 42.44],
    'Europe/Prague': [14.42, 50.08],
    'Europe/Riga': [24.11, 56.95],
    'Europe/Rome': [12.49, 41.89],
    'Europe/Samara': [50.19, 53.2],
    'Europe/San_Marino': [12.45, 43.94],
    'Europe/Sarajevo': [18.42, 43.86],
    'Europe/Saratov': [45.0, 51.57],
    'Europe/Simferopol': [34.11, 44.95],
    'Europe/Skopje': [21.43, 41.99],
    'Europe/Sofia': [23.32, 42.7],
    'Europe/Stockholm': [18.06, 59.33],
    'Europe/Tallinn': [24.75, 59.44],
    'Europe/Tirane': [19.82, 41.33],
    'Europe/Ulyanovsk': [48.4, 54.32],
    'Europe/Uzhgorod': [22.3, 48.62],
    'Europe/Vaduz': [9.52, 47.14],
    'Europe/Vatican': [12.45, 41.9],
    'Europe/Vienna': [16.37, 48.21],
    'Europe/Vilnius': [25.28, 54.69],
    'Europe/Volgograd': [44.51, 48.72],
    'Europe/Warsaw': [21.02, 52.23],
    'Europe/Zagreb': [15.98, 45.81],
    'Europe/Zaporozhye': [35.14, 47.84],
    'Europe/Zurich': [8.54, 47.37],
    'Indian/Antananarivo': [47.51, -18.91],
    'Indian/Chagos': [72.57, -7.31],
    'Indian/Christmas': [105.67, -10.45],
    'Indian/Cocos': [96.83, -12.17],
    'Indian/Comoro': [43.26, -11.7],
    'Indian/Kerguelen': [70.22, -49.35],
    'Indian/Mahe': [55.45, -4.68],
    'Indian/Maldives': [73.51, 4.17],
    'Indian/Mauritius': [57.57, -20.16],
    'Indian/Mayotte': [45.27, -12.78],
    'Indian/Reunion': [55.53, -21.12],
    'Pacific/Apia': [-171.76, -13.83],
    'Pacific/Auckland': [174.76, -36.85],
    'Pacific/Bougainville': [155.56, -6.23],
    'Pacific/Chatham': [-176.52, -43.95],
    'Pacific/Chuuk': [151.83, 7.45],
    'Pacific/Easter': [-109.36, -27.12],
    'Pacific/Efate': [168.32, -17.73],
    'Pacific/Enderbury': [-171.08, -3.13],
    'Pacific/Fakaofo': [-171.22, -9.38],
    'Pacific/Fiji': [178.42, -18.12],
    'Pacific/Funafuti': [179.2, -8.52],
    'Pacific/Galapagos': [-89.6, -0.9],
    'Pacific/Gambier': [-134.95, -23.12],
    'Pacific/Guadalcanal': [160.2, -9.43],
    'Pacific/Guam': [144.75, 13.47],
    'Pacific/Honolulu': [-157.86, 21.31],
    'Pacific/Kiritimati': [-157.34, 1.87],
    'Pacific/Kosrae': [162.96, 5.32],
    'Pacific/Kwajalein': [167.73, 8.73],
    'Pacific/Majuro': [171.38, 7.1],
    'Pacific/Marquesas': [-139.49, -9.0],
    'Pacific/Midway': [-177.37, 28.2],
    'Pacific/Nauru': [166.92, -0.52],
    'Pacific/Niue': [-169.92, -19.05],
    'Pacific/Norfolk': [167.96, -29.06],
    'Pacific/Noumea': [166.45, -22.27],
    'Pacific/Pago_Pago': [-170.7, -14.28],
    'Pacific/Palau': [134.48, 7.33],
    'Pacific/Pitcairn': [-130.1, -25.07],
    'Pacific/Pohnpei': [158.27, 6.97],
    'Pacific/Port_Moresby': [147.18, -9.47],
    'Pacific/Rarotonga': [-159.78, -21.23],
    'Pacific/Saipan': [145.75, 15.2],
    'Pacific/Tahiti': [-149.57, -17.53],
    'Pacific/Tarawa': [173.02, 1.35],
    'Pacific/Tongatapu': [-175.2, -21.14],
    'Pacific/Wake': [166.62, 19.28],
    'Pacific/Wallis': [-176.19, -13.3],
    UTC: [0, 0],
  }
  return timezoneCoordinates[timezone] || [0, 0]
}
