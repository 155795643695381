import React, { useState, useEffect } from "react";
import { Tabs, Tab, Paper, Typography } from "@mui/material";
import Reply from "../modules/campaign/Inbox/Components/Reply";
import Inbox from "../modules/campaign/Inbox/Components/Inbox";
import InboxListing from "../modules/campaign/Inbox/Components/InboxListing";
import CampaignTracking from "../modules/campaign/components/Dashboard/CampaignTracking";
import ChatApp from "../modules/SMSChat/smschatParent";
import Leadgen from "./2-GetLeads/LocalLeads/AiLeadgenComponent";
import CRMParentComponent from "./3-CRM/LeadlistsParent";
import CallInterface from "./callInterface";
import AdvancedLeadGenParent from "./2-GetLeads/AdvancedSearch/Parent-LeadGenAdvanced";
import Collections from "./2-GetLeads/AdvancedSearch/Collections/CollectionsParent";
import MailFull from "./000test/Maill";
import WarmupParentComponent from "./WarmupV2/WarmupParent";
import ImageEditor from "./ImageEditor/ImageEditor";
import EmailAccountsParent from "./0-Account/EmailAccounts/ManageEmailAccounts/EmailAccountsParent";
import PasswordProtection from "./0-General/PasswordProtection";
import Flow from "./test/Diagram";

export function Somar() {
  const [selectedTab, setSelectedTab] = useState(0);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const correctPassword = '123456789.';


  return (
    <PasswordProtection correctPassword={correctPassword}>
      <Paper elevation={3} style={{ padding: "20px" }}>
        <Tabs
          value={selectedTab}
          onChange={handleTabChange}
          indicatorColor="primary"
          textColor="primary"
          centered
        >
          <Tab label="Inbox" />
          <Tab label="Email Accounts" />
          <Tab label="Leads Manager" />
          <Tab label="Sequence" />
          <Tab label="Delivery Settings" />
          <Tab label="Campaign Tracking" />
          <Tab label="tabby1" />
          <Tab label="tabby2" />
          <Tab label="tabby3" />
          <Tab label="tabby4" />
          <Tab label="tabby5" />
        </Tabs>
        <div>
          {selectedTab === 0 && (
            <div>
              <Typography variant="h6">Compose New Message</Typography>
              <Inbox />
              <Typography variant="h6">Inbox</Typography>
              <InboxListing />
              <Typography variant="h6">Reply</Typography>
              <Reply />
            </div>
          )}
          {selectedTab === 1 && <Flow />}
          {selectedTab === 2 && <CallInterface />}
          {selectedTab === 3 && <AdvancedLeadGenParent />}
          {selectedTab === 4 && <Collections />}
          {selectedTab === 5 && (
            <div>
              {/*<ChatApp /> */}
              <CampaignTracking className={""} />
            </div>
          )}
          {/* {selectedTab === 6 && <CRMParentComponent />} */}
          {selectedTab === 7 && <ImageEditor />}
          {selectedTab === 8 && (
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
              <Leadgen />
              <MailFull width={windowWidth} />

            </div>
          )}
          {selectedTab === 9 && <div></div>}
          {selectedTab === 10 && <div>
            <EmailAccountsParent />
          </div>}
        </div>
      </Paper>
    </PasswordProtection>
  );
}