import React, { useEffect, useState } from "react";
import { Box, Typography, useTheme } from "@mui/material";
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { useAuth } from '@/src/app/modules/auth'; // Ensure path is correct

dayjs.extend(utc);
dayjs.extend(timezone);

const Clock = ({ size = 480 }) => { // Default size is 480px (30rem)
    const [day, setDay] = useState(dayjs());
    const theme = useTheme();
    const { currentUser } = useAuth();

    // Calculate relative sizes based on the provided size prop
    const relativeSize = size / 480;
    const centerDotSize = 24 * relativeSize;
    const hourHandSize = { width: 8 * relativeSize, height: 90 * relativeSize };
    const minuteHandSize = { width: 4 * relativeSize, height: 100 * relativeSize };
    const secondHandSize = { width: 5 * relativeSize, height: 170 * relativeSize };
    const fontSize = 2.5 * relativeSize;

    useEffect(() => {
        const interval = setInterval(() => {
            if (currentUser) {
                setDay(dayjs().tz(currentUser.timezone));
            }
        }, 1000);
        return () => clearInterval(interval);
    }, [currentUser]);

    if (!currentUser) {
        return <Typography>Loading...</Typography>;
    }

    const commonHandStyles = {
        position: "absolute",
        left: "50%",
        bottom: "50%",
        transformOrigin: "bottom",
    };

    return (
        <Box sx={{
            height: size,
            width: size,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            border: `${0.5 * relativeSize}px solid ${theme.palette.divider}`,
            bgcolor: 'background.paper',
            borderRadius: '50%',
            boxShadow: theme.shadows[3],
            position: 'relative',
            '&::before': {
                content: '""',
                position: 'absolute',
                width: centerDotSize,
                height: centerDotSize,
                bgcolor: 'info.main',
                borderRadius: '50%',
                zIndex: 10000,
            },
        }}>
            <Box sx={{
                ...commonHandStyles,
                height: hourHandSize.height,
                width: hourHandSize.width,
                bgcolor: 'secondary.main',
                borderRadius: `${6 * relativeSize}px ${6 * relativeSize}px 0 0`,
                transform: `translateX(-50%) rotate(${((day.hour() % 12) + day.minute() / 60 + day.second() / 3600) * 30}deg)`,
                zIndex: 10,
            }} />
            <Box sx={{
                ...commonHandStyles,
                height: minuteHandSize.height,
                width: minuteHandSize.width,
                bgcolor: 'info.main',
                borderRadius: `${6 * relativeSize}px ${6 * relativeSize}px 0 0`,
                transform: `translateX(-50%) rotate(${day.minute() * 6}deg)`,
                zIndex: 11,
            }} />
            <Box sx={{
                ...commonHandStyles,
                height: secondHandSize.height,
                width: secondHandSize.width,
                bgcolor: 'info.main',
                borderRadius: `${6 * relativeSize}px ${6 * relativeSize}px 0 0`,
                transform: `translateX(-50%) rotate(${day.second() * 6}deg)`,
                zIndex: 12,
            }} />
            {Array.from({ length: 12 }, (_, i) => (
                <Box key={i + 1} sx={{
                    position: 'absolute',
                    width: '90%',
                    height: '90%',
                    textAlign: 'center',
                    transform: `rotate(${(i + 1) * 30}deg)`,
                }}>
                    <Typography sx={{
                        position: 'absolute',
                        left: '50%',
                        transform: `translate(-50%, -50%) rotate(-${(i + 1) * 30}deg)`,
                        top: `${12 * relativeSize}px`,
                        color: 'text.secondary',
                        fontWeight: 'bold',
                        fontSize: `${fontSize}rem`,
                    }}>
                        {i + 1}
                    </Typography>
                </Box>
            ))}
        </Box>
    );
};

export default Clock;