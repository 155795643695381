// src\app\pages\4-Campaigns\3-CampaignSequence\SequenceComponents\DelayForm.tsx
import './delayform.css';

import AccessTimeIcon from '@mui/icons-material/AccessTime';
import AutoAwesomeSharpIcon from '@mui/icons-material/AutoAwesomeSharp';
import ErrorSharpIcon from '@mui/icons-material/ErrorSharp';
import ExpandLessSharpIcon from '@mui/icons-material/ExpandLessSharp';
import ExpandMoreSharpIcon from '@mui/icons-material/ExpandMoreSharp';
import { Checkbox, Divider, FormControlLabel, Grid, MenuItem, Select, TextField, Typography } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import React, { useState } from 'react';

import CustomTooltip from '../../../../modules/CustomMaterialUiComponents/CustomTooltip';


function DelayForm({ step, onUpdate }) {
    const [randomizeDelay, setRandomizeDelay] = useState(step.randomizeDelay);
    const [delay, setDelay] = useState(step.delay);
    const [minRandomDelay, setMinRandomDelay] = useState(step.minRandomDelay);
    const [maxRandomDelay, setMaxRandomDelay] = useState(step.maxRandomDelay);
    const [delayUnit, setDelayUnit] = useState(step.delayUnit);
    const [expanded, setExpanded] = useState(false);

    const handleRandomizeDelayChange = (event) => {
        const newRandomizeDelay = event.target.checked;
        setRandomizeDelay(newRandomizeDelay);
        onUpdate({ ...step, randomizeDelay: newRandomizeDelay });
    };


    const handleDelayChange = (event) => {
        const newDelay = event.target.value;
        setDelay(newDelay);
        onUpdate({ ...step, delay: newDelay });
    };


    const handleMinRandomDelayChange = (event) => {
        let newMinRandomDelay = Number(event.target.value);
        let newMaxRandomDelay = Number(maxRandomDelay);

        // For units other than 'days', ensure min is less than max
        if (delayUnit !== 'days' && newMinRandomDelay >= newMaxRandomDelay) {
            newMaxRandomDelay = newMinRandomDelay + 1;
            setMaxRandomDelay(newMaxRandomDelay.toString());
        }

        setMinRandomDelay(newMinRandomDelay.toString());
        onUpdate({ ...step, minRandomDelay: newMinRandomDelay.toString(), maxRandomDelay: newMaxRandomDelay.toString() });
    };

    const handleMaxRandomDelayChange = (event) => {
        let newMaxRandomDelay = Number(event.target.value);
        let newMinRandomDelay = Number(minRandomDelay);

        // For units other than 'days', ensure max is greater than min
        if (delayUnit !== 'days' && newMaxRandomDelay <= newMinRandomDelay) {
            newMinRandomDelay = newMaxRandomDelay > 1 ? newMaxRandomDelay - 1 : 1; // Ensure min is always less, but not less than 1
            setMinRandomDelay(newMinRandomDelay.toString());
        }

        setMaxRandomDelay(newMaxRandomDelay.toString());
        onUpdate({ ...step, maxRandomDelay: newMaxRandomDelay.toString(), minRandomDelay: newMinRandomDelay.toString() });
    };



    const handleDelayUnitChange = (event) => {
        const newDelayUnit = event.target.value;

        // Reset min and max random delays to default values upon unit change
        // Choose appropriate default values for minRandomDelay and maxRandomDelay
        const defaultMinRandomDelay = ''; // Example default value
        const defaultMaxRandomDelay = ''; // Example default value

        setDelayUnit(newDelayUnit);
        setMinRandomDelay(defaultMinRandomDelay);
        setMaxRandomDelay(defaultMaxRandomDelay);

        // Update the step state with the new unit and reset min/max delays
        onUpdate({
            ...step,
            delayUnit: newDelayUnit,
            minRandomDelay: defaultMinRandomDelay,
            maxRandomDelay: defaultMaxRandomDelay
        });
    };


    const handleToggleExpand = () => {
        setExpanded(!expanded);
    };

    const tooltipText = randomizeDelay ? 'Smart Delay is On' : 'Smart Delay is Off';

    const cardClassName = expanded ? 'card expanded' : 'card121';

    let arrayLengthMax;
    switch (delayUnit) {
        case 'seconds':
        case 'minutes':
            arrayLengthMax = 59; // For seconds and minutes, allow values from 1 to 59
            break;
        case 'hours':
            arrayLengthMax = 23; // For hours, allow values from 1 to 23
            break;
        default:
            arrayLengthMax = 0; // Default case, though you might adjust this based on your requirements
    }

    let arrayLengthMin;
    switch (delayUnit) {
        case 'seconds':
        case 'minutes':
            arrayLengthMin = 58; // For seconds and minutes, allow values from 1 to 59
            break;
        case 'hours':
            arrayLengthMin = 22; // For hours, allow values from 1 to 23
            break;
        default:
            arrayLengthMin = 0; // Default case, though you might adjust this based on your requirements
    }

    // Create the conditional text based on whether min and max are set
    let conditionalText = '';
    if (minRandomDelay !== '' && maxRandomDelay !== '') {
        conditionalText = ` ${minRandomDelay}-${maxRandomDelay} ${delayUnit}`;
    }

    return (
        <Card className={cardClassName} sx={{ borderRadius: expanded ? '0' : '0px' }}>
            <CardContent>
                <FormControlLabel
                    control={
                        <Checkbox
                            icon={<ExpandMoreSharpIcon />}
                            checkedIcon={<ExpandLessSharpIcon />}
                            checked={expanded}
                            onChange={handleToggleExpand}
                        />
                    }
                    label=""
                />
                <Typography
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        textAlign: 'center',
                    }}
                    variant="body2"
                >
                    {randomizeDelay ? (
                        minRandomDelay && maxRandomDelay ? (
                            delayUnit === 'days' ?
                                `${delay} days (${minRandomDelay}-${maxRandomDelay} hours flexibility)` :
                                ` ${minRandomDelay}-${maxRandomDelay} ${delayUnit}`
                        ) : (
                            <CustomTooltip
                                title={"Please Choose Delay Time"}
                            >
                                <ErrorSharpIcon sx={{ fontSize: "35px" }} color='secondary' />
                            </CustomTooltip>
                        )
                    ) : `Fixed Delay: ${delay} ${delayUnit}`}
                </Typography>


                <Divider />
                {expanded && (

                    <form>
                        <CustomTooltip
                            title={tooltipText}

                        >


                            <FormControlLabel
                                className="formControl"
                                control={
                                    <Checkbox checked={randomizeDelay}
                                        onChange={handleRandomizeDelayChange}
                                        icon={<AutoAwesomeSharpIcon sx={{ fontSize: "25px" }} />}
                                        checkedIcon={<AutoAwesomeSharpIcon color='secondary' sx={{ fontSize: "35px" }} />}
                                    />
                                }
                                label="Enable Flexible Delay"
                            />

                        </CustomTooltip>



                        <Grid
                            className="padding121"
                            container
                            spacing={1}
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                textAlign: 'center',
                            }}
                        >
                            {(!randomizeDelay || delayUnit === 'days') && (
                                <TextField
                                    variant="outlined"
                                    size="small"
                                    value={delay}
                                    onChange={handleDelayChange}
                                    label={delayUnit === 'days' ? "How many days?" : "Fixed Delay"} // Conditional label
                                    fullWidth
                                    style={{ marginBottom: "16px" }}
                                />
                            )}
                            <Select
                                sx={{ fontSize: "30px" }}
                                variant="outlined"
                                labelId="unit-select-label"
                                id="unit-select"
                                value={delayUnit}
                                onChange={handleDelayUnitChange}
                                fullWidth
                                style={{ marginBottom: "16px" }}
                            >
                                <MenuItem value="seconds">Seconds</MenuItem>
                                <MenuItem value="minutes">Minutes</MenuItem>
                                <MenuItem value="hours">Hours</MenuItem>
                                <MenuItem value="days">Days</MenuItem>
                            </Select>
                            {randomizeDelay && (
                                <>
                                    {delayUnit === 'days' ? (
                                        <>
                                            Flexible hours

                                            <CustomTooltip
                                                title="Minimum value of the flexible delay range. For days, it represents 1 day minus the specified hours."
                                                icon={<AccessTimeIcon sx={{ fontSize: "35px" }}
                                                />
                                                }
                                            >

                                                <Select
                                                    value={minRandomDelay}
                                                    onChange={handleMinRandomDelayChange}
                                                    variant='outlined'
                                                    aria-labelledby="MinimumDelay-select"

                                                >
                                                    {[...Array(4)].map((_, index) => (
                                                        <MenuItem key={index} value={index + 1}>{index + 1}</MenuItem>
                                                    ))}
                                                </Select>
                                            </CustomTooltip>

                                            <CustomTooltip
                                                title="Maximum value of the flexible delay range. For days, it represents 1 day plus a number of hours."
                                                icon={<AccessTimeIcon sx={{ fontSize: "35px" }}
                                                />
                                                }
                                            >

                                                <Select
                                                    value={maxRandomDelay}
                                                    onChange={handleMaxRandomDelayChange}
                                                    variant='outlined'
                                                    aria-labelledby="maxDelay-select"

                                                >
                                                    {[...Array(5)].map((_, index) => (
                                                        <MenuItem key={index} value={index + 1}>{index + 1}</MenuItem>
                                                    ))}
                                                </Select>
                                            </CustomTooltip>


                                        </>
                                    ) : (
                                        <>

                                            <Select
                                                value={minRandomDelay}
                                                onChange={handleMinRandomDelayChange}
                                                variant='outlined'
                                                aria-labelledby="MinimumDelay-select"
                                            >
                                                {[...Array(arrayLengthMin)].map((_, index) => (
                                                    <MenuItem key={index} value={index + 1}>{index + 1}</MenuItem>
                                                ))}
                                            </Select>

                                            <Select
                                                value={maxRandomDelay}
                                                onChange={handleMaxRandomDelayChange}
                                                variant='outlined'
                                                aria-labelledby="maxDelay-select"
                                            >
                                                {[...Array(arrayLengthMax)].map((_, index) => (
                                                    <MenuItem key={index} value={index + 1}>{index + 1}</MenuItem>
                                                ))}
                                            </Select>

                                        </>
                                    )}
                                </>
                            )}
                        </Grid>
                    </form>
                )}
            </CardContent>
        </ Card >
    );


}

export default DelayForm;
