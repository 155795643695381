// src\_metronic\partials\layout\header-menus\HeaderUserMenu.tsx
import { Avatar, Button, FormControl, InputLabel, Menu, MenuItem, Select, useTheme } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react';

import { useAuth } from '../../../../app/modules/auth';
import DraggableDialog from '../../../../app/modules/CustomMaterialUiComponents/DraggableDialog';
import ProfilePageParent from '../../../../app/pages/0-Account/ProfilePage/ProfilePage';
import PreferencesDialog from '../../../layout/components/header/userPreferences';
import Clock from './Clock';

const HeaderUserMenu = () => {
  const theme = useTheme(); // This hook is used to access the current theme
  const { currentUser, logout } = useAuth();
  const [anchorEl, setAnchorEl] = useState(null);
  const [preferencesOpen, setPreferencesOpen] = useState(false);
  const [myAccountOpen, setMyAccountOpen] = useState(false);
  const [accounts, setAccounts] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState('');
  const [isImpersonating, setIsImpersonating] = useState(false);
  const isDarkMode = theme.palette.mode === 'dark';

  const handleAccountChange = async (event) => {
    // Find the selected account details based on the selected ID
    const selectedAccountId = event.target.value;
    //@ts-ignore
    const selectedAccount = accounts.find(account => account._id === selectedAccountId);

    // Save the selected account's name and ID for impersonation
    //@ts-ignore
    localStorage.setItem('impersonatedAccountName', `${selectedAccount.firstname} ${selectedAccount.lastname}`);
    localStorage.setItem('impersonatedAccountId', selectedAccountId);

    // Previous logic for initiating impersonation
    setSelectedAccount(selectedAccountId);

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/impersonation/impersonate/${selectedAccountId}`, {}, {
        withCredentials: true, // Include this if your API requires cookies to be sent
      });

      const { token } = response.data;

      // Example of setting the impersonation token for future requests
      // You might choose to store this token in localStorage/sessionStorage or context state
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

      // Optionally, you can refresh the page or navigate the user to a dashboard route
      localStorage.setItem('isImpersonating', 'true');
      window.location.reload();
      // or use React Router for navigation, for example:
      // navigate('/dashboard');
    } catch (error) {
      console.error('Impersonation error:', error);
      // Handle error (e.g., show an error message)
    }
  };



  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handlePreferencesClick = () => {
    setPreferencesOpen(true);
    handleClose(); // Close the user menu when opening preferences
  };

  const closePreferences = () => {
    setPreferencesOpen(false);
  };

  const handleMyAccountClick = () => {
    setMyAccountOpen(true);
    handleClose(); // Close the user menu when opening preferences
  };

  const closeMyAccount = () => {
    setMyAccountOpen(false);
  };

  useEffect(() => {

    const isCurrentlyImpersonating = localStorage.getItem('isImpersonating') === 'true';
    console.log('Is currently impersonating:', isCurrentlyImpersonating); // Debugging
    setIsImpersonating(isCurrentlyImpersonating);

    // Retrieve the impersonated account's name from local storage
    const accountName = localStorage.getItem('impersonatedAccountName');
    const accountId = localStorage.getItem('impersonatedAccountId');

    if (accountName && accountId) {
      // Assuming you have a way to set the displayed name in your select component
      setSelectedAccount(accountId);
    }

    const getAccounts = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/impersonation/accounts/list`, {
          withCredentials: true,
        });
        setAccounts(response.data);
        setIsImpersonating(response.data.some(account => account.isImpersonating));
      } catch (error) {
        console.error('Error fetching accounts:', error);
      }
    };
    getAccounts();
  }, []);



  const handleEndImpersonation = async () => {
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/impersonation/endImpersonation`, {}, {
        withCredentials: true,
      });
      // Handle successful end of impersonation
      localStorage.removeItem('isImpersonating');
      localStorage.removeItem('impersonatedAccountName');
      localStorage.removeItem('impersonatedAccountId');

      window.location.reload();
    } catch (error) {
      console.error('Error ending impersonation:', error);
      // Handle error
    }
  };


  const handleLogout = () => {
    // Clear impersonation flag from local storage
    localStorage.removeItem('isImpersonating');
    localStorage.removeItem('impersonatedAccountName');
    localStorage.removeItem('impersonatedAccountId');

    // Logout user
    logout();

    // Redirect to login or perform other actions as needed
  };

  const isCurrentlyImpersonating = localStorage.getItem('isImpersonating') === 'true';

  const avatarColor = isDarkMode ? '#793A99' : '#F79010';

  return (
    <div>
      <Button aria-controls="user-menu" aria-haspopup="true" onClick={handleMenuClick}>
        <Avatar
          sx={{ background: avatarColor }}
          alt="User Avatar"
        //  src={toAbsoluteUrl('/media/avatars/300-2.jpg')} 
        />
        {/* <Badge color="success" badgeContent="Pro" overlap="rectangular" /> */}
      </Button>






      <Menu
        id="user-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleClose}>
          <Avatar
            sx={{ background: avatarColor }}
            alt="User Avatar"
          //  src={toAbsoluteUrl('/media/avatars/300-2.jpg')} 
          />
          {currentUser?.firstname} {currentUser?.lastname}
        </MenuItem>
        <MenuItem >

          <Clock size={240} />
        </MenuItem>
        {currentUser && (currentUser.admin || currentUser.canCreateSubaccounts) && (

          <FormControl fullWidth>
            <InputLabel id="account-select-label">Select Account</InputLabel>
            <Select
              labelId="account-select-label"
              id="account-select"
              value={selectedAccount}
              label="Select Account"
              onChange={handleAccountChange}
            >
              {accounts.map((account) => (
                //@ts-ignore
                <MenuItem key={account._id} value={account._id}>{account.firstname} {account.lastname}</MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        {currentUser && (currentUser.admin || currentUser.canCreateSubaccounts) && isCurrentlyImpersonating && (
          <Button color="secondary" onClick={handleEndImpersonation}>
            End Impersonation
          </Button>
        )}

        {/* <MenuItem component={Link} to={'/my-account'} onClick={handleClose}>My Account</MenuItem> */}
        <MenuItem onClick={handlePreferencesClick}>Preferences</MenuItem>
        <MenuItem onClick={handleMyAccountClick}>My Account</MenuItem>
        <MenuItem onClick={() => { handleClose(); handleLogout(); }}>Sign Out</MenuItem>
      </Menu>
      <DraggableDialog
        open={preferencesOpen}
        onClose={closePreferences}
        title="User Preferences"
        content={<PreferencesDialog />}
        enableExternalWindow={false}
        backgroundBlur={false}
      />
      {/* <CssBaseline> */}
      <DraggableDialog
        open={myAccountOpen}
        onClose={closeMyAccount}
        title="My Account"
        content={<ProfilePageParent />}
        enableExternalWindow={false}
        backgroundBlur={false}
      />
      {/* </CssBaseline> */}





    </div>
  );
};

export { HeaderUserMenu };
