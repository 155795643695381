// src\app\pages\0-Account\EmailAccounts\EmailConnection\ConnectionMethods\SMTP_IMAPForm.tsx
import './SMTP_IMAPForm.css';
import '@yaireo/tagify/dist/tagify.css';

import CheckIcon from '@mui/icons-material/Check';
import ErrorIcon from '@mui/icons-material/Error';
import ForwardToInboxSharpIcon from '@mui/icons-material/ForwardToInboxSharp';
import HelpSharpIcon from '@mui/icons-material/HelpSharp';
import { Box, TextField, Typography } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Snackbar from '@mui/material/Snackbar';
import Tagify from '@yaireo/tagify';
import axios from 'axios';
import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import { CSSTransition } from 'react-transition-group';

import ProviderSelectionList from '../ConnectionForm/ProviderSelectionList';



// In SMTP_IMAPForm.tsx
interface SMTP_IMAPFormProps {
    onSuccess?: () => void;
    onSnackbarOpen: (severity: 'success' | 'error', message: string) => void;
}




const SMTP_IMAPForm: React.FC<SMTP_IMAPFormProps> = ({ onSuccess, onSnackbarOpen }) => {
    const [connectionLoading, setConnectionLoading] = useState(false); // Add this line
    const [smtpCheckState, setSmtpCheckState] = useState<'idle' | 'loading' | 'success' | 'failure'>('idle');
    const [imapCheckState, setImapCheckState] = useState<'idle' | 'loading' | 'success' | 'failure'>('idle');
    const [isEmailComplete, setIsEmailComplete] = useState(false);
    const [isNameComplete, setIsNameComplete] = useState(false);

    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error'>('success');
    const [snackbarMessage, setSnackbarMessage] = useState('');


    const validateEmail = (value: string) => {
        let error;
        if (!value) {
            error = 'Email address is required';
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value)) {
            error = 'The value is not a valid email address';
        }
        return error;
    };
    // start of tagify
    const [isDropdownVisible, setDropdownVisible] = useState(false);
    const emailProviders = [
        { name: 'Google', smtpHost: 'smtp.gmail.com', imapHost: 'imap.gmail.com', smtpPort: '587', imapPort: '993' },
        { name: 'Hostinger', smtpHost: 'smtp.hostinger.com', imapHost: 'imap.hostinger.com', smtpPort: '465', imapPort: '993' },
        // ... add other email providers
    ];

    const handleAddEmailAccount = async (values) => {

        // Log the request data before making the axios.post call
        // console.log('Request Data:', {
        //     sendingAccount: values.email,
        //     password: values.imapPassword,
        //     firstName: values.firstName,
        //     lastName: values.lastName,
        //     smtp: {
        //         host: values.smtpHost,
        //         port: Number(values.smtpPort),
        //         password: values.smtpPassword,
        //     },
        //     imap: {
        //         host: values.imapHost,
        //         port: Number(values.imapPort),
        //         password: values.imapPassword,
        //         tls: true, // or false based on your requirement
        //     },
        // });

        setConnectionLoading(true); // Start loading before the request

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/account/emailaccounts/connect`, {
                sendingAccount: values.email,
                password: values.imapPassword,  // Assuming IMAP password is the one to use
                firstName: values.firstName,
                lastName: values.lastName,
                smtp: {
                    host: values.smtpHost,
                    port: Number(values.smtpPort),
                    password: values.smtpPassword,
                },
                imap: {
                    host: values.imapHost,
                    port: Number(values.imapPort),
                    password: values.imapPassword,
                    tls: true, // or false based on your requirement
                },
            });

            console.log('Response:', response.data);  // Log the response data for debugging

            // Success response handling
            if (response.status >= 200 && response.status < 300) {

                onSnackbarOpen('success', response.data.message || 'Email account added successfully.');
                setConnectionLoading(false); // Stop loading on success
                onSuccess?.(); // Call the onSuccess function if it's provided
            } else {
                // This block is technically unnecessary if Axios is used correctly, as Axios treats all 2xx responses as resolved promises.
                setConnectionLoading(false); // Stop loading on failure
                onSnackbarOpen('error', 'Unexpected response status. Please check the console for more info.');
            }
        } catch (error) {
            setConnectionLoading(false); // Stop loading on failure
            console.error('Error:', error);

            let errorMessage = 'An error occurred. Please try again.';
            if (axios.isAxiosError(error)) {
                // Properly handle Axios errors
                if (error.response) {
                    // Server responded with a status code outside the 2xx range
                    errorMessage = error.response.data.error || errorMessage;
                }
            }

            onSnackbarOpen('error', errorMessage);
        }
    };

    const toggleDropdown = () => {
        setDropdownVisible(!isDropdownVisible);
    };

    // Initialize Tagify on component mount and update
    React.useEffect(() => {
        const input = document.querySelector('#providerInput');
        const tagify = new Tagify(input, {
            whitelist: emailProviders.map(provider => provider.name),
            maxTags: 1,
        });

        tagify.on('add', (e) => {
            const selectedProvider = emailProviders.find(provider => provider.name === e.detail.data.value);
            if (selectedProvider) handleProviderSelect(selectedProvider);
        });
    }, []);
    // end of tagify


    function handleProviderSelect(selectedProvider: { name: string; smtpHost: string; imapHost: string; smtpPort: string; imapPort: string; }) {
        throw new Error('Function not implemented.');
    }


    const checkSMTP = async (values: any) => {
        setSmtpCheckState('loading');
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/account/check-smtp`, {
                host: values.smtpHost,
                port: Number(values.smtpPort),
                secure: true, // or true based on your requirement
                auth: {
                    user: values.email,
                    pass: values.smtpPassword,
                },
            });

            if (response.data.success) {
                setSmtpCheckState('success');
            } else {
                setSmtpCheckState('failure');
            }
        } catch (error) {
            setSmtpCheckState('failure');
        }
    };

    const checkIMAP = async (values: any) => {
        setImapCheckState('loading');
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/account/check-imap`, {
                user: values.email,
                password: values.imapPassword,
                host: values.imapHost,
                port: Number(values.imapPort),
                tls: true, // or false based on your requirement
            });

            if (response.data.success) {
                setImapCheckState('success');
            } else {
                setImapCheckState('failure');
            }
        } catch (error) {
            setImapCheckState('failure');
        }
    };

    const fieldStyles = isEmailComplete
        ? { opacity: 1, display: 'block' }
        : { opacity: 0, display: 'none' };


    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };



    return (
        <Formik
            initialValues={{
                email: '',
                imapPassword: '', // Make sure you have this field
                firstName: '',
                lastName: '',
                smtpHost: '',
                smtpPort: '',
                smtpPassword: '',
                imapHost: '',
                imapPort: '',
                setReplyTo: false,
                replyTo: '',
            }}
            onSubmit={handleAddEmailAccount}
        >
            {({ values, setFieldValue }) => {
                // Define handleProviderSelect here
                const handleProviderSelect = (provider) => {
                    // Autofill the SMTP, IMAP, and port fields
                    setFieldValue('smtpHost', provider.smtpHost);
                    setFieldValue('imapHost', provider.imapHost);
                    setFieldValue('smtpPort', provider.smtpPort);
                    setFieldValue('imapPort', provider.imapPort);
                };

                return (
                    <Box sx={{ overflow: 'auto', maxHeight: '100vh', padding: 2 }}>

                        <Form>


                            {/* Email Field */}
                            <Box >
                                <TextField color="info"
                                    type="email"
                                    name="email"
                                    label="Email"
                                    id="floatingInput2"
                                    placeholder="name@example.com"
                                    className={`form-control ${values.email && !validateEmail(values.email) ? 'is-valid' : ''}`}
                                    helperText={values.email && !validateEmail(values.email)}
                                    onChange={e => {
                                        setFieldValue('email', e.target.value);
                                        setIsEmailComplete(e.target.value.includes('@'));
                                    }}
                                    variant='filled'
                                    size='medium'
                                    sx={{ marginTop: 1 }}
                                />
                                {/* No ErrorMessage component to prevent displaying errors */}
                            </Box>

                            {/* The rest of your form fields go here, rendered conditionally based on the presence of an email value */}
                            <CSSTransition
                                in={isEmailComplete}
                                timeout={350}
                                classNames="fade"
                                unmountOnExit
                            >

                                <Box className="row">

                                    {/* First Name Field */}
                                    <Box className="col-md-6">
                                        <Box>
                                            <TextField color="info"
                                                type="text"
                                                name="firstName"
                                                id="floatingInputFirstName"
                                                label="First Name"
                                                className="form-control"
                                                onChange={e => {
                                                    setFieldValue('firstName', e.target.value);
                                                    setIsNameComplete(!!(values.lastName && e.target.value));
                                                }}
                                                sx={{ marginTop: 1 }}
                                            />
                                        </Box>
                                    </Box>

                                    {/* Last Name Field */}
                                    <Box className="col-md-6">
                                        <TextField color="info"
                                            type="text"
                                            name="lastName"
                                            id="floatingInputLastName"
                                            label="Last Name"
                                            className="form-control"
                                            onChange={e => {
                                                setFieldValue('lastName', e.target.value);
                                                setIsNameComplete(!!(values.firstName && e.target.value));
                                            }}
                                            sx={{ marginTop: 1 }}
                                        />

                                    </Box>
                                </Box>
                            </CSSTransition>


                            {/* IMAP Settings */}
                            <CSSTransition
                                in={isNameComplete}
                                timeout={300}
                                classNames="fade"
                                unmountOnExit
                            >
                                <Box className="row">

                                    <Box className="col-md-6">
                                        <Typography variant="h1" margin={1}>Sending:</Typography>

                                        <TextField
                                            color="info"
                                            name="smtpUsername"
                                            type="text"
                                            label="Username"
                                            value={values.email}  // Update to the correct property
                                            className="form-control mb-3"
                                            sx={{ marginTop: 1 }}
                                        />

                                        <TextField
                                            color="info"
                                            name="smtpPassword"
                                            type="password"
                                            label="Password"
                                            value={values.smtpPassword}
                                            onChange={(e) => setFieldValue('smtpPassword', e.target.value)}
                                            className="form-control mb-3"
                                            sx={{ marginTop: 1 }}
                                        />

                                        <TextField
                                            color="info"
                                            name="smtpHost"
                                            type="text"
                                            label="SMTP Host"
                                            value={values.smtpHost}
                                            onChange={(e) => setFieldValue('smtpHost', e.target.value)}
                                            className="form-control mb-3"
                                            sx={{ marginTop: 1 }}
                                        />

                                        <TextField
                                            color="info"
                                            name="smtpPort"
                                            type="text"
                                            label="SMTP Port"
                                            value={values.smtpPort}
                                            onChange={(e) => setFieldValue('smtpPort', e.target.value)}
                                            className="form-control mb-3"
                                            sx={{ marginTop: 1 }}
                                        />


                                    </Box>

                                    {/* SMTP Settings */}

                                    <Box className="col-md-6">
                                        <Box>
                                            <Typography margin={1} variant="h1">Receiving:</Typography>
                                            <TextField color="info" name="imapUsername" type="text" label="Username" value={values.email}
                                                sx={{ marginTop: 1 }}
                                                className="form-control mb-3" />
                                            <TextField
                                                color="info"
                                                name="imapPassword"
                                                type="password"
                                                label="Password"
                                                value={values.imapPassword}
                                                onChange={(e) => setFieldValue('imapPassword', e.target.value)}
                                                className="form-control mb-3"
                                                sx={{ marginTop: 1 }}
                                            />

                                            <TextField
                                                color="info"
                                                name="imapHost"
                                                type="text"
                                                label="IMAP Host"
                                                value={values.imapHost}
                                                onChange={(e) => setFieldValue('imapHost', e.target.value)}
                                                className="form-control mb-3"
                                                sx={{ marginTop: 1 }}
                                            />

                                            <TextField
                                                color="info"
                                                name="imapPort"
                                                type="text"
                                                label="IMAP Port"
                                                value={values.imapPort}
                                                onChange={(e) => setFieldValue('imapPort', e.target.value)}
                                                className="form-control mb-3"
                                                sx={{ marginTop: 1 }}
                                            />
                                        </Box>

                                        {/* Set Reply-To Checkbox */}

                                        <Box className="form-check mb-3"
                                            sx={{ marginTop: "25px", marginBottom: "25px" }}
                                        >
                                            <Checkbox
                                                id="setReplyTo"
                                                name="setReplyTo"
                                                className="form-check-input"
                                                aria-label='Set Reply-To'
                                                title='Set Reply-To'
                                                checked={values.setReplyTo}
                                                onChange={() => setFieldValue('setReplyTo', !values.setReplyTo)}
                                                color='info'
                                                icon={<ForwardToInboxSharpIcon sx={{ fontSize: "35px" }} />}
                                                checkedIcon={<ForwardToInboxSharpIcon sx={{ fontSize: "35px" }} />}
                                            />
                                        </Box>



                                        {/* Conditional Reply-To Fields */}
                                        {values.setReplyTo && (

                                            <Box>
                                                <p>
                                                    You will need to connect the account you will use as a reply-to address to enable reply tracking                                        </p>
                                                <TextField color="info" name="replyTo" type="email" label="Reply To Email" className="form-control mb-3" />
                                            </Box>

                                        )}
                                    </Box>



                                    <Box>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={toggleDropdown}
                                            sx={{ margin: '8px', textTransform: 'none' }}
                                            startIcon={<HelpSharpIcon />}
                                        >
                                            Help Me
                                        </Button>

                                        <Button
                                            variant="contained"
                                            color="primary"
                                            type="submit"
                                            disabled={connectionLoading} // Disable button when loading
                                            sx={{ margin: '8px', textTransform: 'none' }}
                                        >
                                            {connectionLoading ? 'Connecting...' : 'Connect Account'}
                                        </Button>

                                        <Button
                                            variant="contained"
                                            color={smtpCheckState === 'success' ? 'success' : smtpCheckState === 'failure' ? 'error' : 'primary'}
                                            onClick={() => checkSMTP(values)}
                                            sx={{ margin: '8px', textTransform: 'none' }}
                                            disabled={smtpCheckState === 'loading'}
                                            endIcon={smtpCheckState === 'success' ? <CheckIcon /> : smtpCheckState === 'failure' ? <ErrorIcon /> : null}
                                        >
                                            {smtpCheckState === 'loading' ? 'Loading...' : 'Check Sending'}
                                        </Button>

                                        <Button
                                            variant="contained"
                                            color={imapCheckState === 'success' ? 'success' : imapCheckState === 'failure' ? 'error' : 'primary'}
                                            onClick={() => checkIMAP(values)}
                                            sx={{ margin: '8px', textTransform: 'none' }}
                                            disabled={imapCheckState === 'loading'}
                                            endIcon={imapCheckState === 'success' ? <CheckIcon /> : imapCheckState === 'failure' ? <ErrorIcon /> : null}
                                        >
                                            {imapCheckState === 'loading' ? 'Loading...' : 'Check Receiving'}
                                        </Button>

                                    </Box>
                                </Box>
                            </CSSTransition>

                            {isDropdownVisible && (
                                <Box className="dropdown">
                                    <ProviderSelectionList emailProviders={emailProviders} onProviderSelect={handleProviderSelect} />
                                </Box>

                            )}
                            <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
                                <MuiAlert elevation={6} variant="filled" onClose={handleSnackbarClose} severity={snackbarSeverity}>
                                    {snackbarMessage}
                                </MuiAlert>
                            </Snackbar>

                        </Form>
                    </Box>  // Add this line

                )
            }
            }
        </Formik>
    );
};

export default SMTP_IMAPForm;
