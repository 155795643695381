// src\app\pages\3-CRM\ListQueueChip.tsx
import React, { ReactElement, useState } from 'react';
import { Box, Chip, SvgIconProps, styled } from '@mui/material';
import DraggableDialog from '../../modules/CustomMaterialUiComponents/DraggableDialog';
import ListQueueProgressBar from './ListQueueProgressBar';

interface JobCounts {
    active: number;
    waiting: number;
    completed: number;
    delayed: number;
    failed: number;
    totalScheduled: number;
}

interface QueueChipProps {
    jobCounts: JobCounts;
    title: string;
    icon?: ReactElement<SvgIconProps>; // Optional icon prop
}

//@ts-ignore
const ProgressChip = styled(Chip)(({ theme, progress }) => ({
    position: 'relative',
    overflow: 'hidden',
    '&:before': {
        content: '""',
        display: 'block',
        position: 'absolute',
        top: 0,
        left: 0,
        width: `${progress}%`,
        height: '100%',
        backgroundColor: theme.palette.secondary.main,
        transition: 'width 0.3s ease-in-out',
        zIndex: 0,
    },
    '& .MuiChip-label': {
        zIndex: 1,
    }
}));

const ListQueueChip: React.FC<QueueChipProps> = ({ jobCounts, title, icon }) => {
    const [chipDialogOpen, setChipDialogOpen] = useState(false);

    const handleChipClick = () => {
        setChipDialogOpen(true);
    };

    const handleChipDialogClose = () => {
        setChipDialogOpen(false);
    };
    //@ts-ignore
    const waitingCount: number = parseInt(jobCounts.waiting, 10);
    //@ts-ignore
    const delayedCount: number = parseInt(jobCounts.delayed, 10);
    const total: number = waitingCount + delayedCount;

    const totalJobs = jobCounts.waiting + jobCounts.completed + jobCounts.delayed;
    const remainingJobs = jobCounts.waiting + jobCounts.delayed;

    // Compute the percentage of progress.
    const progressPercent = totalJobs === 0 ? 0 : Math.round(((totalJobs - remainingJobs) / totalJobs) * 100);

    const label: string = `${title}: ${progressPercent}%`;

    return (
        <>
            <ProgressChip
                label={label}
                onClick={handleChipClick}
                color="primary"
                variant="filled"

                //@ts-ignore
                progress={progressPercent}
            />


            {/* <div style={{ zIndex: 999999999999999 }}> */}
            <DraggableDialog
                open={chipDialogOpen}
                onClose={handleChipDialogClose}
                title={title}
                enableExternalWindow={false} // If you want to enable opening in a new tab

                content={
                    <>
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 2 }}>
                            {icon}
                        </Box>
                        <ListQueueProgressBar
                            jobCounts={jobCounts}
                            title="Job Progress"
                        />
                    </>
                }
            />
            {/* </div> */}

        </>
    );
};

export default ListQueueChip;
