// src\app\pages\4-Campaigns\5-CampaignReview\LeadReviewInfo.tsx
import {
    AccessTimeFilledSharp,
    AssignmentTurnedInSharp,
    HourglassTopSharp,
    LocalShippingSharp,
    PendingActionsSharp,
    ScheduleSendSharp,
} from '@mui/icons-material';
import CheckCircleOutlineSharp from '@mui/icons-material/CheckCircleOutlineSharp';
import ExpandMoreSharp from '@mui/icons-material/ExpandMoreSharp';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Chip,
    Divider,
    List,
    ListItem,
    ListItemText,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import axios from 'axios';
import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { ComposableMap, Geographies, Geography, Marker } from 'react-simple-maps';
import styled, { keyframes } from 'styled-components';

import { IPersonalizedMessage } from './PersonalizedMessageInterface';
import { getTimezoneCoordinates } from './timezoneCoordinates';
import worldMap from './worldmap.json';

// Define a type for the Lead Data
interface LeadData {
    [key: string]: any; // Allows for dynamic keys and values, including emails and phones
}

const LeadReviewInfo: React.FC<{ selectedCampaignId: string | null; selectedLead: string | null; personalizedMessage: IPersonalizedMessage | null; }> = ({ selectedCampaignId, selectedLead, personalizedMessage }) => {
    const [leadData, setLeadData] = useState<LeadData | null>(null);
    const theme = useTheme();

    const colorChange = (secondary, primary, success) => keyframes`
    0% {
      fill: ${secondary};
    }
    33% {
      fill: ${primary};
    }
    66% {
      fill: ${success};
    }
    100% {
      fill: ${secondary};
    }
  `;

    const AnimatedCircleWrapper = styled.circle`
  
    animation: ${(
        //@ts-ignore
        { secondary, primary, success }) => colorChange(secondary, primary, success)} 3s infinite;
  `;

    useEffect(() => {
        if (selectedLead) {
            axios.get(`${process.env.REACT_APP_API_URL}/leads/${selectedLead}`)
                .then((response) => {
                    setLeadData(response.data); // Assumes the response data matches the LeadData structure
                })
                .catch((error) => {
                    console.error('Error fetching lead data:', error);
                });
        }
    }, [selectedLead]);

    const coordinates = personalizedMessage ?
        //@ts-ignore
        getTimezoneCoordinates(personalizedMessage.location) : [0, 0];

    // Helper function to render collapsible list items
    const renderCollapsibleListItems = (items: any[], label: string) => (
        <Accordion key={label}>
            <AccordionSummary expandIcon={<ExpandMoreSharp />}>
                <Typography>{label} ({items.length})</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <List dense>
                    {items.map((item) => (
                        <ListItem key={item._id}>
                            <ListItemText
                                primary={`${item.emailaddress || item.number}`}
                                secondary={`Type: ${item.type} - Validated: ${item.validated ? 'Yes' : 'No'}${item.validationStatus ? ` (${item.validationStatus})` : ''}`}
                            />
                        </ListItem>
                    ))}
                </List>
            </AccordionDetails>
        </Accordion>
    );

    // Helper function to format dates and times
    const formatDate = (date: string) => format(new Date(date), 'eeee, MMMM do, yyyy');
    const formatTime = (date: string) => format(new Date(date), 'hh:mm a');

    const calendarDate = personalizedMessage?.triggerAt ? new Date(personalizedMessage.triggerAt) : null;


    const getStatusIcon = (status: IPersonalizedMessage['leadStatus']) => {
        switch (status) {
            case 'pending':
                return <PendingActionsSharp sx={{ fontSize: "35px" }} color="secondary" />;
            case 'queued':
                return <ScheduleSendSharp sx={{ fontSize: "35px" }} color="primary" />;
            case 'completed':
                return <AssignmentTurnedInSharp sx={{ fontSize: "35px" }} color="success" />;
            case 'inprogress':
                return <LocalShippingSharp sx={{ fontSize: "35px" }} color="secondary" />;
            default:
                return null;
        }
    };

    const CustomTableCell = ({ children, ...props }) => {
        return (
            <TableCell align="center" {...props}>
                {children}
            </TableCell>
        );
    };

    return (
        <>


            {personalizedMessage && (
                <Box mb={3}>

                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <CustomTableCell><strong>Lead Status</strong></CustomTableCell>
                                    <CustomTableCell><strong>Replied</strong></CustomTableCell>
                                    <CustomTableCell><strong>Sequence Start Day</strong></CustomTableCell>
                                    <CustomTableCell><strong>Sending Time</strong></CustomTableCell>
                                    <CustomTableCell><strong>Lead's Timezone</strong></CustomTableCell>
                                    <CustomTableCell><strong>Lead's Sending Time</strong></CustomTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <CustomTableCell>
                                        {getStatusIcon(personalizedMessage.leadStatus)}
                                    </CustomTableCell>
                                    <CustomTableCell>
                                        {personalizedMessage.replied ?
                                            <CheckCircleOutlineSharp sx={{ fontSize: "35px" }} color="success" /> :
                                            <HourglassTopSharp sx={{ fontSize: "35px" }} color="secondary" />}
                                    </CustomTableCell>
                                    <CustomTableCell>
                                        {personalizedMessage.triggerAt ?
                                            //@ts-ignore
                                            formatDate(personalizedMessage.triggerAt) : ''}
                                    </CustomTableCell>
                                    <CustomTableCell>
                                        <Chip
                                            label={personalizedMessage.triggerAt ?
                                                //@ts-ignore
                                                formatTime(personalizedMessage.triggerAt) : ''}
                                            color="primary" icon={<AccessTimeFilledSharp />} />
                                    </CustomTableCell>
                                    <CustomTableCell>{personalizedMessage.location}</CustomTableCell>
                                    <CustomTableCell>{personalizedMessage.localTriggerAt}</CustomTableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>

            )}

            {personalizedMessage && (
                <ComposableMap>
                    <Geographies geography={worldMap}>
                        {({ geographies }) =>
                            geographies.map((geo) => (
                                <Geography key={geo.rsmKey} geography={geo}

                                    style={{
                                        default: { fill: theme.palette.info.main, stroke: theme.palette.info.main, outline: 'none' },
                                        hover: { fill: theme.palette.secondary.main, stroke: theme.palette.secondary.main, outline: 'none' },
                                        pressed: { fill: theme.palette.secondary.main, stroke: theme.palette.secondary.main, outline: 'none' }
                                    }}

                                />
                            ))
                        }
                    </Geographies>
                    <Marker coordinates={coordinates}>
                        <svg width="100" height="100">
                            <AnimatedCircleWrapper
                                r={20}
                                cx={50}
                                cy={50}
                                //@ts-ignore
                                secondary={theme.palette.secondary.main}
                                primary={theme.palette.primary.main}
                                success={theme.palette.success.main}
                            />
                        </svg>

                    </Marker>
                </ComposableMap>
            )}

            <Paper elevation={3} style={{ padding: '20px', margin: '20px 0' }}>



                <Divider style={{ margin: '10px 0' }} />


                {leadData ? (
                    <>
                        {leadData.Emails && renderCollapsibleListItems(leadData.Emails, 'Emails')}
                        {leadData.Phones && renderCollapsibleListItems(leadData.Phones, 'Phones')}
                        <Accordion>
                            <AccordionSummary expandIcon={<ExpandMoreSharp />}>
                                <Typography>More Info</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                {Object.entries(leadData).map(([key, value]) => {
                                    if (key !== 'Emails' && key !== 'Phones') {
                                        return (
                                            <Typography key={key} variant="body2" style={{ marginBottom: '8px' }}>
                                                <strong>{key}:</strong> {typeof value === 'object' ? JSON.stringify(value, null, 2) : value}
                                            </Typography>
                                        );
                                    }
                                    return null;
                                })}
                            </AccordionDetails>
                        </Accordion>
                    </>
                ) : (
                    <Typography variant="body1">Select Lead</Typography>
                )}
            </Paper>


        </>
    );
};

export default LeadReviewInfo;
