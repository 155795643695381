import React from 'react';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled, useTheme, Theme } from '@mui/material/styles';
import { motion, useMotionValue, useSpring, useTransform } from 'framer-motion';

// Define the props for your custom tooltip
interface CustomTooltipProps extends TooltipProps {
    backgroundColor?: keyof Theme['palette'] | string; // Allow theme palette keys or string colors
    fontColor?: keyof Theme['palette']['text'] | string; // Allow specific text color keys or string colors
    fontSize?: number;
    borderRadius?: number;
    borderColor?: string;
    borderWidth?: number;
    followCursor?: boolean;
    arrowColor?: keyof Theme['palette'] | string; // Allow theme palette keys or string colors
    icon?: React.ReactElement;
    placement?: TooltipProps['placement'];
    disableMotion?: boolean; // New prop to control motion
}

const tooltipVariants = {
    hidden: { opacity: 0, scale: 0.5, y: -20 },
    visible: {
        opacity: 1,
        scale: 1,
        y: 0,
        transition: {
            duration: 0.2,
            ease: "easeOut",
        },
    },
    exit: {
        opacity: 0,
        scale: 0.5,
        y: -20,
        transition: {
            duration: 0.1,
            ease: "easeIn",
        },
    },
};

// CustomTooltip component
const CustomTooltip: React.FC<CustomTooltipProps> = ({
    backgroundColor = 'dark', // Default based on theme mode
    fontColor = '#fff', // Default font color
    fontSize,
    borderRadius,
    borderColor,
    borderWidth,
    arrowColor = 'dark', // Default arrow color based on theme mode
    icon,
    placement = 'bottom-end',
    followCursor = true, // Default to follow cursor
    disableMotion = true, // Default to disable motion animations
    ...props
}) => {
    const theme = useTheme();

    const x = useMotionValue(0);
    const springConfig = disableMotion ? { duration: 0 } : { stiffness: 300, damping: 20 };
    const rotate = useSpring(useTransform(x, [-100, 100], disableMotion ? [0, 0] : [-45, 45]), springConfig);
    const translateX = useSpring(useTransform(x, [-100, 100], disableMotion ? [0, 0] : [-50, 50]), springConfig);

    const TooltipContentComponent = disableMotion ? 'div' : motion.div;

    // Function to resolve theme color values, updated to handle undefined
    // Function to resolve theme color values, updated to handle undefined
    const resolveColor = (color?: keyof Theme['palette'] | string): string => {
        // Helper function to check if the palette color is of PaletteColor type
        //@ts-ignore
        const isPaletteColor = (paletteColor: any): paletteColor is PaletteColor => {
            return paletteColor && typeof paletteColor === 'object' && 'main' in paletteColor;
        };

        if (color) {
            const paletteColor = theme.palette[color as keyof Theme['palette']];
            if (isPaletteColor(paletteColor)) {
                // Safe to access `main` as we've now ensured we have a PaletteColor object
                //@ts-ignore
                return paletteColor.main;
            } else if (typeof color === 'string') {
                // Assuming direct color string
                return color;
            }
        }
        // Fallback to default text primary color if `color` is not provided or not found in the theme
        return theme.palette.text.primary;
    };

    const StyledTooltip = styled(({ className, ...otherProps }: TooltipProps) => (
        <Tooltip
            {...otherProps}
            classes={{ popper: className }}
            placement={placement}
            followCursor={followCursor}
            arrow
            sx={{ height: "100%" }}

            title={
                <TooltipContentComponent
                    className="no-global-transition"
                    variants={!disableMotion ? tooltipVariants : undefined}
                    initial={!disableMotion ? 'hidden' : {}}
                    animate={!disableMotion ? 'visible' : {}}
                    exit={!disableMotion ? 'exit' : {}}
                    style={{
                        translateX: translateX,
                        //@ts-ignore
                        rotate: rotate,
                        whiteSpace: "normal",
                    }}
                >
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        {icon && <span style={{ marginRight: 8 }}>{icon}</span>}
                        {props.title}
                    </div>
                </TooltipContentComponent>
            }
        />
    ))({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: `${theme.palette.mode === 'dark' ? '#793A99' : '#FAAF00'}`,
            color: fontColor,
            fontSize: fontSize ? `${fontSize}px` : 'inherit',
            borderRadius: borderRadius || 4,
            border: `${borderWidth || 0}px solid ${borderColor || 'transparent'}`,
            boxShadow: theme.shadows[1],
            maxWidth: '280px',

        },
        [`& .${tooltipClasses.arrow}`]: {
            color: `${theme.palette.mode === 'dark' ? '#793A99' : '#FAAF00'}`
        },
    });

    return <StyledTooltip {...props} />;
};

export default CustomTooltip;