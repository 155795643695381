// src\app\pages\LeadGenAdvanced\DetailedView\TabsComponent.tsx
import React from 'react';
import { Tabs, Tab } from '@mui/material';
import WorkIcon from '@mui/icons-material/Work';
import SchoolIcon from '@mui/icons-material/School';
import PersonIcon from '@mui/icons-material/Person';
import RecommendSharpIcon from '@mui/icons-material/RecommendSharp';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
const TabsComponent = ({ selectedTab, handleTabChange }) => {
    return (
        <Tabs value={selectedTab} onChange={handleTabChange} indicatorColor="secondary" style={{ backgroundColor: 'var(--bs-primary)' }}>
            <Tab label="" icon={<PersonIcon style={{ color: '#fff', fontSize: '50px' }} />} />
            <Tab label="" icon={<WorkIcon style={{ color: '#fff', fontSize: '50px' }} />} />
            <Tab label="" icon={<SchoolIcon style={{ color: '#fff', fontSize: '50px' }} />} />
            <Tab label="" icon={<SupervisedUserCircleIcon style={{ color: '#fff', fontSize: '50px' }} />} />
        </Tabs>
    );
};

export default TabsComponent;
