// src\app\components\MenuBar.tsx
import React, { useState, useRef, useEffect } from 'react';
import { Button, Box, List, ListItem, ListItemIcon, ListItemText, Divider } from '@mui/material';
import { useTheme } from '@mui/material';

// Define the types for menu items and props
interface DividerItem {
  add: "Divider";
}

interface MenuItem {
  text?: string;
  icon?: React.ReactElement;
  action?: () => void | Promise<void>; // Allows both sync and async functions
}

interface MenuBarProps {
  menus: { [menuId: string]: Menu };
}

interface Menu {
  icon: React.ReactElement;
  items: (MenuItem | DividerItem)[];
}



const MenuBar: React.FC<MenuBarProps> = ({ menus }) => {
  const [openMenu, setOpenMenu] = useState<string | null>(null);
  const [menuOpenedByClick, setMenuOpenedByClick] = useState<boolean>(false);
  const [isHovering, setIsHovering] = useState<boolean>(false);

  const theme = useTheme();

  const anchorEl = useRef<null | HTMLElement>(null);
  const menuRef = useRef<HTMLDivElement>(null);

  const handleClick = (menuId: string) => (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    const currentAnchor = event.currentTarget;
    anchorEl.current = currentAnchor;
    if (openMenu === menuId && menuOpenedByClick) {
      setOpenMenu(null); // Close menu if the same button is clicked
      setMenuOpenedByClick(false);
    } else {
      setOpenMenu(menuId);
      setMenuOpenedByClick(true); // Mark as opened by click
    }
  };

  const handleMouseEnter = (menuId: string) => (event: React.MouseEvent<HTMLButtonElement>) => {
    if (!menuOpenedByClick || openMenu !== menuId) {
      anchorEl.current = event.currentTarget;
      setOpenMenu(menuId);
      setIsHovering(true); // Indicate hovering over the button
    }
  };

  const unifiedMouseLeaveHandler = () => {
    if (!menuOpenedByClick) {
      setTimeout(() => {
        // Close the menu if not hovering over any menu button or the menu itself
        if (!isHovering) {
          setOpenMenu(null);
        }
      }, 300); // Adjust delay as needed
    }
  };



  const handleMenuItemClick = (action: () => void) => {
    action();
    setOpenMenu(null); // Close menu after action
    setMenuOpenedByClick(false); // Reset click state
  };

  // Close menu when clicking outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(event.target as Node) && !anchorEl.current?.contains(event.target as Node)) {
        setOpenMenu(null);
        setMenuOpenedByClick(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div ref={menuRef} style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
      {Object.entries(menus).map(([menuId, menu]) => (
        <Button
          key={menuId}
          startIcon={menu.icon}
          onClick={handleClick(menuId)}
          color='info'
          onMouseEnter={(event) => handleMouseEnter(menuId)(event)}
          onMouseLeave={() => {
            setIsHovering(false);
            unifiedMouseLeaveHandler();
          }}
        >
          {menuId.charAt(0).toUpperCase() + menuId.slice(1)}
        </Button>
      ))}
      {openMenu && menus[openMenu] && (
        <Box
          sx={{
            position: 'absolute',
            top: anchorEl.current ? anchorEl.current.offsetTop + anchorEl.current.offsetHeight : 0,
            left: anchorEl.current ? anchorEl.current.offsetLeft : 0,
            // border: `0.5px solid ${theme.palette.info.main}`,
            bgcolor: 'background.paper',
            zIndex: 444444, // Set a high zIndex value
          }}
          onMouseLeave={() => {
            setIsHovering(false); // Update hover state when leaving button
            unifiedMouseLeaveHandler();
          }}        >
          <List>
            {menus[openMenu]?.items.map((item, index) => {
              // Check if the item is a DividerItem
              if ('add' in item && item.add === "Divider") {
                return <Divider sx={{ height: "4px" }} key={`Divider-${index}`} />;
              } else {
                // At this point, TypeScript knows item is not a DividerItem, but we need to assert it explicitly to access MenuItem properties
                const menuItem = item as MenuItem; // Type assertion here

                return (
                  <ListItem sx={{ height: "30px" }} key={index} button onClick={() => menuItem.action && menuItem.action()}>
                    {/* Assert menuItem.icon and menuItem.text are available */}
                    <ListItemIcon sx={{ color: theme.palette.info.main }}>{menuItem.icon}</ListItemIcon>
                    <ListItemText sx={{ color: theme.palette.info.main }} primary={menuItem.text} />
                  </ListItem>
                );
              }
            })}
          </List>

        </Box>
      )}
    </div>
  );
};

export default MenuBar;
