import React, { useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { useTheme } from '@mui/material/styles';

const Body = styled('div')(({ theme }) => ({
    fontFamily: "'Roboto', Arial, sans-serif",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: 0,
}));

const EmptyInbox = styled('div')(({ theme }) => ({
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    transition: 'background-color 0.3s ease, color 0.3s ease',
    position: 'relative',
    overflow: 'hidden',
    '&::before': {
        content: "''",
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundImage: "url('https://cdni.iconscout.com/illustration/premium/thumb/empty-state-concept-3428212-2902554.png')",
        backgroundSize: '400px auto',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        opacity: 0.8,
        transition: 'filter 0.3s ease, transform 0.5s ease',
        pointerEvents: 'none',
    },
    '&:hover::before': {
        transform: 'scale(1.05)',
    },

    '&.dark-mode::before': {
        filter: 'invert(1) hue-rotate(180deg)',
    },
}));

const Heading = styled('h2')(({ theme }) => ({
    fontFamily: "'Playfair Display', serif",
    color: theme.palette.text.primary,
    fontSize: '36px',
    fontWeight: 700,
    margin: 0,
    transition: 'color 0.3s ease, opacity 0.5s ease',
    position: 'relative',
    zIndex: 1,
    marginTop: '350px', // Adjust this value to move the text down
    '@media (max-width: 768px)': {
        fontSize: '28px',
        marginTop: '250px', // Adjust for mobile
    },
}));

const EmptyEmailListPlaceholder = () => {
    const theme = useTheme();

    useEffect(() => {
        const noEmailsText = document.getElementById('no-emails-text');
        if (noEmailsText) {
            noEmailsText.style.opacity = '1';
        }
    }, []);

    return (
        <Body>
            <EmptyInbox className={`empty-inbox ${theme.palette.mode === 'dark' ? 'dark-mode' : ''}`}>
                <Heading id="no-emails-text">No emails found</Heading>
            </EmptyInbox>
        </Body>
    );
};

export default EmptyEmailListPlaceholder;
