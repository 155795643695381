// SaveButton.tsx
import CheckIcon from '@mui/icons-material/Check';
import SaveSharpIcon from '@mui/icons-material/SaveSharp';
import { Box, CircularProgress, IconButton } from '@mui/material';
import React, { useEffect, useState } from 'react';

interface SaveButtonProps {
    onClick: () => void;
    loading: boolean;
    success: boolean;
    minimumLoadingTime: number;
    successDisplayTime: number;
    size?: number;
}


const SaveButton: React.FC<SaveButtonProps> = ({
    onClick,
    loading,
    success,
    minimumLoadingTime = 0, // Default to 2 seconds if not specified
    successDisplayTime = 2000, // Default to 2 seconds if not specifie
    size = 56,
}) => {
    const [internalLoading, setInternalLoading] = useState(loading);
    const [displaySuccess, setDisplaySuccess] = useState(success);

    useEffect(() => {
        let timeoutId: NodeJS.Timeout;
        if (loading) {
            setInternalLoading(true);
            setDisplaySuccess(false);
        } else if (success) {
            const elapsedTime = minimumLoadingTime;
            timeoutId = setTimeout(() => {
                setInternalLoading(false);
                setDisplaySuccess(true);
                // Reset success indication after a specified time
                setTimeout(() => setDisplaySuccess(false), successDisplayTime);
            }, elapsedTime);
        }
        return () => clearTimeout(timeoutId);
    }, [loading, success, minimumLoadingTime, successDisplayTime]);


    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ m: 1, position: 'relative' }}>
                <IconButton
                    aria-label="save"
                    color="info"
                    onClick={onClick}
                    disabled={internalLoading}
                    sx={{
                        width: size, // Apply size
                        minHeight: "10",
                        height: size, // Apply size
                        '& .MuiSvgIcon-root': { // Ensure the icon scales with the button
                            fontSize: size / 2 // Adjust icon size based on the button size
                        }
                    }}

                >
                    {displaySuccess ? <CheckIcon color='info' /> : <SaveSharpIcon />}
                </IconButton>
                {internalLoading && (
                    <CircularProgress
                        size={size} // Make the CircularProgress match the Fab size
                        color='primary'
                        sx={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            zIndex: 1,
                            width: size + 12,
                            height: size + 12,
                        }}
                    />


                )}
            </Box>    </Box>

    );
};

export default SaveButton;
