// src\app\pages\4-Campaigns\3-CampaignSequence\SequenceComponents\SequencePlanner.tsx
import '../SequenceSteps.css';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CodeIcon from '@mui/icons-material/Code';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import MailOutlineSharpIcon from '@mui/icons-material/MailOutlineSharp';
import PhoneAndroidSharpIcon from '@mui/icons-material/PhoneAndroidSharp';
import TextFormatIcon from '@mui/icons-material/TextFormat';
import { Box, Card, Checkbox, Grid, IconButton, Radio, RadioGroup, Tooltip, useTheme } from '@mui/material';
import React from 'react';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { FaBalanceScaleRight } from 'react-icons/fa';

import { Step } from '../StepInterface';
import DelayForm from './DelayForm';

interface SequencePlannerProps {
    steps: Step[];
    onDragEnd: (result: any) => void;
    onAddStep: (stepId: string) => void;
    onDeleteStep: (stepId: string) => void;
    onSelectStep: (step: Step) => void;
    getCardStyle: (stepId: string) => string;
    handleTypeChange: (event: React.ChangeEvent<HTMLInputElement>, id: string) => void;
    handleEmailTypeChange: (event: React.ChangeEvent<HTMLInputElement>, id: string) => void;
    onUpdate: (updatedStep: Step) => void; // Add this line
    selectStepById: (stepId: string) => void;
    toggleStepVariation: (stepId: string) => void; // Add this line
    selectedStep: Step | null; // Assuming selectedStep is the whole step object. Adjust as necessary.

}

const SequencePlanner: React.FC<SequencePlannerProps> = ({
    steps,
    onDragEnd,
    onAddStep,
    onDeleteStep,
    onSelectStep,
    getCardStyle,
    handleTypeChange,
    handleEmailTypeChange,
    onUpdate,
    selectStepById,
    toggleStepVariation,
    selectedStep
}) => {

    const theme = useTheme();



    return (
        <>
            <Droppable droppableId="droppable">
                {(provided) => (
                    <Grid

                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        style={{ maxHeight: 'calc(80vh - 20px)', overflowY: 'auto' }}
                    >
                        {steps.map((step: Step, index: number) => (
                            <Draggable key={step._id} draggableId={step._id} index={index}>
                                {(provided, snapshot) => (
                                    <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        className={snapshot.isDragging ? "dragging" : ""}
                                    >
                                        {/* Conditionally render DelayForm based on index */}
                                        {index !== 0 && (
                                            <DelayForm step={step} onUpdate={onUpdate} />
                                        )}
                                        <Card
                                            onClick={() => selectStepById(step._id)}
                                            style={{ margin: "10px", padding: "10px", boxShadow: "0 2px 4px rgba(0,0,0,0.2)" }}
                                            className={getCardStyle(step._id)}
                                        >

                                            <Box style={{ marginRight: "30px", marginBottom: "-20px" }}>
                                                <IconButton size='small' sx={{ height: "15px", width: "15px", fontSize: "15px" }}>
                                                    {index + 1}
                                                </IconButton>
                                            </Box>



                                            <RadioGroup
                                                value={step.type}
                                                onChange={(e) => handleTypeChange(e, step._id)}
                                                row
                                                className="iconCenter"
                                            >

                                                <Radio
                                                    value="sms"

                                                    icon={<PhoneAndroidSharpIcon sx={{ color: step._id === selectedStep?._id ? "#d0cdd1" : "#BDBEC0" }} />}
                                                    checkedIcon={<PhoneAndroidSharpIcon sx={{ color: theme.palette.mode === 'dark' ? "#bb47f5" : "#fcb356" }} />}
                                                />

                                                <Radio
                                                    value="email"
                                                    icon={<MailOutlineSharpIcon sx={{ color: step._id === selectedStep?._id ? "#d0cdd1" : "#BDBEC0" }} />}
                                                    checkedIcon={<MailOutlineSharpIcon sx={{ color: theme.palette.mode === 'dark' ? "#bb47f5" : "#fcb356" }} />}
                                                />

                                            </RadioGroup>


                                            {step.type === 'email' && (
                                                <RadioGroup
                                                    value={step.emailType}
                                                    onChange={(e) => handleEmailTypeChange(e as React.ChangeEvent<HTMLInputElement>, step._id)}
                                                    row
                                                    className="iconCenter"
                                                >

                                                    <Radio
                                                        value="plain"
                                                        icon={<TextFormatIcon sx={{ color: step._id === selectedStep?._id ? "#d0cdd1" : "#BDBEC0" }} />}
                                                        //    icon={<MailOutlineSharpIcon sx={{ color: step._id === selectedStep?._id ? "#fcb356" : 'BDBEC0' }} />}
                                                        // i want selected checked secondary
                                                        // i want selected unchecked to be #d0cdd1
                                                        checkedIcon={<TextFormatIcon sx={{ color: theme.palette.mode === 'dark' ? "#bb47f5" : "#fcb356" }} />}
                                                        className="radioButton"
                                                    />
                                                    <Radio
                                                        value="html"
                                                        icon={<CodeIcon sx={{ color: step._id === selectedStep?._id ? "#d0cdd1" : "#BDBEC0" }} />}
                                                        checkedIcon={<CodeIcon sx={{ color: theme.palette.mode === 'dark' ? "#bb47f5" : "#fcb356" }} />}
                                                        className="radioButton"
                                                    />
                                                </RadioGroup>
                                            )}
                                        </Card>
                                        <Box  >
                                            <Tooltip
                                                title={"A/B Testing"}
                                                followCursor
                                            >
                                                <Checkbox
                                                    icon={<FaBalanceScaleRight style={{ color: step._id === selectedStep?._id ? "#d0cdd1" : "#BDBEC0" }} />}
                                                    checkedIcon={<FaBalanceScaleRight style={{ color: "#fcb356" }} />}
                                                    checked={step.variationsEnabled}
                                                    onChange={() => toggleStepVariation(step._id)}
                                                />
                                            </Tooltip>
                                        </Box>

                                        <div className="buttonRow iconCenter">
                                            <IconButton onClick={() => onAddStep(step._id)} color='primary' >
                                                <AddCircleOutlineIcon sx={{ fontSize: "35px" }} />
                                            </IconButton>
                                            {steps.length > 1 && (
                                                <IconButton onClick={() => onDeleteStep(step._id)} color='secondary'>
                                                    <DeleteOutlineIcon sx={{ fontSize: "35px" }} />
                                                </IconButton>
                                            )}

                                        </div>
                                    </div>
                                )}
                            </Draggable>
                        ))}
                        {provided.placeholder}
                    </Grid>
                )}
            </Droppable>
        </>
    );
};

export default SequencePlanner;
