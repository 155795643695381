// src\_metronic\partials\layout\theme-mode\ThemeModeProvider.tsx
import React, { createContext, useContext, useEffect, useState } from 'react';
import { ThemeModeComponent } from '../../../assets/ts/layout';
import { toAbsoluteUrl } from '../../../helpers';

export type ThemeModeType = 'dark' | 'light' | 'system';
export const themeModelSKey = 'kt_theme_mode_value';
export const themeMenuModeLSKey = 'kt_theme_mode_menu';

const systemMode = ThemeModeComponent.getSystemMode() as 'light' | 'dark';

type ThemeModeContextType = {
  mode: ThemeModeType;
  menuMode: ThemeModeType;
  updateMode: (_mode: ThemeModeType) => void;
  updateMenuMode: (_mode: ThemeModeType) => void;
  onThemeChange?: (mode: ThemeModeType) => void; // New callback
};

// This might already exist in a similar form, adjust as needed.
export const getInitialThemeMode = (): ThemeModeType => {
  return getThemeModeFromLocalStorage(themeModelSKey);
};


const themeModeSwitchHelper = (_mode: ThemeModeType) => {
  const mode = _mode !== 'system' ? _mode : systemMode;
  const imageUrl = '/media/patterns/header-bg' + (mode === 'light' ? '.jpg' : '-dark.png');
  document.body.style.backgroundImage = `url("${toAbsoluteUrl(imageUrl)}")`;
};
const getThemeModeFromLocalStorage = (lsKey: string): ThemeModeType => {
  // Check for the presence of localStorage
  if (!localStorage) {
    // Return 'dark' if localStorage is not available
    return 'dark';
  }

  const data = localStorage.getItem(lsKey);
  // Check if the retrieved value is one of the valid theme modes
  if (data === 'dark' || data === 'light' || data === 'system') {
    return data;
  }

  // Additional check against the 'data-bs-theme' attribute on document element
  if (document.documentElement.hasAttribute('data-bs-theme')) {
    const dataTheme = document.documentElement.getAttribute('data-bs-theme');
    if (dataTheme && (dataTheme === 'dark' || dataTheme === 'light' || dataTheme === 'system')) {
      return dataTheme;
    }
  }

  // Return 'dark' as the default mode if no other conditions are met
  return 'dark';
};

const defaultThemeMode: ThemeModeContextType = {
  mode: getThemeModeFromLocalStorage(themeModelSKey),
  menuMode: getThemeModeFromLocalStorage(themeMenuModeLSKey),
  updateMode: (_mode: ThemeModeType) => { },
  updateMenuMode: (_menuMode: ThemeModeType) => { },
  onThemeChange: () => { } // Default empty implementation
};

export const ThemeModeContext = createContext<ThemeModeContextType>(defaultThemeMode);

const useThemeMode = () => useContext(ThemeModeContext);

const ThemeModeProvider = ({ children, onThemeChange }: { children: React.ReactNode, onThemeChange?: (mode: ThemeModeType) => void }) => {
  const [mode, setMode] = useState<ThemeModeType>(defaultThemeMode.mode);
  const [menuMode, setMenuMode] = useState<ThemeModeType>(defaultThemeMode.menuMode);

  const updateMode = (_mode: ThemeModeType, saveInLocalStorage: boolean = true) => {
    setMode(_mode);
    themeModeSwitchHelper(_mode); // Ensure this updates Metronic's theme correctly

    if (saveInLocalStorage && localStorage) {
      localStorage.setItem(themeModelSKey, _mode);
    }

    if (saveInLocalStorage) {
      const updatedMode = _mode === 'system' ? systemMode : _mode;
      document.documentElement.setAttribute('data-bs-theme', updatedMode);
    }
    ThemeModeComponent.init();

    // Ensure Material UI theme is also updated here
    if (onThemeChange) {
      onThemeChange(_mode);
    }
  };


  const updateMenuMode = (_menuMode: ThemeModeType, saveInLocalStorage: boolean = true) => {
    setMenuMode(_menuMode);
    if (saveInLocalStorage && localStorage) {
      localStorage.setItem(themeMenuModeLSKey, _menuMode);
    }
  };

  useEffect(() => {
    updateMode(mode, false);
    updateMenuMode(menuMode, false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ThemeModeContext.Provider value={{ mode, menuMode, updateMode, updateMenuMode }}>
      {children}
    </ThemeModeContext.Provider>
  );
};

export { ThemeModeProvider, useThemeMode, systemMode, themeModeSwitchHelper };
