import React, { useState, useEffect } from 'react';
import { Grid, Drawer, IconButton, Typography, Button, CircularProgress, Paper, Box, Divider, useTheme } from '@mui/material';
import ChevronRightSharpIcon from '@mui/icons-material/ChevronRightSharp';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'; // Importing AddCircleOutlineIcon
import CampaignStepper from './CampaignSteps';
import CampaignsPanel from './CampaignsPanel';
import io from 'socket.io-client';
import axios from 'axios';
import { useQuery, QueryClient, QueryClientProvider } from 'react-query';
import { CircleSharp, MouseSharp } from '@mui/icons-material';
import { useAuth } from '../../../modules/auth';
import { Tooltip } from '@mui/material';
import CustomTooltip from '../../../modules/CustomMaterialUiComponents/CustomTooltip';



const queryClient = new QueryClient();

const fetchCampaignStatus = async (selectedCampaignId) => {
    const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/campaign/status/${selectedCampaignId}`);
    return data;
};


const CampaignParent = () => {
    const theme = useTheme();
    const [selectedCampaignId, setSelectedCampaignId] = useState<string | null>(null);
    const [userOpenedDrawer, setUserOpenedDrawer] = useState(false); // State for user interaction
    const [socketMessage, setSocketMessage] = useState(''); // State to store the message from socket
    const [socketError, setSocketError] = useState(''); // State to store the message from socket
    const [isToggling, setIsToggling] = useState(false); // Track if the campaign status is being toggled
    const [socketDisabled, setSocketDisabled] = useState(false);

    // Use react-query to fetch campaign status
    const { data: campaignStatusData, refetch } = useQuery(['campaignStatus', selectedCampaignId], () => fetchCampaignStatus(selectedCampaignId), {
        enabled: !!selectedCampaignId, // Only run the query if the selectedCampaignId is not null
    });
    const campaignStatus = campaignStatusData?.status || 'Loading...'; // Default text while loading
    const { currentUser } = useAuth()
    const userId = currentUser?._id
    useEffect(() => {
        //@ts-ignore
        const socket = io(process.env.REACT_APP_API_URL);

        // Assuming `userId` and `selectedCampaignId` are available in this context
        // Replace 'userId' with how you access the user's ID in your component
        if (selectedCampaignId && userId) {
            // Construct the object to join the room
            const roomDetails = { userId: userId, campaignId: selectedCampaignId };
            socket.emit('joinCampaignRoom', roomDetails);

            socket.on('campaignUpdate', (data) => {
                console.log('Socket Campaign Update:', data);
                setSocketMessage(data.message); // Update the socket message state
                setSocketError(data.error); // Update the socket message state

                // Determine if the message should disable the button, e.g., processing messages
                const isProcessingMessage = data.message.includes("lead");
                setSocketDisabled(isProcessingMessage);

                refetch(); // Refetch campaign status when it gets updated
            });
        }

        // Cleanup function to leave the room and disconnect the socket when the component unmounts
        // or when `selectedCampaignId` changes
        return () => {
            if (selectedCampaignId && userId) {
                const roomDetails = { userId: userId, campaignId: selectedCampaignId };
                socket.emit('leaveCampaignRoom', roomDetails);
            }
            socket.disconnect();
        };
    }, [refetch, selectedCampaignId, userId]);


    useEffect(() => {
        // Reset the socketMessage to an empty string or a default message
        setSocketMessage('');
        setSocketError('');
    }, [selectedCampaignId]);

    // useEffect(() => {
    //     // Reset the socketMessage to an empty string or a default message
    //     setSocketError('');
    // }, [socketMessage]);

    const toggleCampaignStatus = async () => {
        setIsToggling(true); // Start toggling
        try {
            await axios.put(`${process.env.REACT_APP_API_URL}/campaign/toggle-status/${selectedCampaignId}`);
            refetch();
        } catch (error) {
            console.error("Error toggling campaign status:", error);
        } finally {
            setIsToggling(false); // End toggling
        }
    };


    // Toggle the drawer open/close
    const handleDrawerOpen = () => {
        setUserOpenedDrawer(!userOpenedDrawer);
    };

    // Close the drawer when clicking away
    const handleCloseDrawer = () => {
        setUserOpenedDrawer(false);
    };

    // Drawer should be open if there is no selected campaign or if user opened it
    const isDrawerOpen = userOpenedDrawer;

    // Determine drawer type based on state
    const drawerType = selectedCampaignId === null ? "persistent" : "temporary";

    // Function to render the alternative content
    // Function to render the alternative content
    const renderCreateOrChooseCampaign = () => (
        <Grid
            container
            alignItems="center"
            justifyContent="center"
            style={{
                height: "100vh",
                textAlign: "center",
                cursor: "pointer",
            }}
            onClick={handleDrawerOpen}
        >
            <Grid
                item>
                <Typography variant="h2"
                    sx={{
                        fontSize: "80px",
                        color: "#c9c9c9",
                        transition: "1s",

                        "&:hover": {
                            fontSize: "70px",
                            opacity: 0.7
                        }
                    }}
                >
                    Create or choose a campaign
                </Typography>
                <Grid
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginTop: "30px",
                    }}
                    sx={{ "&:hover": { opacity: 0.7 } }}
                    onClick={handleDrawerOpen}
                >
                    <MouseSharp

                        sx={{
                            fontSize: "290px",
                            // border: "dashed 10px",
                            padding: "15px",
                            borderRadius: "50%",
                            color: "#c9c9c9",
                            transition: "1s",
                            "&:hover": {
                                fontSize: "320px",
                            }
                        }}

                    />
                </Grid>
            </Grid>
        </Grid>
    );

    return (
        <QueryClientProvider client={queryClient}>

            <div>

                <Drawer
                    anchor='left'
                    open={isDrawerOpen}
                    variant="temporary"
                    onClose={handleCloseDrawer}
                    sx={{
                        flexShrink: 0,
                        '& .MuiBackdrop-root': {
                            // Hide the backdrop
                            backgroundColor: 'transparent',
                            // backdropFilter: 'blur(3px)', // Adjust the blur radius as needed

                        },
                        '& .MuiDrawer-paper': {
                            width: 850,
                            boxSizing: 'border-box',
                        },
                    }}
                >

                    <CampaignsPanel
                        onCampaignSelect={setSelectedCampaignId}
                        selectedCampaignId={selectedCampaignId}
                    />
                </Drawer>

                {selectedCampaignId === null && renderCreateOrChooseCampaign()}

                <Grid container spacing={2} style={{ width: '100%', paddingTop: '50px', paddingLeft: isDrawerOpen ? 0 : 0 }}>

                    <Grid item style={{ flexGrow: 1 }}>
                        {selectedCampaignId === null ? null : <CampaignStepper handleDrawerOpen={handleDrawerOpen} selectedCampaignId={selectedCampaignId} />}
                    </Grid>


                </Grid>


            </div>
        </QueryClientProvider>

    );
};

export default CampaignParent;
