// src\app\modules\auth\AuthPage.tsx
import { Route, Routes, useNavigate } from 'react-router-dom';
import Registration from './components/Registration'
import { ForgotPassword } from './components/ForgotPassword'
import { Login } from './components/Login'
import { AuthLayout } from './AuthLayout'
import { ResetPassword } from './components/ResetPassword'
import EmailVerifiedPage from './components/EmailVerifiedPage';
import VerificationFailurePage from './components/VerificationFailurePage';
import VerificationSuccessPage from './components/VerificationSuccessPage';
import { useEffect, useState } from 'react';
import { ThemeModeProvider, ThemeModeType, getInitialThemeMode, systemMode } from '../../../_metronic/partials/layout/theme-mode/ThemeModeProvider';
import { lightTheme, darkTheme } from '../../../MaterialTheme';
import { ThemeProvider } from '@mui/material';
import GridBackgroundComponent from '../../pages/2-GetLeads/AdvancedSearch/GridBackgroundComponent';
import RandomBoxBackgroundComponent from '../../pages/2-GetLeads/AdvancedSearch/AnimatedBoxesBackground';

const AuthPage = () => {


  const initialMode = getInitialThemeMode(); // Ensure this is the shared source of truth
  const [mode, setMode] = useState<ThemeModeType>(initialMode);

  useEffect(() => {
    handleThemeChange(initialMode); // Apply initial theme mode on app start
  }, [initialMode]);

  // Function to toggle the Material-UI theme
  const handleThemeChange = (newMode: ThemeModeType) => {
    setMode(newMode === 'system' ? systemMode : newMode); // Handle 'system' mode
  };

  const currentTheme = mode === 'light' ? lightTheme : darkTheme;

  return (

    <>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh' }}>

        <RandomBoxBackgroundComponent numberOfBoxes={0} minSize={20} maxSize={500} background="var(--bs-body-bg)">


          <ThemeProvider theme={currentTheme}>
            <ThemeModeProvider onThemeChange={handleThemeChange}>

              <Routes>
                <Route element={<AuthLayout />}>
                  <Route path='login' element={<Login />} />
                  <Route path='forgot-password' element={<ForgotPassword />} />
                  <Route path='reset-password/:token' element={<ResetPassword />} />
                  <Route path='email-verified' element={<EmailVerifiedPage />} />
                  <Route index element={<Login />} />
                </Route>
                <Route path='registration' element={<Registration />} />
              </Routes>

            </ThemeModeProvider>
          </ThemeProvider>
        </RandomBoxBackgroundComponent>

      </div>

    </>
  );
};

export { AuthPage }
