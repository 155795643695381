import { useState } from 'react';
import * as Yup from 'yup';
import { Link } from 'react-router-dom';
import { useFormik } from 'formik';
import { getUserByToken, login } from '../core/_requests';
import { useAuth } from '../core/Auth';
import { TextField, Button, CircularProgress, Typography, Box, Grid, Snackbar } from '@mui/material';
import { FaHandPeace } from "react-icons/fa";
import { useTheme } from '@mui/material/styles';
import KeySharpIcon from '@mui/icons-material/KeySharp';
import MuiAlert from '@mui/material/Alert';

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  password: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
});

const initialValues = {
  email: '',
  password: '',
};

export function Login() {
  const [loading, setLoading] = useState(false);
  const { saveAuth, setCurrentUser } = useAuth();
  const theme = useTheme(); // Access the theme

  const [openSnackbar, setOpenSnackbar] = useState(false);

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      try {
        const { data: auth } = await login(values.email, values.password);
        console.log("Received auth:", auth);
        saveAuth(auth); // Pass the entire auth object
        const { data: user } = await getUserByToken();
        setCurrentUser(user);
      } catch (error) {
        console.error(error);
        setStatus('The login details are incorrect');
        setOpenSnackbar(true); // Open the snackbar when there's an error

      }
      setSubmitting(false);
      setLoading(false);
    },
  });

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };



  return (
    <Box
      sx={{
        width: '100%',
        maxWidth: '500px',
        margin: 'auto',
        padding: theme.spacing(3),
      }}
    >
      <form onSubmit={formik.handleSubmit} noValidate id='kt_login_signin_form'>
        {/* begin::Heading */}
        <Grid container mb={2} spacing={2} alignItems="center" justifyContent="center" >
          <Grid item>
            <Typography style={{ fontSize: "55px", color: theme.palette.info.main }} className='fw-bolder mb-3'>Hello</Typography>
          </Grid>
          <Grid item>
            <FaHandPeace style={{ fontSize: "65px", color: theme.palette.info.main }} />
          </Grid>
        </Grid>

        {/* begin::Heading */}


        {/* begin::Form group */}
        <TextField
          label='Email'
          placeholder='Email'
          {...formik.getFieldProps('email')}
          variant='outlined'
          fullWidth
          error={formik.touched.email && !!formik.errors.email}
          helperText={formik.touched.email && formik.errors.email}
          sx={{ mb: 3 }}
        />
        {/* end::Form group */}

        {/* begin::Form group */}
        <TextField
          label='Password'
          type='password'
          autoComplete='off'
          {...formik.getFieldProps('password')}
          variant='outlined'
          fullWidth
          error={formik.touched.password && !!formik.errors.password}
          helperText={formik.touched.password && formik.errors.password}
          sx={{ mb: 3 }}
        />
        {/* end::Form group */}

        {/* begin::Wrapper */}
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>

          <Button
            type='submit'
            variant='text'
            color='info'
            size='large'
            disabled={formik.isSubmitting || !formik.isValid}
            sx={{ fontSize: "15px" }}
          >
            <Link to='/auth/forgot-password' >
              Forgot Password ?
            </Link>
          </Button>
        </Box>
        {/* end::Wrapper */}

        {/* begin::Action */}
        <Button
          type='submit'
          variant='text'
          color='info'
          size='large'
          fullWidth
          disabled={formik.isSubmitting || !formik.isValid}
          sx={{ mb: 2 }}
        >
          {!loading ? <KeySharpIcon sx={{ fontSize: "35px" }} /> : <CircularProgress size={20} sx={{ mr: 1 }} />}
        </Button>
        {/* end::Action */}

        <Typography variant="body2" color="textSecondary" align="center">
          Not a Member yet?{' '}
          <Link to='/auth/registration'>
            Sign up
          </Link>
        </Typography>
      </form>

      <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <MuiAlert
          //@ts-ignore
          onClose={handleCloseSnackbar} severity="error" elevation={6} variant="filled">
          {formik.status}
        </MuiAlert>
      </Snackbar>

    </Box>
  );
}
