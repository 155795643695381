// src\app\pages\Leadgen\LeadgenTable.tsx
import React, { useEffect, useState } from 'react';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { GridColDef } from '@mui/x-data-grid';
import { Rating } from '@mui/material';
import axios from 'axios';
import './Leadgen.css';

const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 70 },
    { field: 'name', headerName: 'Name', width: 300 },
    { field: 'phone', headerName: 'Phone', width: 200 },
    { field: 'category', headerName: 'Category', width: 200 },
    {
        field: 'rating',
        headerName: 'Rating',
        width: 150,
        renderCell: (params) => (
            <Rating
                name={`rating-${params.row.id}`}
                value={params.row.rating !== "" ? parseFloat(params.row.rating) : 0}
                precision={0.1}
                readOnly
            />
        ),
    },
    { field: 'numberOfRatings', headerName: 'Number of Ratings', width: 180 },
    { field: 'searchQuery', headerName: 'My Search', width: 200 },
];


const LeadgenTable: React.FC = () => {
    const [realData, setRealData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);

    const fetchData = () => {
        setLoading(true);

        axios
            .get(`${process.env.REACT_APP_API_URL}/scrape/businesses`)
            .then((response) => {
                if (response.data) {
                    const formattedData = response.data.map((item, index) => ({
                        ...item,
                        id: index + 1,
                    }));
                    setRealData(formattedData);
                    setSuccess(true);
                } else {
                    // Handle the case when the request is not successful
                }
            })
            .catch((error) => {
                // Handle errors here
            })
            .finally(() => {
                setLoading(false);
            });
    };

    useEffect(() => {
        // Fetch data initially
        fetchData();

        // Set up an interval to fetch data every 5 seconds
        const intervalId = setInterval(fetchData, 5000);

        // Clean up the interval when the component unmounts
        return () => clearInterval(intervalId);
    }, []);

    return (
        <div style={{ width: '100%' }}>
            <DataGrid
                rows={realData}
                columns={columns}
                initialState={{
                    pagination: {
                        paginationModel: { page: 0, pageSize: 5 },
                    },
                }}
                pageSizeOptions={[5, 10, 25, 50, 100]}
                checkboxSelection
                components={{
                    Toolbar: GridToolbar,
                }}
            />
        </div>
    );
};

export default LeadgenTable;
