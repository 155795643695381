// src/app/modules/auth/components/VerificationSuccessPage.tsx
import React, { useContext, useEffect } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { Button, Typography, Container, Box, TextField } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { AuthContext } from '../core/Auth';



const VerificationSuccessPage = () => {
    const [searchParams] = useSearchParams();
    const isSubaccount = searchParams.get('subaccount') === 'true';

    return (
        <>


            <Container component="main" maxWidth="xs" sx={{ mt: 8 }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <CheckCircleOutlineIcon sx={{ m: 1, fontSize: 60, color: 'success.main' }} />
                    <Typography component="h1" variant="h5" sx={{ mb: 2 }}>
                        {isSubaccount ? 'Subaccount Email Verified Successfully!' : 'Email Verified Successfully!'}
                    </Typography>


                    <Typography variant="body1" sx={{ textAlign: 'center', mb: 3 }}>
                        Your email address has been successfully verified. {isSubaccount ? "The subaccount is now ready to use." : "You are now ready to start using the application."}
                    </Typography>
                    <Link to="/auth/login" style={{ textDecoration: 'none' }}>
                        <Button type="button" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>

                            {isSubaccount ? "Great" : "Go to Login."}
                        </Button>
                    </Link>
                </Box>
            </Container>
        </>
    );
};

export default VerificationSuccessPage;
