// src\app\pages\3-CRM\Email&PhoneMenus.tsx
import React, { Suspense, useState } from 'react';
import { Autocomplete, Box, FormControl, Grid, IconButton, Menu, MenuItem, OutlinedInput, Select, TextField } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import EmailSharpIcon from '@mui/icons-material/EmailSharp';
import PhoneEnabledSharpIcon from '@mui/icons-material/PhoneEnabledSharp';
import { countries as nameOfCountry } from 'countries-list';
import { Chip } from '@mui/material';
import FilterAltSharpIcon from '@mui/icons-material/FilterAltSharp';

const Flag = React.lazy(() => import('react-world-flags'));

export const EmailPhoneMenu = ({
    columnField,
    handleMenuClose,
    handleEmailFilterChange,
    handlePhoneFilterChange,
    handleEmailVerificationFilterChange,
    emailFilter,
    phoneFilter,
    emailVerificationFilter,
    countries,
    selectedCountries,
    handleCountryChange,
    showFilterIcon,
}) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
        if (handleMenuClose) handleMenuClose();
    };

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

    const safeGetCountryName = (countryCode) => {
        const country = nameOfCountry[countryCode];
        if (!country) {
            console.error('Invalid country code:', countryCode);
            return 'Unknown Country'; // Return a placeholder or handle the error as needed
        }
        return country.name;
    };

    const renderValue = (selected) => {
        console.log('Selected countries:', selected);
        return (
            <Box sx={{ display: 'flex', flexWrap: 'nowrap', gap: 0.5, overflowX: 'auto', maxWidth: '100%', py: 0.5 }}>
                {selected.map((countryCode) => {
                    const countryName = safeGetCountryName(countryCode);
                    return (
                        <Chip
                            key={countryCode}
                            icon={
                                <Suspense fallback={<FlagPlaceholder />}>
                                    <Flag code={countryCode} width="24" height="16" />
                                </Suspense>
                            }
                            label={countryName}
                            clickable
                        />
                    );
                })}
            </Box>
        );
    };

    const FlagPlaceholder = () => <div style={{ width: '24px', height: '16px' }}></div>;

    const emailMenuItems = (
        <>
            <MenuItem
                onClick={() => handleEmailFilterChange('all', columnField)}
                selected={emailFilter.hasAny === false && emailFilter.doesntHaveAny === false}
            >
                All
            </MenuItem>
            <MenuItem
                onClick={() => handleEmailFilterChange('hasAny', columnField)}
                selected={emailFilter.hasAny}
            >
                Emails: Has Any
            </MenuItem>
            <MenuItem
                onClick={() => handleEmailFilterChange('doesntHaveAny', columnField)}
                selected={emailFilter.doesntHaveAny}
            >
                Emails: Doesn't Have Any
            </MenuItem>
            <MenuItem
                onClick={() => handleEmailVerificationFilterChange('all', columnField)}
                selected={emailVerificationFilter === 'all'}
            >
                All Emails (Verified/Unverified)
            </MenuItem>
            <MenuItem
                onClick={() => handleEmailVerificationFilterChange('verified', columnField)}
                selected={emailVerificationFilter === 'verified'}
            >
                Has Verified Emails
            </MenuItem>
            <MenuItem
                onClick={() => handleEmailVerificationFilterChange('unverified', columnField)}
                selected={emailVerificationFilter === 'unverified'}
            >
                Has Unverified Emails
            </MenuItem>
            <MenuItem>
                <Autocomplete
                    multiple
                    fullWidth
                    freeSolo
                    selectOnFocus
                    clearOnBlur
                    handleHomeEndKeys
                    value={emailFilter.textSearch}
                    autoSelect={true}
                    onChange={(event, newValue) => {
                        handleEmailFilterChange('textSearch', Array.isArray(newValue) ? newValue : [newValue].filter(n => n != null));
                    }}
                    options={[]} // No predefined options since it's freeSolo
                    renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                            <Chip
                                label={option}
                                color="primary"
                                {...getTagProps({ index })}
                            />
                        ))
                    }
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Search Emails"
                            variant="outlined"
                            size="small"
                            fullWidth
                            onKeyDown={(e) => {
                                // Stop propagation for all keys except Enter
                                if (e.key !== 'Enter') {
                                    e.stopPropagation();
                                }
                            }}
                        />
                    )}
                />
            </MenuItem>
        </>
    );

    const phoneMenuItems = (
        <>
            <MenuItem
                onClick={() => handlePhoneFilterChange('all', columnField)}
                selected={phoneFilter.hasAny === false && phoneFilter.doesntHaveAny === false}
            >
                All Phones
            </MenuItem>
            <MenuItem
                onClick={() => handlePhoneFilterChange('hasAny', columnField)}
                selected={phoneFilter.hasAny}
            >
                Phones: Has Any
            </MenuItem>
            <MenuItem
                onClick={() => handlePhoneFilterChange('doesntHaveAny', columnField)}
                selected={phoneFilter.doesntHaveAny}
            >
                Phones: Doesn't Have Any
            </MenuItem>

            <FormControl fullWidth>
                <Select
                    multiple
                    value={selectedCountries}
                    onChange={handleCountryChange}
                    input={<OutlinedInput />}
                    renderValue={renderValue}
                    MenuProps={MenuProps}
                    inputProps={{ 'aria-label': 'Without label' }}
                >
                    {countries.map((countryCode) => (
                        <MenuItem key={countryCode} value={countryCode}>
                            <Suspense key={countryCode} fallback={<FlagPlaceholder />}>
                                <Box sx={{ display: 'inline-flex', alignItems: 'center', mr: 2, ml: 1 }}>
                                    <Flag code={countryCode} width="24" height="16" />
                                    <span>{safeGetCountryName(countryCode)}</span>
                                </Box>
                            </Suspense>
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </>
    );

    return (
        <Grid container justifyContent="space-between" alignItems="center" style={{ width: '100%' }}>
            <Grid item xs>
                {columnField === 'Emails' ? (
                    <EmailSharpIcon color='info' />
                ) : (
                    <PhoneEnabledSharpIcon color='info' />
                )}
            </Grid>
            <Grid item>
                <IconButton onClick={handleClick}>
                    <MoreVertIcon />
                </IconButton>

                {showFilterIcon && (
                    <FilterAltSharpIcon color="secondary" />
                )}

                <Menu
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                >
                    {columnField === 'Emails' ? emailMenuItems : phoneMenuItems}
                </Menu>
            </Grid>
        </Grid>
    );
};
