// src\app\pages\0-Account\EmailAccounts\ManageEmailAccounts\DeliverabilityStatusDkimSpfDmarc.tsx
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    Card,
    CardContent,
    Chip,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Snackbar,
    Typography,
} from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react';

interface DeliverabilityStatusDkimSpfDmarcProps {
    emailAccountId: string;
}

const DeliverabilityStatusDkimSpfDmarc: React.FC<DeliverabilityStatusDkimSpfDmarcProps> = ({ emailAccountId }) => {
    const [dnsStatus, setDnsStatus] = useState({
        spf: false,
        dkim: false,
        dmarc: false,
        readyForColdEmailing: false,
        spfDetails: '',
        dkimDetails: '',
        dmarcDetails: '',
        spfAdvice: '',
        dkimAdvice: '',
        dmarcAdvice: '',
        spfExplanation: '',
        dkimExplanation: '',
        dmarcExplanation: ''
    });
    const [isLoading, setIsLoading] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogContent, setDialogContent] = useState('');

    useEffect(() => {
        // Reset DNS status when emailAccountId changes
        setIsLoading(false);
        setDnsStatus({
            spf: false,
            dkim: false,
            dmarc: false,
            readyForColdEmailing: false,
            spfDetails: '',
            dkimDetails: '',
            dmarcDetails: '',
            spfAdvice: '',
            dkimAdvice: '',
            dmarcAdvice: '',
            spfExplanation: '',
            dkimExplanation: '',
            dmarcExplanation: ''
        });
    }, [emailAccountId]);

    const fetchDnsStatus = async () => {
        if (!emailAccountId) return;
        try {
            setIsLoading(true);
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/account/deliverability-status/${emailAccountId}`);
            setDnsStatus(response.data);
            setSnackbarMessage('DNS status fetched successfully!');
            setSnackbarOpen(true);
        } catch (error: any) {
            console.error('Failed to fetch DNS status:', error);
            setSnackbarMessage(error.response?.data?.message || 'Failed to fetch DNS status.');
            setSnackbarOpen(true);
        } finally {
            setIsLoading(false);
        }
    };

    const openDialog = (recordType: string) => {
        // Generate dialog content based on record type
        let content = '';
        switch (recordType) {
            case 'SPF':
                content = 'To add an SPF record to your DNS settings, follow these steps...';
                break;
            case 'DKIM':
                content = 'To add a DKIM record to your DNS settings, follow these steps...';
                break;
            case 'DMARC':
                content = 'To add a DMARC record to your DNS settings, follow these steps...';
                break;
            default:
                content = 'Invalid record type';
        }
        setDialogContent(content);
        setDialogOpen(true);
    };

    const renderDetails = (title: string, isConfigured: boolean, details: string, explanation: string, advice: string, recordType: string) => (
        <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={`${title}-content`} id={`${title}-header`}>
                <Typography variant="h6" style={{ flexGrow: 1 }}>{title}</Typography>
                <Chip label={isConfigured ? 'Configured' : 'Not Configured'} color={isConfigured ? 'success' : 'error'} />
            </AccordionSummary>
            <AccordionDetails>
                <Typography variant="body2" style={{ marginTop: '10px' }}><strong>Simple Explanation:</strong> {explanation}</Typography>
                <Typography variant="body2"><strong>Your Record:</strong> {details}</Typography>
                <Typography variant="body2" style={{ marginTop: '10px' }}><strong>Advice:</strong> {advice}</Typography>
                {!isConfigured && <Button variant="outlined" color="primary" style={{ marginTop: '10px' }} onClick={() => openDialog(recordType)}>How?</Button>}
            </AccordionDetails>
        </Accordion>
    );

    return (
        <Card elevation={3} style={{ margin: '20px' }}>
            <CardContent>
                <Typography variant="h6" gutterBottom>
                    Actions for Better Email Deliverability
                </Typography>
                <Button variant="outlined" onClick={fetchDnsStatus} color="primary" disabled={isLoading}>
                    {isLoading ? <CircularProgress size={24} /> : 'Check My Setup'}
                </Button>
                {dnsStatus.spfDetails && renderDetails("Protect Your Sender Identity: SPF Record", dnsStatus.spf, dnsStatus.spfDetails, dnsStatus.spfExplanation, dnsStatus.spfAdvice, 'SPF')}
                {dnsStatus.dkimDetails && renderDetails("Protect Your Email Authenticity: DKIM Record", dnsStatus.dkim, dnsStatus.dkimDetails, dnsStatus.dkimExplanation, dnsStatus.dkimAdvice, 'DKIM')}
                {dnsStatus.dmarcDetails && renderDetails("Protect Against Email Fraud: DMARC Record", dnsStatus.dmarc, dnsStatus.dmarcDetails, dnsStatus.dmarcExplanation, dnsStatus.dmarcAdvice, 'DMARC')}
            </CardContent>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={() => setSnackbarOpen(false)}
                message={snackbarMessage}
            />
            <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
                <DialogTitle>How to Add DNS Record</DialogTitle>
                <DialogContent>
                    <Typography>{dialogContent}</Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setDialogOpen(false)} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </Card>
    );
};

export default DeliverabilityStatusDkimSpfDmarc;
