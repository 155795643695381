// src\app\pages\3-CRM\ListsPanel.tsx
import { ListSharp, MoveToInboxSharp } from '@mui/icons-material';
import DeleteSharp from '@mui/icons-material/DeleteSharp';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import FolderSharp from '@mui/icons-material/FolderSharp';
import { Skeleton } from '@mui/material';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Collapse from '@mui/material/Collapse';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Snackbar from '@mui/material/Snackbar';
import axios from 'axios';
import * as React from 'react';

import { FolderItem, ListItem } from './LeadlistsParent';
import MoveListDialog from './MoveListDialog';




interface NestedListProps {
  showSkeleton: boolean; // Define prop for showSkeleton
  foldersWithLists: FolderItem[]; // Define prop for foldersWithLists
  listsWithoutFolder: ListItem[]; // Define prop for listsWithoutFolder
  onListSelect: (listId: string) => void;
}

export default function NestedList(props: NestedListProps) {
  const { showSkeleton, foldersWithLists, listsWithoutFolder, onListSelect } = props;

  const [isMoveDialogOpen, setIsMoveDialogOpen] = React.useState(false);
  const [currentListId, setCurrentListId] = React.useState<string>(''); // Specify the type for currentListId
  const [selectedListId, setSelectedListId] = React.useState<string | null>(null);
  const [open, setOpen] = React.useState<Record<string, boolean>>({}); // Specify the type for open as a Record<string, boolean>

  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState('');
  const [snackbarSeverity, setSnackbarSeverity] = React.useState<'success' | 'error'>('success');

  const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);
  const [deleteListId, setDeleteListId] = React.useState<string | null>(null);

  const openDeleteDialog = (listId: string) => {
    setDeleteDialogOpen(true);
    setDeleteListId(listId);
  };

  const closeDeleteDialog = () => {
    setDeleteDialogOpen(false);
    setDeleteListId(null);
  };

  const deleteList = async () => {
    if (!deleteListId) return;

    try {
      await axios.delete(`${process.env.REACT_APP_API_URL}/leads/list/${deleteListId}/delete`);
      setSnackbarMessage('List deleted successfully');
      setSnackbarSeverity('success');
    } catch (error: any) {
      const message = error.response?.data?.error || 'Failed to delete list';
      setSnackbarMessage(message);
      setSnackbarSeverity('error');
    }

    setDeleteDialogOpen(false);
    setSnackbarOpen(true);
  };



  const handleClick = (id, isFolder) => {
    if (isFolder) {
      setOpen((prevOpen) => ({
        ...prevOpen,
        [id]: !prevOpen[id],
      }));
    } else {
      setSelectedListId(id); // Update selected list ID
      props.onListSelect(id);
    }
  };


  const moveListToFolder = async (listId: string, folderId: string) => {
    try {
      const response = await axios.put(`${process.env.REACT_APP_API_URL}/leads/move-list/${listId}`, {
        destinationFolderId: folderId
      });
      setSnackbarMessage('List moved successfully');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    } catch (error) {
      setSnackbarMessage('Failed to move list');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
    setIsMoveDialogOpen(false); // Close the dialog irrespective of success or failure
  };

  const handleSnackbarClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return; // Prevents closing the Snackbar when clicked outside
    }
    setSnackbarOpen(false);
  };

  const openMoveDialog = (listId: string) => {
    setCurrentListId(listId);
    setIsMoveDialogOpen(true);

    // Log the listId here
    console.log("Move button clicked for listId:", listId);
  };

  const closeMoveDialog = () => {
    setIsMoveDialogOpen(false);
  };

  return (
    <>

      {showSkeleton ? (
        <Skeleton variant="rectangular" width={260} height={500} />
      ) : (
        <List
          sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
          component="nav"
          aria-labelledby="nested-list-subheader"
        >
          {foldersWithLists.map((folderItem: FolderItem, index) => (
            <React.Fragment key={folderItem.folder._id}>
              <ListItemButton onClick={() => handleClick(folderItem.folder._id, true)}>
                <ListItemIcon>
                  <FolderSharp color='info' />
                </ListItemIcon>
                <ListItemText color='info' primary={folderItem.folder.name} />
                {folderItem.lists.length > 0 ? (
                  open[folderItem.folder._id] ? <ExpandLess /> : <ExpandMore />
                ) : null}
              </ListItemButton>
              <Collapse in={open[folderItem.folder._id]} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {folderItem.lists.map((listItem) => (
                    <ListItemButton

                      key={listItem._id}
                      sx={{ pl: 4 }}

                      onClick={() => handleClick(listItem._id, false)}
                      selected={selectedListId === listItem._id} // Apply selected state

                    >
                      <ListItemIcon>
                        <ListSharp color='info' />
                      </ListItemIcon>
                      <ListItemText color='info' primary={listItem.name} />
                      <IconButton edge="end" aria-label="move" onClick={() => openMoveDialog(listItem._id)}>
                        <MoveToInboxSharp color='info' />
                      </IconButton>
                      <IconButton edge="end" aria-label="delete" onClick={() => openDeleteDialog(listItem._id)}>
                        <DeleteSharp color='info' />
                      </IconButton>
                    </ListItemButton>
                  ))}
                </List>
              </Collapse>
              {index < foldersWithLists.length - 1 && <Divider />}
            </React.Fragment>
          ))}
          {listsWithoutFolder.map((listItem: ListItem) => (
            <ListItemButton
              key={listItem._id}
              sx={{ pl: 4 }}
              selected={selectedListId === listItem._id}
              onClick={() => handleClick(listItem._id, false)}
            >
              <ListItemIcon>
                <ListSharp color='info' />
              </ListItemIcon>
              <ListItemText primary={listItem.name} />
              <IconButton edge="end" aria-label="move" onClick={() => openMoveDialog(listItem._id)}>
                <MoveToInboxSharp color='info' />
              </IconButton>
              <IconButton edge="end" aria-label="delete" onClick={() => openDeleteDialog(listItem._id)}>
                <DeleteSharp color='info' />
              </IconButton>

            </ListItemButton>

          ))}
          <MoveListDialog
            open={isMoveDialogOpen}
            onClose={closeMoveDialog}
            folders={foldersWithLists.map(folderItem => folderItem.folder)}
            listId={currentListId}
            onMoveList={moveListToFolder}
          />


        </List>

      )}

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        //@ts-ignore
        onClose={handleSnackbarClose}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>

      <Dialog
        open={deleteDialogOpen}
        onClose={closeDeleteDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirm Deletion"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this list? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDeleteDialog}>Cancel</Button>
          <Button onClick={deleteList} color="primary" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>

    </>
  );
}


