import { useAuth } from '@/src/app/modules/auth';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import { Alert, Box, Button, ButtonGroup, Divider, Grid, Paper, Typography, useTheme } from '@mui/material';
import axios from 'axios';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import React, { useCallback, useEffect, useState } from 'react';

import { CampaignStats } from './CampaignStatisticsInterface';
import CampaignStatisticsLoadingPlaceholder from './CampaignStatisticsLoadingPlaceholder';
import CampaignDetailsBox from './Charts/CampaignDetailsBox';
import LeadEmailVerificationChart from './Charts/LeadEmailVerificationChart';
import LeadStatusChart from './Charts/LeadStatusChart';
import StatsLineChart from './Charts/StatsLineChart';
import CampaignContactedLeadsTable from './Charts/UpcomingMessagesChart/CampaignContactedLeadsTable';
import UpcomingPersonalizedMessagesChart from './Charts/UpcomingMessagesChart/UpcomingPersonalizedMessagesChart';
// import { EmailActivityPanel } from './Charts/CampaignActicity/CampaignSentEmailsActivity/SentEmailsList';
import { LeadData } from './CampaignStatisticsInterfaces';

dayjs.extend(utc);
dayjs.extend(timezone);

interface SendingAccountData {
    totalMessages: number;
    sendingTimesUTC: string[];
}

interface CampaignStatisticsProps {
    selectedCampaignId: string | null;
    onNextStep: () => void;
}

export interface EmailActivity {
    _id: string | null;
    personalizedMessageId: string | null;
    messageId: string | null;
    senderEmail: string | null;
    recipientEmail: string | null;
    subject: string | null;
    status: string | null;
    opened: boolean | null;
    replied: boolean | null;
    sendDate: string | null;
}



// const generateSampleEmails = (count: number): EmailActivity[] => {
//     const statuses = ['Sent', 'Delivered', 'Bounced', 'Failed'];
//     const domains = ['gmail.com', 'yahoo.com', 'hotmail.com', 'outlook.com', 'example.com'];

//     return Array.from({ length: count }, (_, i) => ({
//         _id: `email_${i}`,
//         personalizedMessageId: `personalized_message_${i}`,
//         messageId: `message_${i}`,
//         senderEmail: `sender${i}@${domains[Math.floor(Math.random() * domains.length)]}`,
//         recipientEmail: `recipient${i}@${domains[Math.floor(Math.random() * domains.length)]}`,
//         subject: `Subject ${i}`,
//         status: statuses[Math.floor(Math.random() * statuses.length)],
//         opened: Math.random() > 0.5,
//         replied: Math.random() > 0.5,
//         sendDate: new Date(Date.now() - Math.floor(Math.random() * 30 * 24 * 60 * 60 * 1000)).toISOString()
//     }));
// };


// const sampleEmails: EmailActivity[] = generateSampleEmails(1000);

const CampaignStatistics: React.FC<CampaignStatisticsProps> = ({ selectedCampaignId, onNextStep }) => {
    const theme = useTheme();
    const { currentUser } = useAuth();

    const [loading, setLoading] = useState<boolean>(true);
    const [campaignStats, setCampaignStats] = useState<CampaignStats | null>(null);
    const [error, setError] = useState<string>('');
    const [upcomingMessagesData, setUpcomingMessagesData] = useState<{ [key: string]: SendingAccountData }>({});
    const [selectedHours, setSelectedHours] = useState<number>(24);
    const [contactedLeads, setContactedLeads] = useState<LeadData[]>([]);

    const convertToUserTimezone = useCallback((data: { [key: string]: SendingAccountData }) => {
        if (!currentUser) return data;
        return Object.entries(data).reduce((acc, [account, accountData]) => {
            acc[account] = {
                ...accountData,
                sendingTimesUTC: accountData.sendingTimesUTC.map(time =>
                    dayjs(time).tz(currentUser.timezone).format('YYYY-MM-DDTHH:mm:ssZ')
                )
            };
            return acc;
        }, {} as { [key: string]: SendingAccountData });
    }, [currentUser]);

    useEffect(() => {
        const fetchCampaignStats = async () => {
            setLoading(true);
            try {
                const response = await axios.get<CampaignStats>(`${process.env.REACT_APP_API_URL}/campaign/stats/${selectedCampaignId}`);
                setCampaignStats(response.data);
                setLoading(false);
            } catch (err: any) {
                const message = err.response?.data?.message || err.message || 'Failed to fetch campaign stats';
                setError(message);
                setLoading(false);
            }
        };

        if (selectedCampaignId) {
            fetchCampaignStats();
        }
    }, [selectedCampaignId]);

    const fetchUpcomingMessagesData = useCallback(async (hours: number) => {
        try {
            const response = await axios.get<{ [key: string]: SendingAccountData }>(`${process.env.REACT_APP_API_URL}/campaign/${selectedCampaignId}/messages/upcoming/${hours}`);
            const convertedData = convertToUserTimezone(response.data);
            setUpcomingMessagesData(convertedData);
        } catch (err: any) {
            console.error('Error fetching upcoming messages data:', err);
        }
    }, [selectedCampaignId, convertToUserTimezone]);


    const fetchContactedLeads = useCallback(async () => {
        try {
            const response = await axios.get<LeadData[]>(`${process.env.REACT_APP_API_URL}/campaign/${selectedCampaignId}/contacted-leads`);
            setContactedLeads(response.data);
        } catch (err: any) {
            console.error('Error fetching contacted leads:', err);
        }
    }, [selectedCampaignId]);

    useEffect(() => {
        if (selectedCampaignId) {
            fetchUpcomingMessagesData(selectedHours);
            fetchContactedLeads();
        }
    }, [selectedCampaignId, selectedHours, fetchUpcomingMessagesData, fetchContactedLeads]);

    if (loading) {
        return <CampaignStatisticsLoadingPlaceholder />;
    }

    if (error) {
        if (error === "List not found") {
            return (
                <Box>
                    <Grid
                        item
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            padding: '20px',
                            cursor: 'pointer',
                            height: 'auto',
                            marginTop: "30px"
                        }}
                        sx={{ '&:hover': { opacity: 0.7 } }}
                        onClick={onNextStep}
                    >
                        <Alert severity="info" style={{ backgroundColor: `${theme.palette.background.paper}` }}>
                            <Typography color="info">
                                Please connect a campaign to a list and start setting up your campaign.
                            </Typography>
                        </Alert>
                        <ArrowForwardIosSharpIcon
                            sx={{
                                fontSize: "240px",
                                padding: "15px",
                                borderRadius: "50%",
                                color: "#c9c9c9",
                                transition: "1s",
                                "&:hover": {
                                    fontSize: "320px",
                                }
                            }}
                        />
                    </Grid>
                </Box>
            );
        }
        return <Typography color="error">{error}</Typography>;
    }

    if (!campaignStats) {
        return <Typography color="error">Data is currently unavailable.</Typography>;
    }

    const completeData = campaignStats ? {
        emailValidationSeries: [
            campaignStats.leads.emailValidation.valid,
            campaignStats.leads.emailValidation.invalid,
            campaignStats.leads.emailValidation.unknown,
            campaignStats.leads.emailValidation.failed,
            campaignStats.leads.emailValidation.catchall
        ].map(item => item || 0),
        leadStatusSeries: [
            campaignStats.personalizedMessageDocuments.numberOfPendingLeads,
            campaignStats.personalizedMessageDocuments.numberOfQueuedLeads,
            campaignStats.personalizedMessageDocuments.numberOfInProgressLeads,
            campaignStats.personalizedMessageDocuments.numberOfCompletedLeads,
        ].map(item => item || 0),
        statsSeries: [
            campaignStats.stats.sentEmails,
            campaignStats.stats.emailOpens,
            campaignStats.stats.emailReplies,
            campaignStats.stats.bouncedLeads,
        ].map(item => item || 0)
    } : { emailValidationSeries: [], leadStatusSeries: [], statsSeries: [] };

    return (
        <Box p={2}>
            <Typography variant="h4" align="center" gutterBottom>
                Campaign Statistics
            </Typography>
            <Divider />
            <Box display="flex" justifyContent="space-between" flexWrap="wrap" mt={2}>
                <Box width={{ xs: '100%', md: '48%' }} mb={2}>
                    <CampaignDetailsBox campaignStats={campaignStats} />
                </Box>
                <Box width={{ xs: '100%', md: '48%' }} mb={2}>
                    <LeadEmailVerificationChart
                        series={completeData.emailValidationSeries}
                        campaignStatsAvailable={!!campaignStats}
                    />
                </Box>
                <Box width={{ xs: '100%', md: '48%' }} mb={2}>
                    <LeadStatusChart
                        series={completeData.leadStatusSeries}
                    />
                </Box>
                <Box width={{ xs: '100%', md: '48%' }} mb={2}>
                    <StatsLineChart
                        series={completeData.statsSeries}
                    />
                </Box>
            </Box>
            <Paper elevation={3} sx={{ padding: 2, mt: 4 }}>
                <Typography variant="h5" align="center" gutterBottom>
                    Upcoming Sending Schedule
                </Typography>
                <Box display="flex" justifyContent="center" mb={2}>
                    <ButtonGroup variant="contained" color="primary">
                        <Button onClick={() => setSelectedHours(24)} disabled={selectedHours === 24} aria-label="Show messages for 24 hours">24 Hours</Button>
                        <Button onClick={() => setSelectedHours(48)} disabled={selectedHours === 48} aria-label="Show messages for 48 hours">48 Hours</Button>
                        <Button onClick={() => setSelectedHours(72)} disabled={selectedHours === 72} aria-label="Show messages for 72 hours">72 Hours</Button>
                    </ButtonGroup>
                </Box>
                <UpcomingPersonalizedMessagesChart
                    data={upcomingMessagesData}
                />
                <CampaignContactedLeadsTable
                    contactedLeads={contactedLeads} />


                {/* <EmailActivityPanel emails={sampleEmails} /> */}

            </Paper>
        </Box>
    );
};

export default CampaignStatistics;
