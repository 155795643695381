import { useTheme } from '@mui/material';
import React from 'react';
import Chart from 'react-apexcharts';

interface StatsLineChartProps {
    series?: number[];  // Make series optional and provide default data if not passed
}

// const StatsLineChart: React.FC<StatsLineChartProps> = ({ series = [100, 75, 200, 50] }) => {

const StatsLineChart: React.FC<StatsLineChartProps> = ({ series }) => {
    const theme = useTheme();

    const statsLineOptions: ApexCharts.ApexOptions = {
        chart: {
            type: 'bar',
            height: 350,
            toolbar: {
                show: false
            },
            events: {
                click: function (chart, w, e) {
                    console.log(chart, w, e);
                }
            }
        },
        colors: [theme.palette.primary.main, theme.palette.secondary.main, theme.palette.primary.main, theme.palette.secondary.main],
        plotOptions: {
            bar: {
                columnWidth: '45%',
                distributed: true,
            }
        },
        dataLabels: {
            enabled: false
        },
        legend: {
            show: false
        },
        xaxis: {
            categories: ['Sent Emails', 'Email Opens', 'Email Replies', 'Bounced Leads'],
            labels: {
                style: {
                    colors: [theme.palette.primary.main, theme.palette.secondary.main, theme.palette.primary.main, theme.palette.secondary.main],
                    fontSize: '12px'
                }
            },
            axisBorder: {
                show: false,
                color: theme.palette.text.secondary, // Customize x-axis border color
            },
            axisTicks: {
                show: false,
                color: theme.palette.text.secondary // Customize x-axis tick color
            }
        },
        yaxis: {
            labels: {
                style: {
                    colors: theme.palette.info.main,
                    fontSize: '12px'
                }
            },
            axisBorder: {
                show: false,
                color: theme.palette.text.secondary, // Customize y-axis border color
            },
            axisTicks: {
                show: false,
                color: theme.palette.text.secondary // Customize y-axis tick color
            }
        },
        grid: {
            show: false,
            borderColor: theme.palette.grey[400], // Adjust this value to change the grid color
            strokeDashArray: 0, // Optional: set to 0 for solid lines
        }

    };

    return (
        <Chart
            options={statsLineOptions}
            //@ts-ignore
            series={[{ name: 'Count', data: series }]}
            type="bar"
            height={350}
        />
    );
};

export default StatsLineChart;
