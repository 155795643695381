import React, { useState } from 'react';
import { TextField, Button, Typography } from '@mui/material';
import { styled } from '@mui/system';

interface PasswordProtectionProps {
    correctPassword: string;
    children: React.ReactNode;
}

const CenteredContainer = styled('div')({
});

const PasswordProtection: React.FC<PasswordProtectionProps> = ({ correctPassword, children }) => {
    const [enteredPassword, setEnteredPassword] = useState<string>('');
    const [isPasswordCorrect, setIsPasswordCorrect] = useState<boolean | null>(null);

    const handlePasswordSubmit = () => {
        const isCorrect = enteredPassword === correctPassword;
        setIsPasswordCorrect(isCorrect);

        if (isCorrect) {
            // You might want to perform additional actions upon correct password entry
            console.log('Correct password! Displaying content.');
        } else {
            console.log('Incorrect password!');
        }
    };

    const handleKeyPress = (event: React.KeyboardEvent) => {
        if (event.key === 'Enter') {
            handlePasswordSubmit();
        }
    };

    return (
        <CenteredContainer>
            {isPasswordCorrect === null ? (

                <><div>
                    <Typography
                        variant="h6"
                        component="div"
                    >
                        {isPasswordCorrect ? '' : 'Restricted Area'}
                    </Typography>
                </div>

                    <TextField
                        id="password"
                        name="password"
                        type="password"
                        variant="standard"
                        sx={{ width: '300px', marginBottom: '10px' }}
                        placeholder="Enter password"
                        value={enteredPassword}
                        onChange={(e) => setEnteredPassword(e.currentTarget.value)}
                        onKeyPress={handleKeyPress} /></>
            ) : (
                <Typography
                    variant="h6"
                    component="div"
                    style={{ color: isPasswordCorrect ? 'green' : 'red', marginBottom: '10px' }}
                >
                    {isPasswordCorrect ? '' : 'Incorrect password!'}
                </Typography>
            )}

            {isPasswordCorrect && <div>{children}</div>}

            {isPasswordCorrect === null && (
                <Button variant="text" color="primary" onClick={handlePasswordSubmit}>
                    Submit
                </Button>
            )}
        </CenteredContainer>
    );
};

export default PasswordProtection;
