import React from 'react';
import { List, ListItem, ListItemText, Checkbox } from '@mui/material';

interface CollectionListProps {
    data: any[]; // Specify the correct type for your data
    onCollectionSelect: (collectionId: string) => void;
}

const CollectionList: React.FC<CollectionListProps> = ({ data, onCollectionSelect }) => {
    return (
        <List>
            {data.map((item: any) => (
                <ListItem key={item._id} button onClick={() => onCollectionSelect(item._id)}>
                    <Checkbox />
                    <ListItemText primary={item.collectionName} />
                </ListItem>
            ))}
        </List>
    );
};

export default CollectionList;
