
// src\app\pages\uploadcsv2\ProgressIndicator.tsx
import React from 'react';
import { Box, LinearProgress, Typography } from '@mui/material';

const ProgressBar = {
    progress: {
        height: '30px',
        width: '100%'
    },
};

interface ProgressIndicatorProps {
    selectedFile: File | null;
    headerMappings: { [key: string]: string };
    headerTypeMappings: { [key: string]: string };
    visibility: boolean[];
}

const ProgressIndicator: React.FC<ProgressIndicatorProps> = ({
    selectedFile,
    headerMappings,
    headerTypeMappings,
    visibility
}) => {
    const calculateProgress = () => {
        let progress = 0;

        if (selectedFile) {
            progress += 5;

            // Check for email and phone mappings
            const hasEmail = Object.values(headerTypeMappings).includes('email');
            const hasPhone = Object.values(headerTypeMappings).includes('phone');
            const hasLocation = Object.values(headerTypeMappings).includes('location');
            if (hasEmail) progress += 15;
            if (hasPhone) progress += (hasEmail ? 10 : 15);
            if (hasLocation) progress += 10;

            // Check for name and company mappings
            const nameFields = ['fullname', 'firstname', 'lastname', 'companyname'];
            const mappedNameFields = nameFields.filter(field => Object.values(headerTypeMappings).includes(field));
            if (mappedNameFields.length > 0) {
                progress += 15; // 15% for the first field
                if (mappedNameFields.length > 1) {
                    progress += 3 * (mappedNameFields.length - 1); // 3% for each additional field
                }
            }

            // Increase progress based on how many columns are switched on
            const switchedOnColumns = visibility.filter(v => v).length;
            if (switchedOnColumns >= 2) {
                progress += 10; // Add 10% if at least 2 columns are switched on
            }
        }

        return Math.min(progress, 100); // Ensure the progress doesn't exceed 100%
    };

    const progress = calculateProgress();

    return (
        <Box>
            <Typography variant="h6">Helper</Typography>
            <LinearProgress variant="determinate" value={progress} sx={ProgressBar.progress} />
            <Typography>{progress}%</Typography>
        </Box>
    );
};

export default ProgressIndicator;
