// src\app\pages\4-Campaigns\5-CampaignReview\CamapignReviewPlaceholders.tsx
import { Card, CardContent, Skeleton } from '@mui/material';
import React from 'react';


interface PlaceholderProps {
    isLoading: boolean;
}

const PlaceholderLeadsReviewSequence: React.FC<PlaceholderProps> = ({ isLoading }) => (
    <div>
        <Card>
            <CardContent>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    {[1, 2, 3].map((index) => (
                        <div key={index} style={{ margin: '10px 0', width: '100%' }}>
                            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                                <Skeleton animation={isLoading ? 'wave' : false} variant="text" width="60%" height={30} />
                            </div>
                            <Skeleton animation={isLoading ? 'wave' : false} variant="rectangular" width="100%" height={75} />
                            <Skeleton animation={isLoading ? 'wave' : false} variant="text" width="85%" height={20} />
                        </div>
                    ))}
                </div>
            </CardContent>
        </Card>
    </div>
);

const PlaceholderLeadReviewInfo: React.FC<PlaceholderProps> = ({ isLoading }) => (
    <div style={{ padding: '20px', margin: '20px 0' }}>
        <Skeleton animation={isLoading ? 'wave' : false} variant="text" width={210} height={28} />
        <Skeleton animation={isLoading ? 'wave' : false} variant="rectangular" width={300} height={150} style={{ marginBottom: '20px' }} />
        {[1, 2].map((index) => (
            <React.Fragment key={index}>
                <Skeleton animation={isLoading ? 'wave' : false} variant="text" width={180} height={30} />
                <Skeleton animation={isLoading ? 'wave' : false} variant="text" width="100%" height={10} />
                <Skeleton animation={isLoading ? 'wave' : false} variant="text" width="90%" height={10} style={{ marginTop: '10px' }} />
                <Skeleton animation={isLoading ? 'wave' : false} variant="text" width="80%" height={10} style={{ marginTop: '10px' }} />
            </React.Fragment>
        ))}
    </div>
);

const PlaceholderLeadsReviewList: React.FC<PlaceholderProps> = ({ isLoading }) => (
    <Card>
        <CardContent>
            <Skeleton animation={isLoading ? 'wave' : false} variant="text" width="80%" height={20} />
            <Skeleton animation={isLoading ? 'wave' : false} variant="text" width="80%" height={20} style={{ marginTop: '10px' }} />
            <Skeleton animation={isLoading ? 'wave' : false} variant="rectangular" width="100%" height={100} style={{ marginTop: '20px' }} />
        </CardContent>
    </Card>
);

export { PlaceholderLeadsReviewSequence, PlaceholderLeadReviewInfo, PlaceholderLeadsReviewList };
