// src\app\modules\auth\Logout.tsx
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useAuth } from './core/Auth';

export function Logout() {
  const { logout } = useAuth();
  let navigate = useNavigate();

  useEffect(() => {
    const performLogout = async () => {
      try {
        // Assuming your logout endpoint is set up at `/auth/logout`
        await axios.post(`${process.env.REACT_APP_API_URL}/auth/logout`, {}, { withCredentials: true });
        logout(); // Clear context and local storage
        navigate('/auth/login', { replace: true });
      } catch (error) {
        console.error('Logout failed', error);
        // Handle logout error (e.g., show error message)
      }
    };

    performLogout();
  }, [logout, navigate]);

  // Optionally, render a loading indicator or a "logging out..." message until redirect occurs
  return null; // Or any appropriate logout feedback component
}



const LogoutWhenRefreshTokenExpires = () => {
  const { logout } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const handleLogout = (event) => {
      console.error(event.detail.message);
      logout();
      navigate('/auth/login', { replace: true });
    };

    window.addEventListener('requireLogout', handleLogout);

    return () => {
      window.removeEventListener('requireLogout', handleLogout);
    };
  }, [logout, navigate]);

  return null;
};

export default LogoutWhenRefreshTokenExpires;
