// src\app\pages\0-Account\EmailAccounts\ManageEmailAccounts\EmailAccountsList.tsx
import CheckCircleSharpIcon from '@mui/icons-material/CheckCircleSharp';
import DeleteIcon from '@mui/icons-material/Delete';
import RadioButtonCheckedSharpIcon from '@mui/icons-material/RadioButtonCheckedSharp';
import RadioButtonUncheckedSharpIcon from '@mui/icons-material/RadioButtonUncheckedSharp';
import {
    Checkbox,
    IconButton,
    Menu,
    MenuItem,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@mui/material';
import axios from 'axios';
import { motion } from 'framer-motion';
import React, { useState } from 'react';

import { ConfirmDeletionDialog } from './ConfirmDeletionDialog';

interface Account {
    _id: string;
    sendingAccount: string;
    // Add other properties as needed
}

interface SelectionOptionsProps {
    handleSelectAll: () => void;
    handleUnselectAll: () => void;
    handleClose: () => void; // Used to close the options box
}

interface EmailAccountsListProps {
    accounts: Account[];
    selectedAccountId: string;
    handleListItemClick: (accountId: string) => void;
    listItemHeight: string;
    onCheckedAccountsChange: (checkedAccountIds: string[]) => void;
    onDeletionSuccess: (message: string) => void;
    onDeletionFailure: (message: string) => void;
    refreshAccountsList: () => void;
}

const listItemVariants = {
    initial: { opacity: 0, x: -50 },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: -50 },
};

const springConfig = { damping: 15, stiffness: 500 };

const EmailAccountsList: React.FC<EmailAccountsListProps> = ({
    accounts,
    selectedAccountId,
    handleListItemClick,
    listItemHeight,
    onCheckedAccountsChange,
    onDeletionSuccess,
    onDeletionFailure,
    refreshAccountsList,
}) => {
    const [checkedAccounts, setCheckedAccounts] = useState<string[]>([]);
    const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [showSelectionOptions, setShowSelectionOptions] = useState(false);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
        setShowSelectionOptions(true);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setShowSelectionOptions(false);
    };


    const getSelectionState = () => {
        if (checkedAccounts.length === 0) {
            return 'none';
        } else if (checkedAccounts.length === accounts.length) {
            return 'all';
        } else {
            return 'indeterminate';
        }
    };
    const selectionState = getSelectionState();


    const handleToggleSelection = (event: React.MouseEvent<HTMLButtonElement>) => {
        const selectionState = getSelectionState();

        if (selectionState === 'none') {
            handleSelectAll();
        } else if (selectionState === 'all') {
            handleUnselectAll();
        } else {
            handleClick(event);
        }
    };



    const SelectionOptions: React.FC<SelectionOptionsProps> = ({
        handleSelectAll,
        handleUnselectAll,
        handleClose,
    }) => {
        return (
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem onClick={() => { handleSelectAll(); handleClose(); }}>
                    Select All
                </MenuItem>
                <MenuItem onClick={() => { handleUnselectAll(); handleClose(); }}>
                    Unselect All
                </MenuItem>
            </Menu>
        );
    };





    const handleToggle = (value: string) => () => {
        const currentIndex = checkedAccounts.indexOf(value);
        const newChecked = [...checkedAccounts];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setCheckedAccounts(newChecked);
        onCheckedAccountsChange(newChecked);
    };

    const handleSelectAll = () => {
        const allAccountIds = accounts.map(account => account._id);
        setCheckedAccounts(allAccountIds);
        onCheckedAccountsChange(allAccountIds);
    };

    const handleUnselectAll = () => {
        setCheckedAccounts([]);
        onCheckedAccountsChange([]);
    };

    const handleDeleteConfirmation = () => {
        setIsConfirmDialogOpen(true);
    };


    const handleConfirmDeletion = async () => {
        setIsConfirmDialogOpen(false);
        try {
            await axios.delete(`${process.env.REACT_APP_API_URL}/account/emailaccounts/bulk-delete`, {
                data: { accountIds: checkedAccounts },
            });
            onDeletionSuccess(`Deleted ${checkedAccounts.length} accounts.`);
            setCheckedAccounts([]);
            refreshAccountsList();
        } catch (error: any) {
            onDeletionFailure(error.response?.data?.message || 'Failed to delete accounts.');
        }
    };

    return (
        <motion.div
            className="no-global-transition"

            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
        >
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell padding="checkbox">
                                <IconButton onClick={(event) => handleToggleSelection(event)} color="primary">
                                    {selectionState === 'none' && <RadioButtonUncheckedSharpIcon style={{ fontSize: "25" }} />}
                                    {selectionState === 'all' && <CheckCircleSharpIcon color='secondary' style={{ fontSize: "25" }} />}
                                    {selectionState === 'indeterminate' && <RadioButtonCheckedSharpIcon color='secondary' style={{ fontSize: "25" }} />}
                                </IconButton>
                                {showSelectionOptions && (
                                    <SelectionOptions
                                        handleSelectAll={handleSelectAll}
                                        handleUnselectAll={handleUnselectAll}
                                        handleClose={handleClose}
                                    />
                                )}

                            </TableCell>
                            <TableCell>Email Account</TableCell>
                            <TableCell padding="checkbox">
                                <IconButton
                                    color="secondary"
                                    onClick={handleDeleteConfirmation}
                                    disabled={checkedAccounts.length === 0}
                                >
                                    <DeleteIcon />
                                </IconButton>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {accounts.map((account) => (
                            <TableRow
                                key={account._id}
                                selected={selectedAccountId === account._id}
                                hover
                                onClick={() => handleListItemClick(account._id)}
                            >
                                <TableCell padding="checkbox">
                                    <Checkbox
                                        checked={checkedAccounts.includes(account._id)}
                                        onChange={handleToggle(account._id)}
                                        checkedIcon={<CheckCircleSharpIcon color='primary' style={{ fontSize: "25" }} />}
                                        icon={<RadioButtonUncheckedSharpIcon color='primary' style={{ fontSize: "25" }} />}
                                    />
                                </TableCell>
                                <TableCell>{account.sendingAccount}</TableCell>
                                <TableCell padding="checkbox"></TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>


            <ConfirmDeletionDialog
                open={isConfirmDialogOpen}
                onClose={() => setIsConfirmDialogOpen(false)}
                onConfirm={handleConfirmDeletion}
            />
        </motion.div>
    );
};

export default EmailAccountsList;


