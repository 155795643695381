//@ts-nocheck
// src\app\pages\4-Campaigns\3-CampaignSequence\SequenceComponents\NewEditor\components\LexicalEditorTopBar\index.tsx
import { Divider, Grid, ToggleButton, ToggleButtonGroup } from "@mui/material";
import toolbarIconsList from "./toolbarIconsList";
import { Box } from "mdi-material-ui";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import useOnClickListener from "./useOnClickListener";
import { createPortal } from "react-dom";
import FloatingLinkEditor from "./FloatingLinkEditor";
import { InsertImageDialog } from "../CustomPlugins/ImagePlugin";
import React from "react";


const LexicalEditorTopBar = () => {
  const { onClick, selectedEventTypes, blockType, isLink, editor, modal } =
    useOnClickListener();

  const isIconSelected = (plugin) =>
    //@ts-ignore
    selectedEventTypes.includes(plugin.event) ||
    blockType.includes(plugin.event);

  const [selectedIcon, setSelectedIcon] = React.useState<string | null>(null);

  const handleIconChange = (
    event: React.MouseEvent<HTMLElement>,
    newIcon: string | null,
  ) => {
    setSelectedIcon(newIcon);
    // Call onClick function or perform any other action here
  };

  return (
    <Grid>
      <ToggleButtonGroup
        value={selectedIcon}
        exclusive
        onChange={handleIconChange}
        aria-label="toolbar icons"
        size="medium"
      >
        {toolbarIconsList.map((plugin) => (

          <ToggleButton
            key={plugin.id}
            value={plugin.event}
            aria-label={plugin.event}
            onClick={() => onClick(plugin.event)}
            selected={isIconSelected(plugin) ? true : false}
            sx={{ border: "solid 0px", borderRadius: "0px", margin: "4px" }}
          >
            <plugin.Icon color={isIconSelected(plugin) ? "secondary" : "info"} />
          </ToggleButton>
        ))}
      </ToggleButtonGroup>

      {modal}
      {isLink &&
        createPortal(<FloatingLinkEditor editor={editor} />, document.body)}
    </Grid>
  );

};

export default LexicalEditorTopBar;

