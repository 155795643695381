import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import TextField from '@mui/material/TextField';
import LinearProgress from '@mui/material/LinearProgress';
import './Leadgen.css';
import LeadgenTable from './LeadgenTable';
import axios from 'axios';
import Fab from '@mui/material/Fab';
import CheckIcon from '@mui/icons-material/Check';
import SearchIcon from '@mui/icons-material/Search';
import CircularProgress from '@mui/material/CircularProgress';

const LocalLeadgen: React.FC = () => {
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [error, setError] = useState<string | null>(null);
    const [maxItemsToScrape, setMaxItemsToScrape] = useState<number>(35);
    const [resultsFound, setResultsFound] = useState<number | null>(null);


    const buttonSx = {
        ...(success && {
            bgcolor: 'green',
            '&:hover': {
                bgcolor: 'darkgreen',
            },
        }),
    };

    const handleButtonClick = () => {
        if (!loading) {

            // Check if the search query is valid
            if (!isValidSearchQuery(searchQuery)) {
                setError('Please enter a valid search query.');
                return;
            }

            setSuccess(false);
            setLoading(true);
            setError(null);

            axios
                .get(`${process.env.REACT_APP_API_URL}/scrape/scrape-businesses?q=${searchQuery}&maxItemsToScrape=${maxItemsToScrape}`)
                .then((response) => {
                    if (response.data.totalResultsFound) {
                        setResultsFound(response.data.totalResultsFound);
                        setSuccess(true);
                    } else {
                        setError('No results found. Please try a different search.');
                    }
                })
                .catch((error) => {
                    // Check if the error response exists and has a data property
                    if (error.response && error.response.data) {
                        // Set the error message from the server response
                        setError(error.response.data.message || 'An error occurred while fetching data.');
                    } else {
                        // Fallback error message if the response is not available
                        setError('An error occurred while fetching data. Please try again later.');
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    };


    const isValidSearchQuery = (query) => {
        return query && query.trim().length > 2; // Add more conditions as needed
    };


    return (
        <Box sx={{ width: 900, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Slider
                defaultValue={35}
                value={maxItemsToScrape}
                onChange={(event, newValue) => setMaxItemsToScrape(newValue as number)}
                valueLabelDisplay="auto"
                color="secondary"
                min={10}
                max={150}
            />

            <TextField
                fullWidth
                label="Search for Leads"
                id="fullWidth"
                color="secondary"
                InputLabelProps={{
                    style: { textAlign: 'center', fontWeight: 'bold', color: 'var(--bs-primary)' },
                }}
                inputProps={{
                    style: { textAlign: 'center', fontWeight: 'bold', color: 'var(--bs-primary)' },
                    value: searchQuery,
                    onChange: (e: React.ChangeEvent<HTMLInputElement>) => setSearchQuery(e.target.value),
                }}
            />

            <LinearProgress color="secondary" />

            <Box sx={{ m: 1, position: 'relative' }}>
                <Fab
                    aria-label="search"
                    color="secondary"
                    sx={buttonSx}
                    onClick={handleButtonClick}
                >
                    {success ? <CheckIcon /> : <SearchIcon />}
                </Fab>
                {loading && (
                    <CircularProgress
                        size={68}
                        sx={{
                            color: 'orange',
                            position: 'absolute',
                            top: -6,
                            left: -6,
                            zIndex: 1,
                        }}
                    />
                )}
            </Box>

            {success && resultsFound !== null && (
                <div style={{ color: 'green', marginTop: '10px' }}>
                    Success! {resultsFound} results found.
                </div>
            )}

            {error && (
                <div style={{ color: 'red', marginTop: '10px' }}>
                    {error}
                </div>
            )}

            <LeadgenTable />
        </Box>
    );
};

export default LocalLeadgen;
