import BusinessIcon from '@mui/icons-material/Business';
import SchoolIcon from '@mui/icons-material/School';
import {
    Avatar,
    Box,
    Card,
    CardContent,
    CardHeader,
    Chip,
    Divider,
    Grid,
    Link,
    List,
    ListItem,
    Paper,
    Typography,
} from '@mui/material';
import React from 'react';

const EducationComponent = ({ leadData }) => {
    const formatUrl = (url) => {
        try {
            return new URL(url).href;
        } catch {
            return url.startsWith('http') ? url : `http://${url}`;
        }
    };

    return (
        <Box>
            <Typography variant="h4" gutterBottom align="center" sx={{ fontWeight: 'bold', color: 'primary.main' }}>
                Education
            </Typography>
            <List>
                {leadData.education.map((edu, index) => (
                    <ListItem key={index} disableGutters>
                        <Card variant="outlined" sx={{ width: '100%', mb: 2 }}>
                            <CardHeader
                                avatar={
                                    <Avatar>
                                        <SchoolIcon />
                                    </Avatar>
                                }
                                title={edu.school?.name}
                                subheader={edu.degrees?.join(', ')}
                                action={
                                    <Chip label={edu.school?.type} icon={<BusinessIcon />} />
                                }
                            />
                            <CardContent>
                                <Grid container spacing={2}>
                                    {edu.school?.location?.name && (
                                        <Grid item xs={12} sm={6}>
                                            <Paper variant="outlined" sx={{ p: 1 }}>
                                                <Typography variant="subtitle2"><strong>Location Name:</strong> {edu.school.location.name}</Typography>
                                            </Paper>
                                        </Grid>
                                    )}
                                    {edu.school?.location?.locality && (
                                        <Grid item xs={12} sm={6}>
                                            <Paper variant="outlined" sx={{ p: 1 }}>
                                                <Typography variant="subtitle2"><strong>Locality:</strong> {edu.school.location.locality}</Typography>
                                            </Paper>
                                        </Grid>
                                    )}
                                    {edu.school?.location?.region && (
                                        <Grid item xs={12} sm={6}>
                                            <Paper variant="outlined" sx={{ p: 1 }}>
                                                <Typography variant="subtitle2"><strong>Region:</strong> {edu.school.location.region}</Typography>
                                            </Paper>
                                        </Grid>
                                    )}
                                    {edu.school?.location?.country && (
                                        <Grid item xs={12} sm={6}>
                                            <Paper variant="outlined" sx={{ p: 1 }}>
                                                <Typography variant="subtitle2"><strong>Country:</strong> {edu.school.location.country}</Typography>
                                            </Paper>
                                        </Grid>
                                    )}
                                    {edu.school?.location?.continent && (
                                        <Grid item xs={12} sm={6}>
                                            <Paper variant="outlined" sx={{ p: 1 }}>
                                                <Typography variant="subtitle2"><strong>Continent:</strong> {edu.school.location.continent}</Typography>
                                            </Paper>
                                        </Grid>
                                    )}
                                    {edu.school?.linkedin_url && (
                                        <Grid item xs={12} sm={6}>
                                            <Paper variant="outlined" sx={{ p: 1 }}>
                                                <Typography variant="subtitle2">
                                                    <strong>LinkedIn:</strong> <Link href={formatUrl(edu.school.linkedin_url)} target="_blank" rel="noopener noreferrer">{edu.school.linkedin_url}</Link>
                                                </Typography>
                                            </Paper>
                                        </Grid>
                                    )}
                                    {edu.school?.facebook_url && (
                                        <Grid item xs={12} sm={6}>
                                            <Paper variant="outlined" sx={{ p: 1 }}>
                                                <Typography variant="subtitle2">
                                                    <strong>Facebook:</strong> <Link href={formatUrl(edu.school.facebook_url)} target="_blank" rel="noopener noreferrer">{edu.school.facebook_url}</Link>
                                                </Typography>
                                            </Paper>
                                        </Grid>
                                    )}
                                    {edu.school?.twitter_url && (
                                        <Grid item xs={12} sm={6}>
                                            <Paper variant="outlined" sx={{ p: 1 }}>
                                                <Typography variant="subtitle2">
                                                    <strong>Twitter:</strong> <Link href={formatUrl(edu.school.twitter_url)} target="_blank" rel="noopener noreferrer">{edu.school.twitter_url}</Link>
                                                </Typography>
                                            </Paper>
                                        </Grid>
                                    )}
                                    {edu.school?.website && (
                                        <Grid item xs={12} sm={6}>
                                            <Paper variant="outlined" sx={{ p: 1 }}>
                                                <Typography variant="subtitle2">
                                                    <strong>Website:</strong> <Link href={formatUrl(edu.school.website)} target="_blank" rel="noopener noreferrer">{edu.school.website}</Link>
                                                </Typography>
                                            </Paper>
                                        </Grid>
                                    )}
                                    {edu.start_date && (
                                        <Grid item xs={12} sm={6}>
                                            <Paper variant="outlined" sx={{ p: 1 }}>
                                                <Typography variant="subtitle2"><strong>Start Date:</strong> {edu.start_date}</Typography>
                                            </Paper>
                                        </Grid>
                                    )}
                                    {edu.end_date && (
                                        <Grid item xs={12} sm={6}>
                                            <Paper variant="outlined" sx={{ p: 1 }}>
                                                <Typography variant="subtitle2"><strong>End Date:</strong> {edu.end_date}</Typography>
                                            </Paper>
                                        </Grid>
                                    )}
                                    {edu.majors?.length > 0 && (
                                        <Grid item xs={12} sm={6}>
                                            <Paper variant="outlined" sx={{ p: 1 }}>
                                                <Typography variant="subtitle2"><strong>Majors:</strong> {edu.majors.join(', ')}</Typography>
                                            </Paper>
                                        </Grid>
                                    )}
                                    {edu.minors?.length > 0 && (
                                        <Grid item xs={12} sm={6}>
                                            <Paper variant="outlined" sx={{ p: 1 }}>
                                                <Typography variant="subtitle2"><strong>Minors:</strong> {edu.minors.join(', ')}</Typography>
                                            </Paper>
                                        </Grid>
                                    )}
                                    {edu.gpa && (
                                        <Grid item xs={12} sm={6}>
                                            <Paper variant="outlined" sx={{ p: 1 }}>
                                                <Typography variant="subtitle2"><strong>GPA:</strong> {edu.gpa}</Typography>
                                            </Paper>
                                        </Grid>
                                    )}
                                    {edu.summary && (
                                        <Grid item xs={12}>
                                            <Divider sx={{ my: 2 }} />
                                            <Typography variant="body2"><strong>Summary:</strong> {edu.summary}</Typography>
                                        </Grid>
                                    )}
                                </Grid>
                            </CardContent>
                        </Card>
                    </ListItem>
                ))}
            </List>
        </Box>
    );
};

export default EducationComponent;
