// src\app\pages\1-DashBoard\GetStartedInfo\FindPeopleDetails.tsx
import React from 'react';
import { Box, Typography, Card, CardContent, List, ListItem, ListItemIcon, ListItemText, Divider } from '@mui/material';
import { CircleSharp } from '@mui/icons-material';

const Prospecting = '/getstarted/prospecting.svg';

const FindNewPeople = () => {
  return (
    <Box sx={{ maxWidth: 600, mx: 'auto', p: 2 }}>
      <Card sx={{ mb: 2, background: "transparent" }}>
        <CardContent>
          <Typography variant="h4" component="h1" gutterBottom>
            Find Your Ideal Leads
          </Typography>
          <Typography variant="body1" paragraph>
            Get a Client.ai provides three powerful ways to find and prepare a list of people to reach out to:
          </Typography>
          <List>


            <ListItem
              sx={{
                display: 'flex',
                justifyContent: 'center', // Horizontally center
                alignItems: 'center', // Vertically center
              }}
            >
              <Box
                component="div"
                sx={{
                  width: '50%',
                  height: '250px',
                  backgroundImage: `url(${Prospecting})`,
                  backgroundSize: 'cover',
                  backgroundRepeat: 'no-repeat',
                }}
              />
            </ListItem>

            <ListItem>
              <ListItemIcon>
                <CircleSharp sx={{ color: "#747477" }} />
              </ListItemIcon>
              <ListItemText
                primary="Advanced Lead Search"
                secondary="Best for targeting people by email, phone, or mail. Use our filters to find leads by industry, job title, location, company size, and skills."
              />
            </ListItem>
            <Divider />
            <ListItem>
              <ListItemIcon>
                <CircleSharp sx={{ color: "#747477" }} />
              </ListItemIcon>
              <ListItemText
                primary="Real-Time Local Business Phone Number Lookup"
                secondary="Ideal for targeting local businesses using cold calls or targeted SMS campaigns. Perfect for targeting businesses like roofing companies, plumbers, and local services where business owners rely on the phone for communication rather than email."
              />
            </ListItem>
            <Divider />
            <ListItem>
              <ListItemIcon>
                <CircleSharp sx={{ color: "#747477" }} />
              </ListItemIcon>
              <ListItemText
                primary="Upload Your Own List"
                secondary="Easily upload a CSV or Excel file with your leads. The platform will validate and organize your data for seamless integration into your campaigns."
              />
            </ListItem>
          </List>
        </CardContent>
      </Card>

      <Card sx={{ mb: 2, background: "transparent" }}>
        <CardContent>
          <Typography variant="h5" component="h2" gutterBottom>
            Advanced Lead Search
          </Typography>
          <Typography variant="body1" paragraph>
            Get a Client.ai connects you to a vast network of professionals. Use our advanced filters to find your perfect audience by industry, job title, location, company size, and skills.
          </Typography>
          <Typography variant="body1" paragraph>
            Once you’ve found your leads, you can import them into your list or campaign.
          </Typography>
        </CardContent>
      </Card>

      <Card sx={{ mb: 2, background: "transparent" }}>
        <CardContent>
          <Typography variant="h5" component="h2" gutterBottom>
            Local Business Phone Number Lookup
          </Typography>
          <Typography variant="body1" paragraph>
            Our Local Lead Generation tool is perfect if you target businesses that rely on phone communication. Best for SMS or cold call campaigns.
          </Typography>
        </CardContent>
      </Card>

      <Card sx={{ mb: 2, background: "transparent" }}>
        <CardContent>
          <Typography variant="h5" component="h2" gutterBottom>
            Upload Your Own List
          </Typography>
          <Typography variant="body1" paragraph>
            Easily upload a CSV or Excel file with your leads. Map your data fields, validate emails and phone numbers, clean your list of duplicates, process it with AI, and use it in your campaigns.
          </Typography>
        </CardContent>
      </Card>
    </Box>
  );
};

export default FindNewPeople;
