// src\app\pages\4-Campaigns\3-CampaignSequence\SequenceSteps.tsx
import { ResizableHandle, ResizablePanel, ResizablePanelGroup } from '@/src/ui/resizable';
import { ReplaySharp } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, debounce, Grid, Skeleton, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { useTheme } from '@mui/material/styles';
import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import { DragDropContext } from 'react-beautiful-dnd';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import DeleteConfirmationDialog from './DeleteConfirmationDialog';
import LiquidSyntaxGenerator from './LiquidSyntax/LiquidSyntaxGenerator';
import PersonalizationPlayground from './LiquidSyntax/PersonalizationPlayground';
import PlaceholderCards from './PlaceholderCards';
import SequenceEditingArea from './SequenceComponents/SequenceEditingArea';
import SequencePlanner from './SequenceComponents/SequencePlanner';
import { Step, Variation } from './StepInterface';
// import UpdateTrackingLinks from './MoreCampaignSettings.tsx/UpdateTrackingLinks';

interface SequencestepsProps {
    selectedCampaignId: string | null;
}

interface ReorderMutationVariables {
    stepIdToMove: string;
    newPosition: number;
}

interface NewVariation {
    label: string;
    body: string;
    subject?: string;
    type: string | undefined;
    emailType?: string;
}

export interface PersonalizationHeader {
    fieldId: string;
    name: string;
    type: string;
    personalization: string;
}

const CampaignSteps: React.FC<SequencestepsProps> = ({ selectedCampaignId }) => {

    const theme = useTheme(); // This hook is used to access the current theme


    const [stepsd, setSteps] = useState<Step[]>([]);
    const [selectedStep, setSelectedStep] = useState<Step | null>(null);
    const [openDialog, setOpenDialog] = useState(false);
    const [stepToDelete, setStepToDelete] = useState<string | null>(null);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [localSubject, setLocalSubject] = useState(selectedStep?.subject || '');
    const [localBody, setLocalBody] = useState(selectedStep?.body || '');
    const [variations, setVariations] = useState<Variation[]>([]);
    const [personalizationHeaders, setPersonalizationHeaders] = useState<PersonalizationHeader[]>([]);
    const [activeTab, setActiveTab] = useState(0);

    const variationsBodyState = variations.map(variation => variation.body).join();

    const fetchHeaders = async (campaignId: string) => {
        if (campaignId) {
            try {
                const campaignResponse = await axios.get(`${process.env.REACT_APP_API_URL}/campaign/${campaignId}`);
                const listId = campaignResponse.data.listId;
                const headersResponse = await axios.get(`${process.env.REACT_APP_API_URL}/leads/lists/info/${listId}`);
                setPersonalizationHeaders(headersResponse.data.headers);
            } catch (error) {
                console.error('Error fetching headers:', error);
            }
        }
    };



    // Create a debounced version of saveChanges
    const debouncedSaveChanges = useCallback(debounce(() => {
        saveChanges();
    }, 2000), []);

    // Use effect hooks for auto-save when subject or body changes
    useEffect(() => {
        if (selectedStep) {
            debouncedSaveChanges();
        }
    }, [localSubject, localBody, variationsBodyState, debouncedSaveChanges]);



    const queryClient = useQueryClient();

    const { data: steps, error, isLoading } = useQuery('steps', async () => {
        if (!selectedCampaignId) {
            throw new Error('Selected campaign ID is null or undefined.');
        }
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/campaign/sequencebycampaignid/${selectedCampaignId}`);
        return response.data.steps;
    }, {
        enabled: !!selectedCampaignId, // This query will not run until selectedCampaignId is truthy
        onSuccess: (steps) => {
            console.log("Fetched steps successfully:", steps);
            setSteps(steps);
        }
    });


    const saveChanges = () => {
        if (selectedStep) {
            const updatedStep = {
                ...selectedStep,
                subject: localSubject,
                body: localBody,
                variations: variations,
                percentage: 10,
            };
            console.log("Before mutation - updating step", updatedStep);
            updateStepMutation.mutate(updatedStep);
        }
    };


    useEffect(() => {
        if (selectedCampaignId) {
            fetchSteps(); // Fetch the steps for the newly selected campaign.
            setSelectedStep(null); // Reset the selectedStep to null for the new campaign.
            setLocalSubject(''); // Reset local subject
            setLocalBody(''); // Reset local body
            setVariations([]);
        } else {
            console.error('Selected campaign ID is null or undefined.');
            setSteps([]); // Reset the steps array when no campaign is selected.
            setSelectedStep(null); // Reset the selectedStep, localSubject, and localBody when no campaign is selected.
            setLocalSubject('');
            setLocalBody('');
            setVariations([]);
        }
    }, [selectedCampaignId]); // Add fetchSteps to the dependency array if it's defined outside of useEffect.



    const handleOpenDialog = () => {
        setIsDialogOpen(true);
    };

    useEffect(() => {
        const handleKeyDown = (event) => {
            // Check if the Ctrl key and the S key are pressed simultaneously
            if (event.ctrlKey && event.key === 's') {
                // Prevent the default behavior (e.g., saving the webpage)
                event.preventDefault();
                // Call the saveChanges function
                saveChanges();
            }
        };

        // Add the event listener when the component mounts
        document.addEventListener('keydown', handleKeyDown);

        // Clean up the event listener when the component unmounts
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [saveChanges]); // Make sure to include saveChanges in the dependency array to update the effect when it changes


    const handleCloseDialog = () => {
        setIsDialogOpen(false);
    };

    useEffect(() => {
        fetchSteps();
    }, []);

    const fetchSteps = async () => {
        try {
            if (selectedCampaignId) {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/campaign/sequencebycampaignid/${selectedCampaignId}`);
                setSteps(response.data.steps);
            } else {
                // Handle the case when selectedCampaignId is null or undefined
                console.error('Selected campaign ID is null or undefined.');
                // You can set some default steps or show an error message to the user.
            }
        } catch (error) {
            console.error('Error fetching steps:', error);
            // Handle the error, e.g., show an error message to the user or retry the request.
        }
    };

    const handleTypeChange = (event: React.ChangeEvent<HTMLInputElement>, id: string) => {
        event.stopPropagation();
        const newType = event.target.value;
        const updatedStep = steps.find(step => step._id === id);

        if (updatedStep) {
            const modifiedStep = { ...updatedStep, type: newType };
            setSelectedStep(modifiedStep); // Update the selected step immediately
            updateStepMutation.mutate(modifiedStep);
        }
    };


    const handleEmailTypeChange = (event: React.ChangeEvent<HTMLInputElement>, id: string) => {
        event.stopPropagation();
        const newEmailType = event.target.value;
        const updatedSteps = steps.map(step => {
            if (step._id === id) {
                return { ...step, emailType: newEmailType };
            }
            return step;
        });

        // Update steps state
        setSteps(updatedSteps);

        // Find the updated step but don't call saveChanges here
        const updatedStep = updatedSteps.find(step => step._id === id);
        if (updatedStep) {
            setSelectedStep(updatedStep); // Update selected step state
        }
    };

    useEffect(() => {
        // Check if selectedStep is not null and then call saveChanges
        if (selectedStep) {
            saveChanges();
        }
    }, [selectedStep]); // Add saveChanges to dependency array if it's stable or wrapped in useCallback


    const handleSubjectChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setLocalSubject(event.target.value);
    };



    const handleBodyChange = (newBody: string) => {
        setLocalBody(newBody);
    };

    const getCardStyle = (stepId) => {
        // Determine if the current theme mode is dark
        const isDarkMode = theme.palette.mode === 'dark';

        // Determine if the step is selected
        const isSelected = selectedStep?._id === stepId;

        // Construct the class name based on the theme mode and whether the step is selected
        const baseClass = isDarkMode ? 'stepCardDark' : 'stepCardLight';
        const selectedClass = isSelected ? (isDarkMode ? 'selectedStepDark' : 'selectedStepLight') : '';

        // Combine the base class with the selected class (if applicable)
        return `${baseClass} ${selectedClass}`;
    };



    // Ensure the mutation function correctly expects an argument of the type you're passing.
    const addStepMutation = useMutation((newStep: { type: string; subject: string; body: string; emailType: string; position: number }) =>
        axios.post(`${process.env.REACT_APP_API_URL}/campaign/add-step/${selectedCampaignId}`, newStep), {
        onSuccess: () => {
            queryClient.invalidateQueries('steps');
        }
    });


    const addStep = async (afterStepId) => {
        try {
            if (selectedCampaignId) {
                const afterStepIndex = afterStepId ? steps.findIndex(step => step._id === afterStepId) : steps.length - 1;
                const newPosition = afterStepIndex !== -1 ? steps[afterStepIndex].order + 1 : steps.length;

                const newStep = {
                    type: "email",
                    subject: "",
                    body: "",
                    emailType: "html",
                    position: newPosition
                };

                addStepMutation.mutate(newStep);
            } else {
                console.error('Selected campaign ID is null or undefined.');
                // Handle the case when selectedCampaignId is null or undefined
                // You can set some default steps or show an error message to the user.
            }
        } catch (error) {
            console.error('Error adding step:', error);
        }
    };


    // Define the toggleStepVariation mutation
    const toggleStepVariationMutation = useMutation(
        (stepId: string) => {
            return axios.put(`${process.env.REACT_APP_API_URL}/campaign/campaignid/${selectedCampaignId}/step/${stepId}/toggle-variation-enabled`);
        },
        {
            onSuccess: (stepId) => {
                queryClient.invalidateQueries('steps');
            },
            onError: (error) => {
                console.error('Error toggling step variation:', error);
            },
        }
    );



    // Function to toggle step variation
    const toggleStepVariation = (stepId: string) => {
        const updatedSteps = steps.map(step => {
            if (step._id === stepId) {
                return { ...step, variationsEnabled: !step.variationsEnabled };
            }
            return step;
        });

        // Update the steps state immediately for UI feedback
        setSteps(updatedSteps);

        // Find the updated step and ensure it is the selected one, then update the selected step
        const updatedStep = updatedSteps.find(step => step._id === stepId);
        if (updatedStep && selectedStep && updatedStep._id === selectedStep._id) {
            setSelectedStep(updatedStep); // This will trigger the necessary re-render in SequenceEditingArea
        }

        // Proceed to update the backend
        toggleStepVariationMutation.mutate(stepId);
    };



    const selectStepByIdWithoutUpdate = (stepId: string) => {
        // Directly find and select the new step without checking for unsaved changes
        const newSelectedStep = steps.find(step => step._id === stepId);
        if (newSelectedStep) {
            // setSelectedStep(newSelectedStep);
            // setLocalSubject(newSelectedStep.subject || '');
            // setLocalBody(newSelectedStep.body || '');
            // setVariations(newSelectedStep.variations || []);
        }
        console.log("Step selected without update:", { newSelectedStep });
    };



    // Mutation for deleting a step
    const deleteStepMutation = useMutation((stepId: string) => {
        return axios.delete(`${process.env.REACT_APP_API_URL}/campaign/${selectedCampaignId}/step/${stepId}`);
    }, {
        onSuccess: () => {
            queryClient.invalidateQueries('steps');
            setOpenDialog(false);
            setStepToDelete(null);
        },
        onError: (error) => {
            console.error('Error deleting step:', error);
        }
    });

    // Adjusted confirmDelete function to use mutation
    const confirmDelete = () => {
        if (stepToDelete) {
            deleteStepMutation.mutate(stepToDelete);
        }
    };

    // mutation for updating steps
    const updateStepMutation = useMutation((updatedStep: Step) => {
        return axios.put(`${process.env.REACT_APP_API_URL}/campaign/${selectedCampaignId}/step/${updatedStep._id}`, updatedStep);
    }, {
        onMutate: async (updatedStep) => {
            await queryClient.cancelQueries('steps');

            const previousSteps = queryClient.getQueryData<Step[]>('steps');
            if (previousSteps) {
                queryClient.setQueryData('steps', previousSteps.map(step => step._id === updatedStep._id ? updatedStep : step));
            }

            return { previousSteps };
        },
        onError: (err, newStep, context) => {
            if (context?.previousSteps) {
                queryClient.setQueryData('steps', context.previousSteps);
            }
        },
        onSettled: () => {
            queryClient.invalidateQueries('steps');
        },
        onSuccess: () => {
            // Invalidate and refetch
            queryClient.invalidateQueries('steps');
            // Here you could set a success state if you want to show a success message or change the state of the button
        },
    });

    const updateStepOrderMutation = useMutation<void, Error, ReorderMutationVariables>(
        ({ stepIdToMove, newPosition }) => {
            return axios.put(`${process.env.REACT_APP_API_URL}/campaign/reorder-steps/${selectedCampaignId}`, {
                stepIdToMove,
                newPosition
            });
        }, {
        onSuccess: () => {
            queryClient.invalidateQueries('steps');
        },
        onError: (error) => {
            console.error('Error updating step order:', error);
        }
    }
    );


    // Function to add a variation
    const addVariationMutation = useMutation((newVariation: NewVariation) => {
        return axios.post(`${process.env.REACT_APP_API_URL}/campaign/campaignid/${selectedCampaignId}/step/${selectedStep?._id}/add-variation`, newVariation);
    }, {
        onSuccess: () => {
            // Invalidate and refetch data to ensure the UI is up-to-date
            queryClient.invalidateQueries(['steps', selectedCampaignId]);
        }
    });

    const handleAddVariation = async () => {


        const isEmail = selectedStep?.type === 'email';
        const variationNumber = variations.length + 1; // Get the current number of variations and increment it by 1
        const newVariation = {
            label: `Variation ${variationNumber}`, // Add the serial number to the label
            body: selectedStep?.body,
            subject: "Subject A",
            type: selectedStep?.type,
            // Conditionally add the emailType only if the type is 'email'
            ...(isEmail && { emailType: selectedStep?.emailType ?? 'html' })
        };

        //@ts-ignore
        setVariations(currentVariations => {
            const updatedVariations = [...currentVariations, newVariation];
            console.log("Updated variations state:", updatedVariations);
            return updatedVariations;
        });

        // Make the API call to add the variation to the backend
        //@ts-ignore
        addVariationMutation.mutate({ ...newVariation, stepId: selectedStep?._id }, {
            onSuccess: () => {
                // Refetch the step data to reflect the new variation
                queryClient.invalidateQueries(['steps', selectedCampaignId]); // Adjust the query key as needed
                console.log("selectedstepafteradditionofvariation", selectedStep?._id)
                console.log("selectedcampaignafteradditionofvariation", selectedCampaignId)
                console.log("Adding a new variation", newVariation);
            },
            onError: (error) => {
                console.error("Error adding variation:", error);
                // Optionally handle the error state, such as showing a notification to the user
            }
        });
    };



    const deleteVariationMutation = useMutation((variationId: string) => {
        return axios.delete(`${process.env.REACT_APP_API_URL}/campaign/campaignid/${selectedCampaignId}/step/${selectedStep?._id}/variation/${variationId}`);
    }, {
        onSuccess: () => {
            // Invalidate and refetch data to ensure the UI is up-to-date
            queryClient.invalidateQueries('steps');
        }
    });

    const handleDeleteVariation = (variationId: string) => {
        // Optimistically remove the variation from local state
        setVariations(prevVariations => prevVariations.filter(variation => variation._id !== variationId));

        // Proceed with mutation and refetch upon success or failure
        deleteVariationMutation.mutate(variationId, {
            onSuccess: () => {
                queryClient.invalidateQueries(['steps', selectedCampaignId]); // Adjust the query key as needed
            },
            onError: () => {
                // Optionally handle re-adding the variation on error
                // This may require storing and using the removed variation's data
            },
        });
    };




    const onDragEnd = (result) => {
        if (!result.destination) return;

        const items = Array.from(steps);
        const [reorderedItem] = items.splice(result.source.index, 1) as [Step];
        items.splice(result.destination.index, 0, reorderedItem);

        const newPosition = result.destination.index;

        // Update the order on the server
        updateStepOrderMutation.mutate({
            stepIdToMove: reorderedItem._id,
            newPosition
        });
    };

    if (isLoading) {
        return (
            <DragDropContext onDragEnd={onDragEnd}>
                <Grid container style={{ height: '600px', width: "100%" }}>
                    <Grid item xs={3}>
                        <SequencePlannerSkeleton />
                    </Grid>
                    <Grid item xs={6}>
                        <SequenceEditingAreaSkeleton />
                    </Grid>
                    <Grid item xs={3}>
                        <OtherComponentsSkeleton />
                    </Grid>
                </Grid>
            </DragDropContext>
        );
    }


    const handleRetry = () => {
        // Implement your retry logic here, possibly resetting any relevant state or re-calling fetch functions
        fetchSteps();  // Example: Retry fetching steps
    };

    if (error instanceof Error) {
        return (
            <Grid container style={{ height: '600px', width: "100%", alignItems: 'center', justifyContent: 'center' }}>
                <Grid item xs={12} style={{ textAlign: 'center' }}>
                    <div style={{ marginBottom: '20px', fontSize: '18px', color: 'red' }}>{error.message}</div>
                    <IconButton
                        onClick={handleRetry}
                        style={{ backgroundColor: '#1976d2', color: 'white', padding: '20px', borderRadius: '50%' }}
                        size="large"
                    >
                        <ReplaySharp style={{ fontSize: '3rem' }} />
                    </IconButton>
                </Grid>
            </Grid>
        );
    }


    const handleDeleteOpen = (stepId: string) => {
        setStepToDelete(stepId);
        setOpenDialog(true);
    };

    const handleClose = () => {
        setOpenDialog(false);
    };



    //////////////////////////////////////////////////
    // Utility function to select a step
    const selectStepById = async (stepId: string) => {
        // Check if there are unsaved changes in the current step
        console.log("Before calling updateStep", { localSubject, localBody, selectedStep });

        if (selectedStep && (localSubject !== selectedStep.subject || localBody !== selectedStep.body)) {
            // Prepare the updated step data
            const updatedStep = {
                ...selectedStep,
                subject: localSubject,
                body: localBody,
                variations: variations,
            };

            // Save the changes - ensure updateStep is an async operation
            await updateStep(updatedStep); // Assumes updateStep returns a Promise
        }

        // After saving any changes, proceed to select the new step
        const newSelectedStep = steps.find(step => step._id === stepId);
        if (newSelectedStep) {
            setSelectedStep(newSelectedStep);
            setLocalSubject(newSelectedStep.subject || '');
            setLocalBody(newSelectedStep.body || '');
            setVariations(newSelectedStep.variations || []);
        }
        console.log("After calling updateStep");

    };

    // Updated NoStepSelectedPlaceholder with new click handler logic

    const updateStep = (updatedStep: Step) => {
        updateStepMutation.mutate(updatedStep, {
            onError: (error) => {
                console.error("Failed to update step", error);
            },
        });
    };






    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Grid container style={{ height: '75vh', width: "100%" }}>

                <ResizablePanelGroup
                    direction="horizontal"
                >

                    <ResizablePanel
                        defaultSize={20}
                        minSize={10}
                        maxSize={25}
                    >

                        <Grid
                            item
                            //  xs={2.5}
                            style={{ padding: '10px', overflow: "none" }}
                        >
                            <SequencePlanner
                                steps={stepsd}
                                onDragEnd={onDragEnd}
                                onAddStep={addStep}
                                onDeleteStep={handleDeleteOpen}
                                onSelectStep={setSelectedStep}
                                getCardStyle={getCardStyle}
                                handleTypeChange={handleTypeChange}
                                handleEmailTypeChange={handleEmailTypeChange}
                                onUpdate={updateStep}
                                selectStepById={selectStepById}
                                toggleStepVariation={toggleStepVariation}
                                selectedStep={selectedStep}

                            />
                        </Grid>

                    </ResizablePanel>


                    <ResizableHandle />

                    <ResizablePanel defaultSize={50} minSize={40} >

                        <Grid
                            //  item xs={6}

                            style={{ padding: '10px', overflow: 'auto', maxHeight: "75vh" }}>


                            <SequenceEditingArea
                                steps={steps}
                                selectedStep={selectedStep}
                                setSelectedStep={setSelectedStep} // This could directly set the selected step in the parent state
                                handleSubjectChange={handleSubjectChange}
                                handleBodyChange={handleBodyChange}
                                localSubject={localSubject}
                                localBody={localBody}
                                isLoading={updateStepMutation.isLoading}
                                isError={updateStepMutation.isError}
                                addStep={addStep}
                                selectStepById={selectStepById}
                                saveChanges={saveChanges}
                                onUpdate={updateStepMutation.data}
                                setLocalBody={setLocalBody}
                                setLocalSubject={setLocalSubject}
                                variations={variations}
                                setVariations={setVariations}
                                selectedCampaignId={selectedCampaignId}
                                handleDeleteVariation={handleDeleteVariation}
                                handleAddVariation={handleAddVariation}
                                personalizationHeaders={personalizationHeaders}
                                activeTab={activeTab}
                                setActiveTab={setActiveTab}
                            />
                        </Grid>

                    </ResizablePanel>

                    <ResizableHandle />

                    <ResizablePanel defaultSize={20} minSize={10}>

                        <Grid
                            // item xs={3.5} 
                            style={{ padding: '10px', overflow: 'auto', maxHeight: "75vh" }}>

                            {/* <Accordion>
                                <AccordionSummary>
                                    <div>Open Ai Magical Helper</div>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <IconButton onClick={handleOpenDialog}>Open Ai Magical Helper</IconButton>
                                    {selectedStep && (
                                        <AiMagicalHelperDialog
                                            open={isDialogOpen}
                                            onClose={handleCloseDialog}
                                            subject={selectedStep.subject || ''}
                                            body={selectedStep.body}
                                        />
                                    )}
                                </AccordionDetails>
                            </Accordion> */}
                            <Accordion>
                                <AccordionSummary>
                                    <div>Personalization</div>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <div style={{ maxHeight: '400px', overflowY: 'auto' }}>
                                        <PlaceholderCards
                                            selectedCampaignId={selectedCampaignId}
                                            headers={personalizationHeaders}
                                            fetchHeaders={fetchHeaders}
                                        />
                                    </div>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion>
                                <AccordionSummary>
                                    <div>Liquid Syntax Generator</div>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <div style={{ maxHeight: '400px', overflowY: 'auto' }}>
                                        <LiquidSyntaxGenerator />
                                    </div>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion>
                                <AccordionSummary>
                                    <div>Personalization Playground</div>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <PersonalizationPlayground campaignId={selectedCampaignId || ''} />
                                </AccordionDetails>
                            </Accordion>
                            {/* <Accordion> */}
                            {/* <AccordionSummary> */}
                            {/* <Typography>Update Tracking Links</Typography> */}
                            {/* </AccordionSummary> */}
                            {/* <AccordionDetails> */}
                            {/* <UpdateTrackingLinks campaignId={selectedCampaignId || ''} /> Integrate the new component */}
                            {/* </AccordionDetails> */}
                            {/* </Accordion> */}

                        </Grid>

                    </ResizablePanel>

                </ResizablePanelGroup>
            </Grid>
            <DeleteConfirmationDialog
                open={openDialog}
                onClose={handleClose}
                onConfirm={confirmDelete}
            />

        </DragDropContext>

    );
};

export default CampaignSteps;

const OtherComponentsSkeleton = () => (
    <Grid container spacing={2} style={{ padding: '10px' }}>
        <Grid item xs={12}>
            <Skeleton variant="circular" width={70} height={70} animation="wave" />
            <Skeleton variant="text" width="60%" height={30} animation="wave" />
            <Skeleton variant="text" width="80%" height={30} animation="wave" style={{ marginBottom: 8 }} />
            <Skeleton variant="rectangular" height={100} animation="wave" />
            <Skeleton variant="rectangular" height={70} animation="wave" />

        </Grid>
    </Grid>
);

const SequenceEditingAreaSkeleton = () => (
    <Grid container spacing={2} style={{ padding: '10px' }}>
        <Grid item xs={12}>
            <Skeleton variant="text" width="40%" height={50} animation="wave" />
            <Skeleton variant="rectangular" sx={{ marginTop: "10px" }} height={200} animation="wave" />
        </Grid>
    </Grid>
);

const SequencePlannerSkeleton = () => (
    <Grid container spacing={1} style={{ padding: '10px' }}>
        {Array.from(new Array(5)).map((_, index) => (
            <Grid item xs={12} key={index}>
                <Skeleton variant="rectangular" sx={{ marginTop: "10px" }} height={60} animation="wave" />
            </Grid>
        ))}
    </Grid>
);
