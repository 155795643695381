//src\app\pages\4-Campaigns\1-CampaignStatistics\Charts\UpcomingMessagesChart\TurboNode\TurboNode.tsx
import React, { memo, ReactNode, useState, useEffect, useRef } from 'react';
import { Handle, NodeProps, Position } from 'reactflow';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export type TurboNodeData = {
    title: string;
    icon?: ReactNode;
    subline?: string;
    date?: string;
    sendingTime?: string;
};

const TurboNode = ({ data }: NodeProps<TurboNodeData>) => {
    const theme = useTheme();
    const [selected, setSelected] = useState(false);
    const [nodeHeight, setNodeHeight] = useState(0);
    const nodeRef = useRef<HTMLDivElement>(null);

    const handleClick = (event: React.MouseEvent) => {
        event.stopPropagation();
        setSelected(!selected);
    };

    useEffect(() => {
        if (nodeRef.current) {
            setNodeHeight(nodeRef.current.offsetHeight);
        }
    }, [data]);

    const isDarkMode = theme.palette.mode === 'dark';

    return (
        <Box sx={{ position: 'relative', cursor: 'pointer' }}>
            <Box
                sx={{
                    borderRadius: '100%',
                    width: '30px',
                    height: '30px',
                    right: 0,
                    position: 'absolute',
                    top: 0,
                    transform: 'translate(50%, -50%)',
                    display: 'flex',
                    padding: '2px',
                    overflow: 'hidden',
                    boxShadow: isDarkMode
                        ? `10px 0 15px ${theme.palette.primary.main}30, -10px 0 15px ${theme.palette.secondary.main}30`
                        : 'none',
                    zIndex: 1,
                    background: 'conic-gradient(from -160deg at 50% 50%, #F79010 0deg, #a853ba 120deg, #a853ba 240deg, #F79010 360deg)',
                    '&:before': {
                        content: '""',
                        position: 'absolute',
                        paddingBottom: 'calc(100% * 1.41421356237)',
                        width: 'calc(100% * 1.41421356237)',
                        background: 'inherit',
                        left: '50%',
                        top: '50%',
                        transform: 'translate(-50%, -50%)',
                        borderRadius: '100%',
                    },
                }}
            >
                <Box
                    sx={{
                        backgroundColor: theme.palette.background.default,
                        flexGrow: 1,
                        borderRadius: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        position: 'relative',
                    }}
                >
                    {data.icon}
                </Box>
            </Box>
            <Box
                ref={nodeRef}
                sx={{
                    overflow: 'hidden',
                    display: 'flex',
                    padding: '2px',
                    position: 'relative',
                    borderRadius: '10px',
                    flexGrow: 1,
                    background: 'conic-gradient(from -160deg at 50% 50%, #F79010 0deg, #a853ba 120deg, #8ADC75 240deg, #F79010 360deg)',
                }}
                onClick={handleClick}
            >
                <Box
                    sx={{
                        background: theme.palette.background.paper,
                        padding: '16px 20px',
                        borderRadius: '10px',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        flexGrow: 1,
                        position: 'relative',
                        minHeight: nodeHeight,
                    }}
                >
                    <Box>
                        <Typography sx={{ fontSize: '16px', marginBottom: '2px', lineHeight: 1, color: theme.palette.text.primary }}>
                            {data.title}
                        </Typography>
                        {data.subline && <Typography sx={{ fontSize: '12px', color: theme.palette.text.secondary }}>{data.subline}</Typography>}
                        {data.date && <Typography sx={{ fontSize: '12px', color: theme.palette.text.secondary }}>{data.date}</Typography>}
                        {data.sendingTime && <Typography sx={{ fontSize: '12px', color: theme.palette.text.secondary }}>{data.sendingTime}</Typography>}
                    </Box>
                    <Handle type="target" position={Position.Top} style={{ zIndex: 1002 }} />
                    <Handle type="source" position={Position.Bottom} style={{ zIndex: 1002 }} />
                </Box>
            </Box>
        </Box>
    );
};

export default memo(TurboNode);
