import DraggableDialog from '@/src/app/modules/CustomMaterialUiComponents/DraggableDialog';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineSharp from '@mui/icons-material/RemoveCircleOutlineSharp';
import { Button, DialogActions, DialogContent, DialogTitle, IconButton, MenuItem, Select, TextField } from '@mui/material';
import axios from 'axios';
import React, { useState } from 'react';


// src\app\pages\3-CRM\CreateEmptyList.tsx
interface IHeader {
    name: string;
    type: string;
}

const headerTypes = [
    "custom", "email", "phone", "timezone", "companyname", "fixedcompanyname",
    "fullname", "firstname", "lastname", "location", "link", "number", "paragraph",
    "jobtitle", "linkedinurl", "icebreaker"
];

const CreateEmptyListModal = ({ open, onClose }) => {
    const [listName, setListName] = useState('');
    const [headers, setHeaders] = useState<IHeader[]>([]);

    const handleAddHeader = () => {
        setHeaders([...headers, { name: '', type: 'custom' }]);
    };

    const handleHeaderChange = (index: number, field: keyof IHeader, value: string) => {
        const updatedHeaders = headers.map((header, i) => {
            if (i === index) {
                return { ...header, [field]: value };
            }
            return header;
        });
        setHeaders(updatedHeaders);
    };

    const handleRemoveHeader = (index: number) => {
        const updatedHeaders = headers.filter((_, i) => i !== index);
        setHeaders(updatedHeaders);
    };

    const handleSubmit = async () => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/leads/lists/create-empty-list`, {
                name: listName,
                headers
            });
            console.log('List created:', response.data);
            onClose();
        } catch (error) {
            console.error('Error creating list:', error);
        }
    };

    return (
        <DraggableDialog
            open={open}
            onClose={onClose}
            title="Create New List"
            content={
                <>
                    <DialogTitle>Create New List</DialogTitle>
                    <DialogContent>
                        <TextField
                            fullWidth
                            label="List Name"
                            value={listName}
                            onChange={(e) => setListName(e.target.value)}
                            margin="dense"
                        />
                        {headers.map((header, index) => (
                            <div key={index}>
                                <TextField
                                    label="Header Name"
                                    value={header.name}
                                    onChange={(e) => handleHeaderChange(index, 'name', e.target.value)}
                                    style={{ marginRight: 8 }}
                                />


                                <Select
                                    value={header.type}
                                    onChange={(e) => handleHeaderChange(index, 'type', e.target.value)}
                                    label="Type"
                                    style={{ marginRight: 8 }}

                                    MenuProps={{
                                        style: { zIndex: 999999999999999 }
                                    }}
                                >
                                    {headerTypes.map((type) => (
                                        <MenuItem key={type} value={type}>{type}</MenuItem>
                                    ))}
                                </Select>

                                <IconButton onClick={() => handleRemoveHeader(index)}>
                                    <RemoveCircleOutlineSharp />
                                </IconButton>
                            </div>
                        ))}
                        <Button startIcon={<AddCircleOutlineIcon />} onClick={handleAddHeader}>
                            Add Header
                        </Button>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={onClose}>Cancel</Button>
                        <Button onClick={handleSubmit} color="primary">Create</Button>
                    </DialogActions>
                </>
            }
        />
    );
};

export default CreateEmptyListModal;
