// EmailAccountDetails.tsx
import CustomMuiDivider from '@/src/app/modules/CustomMaterialUiComponents/CustomMuiDivider';
import CustomTooltip from '@/src/app/modules/CustomMaterialUiComponents/CustomTooltip';
import {
    AddLinkSharp,
    CampaignSharp,
    CheckCircleSharp,
    InfoSharp,
    LinkOffSharp,
    PauseCircleFilledSharp,
} from '@mui/icons-material';
import CableSharpIcon from '@mui/icons-material/CableSharp';
import MailSharpIcon from '@mui/icons-material/MailSharp';
import {
    Box,
    Button,
    Card,
    CardContent,
    Grid,
    InputAdornment,
    LinearProgress,
    Paper,
    Snackbar,
    Switch,
    TextField,
    Tooltip,
    Typography,
} from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react';

import { Account } from './AccountInterface';
import DeliverabilityStatusDkimSpfDmarc from './DeliverabilityStatusDkimSpfDmarc';

interface EmailAccountDetailsProps {
    account: Account | null;
    refreshAccountsList: () => void;
    toggleLinkTrackingDialog: any;
}

interface Campaign {
    campaignId: string;
    campaignName: string;
    dailyLimit: number;
    status: 'active' | 'paused';
    editableDailyLimit: number; // Added this line
}



const EmailAccountDetails: React.FC<EmailAccountDetailsProps> = ({ account, refreshAccountsList, toggleLinkTrackingDialog }) => {



    const [showConfigurations, setShowConfigurations] = useState(false);
    const [editedFirstName, setEditedFirstName] = useState('');
    const [editedLastName, setEditedLastName] = useState('');


    const [enableFields, setEnableFields] = useState(false);

    const [settingsUpdated, setSettingsUpdated] = useState(false);


    const [campaigns, setCampaigns] = useState<Campaign[]>([]);

    const [dailyWarmupAllowed, setDailyWarmupAllowed] = useState('');
    const [dailyCampaignAllowed, setDailyCampaignAllowed] = useState('');

    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');

    const [isLoading, setIsLoading] = useState(false);

    const totalAllocated = Number(dailyWarmupAllowed) + Number(dailyCampaignAllowed);
    const progress = (totalAllocated / 100) * 100; // Since 100 is the max limit

    const maxCampaignLimit = Number(dailyCampaignAllowed); // Maximum limit allowed for campaigns
    const totalCampaignLimits = campaigns.reduce((acc, campaign) => acc + campaign.editableDailyLimit, 0); // Sum of all editableDailyLimits
    const campaignProgress = (totalCampaignLimits / maxCampaignLimit) * 100; // Calculate progress
    const isOverLimit = totalCampaignLimits > maxCampaignLimit; // Check if over limit


    const [smtpConfig, setSmtpConfig] = useState({
        host: '',
        port: '',
        password: '',
        isEnabled: false,
    });
    const [imapConfig, setImapConfig] = useState({
        host: '',
        port: '',
        password: '',
        tls: false,
        isEnabled: false,
    });

    useEffect(() => {
        // Reset state when account changes
        setEditedFirstName(account?.firstName || '');
        setEditedLastName(account?.lastName || '');
        setSmtpConfig({
            host: account?.smtp?.host || '',
            port: String(account?.smtp?.port) || '',  // Explicitly convert to string
            password: account?.smtp?.password || '',
            isEnabled: false,
        });
        setImapConfig({
            host: account?.imap?.host || '',
            port: String(account?.imap?.port) || '',  // Explicitly convert to string
            password: account?.imap?.password || '',
            tls: account?.imap?.tls || false,
            isEnabled: false,
        });
        if (account) {
            setDailyWarmupAllowed(String(account.usage.dailyLimits.dailyWarmupAllowed));
            setDailyCampaignAllowed(String(account.usage.dailyLimits.dailyCampaignAllowed));
        }
    }, [account]);

    useEffect(() => {
        const fetchCampaigns = async () => {
            setCampaigns([]); // Clear campaigns if account is not selected
            if (account && account._id) { // Check if account and account._id are not null
                try {
                    const response = await axios.get(`${process.env.REACT_APP_API_URL}/campaign/emailAccounts/${account._id}`);
                    const campaignsWithEditableLimit = response.data.map(campaign => ({
                        ...campaign,
                        editableDailyLimit: campaign.dailyLimit, // Initialize editableDailyLimit with the current dailyLimit
                    }));
                    setCampaigns(campaignsWithEditableLimit);
                } catch (error) {
                    console.error('Error fetching campaigns:', error);
                    setCampaigns([]); // Clear campaigns on error
                }
            } else {
                setCampaigns([]); // Clear campaigns if account is not selected
            }
        };

        fetchCampaigns();
    }, [account]); // Re-run the effect when the selected account changes


    const redistributeLimits = async () => {
        if (!account || isOverLimit) {
            // Handle error or prevent execution if account is null or limits are over
            setSnackbarMessage("Cannot redistribute limits. Please check the allocation.");
            setSnackbarOpen(true);
            return;
        }

        try {
            // Prepare the adjustments array from the campaigns state
            const adjustments = campaigns.map(campaign => ({
                campaignId: campaign.campaignId,
                dailyLimit: campaign.editableDailyLimit,
            }));

            // Send the adjustments to the backend
            await axios.post(`${process.env.REACT_APP_API_URL}/campaign/redistribute-limits`, {
                emailAccountId: account?._id,
                adjustments,
            });

            // Show success message
            setSnackbarMessage("Campaign limits redistributed successfully!");
            setSnackbarOpen(true);

            // Optionally, refresh campaign data to reflect the updates
            // This can be done by calling the fetchCampaigns function if it's extracted from the useEffect or by setting a key state to force re-fetching
        } catch (error: any) {
            // Handle errors
            const errorMessage = error.response?.data?.message || "Failed to redistribute campaign limits.";
            setSnackbarMessage(errorMessage);
            setSnackbarOpen(true);
        }
    };


    const handleCampaignLimitChange = (campaignId, newLimit) => {
        const numericalLimit = Number(newLimit);
        if (numericalLimit < 0) return; // Prevent negative values

        setCampaigns(current =>
            current.map(campaign =>
                campaign.campaignId === campaignId ? { ...campaign, editableDailyLimit: Number(newLimit) } : campaign
            )
        );
    };



    const handleSaveChanges = () => {
        // Add logic to save changes, e.g., update the state or make an API call
        // You can use editedFirstName, editedLastName, smtpConfig, and imapConfig values.

        // Dummy function for now
        // Replace this with your actual logic to save changes
        console.log("Settings saved");

        // Set settingsUpdated to true
        setSettingsUpdated(true);
    };


    // const handleEnableFieldsToggle = () => {
    //     setEnableFields((prevValue) => {
    //         return !prevValue;
    //     });
    // };
    const updateDailyLimits = async () => {
        setIsLoading(true);
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/account/emailaccounts/update-daily-limits`, {
                emailAccountId: account?._id,
                dailyWarmupAllowed,
                dailyCampaignAllowed,
            });
            setSnackbarMessage(response.data.message || 'Daily limits updated successfully!');
            setSnackbarOpen(true);
            refreshAccountsList();
        } catch (error: any) {
            const errorMessage = error.response?.data?.message || 'Failed to update daily limits.';
            setSnackbarMessage(errorMessage);
            setSnackbarOpen(true);
        } finally {
            setIsLoading(false); // End loading
        }
    };



    if (!account) {
        return (
            <Grid
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: '20px',
                    cursor: 'pointer',
                    height: 'auto',
                    marginTop: "30px"
                }}

            // onClick={async () => {
            //     if (steps.length === 0) {
            //         await addStep(null);
            //         if (steps.length > 0) {
            //             selectStepById(steps[0]._id);
            //         }
            //     } else {
            //         selectStepById(steps[0]._id);
            //     }
            // }}
            >
                <MailSharpIcon
                    color='secondary'
                    sx={{
                        fontSize: "340px",
                        padding: "15px",
                        borderRadius: "0%",
                        transition: "1s",
                        "&:hover": {
                            fontSize: "320px",
                            opacity: 0.7
                        }
                    }}

                />

                <Typography variant="body1" sx={{ textAlign: 'center', marginTop: 1, color: "#793A99" }}>
                    Here you will see your connected email accounts and phone numbers.
                    This page allows you to manage all your connections and their settings.
                </Typography>

            </Grid>
        );
    }


    const NoCampaignsPlaceholder = () => (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 4 }}>
            <CampaignSharp color='secondary' sx={{ fontSize: 60, }} />
            <Typography variant="subtitle1" sx={{ color: "#793A99", marginTop: 2 }}>
                This account isn't associated with any campaigns yet.
            </Typography>
        </Box>
    );


    const ProgressBar = {
        progress: {
            height: '30px',
            width: '100%'
        },
    };



    return (
        <Paper elevation={0} style={{ padding: '20px', marginBottom: '20px', position: 'relative' }}>
            <div>
                {/* <Typography variant="h4" gutterBottom>
                    Account Details
                </Typography>

                <Grid item xs={6}>
                    <TextField
                        label="Email"
                        variant="outlined"
                        color='secondary'
                        value={account?.sendingAccount}
                        onChange={(e) => setEditedFirstName(e.target.value)}
                        fullWidth // Take up the entire width of the Grid item
                        disabled
                    />
                </Grid> */}

                {/* First & Last Name */}
                <Card elevation={0} style={{ marginTop: '20px' }}>
                    <CardContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <TextField
                                            label="First Name"
                                            variant="standard"
                                            color='secondary'
                                            value={editedFirstName}
                                            onChange={(e) => setEditedFirstName(e.target.value)}
                                            fullWidth // Take up the entire width of the Grid item
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            label="Last Name"
                                            variant="standard"
                                            color='secondary'
                                            value={editedLastName}
                                            onChange={(e) => setEditedLastName(e.target.value)}
                                            fullWidth // Take up the entire width of the Grid item
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid container spacing={2} alignItems="center" style={{ marginTop: '12px' }}>
                                <Grid item xs={12} sm={6}>
                                    {account?.trackingDomain ? (
                                        <TextField
                                            label="Tracking Domain"
                                            variant="standard"
                                            color='secondary'
                                            value={account.trackingDomain}
                                            disabled
                                            fullWidth
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <AddLinkSharp color="info" />
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    ) : (
                                        <Button onClick={toggleLinkTrackingDialog}   >
                                            <Box display="flex" alignItems="center">
                                                <LinkOffSharp color="secondary" />
                                                <Typography variant="body1" sx={{ marginLeft: 1 }}>
                                                    Tracking domain not connected.
                                                </Typography>
                                            </Box>
                                        </Button >

                                    )}
                                </Grid>
                            </Grid>


                            {/* <Grid item xs={12} sm={6} container justifyContent="flex-end" alignItems="center">
                                <IconButton color="primary" onClick={handleSaveChanges}>
                                    <SaveAsSharpIcon color='info' sx={{ fontSize: "35px" }} />
                                </IconButton>
                            </Grid> */}
                        </Grid>
                    </CardContent>
                </Card>


                <DeliverabilityStatusDkimSpfDmarc emailAccountId={account?._id} />



                {/* Connection Details */}
                <Card elevation={0} style={{ marginTop: '20px' }}>
                    <CardContent>
                        <Typography variant="h5" gutterBottom>
                            Connection Details
                        </Typography>
                        <Button variant="text" onClick={() => setShowConfigurations(!showConfigurations)}>
                            {showConfigurations ? <CableSharpIcon color='secondary'
                                sx={{ fontSize: "40px" }}
                            /> : <CableSharpIcon
                                color='info'
                                sx={{ fontSize: "40px" }} />}
                        </Button>
                        {/* 
                        {showConfigurations && (

                            <Grid container justifyContent="flex-end">

                                <IconButton
                                    color="primary"
                                    onClick={handleEnableFieldsToggle}
                                >
                                    <Edit color='info' />
                                </IconButton>
                            </Grid>
                        )} */}

                        {showConfigurations && (



                            <Grid container spacing={2}>


                                {/* SMTP Configuration */}

                                <Grid item xs={12} sm={6}>

                                    <CardContent>
                                        <Typography variant="h5" gutterBottom style={{ position: 'relative' }}>
                                            SMTP
                                            <Tooltip sx={{
                                                fontSize: '40px',
                                                '& .MuiTooltip-tooltip': {
                                                    backgroundColor: '#622F7D', // Change 'secondary' to the desired color
                                                },
                                            }}
                                                title="For Sending Emails"
                                                arrow
                                            >
                                                <InfoSharp
                                                    style={{
                                                        fontSize: '20px',
                                                        marginLeft: '4px',
                                                        cursor: 'pointer',
                                                        position: 'absolute',
                                                        top: '50%',
                                                        transform: 'translateY(-50%)',
                                                    }}
                                                    color='info'
                                                />
                                            </Tooltip>
                                        </Typography>


                                        <Grid container spacing={2}>
                                            <Grid item xs={12} sm={6}>
                                                <TextField
                                                    label="Host"
                                                    color='info'

                                                    variant="outlined"
                                                    value={smtpConfig.host}
                                                    onChange={(e) => setSmtpConfig({ ...smtpConfig, host: e.target.value })}
                                                    disabled={!enableFields}
                                                    style={{ marginTop: '12px' }}
                                                />
                                                <TextField
                                                    label="Port"
                                                    color='info'

                                                    variant="outlined"
                                                    value={smtpConfig.port}
                                                    onChange={(e) => setSmtpConfig({ ...smtpConfig, port: e.target.value })}
                                                    disabled={!enableFields}
                                                    style={{ marginTop: '12px' }}
                                                />
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Grid>

                                {/* IMAP Configuration */}
                                <Grid item xs={12} sm={6}>
                                    <CardContent>
                                        <Typography variant="h5" gutterBottom style={{ position: 'relative' }}>
                                            IMAP
                                            <CustomTooltip

                                                title="For Receiving Emails"
                                            >
                                                <InfoSharp
                                                    style={{
                                                        fontSize: '20px',
                                                        marginLeft: '4px',
                                                        cursor: 'pointer',
                                                        position: 'absolute',
                                                        top: '50%',
                                                        transform: 'translateY(-50%)',
                                                    }}
                                                    color='info'
                                                />
                                            </CustomTooltip>
                                        </Typography>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} sm={6}>
                                                <TextField
                                                    label="Host"
                                                    variant="outlined"
                                                    value={imapConfig.host}
                                                    onChange={(e) => setImapConfig({ ...imapConfig, host: e.target.value })}
                                                    disabled={!enableFields}
                                                    style={{ marginTop: '12px' }}
                                                    color='info'


                                                />
                                                <TextField
                                                    label="Port"
                                                    variant="outlined"
                                                    value={imapConfig.port}
                                                    onChange={(e) => setImapConfig({ ...imapConfig, port: e.target.value })}
                                                    disabled={!enableFields}
                                                    style={{ marginTop: '12px' }}
                                                    color='info'

                                                />

                                                <Grid
                                                    style={{ marginTop: '12px' }}
                                                    item
                                                    xs={12}
                                                    sm={6}
                                                >
                                                    <Typography variant="body1">
                                                        <strong>TLS/SSL:</strong>
                                                    </Typography>
                                                    <Switch
                                                        color='secondary'

                                                        checked={imapConfig.tls}
                                                        onChange={() => setImapConfig({ ...imapConfig, tls: !imapConfig.tls })}
                                                        disabled={!enableFields}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Grid>
                            </Grid>

                        )}

                        {/* 
                        {showConfigurations && (
                            <IconButton
                                color="primary"
                                onClick={() => {
                                    // Dummy function for now
                                    console.log("Settings updated");

                                    // Set settingsUpdated to false after updating settings
                                    setSettingsUpdated(false);
                                }}
                            >
                                <SaveAsSharpIcon color='info' sx={{ fontSize: "35px" }} />
                            </IconButton>
                        )} */}
                    </CardContent>

                    <CustomMuiDivider thickness={3} color='#793A99' />

                    <Typography variant="body1" gutterBottom>
                        Total Allocated Limit: {totalAllocated}/100
                    </Typography>
                    <LinearProgress variant="determinate" value={progress} sx={ProgressBar.progress} />

                    <Grid container spacing={2} style={{ marginTop: '12px' }}>
                        <Grid item xs={6}>
                            <TextField
                                label="Daily Warmup Allowed"
                                variant="outlined"
                                value={dailyWarmupAllowed}
                                onChange={(e) => setDailyWarmupAllowed(e.target.value)}
                                fullWidth
                                type="number"
                                InputProps={{ inputProps: { min: 0 } }} // Optional: Ensures no negative numbers
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                label="Daily Campaign Allowed"
                                variant="outlined"
                                value={dailyCampaignAllowed}
                                onChange={(e) => setDailyCampaignAllowed(e.target.value)}
                                fullWidth
                                type="number"
                                InputProps={{ inputProps: { min: 0 } }} // Optional: Ensures no negative numbers
                            />

                        </Grid>
                    </Grid>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={updateDailyLimits}
                        style={{ marginTop: '12px' }}
                        fullWidth
                    >
                        Save Daily Limits
                    </Button>

                    <Snackbar
                        open={snackbarOpen}
                        autoHideDuration={6000}
                        onClose={() => setSnackbarOpen(false)}
                        message={snackbarMessage}
                    />


                </Card>
                <CustomMuiDivider thickness={3} color='#793A99' />

                {campaigns.length > 0 && (
                    <Typography variant="h6" gutterBottom style={{ marginTop: '20px' }}>
                        Connected Campaigns
                    </Typography>
                )}

                {campaigns.length > 0 ? (

                    <>

                        <Grid container spacing={2}>



                            {campaigns.map((campaign) => (

                                <Grid item xs={12} sm={6} key={campaign.campaignId}>

                                    <Card variant="outlined">
                                        <CardContent>
                                            <Typography variant="h6">{campaign.campaignName}</Typography>
                                            <TextField
                                                label="Daily Limit"
                                                type="number"
                                                value={campaign.editableDailyLimit}
                                                onChange={(e) => handleCampaignLimitChange(campaign.campaignId, e.target.value)}
                                                InputProps={{ inputProps: { min: 0 } }} // Prevent negative values
                                            />
                                            <Typography variant="body2" style={{ display: 'flex', alignItems: 'center', color: campaign.status === 'active' ? '#90EE90' : '#F79010' }}>
                                                Status: {campaign.status === 'active' ? <CheckCircleSharp fontSize="small" /> : <PauseCircleFilledSharp fontSize="small" />}
                                                {campaign.status.charAt(0).toUpperCase() + campaign.status.slice(1)}
                                            </Typography>

                                        </CardContent>
                                    </Card>
                                </Grid>

                            ))}

                            <Grid item xs={12} sm={12} >



                                <Typography variant="body2" gutterBottom>
                                    Total Campaign Limits: {totalCampaignLimits}/{maxCampaignLimit}
                                </Typography>
                                <LinearProgress variant="determinate" value={isOverLimit ? 100 : campaignProgress} color={isOverLimit ? "secondary" : "primary"} sx={ProgressBar.progress} />
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => {
                                        if (isOverLimit) {
                                            setSnackbarMessage("Total campaign limits exceed the maximum allowed. Please adjust.");
                                            setSnackbarOpen(true);
                                        } else {
                                            redistributeLimits(); // Only call if not over limit
                                        }
                                    }}
                                    style={{ marginTop: '12px' }}
                                    fullWidth
                                >
                                    Save Campaign Limits
                                </Button>
                            </Grid>

                        </Grid>
                    </>
                ) : !isLoading && (
                    <NoCampaignsPlaceholder />
                )}

            </div>

        </Paper>

    );
};

export default EmailAccountDetails;