import { Email, Settings } from '@mui/icons-material';
import {
    Button,
    Container,
    Divider,
    Grid,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    TextField,
    Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { FaRegEdit, FaRegEnvelope } from 'react-icons/fa';


// src\app\pages\0-Account\EmailAccounts\Guides\Guide-SMTP-IMAP.tsx
// Define the functional component


const EmailSetupSMTPIMAPGuide = () => {

    const [providerName, setProviderName] = useState('');

    // Function to handle changes in the text field
    const handleProviderNameChange = (event) => {
        setProviderName(event.target.value);
    };

    // Function to handle search button click
    const handleSearch = () => {
        // Direct the user to Google search for SMTP and IMAP settings for the provider
        window.open(`https://www.google.com/search?q=${providerName}+SMTP+&+IMAP+settings`, '_blank');
    };


    return (
        <Container maxWidth="md">
            <Typography variant="h4" align="center" gutterBottom>
                Connect Your Email
            </Typography>
            <Grid container spacing={3}>
                <Grid item >
                    <Typography variant="h6" gutterBottom>
                        What you'll need to connect your email:
                    </Typography>
                    <List>
                        <ListItem>
                            <ListItemIcon>
                                <Email />
                            </ListItemIcon>
                            <ListItemText primary="Email Address and Password" secondary="Your email login credentials." />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <Settings />
                            </ListItemIcon>
                            <ListItemText primary="SMTP and IMAP Host Names and Ports" secondary="These depend on your email provider." />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <FaRegEdit />
                            </ListItemIcon>
                            <ListItemText primary="SMTP and IMAP Settings" secondary="See common settings for popular providers below." />
                        </ListItem>
                    </List>
                    <Divider />
                    <Divider />
                    <Divider />
                    <Divider />
                    <Divider />
                    <Divider />
                    <Divider />

                    <Typography variant="h6" gutterBottom>
                        Common Settings:
                    </Typography>
                    <List>
                        <ListItem>
                            <ListItemText primary="Gmail:" />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <FaRegEnvelope />
                            </ListItemIcon>
                            <ListItemText primary="SMTP Host: smtp.gmail.com" secondary="SMTP Port: 465 or 587 (SSL/TLS)" />
                        </ListItem>


                        <ListItem>
                            <ListItemIcon>
                                <Email />
                            </ListItemIcon>
                            <ListItemText primary="IMAP Host: imap.gmail.com" secondary="IMAP Port: 993 (SSL/TLS)" />
                        </ListItem>
                        <Divider />
                        <Divider />
                        <Divider />
                        <Divider />
                        <Divider />
                        <Divider />
                        <Divider />
                        <ListItem>
                            <ListItemIcon>
                                {/* Icon for Outlook */}
                                <Email />
                            </ListItemIcon>
                            <ListItemText primary="Outlook:" />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                {/* Icon for Outlook */}
                                <Settings />
                            </ListItemIcon>
                            <ListItemText primary="SMTP Host: smtp-mail.outlook.com" secondary="SMTP Port: 587 (StartTLS)" />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                {/* Icon for Outlook */}
                                <FaRegEnvelope />
                            </ListItemIcon>
                            <ListItemText primary="IMAP Host: imap-mail.outlook.com" secondary="IMAP Port: 993 (SSL/TLS)" />
                        </ListItem>
                        <Divider />
                        <Divider />
                        <Divider />
                        <Divider />
                        <Divider />
                        <Divider />
                        <Divider />
                        {/* Add list items for other email providers */}
                        <ListItem>
                            <ListItemIcon>
                                {/* Icon for Zoho Mail */}
                                <Email />
                            </ListItemIcon>
                            <ListItemText primary="Zoho Mail:" />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                {/* Icon for Zoho Mail */}
                                <Settings />
                            </ListItemIcon>
                            <ListItemText primary="SMTP Host: smtp.zoho.com" secondary="SMTP Port: 465 (SSL/TLS)" />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                {/* Icon for Zoho Mail */}
                                <FaRegEnvelope />
                            </ListItemIcon>
                            <ListItemText primary="IMAP Host: imap.zoho.com" secondary="IMAP Port: 993 (SSL/TLS)" />
                        </ListItem>
                        <Divider />
                        <Divider />
                        <Divider />
                        <Divider />
                        <Divider />
                        <Divider />
                        <Divider />
                        <ListItem>
                            <ListItemIcon>
                                {/* Icon for Hostinger */}
                                <Email />
                            </ListItemIcon>
                            <ListItemText primary="Hostinger:" />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                {/* Icon for Hostinger */}
                                <Settings />
                            </ListItemIcon>
                            <ListItemText primary="SMTP Host: smtp.hostinger.com" secondary="SMTP Port: 587 (StartTLS)" />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                {/* Icon for Hostinger */}
                                <FaRegEnvelope />
                            </ListItemIcon>
                            <ListItemText primary="IMAP Host: imap.hostinger.com" secondary="IMAP Port: 993 (SSL/TLS)" />
                        </ListItem>
                        <Divider />
                        <Divider />
                        <Divider />
                        <Divider />
                        <Divider />
                        <Divider />
                        <Divider />
                        <ListItem>
                            <ListItemIcon>
                                {/* Icon for Microsoft 365 */}
                                <Email />
                            </ListItemIcon>
                            <ListItemText primary="Microsoft 365:" />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                {/* Icon for Microsoft 365 */}
                                <Settings />
                            </ListItemIcon>
                            <ListItemText primary="SMTP Host: smtp.office365.com" secondary="SMTP Port: 587 (StartTLS)" />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                {/* Icon for Microsoft 365 */}
                                <FaRegEnvelope />
                            </ListItemIcon>
                            <ListItemText primary="IMAP Host: outlook.office365.com" secondary="IMAP Port: 993 (SSL/TLS)" />
                        </ListItem>

                        {/* Additional email providers */}
                        {/* Insert list items similar to Gmail for other providers */}
                        <Divider />
                        <Divider />
                        <Divider />
                        <Divider />
                        <Divider />
                        <Divider />
                        <Divider />
                        <Grid item>
                            <Typography variant="h6" gutterBottom>
                                Help me find my own:
                            </Typography>
                            {/* Text field for entering the provider's name */}
                            <TextField
                                label="My provider is"
                                variant="outlined"
                                fullWidth
                                margin="normal"
                                value={providerName}
                                onChange={handleProviderNameChange}
                            />

                            {/* Button to search SMTP and IMAP settings */}
                            <Button
                                variant="contained"
                                onClick={handleSearch}
                                fullWidth
                                disabled={!providerName} // Disable the button if the text field is empty
                                sx={{ mt: 1 }}
                            >
                                Search SMTP/IMAP Settings
                            </Button>
                        </Grid>


                    </List>



                </Grid>
            </Grid>
        </Container>
    );
};

export default EmailSetupSMTPIMAPGuide;
