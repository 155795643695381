import React, { useState, useEffect } from 'react';
import { Button, DialogActions, DialogContent, DialogTitle, Paper, TextField, Typography, Select, MenuItem, Chip, InputLabel, FormControl, SelectChangeEvent } from '@mui/material';
import DraggableDialog from '@/src/app/modules/CustomMaterialUiComponents/DraggableDialog';
import axios from 'axios';

interface Header {
    fieldId: string;
    name: string;
    type: string;
    personalization?: string;
}

const IcebreakerModal = ({ open, onClose, selectedLeadIds, selectedListId }) => {
    const [fieldIds, setFieldIds] = useState<string[]>([]);
    const [additionalInstructions, setAdditionalInstructions] = useState("");
    const [headers, setHeaders] = useState<Header[]>([]);
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [delay, setDelay] = useState<number>(0); // Added delay state

    useEffect(() => {
        if (open && selectedListId) {
            axios.get(`${process.env.REACT_APP_API_URL}/leads/lists/info/${selectedListId}`)
                .then(response => {
                    setHeaders(response.data.headers);
                })
                .catch(error => {
                    console.error('Failed to fetch list headers:', error);
                });
        }
    }, [open, selectedListId]);

    const handleInstructionsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setAdditionalInstructions(event.target.value);
    };

    const handleDelayChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDelay(parseInt(event.target.value, 10)); // Convert to integer
    };


    const handleGenerateIcebreakers = async () => {
        if (selectedLeadIds.length === 0) {
            alert('No leads selected');
            return;
        }

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/leads/generate-icebreaker`, {
                leadIds: selectedLeadIds,
                fieldIds: fieldIds,
                additionalInstructions: additionalInstructions,
                listId: selectedListId,
                delay: delay, // Include delay in request

            });
            console.log('Icebreakers generated:', response.data);
            onClose();  // Close the modal after successful request
        } catch (error) {
            console.error('Failed to generate icebreakers:', error);
            alert('Failed to generate icebreakers. Please try again.');
        }
    };

    const handleFieldChange = (event: SelectChangeEvent<string[]>) => {
        const {
            target: { value },
        } = event;
        setFieldIds(typeof value === 'string' ? value.split(',') : value);
    };

    const filteredHeaders = headers.filter(header =>
        header.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <DraggableDialog
            open={open}
            onClose={onClose}
            title={`Generate Icebreakers - ${selectedLeadIds.length} Leads Selected`}
            enableExternalWindow={false}
            content={
                <>
                    <DialogTitle>Generate Icebreakers</DialogTitle>
                    <DialogContent>
                        <Paper elevation={3} style={{ margin: '10px', padding: '15px', textAlign: 'center' }}>
                            <Typography variant="h6" component="p">
                                {selectedLeadIds.length} Leads Selected
                            </Typography>
                        </Paper>
                        <FormControl fullWidth margin="normal">
                            <InputLabel id="select-fields-label">Select Fields</InputLabel>
                            <Select
                                labelId="select-fields-label"
                                id="select-fields"
                                multiple
                                value={fieldIds}
                                onChange={handleFieldChange}
                                renderValue={(selected) => (
                                    <div>
                                        {(selected as string[]).map((value) => (
                                            <Chip key={value} label={headers.find(header => header.fieldId === value)?.name} />
                                        ))}
                                    </div>
                                )}
                                MenuProps={{
                                    style: { zIndex: 999999999999999 },
                                    PaperProps: {
                                        style: {
                                            maxHeight: 200,
                                            overflowY: 'auto',
                                        },
                                    },
                                }}
                            >
                                {/* <TextField
                                    fullWidth
                                    placeholder="Search..."
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                    value={searchTerm}
                                    autoFocus
                                    onKeyDown={(e) => {
                                        e.stopPropagation();
                                    }}
                                /> */}
                                {filteredHeaders.map((header) => (
                                    <MenuItem key={header.fieldId} value={header.fieldId}>
                                        {header.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <TextField
                            fullWidth
                            label="Additional Instructions"
                            value={additionalInstructions}
                            onChange={handleInstructionsChange}
                            variant="outlined"
                            margin="normal"
                            multiline
                            rows={4}
                        />
                        <TextField
                            fullWidth
                            label="Delay in Seconds"
                            type="number"
                            value={delay}
                            onChange={handleDelayChange}
                            variant="outlined"
                            margin="normal"
                        />

                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleGenerateIcebreakers} variant="contained" color="primary" disableElevation>
                            Generate
                        </Button>
                        <Button onClick={onClose} color="primary">
                            Cancel
                        </Button>
                    </DialogActions>
                </>
            }
        />
    );
};

export default IcebreakerModal;
