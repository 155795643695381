import React, { useState } from 'react';
import {
    Button,
    TextField,
    Paper,
    Fade,
    InputAdornment,
    Typography,
    useTheme,
    styled,
    Box,
    Card,
    Snackbar,
    Alert,
} from '@mui/material';
import { Theme } from '@mui/system';

import { ListSharp, AddSharp } from '@mui/icons-material';
import axios from 'axios';
const Container = styled(Card)(({ theme }: { theme: Theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: 450,
    width: '100%',
    padding: theme.spacing(6),
    boxShadow: '0 10px 40px rgba(0,0,0,0.1)',
}));

const Title = styled(Typography)(({ theme }: { theme: Theme }) => ({
    marginBottom: theme.spacing(2),
    position: 'relative',
    '&::after': {
        content: '""',
        position: 'absolute',
        bottom: -10,
        left: '50%',
        transform: 'translateX(-50%)',
        width: 50,
        height: 2,
        backgroundColor: theme.palette.primary.main,
    },
}));



const SubmitButton = styled(Button)(({ theme }: { theme: Theme }) => ({
    margin: theme.spacing(5, 0, 2),
    padding: theme.spacing(1.8),
    fontWeight: 500,
    fontSize: '1.1rem',
    letterSpacing: '0.03em',
    boxShadow: 'none',
    transition: 'all 0.3s ease-in-out',
    '&:hover': {
        boxShadow: `0 5px 15px ${theme.palette.primary.main}50`,
        transform: 'translateY(-2px)',
    },
}));

interface CreateNewListProps {
    selectedCampaignId: string | null;
    onListCreated: () => void; // Add a callback function prop
}

const CreateNewList: React.FC<CreateNewListProps> = ({ selectedCampaignId, onListCreated }) => {
    const theme = useTheme();
    const [listName, setListName] = useState<string>('');
    const [showSuccess, setShowSuccess] = useState(false); // State for success message
    const [showError, setShowError] = useState(false); // State for error message
    const [errorMessage, setErrorMessage] = useState(''); // State for error message content

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        console.log('Creating new list:', listName);

        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/campaigns/${selectedCampaignId}/create-empty-list`,
                { listName }
            );

            console.log('List created successfully:', response.data);
            onListCreated();
            setShowSuccess(true); // Show success Snackbar
        } catch (error: any) { // Type the error object
            console.error('Error creating list:', error);
            setErrorMessage(error.response?.data?.error || 'An error occurred.'); // Extract error message
            setShowError(true); // Show error Snackbar
        }
    };

    // Handle closing Snackbars
    const handleCloseSnackbar = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setShowSuccess(false);
        setShowError(false);
    };


    return (
        <Fade in={true} timeout={1000}>
            <Container>
                <Title variant="h1" fontSize={30}>
                    New Campaign List
                </Title>
                <form onSubmit={handleSubmit}>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="listName"
                        label="List Name"
                        name="listName"
                        autoFocus
                        value={listName}
                        onChange={(e) => setListName(e.target.value)}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <ListSharp style={{ color: theme.palette.primary.main }} />
                                </InputAdornment>
                            ),
                        }}
                    />
                    <SubmitButton
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        startIcon={<AddSharp />}
                    >
                        Create List
                    </SubmitButton>
                </form>

                {/* Success Snackbar */}
                <Snackbar open={showSuccess} autoHideDuration={6000} onClose={handleCloseSnackbar}>
                    <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
                        List created successfully!
                    </Alert>
                </Snackbar>
                {/* Error Snackbar */}
                <Snackbar open={showError} autoHideDuration={6000} onClose={handleCloseSnackbar}>
                    <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: '100%' }}>
                        {errorMessage}
                    </Alert>
                </Snackbar>

            </Container>
        </Fade>
    );
};

export default CreateNewList;
