import React from 'react';
import { styled } from '@mui/material/styles';
import { useTheme } from '@mui/material/styles';


const LoadingAnimation = styled('div')(({ theme }) => ({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '160px',
    height: '160px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
}));


const ModernLoader = styled('div')(({ theme }) => ({
    width: '100px',
    height: '100px',
    position: 'relative',
}));

const LoaderDot = styled('div')(({ theme }) => ({
    position: 'absolute',
    width: '20px',
    height: '20px',
    borderRadius: '50%',
    backgroundColor: '#793A99',
    animation: 'loader-animation 1.5s infinite ease-in-out',
    opacity: 0,
    '&:nth-child(2)': {
        top: '40px',
        right: 0,
        backgroundColor: '#F79010',
        animationDelay: '0.2s',
    },
    '&:nth-child(3)': {
        bottom: 0,
        left: '40px',
        backgroundColor: '#793A99',
        animationDelay: '0.4s',
    },
    '&:nth-child(4)': {
        top: '40px',
        left: 0,
        backgroundColor: '#F79010',
        animationDelay: '0.6s',
    },
    '@keyframes loader-animation': {
        '0%, 20%, 100%': {
            transform: 'scale(0)',
            opacity: 0,
        },
        '50%': {
            transform: 'scale(1)',
            opacity: 1,
        },
    },
}));



const LoadingEmailListPlaceholder = () => {

    return (
        <LoadingAnimation>
            <ModernLoader>
                <LoaderDot />
                <LoaderDot />
                <LoaderDot />
                <LoaderDot />
            </ModernLoader>
        </LoadingAnimation>
    );
};

export default LoadingEmailListPlaceholder;
