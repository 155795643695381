import React from 'react';
import { Box, Typography, Grid, Card, CardContent, CardHeader, Avatar, Link, List, ListItem, Paper, Divider } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import BusinessIcon from '@mui/icons-material/Business';

const PersonalComponent = ({ leadData }) => {
    const formatUrl = (url) => {
        try {
            return new URL(url).href;
        } catch {
            return url.startsWith('http') ? url : `http://${url}`;
        }
    };

    return (
        <Box>
            <Typography variant="h4" gutterBottom align="center" sx={{ fontWeight: 'bold', color: 'primary.main' }}>
                Personal Information
            </Typography>
            <List>
                <ListItem disableGutters>
                    <Card variant="outlined" sx={{ width: '100%', mb: 2 }}>
                        <CardHeader
                            avatar={
                                <Avatar>
                                    <AccountCircleIcon />
                                </Avatar>
                            }
                            title={leadData.full_name}
                            subheader={leadData.job_title}
                        />
                        <CardContent>
                            <Grid container spacing={2}>
                                {leadData.first_name && (
                                    <Grid item xs={12} sm={6}>
                                        <Paper variant="outlined" sx={{ p: 1 }}>
                                            <Typography variant="subtitle2"><strong>First Name:</strong> {leadData.first_name}</Typography>
                                        </Paper>
                                    </Grid>
                                )}
                                {leadData.middle_initial && (
                                    <Grid item xs={12} sm={6}>
                                        <Paper variant="outlined" sx={{ p: 1 }}>
                                            <Typography variant="subtitle2"><strong>Middle Initial:</strong> {leadData.middle_initial}</Typography>
                                        </Paper>
                                    </Grid>
                                )}
                                {leadData.middle_name && (
                                    <Grid item xs={12} sm={6}>
                                        <Paper variant="outlined" sx={{ p: 1 }}>
                                            <Typography variant="subtitle2"><strong>Middle Name:</strong> {leadData.middle_name}</Typography>
                                        </Paper>
                                    </Grid>
                                )}
                                {leadData.last_name && (
                                    <Grid item xs={12} sm={6}>
                                        <Paper variant="outlined" sx={{ p: 1 }}>
                                            <Typography variant="subtitle2"><strong>Last Name:</strong> {leadData.last_name}</Typography>
                                        </Paper>
                                    </Grid>
                                )}
                                {leadData.gender && (
                                    <Grid item xs={12} sm={6}>
                                        <Paper variant="outlined" sx={{ p: 1 }}>
                                            <Typography variant="subtitle2"><strong>Gender:</strong> {leadData.gender}</Typography>
                                        </Paper>
                                    </Grid>
                                )}
                                {leadData.birth_year && (
                                    <Grid item xs={12} sm={6}>
                                        <Paper variant="outlined" sx={{ p: 1 }}>
                                            <Typography variant="subtitle2"><strong>Birth Year:</strong> {leadData.birth_year}</Typography>
                                        </Paper>
                                    </Grid>
                                )}
                                {leadData.birth_date && (
                                    <Grid item xs={12} sm={6}>
                                        <Paper variant="outlined" sx={{ p: 1 }}>
                                            <Typography variant="subtitle2"><strong>Birth Date:</strong> {leadData.birth_date}</Typography>
                                        </Paper>
                                    </Grid>
                                )}
                                {leadData.industry && (
                                    <Grid item xs={12} sm={6}>
                                        <Paper variant="outlined" sx={{ p: 1 }}>
                                            <Typography variant="subtitle2"><strong>Industry:</strong> {leadData.industry}</Typography>
                                        </Paper>
                                    </Grid>
                                )}
                                {leadData.linkedin_url && (
                                    <Grid item xs={12} sm={6}>
                                        <Paper variant="outlined" sx={{ p: 1 }}>
                                            <Typography variant="subtitle2">
                                                <strong>LinkedIn:</strong> <Link href={formatUrl(leadData.linkedin_url)} target="_blank" rel="noopener noreferrer">{leadData.linkedin_url}</Link>
                                            </Typography>
                                        </Paper>
                                    </Grid>
                                )}
                                {leadData.linkedin_username && (
                                    <Grid item xs={12} sm={6}>
                                        <Paper variant="outlined" sx={{ p: 1 }}>
                                            <Typography variant="subtitle2"><strong>LinkedIn Username:</strong> {leadData.linkedin_username}</Typography>
                                        </Paper>
                                    </Grid>
                                )}
                                {leadData.linkedin_id && (
                                    <Grid item xs={12} sm={6}>
                                        <Paper variant="outlined" sx={{ p: 1 }}>
                                            <Typography variant="subtitle2"><strong>LinkedIn ID:</strong> {leadData.linkedin_id}</Typography>
                                        </Paper>
                                    </Grid>
                                )}
                                {leadData.facebook_url && (
                                    <Grid item xs={12} sm={6}>
                                        <Paper variant="outlined" sx={{ p: 1 }}>
                                            <Typography variant="subtitle2">
                                                <strong>Facebook:</strong> <Link href={formatUrl(leadData.facebook_url)} target="_blank" rel="noopener noreferrer">{leadData.facebook_url}</Link>
                                            </Typography>
                                        </Paper>
                                    </Grid>
                                )}
                                {leadData.facebook_username && (
                                    <Grid item xs={12} sm={6}>
                                        <Paper variant="outlined" sx={{ p: 1 }}>
                                            <Typography variant="subtitle2"><strong>Facebook Username:</strong> {leadData.facebook_username}</Typography>
                                        </Paper>
                                    </Grid>
                                )}
                                {leadData.facebook_id && (
                                    <Grid item xs={12} sm={6}>
                                        <Paper variant="outlined" sx={{ p: 1 }}>
                                            <Typography variant="subtitle2"><strong>Facebook ID:</strong> {leadData.facebook_id}</Typography>
                                        </Paper>
                                    </Grid>
                                )}
                                {leadData.twitter_url && (
                                    <Grid item xs={12} sm={6}>
                                        <Paper variant="outlined" sx={{ p: 1 }}>
                                            <Typography variant="subtitle2">
                                                <strong>Twitter:</strong> <Link href={formatUrl(leadData.twitter_url)} target="_blank" rel="noopener noreferrer">{leadData.twitter_url}</Link>
                                            </Typography>
                                        </Paper>
                                    </Grid>
                                )}
                                {leadData.twitter_username && (
                                    <Grid item xs={12} sm={6}>
                                        <Paper variant="outlined" sx={{ p: 1 }}>
                                            <Typography variant="subtitle2"><strong>Twitter Username:</strong> {leadData.twitter_username}</Typography>
                                        </Paper>
                                    </Grid>
                                )}
                                {leadData.github_url && (
                                    <Grid item xs={12} sm={6}>
                                        <Paper variant="outlined" sx={{ p: 1 }}>
                                            <Typography variant="subtitle2">
                                                <strong>GitHub:</strong> <Link href={formatUrl(leadData.github_url)} target="_blank" rel="noopener noreferrer">{leadData.github_url}</Link>
                                            </Typography>
                                        </Paper>
                                    </Grid>
                                )}
                                {leadData.github_username && (
                                    <Grid item xs={12} sm={6}>
                                        <Paper variant="outlined" sx={{ p: 1 }}>
                                            <Typography variant="subtitle2"><strong>GitHub Username:</strong> {leadData.github_username}</Typography>
                                        </Paper>
                                    </Grid>
                                )}

                                {leadData?.certifications?.length > 0 && (
                                    <Grid item xs={12} sm={6}>
                                        <Paper variant="outlined" sx={{ p: 1 }}>
                                            <Typography variant="subtitle2"><strong>Certifications:</strong> {leadData.certifications.join(', ')}</Typography>
                                        </Paper>
                                    </Grid>
                                )}

                                {leadData?.languages?.length > 0 && (
                                    <Grid item xs={12} sm={6}>
                                        <Paper variant="outlined" sx={{ p: 1 }}>
                                            <Typography variant="subtitle2"><strong>Languages:</strong> {leadData.languages.join(', ')}</Typography>
                                        </Paper>
                                    </Grid>
                                )}

                                {leadData.job_title && (
                                    <Grid item xs={12}>
                                        <Divider sx={{ my: 2 }} />
                                        <Typography variant="h6" gutterBottom>Job Information</Typography>
                                    </Grid>
                                )}
                                {leadData.job_title && (
                                    <Grid item xs={12} sm={6}>
                                        <Paper variant="outlined" sx={{ p: 1 }}>
                                            <Typography variant="subtitle2"><strong>Job Title:</strong> {leadData.job_title}</Typography>
                                        </Paper>
                                    </Grid>
                                )}
                                {leadData.job_title_role && (
                                    <Grid item xs={12} sm={6}>
                                        <Paper variant="outlined" sx={{ p: 1 }}>
                                            <Typography variant="subtitle2"><strong>Role:</strong> {leadData.job_title_role}</Typography>
                                        </Paper>
                                    </Grid>
                                )}
                                {leadData.job_title_sub_role && (
                                    <Grid item xs={12} sm={6}>
                                        <Paper variant="outlined" sx={{ p: 1 }}>
                                            <Typography variant="subtitle2"><strong>Sub Role:</strong> {leadData.job_title_sub_role}</Typography>
                                        </Paper>
                                    </Grid>
                                )}
                                {leadData.job_title_levels?.length > 0 && (
                                    <Grid item xs={12} sm={6}>
                                        <Paper variant="outlined" sx={{ p: 1 }}>
                                            <Typography variant="subtitle2"><strong>Levels:</strong> {leadData.job_title_levels.join(', ')}</Typography>
                                        </Paper>
                                    </Grid>
                                )}
                                {leadData.job_company_name && (
                                    <Grid item xs={12}>
                                        <Divider sx={{ my: 2 }} />
                                        <Typography variant="h6" gutterBottom>Company Information</Typography>
                                    </Grid>
                                )}
                                {leadData.job_company_name && (
                                    <Grid item xs={12} sm={6}>
                                        <Paper variant="outlined" sx={{ p: 1 }}>
                                            <Typography variant="subtitle2"><strong>Company Name:</strong> {leadData.job_company_name}</Typography>
                                        </Paper>
                                    </Grid>
                                )}
                                {leadData.job_company_website && (
                                    <Grid item xs={12} sm={6}>
                                        <Paper variant="outlined" sx={{ p: 1 }}>
                                            <Typography variant="subtitle2">
                                                <strong>Website:</strong> <Link href={formatUrl(leadData.job_company_website)} target="_blank" rel="noopener noreferrer">{leadData.job_company_website}</Link>
                                            </Typography>
                                        </Paper>
                                    </Grid>
                                )}
                                {leadData.job_company_size && (
                                    <Grid item xs={12} sm={6}>
                                        <Paper variant="outlined" sx={{ p: 1 }}>
                                            <Typography variant="subtitle2"><strong>Size:</strong> {leadData.job_company_size}</Typography>
                                        </Paper>
                                    </Grid>
                                )}
                                {leadData.job_company_founded && (
                                    <Grid item xs={12} sm={6}>
                                        <Paper variant="outlined" sx={{ p: 1 }}>
                                            <Typography variant="subtitle2"><strong>Founded:</strong> {leadData.job_company_founded}</Typography>
                                        </Paper>
                                    </Grid>
                                )}
                                {leadData.job_company_industry && (
                                    <Grid item xs={12} sm={6}>
                                        <Paper variant="outlined" sx={{ p: 1 }}>
                                            <Typography variant="subtitle2"><strong>Industry:</strong> {leadData.job_company_industry}</Typography>
                                        </Paper>
                                    </Grid>
                                )}
                                {leadData.job_company_linkedin_url && (
                                    <Grid item xs={12} sm={6}>
                                        <Paper variant="outlined" sx={{ p: 1 }}>
                                            <Typography variant="subtitle2">
                                                <strong>LinkedIn:</strong> <Link href={formatUrl(leadData.job_company_linkedin_url)} target="_blank" rel="noopener noreferrer">{leadData.job_company_linkedin_url}</Link>
                                            </Typography>
                                        </Paper>
                                    </Grid>
                                )}
                                {leadData.job_company_facebook_url && (
                                    <Grid item xs={12} sm={6}>
                                        <Paper variant="outlined" sx={{ p: 1 }}>
                                            <Typography variant="subtitle2">
                                                <strong>Facebook:</strong> <Link href={formatUrl(leadData.job_company_facebook_url)} target="_blank" rel="noopener noreferrer">{leadData.job_company_facebook_url}</Link>
                                            </Typography>
                                        </Paper>
                                    </Grid>
                                )}
                                {leadData.job_company_twitter_url && (
                                    <Grid item xs={12} sm={6}>
                                        <Paper variant="outlined" sx={{ p: 1 }}>
                                            <Typography variant="subtitle2">
                                                <strong>Twitter:</strong> <Link href={formatUrl(leadData.job_company_twitter_url)} target="_blank" rel="noopener noreferrer">{leadData.job_company_twitter_url}</Link>
                                            </Typography>
                                        </Paper>
                                    </Grid>
                                )}
                                {leadData.job_company_location_name && (
                                    <Grid item xs={12}>
                                        <Divider sx={{ my: 2 }} />
                                        <Typography variant="h6" gutterBottom>Company Location</Typography>
                                    </Grid>
                                )}
                                {leadData.job_company_location_name && (
                                    <Grid item xs={12} sm={6}>
                                        <Paper variant="outlined" sx={{ p: 1 }}>
                                            <Typography variant="subtitle2"><strong>Name:</strong> {leadData.job_company_location_name}</Typography>
                                        </Paper>
                                    </Grid>
                                )}
                                {leadData.job_company_location_locality && (
                                    <Grid item xs={12} sm={6}>
                                        <Paper variant="outlined" sx={{ p: 1 }}>
                                            <Typography variant="subtitle2"><strong>Locality:</strong> {leadData.job_company_location_locality}</Typography>
                                        </Paper>
                                    </Grid>
                                )}
                                {leadData.job_company_location_metro && (
                                    <Grid item xs={12} sm={6}>
                                        <Paper variant="outlined" sx={{ p: 1 }}>
                                            <Typography variant="subtitle2"><strong>Metro:</strong> {leadData.job_company_location_metro}</Typography>
                                        </Paper>
                                    </Grid>
                                )}
                                {leadData.job_company_location_region && (
                                    <Grid item xs={12} sm={6}>
                                        <Paper variant="outlined" sx={{ p: 1 }}>
                                            <Typography variant="subtitle2"><strong>Region:</strong> {leadData.job_company_location_region}</Typography>
                                        </Paper>
                                    </Grid>
                                )}
                                {leadData.job_company_location_country && (
                                    <Grid item xs={12} sm={6}>
                                        <Paper variant="outlined" sx={{ p: 1 }}>
                                            <Typography variant="subtitle2"><strong>Country:</strong> {leadData.job_company_location_country}</Typography>
                                        </Paper>
                                    </Grid>
                                )}
                                {leadData.job_company_location_continent && (
                                    <Grid item xs={12} sm={6}>
                                        <Paper variant="outlined" sx={{ p: 1 }}>
                                            <Typography variant="subtitle2"><strong>Continent:</strong> {leadData.job_company_location_continent}</Typography>
                                        </Paper>
                                    </Grid>
                                )}
                                {leadData.job_company_location_street_address && (
                                    <Grid item xs={12} sm={6}>
                                        <Paper variant="outlined" sx={{ p: 1 }}>
                                            <Typography variant="subtitle2"><strong>Street Address:</strong> {leadData.job_company_location_street_address}</Typography>
                                        </Paper>
                                    </Grid>
                                )}
                                {leadData.job_company_location_address_line_2 && (
                                    <Grid item xs={12} sm={6}>
                                        <Paper variant="outlined" sx={{ p: 1 }}>
                                            <Typography variant="subtitle2"><strong>Address Line 2:</strong> {leadData.job_company_location_address_line_2}</Typography>
                                        </Paper>
                                    </Grid>
                                )}
                                {leadData.job_company_location_postal_code && (
                                    <Grid item xs={12} sm={6}>
                                        <Paper variant="outlined" sx={{ p: 1 }}>
                                            <Typography variant="subtitle2"><strong>Postal Code:</strong> {leadData.job_company_location_postal_code}</Typography>
                                        </Paper>
                                    </Grid>
                                )}
                                {leadData.job_company_location_geo && (
                                    <Grid item xs={12} sm={6}>
                                        <Paper variant="outlined" sx={{ p: 1 }}>
                                            <Typography variant="subtitle2"><strong>Geo:</strong> {leadData.job_company_location_geo}</Typography>
                                        </Paper>
                                    </Grid>
                                )}
                                {leadData.job_last_updated && (
                                    <Grid item xs={12} sm={6}>
                                        <Divider sx={{ my: 2 }} />
                                        <Typography variant="body2"><strong>Job Last Updated:</strong> {leadData.job_last_updated}</Typography>
                                    </Grid>
                                )}
                                {leadData.job_start_date && (
                                    <Grid item xs={12} sm={6}>
                                        <Typography variant="body2"><strong>Job Start Date:</strong> {leadData.job_start_date}</Typography>
                                    </Grid>
                                )}
                                {leadData.job_summary && (
                                    <Grid item xs={12}>
                                        <Typography variant="body2"><strong>Job Summary:</strong> {leadData.job_summary}</Typography>
                                    </Grid>
                                )}
                                {leadData.location_name && (
                                    <Grid item xs={12}>
                                        <Divider sx={{ my: 2 }} />
                                        <Typography variant="h6" gutterBottom>Location</Typography>
                                    </Grid>
                                )}
                                {leadData.location_name && (
                                    <Grid item xs={12} sm={6}>
                                        <Paper variant="outlined" sx={{ p: 1 }}>
                                            <Typography variant="subtitle2"><strong>Name:</strong> {leadData.location_name}</Typography>
                                        </Paper>
                                    </Grid>
                                )}
                                {leadData.location_locality && (
                                    <Grid item xs={12} sm={6}>
                                        <Paper variant="outlined" sx={{ p: 1 }}>
                                            <Typography variant="subtitle2"><strong>Locality:</strong> {leadData.location_locality}</Typography>
                                        </Paper>
                                    </Grid>
                                )}
                                {leadData.location_metro && (
                                    <Grid item xs={12} sm={6}>
                                        <Paper variant="outlined" sx={{ p: 1 }}>
                                            <Typography variant="subtitle2"><strong>Metro:</strong> {leadData.location_metro}</Typography>
                                        </Paper>
                                    </Grid>
                                )}
                                {leadData.location_region && (
                                    <Grid item xs={12} sm={6}>
                                        <Paper variant="outlined" sx={{ p: 1 }}>
                                            <Typography variant="subtitle2"><strong>Region:</strong> {leadData.location_region}</Typography>
                                        </Paper>
                                    </Grid>
                                )}
                                {leadData.location_country && (
                                    <Grid item xs={12} sm={6}>
                                        <Paper variant="outlined" sx={{ p: 1 }}>
                                            <Typography variant="subtitle2"><strong>Country:</strong> {leadData.location_country}</Typography>
                                        </Paper>
                                    </Grid>
                                )}
                                {leadData.location_continent && (
                                    <Grid item xs={12} sm={6}>
                                        <Paper variant="outlined" sx={{ p: 1 }}>
                                            <Typography variant="subtitle2"><strong>Continent:</strong> {leadData.location_continent}</Typography>
                                        </Paper>
                                    </Grid>
                                )}
                                {leadData.location_street_address && (
                                    <Grid item xs={12} sm={6}>
                                        <Paper variant="outlined" sx={{ p: 1 }}>
                                            <Typography variant="subtitle2"><strong>Street Address:</strong> {leadData.location_street_address}</Typography>
                                        </Paper>
                                    </Grid>
                                )}
                                {leadData.location_address_line_2 && (
                                    <Grid item xs={12} sm={6}>
                                        <Paper variant="outlined" sx={{ p: 1 }}>
                                            <Typography variant="subtitle2"><strong>Address Line 2:</strong> {leadData.location_address_line_2}</Typography>
                                        </Paper>
                                    </Grid>
                                )}
                                {leadData.location_postal_code && (
                                    <Grid item xs={12} sm={6}>
                                        <Paper variant="outlined" sx={{ p: 1 }}>
                                            <Typography variant="subtitle2"><strong>Postal Code:</strong> {leadData.location_postal_code}</Typography>
                                        </Paper>
                                    </Grid>
                                )}
                                {leadData.location_geo && (
                                    <Grid item xs={12} sm={6}>
                                        <Paper variant="outlined" sx={{ p: 1 }}>
                                            <Typography variant="subtitle2"><strong>Geo:</strong> {leadData.location_geo}</Typography>
                                        </Paper>
                                    </Grid>
                                )}
                                {leadData.location_last_updated && (
                                    <Grid item xs={12} sm={6}>
                                        <Typography variant="body2"><strong>Last Updated:</strong> {leadData.location_last_updated}</Typography>
                                    </Grid>
                                )}
                                {leadData.linkedin_connections && (
                                    <Grid item xs={12} sm={6}>
                                        <Typography variant="body2"><strong>LinkedIn Connections:</strong> {leadData.linkedin_connections}</Typography>
                                    </Grid>
                                )}
                                {leadData.inferred_salary && (
                                    <Grid item xs={12} sm={6}>
                                        <Typography variant="body2"><strong>Inferred Salary:</strong> {leadData.inferred_salary}</Typography>
                                    </Grid>
                                )}
                                {leadData.inferred_years_experience && (
                                    <Grid item xs={12} sm={6}>
                                        <Typography variant="body2"><strong>Inferred Years of Experience:</strong> {leadData.inferred_years_experience}</Typography>
                                    </Grid>
                                )}
                                {leadData.summary && (
                                    <Grid item xs={12}>
                                        <Divider sx={{ my: 2 }} />
                                        <Typography variant="body2"><strong>Summary:</strong> {leadData.summary}</Typography>
                                    </Grid>
                                )}
                                {leadData.interests?.length > 0 && (
                                    <Grid item xs={12}>
                                        <Typography variant="body2"><strong>Interests:</strong> {leadData.interests.join(', ')}</Typography>
                                    </Grid>
                                )}
                                {leadData.skills?.length > 0 && (
                                    <Grid item xs={12}>
                                        <Typography variant="body2"><strong>Skills:</strong> {leadData.skills.join(', ')}</Typography>
                                    </Grid>
                                )}
                            </Grid>
                        </CardContent>
                    </Card>
                </ListItem>
            </List>
        </Box>
    );
};

export default PersonalComponent;
