// src\app\pages\WarmupV2\Warmup\Helper\HelpmeDialog.tsx

import React from 'react';
import {
    Dialog, DialogTitle, DialogContentText, DialogActions, DialogContent, Paper, Button
} from '@mui/material';
import Draggable from "react-draggable";
import { PaperProps } from '@mui/material';
import { WarmupSettingsInterface } from '../InterfacesWarmupSettings';
import HelpMeSettings from './HelpMeSettings';
import { motion } from 'framer-motion';
import { grey } from '@mui/material/colors';

interface DialogComponentProps {
    open: boolean;
    onClose: () => void;
    applyRecommendedSettings: (newSettings: WarmupSettingsInterface) => void;
    currentSettings: WarmupSettingsInterface;
    warmupSettings: WarmupSettingsInterface; // Accept warmupSettings as a prop
}
function PaperComponent(props: PaperProps) {
    return (
        <Draggable cancel={'[class*="MuiDialogContent-root"]'}>
            <motion.div
                className="no-global-transition"

                {...props as any}
                initial="initial"
                animate="animate"
                variants={variants}
                transition={{ duration: 1, ease: "easeInOut" }}
            />

        </Draggable>
    );
}


const variants = {
    initial: { backgroundColor: grey[500] }, // Replace with your initial color
    animate: { backgroundColor: 'var(--bs-body-bg)' }  // Replace with the color you want to animate to
};

const titleVariants = {
    initial: { opacity: 0, y: -20 },
    animate: { opacity: 1, y: 0 }
};

const contentVariants = {
    initial: { opacity: 0, x: -20 },
    animate: { opacity: 1, x: 0 }
};

const actionsVariants = {
    initial: { opacity: 0, scale: 0.8 },
    animate: { opacity: 1, scale: 1 }
};

const HelpmeDialog: React.FC<DialogComponentProps> = ({
    open, onClose, applyRecommendedSettings, currentSettings, warmupSettings, // Add warmupSettings here
}) => {

    const closeModalHandler = () => {
        onClose();
    };

    // Add your dialog content here
    return (
        <Dialog
            open={open}
            onClose={closeModalHandler}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            PaperComponent={PaperComponent}
            hideBackdrop={true}
        >

            <DialogTitle
                style={{ cursor: "move" }}
                id="draggable-dialog-title"
            >
                <motion.div
                    className="no-global-transition"

                    initial="initial"
                    animate="animate"
                    variants={titleVariants} // Use title animation variants here
                >
                    Helper
                </motion.div>
            </DialogTitle>

            <DialogContent>
                <motion.div
                    className="no-global-transition"

                    initial="initial"
                    animate="animate"
                    variants={contentVariants} // Use content animation variants here
                >
                    <DialogContentText>
                        To subscribe to this website, please enter your email address here. We will send updates occasionally.
                        <HelpMeSettings applyRecommendedSettings={applyRecommendedSettings} currentSettings={warmupSettings} />
                    </DialogContentText>
                </motion.div>
            </DialogContent>

            <DialogActions>
                <motion.div
                    className="no-global-transition"

                    initial="initial"
                    animate="animate"
                    variants={actionsVariants} // Use actions animation variants here
                >
                    <Button autoFocus onClick={closeModalHandler} color="primary">
                        Cancel
                    </Button>
                </motion.div>
            </DialogActions>
        </Dialog>
    );
};

export default HelpmeDialog;
