import { Grid } from '@mui/material';
import React, { useState } from 'react';

import IconTabs from './ProfileTabs/ProfileTabsParent';

const ProfilePageParent: React.FC = () => {

    return (
        <Grid container spacing={3}>

            <Grid item xs={12}>
                <IconTabs />
            </Grid>
        </Grid>
    );
}

export default ProfilePageParent;
