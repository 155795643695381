/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect } from 'react';
import { Outlet, Link } from 'react-router-dom';
import { Grid, Paper } from '@mui/material'; // Import Grid and Paper from Material-UI
import { toAbsoluteUrl } from '../../../_metronic/helpers';
import DragAndDropCanvas from './DragAndDropCanvas';
import { motion } from 'framer-motion';
import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';

const AuthLayout = () => {
  const theme = useTheme();
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    document.body.style.backgroundImage = `none`;
    const root = document.getElementById('root');
    if (root) {
      root.style.height = '100%';
    }
    return () => {
      document.body.style.backgroundImage = `url(${toAbsoluteUrl('/media/patterns/header-bg.jpg')})`;
      if (root) {
        root.style.height = 'auto';
      }
    };
  }, []);

  return (
    <>

      <div className='d-flex flex-column flex-lg-row flex-column-fluid h-100'>
        {/* begin::Body */}
        <Grid

          container justifyContent="center">
          {/* Outlet */}
          <Grid >
            <Paper
              sx={{
                backdropFilter: "blur(55px)",
                WebkitBackdropFilter: 'blur(55px)', // Add this line
                bgcolor: "transparent"
              }}
              elevation={0} className='d-flex flex-center flex-column flex-lg-row-fluid w-lg-500px p-10'>
              <Outlet />
            </Paper>
          </Grid>

          {/* Footer */}
          <Grid item xs={12} className='d-flex flex-center flex-wrap px-5'>
            <div className='d-flex fw-semibold text-primary fs-base'>{/* Links or other content */}</div>
          </Grid>

          {/* Aside */}
          {/* {!isMobileOrTablet && (
            // <Grid item xs={12} lg={6}>
            //   <motion.div
            //     className="no-global-transition"
            //     whileHover={{ scale: 1.1 }} whileTap={{ scale: 1.2 }}>
            //     <DragAndDropCanvas />
            //   </motion.div>
            // </Grid>
          )} */}

        </Grid>
        {/* end::Body */}
      </div>

    </>
  );
};

export { AuthLayout };

