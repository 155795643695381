import React, { useState, ForwardedRef } from 'react';
import { useParams, Link } from 'react-router-dom';
import { useFormik } from 'formik';
import { resetPassword } from '../core/_requests';
import { IconButton, Paper, InputAdornment, TextField, Button, CircularProgress, Snackbar, Box, Typography, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Visibility from '@mui/icons-material/Visibility';
import CheckCircleOutline from '@mui/icons-material/CheckCircleOutline';
import CancelOutlined from '@mui/icons-material/CancelOutlined';
import PasswordValidator from 'password-validator';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Confetti from 'react-confetti';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseSharpIcon from '@mui/icons-material/CloseSharp';
import { motion, AnimatePresence, HTMLMotionProps } from "framer-motion";


const initialValues = {
    password: '',
    confirmPassword: '',
};

const passwordSchema = new PasswordValidator();
passwordSchema
    .is().min(8)
    .is().max(100)
    .has().uppercase()
    .has().lowercase()
    .has().digits()
    .has().not().spaces();

export function ResetPassword() {
    const [loading, setLoading] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [passwordsMatch, setPasswordsMatch] = useState(true);
    const [successModalOpen, setSuccessModalOpen] = useState(false);

    const [passwordValidation, setPasswordValidation] = useState({
        length: false,
        uppercase: false,
        lowercase: false,
        digits: false,
        noSpaces: false,
        strength: 0,
    });

    const { token } = useParams<{ token: string }>();

    const formik = useFormik({
        initialValues,
        onSubmit: async (values, { setSubmitting }) => {
            setLoading(true);

            try {
                const response = await resetPassword(token!, values.password);
                if (response.data.message === 'Password reset successful') {
                    setSuccessModalOpen(true);
                } else {
                    handleSnackbarOpen('Unable to reset password. Please try again.');
                }
            } catch (error) {
                handleSnackbarOpen('Something went wrong. Please try again later.');
            }

            setLoading(false);
            setSubmitting(false);
        },
    });

    const handleSnackbarOpen = (message: string) => {
        setSnackbarMessage(message);
        setSnackbarOpen(true);
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newPassword = event.target.value;
        formik.setFieldValue('password', newPassword);
        updatePasswordStrength(newPassword);
        setPasswordsMatch(newPassword === formik.values.confirmPassword);
    };

    const handleConfirmPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newConfirmPassword = event.target.value;
        formik.setFieldValue('confirmPassword', newConfirmPassword);
        setPasswordsMatch(formik.values.password === newConfirmPassword);
    };
    const updatePasswordStrength = (password: string) => {
        const validation = { ...passwordValidation };
        let strength = 0;

        validation.length = password.length >= 8;
        validation.uppercase = /[A-Z]/.test(password);
        validation.lowercase = /[a-z]/.test(password);
        validation.digits = /\d/.test(password);
        validation.noSpaces = !/\s/.test(password);

        strength += validation.length ? 20 : 0;
        strength += validation.uppercase ? 20 : 0;
        strength += validation.lowercase ? 20 : 0;
        strength += validation.digits ? 20 : 0;
        strength += validation.noSpaces ? 20 : 0;

        validation.strength = strength;
        setPasswordValidation(validation);
    };

    return (
        <>
            <form
                className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
                noValidate
                id='kt_reset_password_form'
                onSubmit={formik.handleSubmit}
            >
                <div className='text-center mb-10'>
                    <h1 className='text-dark fw-bolder mb-3'>Reset Password</h1>
                </div>

                {/* Password Field */}
                <div className='fv-row mb-8'>
                    <TextField
                        type={showPassword ? 'text' : 'password'}
                        label="New Password"
                        autoComplete='off'
                        {...formik.getFieldProps('password')}
                        onChange={handlePasswordChange}
                        variant='outlined'
                        fullWidth
                        error={formik.touched.password && !!formik.errors.password}
                        helperText={formik.touched.password && formik.errors.password}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={() => setShowPassword(!showPassword)}
                                        edge="end"
                                    >
                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                </div>

                {/* Confirm Password Field */}
                <div className='fv-row mb-8'>
                    <TextField
                        type={showConfirmPassword ? 'text' : 'password'}
                        label="Confirm Password"
                        autoComplete='off'
                        {...formik.getFieldProps('confirmPassword')}
                        onChange={handleConfirmPasswordChange}
                        variant='outlined'
                        fullWidth
                        error={!passwordsMatch || (formik.touched.confirmPassword && !!formik.errors.confirmPassword)}
                        helperText={!passwordsMatch ? 'Passwords do not match' : formik.touched.confirmPassword && formik.errors.confirmPassword}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle confirm password visibility"
                                        onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                                        edge="end"
                                    >
                                        {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                </div>

                {/* Password strength indicators */}
                <Box sx={{ my: 2 }}>
                    <Typography variant="subtitle1" gutterBottom>Password Requirements:</Typography>
                    <List dense>
                        {Object.keys(passwordValidation).map(key => {
                            if (key !== 'strength') {
                                return (
                                    <ListItem key={key} disableGutters>
                                        <ListItemIcon>
                                            {passwordValidation[key] ? <CheckCircleOutline color="success" /> : <CancelOutlined color="error" />}
                                        </ListItemIcon>
                                        <ListItemText primary={key.charAt(0).toUpperCase() + key.slice(1).replace(/[A-Z]/g, ' $&')} />
                                    </ListItem>
                                );
                            }
                            return null;
                        })}
                    </List>
                </Box>

                {/* Strength Bar */}
                <Box sx={{ width: '100%', bgcolor: 'background.paper', borderRadius: 1, overflow: 'hidden' }}>
                    <Box
                        sx={{
                            width: `${passwordValidation.strength}%`,
                            bgcolor: passwordValidation.strength > 75 ? 'success.main' : passwordValidation.strength > 50 ? 'warning.main' : 'error.main',
                            height: '10px',
                        }}
                    />
                </Box>

                <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
                    <Button
                        type='submit'
                        id='kt_reset_password_submit'
                        variant='contained'
                        color='secondary'
                        size='large'
                        sx={{ fontSize: "20px" }}
                        disabled={loading}
                    >
                        <span className='indicator-label'>Submit</span>
                        {loading && <CircularProgress size={20} className='ms-2' />}
                    </Button>
                </div>

                <Snackbar
                    open={snackbarOpen}
                    autoHideDuration={6000}
                    onClose={handleSnackbarClose}
                    message={snackbarMessage}
                />
            </form>
            <AnimatePresence>
                {successModalOpen && (
                    <SuccessModal
                        open={successModalOpen}
                        onClose={() => setSuccessModalOpen(false)}
                    />
                )}
            </AnimatePresence>
        </>
    );
}


const MotionPaper = React.forwardRef((props, ref: ForwardedRef<HTMLDivElement>) => {
    const [isDragging, setIsDragging] = useState(false);

    const handleMouseDown = () => setIsDragging(true);
    const handleMouseUp = () => setIsDragging(false);

    return (
        <motion.div
            className="no-global-transition"

            initial={{ scale: 0.5, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            exit={{ scale: 0.5, opacity: 0 }}
            drag
            onMouseDown={handleMouseDown}
            onMouseUp={handleMouseUp}
            ref={ref}
            style={{ cursor: isDragging ? 'grabbing' : 'grab', zIndex: 1 }}
        >
            <Paper {...props} />
        </motion.div>
    );
});
MotionPaper.displayName = 'MotionPaper';

const SuccessModal = ({ open, onClose }) => {
    const [confetti, setConfetti] = useState(true);

    const handleClose = () => {
        setConfetti(false);
        onClose();
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            PaperComponent={MotionPaper}
            sx={{ width: 'auto', maxWidth: 'none', height: 'auto', maxHeight: 'none', zIndex: (theme) => theme.zIndex.modal + 1 }}
        >
            {confetti && <Confetti width={window.innerWidth} height={window.innerHeight} />}
            <DialogTitle>
                <CheckCircleIcon color='success' sx={{ fontSize: '4rem' }} />
                Password Reset Successful!
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    You can now log in with your new password.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Link to='/auth/login' style={{ textDecoration: 'none' }}>
                    <Button color='primary'>Go to Login</Button>
                </Link>
                <IconButton aria-label="close" onClick={handleClose}>
                    <CloseSharpIcon />
                </IconButton>
            </DialogActions>
        </Dialog>
    );
};


