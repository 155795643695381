import * as React from 'react';
import { SnackbarProvider, useSnackbar } from 'notistack';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import CloseSharpIcon from '@mui/icons-material/CloseSharp';
import TipsAndUpdatesSharpIcon from '@mui/icons-material/TipsAndUpdatesSharp';
import axios from 'axios';
import { useTheme } from '@mui/material/styles';

function MyApp() {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const theme = useTheme(); // Access the theme to use MUI's secondary main color

    const fetchAndShowTip = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/account/random-tip`);
            const tip = response.data;
            enqueueSnackbar(`${tip.category}: ${tip.Tip}`, {
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center',
                },
                autoHideDuration: 10000,
                style: {
                    fontSize: "30px",
                    backgroundColor: theme.palette.secondary.main,
                    borderRadius: "0px"
                }, // Inline styling
                action: key => (
                    <IconButton onClick={() => closeSnackbar(key)} color="inherit">
                        <CloseSharpIcon />
                    </IconButton>
                )
            });
        } catch (error) {
            console.error('Failed to fetch tip:', error);
            enqueueSnackbar('Failed to fetch tip!', {
                autoHideDuration: 10000,
                style: {

                    backgroundColor: theme.palette.error.main,
                    borderRadius: "0px",
                    fontSize: "250px",

                }, // Inline styling for error variant
                action: key => (
                    <IconButton onClick={() => closeSnackbar(key)} color="inherit">
                        <CloseSharpIcon />
                    </IconButton>
                )
            });
        }
    };

    return (
        <Tooltip title="Get a Random Tip">
            <IconButton onClick={fetchAndShowTip} color="inherit">
                <TipsAndUpdatesSharpIcon

                    color='primary'
                    sx={{ fontSize: "25px" }}
                />
            </IconButton>
        </Tooltip>
    );
}

export default function IntegrationNotistack() {
    return (
        <SnackbarProvider maxSnack={2}>
            <MyApp />
        </SnackbarProvider>
    );
}
