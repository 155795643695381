import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DeleteIcon from '@mui/icons-material/Delete';
import {
    Alert,
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    Slide,
    Snackbar,
    Typography,
} from '@mui/material';
import Papa from 'papaparse';
import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { v4 as uuidv4 } from 'uuid';
import * as XLSX from 'xlsx';

//@ts-ignore
// Define the initial mapping with proper types for TypeScript
interface Mapping {
    email: string;
    firstName: string;
    lastName: string;
    smtpHost: string;
    smtpPort: string;
    smtpPassword: string;
    imapHost: string;
    imapPort: string;
    imapPassword: string;
}

const initialMapping: Mapping = {
    email: '',
    firstName: '',
    lastName: '',
    smtpHost: '',
    smtpPort: '',
    smtpPassword: '',
    imapHost: '',
    imapPort: '',
    imapPassword: '',
};

interface FileUploadDialogProps {
    open: boolean;
    onClose: () => void;
    onImport: (importedRows: any[]) => void; // Adjust the type based on your actual data structure
}

function FileUploadDialog({ open, onClose, onImport }: FileUploadDialogProps) {
    const [file, setFile] = useState<File | null>(null);
    const [fileHeaders, setFileHeaders] = useState<string[]>([]);
    const [parsedData, setParsedData] = useState<string[][]>([]);
    const [mapping, setMapping] = useState<Mapping>(initialMapping);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('info'); // can be 'error', 'warning', 'info', 'success'

    const onDrop = (acceptedFiles: File[]) => {
        const file = acceptedFiles[0];
        setFile(file);
        const reader = new FileReader();
        reader.onload = (e: ProgressEvent<FileReader>) => {
            const bstr = e.target?.result as string;
            const wb = XLSX.read(bstr, { type: 'binary' });
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            const data = XLSX.utils.sheet_to_csv(ws);
            Papa.parse(data, {
                complete: (results) => {
                    setFileHeaders(results.data[0] as string[]);
                    setParsedData(results.data.slice(1) as string[][]);
                },
            });
        };
        reader.readAsBinaryString(file);
    };

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop, accept: {
            'text/csv': ['.csv'],
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
            'application/vnd.ms-excel': ['.xls', '.xlsx']
        }
    });

    const handleMappingChange = (field: keyof Mapping, header: string) => {
        setMapping((prevMapping) => ({ ...prevMapping, [field]: header }));
    };

    const handleImport = () => {
        const allFieldsMapped = Object.values(mapping).every((value) => value !== '');
        if (!allFieldsMapped) {
            setSnackbarMessage("Please map all fields before importing.");
            setSnackbarSeverity('error'); // Adjust based on the context
            setSnackbarOpen(true);
            return;
        }

        const importedRows = parsedData.map((row) => {
            return {
                id: uuidv4(),
                email: row[fileHeaders.indexOf(mapping.email)],
                firstName: row[fileHeaders.indexOf(mapping.firstName)],
                lastName: row[fileHeaders.indexOf(mapping.lastName)],
                smtpHost: row[fileHeaders.indexOf(mapping.smtpHost)],
                smtpPort: row[fileHeaders.indexOf(mapping.smtpPort)],
                smtpPassword: row[fileHeaders.indexOf(mapping.smtpPassword)],
                imapHost: row[fileHeaders.indexOf(mapping.imapHost)],
                imapPort: row[fileHeaders.indexOf(mapping.imapPort)],
                imapPassword: row[fileHeaders.indexOf(mapping.imapPassword)],
                isNew: true,
            };
        });

        onImport(importedRows);
        onClose();
    };

    const formatLabel = (label: string): string => {
        return label.replace(/([A-Z])/g, ' $1').replace(/^./, (str) => str.toUpperCase());
    };

    const resetForm = () => {
        setFile(null);
        setFileHeaders([]);
        setParsedData([]);
        setMapping(initialMapping);
    };

    return (
        <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
            <DialogTitle>Upload and Map Data</DialogTitle>
            <DialogContent>
                <Paper {...getRootProps()} sx={{
                    p: 2,
                    border: '2px dashed',
                    borderColor: isDragActive ? 'primary.main' : 'grey.400',
                    bgcolor: 'background.paper',
                    textAlign: 'center',
                    cursor: 'pointer',
                    mb: 2
                }}>
                    <input {...getInputProps()} />
                    {isDragActive ? (
                        <Typography>Drop the file here ...</Typography>
                    ) : (
                        <Typography>Drag 'n' drop a file here, or click to select file</Typography>
                    )}
                    {file && (
                        <Typography sx={{ mt: 2 }}>
                            Selected file: {file.name}
                            <IconButton onClick={resetForm} size="small" sx={{ ml: 1 }}>
                                <DeleteIcon fontSize="inherit" />
                            </IconButton>
                        </Typography>
                    )}
                    {!file && <CloudUploadIcon sx={{ mt: 2 }} />}
                </Paper>
                {fileHeaders.length > 0 && (
                    <Grid container spacing={2}>
                        {Object.keys(initialMapping).map((field) => (
                            <Grid item xs={12} sm={6} key={field}>
                                <FormControl fullWidth>
                                    <InputLabel>{formatLabel(field)}</InputLabel>
                                    <Select
                                        value={mapping[field as keyof Mapping]}
                                        label={field}
                                        onChange={(e) => handleMappingChange(field as keyof Mapping, e.target.value as string)}
                                    >
                                        {fileHeaders.map((header, index) => (
                                            <MenuItem key={index} value={header}>
                                                {header}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                        ))}
                    </Grid>
                )}
                <Button onClick={handleImport} color='secondary' variant="text" disabled={!file}>
                    Import
                </Button>
                <Button onClick={resetForm} color='info' variant="text" >
                    Reset
                </Button>
            </DialogContent>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                color='secondary'
                onClose={() => setSnackbarOpen(false)}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                TransitionComponent={Slide}
            >

                <Alert
                    onClose={() => setSnackbarOpen(false)}
                    //@ts-ignore
                    severity={snackbarSeverity}
                    sx={{ width: '100%' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>


        </Dialog >
    );
}

export default FileUploadDialog;
