// src\app\pages\0-Account\EmailAccounts\SignatureEditor.tsx
import { Alert, Button, Chip, Grid, Snackbar, TextField } from '@mui/material';
import axios from 'axios';
import React, { useRef, useState } from 'react';
import ReactQuill from 'react-quill';

import { CustomToolbar } from '../../4-Campaigns/3-CampaignSequence/SequenceComponents/CustomToolbar';

interface SignatureComponentProps {
    selectedAccountEmails: string[];
    accountIds: string[];
}

const SignatureComponent: React.FC<SignatureComponentProps> = ({ selectedAccountEmails, accountIds }) => {
    const [styledContent, setStyledContent] = useState('');
    const [textContent, setTextContent] = useState('');
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error'>('success');
    const editorRef = useRef<any>(null); // Adjusted for potential type issue with useRef


    const handleSubmit = async () => {

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/account/emailaccounts/set-signature`, {
                accountIds,
                signature: textContent,
                styledSignature: styledContent,
            });
            // Assuming the response includes a message
            setSnackbarMessage(response.data.message || 'Signature updated successfully!');
            setSnackbarSeverity('success');
        } catch (error: any) {
            setSnackbarMessage(error.response?.data?.message || 'An error occurred while updating the signature.');
            setSnackbarSeverity('error');
        } finally {
            setSnackbarOpen(true);
        }
    };

    const handleStyledContentChange = (content: string) => {
        setStyledContent(content);
    };

    const handleTextContentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTextContent(event.target.value);
    };

    const handleCloseSnackbar = () => {
        setSnackbarOpen(false);
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={8} container direction="column" spacing={2}>
                <Grid item>
                    <h3>Styled Signature (for HTML emails)</h3>
                    <CustomToolbar
                        displayHTML={false}
                        children={[]}
                        toggleDisplayMode
                        editorRef={editorRef}
                    />
                    <ReactQuill
                        ref={editorRef}
                        placeholder="Styled Signature..."
                        value={styledContent}
                        onChange={handleStyledContentChange}
                        modules={{ toolbar: false }}
                        style={{ height: '200px', marginBottom: '20px' }}
                    />
                </Grid>
                <Grid item>
                    <h3>Text Signature (for plain text emails)</h3>
                    <TextField
                        multiline
                        placeholder="Text Signature..."
                        variant="standard"
                        fullWidth
                        value={textContent}
                        onChange={handleTextContentChange}
                        style={{ height: '200px', marginBottom: '20px' }}
                        inputProps={{ style: { height: '173px', padding: '0 14px', fontSize: "14px" } }}
                    />
                </Grid>
            </Grid>
            <Grid item xs={4}>
                {selectedAccountEmails.map((email, index) => (
                    <Chip key={index} label={email} style={{ margin: 2 }} />
                ))}
            </Grid>
            <Grid item xs={12} style={{ textAlign: 'center' }}>
                <Button variant="contained" color="primary" onClick={handleSubmit}>
                    Submit
                </Button>
            </Grid>
            <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleCloseSnackbar}>
                <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Grid>
    );
};

export default SignatureComponent;
