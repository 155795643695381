// src\app\modules\CustomMaterialUiComponents\CustomMuiDivider.tsx
import React from 'react';
import { Divider } from '@mui/material';

// Define the props for the custom divider
interface CustomDividerProps {
    thickness?: number; // Custom thickness
    color?: string; // Custom color
    marginTop?: number | string; // Custom margin top
    marginBottom?: number | string; // Custom margin bottom
}

const CustomMuiDivider: React.FC<CustomDividerProps> = ({ thickness = 1, color = '#666666', marginTop = 2, marginBottom = 2 }) => {
    return (
        <Divider
            sx={{
                borderTop: `${thickness}px solid ${color}`, // Customize thickness and color
                marginTop: marginTop, // Customize margin top
                marginBottom: marginBottom, // Customize margin bottom
            }}
        />
    );
};

export default CustomMuiDivider;
