import { useAuth } from '@/src/app/modules/auth';
import AccountBoxSharpIcon from '@mui/icons-material/AccountBoxSharp';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React, { ReactNode } from 'react';

import { ProfilePageHeader } from '../Headerprofile';
import IntegrationsTab from '../IntegrationsTab';
import ProviderPreferences from '../ProviderPreferences';
import { UserUsageStatistics } from '../UserUsageStatistics';
import CertificatesInfo from './admin/AllCertificates';
import NginxAndCertFiles from './admin/NginxCertFiles';
import SystemHealth from './admin/systeminfo';
import { AdminArea } from './AdminArea';

interface TabPanelProps {
    children?: ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

export default function VerticalTabs() {
    const { currentUser } = useAuth();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Box
            sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex', height: 400 }}
        >
            <Tabs
                orientation="vertical"
                variant="scrollable"
                value={value}
                onChange={handleChange}
                aria-label="Vertical tabs example"
                sx={{ borderRight: 1, borderColor: 'divider' }}
            >
                <Tab icon={<AccountBoxSharpIcon />} {...a11yProps(0)} />
                <Tab label="Usage" {...a11yProps(1)} />
                <Tab label="Integrations" {...a11yProps(2)} />
                {currentUser && currentUser.admin && (
                    <Tab label="Admin Area" {...a11yProps(3)} />
                )}
                {currentUser && currentUser.admin && (
                    <Tab label="System" {...a11yProps(4)} />
                )}
                {currentUser && currentUser.admin && (
                    <Tab label="User Domain Files" {...a11yProps(5)} />
                )}

                {currentUser && currentUser.admin && (
                    <Tab label="Certificates" {...a11yProps(6)} />
                )}



            </Tabs>
            <TabPanel value={value} index={0}>
                <ProfilePageHeader />
            </TabPanel>

            <TabPanel value={value} index={1}>
                <UserUsageStatistics />
            </TabPanel>
            <TabPanel value={value} index={2}>
                <IntegrationsTab />
                <ProviderPreferences />
            </TabPanel>

            {currentUser && currentUser.admin && (
                <TabPanel value={value} index={3}>
                    <AdminArea />
                </TabPanel>
            )}

            {currentUser && currentUser.admin && (
                <TabPanel value={value} index={4}>
                    <SystemHealth />
                </TabPanel>
            )}


            {currentUser && currentUser.admin && (
                <TabPanel value={value} index={5}>
                    <NginxAndCertFiles />
                </TabPanel>
            )}


            {currentUser && currentUser.admin && (
                <TabPanel value={value} index={6}>
                    <CertificatesInfo />
                </TabPanel>
            )}

        </Box>
    );
}