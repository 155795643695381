import React, { Component } from 'react';
import { CircularProgress, MenuItem, Menu, IconButton, Snackbar, Checkbox, Drawer, Button } from '@mui/material';
import { List, ListItem, ListItemText, Typography, Box } from '@mui/material';

import mails from './data/mails';
import folders from './data/folders';
import filters from './data/filters';
import options from './data/options';
import labels from './data/labels';
import './Mail.css'; // Replace with the actual path to your CSS file



import IntlMessages from './util/IntlMessages';

import ComposeMail from './Compose';
import MailDetail from './MailDetail';
import CustomScrollbars from './util/CustomScrollbars';
import MailList from './MailList';

import DeleteIcon from '@mui/icons-material/Delete';
import FolderIcon from '@mui/icons-material/Folder';
import LabelIcon from '@mui/icons-material/Label';
import MoreVertSharpIcon from '@mui/icons-material/MoreVertSharp';
import ArrowBackIosNewSharpIcon from '@mui/icons-material/ArrowBackIosNewSharp';
interface MailProps {
    width: number;
}

interface MailState {
    searchMail: string;
    noContentFoundMessage: string;
    alertMessage: string;
    showMessage: boolean;
    drawerState: boolean;
    optionName: string;
    anchorEl: any; // Use a more specific type if possible
    allMail: any[]; // Replace 'any' with a more specific type if possible
    loader: boolean;
    currentMail: any; // Replace 'any' with a more specific type
    selectedMails: number;
    selectedFolder: number;
    composeMail: boolean;
    labelMenuState: boolean;
    folderMenuState: boolean;
    optionMenuState: boolean;
    folderMails: any[]; // Replace 'any' with a more specific type
    user: { // Define user if it's part of the state
        name: string;
        email: string;
        avatar: string;
    };
}

interface ComposeMailProps {
    onMailSend: (mail: any) => void;
    onClose: () => void;
    user?: { // Make user optional
        name: string;
        avatar: string;
        email: string;
    };
    open: boolean;
}


class MailFull extends Component<MailProps, MailState> {

    MailSideBar = () => {
        return (
            <Box display="flex" flexDirection="column" alignItems="center">
                <Box mt={2}>
                    <i />
                </Box>
                <Typography variant="h6" mt={1}><IntlMessages id="mail.mailbox" /></Typography>
                <div className="module-side-content">
                    <CustomScrollbars
                        className="module-side-scroll scrollbar"
                        style={{
                            height: this.props.width >= 1200 ? 'calc(100vh - 200px)' : 'calc(100vh - 80px)',
                        }}
                    >
                        <div className="module-add-task">
                            <Button
                                variant="contained"
                                color="primary"
                                className="btn-block"
                                onClick={() => {
                                    this.setState({ composeMail: true });
                                }}
                            >
                                <i className="zmdi zmdi-edit zmdi-hc-fw" />
                                <IntlMessages id="mail.compose" />
                            </Button>
                        </div>

                        <List className="module-nav">
                            {this.getNavFolders()}

                            <ListItem className="module-nav-label">
                                <ListItemText primary={<IntlMessages id="mail.filters" />} />
                            </ListItem>

                            {this.getNavFilters()}

                            <ListItem className="module-nav-label">
                                <ListItemText primary={<IntlMessages id="mail.labels" />} />
                            </ListItem>

                            {this.getNavLabels()}
                        </List>
                    </CustomScrollbars>
                </div>
            </Box>
        );
    };


    onDeleteMail = () => {
        const mails = this.state.allMail.map(mail =>
            mail.selected && (mail.folder === this.state.selectedFolder) ?
                { ...mail, folder: 4, selected: false, } : mail
        );
        this.setState({
            alertMessage: 'Mail Deleted Successfully',
            showMessage: true,
            selectedMails: 0,
            allMail: mails,
            folderMails: mails.filter(mail => mail.folder === this.state.selectedFolder)
        });
    };
    getNavFolders = () => {
        return folders.map((folder, index) => (
            <ListItem
                key={index}
                button
                onClick={() => {
                    const filterMails = this.state.allMail.filter((mail) => mail.folder === folder.id);
                    this.setState({
                        selectedFolder: folder.id,
                        noContentFoundMessage: 'No mail found in selected folder',
                        currentMail: null,
                        loader: true,
                        folderMails: filterMails,
                    });
                    setTimeout(() => {
                        this.setState({ loader: false });
                    }, 1500);
                }}
                className={this.state.selectedFolder === folder.id ? 'active' : ''}
            >
                <ListItemText
                    primary={
                        <span className="jr-link">
                            <i className={`zmdi zmdi-${folder.icon}`} />
                            <span>{folder.title}</span>
                        </span>
                    }
                />
            </ListItem>
        ));
    };

    onFolderMenuItemSelect = (folderId) => {
        this.handleRequestClose();
        const mails = this.state.allMail.map(mail =>
            mail.selected && (mail.folder === this.state.selectedFolder) ?
                { ...mail, folder: folderId, selected: false, } : mail
        );
        this.setState({
            selectedMails: 0,
            allMail: mails,
            noContentFoundMessage: 'No mail found in selected folder',
            alertMessage: 'Mail has been moved successfully',
            showMessage: true,
            folderMails: mails.filter(mail => mail.folder === this.state.selectedFolder)
        });
    };
    onLabelMenuItemSelect = (label) => {
        this.handleRequestClose();
        const mails = this.state.allMail.map(mail => {
            if (mail.selected && (mail.folder === this.state.selectedFolder)) {
                if (mail.labels.includes(label.id)) {
                    return { ...mail, labels: this.removeLabel(mail, label.id) };
                } else {
                    return { ...mail, labels: this.addLabel(mail, label.id) };
                }
            } else {
                return mail;
            }
        }
        );
        this.setState({
            noContentFoundMessage: 'No mail found in selected label',
            alertMessage: 'Label Updated Successfully',
            showMessage: true,
            allMail: mails,
            folderMails: mails.filter(mail => mail.folder === this.state.selectedFolder)
        });
    };
    handleRequestClose = () => {
        this.setState({
            composeMail: false,
            showMessage: false,
            folderMenuState: false,
            labelMenuState: false,
            optionMenuState: false
        });
    };
    getNavFilters = () => {
        return filters.map((filter, index) => (
            <ListItem
                key={index}
                button
                onClick={() => {
                    const filterMails = this.state.allMail.filter((mail) => {
                        if (filter.id === 0 && mail.starred) {
                            return mail;
                        } else if (filter.id === 1 && mail.important) {
                            return mail;
                        }
                        return null;
                    });
                    this.setState({
                        noContentFoundMessage: 'No mail found in selected filter',
                        loader: true,
                        folderMails: filterMails,
                    });
                    setTimeout(() => {
                        this.setState({ loader: false });
                    }, 1500);
                }}
            >
                <ListItemText
                    primary={
                        <span className="jr-link">
                            <i className={`zmdi zmdi-${filter.icon}`} />
                            <span>{filter.title}</span>
                        </span>
                    }
                />
            </ListItem>
        ));
    };
    onFolderSelect = event => {
        this.setState({
            anchorEl: event.currentTarget,
            folderMenuState: !this.state.folderMenuState
        })
    };
    onLabelSelect = event => {
        this.setState({
            anchorEl: event.currentTarget,
            labelMenuState: !this.state.labelMenuState
        })
    };
    onOptionMenuSelect = event => {
        this.setState({
            anchorEl: event.currentTarget,
            optionMenuState: !this.state.optionMenuState
        })
    };
    onOptionMenuItemSelect = (option) => {
        switch (option.title) {
            case 'All':
                this.handleRequestClose();
                this.getAllMail();
                break;
            case 'None':
                this.handleRequestClose();
                this.getUnselectedAllMail();
                break;
            case 'Read':
                this.handleRequestClose();
                this.getReadMail();
                break;
            case 'Unread':
                this.handleRequestClose();
                this.getUnreadMail();
                break;
            case 'Starred':
                this.handleRequestClose();
                this.getStarredMail();
                break;
            case 'Unstarred':
                this.handleRequestClose();
                this.getUnStarredMail();
                break;
            case 'Important':
                this.handleRequestClose();
                this.getImportantMail();
                break;
            case 'Unimportant':
                this.handleRequestClose();
                this.getUnimportantMail();
                break;
            default:
                this.handleRequestClose();
                this.getAllMail();
        }
    };
    getAllMail = () => {
        let mails = this.state.allMail.map((mail) => mail.folder === this.state.selectedFolder ? {
            ...mail,
            selected: true
        } : mail);
        this.setState({
            selectedMails: mails.length,
            allMail: mails,
            optionName: 'All',
            folderMails: mails.filter(mail => mail.folder === this.state.selectedFolder)
        });
    };
    getUnselectedAllMail = () => {
        let mails = this.state.allMail.map((mail) => mail.folder === this.state.selectedFolder ? {
            ...mail,
            selected: false
        } : mail);
        this.setState({
            selectedMails: 0,
            allMail: mails,
            optionName: 'None',
            folderMails: mails.filter(mail => mail.folder === this.state.selectedFolder)
        });
    };
    getReadMail = () => {
        let selectedMail = 0;
        let mails = this.state.allMail.filter(mail => mail.folder === this.state.selectedFolder);
        mails = mails.map((mail) => {
            if (mail.read) {
                selectedMail++;
                return { ...mail, selected: true };
            }
            return { ...mail, selected: false }
        });
        this.setState({
            selectedMails: selectedMail,
            allMail: mails,
            optionName: 'Read',
            folderMails: mails.filter(mail => mail.folder === this.state.selectedFolder)
        });
        return mails;
    };
    getUnreadMail = () => {
        let selectedMail = 0;
        let mails = this.state.allMail.filter(mail => mail.folder === this.state.selectedFolder);
        mails = mails.map((mail) => {
            if (!mail.read) {
                selectedMail++;
                return { ...mail, selected: true };
            }
            return { ...mail, selected: false }
        });
        this.setState({
            selectedMails: selectedMail,
            allMail: mails,
            optionName: 'Unread',
            folderMails: mails.filter(mail => mail.folder === this.state.selectedFolder)
        });
        return mails;
    };
    getStarredMail = () => {
        let selectedMail = 0;
        let mails = this.state.allMail.filter(mail => mail.folder === this.state.selectedFolder);
        mails = mails.map((mail) => {
            if (mail.starred) {
                selectedMail++;
                return { ...mail, selected: true };
            }
            return { ...mail, selected: false }
        });
        this.setState({
            selectedMails: selectedMail,
            allMail: mails,
            optionName: 'Starred',
            folderMails: mails.filter(mail => mail.folder === this.state.selectedFolder)
        });
        return mails;
    };
    getUnStarredMail = () => {
        let selectedMail = 0;
        let mails = this.state.allMail.filter(mail => mail.folder === this.state.selectedFolder);
        mails = mails.map((mail) => {
            if (!mail.starred) {
                selectedMail++;
                return { ...mail, selected: true };
            }
            return { ...mail, selected: false }
        });
        this.setState({
            selectedMails: selectedMail,
            allMail: mails,
            optionName: 'UnStarred',
            folderMails: mails.filter(mail => mail.folder === this.state.selectedFolder)
        });
        return mails;
    };
    getImportantMail = () => {
        let selectedMail = 0;
        let mails = this.state.allMail.filter(mail => mail.folder === this.state.selectedFolder);
        mails = mails.map((mail) => {
            if (mail.important) {
                selectedMail++;
                return { ...mail, selected: true };
            }
            return { ...mail, selected: false }
        });
        this.setState({
            selectedMails: selectedMail,
            allMail: mails,
            optionName: 'Important',
            folderMails: mails.filter(mail => mail.folder === this.state.selectedFolder)
        });
        return mails;
    };
    getUnimportantMail = () => {
        let selectedMail = 0;
        let mails = this.state.allMail.filter(mail => mail.folder === this.state.selectedFolder);
        mails = mails.map((mail) => {
            if (!mail.important) {
                selectedMail++;
                return { ...mail, selected: true };
            }
            return { ...mail, selected: false }
        });
        this.setState({
            selectedMails: selectedMail,
            optionName: 'Unimportant',
            allMail: mails,
            noContentFoundMessage: 'No Mail found in selected Label',
            folderMails: mails.filter(mail => mail.folder === this.state.selectedFolder)
        });
        return mails;
    };
    getNavLabels = () => {
        return labels.map((label, index) => (
            <ListItem
                key={index}
                button
                onClick={() => {
                    const filterMails = this.state.allMail.filter((mail) =>
                        mail.labels.includes(label.id)
                    );
                    this.setState({
                        loader: true,
                        noContentFoundMessage: 'No mail found in selected label',
                        folderMails: filterMails,
                    });
                    setTimeout(() => {
                        this.setState({ loader: false });
                    }, 1500);
                }}
            >
                <ListItemText
                    primary={
                        <span className="jr-link">
                            <i className={`zmdi zmdi-circle text-${label.color}`} />
                            <span>{label.title}</span>
                        </span>
                    }
                />
            </ListItem>
        ));
    };
    searchMail = (searchText) => {
        if (searchText === '') {
            this.setState({ folderMails: this.state.allMail.filter((mail) => !mail.deleted) });
        } else {
            const searchMails = this.state.allMail.filter((mail) =>
                !mail.deleted && mail.subject.toLowerCase().indexOf(searchText.toLowerCase()) > -1);
            this.setState({
                folderMails: searchMails
            });
        }
    };
    displayMail = (currentMail, folderMails, noContentFoundMessage) => {
        return (<div className="module-box-column">
            {currentMail === null ?
                folderMails.length === 0 ?
                    <div className="d-flex align-items-center justify-content-center"
                        style={{ height: this.props.width >= 1200 ? 'calc(100vh - 259px)' : 'calc(100vh - 238px)' }}>
                        {noContentFoundMessage}
                    </div>
                    :
                    <MailList mails={folderMails} onStartSelect={this.onStartSelect.bind(this)}
                        onMailSelect={this.onMailSelect.bind(this)}
                        width={this.props.width}
                        onMailChecked={this.onMailChecked.bind(this)} />
                :
                <MailDetail mail={currentMail} onStartSelect={this.onStartSelect.bind(this)}
                    width={this.props.width}
                    onImportantSelect={this.onImportantSelect.bind(this)} />}
        </div>)
    };
    getMailActions = () => {
        return <div>
            <IconButton onClick={this.onFolderSelect.bind(this)} className="icon-btn">
                <FolderIcon />
            </IconButton>

            <IconButton onClick={this.onDeleteMail.bind(this)} className="icon-btn">
                <DeleteIcon />
            </IconButton>

            <IconButton onClick={this.onLabelSelect.bind(this)} className="icon-btn">
                <LabelIcon />
            </IconButton>

        </div>
    };



    constructor(props: MailProps) {
        super(props); // Pass props to the super constructor
        this.state = {
            searchMail: '',
            noContentFoundMessage: 'No mail found in selected folder',
            alertMessage: '',
            showMessage: false,
            drawerState: false,
            optionName: 'None',
            anchorEl: null,
            allMail: mails,
            loader: true,
            currentMail: null,
            user: {
                name: 'Robert Johnson',
                email: 'robert@example.com',
                avatar: 'https://via.placeholder.com/150x150'
            },
            selectedMails: 0,
            selectedFolder: 0,
            composeMail: false,
            labelMenuState: false,
            folderMenuState: false,
            optionMenuState: false,
            folderMails: mails.filter(mail => mail.folder === 0)
        }
    }

    componentDidMount() {
        setTimeout(() => {
            this.setState({ loader: false });
        }, 1500);
    }

    onMailChecked(data) {
        data.selected = !data.selected;
        let selectedMail = 0;
        const mails = this.state.folderMails.map(mail => {
            if (mail.selected) {
                selectedMail++;
            }
            if (mail.id === data.id) {
                if (mail.selected) {
                    selectedMail++;
                }
                return data;
            } else {
                return mail;
            }
        }
        );
        this.setState({
            selectedMails: selectedMail,
            folderMails: mails
        });
    }

    onAllMailSelect() {
        const selectAll = this.state.selectedMails <= this.state.folderMails.length;
        if (selectAll) {
            this.getAllMail();
        } else {
            this.getUnselectedAllMail();
        }
    }

    removeLabel(mail, label) {
        mail.labels.splice(mail.labels.indexOf(label), 1);
        if (this.state.currentMail !== null && mail.id === this.state.currentMail.id) {
            this.setState({
                currentMail: { ...mail, labels: mail.labels }
            })
        }
        return mail.labels;
    }

    onStartSelect(data) {
        data.starred = !data.starred;
        this.setState({
            alertMessage: data.starred ? 'Mail Mark as Star' : 'Mail Remove as Star',
            showMessage: true,
            folderMails: this.state.folderMails.map(mail =>
                mail.id === data.id ?
                    data : mail
            )
        });
    }

    onImportantSelect(data) {
        data.important = !data.important;
        this.setState({
            alertMessage: data.important ? 'Mail Mark as Important' : 'Mail Remove as Important',
            showMessage: true,
            folderMails: this.state.folderMails.map(mail =>
                mail.id === data.id ?
                    data : mail
            )
        });
    }

    onMailSend(data) {
        this.setState(
            {
                alertMessage: 'Mail Sent Successfully',
                showMessage: true,
                folderMails: this.state.allMail.concat(data),
                allMail: this.state.allMail.concat(data)
            }
        );
    }

    onMailSelect(mail) {
        this.setState({
            loader: true,
            currentMail: mail,
        });
        setTimeout(() => {
            this.setState({ loader: false });
        }, 1500);
    }

    addLabel(mail, label) {
        if (this.state.currentMail !== null && mail.id === this.state.currentMail.id) {
            this.setState({
                currentMail: { ...mail, labels: mail.labels.concat(label) }
            })
        }
        return mail.labels.concat(label)
    }

    updateSearch(evt) {
        this.setState({
            searchMail: evt.target.value,
        });
        this.searchMail(evt.target.value)
    }

    onToggleDrawer() {
        this.setState({
            drawerState: !this.state.drawerState
        });
    }

    render() {
        const { selectedMails, loader, currentMail, folderMails, composeMail, user, alertMessage, showMessage, noContentFoundMessage } = this.state;
        return (

            <div className="app-wrapper">
                <div className="animated slideInUpTiny animation-duration-3">
                    <div className="app-module">

                        <div className="d-block d-xl-none">
                            <Drawer
                                open={this.state.drawerState}
                                onClose={this.onToggleDrawer.bind(this)}>
                                {this.MailSideBar()}
                            </Drawer>
                        </div>
                        <div className="app-module-sidenav d-none d-xl-flex">
                            {this.MailSideBar()}
                        </div>

                        <div className="module-box">

                            <div className="module-box-header">

                                <IconButton className="drawer-btn d-block d-xl-none" aria-label="Menu"
                                    onClick={this.onToggleDrawer.bind(this)}>
                                    <i className="zmdi zmdi-menu" />
                                </IconButton>

                            </div>

                            <div className="module-box-content">
                                <div className="module-box-topbar">
                                    {this.state.currentMail === null ?
                                        <div className="d-flex">
                                            <Checkbox color="primary"
                                                indeterminate={selectedMails > 0 && selectedMails < folderMails.length}
                                                checked={selectedMails > 0}
                                                onChange={this.onAllMailSelect.bind(this)}
                                                value="SelectMail" />
                                            <div className="d-flex align-items-center" onClick={this.onOptionMenuSelect.bind(this)}>
                                                <span className="px-2"> {this.state.optionName}</span>
                                                <IconButton className="icon-btn-sm">
                                                    <MoreVertSharpIcon />
                                                </IconButton>
                                            </div>
                                        </div>
                                        :
                                        <IconButton className="icon-btn"
                                            onClick={() => {
                                                this.setState({
                                                    currentMail: null
                                                })
                                            }}>
                                            <ArrowBackIosNewSharpIcon />
                                        </IconButton>
                                    }

                                    {(selectedMails > 0) && this.getMailActions()}


                                    <Menu id="option-menu"
                                        anchorEl={this.state.anchorEl}
                                        open={this.state.optionMenuState}
                                        onClose={this.handleRequestClose}
                                        MenuListProps={{
                                            style: {
                                                width: 150,
                                            },
                                        }}>
                                        {options.map(option =>
                                            <MenuItem key={option.title}
                                                onClick={this.onOptionMenuItemSelect.bind(this, option)}>
                                                {option.title}
                                            </MenuItem>,
                                        )}
                                    </Menu>

                                    <Menu id="folder-menu"
                                        anchorEl={this.state.anchorEl}
                                        open={this.state.folderMenuState}
                                        onClose={this.handleRequestClose}
                                        MenuListProps={{
                                            style: {
                                                width: 150,
                                            },
                                        }}>
                                        {folders.map(folder =>
                                            <MenuItem key={folder.id}
                                                onClick={this.onFolderMenuItemSelect.bind(this, folder.id)}>
                                                {folder.title}
                                            </MenuItem>,
                                        )}
                                    </Menu>
                                    <Menu id="label-menu"
                                        anchorEl={this.state.anchorEl}
                                        open={this.state.labelMenuState}
                                        onClose={this.handleRequestClose}
                                        MenuListProps={{
                                            style: {
                                                width: 150,
                                            },
                                        }}>
                                        {labels.map(label =>
                                            <MenuItem key={label.id}
                                                onClick={this.onLabelMenuItemSelect.bind(this, label)}>
                                                {label.title}
                                            </MenuItem>,
                                        )}
                                    </Menu>
                                </div>

                                {loader ?
                                    <div className="loader-view"
                                        style={{ height: this.props.width >= 1200 ? 'calc(100vh - 259px)' : 'calc(100vh - 238px)' }}>
                                        <CircularProgress />
                                    </div> : this.displayMail(currentMail, folderMails, noContentFoundMessage)}

                                <ComposeMail
                                    open={composeMail}
                                    user={this.state.user}
                                    onClose={this.handleRequestClose.bind(this)}
                                    onMailSend={this.onMailSend.bind(this)}
                                />

                            </div>
                        </div>
                    </div>
                    <Snackbar
                        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                        open={showMessage}
                        autoHideDuration={3000}
                        onClose={this.handleRequestClose}
                        ContentProps={{
                            'aria-describedby': 'message-id',
                        }}
                        message={<span id="message-id">{alertMessage}</span>}
                    />
                </div>
            </div>
        )
    }
}

export default MailFull;