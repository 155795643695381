import React from 'react';

import CampaignParent from './0-CampaignParent/CampaignParent';

// import WarmupPageTopMenu from '../WarmupV2/warmupTopMenu';



function CampaignsPage() {


    return (
        <>
            {/* <WarmupPageTopMenu /> */}
            <CampaignParent />

        </>
    );
}

export default CampaignsPage;
