import React, { useState, useEffect } from 'react';
import { Stage, Layer, Text, Rect, Circle, Line, Image, Transformer } from 'react-konva';
import axios from 'axios';
import { Button, Slider, Dialog, TextField, TextareaAutosize, Box, Paper } from '@mui/material';
import useImage from 'use-image';
import Konva from 'konva';

interface GuideCandidate {
  lineGuide: number;
  diff: number;
  snap: 'start' | 'center' | 'end'; // Adjust as needed
  offset: number;
}

interface Guide {
  lineGuide: number;
  offset: number;
  orientation: 'V' | 'H';
  snap: 'start' | 'center' | 'end';
}

interface Element {
  type: string;
  text?: string;
  x?: number;
  y?: number;
  width?: number;
  height?: number;
  radius?: number;
  fontSize?: number;
  draggable: boolean;
  color?: string; // Add color property
  startX?: number; // Add startX for lines
  startY?: number; // Add startY for lines
  endX?: number;   // Add endX for lines
  endY?: number;   // Add endY for lines
  ref?: React.RefObject<any>;
  scaleX?: number;
  scaleY?: number;
  rotation?: number;

  // ... other necessary properties
}

const ImageEditor = () => {
  const [imageSrc, setImageSrc] = useState<string | null>(null);
  const [image] = useImage(imageSrc);
  const [elements, setElements] = useState<Element[]>([]);
  const [canvasSize, setCanvasSize] = useState({ width: 500, height: 500 }); // default size
  const [selectedId, setSelectedId] = useState<number | null>(null);
  const [editingTextIndex, setEditingTextIndex] = useState<number | null>(null);
  const [textAreaPosition, setTextAreaPosition] = useState({ x: 0, y: 0 });
  const [showGuides, setShowGuides] = useState(true);
  const [showGrid, setShowGrid] = useState(false);
  const [selectedImage, setSelectedImage] = useState<File | null>(null);

  // Function to update element properties
  const updateElement = (index, newProps) => {
    setElements(prevElements => prevElements.map((el, idx) =>
      idx === index ? { ...el, ...newProps } : el
    ));
  };


  const onTransform = (index, node) => {
    const scaleX = node.scaleX();
    const scaleY = node.scaleY();
    node.scaleX(1);
    node.scaleY(1);

    let newProps: Partial<Element> = {
      x: node.x(),
      y: node.y(),
      scaleX,
      scaleY,
      rotation: node.rotation(),
    };

    if (elements[index].type === 'rectangle' || elements[index].type === 'circle') {
      newProps.width = node.width() * scaleX;
      newProps.height = node.height() * scaleY;
    }

    updateElement(index, newProps);
  };



  // Function to handle image upload
  const handleImageUpload = (event) => {
    const file = event.target.files[0]
    const reader = new FileReader()
    setSelectedImage(file);
    reader.onloadend = () => {
      if (typeof reader.result === 'string') {
        setImageSrc(reader.result);
      }
    };

    reader.readAsDataURL(file)
  }

  useEffect(() => {
    if (image) {
      // Fit the image within the canvas size while maintaining aspect ratio
      const ratio = Math.min(canvasSize.width / image.width, canvasSize.height / image.height);
      setCanvasSize({ width: image.width * ratio, height: image.height * ratio });
    }
  }, [image, canvasSize.width, canvasSize.height]);
  // Add onSelect and onTransform handlers for each element

  const elementProps = (index) => ({
    onClick: () => setSelectedId(index),
    onTransform: (e) => onTransform(index, e.target),
    draggable: true,
    onDragMove: (e) => {
      const node = e.target;
      const stage = node.getStage();
      const layer = node.getLayer();
      layer.find('.guid-line').forEach((l) => l.destroy());

      const lineGuideStops = getLineGuideStops(node, elements, stage);
      const itemBounds = getObjectSnappingEdges(node);
      const guides: Guide[] = getGuides(lineGuideStops, itemBounds);


      if (guides.length > 0) {
        drawGuides(guides, layer);
      }

      const absPos = node.absolutePosition();
      guides.forEach((lg) => {
        if (lg.orientation === 'V') {
          absPos.x = lg.lineGuide + lg.offset;
        } else if (lg.orientation === 'H') {
          absPos.y = lg.lineGuide + lg
            .offset;
        }
      });

      node.absolutePosition(absPos);
      updateElement(index, { x: absPos.x, y: absPos.y }); // This should update the state
    },


    onDragEnd: (e) => {
      e.target.getLayer().find('.guid-line').forEach((l) => l.destroy());
    },

  });


  // Function to render the Transformer for selected element
  const renderTransformer = () => {
    if (selectedId === null) return null;
    return (
      <Transformer
      // Configure Transformer based on selected element type
      />
    );
  };



  // Function to add text
  const addText = () => {
    const newText = {
      type: 'text',
      text: 'Sample Text',
      x: 50,
      y: 50,
      fontSize: 30,
      draggable: true,
      ref: React.createRef(),
    };
    setElements([...elements, newText]);
  };



  // Function to add Rectangle
  const addRectangle = () => {
    const newRect: Element = {
      type: 'rectangle',
      x: 50,
      y: 50,
      width: 100,
      height: 100,
      draggable: true,
      color: 'red', // Example color
    };
    setElements([...elements, newRect]);
  };

  // Function to add Circle
  const addCircle = () => {
    const newCircle = {
      type: 'circle',
      x: 100,
      y: 100,
      radius: 50,
      draggable: true,
    };
    setElements([...elements, newCircle]);
  };

  // Function to add Line
  const addLine = () => {
    const newLine: Element = {
      type: 'line',
      startX: 100,
      startY: 100,
      endX: 200,
      endY: 200,
      draggable: true,
      color: 'blue', // Example color
      width: 5, // Example width
    };
    setElements([...elements, newLine]);
  };


  // Function to send data to backend
  const processImage = async () => {
    try {
      const formData = new FormData();
      formData.append('elements', JSON.stringify(elements));
      if (selectedImage) {
        formData.append('image', selectedImage);
      }
      console.log("image: ", selectedImage);

      const response = await axios.post(`${process.env.REACT_APP_API_URL}/imageeditor/process`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })

      console.log(response.data)
      // Handle the response accordingly
    } catch (error) {
      console.error('Error processing image:', error)
    }
  }


  // guides logic: 

  const GUIDELINE_OFFSET = 5;

  function getLineGuideStops(skipShape, elements, stage) {
    const vertical = [0, stage.width() / 2, stage.width()];
    const horizontal = [0, stage.height() / 2, stage.height()];

    elements.forEach((el) => {
      if (el === skipShape) {
        return;
      }
      const { x, y, width, height } = el;
      vertical.push(x, x + width, x + width / 2);
      horizontal.push(y, y + height, y + height / 2);
    });

    return { vertical, horizontal };
  }

  function getObjectSnappingEdges(node) {
    const box = node.getClientRect();
    const absPos = node.absolutePosition();

    return {
      vertical: [
        {
          guide: Math.round(box.x),
          offset: Math.round(absPos.x - box.x),
          snap: 'start',
        },
        {
          guide: Math.round(box.x + box.width / 2),
          offset: Math.round(absPos.x - box.x - box.width / 2),
          snap: 'center',
        },
        {
          guide: Math.round(box.x + box.width),
          offset: Math.round(absPos.x - box.x - box.width),
          snap: 'end',
        },
      ],
      horizontal: [
        {
          guide: Math.round(box.y),
          offset: Math.round(absPos.y - box.y),
          snap: 'start',
        },
        {
          guide: Math.round(box.y + box.height / 2),
          offset: Math.round(absPos.y - box.y - box.height / 2),
          snap: 'center',
        },
        {
          guide: Math.round(box.y + box.height),
          offset: Math.round(absPos.y - box.y - box.height),
          snap: 'end',
        },
      ],
    };
  }

  function getGuides(lineGuideStops: { vertical: number[]; horizontal: number[]; }, itemBounds: any): Guide[] {
    let resultV: GuideCandidate[] = [];
    let resultH: GuideCandidate[] = [];
    let guides: Guide[] = []; // Explicitly declare guides as an array of Guide

    lineGuideStops.vertical.forEach((lineGuide) => {
      itemBounds.vertical.forEach((itemBound) => {
        const diff = Math.abs(lineGuide - itemBound.guide);
        if (diff < GUIDELINE_OFFSET) {
          resultV.push({ lineGuide, diff, snap: itemBound.snap, offset: itemBound.offset });
        }
      });
    });

    lineGuideStops.horizontal.forEach((lineGuide) => {
      itemBounds.horizontal.forEach((itemBound) => {
        const diff = Math.abs(lineGuide - itemBound.guide);
        if (diff < GUIDELINE_OFFSET) {
          resultH.push({ lineGuide, diff, snap: itemBound.snap, offset: itemBound.offset });
        }
      });
    });

    const minV = resultV.sort((a, b) => a.diff - b.diff)[0];
    const minH = resultH.sort((a, b) => a.diff - b.diff)[0];
    if (minV) {
      guides.push({ lineGuide: minV.lineGuide, offset: minV.offset, orientation: 'V', snap: minV.snap });
    }
    if (minH) {
      guides.push({ lineGuide: minH.lineGuide, offset: minH.offset, orientation: 'H', snap: minH.snap });
    }
    return guides;
  }


  function drawGuides(guides: Guide[], layer: any) {

    console.log("Drawing guides:", guides); // Debugging
    guides.forEach((lg) => {
      const line = new Konva.Line({
        points: lg.orientation === 'V' ? [0, -6000, 0, 6000] : [-6000, 0, 6000, 0],
        stroke: 'rgb(0, 161, 255)',
        strokeWidth: 1,
        name: 'guid-line',
        dash: [4, 6],
      });

      line.absolutePosition({
        x: lg.orientation === 'V' ? lg.lineGuide : 0,
        y: lg.orientation === 'H' ? lg.lineGuide : 0,
      });

      layer.add(line);
      line.moveToTop(); // Move line to top
    });

    layer.draw();
  }



  return (
    <div>
      <input type='file' onChange={handleImageUpload} accept='image/*' />
      <Button onClick={addText}>Add Text</Button>
      <Button onClick={addRectangle}>Add Rectangle</Button>
      <Button onClick={addCircle}>Add Circle</Button>
      <Button onClick={addLine}>Add Line</Button>
      <Button onClick={processImage}>Process Image</Button>
      <Box >
        <Paper elevation={20}>
          <Stage width={canvasSize.width} height={canvasSize.height}>
            <Layer>
              {image && <Image image={image} />}
              {elements.map((element, index) => {
                const isSelected = index === selectedId;
                let elementNode: React.ReactNode = null;

                switch (element.type) {
                  case 'text':
                    elementNode = (
                      <Text
                        key={index}
                        text={element.text}
                        fontSize={element.fontSize}
                        x={element.x}
                        y={element.y}
                        className={`shape-${index}`}
                        ref={element.ref}
                        onDblClick={() => {
                          if (element.ref && element.ref.current) {
                            const textNode = element.ref.current;
                            const stage = textNode.getStage();
                            const textPosition = textNode.getAbsolutePosition();

                            setEditingTextIndex(index);
                            setTextAreaPosition({
                              x: stage.container().getBoundingClientRect().left + textPosition.x,
                              y: stage.container().getBoundingClientRect().top + textPosition.y,
                            });
                          }
                        }}
                        {...elementProps(index)} // Applying elementProps here





                      // Add other necessary props like fill, fontFamily, etc.
                      />


                    );
                    break;

                  case 'rectangle':
                    elementNode = (
                      <Rect
                        key={index}
                        x={element.x}
                        y={element.y}
                        width={element.width}
                        height={element.height}
                        fill={element.color} // Make sure to set color and other style properties
                        {...elementProps(index)} // Applying elementProps here
                        draggable
                        onClick={() => setSelectedId(index)}
                        className={`shape-${index}`}
                      />
                    );
                    break;

                  case 'circle':
                    elementNode = (
                      <Circle
                        key={index}
                        x={element.x}
                        y={element.y}
                        radius={element.radius}
                        fill={element.color} // Set the color and other styles
                        className={`shape-${index}`}
                        {...elementProps(index)} // Applying elementProps here
                      />
                    );
                    break;

                  case 'line':
                    elementNode = (
                      <Line
                        key={index}
                        points={[
                          element.startX || 0,
                          element.startY || 0,
                          element.endX || 0,
                          element.endY || 0
                        ]}
                        stroke={element.color}
                        strokeWidth={element.width} // Use 'width' for the line's stroke width
                        className={`shape-${index}`}
                        {...elementProps(index)} // Applying elementProps here

                      />
                    );
                    break;

                  default:
                    break;
                }

                return isSelected ? [elementNode, renderTransformer()].filter(Boolean) : elementNode;
              })}

            </Layer>
          </Stage>

        </Paper>

      </Box>


      {editingTextIndex !== null && (
        <TextField
          multiline
          style={{
            position: 'absolute',
            top: textAreaPosition.y + 'px',
            left: textAreaPosition.x + 'px',
            width: elements[editingTextIndex]?.width || 'auto',
            fontSize: elements[editingTextIndex]?.fontSize + 'px',
            fontFamily: 'Your-Font-Family', // Match this with your canvas text font family
            lineHeight: 'normal',
            color: 'transparent',
            backgroundColor: 'transparent',
            border: 'none',
            padding: '0',
            margin: '0',
            outline: 'none',
            overflow: 'hidden'
          }}
          value={elements[editingTextIndex]?.text || ''}
          onChange={(e) => {
            const updatedElements = [...elements];
            updatedElements[editingTextIndex] = {
              ...updatedElements[editingTextIndex],
              text: e.target.value,
            };
            setElements(updatedElements);
          }}
          onBlur={() => {
            setEditingTextIndex(null);
          }}
          autoFocus
        />
      )}

    </div>

  );

};

export default ImageEditor;


