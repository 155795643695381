import { Box, LinearProgress, Typography } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react';


export interface Limits {
    emailCredits: number;
    smsCredits: number;
    actionCredits: number;
    enrichmentCredits: number;
    timeZoneCredits: number;
    leadReveals: number;
    scrapingLimit: number;
    connectedEmailAccountsLimit: number;
    connectedPhoneNumbersLimit: number;
}

export interface Usage {
    emailCreditsUsage: number;
    smsCreditsUsage: number;
    actionCreditsUsage: number;
    enrichmentCreditsUsage: number;
    timeZoneCreditsUsage: number;
    leadRevealsUsage: number;
    scrapingLimitUsage: number;
    connectedEmailAccountsLimitUsage: number;
    connectedPhoneNumbersLimitUsage: number;
}

export interface IUserUsageData {
    limits: Limits;
    usage: Usage;
}

const UserUsageStatistics: React.FC = () => {
    const [usageData, setUsageData] = useState<IUserUsageData | null>(null);

    useEffect(() => {
        const fetchUsageData = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/account/usage`);
                setUsageData(response.data);
            } catch (error) {
                console.error('Error fetching usage data:', error);
            }
        };

        fetchUsageData();
    }, []);

    const handleUpgrade = async (planName: 'professional' | 'master') => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/subscriptions/stripe/create-checkout-session`, { planName });
            console.log(response.data); // Handle the response according to your needs
            const { url } = response.data;
            // Open the URL in a new tab
            window.open(url, '_blank');

        } catch (error) {
            console.error(`Error upgrading to ${planName}:`, error);
        }
    };


    return (
        <Box sx={{ mb: 5, mbXl: 10 }}>
            {usageData && (
                <Box sx={{ pt: 9, pb: 0 }}>
                    <Box sx={{ mb: 2 }}>
                        <Typography variant='h4' sx={{ color: 'text.primary', '&:hover': { color: 'primary.main' }, fontSize: '2rem', fontWeight: 'bold', marginRight: '0.5rem' }}>
                            Usage Statistics
                        </Typography>
                    </Box>
                    <Box sx={{ mb: 5, mbXl: 10 }}>
                        {usageData && (
                            <Box sx={{ pt: 9, pb: 0 }}>
                                {/* Iterate through each limit and corresponding usage */}
                                {Object.keys(usageData.limits).map((key) => {
                                    const limit = usageData.limits[key];
                                    const usage = usageData.usage[key + 'Usage'];
                                    return (
                                        <Box sx={{ mb: 2 }} key={key}>
                                            <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>{key.replace(/([A-Z])/g, ' $1').trim()}:</Typography>
                                            <LinearProgress color='info' sx={{ height: "10px", padding: "10px" }} variant="determinate" value={(usage / limit) * 100} />
                                            <Typography variant="body2" sx={{ mt: 1 }}>
                                                {usage} out of {limit} used
                                            </Typography>
                                        </Box>
                                    );
                                })}
                                {/* <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 4 }}>
                                    <Button variant="contained" color="primary" onClick={() => handleUpgrade('professional')}>
                                        Upgrade to Professional
                                    </Button>
                                    <Button variant="contained" color="secondary" onClick={() => handleUpgrade('master')}>
                                        Upgrade to Master
                                    </Button>
                                </Box> */}
                            </Box>
                        )}
                    </Box>

                </Box>
            )}
        </Box>
    );
};

export { UserUsageStatistics };
