import React from 'react';

const Messagesforreply: React.FC = () => {
    // ... your contacts code here ...

    return (
        <div>     {/*begin::Message accordion*/}
            <div data-kt-inbox-message="message_wrapper">
                {/*begin::Message header*/}
                <div
                    className="d-flex flex-wrap gap-2 flex-stack cursor-pointer"
                    data-kt-inbox-message="header"
                >
                    {/*begin::Author*/}
                    <div className="d-flex align-items-center">
                        {/*begin::Avatar*/}
                        <div className="symbol symbol-50 me-4">
                            <span
                                className="symbol-label"
                                style={{
                                    backgroundImage:
                                        "url(assets/media/avatars/300-6.jpg)"
                                }}
                            />
                        </div>
                        {/*end::Avatar*/}
                        <div className="pe-5">
                            {/*begin::Author details*/}
                            <div className="d-flex align-items-center flex-wrap gap-1">
                                <a
                                    href="#"
                                    className="fw-bold text-dark text-hover-primary"
                                >
                                    Emma Smith
                                </a>
                                <i className="ki-duotone ki-abstract-8 fs-7 text-success mx-3">
                                    <span className="path1" />
                                    <span className="path2" />
                                </i>
                                <span className="text-muted fw-bold">
                                    1 day ago
                                </span>
                            </div>
                            {/*end::Author details*/}
                            {/*begin::Message details*/}
                            <div data-kt-inbox-message="details">
                                <span className="text-muted fw-semibold">
                                    to me
                                </span>
                                {/*begin::Menu toggle*/}
                                <a
                                    href="#"
                                    className="me-1"
                                    data-kt-menu-trigger="click"
                                    data-kt-menu-placement="bottom-start"
                                >
                                    <i className="ki-duotone ki-down fs-5 m-0" />
                                </a>
                                {/*end::Menu toggle*/}
                                {/*begin::Menu*/}
                                <div
                                    className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-300px p-4"
                                    data-kt-menu="true"
                                >
                                    {/*begin::Table*/}
                                    <table className="table mb-0">
                                        <tbody>
                                            <tr>
                                                <td className="w-75px text-muted">
                                                    From
                                                </td>
                                                <td>Emma Bold</td>
                                            </tr>
                                            <tr>
                                                <td className="text-muted">Date</td>
                                                <td>24 Jun 2023, 11:30 am</td>
                                            </tr>
                                            <tr>
                                                <td className="text-muted">Subject</td>
                                                <td>
                                                    Trip Reminder. Thank you for flying with
                                                    us!
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="text-muted">Reply-to</td>
                                                <td>emma@intenso.com</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                {/*end::Menu*/}
                            </div>
                            {/*end::Message details*/}
                            {/*begin::Preview message*/}
                            <div
                                className="text-muted fw-semibold mw-450px d-none"
                                data-kt-inbox-message="preview"
                            >
                                With resrpect, i must disagree with Mr.Zinsser. We
                                all know the most part of important part....
                            </div>
                            {/*end::Preview message*/}
                        </div>
                    </div>
                    {/*end::Author*/}
                    {/*begin::Actions*/}
                    <div className="d-flex align-items-center flex-wrap gap-2">
                        {/*begin::Date*/}
                        <span className="fw-semibold text-muted text-end me-3">
                            25 Jul 2023, 9:23 pm
                        </span>
                        {/*end::Date*/}
                        <div className="d-flex">
                            {/*begin::Star*/}
                            <a
                                href="#"
                                className="btn btn-sm btn-icon btn-clear btn-active-light-primary me-3"
                                data-bs-toggle="tooltip"
                                data-bs-placement="top"
                                title="Star"
                            >
                                <i className="ki-duotone ki-star fs-2 m-0" />
                            </a>
                            {/*end::Star*/}
                            {/*begin::Mark as important*/}
                            <a
                                href="#"
                                className="btn btn-sm btn-icon btn-clear btn-active-light-primary me-3"
                                data-bs-toggle="tooltip"
                                data-bs-placement="top"
                                title="Mark as important"
                            >
                                <i className="ki-duotone ki-save-2 fs-2 m-0">
                                    <span className="path1" />
                                    <span className="path2" />
                                </i>
                            </a>
                            {/*end::Mark as important*/}
                            {/*begin::Reply*/}
                            <a
                                href="#"
                                className="btn btn-sm btn-icon btn-clear btn-active-light-primary me-3"
                                data-bs-toggle="tooltip"
                                data-bs-placement="top"
                                title="Reply"
                            >
                                <i className="ki-duotone ki-message-edit fs-2 m-0">
                                    <span className="path1" />
                                    <span className="path2" />
                                </i>
                            </a>
                            {/*end::Reply*/}
                            {/*begin::Settings*/}
                            <a
                                href="#"
                                className="btn btn-sm btn-icon btn-clear btn-active-light-primary"
                                data-bs-toggle="tooltip"
                                data-bs-placement="top"
                                title="Settings"
                            >
                                <i className="ki-duotone ki-dots-square-vertical fs-2 m-0">
                                    <span className="path1" />
                                    <span className="path2" />
                                    <span className="path3" />
                                    <span className="path4" />
                                </i>
                            </a>
                            {/*end::Settings*/}
                        </div>
                    </div>
                    {/*end::Actions*/}
                </div>
                {/*end::Message header*/}
                {/*begin::Message content*/}
                <div
                    className="collapse fade show"
                    data-kt-inbox-message="message"
                >
                    <div className="py-5">
                        <p>Hi Bob,</p>
                        <p>
                            With resrpect, i must disagree with Mr.Zinsser. We
                            all know the most part of important part of any
                            article is the title.Without a compelleing title,
                            your reader won't even get to the first
                            sentence.After the title, however, the first few
                            sentences of your article are certainly the most
                            important part.
                        </p>
                        <p>
                            Jornalists call this critical, introductory section
                            the "Lede," and when bridge properly executed, it's
                            the that carries your reader from an headine try at
                            attention-grabbing to the body of your blog post, if
                            you want to get it right on of these 10 clever ways
                            to omen your next blog posr with a bang
                        </p>
                        <p>Best regards,</p>
                        <p className="mb-0">Jason Muller</p>
                    </div>
                </div>
                {/*end::Message content*/}
            </div>
            {/*end::Message accordion*/}
            <div className="separator my-6" />
            {/*begin::Message accordion*/}
            <div data-kt-inbox-message="message_wrapper">
                {/*begin::Message header*/}
                <div
                    className="d-flex flex-wrap gap-2 flex-stack cursor-pointer"
                    data-kt-inbox-message="header"
                >
                    {/*begin::Author*/}
                    <div className="d-flex align-items-center">
                        {/*begin::Avatar*/}
                        <div className="symbol symbol-50 me-4">
                            <span
                                className="symbol-label"
                                style={{
                                    backgroundImage:
                                        "url(assets/media/avatars/300-5.jpg)"
                                }}
                            />
                        </div>
                        {/*end::Avatar*/}
                        <div className="pe-5">
                            {/*begin::Author details*/}
                            <div className="d-flex align-items-center flex-wrap gap-1">
                                <a
                                    href="#"
                                    className="fw-bold text-dark text-hover-primary"
                                >
                                    Sean Bean
                                </a>
                                <i className="ki-duotone ki-abstract-8 fs-7 text-success mx-3">
                                    <span className="path1" />
                                    <span className="path2" />
                                </i>
                                <span className="text-muted fw-bold">
                                    3 days ago
                                </span>
                            </div>
                            {/*end::Author details*/}
                            {/*begin::Message details*/}
                            <div
                                className="d-none"
                                data-kt-inbox-message="details"
                            >
                                <span className="text-muted fw-semibold">
                                    to me
                                </span>
                                {/*begin::Menu toggle*/}
                                <a
                                    href="#"
                                    className="me-1"
                                    data-kt-menu-trigger="click"
                                    data-kt-menu-placement="bottom-start"
                                >
                                    <i className="ki-duotone ki-down fs-5 m-0" />
                                </a>
                                {/*end::Menu toggle*/}
                                {/*begin::Menu*/}
                                <div
                                    className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-300px p-4"
                                    data-kt-menu="true"
                                >
                                    {/*begin::Table*/}
                                    <table className="table mb-0">
                                        <tbody>
                                            <tr>
                                                <td className="w-75px text-muted">
                                                    From
                                                </td>
                                                <td>Emma Bold</td>
                                            </tr>
                                            <tr>
                                                <td className="text-muted">Date</td>
                                                <td>20 Dec 2023, 6:05 pm</td>
                                            </tr>
                                            <tr>
                                                <td className="text-muted">Subject</td>
                                                <td>
                                                    Trip Reminder. Thank you for flying with
                                                    us!
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="text-muted">Reply-to</td>
                                                <td>emma@intenso.com</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                {/*end::Menu*/}
                            </div>
                            {/*end::Message details*/}
                            {/*begin::Preview message*/}
                            <div
                                className="text-muted fw-semibold mw-450px"
                                data-kt-inbox-message="preview"
                            >
                                Jornalists call this critical, introductory
                                section the "Lede," and when bridge properly
                                executed....
                            </div>
                            {/*end::Preview message*/}
                        </div>
                    </div>
                    {/*end::Author*/}
                    {/*begin::Actions*/}
                    <div className="d-flex align-items-center flex-wrap gap-2">
                        {/*begin::Date*/}
                        <span className="fw-semibold text-muted text-end me-3">
                            25 Oct 2023, 11:30 am
                        </span>
                        {/*end::Date*/}
                        <div className="d-flex">
                            {/*begin::Star*/}
                            <a
                                href="#"
                                className="btn btn-sm btn-icon btn-clear btn-active-light-primary me-3"
                                data-bs-toggle="tooltip"
                                data-bs-placement="top"
                                title="Star"
                            >
                                <i className="ki-duotone ki-star fs-2 m-0" />
                            </a>
                            {/*end::Star*/}
                            {/*begin::Mark as important*/}
                            <a
                                href="#"
                                className="btn btn-sm btn-icon btn-clear btn-active-light-primary me-3"
                                data-bs-toggle="tooltip"
                                data-bs-placement="top"
                                title="Mark as important"
                            >
                                <i className="ki-duotone ki-save-2 fs-2 m-0">
                                    <span className="path1" />
                                    <span className="path2" />
                                </i>
                            </a>
                            {/*end::Mark as important*/}
                            {/*begin::Reply*/}
                            <a
                                href="#"
                                className="btn btn-sm btn-icon btn-clear btn-active-light-primary me-3"
                                data-bs-toggle="tooltip"
                                data-bs-placement="top"
                                title="Reply"
                            >
                                <i className="ki-duotone ki-message-edit fs-2 m-0">
                                    <span className="path1" />
                                    <span className="path2" />
                                </i>
                            </a>
                            {/*end::Reply*/}
                            {/*begin::Settings*/}
                            <a
                                href="#"
                                className="btn btn-sm btn-icon btn-clear btn-active-light-primary"
                                data-bs-toggle="tooltip"
                                data-bs-placement="top"
                                title="Settings"
                            >
                                <i className="ki-duotone ki-dots-square-vertical fs-2 m-0">
                                    <span className="path1" />
                                    <span className="path2" />
                                    <span className="path3" />
                                    <span className="path4" />
                                </i>
                            </a>
                            {/*end::Settings*/}
                        </div>
                    </div>
                    {/*end::Actions*/}
                </div>
                {/*end::Message header*/}
            </div>
            {/*end::Message accordion*/}
        </div>
    );
};

export default Messagesforreply;
