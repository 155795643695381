// src\app\pages\0-Account\EmailAccounts\EmailConnection\ConnectionForm\EmailConnectionModal.tsx
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Snackbar } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import FormControlLabel from '@mui/material/FormControlLabel';
import IconButton from '@mui/material/IconButton';
import Modal from '@mui/material/Modal';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Typography from '@mui/material/Typography';
import React from 'react';
import { FcGoogle } from 'react-icons/fc';
import { MdAlternateEmail } from 'react-icons/md';
import { SiGmail } from 'react-icons/si';

import GoogleWorkspaceForm from '../ConnectionMethods/GoogleWorkspaceForm';
import PersonalGmailForm from '../ConnectionMethods/PersonalGmailForm';
import SMTP_IMAPForm from '../ConnectionMethods/SMTP_IMAPForm';


// import MicrosoftOutlookForm from '../ConnectionMethods/Outlook-Office-360Form';
// import { TfiMicrosoftAlt } from "react-icons/tfi";

interface EmailConnectionModalProps {
    show: boolean;
    onHide: () => void;
    RefreshAccountList: () => void;
}

const EmailConnectionModal: React.FC<EmailConnectionModalProps> = ({
    show,
    onHide,
    RefreshAccountList
}) => {
    const [snackbarOpen, setSnackbarOpen] = React.useState(false);
    const [snackbarSeverity, setSnackbarSeverity] = React.useState<'success' | 'error'>('success');
    const [snackbarMessage, setSnackbarMessage] = React.useState('');


    const handleSnackbarOpen = (severity: 'success' | 'error', message: string) => {
        setSnackbarSeverity(severity);
        setSnackbarMessage(message);
        setSnackbarOpen(true);
    };

    const handleSnackbarClose = (_event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };


    const [selectedOption, setSelectedOption] = React.useState<string | null>(null);
    const [isConfirmDialogOpen, setIsConfirmDialogOpen] = React.useState(false);

    const handleOptionSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedOption(event.target.value);
    };

    const handleBackButtonClick = () => {
        setSelectedOption(null);
    };
    const modalStyle = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '80%',
        boxShadow: 24,
        p: 4,
    };
    const backdropStyle = {
        color: 'var(--bs-body-bg)',
        backgroundColor: 'var(--bs-body-bg)', // White background with 80% opacity
    };


    const handleDialogOpen = () => {
        setIsConfirmDialogOpen(true);
        RefreshAccountList();
    };

    const modifiedOnHide = () => {
        handleDialogOpen();
    };

    const handleConfirmClose = () => {
        setIsConfirmDialogOpen(false);
        RefreshAccountList();
        onHide(); // This calls the original onHide prop, closing the modal
    };

    const handleCancelClose = () => {
        setIsConfirmDialogOpen(false);
    };

    return (
        <>
            <Modal
                open={show}
                onClose={modifiedOnHide}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                BackdropComponent={Backdrop}
                BackdropProps={{
                    sx: backdropStyle
                }}
            >
                <Box sx={modalStyle}>


                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            mt: 2,
                        }}
                    >
                        {selectedOption ? (
                            <>
                                <IconButton onClick={handleBackButtonClick} color="primary">
                                    <ArrowBackIcon />
                                </IconButton>
                                {selectedOption === 'smtp_imap' && <SMTP_IMAPForm onSuccess={handleConfirmClose} onSnackbarOpen={handleSnackbarOpen} />}
                                {selectedOption === 'google_workspace' && <GoogleWorkspaceForm />}
                                {selectedOption === 'google_gmail' && <PersonalGmailForm />}
                                {/* {selectedOption === 'microsoft_outlook' && <MicrosoftOutlookForm />} */}

                            </>
                        ) : (
                            <RadioGroup
                                aria-labelledby="email-option-group-label"
                                name="email_options"
                                onChange={handleOptionSelect}
                            >
                                <FormControlLabel
                                    value="smtp_imap"
                                    control={<Radio />}
                                    label={
                                        <Box sx={{ display: 'flex', padding: "30px", alignItems: 'center' }}>
                                            <MdAlternateEmail style={{ fontSize: "40px", color: "#2B70E9" }} />
                                            <Box sx={{ paddingLeft: "30px", paddingRight: "30px", alignItems: 'center' }}>
                                                <Typography color="#2B70E9" variant="subtitle1" component="div">
                                                    SMTP + IMAP
                                                </Typography>
                                                <Typography variant="body2" color="text.secondary">
                                                    Connect via your email server.
                                                </Typography>
                                            </Box>
                                        </Box>
                                    }
                                />
                                <FormControlLabel
                                    value="google_workspace"
                                    control={<Radio />}
                                    label={
                                        <Box sx={{ display: 'flex', padding: "30px", alignItems: 'center' }}>
                                            <FcGoogle style={{ fontSize: "40px" }} />
                                            <Box sx={{ paddingLeft: "30px", paddingRight: "30px", alignItems: 'center' }}>
                                                <Typography variant="subtitle1" color="#ffc107" component="div">
                                                    Google Workspace
                                                </Typography>
                                                <Typography variant="body2" color="text.secondary">
                                                    Connect via Google Workspace.
                                                </Typography>
                                            </Box>
                                        </Box>
                                    }
                                />

                                <FormControlLabel
                                    value="google_gmail"
                                    control={<Radio />}
                                    label={
                                        <Box sx={{ display: 'flex', padding: "30px", alignItems: 'center' }}>
                                            <SiGmail style={{ fontSize: "40px", color: "#C71610", background: "#fff", borderRadius: "10px", padding: "5px" }} />
                                            <Box sx={{ paddingLeft: "30px", paddingRight: "30px", alignItems: 'center' }}>
                                                <Typography variant="subtitle1" color="#FF3d00" component="div">
                                                    Personal Gmail Account
                                                </Typography>
                                                <Typography variant="body2" color="text.secondary">
                                                    Connect via Gmail.
                                                </Typography>
                                            </Box>
                                        </Box>
                                    }
                                />
                                {/* <FormControlLabel
                                value="microsoft_outlook"
                                control={<Radio />}
                                label={
                                    <Box sx={{ display: 'flex', padding: "30px", alignItems: 'center' }}>
                                        <TfiMicrosoftAlt style={{ color: "#1976D2", fontSize: "40px" }} />
                                        <Box sx={{ paddingLeft: "30px", paddingRight: "30px", alignItems: 'center' }}>
                                            <Typography variant="subtitle1" component="div">
                                                Microsoft Outlook / Office 365
                                            </Typography>
                                            <Typography variant="body2" color="text.secondary">
                                                Connect Your Microsoft Email Account.
                                            </Typography>
                                        </Box>
                                    </Box>
                                }
                            /> */}



                            </RadioGroup>
                        )}
                    </Box>
                    <Dialog
                        open={isConfirmDialogOpen}
                        onClose={handleCancelClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                All changes will be lost if closed. Are you sure you want to close?
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleCancelClose}>Cancel</Button>
                            <Button onClick={handleConfirmClose} autoFocus>
                                Confirm
                            </Button>
                        </DialogActions>
                    </Dialog>
                </Box>
            </Modal>
            <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
                <MuiAlert elevation={6} variant="filled" onClose={handleSnackbarClose} severity={snackbarSeverity}>
                    {snackbarMessage}
                </MuiAlert>
            </Snackbar>

        </>
    );
};

export default EmailConnectionModal;

