// src\app\modules\CustomMaterialUiComponents\DraggableDialog.tsx
import './modalHandler.css';

import { CloseSharp, CropSquareSharp, FilterNoneSharp, MinimizeSharp, OpenInNewSharp } from '@mui/icons-material';
import WarningSharpIcon from '@mui/icons-material/WarningSharp';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    Typography,
} from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import Draggable from 'react-draggable';
import { ResizableBox } from 'react-resizable';

import { useGlassEffect } from '../../../_metronic/layout/components/header/GlassEffectContext';

interface DraggableDialogProps {
    open: boolean;
    onClose: () => void;
    content: React.ReactNode;
    title: string;
    enableExternalWindow?: boolean;
    width?: number;
    height?: number;
    showCloseWarning?: boolean;
    closeWarningMessage?: string;
    backgroundBlur?: boolean;
}

const DraggableDialog: React.FC<DraggableDialogProps> = ({
    open,
    onClose,
    content,
    title,
    enableExternalWindow = false,
    width = 720,
    height = 490,
    showCloseWarning = false,
    closeWarningMessage = "string",
    backgroundBlur = true,
}) => {
    const [isMaximized, setIsMaximized] = useState(false);
    const [isMinimized, setIsMinimized] = useState(false);
    const [size, setSize] = useState({ width, height });
    const [position, setPosition] = useState({ x: 0, y: 0 });
    const [dragBounds, setDragBounds] = useState({ top: 0, left: 0, right: 0, bottom: 0 });
    const [showWarningDialog, setShowWarningDialog] = useState(false);
    const { isGlassEffectEnabled } = useGlassEffect(); // Consume the isGlassEffectEnabled state
    const componentKey = useRef(Date.now()); // Unique key generated on mount

    const headerBackgroundStyle = {
        backgroundColor: isGlassEffectEnabled ? 'rgba(255, 255, 255, 0.7)' : 'var(--bs-background.paper)',
    };

    // Center the dialog on mount
    useEffect(() => {
        console.log("Component mounted or size changed");
        const centerX = (window.innerWidth - size.width) / 2;
        const centerY = (window.innerHeight - size.height) / 2;
        setPosition({ x: centerX, y: centerY });
    }, [size.width, size.height]);

    const toggleMaximize = () => {
        if (isMinimized) return;
        setIsMaximized(!isMaximized);

    };


    useEffect(() => {
        if (isMaximized) {
            // For maximizing, set position to top left and size to cover the full screen
            setPosition({ x: 0, y: 0 });
            setSize({ width: window.innerWidth, height: window.innerHeight });
        } else {
            // For normal state, reset size and optionally center the dialog
            const initialSize = { width: width, height: height };
            setSize(initialSize);
            const centerX = (window.innerWidth - initialSize.width) / 2;
            const centerY = (window.innerHeight - initialSize.height) / 2;
            setPosition({ x: centerX, y: centerY });
        }
    }, [isMaximized]);

    const draggableProps = isMaximized ? { position } : {};



    const minimize = () => {
        console.log("Minimizing");
        setIsMinimized(true);
        setIsMaximized(false);
    };

    const restore = () => {
        console.log("Restoring");
        setIsMinimized(false);
    };

    const openInNewTab = () => {
        const styleSheets = [...document.styleSheets].map(styleSheet => {
            try {
                return Array.from(styleSheet.cssRules).map(rule => rule.cssText).join('');
            } catch (e) {
                console.warn("Cannot access stylesheet: ", styleSheet);
                return '';
            }
        }).join('\n');

        const contentHtml = `
                <html>
                <head>
                    <title>${title}</title>
                    <style>${styleSheets}</style>
                </head>
                <body>${document.getElementById('dialog-content')?.innerHTML || ''}</body>
                </html>
            `;
        const blob = new Blob([contentHtml], { type: 'text/html' });
        const url = URL.createObjectURL(blob);
        window.open(url, '_blank');
    };

    const handleOnClose = () => {
        if (showCloseWarning) {
            setShowWarningDialog(true);
        } else {
            onClose(); // Call the onClose prop directly if no warning is needed
        }
    };

    useEffect(() => {
        const updateBounds = () => {
            const windowWidth = window.innerWidth;
            const windowHeight = window.innerHeight;
            const bounds = {
                left: 0 - position.x,
                top: 0 - position.y,
                right: windowWidth - position.x - size.width,
                bottom: windowHeight - position.y - size.height,
            };
            setDragBounds(bounds);
        };

        updateBounds();
        // Optionally, listen to window resize to update bounds dynamically
        window.addEventListener('resize', updateBounds);
        return () => window.removeEventListener('resize', updateBounds);
    }, [size, position]);

    const onResize = (event, { element, size }) => {
        // Directly update the size without modifying the position
        setSize(size);
    };


    if (!open) return null;

    // console.log("Rendering component", { isMaximized, isMinimized, size, position });

    return (


        <div
            style={{
                zIndex: 99999999999,
                position: 'fixed',
                top: isMinimized ? 'auto' : position.y,
                left: isMinimized ? 'auto' : position.x,
                right: isMinimized ? '10px' : 'auto',
                bottom: isMinimized ? '10px' : 'auto',
                pointerEvents: 'none',
            }}

        >


            <Box
                sx={{
                    display: isMinimized ? 'flex' : 'none',
                    alignItems: 'center',
                    backgroundColor: 'background.paper',
                    boxShadow: 3,
                    padding: '8px',
                    justifyContent: 'space-between',
                    maxWidth: 400,
                    width: 400,
                    pointerEvents: 'auto',
                }}

            >

                <Typography variant="body2" noWrap color="info">
                    {title}
                </Typography>

                <Divider orientation="vertical" flexItem />
                <Divider orientation="vertical" flexItem />
                <Button className="non-draggable" sx={{ borderRadius: "0px" }} size="small" color='info' onClick={restore}>
                    {isMaximized ? <FilterNoneSharp /> : <CropSquareSharp />}
                </Button>
                <Button className="non-draggable" sx={{ borderRadius: "0px" }} size="small" color='info' onClick={handleOnClose}>
                    <CloseSharp />
                </Button>
            </Box>

            <Draggable
                //@ts-ignore
                key={componentKey.current}
                handle="strong"
                bounds={dragBounds}
                {...draggableProps}
                cancel=".non-draggable"
            >
                <Box
                    sx={{
                        boxShadow: 3,
                        display: isMinimized ? 'none' : 'flex',
                        flexDirection: 'column',
                        maxWidth: size.width,
                        width: size.width,
                        pointerEvents: 'auto',
                    }}

                >

                    <Box
                        // className={isGlassEffectEnabled ? 'blur-background' : 'blur-background-disabled'}
                        sx={{

                            backgroundColor: backgroundBlur && isGlassEffectEnabled ? 'transparent' : "var(--bs-page-bg)",
                            backdropFilter: backgroundBlur && isGlassEffectEnabled ? 'blur(25px)' : undefined,
                            WebkitBackdropFilter: backgroundBlur && isGlassEffectEnabled ? 'blur(25px)' : undefined, // Add this line
                            zIndex: 999999999999999

                        }}
                    >
                        <strong
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                cursor: 'move',
                                justifyContent: 'space-between',
                                color: 'primary.contrastText',
                                pointerEvents: 'auto',
                            }}
                            onDoubleClick={toggleMaximize}

                        >
                            <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'center', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                                <Typography variant="body2" noWrap color="info">
                                    {title}
                                </Typography>
                            </Box>
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    borderColor: 'divider',
                                    borderRadius: 0,
                                    bgcolor: 'background.paper',
                                    pointerEvents: 'auto',
                                    transition: "0.5s",
                                    color: 'text.secondary',
                                    '& svg': {
                                        m: 1,
                                    },
                                    '& hr': {
                                        mx: 0.5,
                                    },
                                }}
                            >
                                <Button className="non-draggable" sx={{ borderRadius: "0px" }} size="small" color='info' onClick={openInNewTab} style={{ display: enableExternalWindow ? 'block' : 'none' }}>
                                    <OpenInNewSharp />
                                </Button>
                                <Divider orientation="vertical" flexItem />
                                <Button className="non-draggable" sx={{ borderRadius: "0px" }} color='info' size="small" onClick={minimize}>
                                    <MinimizeSharp />
                                </Button>
                                <Button className="non-draggable" sx={{ borderRadius: "0px" }} color='info' size="small" onClick={toggleMaximize}>
                                    {isMaximized ? <FilterNoneSharp /> : <CropSquareSharp />}
                                </Button>
                                <Button className="non-draggable" sx={{ borderRadius: "0px" }} color='info' size="small" onClick={handleOnClose}>
                                    <CloseSharp />
                                </Button>
                            </Box>
                        </strong>
                    </Box>


                    <Box
                        sx={{

                            backgroundColor: backgroundBlur && isGlassEffectEnabled ? 'transparent' : "var(--bs-page-bg)",
                            backdropFilter: backgroundBlur && isGlassEffectEnabled ? 'blur(25px)' : undefined,
                            WebkitBackdropFilter: backgroundBlur && isGlassEffectEnabled ? 'blur(25px)' : undefined,

                            zIndex: 999999999999999
                        }}
                    >
                        <ResizableBox
                            width={size.width}
                            height={size.height}
                            onResize={(event, { size }) => setSize(size)}
                            minConstraints={[300, 200]}
                            maxConstraints={[1300, 700]}
                            resizeHandles={['se']}
                            className="resizable-box"

                        >




                            <Box
                                id="dialog-content"
                                sx={{
                                    width: isMaximized ? window.innerWidth : size.width,
                                    height: isMaximized ? window.innerHeight : size.height,
                                    flexGrow: 1,
                                    overflow: 'auto',
                                    padding: '16px',
                                    pointerEvents: 'auto',
                                    transition: "0.5s",
                                    backgroundColor: backgroundBlur && isGlassEffectEnabled ? 'transparent' : "var(--bs-page-bg)",
                                    backdropFilter: backgroundBlur && isGlassEffectEnabled ? 'blur(25px)' : undefined,
                                    WebkitBackdropFilter: backgroundBlur && isGlassEffectEnabled ? 'blur(25px)' : undefined,
                                    zIndex: 999999999999999,
                                }}
                            >
                                {content}
                            </Box>



                        </ResizableBox>
                    </Box>

                </Box>
            </Draggable>



            {
                showWarningDialog && (
                    <Dialog
                        open={showWarningDialog}
                        onClose={() => setShowWarningDialog(false)}
                        sx={{
                            zIndex: 999999999999999,
                        }}
                    >
                        <DialogTitle>
                            <Box>
                                <Typography color="secondary">

                                    <WarningSharpIcon sx={{ marginRight: "10px" }} color='secondary' />

                                    Warning
                                </Typography>
                            </Box>
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                {closeWarningMessage || "Are you sure you want to close this dialog?"}
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button color='primary' onClick={() => setShowWarningDialog(false)}>NO</Button>
                            <Button color="error" onClick={() => { setShowWarningDialog(false); onClose(); }} autoFocus>
                                YES
                            </Button>
                        </DialogActions>
                    </Dialog>
                )
            }

        </div>
    );
};

export default DraggableDialog;
