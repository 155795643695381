import React from 'react';
import MailPage from './mail/page';
import GmailClone from './GmailClone';


function MailParnetPage() {


    return (
        <>
            {/* <MailPage /> */}
            <GmailClone />
        </>
    );
}

export default MailParnetPage;
