// src\app\pages\0-Account\ProfilePage\ProfileTabs\admin\AllCertificates.tsx
import LoopIcon from '@mui/icons-material/Loop';
import RefreshIcon from '@mui/icons-material/Refresh';
import {
  Alert,
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  Paper,
  Skeleton,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react';

interface CertificateInfo {
  domain: string;
  expiryDate: string;
  certificatePath: string;
  privateKeyPath: string;
}

const CertificatesInfo: React.FC = () => {
  const [data, setData] = useState<CertificateInfo[]>([]);
  const [loading, setLoading] = useState(true);
  const [renewLoading, setRenewLoading] = useState<{ [key: string]: boolean }>({});
  const [search, setSearch] = useState<string>('');
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(5);
  const [error, setError] = useState<string | null>(null);

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/admin/get-certbot-certificates`);
      setData(response.data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setError('Error fetching certificate data.');
      setData([]);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const filterCertificates = (certificates: CertificateInfo[]) => {
    return certificates.filter(cert => cert.domain.toLowerCase().includes(search.toLowerCase()));
  };

  const handleRenewCertificate = async (domain: string) => {
    setRenewLoading(prevState => ({ ...prevState, [domain]: true }));
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/admin/renewCertificate`, { domain });
      fetchData();
    } catch (error) {
      console.error(`Error renewing certificate for ${domain}:`, error);
      setError(`Error renewing certificate for ${domain}.`);
    } finally {
      setRenewLoading(prevState => ({ ...prevState, [domain]: false }));
    }
  };

  const handleCloseSnackbar = () => {
    setError(null);
  };

  return (
    <Container>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Typography variant="h4" gutterBottom>
          SSL Certificates Info
        </Typography>
        <Button
          variant="contained"
          color="primary"
          startIcon={<RefreshIcon />}
          onClick={fetchData}
        >
          Refresh
        </Button>
      </Box>
      <TextField
        label="Search"
        variant="outlined"
        fullWidth
        value={search}
        onChange={handleSearch}
        sx={{ mb: 2 }}
      />
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper sx={{ p: 2 }}>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Domain</TableCell>
                    <TableCell>Expiry Date</TableCell>
                    <TableCell>Certificate Path</TableCell>
                    <TableCell>Private Key Path</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {loading ? (
                    <TableRow>
                      <TableCell colSpan={5}>
                        <Skeleton variant="rectangular" height={200} />
                      </TableCell>
                    </TableRow>
                  ) : (
                    filterCertificates(data).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((cert, index) => (
                      <TableRow key={index}>
                        <TableCell>{cert.domain}</TableCell>
                        <TableCell>{cert.expiryDate}</TableCell>
                        <TableCell>{cert.certificatePath}</TableCell>
                        <TableCell>{cert.privateKeyPath}</TableCell>
                        <TableCell>
                          <IconButton
                            color="primary"
                            onClick={() => handleRenewCertificate(cert.domain)}
                            disabled={renewLoading[cert.domain]}
                          >
                            <LoopIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              component="div"
              count={filterCertificates(data).length}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </Grid>
      </Grid>
      <Snackbar open={!!error} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: '100%' }}>
          {error}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default CertificatesInfo;
