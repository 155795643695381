// regions.ts

const regions = [
  'a coruña',
  'aargau',
  'aberdeen city',
  'aberdeenshire',
  'abruzzi',
  'abruzzo',
  'abu dhabi',
  'aceh',
  'acre',
  'act',
  'ad dakhiliyah',
  'ad dawhah',
  'adana',
  'adis abeba',
  'afyonkarahisar',
  'aguascalientes',
  'aichi',
  'akershus',
  'aksaray',
  'akwa ibom',
  'al aqabah',
  'al asimah',
  'al batinah',
  'al gharbiyah',
  'al iskandariyah',
  'al jizah',
  'al mahdiyah',
  'al munastir',
  'al qahirah',
  'al qasim',
  'al wusta',
  'alabama',
  'alagoas',
  'aland',
  'alaska',
  'alba',
  'alberta',
  'alger',
  'algiers',
  'alicante',
  'almeria',
  'alsace',
  'alto paraná',
  'amapa',
  'amasya',
  'amazonas',
  'amman',
  'ammochostos',
  'andalucia',
  'andalusia',
  'andaman and nicobar islands',
  'andhra pradesh',
  'angus',
  'anhui',
  'ankara',
  'antalya',
  'antioquia',
  'antofagasta',
  'antwerp province',
  'antwerpen',
  'anzoategui',
  'anzoátegui',
  'aomori',
  'aosta valley',
  'apulia',
  'apure',
  'aquitaine',
  'ar riyad',
  'ar riyāḑ',
  'arad',
  'aragon',
  'aragua',
  'araucanía',
  'arequipa',
  'arges',
  'argyll and bute',
  'arica y parinacota',
  'arizona',
  'arkansas',
  'arkhangelskaya',
  'armagh city banbridge and craigavon',
  'artvin',
  'ash sharqiyah',
  'ashanti',
  'assam',
  'astana',
  "astrakhan'",
  'asturias',
  'atacama',
  'atlantico',
  'atlántico sur',
  'attiki',
  'auckland',
  'auvergne',
  'auvergne-rhône-alpes',
  'aveiro',
  'ayacucho',
  'aydin',
  'aydın',
  'azores',
  'ağrı',
  'bac ninh',
  'bacău',
  'baden-wuerttemberg',
  'baden-wurttemberg',
  'baden-württemberg region',
  'bahia',
  'baja california',
  'baja california sur',
  'bajah',
  'baki',
  'balearic islands',
  'bali',
  'balikesir',
  'balti',
  'balzers',
  'balıkesir',
  'bangkok',
  'banten',
  'baranya',
  'barinas',
  'barnet',
  'basel-city',
  'basel-stadt',
  'bashkortostan',
  'basilicata',
  'basilicate',
  'basque country',
  'basse-normandie',
  'bataan',
  'bath and north east somerset',
  'batman',
  'bavaria',
  'bay of plenty',
  'bayern',
  'bedford',
  'bedfordshire',
  'beijing',
  'beja',
  'bejaia',
  'bekes',
  'belfast',
  'ben arous',
  'bengkulu',
  'berlin',
  'bern',
  'bexley',
  'bicol',
  'bihar',
  'bihor',
  'bilecik',
  "bin 'arus",
  'bio-bio',
  'biobío',
  'birmingham',
  'bissau',
  'bistriţa-năsăud',
  'bitlis',
  'bizerte',
  'blackburn with darwen',
  'blackpool',
  'blaenau gwent',
  'blekinge',
  'blida',
  'bogota d.c.',
  'bohol',
  'bolivar',
  'bolton',
  'bolu',
  'bourgogne',
  'bourgogne-franche-comte',
  'bournemouth',
  'bracknell forest',
  'bradford',
  'braga',
  'braganca',
  'braila',
  'brandenburg',
  'brasov',
  'bratislava',
  'bratislavský',
  'braşov',
  'bremen',
  "brestskaya voblasts'",
  'bretagne',
  'bridgend',
  'brighton and hove',
  'bristol',
  'british columbia',
  'brittany',
  'bromley',
  'brussels capital',
  'brussels hoofdstedelijk gewest',
  'bryansk',
  'buckinghamshire',
  'bucuresti',
  'budapest',
  'buenos aires',
  'burdur',
  'burgas',
  'burgenland',
  'bursa',
  'busan',
  'buskerud',
  'buzau',
  'béni mellal-khénifra',
  'cabo rojo',
  'caerphilly',
  'cagayan valley',
  'caguas',
  'cairo',
  'cairo governorate',
  'cajamarca',
  'calabarzon',
  'calabria',
  'calderdale',
  'california',
  'cambridgeshire',
  'camden',
  'campania',
  'campeche',
  'canakkale',
  'canarias',
  'canary islands',
  'cankiri',
  'cantabria',
  'canterbury',
  'capital',
  'capital region',
  'caquetá',
  'carabobo',
  'caraga',
  'cardiff',
  'carinthia',
  'carmarthenshire',
  'casablanca-settat',
  'castellon',
  'castelo branco',
  'castilla y leon',
  'castille and león',
  'castille-la mancha',
  'catalonia',
  'causeway coast and glens',
  'ceara',
  'ceará',
  'cebu',
  'celje',
  'central',
  'central and western district',
  'central bedfordshire',
  'central bohemia',
  'central district',
  'central finland',
  'central java',
  'central kalimantan',
  'central luzon',
  'central ostrobothnia',
  'central region',
  'central serbia',
  'central singapore',
  'central sulawesi',
  'central visayas',
  'centre',
  'ceredigion',
  'cerro largo',
  'ceuta',
  'chachoengsao',
  'champagne-ardenne',
  'chandigarh',
  'changwat lop buri',
  'chanthaburi',
  'chelyabinsk',
  "cherkas'ka oblast'",
  'cheshire',
  'cheshire east',
  'cheshire west and chester',
  'chhattisgarh',
  'chiang mai',
  'chiang rai',
  'chiapas',
  'chiba',
  'chihuahua',
  'chisinau',
  'chisinau municipality',
  'chittagong',
  'chon buri',
  'chongqing',
  'city and county of swansea',
  'city of kingston upon hull',
  'city of leicester',
  'city of westminster',
  'clackmannanshire',
  'cluj',
  'coahuila de zaragoza',
  'coimbra',
  'cojedes',
  'colorado',
  'comunidad valenciana',
  'concepcion',
  'connecticut',
  'constanța',
  'conwy',
  'copperbelt',
  'coquimbo',
  'coquimbo region',
  'cordoba',
  'cornwall',
  'corrientes',
  'corrèze',
  'corse',
  'corsica',
  'cortes',
  'corum',
  'county donegal',
  'county durham',
  'county galway',
  'county westmeath',
  'coventry',
  'croydon',
  'csongrad',
  'csongrád',
  'cumbria',
  'cundinamarca',
  'dadra and nagar haveli',
  'daegu',
  'daejeon',
  'dalarna',
  'dalarnas lan',
  'daman and diu',
  'damietta',
  'darlington',
  'davao city',
  'delaware',
  'delhi',
  'delta amacuro',
  'denbighshire',
  'denizli',
  'departamento de sacatepequez',
  'derby',
  'derbyshire',
  'derry',
  'devon',
  'dhaka',
  'district of columbia',
  'distrito especial',
  'distrito federal',
  'diyarbakir',
  'dolj',
  'dolnoslaskie',
  'doncaster',
  'dorset',
  'down',
  'draa-tafilalet',
  'drenthe',
  'dubai',
  'dublin',
  'dudley',
  'dumfries and galloway',
  'dundee city',
  'durango',
  'durham',
  "dushet'is raioni",
  'duzce',
  'east ayrshire',
  'east azerbaijan',
  'east dunbartonshire',
  'east flanders province',
  'east java',
  'east kalimantan',
  'east lothian',
  'east nusa tenggara',
  'east sussex',
  'eastern cape',
  'eastern finland',
  'eastern visayas',
  'edinburgh',
  'edirne',
  'ehime',
  'el-jadida',
  'elazig',
  'emilia-romagna',
  'enfield',
  'england',
  'equateur',
  'erzincan',
  'erzurum',
  'eskisehir',
  'espirito santo',
  'essex',
  'evora',
  'extremadura',
  'falcon',
  'falkirk',
  'faro',
  'fars',
  'federal district',
  'fejer',
  'fermanagh and omagh',
  'fes-boulemane',
  'fes-meknes',
  'fife',
  'finland proper',
  'finnmark',
  'flanders',
  'flemish brabant province',
  'flevoland',
  'flintshire',
  'floresti',
  'florida',
  'francisco morazan',
  'free state',
  'fribourg',
  'friesland',
  'friuli venezia giulia',
  'friuli-venezia giulia',
  'fujian',
  'fukui',
  'fukuoka',
  'fukushima',
  'gabrovo',
  'gaevleborg',
  'gafsa',
  'galati',
  'galicia',
  'galway',
  'gansu',
  'gateshead',
  'gauteng',
  'gavleborgs lan',
  'gaziantep',
  'gelderland',
  'geneva',
  'georgia',
  'gharb-chrarda-beni hssen',
  'gifu',
  'gipuzkoa',
  'giresun',
  'glasgow city',
  'gloucestershire',
  'goa',
  'goias',
  'gorj',
  'gotland',
  'grad sofiya',
  'grad zagreb',
  'granada',
  'grand casablanca',
  'grand est',
  'graubunden',
  'greater accra',
  'greater london',
  'greater poland',
  'grisons',
  'groningen',
  'guanajuato',
  'guangdong',
  'guangxi',
  'guarda',
  'guarico',
  'guatemala',
  'guayas',
  'guaynabo',
  'guerrero',
  'guizhou',
  'gujarat',
  'gumushane',
  'gwynedd',
  'hadarom',
  'haifa',
  'hainan',
  'hainaut',
  'hainaut province',
  'halland',
  'hallands lan',
  'halton',
  'hamburg',
  'hamerkaz',
  'hampshire',
  'harare',
  'harghita',
  'harjumaa',
  'harrow',
  'hartlepool',
  'haryana',
  'hatay',
  'haute-normandie',
  'hauts-de-france',
  'havering',
  'hawaii',
  "hawke's bay",
  'hebei',
  'hedmark',
  'hefa',
  'heilongjiang',
  'helmand',
  'henan',
  'herefordshire',
  'hertford',
  'hertfordshire',
  'hesse',
  'hessen',
  'heves',
  'hidalgo',
  'highland',
  'hillingdon',
  'himachal pradesh',
  'hiroshima',
  'hlavni mesto praha',
  'ho chi minh city',
  'hokkaido',
  'hordaland',
  'hounslow',
  'hovedstaden',
  'huanuco',
  'hubei',
  'huesca',
  'hunan',
  'hunedoara',
  'hyogo',
  'iaşi',
  'ica',
  'idaho',
  'igdir',
  'ile-de-france',
  'ilfov',
  'illinois',
  'ilocos',
  'incheon',
  'indiana',
  'inner mongolia',
  'intibuca',
  'inverclyde',
  'iowa',
  'irkutsk',
  'ishikawa',
  'islamabad',
  'islas baleares',
  'isle of anglesey',
  'isle of wight',
  'islington',
  'isparta',
  'istanbul',
  'ivanovo',
  'i̇zmir',
  'jaen',
  'jakarta',
  'jakarta raya',
  'jalisco',
  'jambi',
  'jammu and kashmir',
  'jamtlands lan',
  'jawa barat',
  'jawa tengah',
  'jawa timur',
  'jharkhand',
  'jiangsu',
  'jiangxi',
  'jihocesky kraj',
  'jihomoravsky kraj',
  'jilin',
  'johor',
  'jonkopings lan',
  'junin',
  'jura',
  'jönköping',
  'kahramanmaras',
  'kalimantan barat',
  'kalimantan selatan',
  'kalimantan tengah',
  'kaliningrad',
  'kalmar',
  'kalmar lan',
  'kampala',
  'kamphaeng phet',
  'kanagawa',
  'kanchanaburi',
  'kansas',
  'kaohsiung',
  'karabuk',
  'karelia',
  'karlovarsky',
  'karlovarsky kraj',
  'karnataka',
  'kars',
  'kastamonu',
  'kauno apskritis',
  'kayseri',
  'kedah',
  'kelantan',
  'kemerovo',
  'kensington and chelsea',
  'kent',
  'kentucky',
  'kerala',
  'khomas',
  'khon kaen',
  'khyber pakhtunkhwa',
  'kildare',
  'kilis',
  'kilkenny',
  'kingston upon hull',
  'kingston upon thames',
  'kirikkale',
  'kirklareli',
  'kirklees',
  'kirsehir',
  'kisumu',
  'knowsley',
  'kocaeli',
  'kochi',
  'komarom-esztergom',
  'konya',
  'kosice',
  'kosicky',
  'kostroma',
  'krabi',
  'kralovehradecky kraj',
  'krasnodar',
  'krasnoyarskiy',
  'kronoberg',
  'kronobergs lan',
  'krung thep',
  'kuala lumpur',
  'kujawsko-pomorskie',
  'kumamoto',
  'kutahya',
  'kwazulu-natal',
  'kyiv city',
  'kymenlaakso',
  'kyoto',
  "l'vivs'ka oblast'",
  'la libertad',
  'la pampa',
  'la rioja',
  'labuan',
  'lagos',
  'lampang',
  'lampung',
  'lancashire',
  'languedoc-roussillon',
  'laois',
  'lapland',
  'lara',
  'latium',
  'lazio',
  'leeds',
  'leicester',
  'leicestershire',
  'leinster',
  'leiria',
  'lesser poland voivodeship',
  'liaoning',
  'liberecky kraj',
  "libertador general bernardo o'higgins",
  'liguria',
  'lima region',
  'limburg',
  'limburg province',
  'limerick',
  'limousin',
  'limpopo',
  'lincolnshire',
  'lipetsk',
  'lisboa',
  'lisbon',
  'littoral',
  'liverpool',
  'liège province',
  'lombardia',
  'lombardy',
  'london',
  'lorraine',
  'los lagos',
  'los lagos region',
  'los rios',
  'los ríos region',
  'louisiana',
  'louth',
  'lovech',
  'lower austria',
  'lower saxony',
  'lower silesia',
  'luanda',
  'lubelskie',
  'lubuskie',
  'lucerne',
  'luquillo',
  'lusaka',
  'luton',
  'luxembourg',
  'madeira',
  'madhya pradesh',
  'madre de dios',
  'madrid',
  'magallanes y de la antartica chilena',
  'maharashtra',
  'maine',
  'maine-et-loire',
  'malaga',
  'malatya',
  'malopolskie',
  'maluku',
  'manama',
  'manchester',
  'manila',
  'manipur',
  'manisa',
  'manitoba',
  'maranhao',
  'marche',
  'mardin',
  'marrakech-tensift-al haouz',
  'marrakesh-safi',
  'martinique',
  'maryland',
  'mashonaland east',
  'massachusetts',
  'mato grosso',
  'mato grosso do sul',
  'maule',
  'maule region',
  'mayenne',
  'mazovia',
  'mazowieckie',
  'mecklenburg-vorpommern',
  'medway',
  'meghalaya',
  'mehedinţi',
  'meknes-tafilalet',
  'melaka',
  'melilla',
  'mendoza',
  'merida',
  'mersin',
  'merthyr tydfil',
  'merthyr tydfil county borough',
  'merton',
  'meta',
  'mexico',
  'mexico city',
  'michigan',
  'michoacan',
  'michoacan de ocampo',
  'mid and east antrim',
  'mid ulster',
  'middlesbrough',
  'midi-pyrenees',
  'midlands',
  'midlothian',
  'midtjylland',
  'mie',
  'milton keynes',
  'mimaropa',
  'minas gerais',
  'minnesota',
  'minsk city',
  'miranda',
  'miskolc',
  'mississippi',
  'missouri',
  'miyagi',
  'mizoram',
  'molise',
  'monaco',
  'monagas',
  'monmouthshire',
  'montana',
  'montevideo',
  'moravskoslezsky kraj',
  'moray',
  'more og romsdal',
  'morelos',
  'morona-santiago',
  'moscow city',
  'mpumalanga',
  'mugla',
  'munster',
  'murcia',
  'murmansk',
  'muscat',
  'mymensingh division',
  'nabeul',
  'nagaland',
  'nagano',
  'nagasaki',
  'nairobi area',
  'nakhon pathom',
  'nakhon ratchasima',
  'nakhon sawan',
  'nakhon si thammarat',
  'namur',
  'namur province',
  'nara',
  'national capital region',
  'navarra',
  'navarre',
  'nayarit',
  'nct',
  'neath port talbot',
  'nebraska',
  'negeri sembilan',
  'nei mongol',
  'nelson',
  'neuchatel',
  'neuquen',
  'nevada',
  'nevsehir',
  'new brunswick',
  'new hampshire',
  'new jersey',
  'new mexico',
  'new south wales',
  'new york',
  'newcastle upon tyne',
  'newfoundland and labrador',
  'newport',
  'newry mourne and down',
  'nidwalden',
  'niedersachsen',
  'nigde',
  'niigata',
  'nizhegorod',
  'nong khai',
  'nonthaburi',
  'noord-brabant',
  'noord-holland',
  'nord-pas-de-calais',
  'nordland',
  'nordrhein-westfalen',
  'norfolk',
  'normandy',
  'norrbotten',
  'norrbottens lan',
  'norte de santander',
  'north ayrshire',
  'north brabant',
  'north carolina',
  'north dakota',
  'north denmark',
  'north east lincolnshire',
  'north holland',
  'north karelia',
  'north lanarkshire',
  'north lincolnshire',
  'north rhine-westphalia',
  'north somerset',
  'north sulawesi',
  'north sumatra',
  'north yorkshire',
  'north-west',
  'north-west frontier',
  'northamptonshire',
  'northern',
  'northern cape',
  'northern district',
  'northern mindanao',
  'northern ostrobothnia',
  'northern savo',
  'northern territory',
  'northland',
  'northumberland',
  'northwest territories',
  'nottingham',
  'nottinghamshire',
  'nouvelle-aquitaine',
  'nova scotia',
  'novgorod',
  'novosibirsk',
  'nueva esparta',
  'nuevo leon',
  'nuevo león',
  'nunavut',
  'nusa tenggara barat',
  'nusa tenggara timur',
  'oaxaca',
  'obwalden',
  'occitanie',
  "odes'ka oblast'",
  'odisha',
  'ohio',
  'okayama',
  'okinawa',
  'oklahoma',
  'oldham',
  'olomoucky kraj',
  'olt',
  'omsk',
  'onchan',
  'ondo',
  'ontario',
  'oost-vlaanderen',
  'opolskie',
  'oppland',
  'orange free state',
  'ordu',
  'orebro lan',
  'oregon',
  'orel',
  'orenburg',
  'orhei',
  'oriental',
  'orissa',
  'orkney islands',
  'oromiya',
  'osaka',
  'oslo',
  'ostergotlands lan',
  'ostfold',
  'ostrobothnia',
  'otago',
  'oulu',
  'overijssel',
  'oxfordshire',
  'pahang',
  'pais vasco',
  'panama',
  'papua',
  'para',
  'parana',
  'paraíba',
  'pardubicky kraj',
  'pathum thani',
  'pattani',
  'pays de la loire',
  'pembrokeshire',
  'penang',
  'pennsylvania',
  'perak',
  'perlis',
  'pernambuco',
  'perth and kinross',
  'pest',
  'peten',
  'peterborough',
  'phetchaburi',
  'phichit',
  'phitsanulok',
  'phnom penh',
  'phra nakhon si ayutthaya',
  'phrae',
  'phuket',
  'piaui',
  'piauí',
  'picardie',
  'pichincha',
  'piedmont',
  'piemonte',
  'pirkanmaa',
  'piura',
  'pleven',
  'plovdiv',
  'plymouth',
  'plzensky',
  'plzensky kraj',
  'podkarpackie',
  'podlaskie',
  'poitou-charentes',
  'pomerania',
  'pomorskie',
  'pontevedra',
  'poole',
  'portalegre',
  'porto',
  'portsmouth',
  'portuguesa',
  'powys',
  'prachin buri',
  'prachuap khiri khan',
  'praha',
  'prahova',
  "primor'ye",
  'prince edward island',
  "provence-alpes-cote d'azur",
  'province of arezzo',
  'province of mantua',
  'provincia di como',
  'pskov',
  'puducherry',
  'puebla',
  'puglia',
  'pulau pinang',
  'punjab',
  'putrajaya',
  'päijänne tavastia',
  'quebec',
  'queensland',
  'queretaro',
  'queretaro de arteaga',
  'quezon city',
  'quindio',
  'quintana roo',
  'rabat-sale-zemmour-zaer',
  'rabat-salé-kénitra',
  'rajasthan',
  'rajshahi division',
  'ratchaburi',
  'rayong',
  'reading',
  'redbridge',
  'redcar and cleveland',
  'region metropolitana',
  'renfrewshire',
  'reunion',
  'rheinland-pfalz',
  'rhode island',
  'rhondda cynon taf',
  'rhondda cynon taff',
  'rhone-alpes',
  'riau',
  'riau islands',
  'richmond upon thames',
  'riga',
  'rio de janeiro',
  'rio grande do norte',
  'rio grande do sul',
  'risaralda',
  'rivers',
  'rize',
  'rochdale',
  'rogaland',
  'rondonia',
  'roraima',
  'rostov',
  'rotherham',
  'royal kensington and chelsea',
  "ryazan'",
  'sa kaeo',
  "sa'dah",
  'saarland',
  'sabah',
  'sacatepequez',
  'sachsen',
  'sachsen-anhalt',
  'saint gallen',
  'saint john island',
  'saint petersburg city',
  'saitama',
  'sakarya',
  'sakha',
  'sakhalin',
  'sakon nakhon',
  'salford',
  'salzburg',
  'samara',
  'samsun',
  'samut prakan',
  'samut songkhram',
  'san german',
  'san jose',
  'san juan',
  'san luis',
  'san luis potosí',
  'san salvador',
  'sandwell',
  'sangha',
  'sankt gallen',
  'sanliurfa',
  'santa catarina',
  'santa cruz',
  'santa fe',
  'santander',
  'santarem',
  'santiago metropolitan',
  'sao paulo',
  'saraburi',
  'saratov',
  'sarawak',
  'sardegna',
  'sardinia',
  'saskatchewan',
  'satakunta',
  'satu mare',
  'saxony',
  'saxony-anhalt',
  'schaan',
  'schaffhausen',
  'schleswig-holstein',
  'scottish borders',
  'selangor',
  "seoul-t'ukpyolsi",
  'sergipe',
  'setubal',
  'shaanxi',
  'shandong',
  'shanghai',
  'shanxi',
  'sheffield',
  'shetland islands',
  'shiga',
  'shimane',
  'shizuoka',
  'shropshire',
  'shumen',
  'siauliu apskritis',
  'sibiu',
  'sichuan',
  'sicilia',
  'sicily',
  'sikkim',
  'silesia',
  'siliana',
  'sinaloa',
  'sindh',
  'sinop',
  'sisaket',
  'sjelland',
  'skane lan',
  'skåne',
  'slaskie',
  'slough',
  'smolensk',
  'soccsksargen',
  'sodermanlands lan',
  'soedermanland',
  'sogn og fjordane',
  'solothurn',
  'somerset',
  'songkhla',
  'sonora',
  'sor-trondelag',
  'souss-massa',
  'sousse',
  'south australia',
  'south ayrshire',
  'south carolina',
  'south dakota',
  'south denmark',
  'south gloucestershire',
  'south holland',
  'south kalimantan',
  'south karelia',
  'south lanarkshire',
  'south sulawesi',
  'south sumatra',
  'southampton',
  'southeast sulawesi',
  'southern',
  'southern finland',
  'southern governorate',
  'southern savonia',
  'southland',
  'st helier',
  'st. brelade',
  'st. helens',
  'st.-petersburg',
  'staffordshire',
  'stara zagora',
  'stirling',
  'stockholms lan',
  'stockport',
  'stockton-on-tees',
  'store',
  'stredocesky kraj',
  'styria',
  'subcarpathian voivodeship',
  'sucre',
  'suffolk',
  'sulawesi selatan',
  'sumatera barat',
  'sumatera utara',
  'sunderland',
  'surat thani',
  'surin',
  'surrey',
  'sutton',
  'svalbard',
  'sverdlovsk',
  'swansea',
  'swietokrzyskie',
  'swindon',
  'syddanmark',
  'syracuse',
  "t'ai-wan",
  'tabasco',
  'tachira',
  'tainan',
  'taiwan',
  'tamaulipas',
  'tameside',
  'tamil nadu',
  'tanger-tetouan-al hoceima',
  'tarapaca',
  'tasman',
  'tasmania',
  'tatarstan',
  'tavastia proper',
  'tehrān',
  'tekirdag',
  'tekirdağ',
  'tel aviv',
  'telangana',
  'telemark',
  'telford and wrekin',
  'tennessee',
  'terengganu',
  'texas',
  'the marches',
  'the scottish borders',
  'thessaloniki',
  'thurgau',
  'thuringen',
  'thuringia',
  'thurrock',
  'tianjin',
  'ticino',
  'tierra del fuego',
  'timis',
  'timiş',
  'tlaxcala',
  'toa baja',
  'tobago',
  'tocantins',
  'tochigi',
  'tokat',
  'tokyo',
  'tolima',
  'tolna',
  'tomsk',
  'torbay',
  'torfaen',
  'toscana',
  'toshkent',
  'toyama',
  'trabzon',
  'trafford',
  'trang',
  'trat',
  'trentino-alto adige',
  'triesen',
  'tripura',
  'troms',
  'trujillo',
  'tucuman',
  'tula',
  'tungurahua',
  'tunis',
  'turgovishte',
  'tuscany',
  "tver'",
  'tyrol',
  "tyumen'",
  'tūnis',
  'ubon ratchathani',
  'udon thani',
  "ul'yanovsk",
  'ulsan',
  'ulster',
  'umbria',
  'ungheni',
  'upper austria',
  'uppsala',
  'uppsala lan',
  'uri',
  'usak',
  'ustecky',
  'ustecky kraj',
  'utah',
  'uthai thani',
  'utrecht',
  'uttar pradesh',
  'uttaradit',
  'uttarakhand',
  'uusimaa',
  'vaduz',
  'vaesterbotten',
  'vaestmanland',
  'vaestra goetaland',
  'valais',
  'vale of glamorgan',
  'valencia',
  'valle del cauca',
  'valparaiso',
  'van',
  'vargas',
  'varna',
  'vasterbottens lan',
  'vasternorrlands lan',
  'vastmanlands lan',
  'vastra gotaland',
  'vaud',
  'vega baja',
  'veneto',
  'venice',
  'veracruz',
  'veracruz-llave',
  'vermont',
  'vest-agder',
  'vestfold',
  'veszprem',
  'viana do castelo',
  'victoria',
  'vienna',
  'vila real',
  'vilniaus apskritis',
  'virgin islands',
  'virginia',
  'viseu',
  'vladimir',
  'vojvodina',
  'volgograd',
  'vorarlberg',
  'voronezh',
  'vratsa',
  'vysocina',
  'vysočina',
  'värmland',
  'västernorrland',
  'waikato',
  'wakayama',
  'wakefield',
  'wallonia',
  'walloon brabant province',
  'walsall',
  'warrington',
  'warwickshire',
  'washington',
  'waterford',
  'wellington',
  'west bengal',
  'west berkshire',
  'west coast',
  'west flanders province',
  'west java',
  'west kalimantan',
  'west lothian',
  'west nusa tenggara',
  'west sulawesi',
  'west sumatra',
  'west sussex',
  'west virginia',
  'west-vlaanderen',
  'western',
  'western area',
  'western australia',
  'western cape',
  'western finland',
  'western isles',
  'western visayas',
  'wexford',
  'wicklow',
  'wielkopolskie',
  'wigan',
  'wiltshire',
  'windsor and maidenhead',
  'wirral',
  'wisconsin',
  'wokingham',
  'wolverhampton',
  'worcestershire',
  'wrexham',
  'wyoming',
  'xinjiang',
  'yala',
  'yalova',
  'yamagata',
  'yamaguchi',
  'yamanashi',
  'yangon',
  'yaracuy',
  "yaroslavl'",
  'yauco',
  'yerushalayim',
  'yogyakarta',
  'york',
  'yozgat',
  'yucatan',
  'yukon',
  'yunlin county',
  'yunnan',
  'zacatecas',
  'zachodniopomorskie',
  'zamboanga peninsula',
  'zealand',
  'zeeland',
  'zhejiang',
  'zinder',
  'zlin',
  'zlinsky kraj',
  'zonguldak',
  'zug',
  'zuid-holland',
  'zulia',
  'zurich',
  'évora',
  'île-de-france',
  'örebro',
  'östergötland',
  'østfold',
  'ōsaka',
  'şafāqis',
  'žilinský',
]

export default regions
