// src\app\pages\0-Account\EmailAccounts\ConnectPhones\BuyPhoneNumbers.tsx
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button, CircularProgress, Typography, List, ListItem, Snackbar, Alert, Grid, Skeleton } from '@mui/material';

interface PhoneNumber {
    friendlyName: string;
    phoneNumber: string;
}

interface BuyPhoneNumbersProps {
    onClose: () => void;
}

const BuyPhoneNumbers: React.FC<BuyPhoneNumbersProps> = ({ onClose }) => {
    const [tollFreeNumbers, setTollFreeNumbers] = useState<PhoneNumber[]>([]);
    const [localNumbers, setLocalNumbers] = useState<PhoneNumber[]>([]);
    const [loadingNumbers, setLoadingNumbers] = useState<{ [key: string]: boolean }>({});
    const [tollFreeError, setTollFreeError] = useState<string | null>(null);
    const [localError, setLocalError] = useState<string | null>(null);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState<'info' | 'success' | 'warning' | 'error'>('info');

    const fetchTollFreeNumbers = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/phone/fetch-toll-free-numbers`);
            setTollFreeNumbers(response.data);
            setTollFreeError(null);
        } catch (error: any) {
            setTollFreeError(`Failed to fetch toll-free numbers: ${error.response?.data.message || error.message}`);
            setSnackbarSeverity('error');
            setSnackbarMessage(`Failed to fetch toll-free numbers: ${error.response?.data.message || error.message}`);
            setSnackbarOpen(true);
        }
    };

    const fetchLocalNumbers = async (state = '') => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/phone/fetch-local-numbers`, {
                params: { state }
            });
            setLocalNumbers(response.data);
            setLocalError(null);
        } catch (error: any) {
            setLocalError(`Failed to fetch local numbers: ${error.response?.data.message || error.message}`);
            setSnackbarSeverity('error');
            setSnackbarMessage(`Failed to fetch local numbers: ${error.response?.data.message || error.message}`);
            setSnackbarOpen(true);
        }
    };

    useEffect(() => {
        fetchTollFreeNumbers();
        fetchLocalNumbers();
    }, []);

    const handlePurchaseNumber = async (phoneNumber: string) => {
        setLoadingNumbers((prev) => ({ ...prev, [phoneNumber]: true }));
        try {
            await axios.post(`${process.env.REACT_APP_API_URL}/phone/buy-number`, { phoneNumber });
            setSnackbarSeverity('success');
            setSnackbarMessage('Phone number purchased successfully');
        } catch (error: any) {
            setSnackbarSeverity('error');
            setSnackbarMessage(`Failed to purchase phone number: ${error.response?.data.message || error.message}`);
        } finally {
            setLoadingNumbers((prev) => ({ ...prev, [phoneNumber]: false }));
            setSnackbarOpen(true);
        }
    };

    const handleReleaseNumber = async (phoneNumberSid: string) => {
        setLoadingNumbers((prev) => ({ ...prev, [phoneNumberSid]: true }));
        try {
            await axios.post(`${process.env.REACT_APP_API_URL}/phone/release-number`, { phoneNumberSid });
            setSnackbarSeverity('success');
            setSnackbarMessage('Phone number released successfully');
        } catch (error: any) {
            setSnackbarSeverity('error');
            setSnackbarMessage(`Failed to release phone number: ${error.response?.data.message || error.message}`);
        } finally {
            setLoadingNumbers((prev) => ({ ...prev, [phoneNumberSid]: false }));
            setSnackbarOpen(true);
        }
    };

    return (
        <div>
            <Typography variant="h6" style={{ marginTop: '10px' }}>Toll-free Numbers:</Typography>
            {tollFreeError && (
                <Typography variant="body2" color="error" style={{ marginTop: '10px' }}>{tollFreeError}</Typography>
            )}
            {tollFreeNumbers.length === 0 && !tollFreeError ? <CircularProgress /> : (
                <List>
                    {tollFreeNumbers.map((number, index) => (
                        <ListItem key={index} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Grid container spacing={2} alignItems="center">
                                <Grid item>
                                    {loadingNumbers[number.phoneNumber] ? (
                                        <Skeleton width={200} />
                                    ) : (
                                        <Typography variant="body1">{number.friendlyName}</Typography>
                                    )}
                                </Grid>
                                <Grid item>
                                    <Button variant="text" color="info" onClick={() => handlePurchaseNumber(number.phoneNumber)} disabled={loadingNumbers[number.phoneNumber]}>
                                        {loadingNumbers[number.phoneNumber] ? <CircularProgress size={24} /> : 'Buy'}
                                    </Button>
                                </Grid>
                            </Grid>
                        </ListItem>
                    ))}
                </List>
            )}

            <Typography variant="h6" style={{ marginTop: '20px' }}>Local Numbers:</Typography>
            {localError && (
                <Typography variant="body2" color="error" style={{ marginTop: '10px' }}>{localError}</Typography>
            )}
            {localNumbers.length === 0 && !localError ? <CircularProgress /> : (
                <List>
                    {localNumbers.map((number, index) => (
                        <ListItem key={index} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Grid container spacing={2} alignItems="center">
                                <Grid item>
                                    {loadingNumbers[number.phoneNumber] ? (
                                        <Skeleton width={200} />
                                    ) : (
                                        <Typography variant="body1">{number.friendlyName}</Typography>
                                    )}
                                </Grid>
                                <Grid item>
                                    <Button variant="text" color="info" onClick={() => handlePurchaseNumber(number.phoneNumber)} disabled={loadingNumbers[number.phoneNumber]}>
                                        {loadingNumbers[number.phoneNumber] ? <CircularProgress size={24} /> : 'Buy'}
                                    </Button>
                                </Grid>
                            </Grid>
                        </ListItem>
                    ))}
                </List>
            )}

            <Button variant="contained" color="secondary" onClick={onClose} style={{ marginTop: '20px' }}>
                Close
            </Button>

            <Snackbar open={snackbarOpen} autoHideDuration={null} onClose={() => setSnackbarOpen(false)}>
                <Alert onClose={() => setSnackbarOpen(false)}
                    //@ts-ignore
                    severity={snackbarSeverity}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </div>
    );
};

export default BuyPhoneNumbers;
