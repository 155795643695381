// IntegrationsTab.tsx
import { Alert, Button, CircularProgress, Snackbar, TextField } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';


interface IntegrationState {
    openAi: { apiKey: string; status: string; };
    googleGemini: { apiKey: string; status: string; };
    reoonEmailVerifier: { apiKey: string; status: string; };
    emailListValidation: { apiKey: string; status: string; };
}


const fetchIntegrations = async () => {
    const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/integrations/all`);
    return data; // Directly return the integration data object
};

const linkIntegration = async (integrationInfo) => {
    const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/integrations/${integrationInfo.type}/link`, {
        apiKey: integrationInfo.apiKey,
    });
    return data;
};

const delinkIntegration = async (integrationType) => {
    const { data } = await axios.delete(`${process.env.REACT_APP_API_URL}/integrations/${integrationType}/delink`);
    return data;
};

const IntegrationsTab = () => {
    const [apiKeys, setApiKeys] = useState<IntegrationState>({
        openAi: { apiKey: '', status: 'disconnected' }, // Default status can be 'disconnected'
        googleGemini: { apiKey: '', status: 'disconnected' },
        reoonEmailVerifier: { apiKey: '', status: 'disconnected' },
        emailListValidation: { apiKey: '', status: 'disconnected' },
    });
    const [snackbarInfo, setSnackbarInfo] = useState({ open: false, message: '', severity: 'info' });
    const { data, isLoading, isError, refetch } = useQuery('integrations', fetchIntegrations);
    const [loadingStates, setLoadingStates] = useState({
        openAi: false,
        googleGemini: false,
        reoonEmailVerifier: false,
        emailListValidation: false,
    });


    useEffect(() => {
        if (data) {
            setApiKeys({
                openAi: { apiKey: data.openAi?.apiKey || '', status: data.openAi?.status || 'disconnected' },
                googleGemini: { apiKey: data.googleGemini?.apiKey || '', status: data.googleGemini?.status || 'disconnected' },
                reoonEmailVerifier: { apiKey: data.reoonEmailVerifier?.apiKey || '', status: data.reoonEmailVerifier?.status || 'disconnected' },
                emailListValidation: { apiKey: data.emailListValidation?.apiKey || '', status: data.emailListValidation?.status || 'disconnected' },
            });
        }
    }, [data]);


    console.log("data:", data)

    // Correctly use `useMutation` for linking integrations
    const linkMutation = useMutation(linkIntegration, {
        onSuccess: () => {
            setSnackbarInfo({ open: true, message: 'Integration linked successfully!', severity: 'success' });
            refetch();
        },
        onError: () => {
            setSnackbarInfo({ open: true, message: 'Failed to link integration.', severity: 'error' });
        },
    });

    // Correctly use `useMutation` for delinking integrations
    const delinkMutation = useMutation(delinkIntegration, {
        onSuccess: () => {
            setSnackbarInfo({ open: true, message: 'Integration delinked successfully!', severity: 'success' });
            refetch();
        },
        onError: () => {
            setSnackbarInfo({ open: true, message: 'Failed to delink integration.', severity: 'error' });
        },
    });


    const handleCloseSnackbar = () => setSnackbarInfo({ ...snackbarInfo, open: false });


    const handleChangeApiKey = (type, value) => {
        setApiKeys((prevKeys) => ({
            ...prevKeys,
            [type]: {
                ...prevKeys[type], // Preserve the existing object structure
                apiKey: value, // Update only the apiKey part
            },
        }));
    };


    const handleLink = (type) => {
        setLoadingStates(prev => ({ ...prev, [type]: true }));
        linkMutation.mutate({ type, apiKey: apiKeys[type].apiKey }, {
            onSuccess: () => {
                setLoadingStates(prev => ({ ...prev, [type]: false }));
            },
            onError: () => {
                setLoadingStates(prev => ({ ...prev, [type]: false }));
            },
        });
    };

    const handleDelink = (type) => {
        setLoadingStates(prev => ({ ...prev, [type]: true }));
        delinkMutation.mutate(type, {
            onSuccess: () => {
                setLoadingStates(prev => ({ ...prev, [type]: false }));
            },
            onError: () => {
                setLoadingStates(prev => ({ ...prev, [type]: false }));
            },
        });
    };


    if (isError) return <div>Error loading integrations</div>;

    // Safe access to API keys
    const safeGetApiKey = (type) => data && data[type] ? data[type].apiKey : '';



    return (
        <div>
            {['openAi', 'googleGemini', 'reoonEmailVerifier', 'emailListValidation'].map((type) => (
                <div key={type} style={{ marginBottom: '20px', display: 'flex', alignItems: 'center' }}>
                    <TextField
                        label={`${type} API Key`}
                        variant="outlined"
                        value={apiKeys[type].apiKey}
                        onChange={(e) => handleChangeApiKey(type, e.target.value)}
                        disabled={apiKeys[type].status === 'connected' || loadingStates[type]}
                    />
                    {loadingStates[type] ? (
                        <CircularProgress size={24} style={{ marginLeft: 16 }} />
                    ) : (
                        <Button
                            onClick={() => apiKeys[type].status === 'connected' ? handleDelink(type) : handleLink(type)}
                            color="primary"
                            variant="contained"
                            style={{ marginLeft: '10px' }}
                            disabled={loadingStates[type]}
                        >
                            {apiKeys[type].status === 'connected' ? 'Disconnect' : 'Connect'}
                        </Button>
                    )}
                </div>
            ))}


            <Snackbar open={snackbarInfo.open} autoHideDuration={6000} onClose={handleCloseSnackbar}>
                <Alert onClose={handleCloseSnackbar}
                    //@ts-ignore
                    severity={snackbarInfo.severity}>
                    {snackbarInfo.message}
                </Alert>
            </Snackbar>
        </div>
    );

};

export default IntegrationsTab;
