// src\app\pages\0-Account\EmailAccounts\EmailAccountsPage.tsx
import React from 'react';

import EmailAccountsParent from './ManageEmailAccounts/EmailAccountsParent';

//@ts-ignore
const EmailAccountsPage = () => {

  return (
    <div>
      <EmailAccountsParent />
    </div>
  );
};

export default EmailAccountsPage;
