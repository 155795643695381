import React, { useState, useEffect } from 'react';
import Quill from 'quill';
import Tagify from '@yaireo/tagify';
import '@yaireo/tagify/dist/tagify.css'; // don't forget to import the related CSS
import 'quill/dist/quill.bubble.css';  // for Snow theme

const contacts = ['john@example.com', 'jane@example.com', 'doe@example.com'];

const ComposeInbox: React.FC = () => {
  const [showCC, setShowCC] = useState(false);
  const [showBCC, setShowBCC] = useState(false);
  const inputElement = document.querySelector('[name="compose_to"]');
  const tagify = new Tagify(inputElement, {
    // Tagify options...
    whitelist: contacts,
  });

  let tagifyCc, tagifyBcc;

  // Initialize Tagify on the 'Cc' input if it's visible
  if (showCC) {
    const inputElementCc = document.querySelector('[name="compose_cc"]');
    tagifyCc = new Tagify(inputElementCc, {
      // Tagify options...
      whitelist: contacts,
    });
  }

  // Initialize Tagify on the 'Bcc' input if it's visible
  if (showBCC) {
    const inputElementBcc = document.querySelector('[name="compose_bcc"]');
    tagifyBcc = new Tagify(inputElementBcc, {
      // Tagify options...
      whitelist: contacts,
    });
  }

  const quill = new Quill('#kt_inbox_form_editor', {
    theme: 'bubble'  // or 'bubble' or null for Core
  });
  return (
    <>
      {/*begin::Container*/}
      <div
        id="kt_content_container"
        className="d-flex flex-column-fluid align-items-start container-xxl"
      >
        {/*begin::Post*/}
        <div className="content flex-row-fluid" id="kt_content">
          {/*begin::Inbox App - Compose */}
          <div className="d-flex flex-column flex-lg-row">

            {/*begin::Content*/}
            <div className="flex-lg-row-fluid ms-lg-7 ms-xl-10">
              {/*begin::Card*/}
              <div className="card">
                <div className="card-header d-flex align-items-center justify-content-between py-3">
                  <h2 className="card-title m-0">Compose Message</h2>
                  {/*begin::Toggle*/}
                  <a
                    href="#"
                    className="btn btn-sm btn-icon btn-color-primary btn-light btn-active-light-primary d-lg-none"
                    data-bs-toggle="tooltip"
                    data-bs-dismiss="click"
                    data-bs-placement="top"
                    title="Toggle inbox menu"
                    id="kt_inbox_aside_toggle"
                  >
                    <i className="ki-duotone ki-burger-menu-2 fs-3 m-0">
                      <span className="path1" />
                      <span className="path2" />
                      <span className="path3" />
                      <span className="path4" />
                      <span className="path5" />
                      <span className="path6" />
                      <span className="path7" />
                      <span className="path8" />
                      <span className="path9" />
                      <span className="path10" />
                    </i>
                  </a>
                  {/*end::Toggle*/}
                </div>
                <div className="card-body p-0">
                  {/*begin::Form*/}
                  <form id="kt_inbox_compose_form">
                    {/*begin::Body*/}
                    <div className="d-block">
                      {/*begin::To*/}
                      <div className="d-flex align-items-center border-bottom px-8 min-h-50px">
                        {/*begin::Label*/}
                        <div className="text-dark fw-bold w-75px">To:</div>
                        {/*end::Label*/}
                        {/*begin::Input*/}
                        <input
                          type="text"
                          className="form-control form-control-transparent border-0"
                          name="compose_to"
                          defaultValue=""
                          data-kt-inbox-form="tagify"
                        />
                        {/*end::Input*/}
                        {/*begin::CC & BCC buttons*/}
                        <div className="ms-auto w-75px text-end">
                          <span
                            className="text-muted fs-bold cursor-pointer text-hover-primary me-2"
                            onClick={() => setShowCC(!showCC)}
                          >
                            Cc
                          </span>
                          <span
                            className="text-muted fs-bold cursor-pointer text-hover-primary"
                            onClick={() => setShowBCC(!showBCC)}
                          >
                            Bcc
                          </span>
                        </div>
                        {/*end::CC & BCC buttons*/}
                      </div>
                      {/*end::To*/}
                      {/*begin::CC*/}
                      {showCC && (
                        <div className="d-flex align-items-center border-bottom ps-8 pe-5 min-h-50px">
                          {/*begin::Label*/}
                          <div className="text-dark fw-bold w-75px">Cc:</div>
                          {/*end::Label*/}
                          {/*begin::Input*/}
                          <input
                            type="text"
                            className="form-control form-control-transparent border-0"
                            name="compose_cc"
                            defaultValue=""
                          />
                          {/*end::Input*/}
                          {/*begin::Close*/}
                          <span
                            className="btn btn-clean btn-xs btn-icon"
                            onClick={() => setShowCC(false)}
                          >
                            <i className="ki-duotone ki-cross fs-5">
                              <span className="path1" />
                              <span className="path2" />
                            </i>
                          </span>
                          {/*end::Close*/}
                        </div>
                      )}
                      {/*end::CC*/}
                      {/*begin::BCC*/}
                      {showBCC && (
                        <div className="d-flex align-items-center border-bottom ps-8 pe-5 min-h-50px">
                          {/*begin::Label*/}
                          <div className="text-dark fw-bold w-75px">Bcc:</div>
                          {/*end::Label*/}
                          {/*begin::Input*/}
                          <input
                            type="text"
                            className="form-control form-control-transparent border-0"
                            name="compose_bcc"
                            defaultValue=""
                          />
                          {/*end::Input*/}
                          {/*begin::Close*/}
                          <span
                            className="btn btn-clean btn-xs btn-icon"
                            onClick={() => setShowBCC(false)}
                          >
                            <i className="ki-duotone ki-cross fs-5">
                              <span className="path1" />
                              <span className="path2" />
                            </i>
                          </span>
                          {/*end::Close*/}
                        </div>
                      )}
                      {/*end::BCC*/}
                      {/*begin::Subject*/}
                      <div className="border-bottom">
                        <input
                          className="form-control form-control-transparent border-0 px-8 min-h-45px"
                          name="compose_subject"
                          placeholder="Subject"
                        />
                      </div>
                      {/*end::Subject*/}
                      {/*begin::Message*/}
                      <div
                        id="kt_inbox_form_editor"
                        className="bg-transparent border-0 h-350px px-3"
                      />
                      {/*end::Message*/}
                      {/*begin::Attachments*/}
                      <div
                        className="dropzone dropzone-queue px-8 py-4"
                        id="kt_inbox_reply_attachments"
                        data-kt-inbox-form="dropzone"
                      >
                        <div className="dropzone-items">
                          <div
                            className="dropzone-item"
                            style={{ display: "none" }}
                          >
                            {/*begin::Dropzone filename*/}
                            <div className="dropzone-file">
                              <div
                                className="dropzone-filename"
                                title="some_image_file_name.jpg"
                              >
                                <span data-dz-name="">
                                  some_image_file_name.jpg
                                </span>
                                <strong>
                                  (<span data-dz-size="">340kb</span>)
                                </strong>
                              </div>
                              <div
                                className="dropzone-error"
                                data-dz-errormessage=""
                              />
                            </div>
                            {/*end::Dropzone filename*/}
                            {/*begin::Dropzone progress*/}
                            <div className="dropzone-progress">
                              <div className="progress bg-gray-300">
                                <div
                                  className="progress-bar bg-primary"
                                  role="progressbar"
                                  aria-valuemin={0}
                                  aria-valuemax={100}
                                  aria-valuenow={0}
                                  data-dz-uploadprogress=""
                                />
                              </div>
                            </div>
                            {/*end::Dropzone progress*/}
                            {/*begin::Dropzone toolbar*/}
                            <div className="dropzone-toolbar">
                              <span className="dropzone-delete" data-dz-remove="">
                                <i className="ki-duotone ki-cross fs-2">
                                  <span className="path1" />
                                  <span className="path2" />
                                </i>
                              </span>
                            </div>
                            {/*end::Dropzone toolbar*/}
                          </div>
                        </div>
                      </div>
                      {/*end::Attachments*/}
                    </div>
                    {/*end::Body*/}
                    {/*begin::Footer*/}
                    <div className="d-flex flex-stack flex-wrap gap-2 py-5 ps-8 pe-5 border-top">
                      {/*begin::Actions*/}
                      <div className="d-flex align-items-center me-3">
                        {/*begin::Send*/}
                        <div className="btn-group me-4">
                          {/*begin::Submit*/}
                          <span
                            className="btn btn-primary fs-bold px-6"
                            data-kt-inbox-form="send"
                          >
                            <span className="indicator-label">Send</span>
                            <span className="indicator-progress">
                              Please wait...
                              <span className="spinner-border spinner-border-sm align-middle ms-2" />
                            </span>
                          </span>
                          {/*end::Submit*/}
                          {/*begin::Send options*/}
                          <span
                            className="btn btn-primary btn-icon fs-bold w-30px pe-0"
                            role="button"
                          >
                            <span
                              className="lh-0"
                              data-kt-menu-trigger="click"
                              data-kt-menu-placement="top-start"
                            >
                              <i className="ki-duotone ki-down fs-4 m-0" />
                            </span>
                            {/*begin::Menu*/}
                            <div
                              className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-150px py-4"
                              data-kt-menu="true"
                            >
                              {/*begin::Menu item*/}
                              <div className="menu-item px-3">
                                <a href="#" className="menu-link px-3">
                                  Schedule send
                                </a>
                              </div>
                              {/*end::Menu item*/}
                              {/*begin::Menu item*/}
                              <div className="menu-item px-3">
                                <a href="#" className="menu-link px-3">
                                  Save &amp; archive
                                </a>
                              </div>
                              {/*end::Menu item*/}
                              {/*begin::Menu item*/}
                              <div className="menu-item px-3">
                                <a href="#" className="menu-link px-3">
                                  Cancel
                                </a>
                              </div>
                              {/*end::Menu item*/}
                            </div>
                            {/*end::Menu*/}
                          </span>
                          {/*end::Send options*/}
                        </div>
                        {/*end::Send*/}
                        {/*begin::Upload attachement*/}
                        <span
                          className="btn btn-icon btn-sm btn-clean btn-active-light-primary me-2"
                          id="kt_inbox_reply_attachments_select"
                          data-kt-inbox-form="dropzone_upload"
                        >
                          <i className="ki-duotone ki-paper-clip fs-2 m-0" />
                        </span>
                        {/*end::Upload attachement*/}
                        {/*begin::Pin*/}
                        <span className="btn btn-icon btn-sm btn-clean btn-active-light-primary">
                          <i className="ki-duotone ki-geolocation fs-2 m-0">
                            <span className="path1" />
                            <span className="path2" />
                          </i>
                        </span>
                        {/*end::Pin*/}
                      </div>
                      {/*end::Actions*/}
                      {/*begin::Toolbar*/}
                      <div className="d-flex align-items-center">
                        {/*begin::More actions*/}
                        <span
                          className="btn btn-icon btn-sm btn-clean btn-active-light-primary me-2"
                          data-toggle="tooltip"
                          title="More actions"
                        >
                          <i className="ki-duotone ki-setting-2 fs-2">
                            <span className="path1" />
                            <span className="path2" />
                          </i>
                        </span>
                        {/*end::More actions*/}
                        {/*begin::Dismiss reply*/}
                        <span
                          className="btn btn-icon btn-sm btn-clean btn-active-light-primary"
                          data-inbox="dismiss"
                          data-toggle="tooltip"
                          title="Dismiss reply"
                        >
                          <i className="ki-duotone ki-trash fs-2">
                            <span className="path1" />
                            <span className="path2" />
                            <span className="path3" />
                            <span className="path4" />
                            <span className="path5" />
                          </i>
                        </span>
                        {/*end::Dismiss reply*/}
                      </div>
                      {/*end::Toolbar*/}
                    </div>
                    {/*end::Footer*/}
                  </form>
                  {/*end::Form*/}
                </div>
              </div>
              {/*end::Card*/}
            </div>
            {/*end::Content*/}
          </div>
          {/*end::Inbox App - Compose */}
        </div>
        {/*end::Post*/}
      </div>
      {/*end::Container*/}
    </>
  );
};

export default ComposeInbox;
