// CampaignSettingsParent.tsx
import React from 'react';
import CustomizedDividers from './CampaignSchedule';
import EmailCampaignSettings from './CampaignSettings';

interface CampaignSettingsParentProps {
    selectedCampaignId: string | null;
}

const CampaignSettingsParent: React.FC<CampaignSettingsParentProps> = ({ selectedCampaignId }) => {
    return (
        <div>
            <EmailCampaignSettings selectedCampaignId={selectedCampaignId} />

            <CustomizedDividers selectedCampaignId={selectedCampaignId} />
        </div>
    );
};

export default CampaignSettingsParent;