import React from 'react';
import AdvancedLeadGenParent from './Parent-LeadGenAdvanced';
import "./AdvancedLeadGenParent.css"

const AdvancedLeadGenPage = () => {

    return (
        <div className='full-width-container' >

            <AdvancedLeadGenParent />
        </div>

    );
};

export default AdvancedLeadGenPage;
