import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import {
    Box,
    Typography,
    Collapse,
    List,
    ListItem,
    Avatar,
    Divider,
} from '@mui/material';
import ExpandMoreSharp from '@mui/icons-material/ExpandMoreSharp';

const AccountSelectorContainer = styled(Box)({
    position: 'relative',
    width: '100%',
    marginBottom: '16px',
});


const AccountToggle = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    padding: '12px 16px',
    backgroundColor: theme.palette.mode === 'dark' ? '#353535' : '#F2F2F2',
    borderRadius: '24px',
    cursor: 'pointer',
    transition: 'all 0.3s ease',
    boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
    '&:hover': {
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    },
    '&:focus': {
        outline: 'none',
        boxShadow: '0 0 0 2px rgba(156, 39, 176, 0.4)',
    },
}));

const AccountToggleArrow = styled(ExpandMoreSharp)({
    transition: 'transform 0.3s ease',
    '&.expanded': {
        transform: 'rotate(180deg)',
    },
});


const AccountToggleText = styled(Box)(({ theme }) => ({
    fontSize: '20px',
    fontWeight: 400,
    gap: '12px',
    color: theme.palette.text.primary,
    overflow: 'hidden',  // Add this line
    whiteSpace: 'nowrap',  // Add this line
    textOverflow: 'ellipsis',  // Add this line

}));


const AccountAvatar = styled(Avatar)(({ theme }) => ({
    width: '32px',
    height: '32px',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    fontSize: '16px',
    textTransform: 'uppercase',
    transition: 'transform 0.3s ease',
}));


const AccountDropdown = styled(Collapse)(({ theme }) => ({
    position: 'absolute',
    top: 'calc(100% + 8px)',
    left: 0,
    width: '100%',
    backgroundColor: theme.palette.mode === 'dark' ? '#121212' : '#F2F2F2',
    borderRadius: '12px',
    boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
    zIndex: 10,
    overflow: 'hidden',
    transition: 'all 0.3s cubic-bezier(0.68, -0.55, 0.27, 1.55)',
    opacity: 0,
    visibility: 'hidden',
    transform: 'translateY(-10px)',
    maxHeight: '400px', // Add this line
    overflowY: 'auto',  // Add this line
    '&.show': {
        opacity: 1,
        visibility: 'visible',
        transform: 'translateY(0)',
    },
}));



const AccountList = styled(List)({
    listStyleType: 'none',
    margin: 0,
    padding: 0,
});

const AccountItem = styled(ListItem)(({ theme }) => ({
    padding: '12px 16px',
    display: 'flex',
    alignItems: 'center',
    gap: '12px',
    cursor: 'pointer',
    transition: 'all 0.2s ease',
    '&:hover': {
        backgroundColor: theme.palette.action.hover,
        '& .account-avatar': {
            transform: 'scale(1.1) rotate(5deg)',
        },
    },
    '&.active': {
        backgroundColor: theme.palette.action.selected,
        color: theme.palette.primary.main,
    },
    '&:focus': {
        outline: 'none',
        boxShadow: 'inset 0 0 0 2px rgba(156, 39, 176, 0.4)',
    },
}));


const AccountEmail = styled(Typography)(({ theme }) => ({
    fontSize: '14px',
    color: theme.palette.text.primary,
    transition: 'color 0.2s ease',
    whiteSpace: 'nowrap',      // Add this line
    overflow: 'hidden',        // Add this line
    textOverflow: 'ellipsis',  // Add this line
    '&.active': {
        color: theme.palette.primary.main,
    },
}));


const AddAccount = styled(AccountItem)(({ theme }) => ({
    borderTop: `1px solid ${theme.palette.divider}`,
    color: theme.palette.primary.main,
    fontWeight: 500,
}));

const AddAccountIcon = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '32px',
    height: '32px',
    borderRadius: '50%',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    fontSize: '24px',
    fontWeight: 700,
    transition: 'transform 0.2s ease',
    '&:hover': {
        transform: 'scale(1.1)',
    },
}));

const AccountSelectorDropdown = ({ accounts, selectedAccount, handleAccountChange }) => {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    const selectAccount = (account) => {
        handleAccountChange(account);
        setIsDropdownOpen(false);
    };

    return (
        <AccountSelectorContainer>
            <AccountToggle
                onClick={toggleDropdown}
                aria-haspopup="listbox"
                aria-expanded={isDropdownOpen}
                className={isDropdownOpen ? 'active' : ''}
            >
                <AccountToggleText>
                    {/* <AccountAvatar>{selectedAccount?.label?.charAt(0).toUpperCase() || ''}</AccountAvatar> */}
                    <Typography
                        className="account-email"
                        noWrap
                        style={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            fontFamily: 'Playfair Display, serif',
                            fontSize: '20px',
                            fontWeight: 400
                        }}
                    >
                        {selectedAccount?.email || 'All Accounts'}
                    </Typography>

                </AccountToggleText>
                <AccountToggleArrow className={isDropdownOpen ? 'expanded' : ''} />
            </AccountToggle>
            <AccountDropdown in={isDropdownOpen} className={isDropdownOpen ? 'show' : ''}>
                <AccountList role="listbox">
                    <AddAccount
                        role="option"
                        aria-selected={selectedAccount.value === 'all'}
                        className={selectedAccount.value === 'all' ? 'active' : ''}
                        onClick={() => selectAccount({ type: 'all', value: 'all', label: 'All Accounts', email: 'All Accounts' })}
                    >
                        <AccountAvatar>A</AccountAvatar>
                        <AccountEmail>All Accounts</AccountEmail>
                    </AddAccount>
                    <Divider />
                    {accounts.map((account) => (
                        <AccountItem
                            key={account.emailAccountId}
                            role="option"
                            aria-selected={selectedAccount.emailAccountId === account.emailAccountId}
                            className={selectedAccount.emailAccountId === account.emailAccountId ? 'active' : ''}
                            onClick={() => selectAccount(account)}
                        >
                            <AccountAvatar>{account.label.charAt(0).toUpperCase()}</AccountAvatar>
                            <AccountEmail noWrap>{account.email}</AccountEmail>
                        </AccountItem>
                    ))}
                </AccountList>
            </AccountDropdown>
        </AccountSelectorContainer>
    );
};

export default AccountSelectorDropdown;
