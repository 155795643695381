// src\app\pages\0-Account\ProfilePage\ProviderPreferences.tsx
import { EmailSharp, TextFieldsSharp } from '@mui/icons-material';
import {
    Alert,
    Button,
    CircularProgress,
    Divider,
    FormControl,
    Grid,
    InputLabel,
    ListItemIcon,
    ListItemText,
    MenuItem,
    Select,
    Snackbar,
} from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { BsGoogle } from 'react-icons/bs';
import { FaRobot } from 'react-icons/fa';
import { SiOpenai } from 'react-icons/si';
import { useMutation, useQuery } from 'react-query';



// Define the interface for the state
interface ProviderSelectionState {
    textGenerationPrimary: string;
    textGenerationSecondary: string;
    emailValidationPrimary: string;
    emailValidationSecondary: string;
}

const fetchProviderPreferences = async () => {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/integrations/all`);
    return response.data.providerPreferences;
};

const updateProviderPreference = async (preferenceDetails) => {
    const { preferenceType, service, provider } = preferenceDetails;
    const url = `${process.env.REACT_APP_API_URL}/integrations/provider-preferences/${preferenceType}`;
    const response = await axios.post(url, { service, provider });
    return response.data;
};




const ProviderPreferences = () => {
    const [selectedProviders, setSelectedProviders] = useState<ProviderSelectionState>({
        textGenerationPrimary: '',
        textGenerationSecondary: '',
        emailValidationPrimary: '',
        emailValidationSecondary: '',
    });

    const [customAIModel, setCustomAIModel] = useState('');

    const [snackbarInfo, setSnackbarInfo] = useState({
        open: false,
        message: '',
        severity: 'info'
    });

    const { data, isLoading, error, refetch } = useQuery('providerPreferences', fetchProviderPreferences);
    const updateMutation = useMutation(updateProviderPreference, {
        onSuccess: (data) => {
            refetch();
            setSnackbarInfo({
                open: true,
                message: data.message || 'Update successful!',
                severity: 'success'
            });
        },
        onError: (error: any) => {
            setSnackbarInfo({
                open: true,
                message: error.response?.data?.message || 'Failed to update preferences',
                severity: 'error'
            });
        }
    });

    useEffect(() => {
        if (data) {
            setSelectedProviders({
                textGenerationPrimary: data.textGeneration.primary,
                textGenerationSecondary: data.textGeneration.secondary,
                emailValidationPrimary: data.emailValidation.primary,
                emailValidationSecondary: data.emailValidation.secondary,
            });
        }
    }, [data]);

    const handleProviderChange = (event) => {
        const { name, value } = event.target;
        setSelectedProviders(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handleUpdateProvider = (preferenceType, service) => {
        // Correctly identify the state key based on the combination of service and preferenceType
        const providerKey = `${service}${preferenceType.charAt(0).toUpperCase() + preferenceType.slice(1)}`;
        const provider = selectedProviders[providerKey];

        if (provider) {
            updateMutation.mutate({ preferenceType, service, provider });
        } else {
            console.error('Provider value is undefined', providerKey);
        }
    };


    const handleCloseSnackbar = () => {
        setSnackbarInfo(prev => ({
            ...prev,
            open: false
        }));
    };

    const fetchCustomAIModel = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/integrations/customai/model`);
            return response.data.model;
        } catch (error: any) {
            setSnackbarInfo({
                open: true,
                message: error.response?.data?.message || 'Failed to fetch Custom AI model',
                severity: 'error'
            });
            throw error; // Re-throw the error to stop further execution if needed
        }
    };

    const updateCustomAIModel = async (model) => {
        try {
            const response = await axios.put(`${process.env.REACT_APP_API_URL}/integrations/customai/model`, { model });
            setSnackbarInfo({
                open: true,
                message: response.data.message || 'Custom AI model updated successfully',
                severity: 'success'
            });
            return response.data;
        } catch (error: any) {
            setSnackbarInfo({
                open: true,
                message: error.response?.data?.message || 'Failed to update Custom AI model',
                severity: 'error'
            });
            throw error; // Re-throw the error to stop further execution if needed
        }
    };

    useEffect(() => {
        const getCustomAIModel = async () => {
            try {
                const model = await fetchCustomAIModel();
                setCustomAIModel(model);
            } catch (error) {
                console.error("Error fetching Custom AI model", error);
            }
        };
        getCustomAIModel();
    }, []);





    if (isLoading) return <CircularProgress />;
    if (error) return <div>Error loading preferences</div>;

    return (
        <div>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <Grid container direction="column" spacing={1}>
                        <Grid item>
                            <TextFieldsSharp color='info' fontSize="large" />
                        </Grid>
                        <Grid item>
                            <FormControl fullWidth margin="normal">
                                <InputLabel id="text-generation-primary-label">Primary Text Generation Provider</InputLabel>
                                <Select
                                    labelId="text-generation-primary-label"
                                    id="text-generation-primary-select"
                                    value={selectedProviders.textGenerationPrimary}
                                    label="Primary Text Generation Provider"
                                    name="textGenerationPrimary"
                                    onChange={handleProviderChange}
                                >
                                    <MenuItem value="None">None</MenuItem>
                                    <MenuItem value="OpenAI">
                                        <ListItemIcon>
                                            <SiOpenai />
                                        </ListItemIcon>
                                        <ListItemText primary="OpenAI" />
                                    </MenuItem>
                                    <MenuItem value="GoogleGemini">
                                        <ListItemIcon>
                                            <BsGoogle />
                                        </ListItemIcon>
                                        <ListItemText primary="GoogleGemini" />
                                    </MenuItem>
                                    <MenuItem value="CustomAI">
                                        <ListItemIcon>
                                            <FaRobot />
                                        </ListItemIcon>
                                        <ListItemText primary="CustomAI" />
                                    </MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item>
                            <Button onClick={() => handleUpdateProvider('primary', 'textGeneration')} >
                                Update Primary
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={6}>
                    {/* 
// ! ||--------------------------------------------------------------------------------||
// ! ||                                    secondary                                   ||
// ! ||--------------------------------------------------------------------------------|| */}

                    {/* <Grid container direction="column" spacing={1}>
                        <Grid item>
                            <TextFieldsSharp color='info' fontSize="large" />
                        </Grid>
                        <Grid item>
                            <FormControl fullWidth margin="normal">
                                <InputLabel id="text-generation-secondary-label">Secondary Text Generation Provider</InputLabel>
                                <Select
                                    labelId="text-generation-secondary-label"
                                    id="text-generation-secondary-select"
                                    value={selectedProviders.textGenerationSecondary}
                                    label="Secondary Text Generation Provider"
                                    name="textGenerationSecondary"
                                    onChange={handleProviderChange}
                                >
                                    <MenuItem value="None">None</MenuItem>
                                    <MenuItem value="OpenAI">
                                        <ListItemIcon>
                                            <SiOpenai />
                                        </ListItemIcon>
                                        <ListItemText primary="OpenAI" />
                                    </MenuItem>
                                    <MenuItem value="GoogleGemini">
                                        <ListItemIcon>
                                            <BsGoogle />
                                        </ListItemIcon>
                                        <ListItemText primary="GoogleGemini" />
                                    </MenuItem>
                                      <MenuItem value="CustomAI">
    <ListItemIcon>
      <FaRobot />
    </ListItemIcon>
    <ListItemText primary="CustomAI" />
  </MenuItem>

                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item>
                            <Button onClick={() => handleUpdateProvider('secondary', 'textGeneration')} >
                                Update Secondary
                            </Button>
                        </Grid>
                    </Grid> */}
                </Grid>

                {(selectedProviders.textGenerationPrimary === 'CustomAI' || selectedProviders.textGenerationSecondary === 'CustomAI') && (
                    <Grid item xs={12}>
                        <Grid container direction="column" spacing={1}>
                            <Grid item>
                                <FormControl fullWidth margin="normal">
                                    <InputLabel id="custom-ai-model-label">Custom AI Model</InputLabel>
                                    <Select
                                        labelId="custom-ai-model-label"
                                        id="custom-ai-model-select"
                                        value={customAIModel}
                                        label="Custom AI Model"
                                        onChange={(event) => setCustomAIModel(event.target.value)}
                                    >
                                        <MenuItem value="llama3-8b-8192">llama3-8b-8192</MenuItem>
                                        <MenuItem value="llama3-70b-8192">llama3-70b-8192</MenuItem>
                                        <MenuItem value="mixtral-8x7b-32768">mixtral-8x7b-32768</MenuItem>
                                        <MenuItem value="gemma-7b-it">gemma-7b-it</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item>
                                <Button onClick={async () => {
                                    try {
                                        await updateCustomAIModel(customAIModel);
                                    } catch (error) {
                                        console.error("Error updating Custom AI model", error);
                                    }
                                }}>
                                    Update Custom AI Model
                                </Button>

                            </Grid>
                        </Grid>
                    </Grid>
                )}



                <Divider variant="middle" style={{ margin: '16px 0' }} />
                <Grid item xs={12} sm={6}>
                    <Grid container direction="column" spacing={1}>
                        <Grid item>
                            <EmailSharp color='info' fontSize="large" />
                        </Grid>
                        <Grid item>
                            <FormControl fullWidth margin="normal">
                                <InputLabel id="email-validation-primary-label">Primary Email Validation Provider</InputLabel>
                                <Select
                                    labelId="email-validation-primary-label"
                                    id="email-validation-primary-select"
                                    value={selectedProviders.emailValidationPrimary}
                                    label="Primary Email Validation Provider"
                                    name="emailValidationPrimary"
                                    onChange={handleProviderChange}
                                >
                                    <MenuItem value="None">None</MenuItem>
                                    <MenuItem value="Reoon">Reoon</MenuItem>
                                    <MenuItem value="EmailListValidation">EmailListValidation</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item>
                            <Button onClick={() => handleUpdateProvider('primary', 'emailValidation')} >
                                Update Primary
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Grid container direction="column" spacing={1}>
                        <Grid item>
                            <EmailSharp color='info' fontSize="large" />
                        </Grid>
                        <Grid item>
                            <FormControl fullWidth margin="normal">
                                <InputLabel id="email-validation-secondary-label">Secondary Email Validation Provider</InputLabel>
                                <Select
                                    labelId="email-validation-secondary-label"
                                    id="email-validation-secondary-select"
                                    value={selectedProviders.emailValidationSecondary}
                                    label="Secondary Email Validation Provider"
                                    name="emailValidationSecondary"
                                    onChange={handleProviderChange}
                                >
                                    <MenuItem value="None">None</MenuItem>
                                    <MenuItem value="Reoon">Reoon</MenuItem>
                                    <MenuItem value="EmailListValidation">EmailListValidation</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item>
                            <Button onClick={() => handleUpdateProvider('secondary', 'emailValidation')} >
                                Update Secondary
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>


            <Snackbar open={snackbarInfo.open} autoHideDuration={6000} onClose={handleCloseSnackbar}>
                <Alert onClose={handleCloseSnackbar}
                    //@ts-ignore  
                    severity={snackbarInfo.severity} sx={{ width: '100%' }}>
                    {snackbarInfo.message}
                </Alert>
            </Snackbar>

        </div>
    );
};

export default ProviderPreferences;
