import React, { useEffect, useState } from 'react';
import { useTheme } from '@mui/material';
import Chart from 'react-apexcharts';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { useAuth } from '@/src/app/modules/auth'; // Ensure path is correct

dayjs.extend(utc);
dayjs.extend(timezone);

interface SendingAccountData {
    totalMessages: number;
    sendingTimesUTC: string[];
}

interface PersonalizedMessagesChartProps {
    data: { [key: string]: SendingAccountData };
}

interface TimelineSeriesData {
    name: string;
    data: {
        x: string;
        y: number[];
    }[];
}

const UpcomingPersonalizedMessagesChart: React.FC<PersonalizedMessagesChartProps> = ({ data }) => {
    const theme = useTheme();
    const { currentUser } = useAuth();
    const [timelineSeriesData, setTimelineSeriesData] = useState<TimelineSeriesData[]>([]);

    useEffect(() => {
        if (currentUser) {
            const updatedTimelineSeriesData: TimelineSeriesData[] = Object.entries(data).map(([account, accountData]) => ({
                name: account,
                data: accountData.sendingTimesUTC.map(time => {
                    const localTime = dayjs(time).tz(currentUser.timezone);
                    return {
                        x: 'Sending Time',
                        y: [
                            localTime.valueOf(),
                            localTime.add(1, 'minute').valueOf() // Add 1 minute for visibility
                        ]
                    };
                })
            }));
            setTimelineSeriesData(updatedTimelineSeriesData);
        }
    }, [currentUser, data]);

    // Prepare data for the bar chart
    const barChartData = [{
        name: 'Total Messages',
        data: Object.keys(data).map(account => data[account].totalMessages)
    }];

    const barChartCategories = Object.keys(data);

    const barChartOptions: ApexCharts.ApexOptions = {
        chart: {
            type: 'bar',
            height: 350,
            toolbar: {
                show: false
            }
        },
        colors: [theme.palette.primary.main],
        plotOptions: {
            bar: {
                horizontal: true,
                columnWidth: '50%',
            }
        },
        dataLabels: {
            enabled: true,
            style: {
                colors: ['#fff'],
                fontSize: '12px'
            }
        },
        xaxis: {
            categories: barChartCategories,
            labels: {
                style: {
                    colors: theme.palette.text.secondary,
                    fontSize: '12px'
                }
            }
        },
        yaxis: {
            labels: {
                style: {
                    colors: theme.palette.text.secondary,
                    fontSize: '12px'
                }
            }
        },
        grid: {
            borderColor: theme.palette.grey[400]
        },
        tooltip: {
            y: {
                formatter: (val: number) => `${val} messages`
            }
        }
    };

    const timelineOptions: ApexCharts.ApexOptions = {
        chart: {
            height: 350,
            type: 'rangeBar',
            toolbar: {
                show: true, // Enable the toolbar
                tools: {
                    zoom: true, // Enable zooming
                    zoomin: true, // Enable zoom in control
                    zoomout: true, // Enable zoom out control
                    pan: true, // Enable panning
                    reset: true // Enable reset zoom control
                },
                autoSelected: 'zoom' // Set the default selected tool to zoom
            }
        },
        plotOptions: {
            bar: {
                horizontal: true,
                barHeight: '50%',
                rangeBarGroupRows: true
            }
        },
        colors: [
            theme.palette.primary.main,
            theme.palette.secondary.main,
            theme.palette.success.main,
            theme.palette.warning.main,
            theme.palette.error.main,
        ],
        xaxis: {
            type: 'datetime',
            labels: {
                style: {
                    colors: theme.palette.text.secondary,
                    fontSize: '12px'
                },
                datetimeFormatter: {
                    hour: 'HH:mm'
                }
            }
        },
        yaxis: {
            labels: {
                style: {
                    colors: theme.palette.text.secondary,
                    fontSize: '12px'
                }
            }
        },
        tooltip: {
            x: {
                format: 'HH:mm'
            }
        },
        legend: {
            position: 'top',
            horizontalAlign: 'left'
        }
    };

    return (
        <div>
            <div>
                <h3>Total Messages per Account</h3>
                <Chart
                    options={barChartOptions}
                    series={barChartData}
                    type="bar"
                    height={350}
                />
            </div>
            <div>
                <h3>Message Sending Times Distribution</h3>
                <Chart
                    options={timelineOptions}
                    series={timelineSeriesData}
                    type="rangeBar"
                    height={350}
                />
            </div>
        </div>
    );
};

export default UpcomingPersonalizedMessagesChart;
