// src\app\pages\uploadcsv2\EditableHeader.tsx

import React, { useState } from 'react';
import { Typography, TextField } from '@mui/material';

const EditableHeader = ({ originalHeader, editableHeader, onChange }) => {
    console.log({ originalHeader, editableHeader });
    const [inputValue, setInputValue] = useState(editableHeader);

    const handleBlur = () => {
        onChange(inputValue);
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'start', height: '100px' }}>
            <Typography variant="caption">
                {originalHeader}
            </Typography>
            <TextField
                size="small"
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                onBlur={handleBlur}
                variant="filled"
                fullWidth
                InputProps={{
                    disableUnderline: true,
                }}
            />
        </div>
    );
};

export default EditableHeader;
