import { ErrorOutlineSharp } from '@mui/icons-material';
import { Box, Button, DialogContent, Paper, Skeleton, Typography } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react';

import DraggableDialog from '../../../modules/CustomMaterialUiComponents/DraggableDialog';
import { Lead, LeadDetailDialogProps } from './DetailedView/DialogInterfaces';
import EducationComponent from './DetailedView/EducationComponent';
import ExperienceComponent from './DetailedView/ExperienceComponent';
import PersonalComponent from './DetailedView/PersonalComponent';
import ProfilesComponent from './DetailedView/ProfilesComponent';
import TabPanelComponent from './DetailedView/TabPanelComponent';
import TabsComponent from './DetailedView/TabsComponent';

const LeadDetailDialog: React.FC<LeadDetailDialogProps> = ({ leadId, onClose }) => {
    const [leadData, setLeadData] = useState<Lead | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<boolean>(false);
    const [selectedTab, setSelectedTab] = useState(0);

    const fetchLeadData = async () => {
        setLoading(true);
        setError(false);
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/advancedleadgen/onelead/${leadId}`);
            setLeadData(response.data);
        } catch (error) {
            console.error('Error fetching lead data:', error);
            setError(true);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (leadId) {
            fetchLeadData();
        }
    }, [leadId]);

    const handleTabChange = (event, newValue) => {
        setSelectedTab(newValue);
    };

    function TabPanel(props) {
        const { children, value, index } = props;

        return (
            <div role="tabpanel" hidden={value !== index}>
                {value === index && (
                    <Box p={3}>
                        <Typography>{children}</Typography>
                    </Box>
                )}
            </div>
        );
    }

    return (
        <DraggableDialog
            open={leadId !== null}
            onClose={onClose}
            title={`Lead Details - ${leadData?.full_name || 'Unknown'}`}
            content={
                <div>
                    <DialogContent>
                        {loading ? (
                            <>
                                <Skeleton variant="rectangular" width="80%" height="500px" />
                                {/* Add more Skeleton components as needed */}
                            </>
                        ) : error ? (
                            <Paper sx={{ padding: "5px", textAlign: "center" }}>
                                <ErrorOutlineSharp color="error" fontSize="large" />
                                <Typography variant="h6" color="error" sx={{ mt: 1 }}>
                                    Failed to load data. Please try again.
                                </Typography>
                                <Button
                                    variant="text"
                                    color="primary"
                                    onClick={fetchLeadData}
                                    sx={{ mt: 2, px: 4, py: 1, fontWeight: 'bold', textTransform: 'none' }}
                                >
                                    Reload
                                </Button>
                            </Paper>

                        ) : leadData ? (
                            <div>
                                <TabsComponent selectedTab={selectedTab} handleTabChange={handleTabChange} />

                                <TabPanelComponent value={selectedTab} index={0}>
                                    <PersonalComponent leadData={leadData} />
                                </TabPanelComponent>

                                <TabPanelComponent value={selectedTab} index={1}>
                                    <ExperienceComponent leadData={leadData} />
                                </TabPanelComponent>

                                <TabPanelComponent value={selectedTab} index={2}>
                                    <EducationComponent leadData={leadData} />
                                </TabPanelComponent>

                                <TabPanelComponent value={selectedTab} index={3}>
                                    <ProfilesComponent leadData={leadData} />
                                </TabPanelComponent>
                            </div>
                        ) : (
                            <Typography>No data available for this lead.</Typography>
                        )}
                    </DialogContent>
                </div>
            }
        />
    );
};

export default LeadDetailDialog;
