// src/app/pages/4-Campaigns/3-CampaignSequence/SequenceComponents/NewEditor/components/CustomOnChangePlugin.tsx
import { useEffect } from 'react';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $getRoot } from 'lexical';
import { $generateHtmlFromNodes, $generateNodesFromDOM } from "@lexical/html";
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';
import React from 'react';
const CustomOnChangePlugin = ({ onContentChange, emailType }) => {

    const [editor] = useLexicalComposerContext();

    const onChange = (editorState) => {
        editorState.read(() => {
            if (emailType === 'html') {
                let htmlContent = $generateHtmlFromNodes(editor, null);
                // Process to replace the specific mention node representation
                htmlContent = htmlContent.replace(/<span data-lexical-mention="true">([^<]+)<\/span>/g, (match, mentionText) => {
                    // Optionally, adjust the replacement format as needed
                    return `${mentionText}`;
                });
                onContentChange(htmlContent);
            } else {
                const root = $getRoot();
                const textContent = root.getTextContent();
                onContentChange(textContent);
            }
        });
    };

    return (
        <React.Fragment>
            <OnChangePlugin onChange={onChange} />
        </React.Fragment>
    );
};

export default CustomOnChangePlugin;
