// src\app\pages\LeadGenAdvanced\DetailedView\ExperienceComponent.tsx
import React from 'react';
import { Box, Typography, Grid, Card, CardContent, CardHeader, Avatar, Link, List, ListItem, Divider, Chip, Paper } from '@mui/material';
import WorkIcon from '@mui/icons-material/Work';
import BusinessIcon from '@mui/icons-material/Business';

const ExperienceComponent = ({ leadData }) => {
    const formatUrl = (url) => {
        try {
            return new URL(url).href;
        } catch {
            return url.startsWith('http') ? url : `http://${url}`;
        }
    };

    return (
        <Box>
            <Typography variant="h4" gutterBottom align="center" sx={{ fontWeight: 'bold', color: 'primary.main' }}>
                Experience
            </Typography>
            <List>
                {leadData.experience.map((exp, index) => (
                    <ListItem key={index} disableGutters>
                        <Card variant="outlined" sx={{ width: '100%', mb: 2 }}>
                            <CardHeader
                                avatar={
                                    <Avatar>
                                        <WorkIcon />
                                    </Avatar>
                                }
                                title={exp.company?.name}
                                subheader={exp.title?.name}
                                action={
                                    <Chip label={exp.company?.industry} icon={<BusinessIcon />} />
                                }
                            />
                            <CardContent>
                                <Grid container spacing={2}>
                                    {exp.company?.size && (
                                        <Grid item xs={12} sm={6}>
                                            <Paper variant="outlined" sx={{ p: 1 }}>
                                                <Typography variant="subtitle2"><strong>Company Size:</strong> {exp.company.size}</Typography>
                                            </Paper>
                                        </Grid>
                                    )}
                                    {exp.company?.founded && (
                                        <Grid item xs={12} sm={6}>
                                            <Paper variant="outlined" sx={{ p: 1 }}>
                                                <Typography variant="subtitle2"><strong>Founded:</strong> {exp.company.founded}</Typography>
                                            </Paper>
                                        </Grid>
                                    )}
                                    {exp.company?.location?.name && (
                                        <Grid item xs={12} sm={6}>
                                            <Paper variant="outlined" sx={{ p: 1 }}>
                                                <Typography variant="subtitle2"><strong>Location Name:</strong> {exp.company.location.name}</Typography>
                                            </Paper>
                                        </Grid>
                                    )}
                                    {exp.company?.location?.locality && (
                                        <Grid item xs={12} sm={6}>
                                            <Paper variant="outlined" sx={{ p: 1 }}>
                                                <Typography variant="subtitle2"><strong>Locality:</strong> {exp.company.location.locality}</Typography>
                                            </Paper>
                                        </Grid>
                                    )}
                                    {exp.company?.location?.region && (
                                        <Grid item xs={12} sm={6}>
                                            <Paper variant="outlined" sx={{ p: 1 }}>
                                                <Typography variant="subtitle2"><strong>Region:</strong> {exp.company.location.region}</Typography>
                                            </Paper>
                                        </Grid>
                                    )}
                                    {exp.company?.location?.country && (
                                        <Grid item xs={12} sm={6}>
                                            <Paper variant="outlined" sx={{ p: 1 }}>
                                                <Typography variant="subtitle2"><strong>Country:</strong> {exp.company.location.country}</Typography>
                                            </Paper>
                                        </Grid>
                                    )}
                                    {exp.company?.location?.continent && (
                                        <Grid item xs={12} sm={6}>
                                            <Paper variant="outlined" sx={{ p: 1 }}>
                                                <Typography variant="subtitle2"><strong>Continent:</strong> {exp.company.location.continent}</Typography>
                                            </Paper>
                                        </Grid>
                                    )}
                                    {exp.company?.linkedin_url && (
                                        <Grid item xs={12} sm={6}>
                                            <Paper variant="outlined" sx={{ p: 1 }}>
                                                <Typography variant="subtitle2">
                                                    <strong>LinkedIn:</strong> <Link href={formatUrl(exp.company.linkedin_url)} target="_blank" rel="noopener noreferrer">{exp.company.linkedin_url}</Link>
                                                </Typography>
                                            </Paper>
                                        </Grid>
                                    )}
                                    {exp.company?.facebook_url && (
                                        <Grid item xs={12} sm={6}>
                                            <Paper variant="outlined" sx={{ p: 1 }}>
                                                <Typography variant="subtitle2">
                                                    <strong>Facebook:</strong> <Link href={formatUrl(exp.company.facebook_url)} target="_blank" rel="noopener noreferrer">{exp.company.facebook_url}</Link>
                                                </Typography>
                                            </Paper>
                                        </Grid>
                                    )}
                                    {exp.company?.twitter_url && (
                                        <Grid item xs={12} sm={6}>
                                            <Paper variant="outlined" sx={{ p: 1 }}>
                                                <Typography variant="subtitle2">
                                                    <strong>Twitter:</strong> <Link href={formatUrl(exp.company.twitter_url)} target="_blank" rel="noopener noreferrer">{exp.company.twitter_url}</Link>
                                                </Typography>
                                            </Paper>
                                        </Grid>
                                    )}
                                    {exp.company?.website && (
                                        <Grid item xs={12} sm={6}>
                                            <Paper variant="outlined" sx={{ p: 1 }}>
                                                <Typography variant="subtitle2">
                                                    <strong>Website:</strong> <Link href={formatUrl(exp.company.website)} target="_blank" rel="noopener noreferrer">{exp.company.website}</Link>
                                                </Typography>
                                            </Paper>
                                        </Grid>
                                    )}
                                    {exp.location_names?.length > 0 && (
                                        <Grid item xs={12} sm={6}>
                                            <Paper variant="outlined" sx={{ p: 1 }}>
                                                <Typography variant="subtitle2"><strong>Location Names:</strong> {exp.location_names.join(', ')}</Typography>
                                            </Paper>
                                        </Grid>
                                    )}
                                    {exp.start_date && (
                                        <Grid item xs={12} sm={6}>
                                            <Paper variant="outlined" sx={{ p: 1 }}>
                                                <Typography variant="subtitle2"><strong>Start Date:</strong> {exp.start_date}</Typography>
                                            </Paper>
                                        </Grid>
                                    )}
                                    {exp.end_date && (
                                        <Grid item xs={12} sm={6}>
                                            <Paper variant="outlined" sx={{ p: 1 }}>
                                                <Typography variant="subtitle2"><strong>End Date:</strong> {exp.end_date}</Typography>
                                            </Paper>
                                        </Grid>
                                    )}
                                    {exp.title?.role && (
                                        <Grid item xs={12} sm={6}>
                                            <Paper variant="outlined" sx={{ p: 1 }}>
                                                <Typography variant="subtitle2"><strong>Role:</strong> {exp.title.role}</Typography>
                                            </Paper>
                                        </Grid>
                                    )}
                                    {exp.title?.sub_role && (
                                        <Grid item xs={12} sm={6}>
                                            <Paper variant="outlined" sx={{ p: 1 }}>
                                                <Typography variant="subtitle2"><strong>Sub Role:</strong> {exp.title.sub_role}</Typography>
                                            </Paper>
                                        </Grid>
                                    )}
                                    {exp.title?.levels?.length > 0 && (
                                        <Grid item xs={12} sm={6}>
                                            <Paper variant="outlined" sx={{ p: 1 }}>
                                                <Typography variant="subtitle2"><strong>Levels:</strong> {exp.title.levels.join(', ')}</Typography>
                                            </Paper>
                                        </Grid>
                                    )}
                                    {exp.is_primary !== undefined && (
                                        <Grid item xs={12} sm={6}>
                                            <Paper variant="outlined" sx={{ p: 1 }}>
                                                <Typography variant="subtitle2"><strong>Is Primary:</strong> {exp.is_primary ? 'Yes' : 'No'}</Typography>
                                            </Paper>
                                        </Grid>
                                    )}
                                    {exp.summary && (
                                        <Grid item xs={12}>
                                            <Divider sx={{ my: 2 }} />
                                            <Typography variant="body2"><strong>Summary:</strong> {exp.summary}</Typography>
                                        </Grid>
                                    )}
                                </Grid>
                            </CardContent>
                        </Card>
                    </ListItem>
                ))}
            </List>
        </Box>
    );
};

export default ExperienceComponent;
