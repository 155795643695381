import CustomTooltip from '@/src/app/modules/CustomMaterialUiComponents/CustomTooltip';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import EmailSharpIcon from '@mui/icons-material/EmailSharp';
import PeopleSharpIcon from '@mui/icons-material/PeopleSharp';
import SecurityUpdateGoodSharpIcon from '@mui/icons-material/SecurityUpdateGoodSharp';
import { Badge, Grid, Paper, styled, Typography, useTheme } from '@mui/material';
import { motion } from 'framer-motion';
import React from 'react';
import { SiCoffeescript } from 'react-icons/si';

const DetailBox = styled(motion.div)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    gap: theme.spacing(2),
    width: 200, // Increased width for better visibility
    height: 200, // Increased height for better visibility
    padding: theme.spacing(2),
    borderRadius: "0px",
    color: theme.palette.secondary.main,
    transition: 'background-color 0.2s ease, transform 0.2s ease',
    '&:hover': {
        backgroundColor: 'var(--bs-input-bg)',
        border: theme.palette.mode === 'light' ? 'solid 3px #F79010' : 'none',
    },
    '& svg': {
        fontSize: '4rem', // Increased icon size
    }
}));

const CampaignDetailsBox = ({ campaignStats }) => {
    const theme = useTheme();

    const dataItems = [
        {
            label: "Targeted Leads",
            value: campaignStats?.leads.numberOfLeadsInList,
            Icon: PeopleSharpIcon,
            tooltip: "Total number of leads"
        },
        {
            label: "Icebreakers",
            value: campaignStats?.leads.leadsWithIcebreakers,
            Icon: SiCoffeescript,
            tooltip: "Number of icebreakers used"
        },
        {
            label: "Leads with Email",
            value: campaignStats?.leads.leadsWithEmail,
            Icon: EmailSharpIcon,
            tooltip: "Number of leads with an email"
        },
        {
            label: "Leads with Phone",
            value: campaignStats?.leads.leadsWithPhone,
            Icon: SecurityUpdateGoodSharpIcon,
            tooltip: "Number of leads with a phone"
        },
        {
            label: "Leads with Timezone",
            value: campaignStats?.leads.leadsWithTimezoneDetected,
            Icon: AccessTimeIcon,
            tooltip: `Number of leads with detected timezones. ${campaignStats?.leads.leadsWithLocationInfo} leads have location info that can be used to detect timezone using the timezone detector feature to launch time-aware campaigns.`
        }
    ];



    const detailItem = (item) => (
        <Grid item xs={4} key={item.label}>
            <CustomTooltip
                title={item.tooltip}

            >
                <DetailBox
                    whileHover={{ scale: 1.1, transition: { type: 'spring', stiffness: 400 } }}
                    whileTap={{ scale: 0.9 }}
                >
                    <Badge
                        badgeContent={item.value === 0 ? "0" : item.value}
                        color="primary"
                        sx={{ '& .css-1e3307w-MuiBadge-badge': { fontSize: '1.55rem' } }} // Inline sx to target the badge content
                    >
                        <item.Icon />
                    </Badge>
                    <Typography variant="subtitle1" sx={{ fontWeight: 'bold', color: theme.palette.info.main }}>
                        {item.label}
                    </Typography>
                </DetailBox>
            </CustomTooltip>
        </Grid>
    );

    return (
        <Paper elevation={0} sx={{ maxWidth: 600, margin: 'auto', background: "transparent" }}>
            <Grid container spacing={4} justifyContent="center" alignItems="center">
                {dataItems.map(detailItem)}
            </Grid>
        </Paper>
    );
};

export default CampaignDetailsBox;
