// src\app\pages\4-Campaigns\3-CampaignSequence\SequenceComponents\NewEditor\components\LexicalEditorTopBar\toolbarIconsList.tsx
//@ts-nocheck
import FormatBoldOutlinedIcon from "@mui/icons-material/FormatBoldOutlined";
import FormatItalicOutlinedIcon from "@mui/icons-material/FormatItalicOutlined";
import FormatUnderlinedOutlinedIcon from "@mui/icons-material/FormatUnderlinedOutlined";
import StrikethroughSOutlinedIcon from "@mui/icons-material/StrikethroughSOutlined";
import AddLinkSharpIcon from '@mui/icons-material/AddLinkSharp';
import FormatAlignLeftOutlinedIcon from "@mui/icons-material/FormatAlignLeftOutlined";
import FormatAlignRightOutlinedIcon from "@mui/icons-material/FormatAlignRightOutlined";
import FormatAlignJustifyOutlinedIcon from "@mui/icons-material/FormatAlignJustifyOutlined";
import FormatQuoteIcon from "@mui/icons-material/FormatQuote";
import { LuHeading1 } from "react-icons/lu";
import { LuHeading2 } from "react-icons/lu";
import FormatText from "mdi-material-ui/FormatText";
import CodeIcon from "@mui/icons-material/Code";
import { RedoSharp } from "@mui/icons-material";
import { UndoSharp } from "@mui/icons-material";
import FormatListNumberedSharpIcon from '@mui/icons-material/FormatListNumberedSharp';
import FormatListBulletedSharpIcon from '@mui/icons-material/FormatListBulletedSharp';
import { AddPhotoAlternateSharp } from "@mui/icons-material";
export const eventTypes = {
  paragraph: "paragraph",
  h1: "h1",
  h2: "h2",
  ul: "ul",
  ol: "ol",
  quote: "quote",
  formatCode: "formatCode",
  formatUndo: "formatUndo",
  formatRedo: "formatRedo",
  formatBold: "formatBold",
  formatItalic: "formatItalic",
  formatUnderline: "formatUnderline",
  formatStrike: "formatStrike",
  formatInsertLink: "formatInsertLink",
  formatAlignLeft: "formatAlignLeft",
  formatAlignCenter: "formatAlignCenter",
  formatAlignRight: "formatAlignRight",
  insertImage: "insertImage",
};

const pluginsList = [
  {
    id: 1,
    Icon: FormatText,
    event: eventTypes.paragraph,
  },
  {
    id: 2,
    Icon: LuHeading1,
    event: eventTypes.h1,
  },
  {
    id: 3,
    Icon: LuHeading2,
    event: eventTypes.h2,
  },
  {
    id: 4,
    Icon: FormatListBulletedSharpIcon,
    event: eventTypes.ul,
  },

  {
    id: 5,
    Icon: FormatListNumberedSharpIcon,
    event: eventTypes.ol,
  },
  {
    id: 6,
    Icon: FormatQuoteIcon,
    event: eventTypes.quote,
  },

  {
    id: 7,
    Icon: CodeIcon,
    event: eventTypes.formatCode,
  },
  {
    id: 8,
    Icon: UndoSharp,
    event: eventTypes.formatUndo,
  },
  {
    id: 9,
    Icon: RedoSharp,
    event: eventTypes.formatRedo,
  },
  {
    id: 10,
    Icon: FormatBoldOutlinedIcon,
    event: eventTypes.formatBold,
  },
  {
    id: 11,
    Icon: FormatItalicOutlinedIcon,
    event: eventTypes.formatItalic,
  },
  {
    id: 12,
    Icon: FormatUnderlinedOutlinedIcon,
    event: eventTypes.formatUnderline,
  },
  {
    id: 13,
    Icon: StrikethroughSOutlinedIcon,
    event: eventTypes.formatStrike,
  },
  {
    id: 14,
    Icon: AddPhotoAlternateSharp,
    event: eventTypes.insertImage,
  },
  {
    id: 15,
    Icon: AddLinkSharpIcon,
    event: eventTypes.formatInsertLink,
  },
  {
    id: 16,
    Icon: FormatAlignLeftOutlinedIcon,
    event: eventTypes.formatAlignLeft,
  },

  {
    id: 17,
    Icon: FormatAlignJustifyOutlinedIcon,
    event: eventTypes.formatAlignCenter,
  },
  {
    id: 18,
    Icon: FormatAlignRightOutlinedIcon,
    event: eventTypes.formatAlignRight,
  },
];

export default pluginsList;
