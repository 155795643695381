// src\app\pages\4-Campaigns\1-CampaignStatistics\CampaignStatisticsLoadingPlaceholder.tsx
import { Box, Grid, Skeleton } from '@mui/material';
import React from 'react';

const CampaignStatisticsLoadingPlaceholder: React.FC = () => {
    return (
        <Box>
            <Grid container spacing={4}>
                <Grid item xs={6}>
                    <Skeleton variant="rectangular" width="100%" height={350} />
                </Grid>
                <Grid item xs={6}>
                    <Box display="flex" justifyContent="center" alignItems="center" height={350}>
                        <Skeleton variant="circular" width={350} height={350} />
                    </Box>
                </Grid>
                <Grid item xs={6}>
                    <Box display="flex" justifyContent="center" alignItems="center" height={350}>
                        <Skeleton variant="circular" width={350} height={350} />
                    </Box>
                </Grid>
                <Grid item xs={6}>
                    <Skeleton variant="rectangular" width="100%" height={350} />
                </Grid>
            </Grid>
        </Box>
    );
};

export default CampaignStatisticsLoadingPlaceholder;
