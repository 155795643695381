import React from 'react';
import { Button, Box } from '@mui/material';
import { useGlassEffect } from './GlassEffectContext';
import GlassIcon from './GlassIcon/GlassIcon';

const PreferencesDialog = () => {
    const { isGlassEffectEnabled, toggleGlassEffect } = useGlassEffect();

    return (
        <Box
            sx={{

                background: "var(--bs-page-bg)",
                // backdropFilter: isGlassEffectEnabled ? 'blur(50px)' : undefined,
                height: "100%"
            }}
        >
            <Box >
                <Box >Visual Preferences</Box>
                <Button onClick={toggleGlassEffect}>
                    {isGlassEffectEnabled ? 'Disable Glass Effect' : 'Enable Glass Effect'}
                    <GlassIcon size={25} color="var(--bs-dark)" />
                </Button>

            </Box>
        </Box>
    );
};

export default PreferencesDialog;
