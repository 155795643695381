import React, { Component } from 'react';
import { Modal, ModalHeader } from 'reactstrap';
import Moment from 'moment';
import CloseSharpIcon from '@mui/icons-material/CloseSharp';
import { TextField, Button, IconButton } from '@mui/material';
import './ComposeMailStyles.css'; // Import the CSS files

interface ComposeMailProps {
    onMailSend: (mail: any) => void;
    onClose: () => void;
    user: {
        name: string;
        avatar: string;
        email: string;
    };
    open: boolean;
}

interface ComposeMailState {
    to: string;
    cc: string;
    bcc: string;
    subject: string;
    message: string;
}

class ComposeMail extends Component<ComposeMailProps, ComposeMailState> {
    constructor(props: ComposeMailProps) {
        super(props);
        this.state = {
            to: '',
            cc: '',
            bcc: '',
            subject: '',
            message: '',
        };
    }

    render() {
        const { onMailSend, onClose, user } = this.props;
        const { to, subject, message } = this.state;

        return (
            <Modal className="modalStyle" toggle={onClose} isOpen={this.props.open}>
                <ModalHeader className="modalBoxHeaderStyle">
                    New Message
                    <IconButton className="sendMessageBtnStyle" onClick={onClose}>
                        <CloseSharpIcon />
                    </IconButton>
                </ModalHeader>
                <div className="modalBoxContentStyle">
                    <TextField
                        id="required"
                        label="To*"
                        onChange={(event) => this.setState({ to: event.target.value })}
                        value={to}
                        className="textFieldStyle"
                    />
                    <TextField
                        id="required"
                        label="Subject"
                        onChange={(event) => this.setState({ subject: event.target.value })}
                        value={subject}
                        className="textFieldStyle"
                    />
                    <TextField
                        id="required"
                        label="Message"
                        onChange={(event) => this.setState({ message: event.target.value })}
                        value={message}
                        multiline
                        className="textFieldStyle"
                    />
                </div>

                <div className="modalBoxFooterStyle">
                    <Button className="attachFileBtnStyle attach-file jr-btn text-muted">
                        <i className="zmdi zmdi-attachment mr-2 zmdi-hc-2x" /> Attach File
                    </Button>

                    <Button
                        disabled={to === ''}
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            onClose();
                            onMailSend({
                                'id': '15453a06c08fb021776',
                                'from': {
                                    'name': user.name,
                                    'avatar': user.avatar,
                                    'email': user.email,
                                },
                                'to': [
                                    {
                                        'name': to,
                                        'email': to,
                                    },
                                ],
                                'subject': subject,
                                'message': message,
                                'time': Moment().format('DD MMM'),
                                'read': false,
                                'starred': false,
                                'important': false,
                                'hasAttachments': false,
                                'folder': 1,
                                'selected': false,
                                'labels': [],
                            });
                        }}
                    >
                        <i className="zmdi zmdi-mail-send mr-2" /> Send Message
                    </Button>
                </div>
            </Modal>
        );
    }
}

export default ComposeMail;
