// src\app\pages\WarmupV2\Warmup\GlowingFireIcon\FireIcon.tsx
import { GiFireplace } from "react-icons/gi";
import { useSpring, animated } from 'react-spring';

interface GlowingIconProps {
    glowIntensity: number;
    isDisabled: boolean;
    isWarmupActive: boolean;
    onMouseEnter?: () => void;
    onMouseLeave?: () => void;
    onMouseDown?: () => void;
    onMouseUp?: () => void;
    style?: React.CSSProperties;
}



export const GlowingIcon: React.FC<GlowingIconProps> = ({
    glowIntensity,
    isDisabled,
    isWarmupActive,
    onMouseEnter,
    onMouseLeave,
    onMouseDown,
    onMouseUp,
    style = {},
}) => {
    // Set glowIntensity to 0 and color to grey when isWarmupActive is false
    if (!isWarmupActive) {
        glowIntensity = 0;
    }

    const color = isWarmupActive ? interpolateColor(glowIntensity) : 'grey';

    const calculateDuration = (intensity) => {
        // Assuming the maximum intensity is 100 and minimum is 0
        // You can adjust these values based on your requirements
        const maxDuration = 2000; // Maximum duration in milliseconds
        const minDuration = 1000;  // Minimum duration in milliseconds

        return minDuration + ((100 - intensity) / 100) * (maxDuration - minDuration);
    };

    const duration = calculateDuration(50);

    // Use React Spring to animate the glow  // Prevent glow effect when disabled
    const animatedStyle = useSpring({
        from: { filter: `drop-shadow(0 0 ${isDisabled ? 0 : glowIntensity}px ${color})` },
        to: async (next) => {
            while (1) {
                await next({ filter: `drop-shadow(0 0 ${(glowIntensity + 20)}px ${color})` });
                await next({ filter: `drop-shadow(0 0 ${glowIntensity}px ${color})` });
                await next({ filter: `drop-shadow(0 0 ${glowIntensity + 5}px  ${color})` });
            }
        },
        config: { duration: duration }, // Use the dynamic duration
    });


    const iconStyle = {
        color: color,
        fontSize: '220px',
        transition: 'transform 0.3s ease',
        ':hover': {
            transform: 'scale(1.1) rotate(5deg)',
        },
    };




    return (
        <animated.div
            style={{ ...iconStyle, ...animatedStyle, ...style }}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            onMouseDown={onMouseDown}
            onMouseUp={onMouseUp}
        >
            <GiFireplace style={{ cursor: style.cursor || 'auto', color }} />
        </animated.div>
    );
};



const interpolateColor = (intensity) => {
    // Assuming intensity is a value between 0 and 100

    // Gradient from orange (rgb(255, 140, 0)) to red (rgb(255, 0, 0))
    const greenValue = 190 - (intensity / 100) * 190; // Decreases from 140 to 0

    return `rgb(255, ${Math.round(greenValue)}, 0)`;
};



const glowAnimation = (intensity, color) => {
    const flickerSpeed = intensity > 40 ? '2s' : '4s'; // Faster flicker for higher intensity

    const maxSpread = intensity / 2; // Adjust the spread based on intensity

    return `
    @keyframes glow {
        0%, 100% {
            filter: drop-shadow(0 0 ${maxSpread}px ${color});
        }
        50% {
            filter: drop-shadow(0 0 ${intensity / 1}px ${color});
        }
    }
    animation: glow ${flickerSpeed} infinite alternate;
    `;
};