// src\app\pages\4-Campaigns\5-CampaignReview\LeadsReviewSequence.tsx
import AccessAlarmSharpIcon from '@mui/icons-material/AccessAlarmSharp';
import EmailSharp from '@mui/icons-material/EmailSharp';
import SmartphoneSharp from '@mui/icons-material/SmartphoneSharp';
import { Card, CardContent, Divider, List, ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material';
import { styled } from '@mui/system';
import DOMPurify from 'dompurify';
import React, { useEffect, useState } from 'react';

import { ILeadReviewInfo } from './CampaignReviewParent';
import MessageDialog from './ModalDialog';
import { IMessage, IPersonalizedMessage } from './PersonalizedMessageInterface';

interface LeadsReviewSequenceProps {
    leadReviewInfo: ILeadReviewInfo[];
    selectedLead: string | null;
    selectedCampaignId: string | null;
    personalizedMessage: IPersonalizedMessage | null;
    messages: IMessage | null;
}

const StyledCard = styled(Card)({
    marginBottom: '0.5rem',
    backgroundColor: '#fff',
    transition: '0.5s',
    '&:hover': {
        backgroundColor: '#f5f5f5',
        transition: '0.5s',
    },
});

// Function to get the appropriate icon based on the message type
const getMessageIcon = (type) => {
    switch (type) {
        case 'email': return <EmailSharp sx={{ fontSize: "35px" }} />;
        case 'sms': return <SmartphoneSharp sx={{ fontSize: "35px" }} />;
        default: return null; // default case if needed
    }
};

export const formatDelay = (milliseconds: number) => {
    const seconds = Math.floor(milliseconds / 1000);
    if (seconds < 60) return `${seconds} second${seconds !== 1 ? 's' : ''}`;

    const minutes = Math.floor(seconds / 60);
    if (minutes < 60) return `${minutes} minute${minutes !== 1 ? 's' : ''}`;

    const hours = Math.floor(minutes / 60);
    if (hours < 24) return `${hours} hour${hours !== 1 ? 's' : ''}`;

    const days = Math.floor(hours / 24);
    return `${days} day${days !== 1 ? 's' : ''}`;
};

const LeadsReviewSequence: React.FC<LeadsReviewSequenceProps> = ({ selectedLead, selectedCampaignId, leadReviewInfo, personalizedMessage, messages }) => {
    const [openMessages, setOpenMessages] = useState<{ [key: string]: boolean }>({});


    const toggleMessage = (messageId: string) => {
        setOpenMessages((prevOpenMessages) => ({
            ...prevOpenMessages,
            [messageId]: !prevOpenMessages[messageId],
        }));
    };

    return (
        <div>
            {/* ... Typography components ... */}

            <List>
                {Array.isArray(messages) && messages.length > 0 ? (
                    messages.map((message, index) => (
                        <React.Fragment key={message._id}>
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>

                                {index > 0 && ( // Check if the index is greater than 0
                                    <Card variant="outlined" style={{ marginTop: '1rem', width: '150px' }}>
                                        <CardContent>
                                            <Typography variant="body2">
                                                <AccessAlarmSharpIcon />

                                                {formatDelay(
                                                    //@ts-ignore
                                                    message.sendAfterDelay)}
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                )}





                                {/* <CustomTooltip
                                    //@ts-ignore
                                    title={<span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(message.content) }} />}
                                    placement="top"
                                > */}
                                <ListItem button style={{ borderTopLeftRadius: '20px', borderBottomRightRadius: "20px", margin: '10px' }} onClick={() => toggleMessage(message._id)}>
                                    <ListItemIcon>{getMessageIcon(message.type)}</ListItemIcon>
                                    <ListItemText>
                                        <Typography variant="subtitle1">{message.type.toUpperCase()}</Typography>
                                        <Typography variant="body2">
                                            <strong>Subject:</strong> {message.subject}
                                        </Typography>
                                        {/* Add a Card for displaying delay information */}
                                        <Typography variant="body2" style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                            <strong>Message:</strong>
                                            {message.emailType === 'html'
                                                ? <span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(message.content) }} />
                                                : message.content}
                                        </Typography>

                                        {
                                            message.statistics && message.statistics.sendAt && (
                                                <Typography variant="body2" style={{ margin: "8px 0" }}>
                                                    Scheduled to send at: {new Date(message.statistics.sendAt).toLocaleString()}
                                                </Typography>
                                            )
                                        }
                                    </ListItemText>
                                </ListItem>
                                {/* </CustomTooltip> */}

                                {index < messages.length - 1 && <Divider />}
                            </div>
                        </React.Fragment>
                    ))
                ) : (
                    <Typography variant="body2">No messages available.</Typography>
                )}

            </List>


            {
                //@ts-ignore
                personalizedMessage?.messages.map((message) => (
                    <MessageDialog
                        key={message._id} // This is the unique ID for each individual message
                        message={message}
                        open={!!openMessages[message._id]}
                        onClose={() => toggleMessage(message._id)}
                        selectedCampaignId={selectedCampaignId}
                        personalizedMessageId={personalizedMessage._id} // Pass the parent PersonalizedMessage ID here
                        leadReviewInfo={leadReviewInfo}
                    />
                ))}

        </div>
    );
};

export default LeadsReviewSequence;
