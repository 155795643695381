// src\app\modules\campaign\components\Sequence\MailComponent.tsx
import React from 'react';

import SequenceSteps from './SequenceSteps';

interface SequenceComponentProps {
  selectedCampaignId: string | null;
}

const SequenceComponent: React.FC<SequenceComponentProps> = ({ selectedCampaignId }) => {


  return (
    <SequenceSteps key={selectedCampaignId} selectedCampaignId={selectedCampaignId} />
  );
};

export default SequenceComponent;
