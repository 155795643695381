// src\app\pages\3-CRM\CustomToolbarComponents\PrioritySelectionModal.tsx

import React from 'react';
import { Button, ButtonBase, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Paper, Typography } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'; // Example icon
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked'; // Example icon for unselected state
import DraggableDialog from '@/src/app/modules/CustomMaterialUiComponents/DraggableDialog';
import { CheckCircleSharp } from '@mui/icons-material';
import CustomTooltip from '@/src/app/modules/CustomMaterialUiComponents/CustomTooltip';

const LinkedInPriorityModal = ({ open, onClose, onSubmit, selectedLeadCount }) => {
    const [selectedPriority, setSelectedPriority] = React.useState(null);

    const priorities = [
        { label: 'About First', value: 'aboutFirst', description: 'Prioritizes the About section. If found, it will stop; otherwise, it will look for the latest post.' },
        { label: 'Posts First', value: 'postsFirst', description: 'Prioritizes the latest post. If found, it will stop; otherwise, it will look for the About section.' },
        { label: 'Only About', value: 'onlyAbout', description: 'Searches exclusively in the About section of the LinkedIn profile.' },
        { label: 'Only Posts', value: 'onlyPosts', description: 'Searches exclusively for the most recent post on the LinkedIn profile.' }
    ];

    const handleGridItemClick = (value) => {
        setSelectedPriority(value);
    };

    /*const handleStartClick = () => {
        onSubmit(selectedPriority);
        console.log("selected priority", selectedPriority)
        // onClose();
    };*/

    return (
        <DraggableDialog
            open={open}
            onClose={onClose}
            title={`Enrich LinkedIn Information - ${selectedLeadCount} Leads Selected`}
            enableExternalWindow={false}
            content={
                <>
                    <DialogTitle>Select LinkedIn Enrichment Priority</DialogTitle>
                    <DialogContent>
                        <Paper elevation={3} style={{ margin: '10px', padding: '15px', textAlign: 'center' }}>
                            <Typography variant="h6" component="p">
                                {selectedLeadCount} Leads Selected
                            </Typography>
                        </Paper>
                        <Grid container spacing={2}>
                            {priorities.map((priority) => (
                                <Grid item xs={6} key={priority.value}>
                                    <ButtonBase
                                        onClick={() => handleGridItemClick(priority.value)}
                                        style={{
                                            width: '100%', // Ensure it covers the area of the Paper component
                                        }}
                                    >
                                        <CustomTooltip
                                            title={priority.description}
                                            followCursor
                                            placement="top"
                                            disableMotion={false}
                                            sx={{ zIndex: 2147483647 }} // Set a high z-index value
                                        >
                                            <Paper
                                                style={{
                                                    padding: '20px',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    cursor: 'pointer',
                                                    width: "100%",
                                                    height: "100%",
                                                    border: selectedPriority === priority.value ? 'dashed 6px #793A9B' : 'solid 0px'
                                                }}
                                            >
                                                <IconButton>
                                                    {priority.value === selectedPriority ? <CheckCircleSharp sx={{ fontSize: "40px" }} color="primary" /> : <RadioButtonUncheckedIcon sx={{ fontSize: "40px" }} />}
                                                </IconButton>
                                                <Typography variant="body1">{priority.label}</Typography>
                                            </Paper>
                                        </CustomTooltip>
                                    </ButtonBase>
                                </Grid>
                            ))}
                        </Grid>
                        <Paper elevation={3} style={{ margin: '20px 0', padding: '10px', textAlign: 'center', backgroundColor: '#ffeb3b' }}>
                            <Typography variant="body1" component="p">
                                Note: This feature will be available in one to two weeks.
                            </Typography>
                        </Paper>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" color="primary" disableElevation disabled>
                            Start
                        </Button>
                        {/*<Button onClick={handleStartClick} variant="contained" color="primary" disableElevation>
                            Start
                        </Button>*/}
                        <Button onClick={onClose} color="primary">
                            Cancel
                        </Button>
                    </DialogActions>
                </>
            }
        />
    );
};

export default LinkedInPriorityModal;
