import React from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import axios from 'axios';

export const TagMenu = ({ anchorEl, handleClose, leadId, refreshData }) => {
    const handleTagSelected = async (tagName, tagColor) => {
        const apiUrl = `${process.env.REACT_APP_API_URL}/leads/tag`;
        const payload = {
            leadIds: [leadId],
            tagName,
            tagColor,
        };

        try {
            await axios.post(apiUrl, payload);
            console.log('Tag added:', payload);
            //@ts-ignore
            refreshData({ noSkeleton: true });
        } catch (error) {
            console.error('Error adding tag:', error);
        }

        handleClose(); // Close the menu after handling the action
    };

    return (
        <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
        >
            <MenuItem onClick={() => handleTagSelected('Priority', '#FFD700')}>Priority</MenuItem>
            <MenuItem onClick={() => handleTagSelected('Follow-Up', '#00FF00')}>Follow-Up</MenuItem>
        </Menu>
    );
};
