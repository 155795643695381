// src\app\pages\4-Campaigns\3-CampaignSequence\SequenceComponents\CustomToolbar.tsx
import { CodeSharp } from '@mui/icons-material';
import ClearIcon from '@mui/icons-material/Clear';
import HtmlIcon from '@mui/icons-material/Code';
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';
import FormatAlignJustifySharpIcon from '@mui/icons-material/FormatAlignJustifySharp';
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight';
import FormatBoldIcon from '@mui/icons-material/FormatBold';
import FormatColorFillIcon from '@mui/icons-material/FormatColorFill';
import FormatColorTextIcon from '@mui/icons-material/FormatColorText';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import FormatSizeIcon from '@mui/icons-material/FormatSize';
import FormatUnderlinedIcon from '@mui/icons-material/FormatUnderlined';
import ImageIcon from '@mui/icons-material/Image';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import LinkIcon from '@mui/icons-material/Link';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import RedoIcon from '@mui/icons-material/Redo';
import TextIcon from '@mui/icons-material/TextFields';
import UndoIcon from '@mui/icons-material/Undo';
import { Button, Grid, IconButton, Slider, Toolbar, Tooltip } from '@mui/material';
import Divider from '@mui/material/Divider';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Popover from '@mui/material/Popover';
import Quill from 'quill';
import React, { useState } from 'react';
import { HexColorPicker } from 'react-colorful';
import { animated, useSpring } from 'react-spring';



// Function to handle toolbar actions
const handleToolbarAction = (editorRef, action: string, value: null | undefined | string = null) => {
    console.log(`Action: ${action}, Value: ${value}`);
    const editor = editorRef.current;
    if (editor && editor.getEditor) {
        const quill = editor.getEditor();
        if (action === 'link') {
            const url = prompt('Enter the URL');
            quill.format(action, url);
        } else {
            quill.format(action, value || !quill.getFormat()[action]);
        }
    }
};

export const CustomToolbar = ({ editorRef, children, toggleDisplayMode, displayHTML }) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [showMoreOptions, setShowMoreOptions] = useState(false);

    // State to manage the color picker's open status and anchor element

    const [textColor, setTextColor] = useState('#000'); // Default text color
    const [showTextColorPicker, setShowTextColorPicker] = useState(false); // Text color picker visibility

    const [backgroundColor, setBackgroundColor] = useState('#fff'); // Default background color

    const [showBackgroundColorPicker, setShowBackgroundColorPicker] = useState(false); // Background color picker visibility
    const [templateMenuAnchorEl, setTemplateMenuAnchorEl] = useState<HTMLElement | null>(null);


    const [currentTextColor, setCurrentTextColor] = useState('#000');
    const [currentBackgroundColor, setCurrentBackgroundColor] = useState('#fff');


    const [colorPickerAnchorEl, setColorPickerAnchorEl] = useState<null | HTMLElement>(null);

    const [fontSizeAnchorEl, setFontSizeAnchorEl] = useState<null | HTMLElement>(null);
    const [fontSize, setFontSize] = useState(18); // Default font size

    // Color picker change handler
    const handleTextColorChange = (color) => {
        setCurrentTextColor(color); // Directly using the color value
    };

    const handleBackgroundColorChange = (color) => {
        setCurrentBackgroundColor(color); // Directly using the color value
    };


    const handleCloseColorPicker = () => {
        setColorPickerAnchorEl(null);
    };

    const handleCloseTemplateMenu = () => {
        setTemplateMenuAnchorEl(null);
    };


    const applyTextColor = () => {
        console.log("Applying text color:", currentTextColor); // Debugging
        setTextColor(currentTextColor);
        handleToolbarAction(editorRef, 'color', currentTextColor);
        setShowTextColorPicker(false);
    };

    const applyBackgroundColor = () => {
        console.log("Applying background color:", currentBackgroundColor); // Debugging
        setBackgroundColor(currentBackgroundColor);
        handleToolbarAction(editorRef, 'background', currentBackgroundColor);
        setShowBackgroundColorPicker(false);
    };




    const optionsAnimation = useSpring({
        height: showMoreOptions ? 120 : 0,
        opacity: showMoreOptions ? 1 : 0,
        overflow: showMoreOptions ? 'visible' : 'hidden',
        scale: showMoreOptions ? 1 : 0.8, // Scale animation (1 means no scale, 0.8 means 80% size)
        rotate: showMoreOptions ? 0 : 2, // Rotate animation (0 degrees when shown, 0 degrees when hidden)
    });

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setTemplateMenuAnchorEl(event.currentTarget);
    };


    const handleToggleDisplayMode = () => {
        console.log("Toggle Display Mode Button Clicked"); // Debugging
        toggleDisplayMode();
    };



    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleSaveTemplate = () => {
        console.log("Save Template");
        handleClose();
    };

    const handleSelectTemplate = (template: string) => {
        console.log("Template Selected:", template);
        handleClose();
        // Implement the logic to apply the selected template
    };

    const SizeStyle = Quill.import('attributors/style/size');
    Quill.register(SizeStyle, true);

    const handleFontSizeChange = (fontSize: number | number[]) => {
        if (typeof fontSize === 'number') {
            const editor = editorRef.current?.getEditor();
            if (editor) {
                editor.format('size', `${fontSize}px`);
            }
        }
    };

    const popoverStyle = {
        width: '250px', // Adjust width as needed
        padding: '10px'
    };

    const sliderStyle = {
        width: '200px', // Adjust width as needed
        margin: 'auto'
    };


    return (
        <Toolbar>
            <Grid container spacing={0} alignItems="center" direction="row">
                <Grid item>
                    <Tooltip title="Bold">
                        <IconButton
                            onMouseDown={(e) => {
                                e.preventDefault();
                                handleToolbarAction(editorRef, 'bold', null);
                            }}
                        >
                            <FormatBoldIcon sx={{ fontSize: "25px" }} />
                        </IconButton>
                    </Tooltip>
                </Grid>
                <Grid item>
                    <Tooltip title="Italic">
                        <IconButton
                            onMouseDown={(e) => {
                                e.preventDefault();
                                handleToolbarAction(editorRef, 'italic');
                            }}
                        >
                            <FormatItalicIcon sx={{ fontSize: "25px" }} />
                        </IconButton>
                    </Tooltip>
                </Grid>
                <Grid item>
                    <Tooltip title="Underline">
                        <IconButton
                            onMouseDown={(e) => {
                                e.preventDefault();
                                handleToolbarAction(editorRef, 'underline');
                            }}
                        >
                            <FormatUnderlinedIcon sx={{ fontSize: "25px" }} />
                        </IconButton>
                    </Tooltip>
                </Grid>

                {/* More Options Button */}
                <Grid item>
                    <IconButton onClick={() => setShowMoreOptions(!showMoreOptions)}>
                        <MoreVertIcon sx={{ fontSize: 25 }} />
                    </IconButton>
                </Grid>
                {/* Toggle HTML/Text View Button */}
                {showMoreOptions && (
                    <>
                        {/* Additional toolbar options, shown when showMoreOptions is true */}
                        <animated.div style={optionsAnimation}>
                            <Grid container spacing={2} alignItems="center">
                                <Grid item>
                                    <Tooltip title="Insert Image">
                                        <IconButton
                                            onMouseDown={(e) => {
                                                e.preventDefault();
                                                // Add your logic to open a dialog to select an image and insert it into your editor
                                            }}
                                        >
                                            <ImageIcon />
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                                {/* New buttons for alignment */}
                                <Grid item>
                                    <Tooltip title="Align Left">
                                        <IconButton
                                            onMouseDown={(e) => {
                                                e.preventDefault();
                                                handleToolbarAction(editorRef, 'align', ''); // You can specify 'left' as the alignment
                                            }}
                                        >
                                            <FormatAlignLeftIcon />
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                                {/* New buttons for alignment */}
                                <Grid item>
                                    <Tooltip title="Justify">
                                        <IconButton
                                            onMouseDown={(e) => {
                                                e.preventDefault();
                                                handleToolbarAction(editorRef, 'align', 'justify'); // You can specify 'left' as the alignment
                                            }}
                                        >
                                            <FormatAlignJustifySharpIcon />
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                                <Grid item>
                                    <Tooltip title="Align Center">
                                        <IconButton
                                            onMouseDown={(e) => {
                                                e.preventDefault();
                                                handleToolbarAction(editorRef, 'align', 'center'); // You can specify 'center' as the alignment
                                            }}
                                        >
                                            <FormatAlignCenterIcon />
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                                <Grid item>
                                    <Tooltip title="Align Right">
                                        <IconButton
                                            onMouseDown={(e) => {
                                                e.preventDefault();
                                                handleToolbarAction(editorRef, 'align', "right");
                                            }}
                                        >
                                            <FormatAlignRightIcon sx={{ fontSize: "25px" }} />
                                        </IconButton>
                                    </Tooltip>
                                </Grid>

                                {/* List formatting buttons */}
                                <Grid item>
                                    <Tooltip title="Bullet List">
                                        <IconButton
                                            onMouseDown={(e) => {
                                                e.preventDefault();
                                                handleToolbarAction(editorRef, 'list', null);
                                            }}
                                        >
                                            <FormatListBulletedIcon sx={{ fontSize: "25px" }} />
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                                <Grid item>
                                    <Tooltip title="Numbered List">
                                        <IconButton
                                            onMouseDown={(e) => {
                                                e.preventDefault();
                                                handleToolbarAction(editorRef, 'list', null);
                                            }}
                                        >
                                            <FormatListNumberedIcon sx={{ fontSize: "25px" }} />
                                        </IconButton>
                                    </Tooltip>
                                </Grid>

                                {/* Hyperlink button */}
                                <Grid item>
                                    <Tooltip title="Insert Link">
                                        <IconButton
                                            onMouseDown={(e) => {
                                                e.preventDefault();
                                                handleToolbarAction(editorRef, 'link');
                                            }}
                                        >
                                            <LinkIcon sx={{ fontSize: "25px" }} />
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                                {/* Text and Background Color Pickers */}
                                {/* Text Color Button */}
                                <Grid item>
                                    <Tooltip title="Text Color">
                                        <IconButton onClick={(e) => {
                                            setShowTextColorPicker(true);
                                            setColorPickerAnchorEl(e.currentTarget);
                                        }}>
                                            <FormatColorTextIcon sx={{ fontSize: "25px" }} />
                                        </IconButton>

                                    </Tooltip>
                                    <Popover
                                        open={showTextColorPicker}
                                        anchorEl={colorPickerAnchorEl}
                                        onClose={handleCloseColorPicker}
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'left',
                                        }}
                                    >
                                        <div>
                                            <HexColorPicker color={currentTextColor} onChange={handleTextColorChange} />
                                            <Button onClick={applyTextColor}>Apply Color</Button>
                                        </div>
                                    </Popover>
                                </Grid>


                                {/* Background Color Button */}
                                <Grid item>
                                    <Tooltip title="Background Color">
                                        <IconButton onClick={(e) => {
                                            setShowBackgroundColorPicker(true);
                                            setAnchorEl(e.currentTarget); // This should be setBackgroundColorPickerAnchorEl if you are using a different element for background color
                                        }}>
                                            <FormatColorFillIcon sx={{ fontSize: "25px" }} />
                                        </IconButton>
                                    </Tooltip>
                                    <Popover
                                        open={showBackgroundColorPicker}
                                        anchorEl={anchorEl}
                                        onClose={() => setShowBackgroundColorPicker(false)}
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'left',
                                        }}
                                    >
                                        <div>
                                            <HexColorPicker color={currentBackgroundColor} onChange={handleBackgroundColorChange} />
                                            <Button onClick={applyBackgroundColor}>Apply Color</Button>
                                        </div>
                                    </Popover>
                                </Grid>
                                {/* Font Size Selector */}
                                <Grid item>
                                    <Tooltip title="Font Size">
                                        <IconButton
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setFontSizeAnchorEl(e.currentTarget);
                                            }}
                                        >
                                            <FormatSizeIcon sx={{ fontSize: "25px" }} />
                                        </IconButton>
                                    </Tooltip>
                                    <Popover
                                        style={popoverStyle}
                                        open={Boolean(fontSizeAnchorEl)}
                                        anchorEl={fontSizeAnchorEl}
                                        onClose={() => setFontSizeAnchorEl(null)}
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'left',
                                        }}
                                    >
                                        <Slider
                                            style={sliderStyle}
                                            value={fontSize}
                                            aria-label="Font Size"
                                            valueLabelDisplay="auto"
                                            step={1}
                                            marks
                                            min={10}
                                            max={36}
                                            onChange={(e, value) => {
                                                setFontSize(value as number);
                                                console.log("Updated Font Size State: ", value);
                                            }}
                                            onChangeCommitted={(e, value) => handleFontSizeChange(value)}
                                        />
                                    </Popover>
                                </Grid>

                                {/* Blockquote, Code Block */}
                                <Grid item>
                                    <Tooltip title="Blockquote">
                                        <IconButton
                                            onMouseDown={(e) => {
                                                e.preventDefault();
                                                handleToolbarAction(editorRef, 'blockquote');
                                            }}
                                        >
                                            <FormatQuoteIcon sx={{ fontSize: "25px" }} />
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                                <Grid item>
                                    <Tooltip title="Code Block">
                                        <IconButton
                                            onMouseDown={(e) => {
                                                e.preventDefault();
                                                handleToolbarAction(editorRef, 'code-block');
                                            }}
                                        >
                                            <CodeSharp sx={{ fontSize: "25px" }} />
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                                {/* Undo and Redo */}
                                <Grid item>
                                    <Tooltip title="Undo">
                                        <IconButton
                                            onMouseDown={(e) => {
                                                e.preventDefault();
                                                editorRef.current?.getEditor().history.undo();
                                            }}
                                        >
                                            <UndoIcon sx={{ fontSize: "25px" }} />
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                                <Grid item>
                                    <Tooltip title="Redo">
                                        <IconButton
                                            onMouseDown={(e) => {
                                                e.preventDefault();
                                                editorRef.current?.getEditor().history.redo();
                                            }}
                                        >
                                            <RedoIcon sx={{ fontSize: "25px" }} />
                                        </IconButton>
                                    </Tooltip>
                                </Grid>

                                {/* Clear Formatting */}
                                <Grid item>
                                    <Tooltip title="Clear Formatting">
                                        <IconButton
                                            onMouseDown={(e) => {
                                                e.preventDefault();
                                                const editor = editorRef.current?.getEditor();
                                                if (editor) {
                                                    const range = editor.getSelection();
                                                    if (range) {
                                                        editor.removeFormat(range.index, range.length);
                                                    }
                                                }
                                            }}
                                        >
                                            <ClearIcon sx={{ fontSize: "25px" }} />
                                        </IconButton>
                                    </Tooltip>
                                </Grid>

                                {/* Embed Video */}
                                <Grid item>
                                    <Tooltip title="Embed Video">
                                        <IconButton
                                            onMouseDown={(e) => {
                                                e.preventDefault();
                                                const url = prompt('Enter video URL:');
                                                const editor = editorRef.current?.getEditor();
                                                if (editor) {
                                                    const range = editor.getSelection();
                                                    if (range) {
                                                        editor.insertEmbed(range.index, 'video', url);
                                                    }
                                                }
                                            }}
                                        >
                                            <OndemandVideoIcon sx={{ fontSize: "25px" }} />
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                                <Grid item>
                                    <IconButton
                                        onClick={handleClick}
                                    >
                                        <InsertDriveFileIcon sx={{ fontSize: "25px" }} />
                                    </IconButton>
                                </Grid>
                                <Grid item>
                                    <Tooltip title={`Toggle View: ${displayHTML ? "HTML" : "Text"}`}>
                                        <IconButton
                                            onClick={handleToggleDisplayMode}
                                            style={{ marginLeft: '8px' }}
                                        >
                                            {displayHTML ? <HtmlIcon sx={{ fontSize: "25px" }} /> : <TextIcon sx={{ fontSize: "25px" }} />}
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                            </Grid>
                        </animated.div>


                    </>
                )}
            </Grid>

            <Menu
                id="simple-menu"
                anchorEl={templateMenuAnchorEl}
                keepMounted
                open={Boolean(templateMenuAnchorEl)} // Use templateMenuAnchorEl to control open state
                onClose={handleCloseTemplateMenu}
            >

                <MenuItem onClick={handleSaveTemplate}>Save Template</MenuItem>
                <Divider />
                {/* Example templates - replace these with actual template options */}
                <MenuItem onClick={() => handleSelectTemplate("Template 1")}>Template 1</MenuItem>
                <MenuItem onClick={() => handleSelectTemplate("Template 2")}>Template 2</MenuItem>
            </Menu>

            {children} {/* Add children here */}
        </Toolbar>

    );
};
