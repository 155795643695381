// src\app\pages\WarmupV2\WarmupSettings.tsx
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
    Grid, Paper, Typography, Switch, Slider, Button, FormGroup, FormControlLabel,
    Checkbox, Box, FormControl, InputLabel, Select, MenuItem, IconButton
} from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
import { Dialog } from '@mui/material';
import Draggable from "react-draggable";
import { PaperProps } from '@mui/material';
import { WarmupSettingsInterface } from './Warmup/InterfacesWarmupSettings';
import HelpmeDialog from './Warmup/Helper/HelpmeDialog';
import AutoGraphSharpIcon from '@mui/icons-material/AutoGraphSharp';
import RadioButtonUncheckedSharpIcon from '@mui/icons-material/RadioButtonUncheckedSharp';
import TaskAltSharpIcon from '@mui/icons-material/TaskAltSharp';
import { motion } from 'framer-motion';
import CheckCircleSharpIcon from '@mui/icons-material/CheckCircleSharp';
import SignatureIcon from '@mui/icons-material/HistoryEduSharp';
import { grey, orange } from '@mui/material/colors';
import TipsAndUpdatesSharpIcon from '@mui/icons-material/TipsAndUpdatesSharp';
import { MarkEmailReadSharp } from '@mui/icons-material';


interface WarmupSettingsComponentProps {
    warmupSettings: WarmupSettingsInterface;
    setWarmupSettings: (settings: WarmupSettingsInterface) => void;
    updateWarmupSettings: (updatedSettings: WarmupSettingsInterface) => Promise<void>;
}

function PaperComponent(props: PaperProps) {
    return (
        <Draggable cancel={'[class*="MuiDialogContent-root"]'}>
            <Paper {...props} />
        </Draggable>
    );
}



const WarmupSettingsComponent: React.FC<WarmupSettingsComponentProps & { toggleWarmupActive: () => Promise<void>; }> = ({
    warmupSettings, setWarmupSettings, updateWarmupSettings, toggleWarmupActive
}) => {
    const [openModal, setOpenModal] = useState(false);

    const handleSignatureChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setWarmupSettings({ ...warmupSettings, [event.target.name]: event.target.checked });
    };

    const handleSliderChange = (name: keyof WarmupSettingsInterface) => (event: Event, newValue: number | number[]) => {
        setWarmupSettings({ ...warmupSettings, [name]: newValue });
    };

    const handleSelectChange = (event: SelectChangeEvent<string>) => {
        setWarmupSettings({ ...warmupSettings, [event.target.name as string]: event.target.value });
    };

    const openModalHandler = () => {
        setOpenModal(true);
    };

    const closeModalHandler = () => {
        setOpenModal(false);
    };

    const applyRecommendedSettings = (newSettings) => {
        // Apply the new settings to the warmupSettings state
        setWarmupSettings({ ...warmupSettings, ...newSettings });
    };



    const handleDayChange = (day: string) => {
        const updatedDays = warmupSettings.activeDays.includes(day)
            ? warmupSettings.activeDays.filter(d => d !== day)
            : [...warmupSettings.activeDays, day];
        setWarmupSettings({ ...warmupSettings, activeDays: updatedDays });
    };



    const largerSliderStyle = {
        height: '15px',
        borderRadius: '50px',
        '& .MuiSlider-thumb': {
            width: '30px', // Adjust as needed
            height: '30px', // Adjust as needed

            // Position your icon in the center of the hidden thumb area
        },
    };

    const largerSelect = {
        height: '60px', // Increase height for a bigger select box
        fontSize: '1.6rem', // Adjust font size
        fontWeight: 'bold', // Make it bold
        textAlign: 'center', // Center the text
        '& .MuiSelect-select': {
            padding: '0px', // Add padding inside the select box
        },
    };




    return (
        <Grid container spacing={2}>

            <Grid item xs={12}>
                <Box style={{ display: 'flex', justifyContent: 'right', alignItems: 'right' }}>
                    <IconButton onClick={openModalHandler}>

                        <TipsAndUpdatesSharpIcon sx={{ fontSize: "25px" }} />
                    </IconButton>
                </Box>
                <Paper elevation={3} style={{ padding: '20px' }}>


                    <FormGroup>
                        <FormControl fullWidth sx={{ marginTop: '20px', width: '100%' }}> {/* Add padding and adjust width */}
                            <InputLabel sx={{ fontSize: '1.5rem' }}>Warmup Template</InputLabel> {/* Increase font size */}
                            <Select
                                value={warmupSettings.warmupTemplates}
                                label="Warmup Template"
                                onChange={handleSelectChange}
                                name="warmupTemplates"
                                sx={largerSelect}
                                disabled={!warmupSettings}

                            >
                                <MenuItem value="General">General</MenuItem>
                                <MenuItem value="Business">Business</MenuItem>
                                {/* Add more templates as needed */}
                            </Select>
                        </FormControl>
                        <Typography gutterBottom style={{ marginTop: '20px' }}>Starting Email Count: {warmupSettings.startingEmailCount}</Typography>
                        <Slider
                            value={warmupSettings.startingEmailCount}
                            onChange={handleSliderChange('startingEmailCount')}
                            min={1}
                            max={30}
                            disabled={!warmupSettings}
                            sx={largerSliderStyle}
                            valueLabelDisplay="auto" // Show the default tooltip
                            valueLabelFormat={(value) => `Starting Email Count: ${value}`} // Customize the tooltip text
                        />


                        <Typography gutterBottom style={{ marginTop: '20px' }}>Target Max Email Count: {warmupSettings.targetMaxEmailCount}</Typography>
                        <Slider
                            value={warmupSettings.targetMaxEmailCount}
                            onChange={handleSliderChange('targetMaxEmailCount')}
                            min={10}
                            max={30}
                            sx={largerSliderStyle}
                            disabled={!warmupSettings}
                            valueLabelDisplay="auto" // Show the default tooltip
                            valueLabelFormat={(value) => `Target Max Email Count: ${value}`} // Customize the tooltip text
                        />

                        <Typography gutterBottom style={{ marginTop: '20px' }}>
                            Randomize After Reaching Max Limit: {warmupSettings.maxRandomAdjustment}
                        </Typography>
                        <Slider
                            value={warmupSettings.maxRandomAdjustment}
                            onChange={handleSliderChange('maxRandomAdjustment')}
                            aria-labelledby="max-random-adjustment-slider"
                            valueLabelDisplay="auto"
                            min={1}
                            max={6}
                            sx={largerSliderStyle}
                            disabled={!warmupSettings}
                        />

                        <Typography gutterBottom style={{ marginTop: '20px' }}>Reply Buffer Percentage: {warmupSettings.replyBufferPercentage}%</Typography>
                        <Slider
                            value={warmupSettings.replyBufferPercentage}
                            onChange={handleSliderChange('replyBufferPercentage')}
                            min={0}
                            max={100}
                            sx={largerSliderStyle}
                            disabled={!warmupSettings}
                            valueLabelDisplay="auto" // Show the default tooltip
                            valueLabelFormat={(value) => `Reply Buffer Percentage: ${value}%`} // Customize the tooltip text

                        />

                        <Typography gutterBottom style={{ marginTop: '20px' }}>Growth Rate: {warmupSettings.growthRate}</Typography>
                        <Slider
                            value={warmupSettings.growthRate}
                            onChange={handleSliderChange('growthRate')}
                            min={1}
                            max={10}
                            disabled={!warmupSettings}
                            sx={largerSliderStyle}
                            valueLabelDisplay="auto" // Show the default tooltip
                            valueLabelFormat={(value) => `Growth Rate: ${value}`} // Customize the tooltip text

                        />

                        <FormControlLabel
                            control={

                                <Checkbox
                                    checked={warmupSettings.includeSignature}
                                    onChange={handleSignatureChange}
                                    name="includeSignature"
                                    checkedIcon={<SignatureIcon sx={{ color: orange[300], fontSize: "35px" }} />}
                                    icon={<SignatureIcon sx={{ color: grey[300], fontSize: "35px" }} />}
                                    disabled={!warmupSettings}
                                    sx={{
                                        transform: 'scale(1.5)', // Larger checkbox
                                    }}
                                />}
                            label="Include Signature"
                            style={{ marginTop: '20px' }}
                        />


                        <Box>
                            <Typography>Active Days:</Typography>
                            <Box display="flex" flexDirection="row" flexWrap="wrap"> {/* Flex container for days */}
                                {['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'].map((day) => (
                                    <motion.div
                                        className="no-global-transition"

                                        key={day}
                                        whileHover={{ scale: 1.1 }}
                                        whileTap={{ scale: 0.9 }}
                                        style={{ marginRight: '10px' }} // Add some spacing between checkboxes
                                    >
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={warmupSettings.activeDays.includes(day)}
                                                    onChange={() => handleDayChange(day)}
                                                    icon={<RadioButtonUncheckedSharpIcon color='secondary' />}
                                                    checkedIcon={<CheckCircleSharpIcon color='primary' />}
                                                    sx={{ transform: 'scale(1.5)' }}
                                                    disabled={!warmupSettings}
                                                />
                                            }
                                            label={day.substr(0, 3).toUpperCase()}
                                        />
                                    </motion.div>
                                ))}
                            </Box>
                        </Box>


                    </FormGroup>


                </Paper>
            </Grid>




            <Dialog
                open={openModal}
                onClose={closeModalHandler}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                PaperComponent={PaperComponent}
                hideBackdrop={true}

            >
                <HelpmeDialog
                    open={openModal}
                    onClose={closeModalHandler}
                    applyRecommendedSettings={applyRecommendedSettings}
                    currentSettings={warmupSettings}
                    warmupSettings={warmupSettings} // Pass warmupSettings here
                />

            </Dialog>


        </Grid >

    );
};

export default WarmupSettingsComponent;
