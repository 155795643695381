// src\app\pages\0-Account\ProfilePage\ProfileTabs\admin\NginxCertFiles.tsx
import RefreshIcon from '@mui/icons-material/Refresh';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import VisibilityIcon from '@mui/icons-material/Visibility';
import {
    Alert,
    Box,
    Button,
    Container,
    Grid,
    IconButton,
    Paper,
    Skeleton,
    Snackbar,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TextField,
    Typography,
} from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react';

interface NginxAndCertFiles {
    sslCertFiles: string[];
    nginxAvailableFiles: string[];
    nginxEnabledFiles: string[];
}

const NginxAndCertFiles: React.FC = () => {
    const [data, setData] = useState<NginxAndCertFiles | null>(null);
    const [loading, setLoading] = useState(true);
    const [search, setSearch] = useState<string>('');
    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(5);
    const [error, setError] = useState<string | null>(null);
    const [nginxConfigContent, setNginxConfigContent] = useState<string | null>(null);

    const fetchData = async () => {
        setLoading(true);
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/admin/getNginxAndCertFiles`);
            setData(response.data);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching data:', error);
            setError('Error fetching NGINX and SSL files data.');
            setData({
                sslCertFiles: [],
                nginxAvailableFiles: [],
                nginxEnabledFiles: [],
            });
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(e.target.value);
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const filterFiles = (files: string[]) => {
        return files.filter(file => file.toLowerCase().includes(search.toLowerCase()));
    };

    const handleViewConfigFile = async (fileName: string) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/admin/getNginxConfigFile/${fileName}`);
            setNginxConfigContent(response.data.fileContent);
        } catch (error) {
            console.error(`Error fetching NGINX config file ${fileName}:`, error);
            setError(`Error fetching NGINX config file ${fileName}.`);
        }
    };

    const handleRestartNginx = async () => {
        try {
            await axios.post(`${process.env.REACT_APP_API_URL}/admin/reloadNginx`);
            fetchData();
        } catch (error) {
            console.error('Error restarting NGINX:', error);
            setError('Error restarting NGINX.');
        }
    };

    const handleCloseSnackbar = () => {
        setError(null);
    };

    const renderTable = (title: string, files: string[], showActions: boolean = false) => (
        <Grid item xs={12} md={4}>
            <Paper sx={{ p: 2 }}>
                <Typography variant="h6">{title}</Typography>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>File Name</TableCell>
                                {showActions && <TableCell>Actions</TableCell>}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {loading ? (
                                <TableRow>
                                    <TableCell colSpan={showActions ? 2 : 1}>
                                        <Skeleton variant="rectangular" height={200} />
                                    </TableCell>
                                </TableRow>
                            ) : (
                                filterFiles(files).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((file, index) => (
                                    <TableRow key={index}>
                                        <TableCell>{file}</TableCell>
                                        {showActions && (
                                            <TableCell>
                                                <IconButton color="primary" onClick={() => handleViewConfigFile(file)}>
                                                    <VisibilityIcon />
                                                </IconButton>
                                                <IconButton color="secondary" onClick={handleRestartNginx}>
                                                    <RestartAltIcon />
                                                </IconButton>
                                            </TableCell>
                                        )}
                                    </TableRow>
                                ))
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    component="div"
                    count={filterFiles(files).length}
                    page={page}
                    onPageChange={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
        </Grid>
    );

    return (
        <Container>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                <Typography variant="h4" gutterBottom>
                    NGINX and SSL Certificate Files
                </Typography>
                <Button
                    variant="contained"
                    color="primary"
                    startIcon={<RefreshIcon />}
                    onClick={fetchData}
                >
                    Refresh
                </Button>
            </Box>
            <TextField
                label="Search"
                variant="outlined"
                fullWidth
                value={search}
                onChange={handleSearch}
                sx={{ mb: 2 }}
            />
            <Grid container spacing={3}>
                {renderTable('SSL Certificate Files', data?.sslCertFiles || [])}
                {renderTable('NGINX Available Files', data?.nginxAvailableFiles || [], true)}
                {renderTable('NGINX Enabled Files', data?.nginxEnabledFiles || [], true)}
            </Grid>
            <Snackbar open={!!error} autoHideDuration={6000} onClose={handleCloseSnackbar}>
                <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: '100%' }}>
                    {error}
                </Alert>
            </Snackbar>
            {nginxConfigContent && (
                <Box sx={{ mt: 2 }}>
                    <Paper sx={{ p: 2 }}>
                        <Typography variant="h6">NGINX Configuration Content</Typography>
                        <Typography variant="body1" component="pre">
                            {nginxConfigContent}
                        </Typography>
                        <Button variant="contained" color="secondary" onClick={() => setNginxConfigContent(null)}>
                            Close
                        </Button>
                    </Paper>
                </Box>
            )}
        </Container>
    );
};

export default NginxAndCertFiles;
