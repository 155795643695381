// src\app\pages\uploadcsv2\ListSelectionForm.tsx
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Box, Button, FormControl, Grid, LinearProgress, Paper, Typography } from '@mui/material';
import { TextField } from '@mui/material';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import PostAddSharpIcon from '@mui/icons-material/PostAddSharp';
import PersonAddAlt1SharpIcon from '@mui/icons-material/PersonAddAlt1Sharp';
import Tooltip from '@mui/material/Tooltip';
import InputAdornment from '@mui/material/InputAdornment';
import { FoldersWithListsResponse } from './Interfacefolderslists';
import { TreeView } from '@mui/x-tree-view/TreeView';
import { TreeItem } from '@mui/x-tree-view/TreeItem';
import ChevronRightSharp from '@mui/icons-material/ChevronRightSharp';
import ExpandMoreSharp from '@mui/icons-material/ExpandMoreSharp';
import { CircularProgress } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import CustomTooltip from '@/src/app/modules/CustomMaterialUiComponents/CustomTooltip';
import { io } from 'socket.io-client';
import { useAuth } from '@/src/app/modules/auth';


interface ListSelectionFormProps {
    selectedFile: File | null;
    originalHeaders: string[];
    editableHeaders: string[];
    headerMappings: { [key: string]: string };
    visibility: boolean[];
    onListSelected: (listId: string) => void;
    headerTypeMappings: { [key: string]: string };
    emailTypesColumn: { [key: string]: string }; // Added prop
    setEmailTypesColumn: React.Dispatch<React.SetStateAction<{ [key: string]: string }>>; // Added prop
    phoneTypesColumn: { [key: string]: string }; // Added prop
    setPhoneTypesColumn: React.Dispatch<React.SetStateAction<{ [key: string]: string }>>; // Added prop
    existingListsData: FoldersWithListsResponse | null; // Added this line
    linkedInUrlColumn: string;
    setLinkedInUrlColumn: React.Dispatch<React.SetStateAction<string>>;
    icebreakerColumn: string;
    setIcebreakerColumn: React.Dispatch<React.SetStateAction<string>>;
    locationColumn: string;
    setLocationColumn: React.Dispatch<React.SetStateAction<string>>;
    companyNameColumn: string;
    setCompanyNameColumn: React.Dispatch<React.SetStateAction<string>>;
    fullNameColumn: string;
    setFullNameColumn: React.Dispatch<React.SetStateAction<string>>;
    firstNameColumn: string;
    setFirstNameColumn: React.Dispatch<React.SetStateAction<string>>;
    lastNameColumn: string;
    setLastNameColumn: React.Dispatch<React.SetStateAction<string>>;
    jobTitleColumn: string;
    setJobTitleColumn: React.Dispatch<React.SetStateAction<string>>;

}

export const ListSelectionForm: React.FC<ListSelectionFormProps> = ({
    selectedFile,
    originalHeaders,
    editableHeaders,
    headerMappings,
    visibility,
    onListSelected,
    headerTypeMappings,
    emailTypesColumn,
    setEmailTypesColumn,
    phoneTypesColumn,
    setPhoneTypesColumn,
    existingListsData,
    linkedInUrlColumn,
    setLinkedInUrlColumn,
    icebreakerColumn,
    setIcebreakerColumn,
    locationColumn,
    setLocationColumn,
    companyNameColumn,
    setCompanyNameColumn,
    fullNameColumn,
    setFullNameColumn,
    firstNameColumn,
    setFirstNameColumn,
    lastNameColumn,
    setLastNameColumn,
    jobTitleColumn,
    setJobTitleColumn

}) => {
    const [selectedListId, setSelectedListId] = useState<string | null>(null);

    const [newListName, setNewListName] = useState<string>('');
    const [uploadMessage, setUploadMessage] = useState('');
    const [uploadError, setUploadError] = useState('');

    const [selectedEmails, setSelectedEmails] = useState<string[]>([]);
    const [selectedPhones, setSelectedPhones] = useState<string[]>([]);

    const [isLoading, setIsLoading] = useState(false);


    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');

    const [progress, setProgress] = useState(0);
    const [message, setMessage] = useState('');
    const [socket, setSocket] = useState(null);
    const [rowProcessingMessage, setRowProcessingMessage] = useState('');

    const { currentUser } = useAuth()
    const userId = currentUser?._id

    useEffect(() => {
        // Connect to Socket.IO server
        //@ts-ignore
        const newSocket = io(process.env.REACT_APP_API_URL);
        //@ts-ignore
        setSocket(newSocket);

        const roomName = `user_${userId}_uploads`;
        newSocket.emit('joinUserUploadsRoom', { userId });

        newSocket.on('rowProcessing', (data) => {
            setRowProcessingMessage(data.message);
            setProgress(Math.floor((data.count / data.total) * 100));
        });

        newSocket.on('uploadStatus', (data) => {
            setMessage(data.message);
            if (data.status === 'error') {
                setSnackbarSeverity('error');
                setSnackbarMessage(data.message);
                setSnackbarOpen(true);
            }
        });

        return () => {
            newSocket.emit('leaveUserUploadsRoom', { userId });
            newSocket.off('rowProcessing');
            newSocket.off('uploadStatus');
            newSocket.close();
        };
    }, [userId]);

    useEffect(() => {
        onListSelected(selectedListId!);
    }, [selectedListId]);

    useEffect(() => {
        const emails: string[] = []; // Explicitly type as string[]
        const phones: string[] = []; // Explicitly type as string[]
        let linkedInUrl = '';
        let icebreaker = '';
        let location = '';
        let companyName = '';
        let fullName = '';
        let firstName = '';
        let lastName = '';
        let jobTitle = '';

        const emailTypes = new Set(['email', 'customEmailType']); // Adjust based on your actual types
        const phoneTypes = new Set(['phone', 'customPhoneType']); // Adjust based on your actual types
        const linkedInTypes = new Set(['linkedin']); // Assuming 'linkedin' is the type set for LinkedIn URL columns
        const icebreakerTypes = new Set(['icebreaker']); // Type for Icebreaker
        const locationTypes = new Set(['location']);
        const companyNameTypes = new Set(['companyname']);
        const fullNameTypes = new Set(['fullname']);
        const firstNameTypes = new Set(['firstname']);
        const lastNameTypes = new Set(['lastname']);
        const jobTitleTypes = new Set(['jobtitle']);


        Object.entries(headerTypeMappings).forEach(([header, type]) => {
            if (emailTypes.has(type)) emails.push(header);
            else if (phoneTypes.has(type)) phones.push(header);
            else if (linkedInTypes.has(type)) linkedInUrl = header;
            else if (icebreakerTypes.has(type)) icebreaker = header;
            else if (locationTypes.has(type)) location = header;
            else if (companyNameTypes.has(type)) companyName = header;
            else if (fullNameTypes.has(type)) fullName = header;
            else if (firstNameTypes.has(type)) firstName = header;
            else if (lastNameTypes.has(type)) lastName = header;
            else if (jobTitleTypes.has(type)) jobTitle = header;
        });


        setSelectedEmails(emails);
        setSelectedPhones(phones);
        setLinkedInUrlColumn(linkedInUrl);
        setIcebreakerColumn(icebreaker);
        setLocationColumn(location);
        setCompanyNameColumn(companyName);
        setFullNameColumn(fullName);
        setFirstNameColumn(firstName);
        setLastNameColumn(lastName);
        setJobTitleColumn(jobTitle);



    }, [headerTypeMappings]);


    const handleSubmit = async (actionType: 'newlist' | 'existinglist') => {
        setIsLoading(true);

        if (!selectedFile) {
            alert('No file selected');
            return;
        }

        // First, calculate filteredSelectedHeaders based on visibility and exclusion of selected emails and phones.
        const filteredSelectedHeaders = originalHeaders.filter((header, index) =>
            visibility[index] && !selectedEmails.includes(header) && !selectedPhones.includes(header) &&
            header !== linkedInUrlColumn && header !== icebreakerColumn && header !== locationColumn &&
            header !== companyNameColumn &&
            header !== fullNameColumn && header !== firstNameColumn && header !== lastNameColumn && header !== jobTitleColumn
        );


        // Correctly refine headerMappings to include only those headers from filteredSelectedHeaders
        // Ensure this happens before any other manipulation to keep it clean and logical
        const refinedHeaderMappings = Object.fromEntries(
            Object.entries(headerMappings).filter(([header]) => filteredSelectedHeaders.includes(header))
        );
        const refinedHeaderTypeMappings = Object.fromEntries(
            Object.entries(headerTypeMappings).filter(([key]) => filteredSelectedHeaders.includes(key))
        );



        originalHeaders.forEach((header, index) => {
            if (visibility[index]) {
                refinedHeaderMappings[header] = editableHeaders[index] || header;
            }
        });

        const formData = new FormData();
        formData.append('file', selectedFile);
        if (actionType === 'newlist') {
            formData.append('newListName', newListName);
        } else if (actionType === 'existinglist' && selectedListId) {
            formData.append('listId', selectedListId);
        }

        formData.append('headerMappings', JSON.stringify(refinedHeaderMappings));
        formData.append('headerTypeMappings', JSON.stringify(refinedHeaderTypeMappings));
        formData.append('selectedHeaders', JSON.stringify(filteredSelectedHeaders));
        formData.append('selectedEmails', JSON.stringify(selectedEmails));
        formData.append('selectedEmailTypes', JSON.stringify(emailTypesColumn));
        formData.append('selectedPhones', JSON.stringify(selectedPhones));
        formData.append('selectedPhoneTypes', JSON.stringify(phoneTypesColumn));
        formData.append('selectedLinkedInUrl', JSON.stringify(linkedInUrlColumn));
        formData.append('selectedIcebreaker', JSON.stringify(icebreakerColumn)); // Include Icebreaker in the formData
        formData.append('selectedLocation', JSON.stringify(locationColumn));
        formData.append('selectedCompanyName', JSON.stringify(companyNameColumn));
        formData.append('selectedFullName', JSON.stringify(fullNameColumn));
        formData.append('selectedFirstName', JSON.stringify(firstNameColumn));
        formData.append('selectedLastName', JSON.stringify(lastNameColumn));
        formData.append('selectedJobTitle', JSON.stringify(jobTitleColumn));


        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/leads/upload-leads`, formData);
            console.log(response.data);
            // Set success message
            setSnackbarSeverity('success');
            setSnackbarMessage(response.data);
            setSnackbarOpen(true);
        } catch (error: any) {
            console.error('Error uploading file:', error);
            // Extract error message
            let errorMessage = '';
            if (error.response && error.response.data) {
                errorMessage = error.response.data.message || 'An error occurred while uploading the file.';
            } else {
                errorMessage = 'An unexpected error occurred while uploading the file.';
            }
            // Set error message
            setSnackbarSeverity('error');
            setSnackbarMessage(errorMessage);
            setSnackbarOpen(true);
        } finally {
            setIsLoading(false);
        }

    };

    return (
        <Grid container justifyContent="center" alignItems="start" spacing={3}  >

            {progress > 0 &&
                <Grid item xs={12} md={12}>

                    <Paper elevation={3} style={{ padding: '50px', marginBottom: '20px' }}>
                        <Box width="100%">
                            <Typography>{message}</Typography>
                            {progress > 0 && <LinearProgress variant="determinate" value={progress} sx={{ height: 20, borderRadius: 5 }} />}
                        </Box>

                    </Paper>
                </Grid>
            }
            {/* Section for creating a new list */}
            <Grid item xs={12} md={12}>
                <Paper elevation={3} style={{ padding: '50px', marginBottom: '20px' }}>
                    <Typography variant="h6" fontSize={45} gutterBottom>
                        Create New List
                    </Typography>
                    <FormControl fullWidth>
                        <TextField
                            label="List Name"
                            value={newListName}
                            onChange={(e) => setNewListName(e.target.value)}
                            variant="outlined"
                            onKeyPress={(e) => {
                                if (e.key === 'Enter') {
                                    handleSubmit('newlist')
                                    e.preventDefault(); // Prevents the default action of the enter key
                                }
                            }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <CustomTooltip title="Upload to New List">
                                            {isLoading ? (
                                                <CircularProgress />
                                            ) : (
                                                <IconButton onClick={() => handleSubmit('newlist')} color="primary">
                                                    <PostAddSharpIcon />
                                                    Create List
                                                </IconButton>
                                            )}
                                        </CustomTooltip>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </FormControl>
                </Paper>


                {/* Section for adding to an existing list */}
                <Paper elevation={3} style={{ padding: '20px' }}>
                    <Typography variant="h6" fontSize={45} gutterBottom>
                        Add to Existing List
                    </Typography>
                    <Typography variant="h6" fontSize={25} gutterBottom>
                        When selecting a list, hover over the header names to match them with your file's columns. You can also create mappings for new columns
                    </Typography>


                    <Box sx={{ minHeight: 180, flexGrow: 1, overflowY: 'auto', width: '100%' }}>
                        <TreeView
                            aria-label="lists"
                            defaultCollapseIcon={<ExpandMoreSharp />}
                            defaultExpandIcon={<ChevronRightSharp />}
                        >
                            {existingListsData?.foldersWithLists.flatMap(folderWithLists =>
                                folderWithLists.lists.map(list => (
                                    <TreeItem
                                        key={list._id}
                                        nodeId={list._id}
                                        label={list.name}
                                        onClick={() => setSelectedListId(list._id)}
                                    />
                                ))
                            )}
                            {existingListsData?.listsWithoutFolder.map(list => (
                                <TreeItem
                                    key={list._id}
                                    nodeId={list._id}
                                    label={list.name}
                                    onClick={() => setSelectedListId(list._id)}
                                />
                            ))}
                        </TreeView>
                    </Box>
                    <CustomTooltip title="Upload to Selected List">
                        {isLoading ? (
                            <CircularProgress />
                        ) : (
                            <IconButton onClick={() => handleSubmit('existinglist')} color="primary" disabled={!selectedListId}>
                                <PersonAddAlt1SharpIcon />
                                Add to Existing List
                            </IconButton>
                        )}
                    </CustomTooltip>
                </Paper>

            </Grid>

            {/* Messages Section */}
            <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={() => setSnackbarOpen(false)}>

                <MuiAlert
                    elevation={6}
                    variant="filled"
                    //@ts-ignore
                    severity={snackbarSeverity}
                    onClose={() => setSnackbarOpen(false)}
                >
                    {snackbarMessage}
                </MuiAlert>
            </Snackbar>

            <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={() => setSnackbarOpen(false)}>
                <MuiAlert
                    elevation={6}
                    variant="filled"
                    //@ts-ignore
                    severity={snackbarSeverity}
                    onClose={() => setSnackbarOpen(false)}
                >
                    {snackbarMessage}
                </MuiAlert>
            </Snackbar>


        </Grid>
    );




}
