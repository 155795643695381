// src\app\pages\WarmupV2\Warmup\GlowingFireIcon\GlowingFireIcon.tsx

import React, { useState, useEffect } from 'react';
import { Slider, Box } from '@mui/material';
import { GlowingIcon } from './FireIcon';
import { CustomCursor } from './LighterCursor';
import { Grid } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import CustomTooltip from '@/src/app/modules/CustomMaterialUiComponents/CustomTooltip';
const GlowingFireIcon = ({ isWarmupActive }) => {
    const cursorHover = '/cursor/11va.png';
    const cursorClick = '/cursor/11vQ.svg';

    const [glowIntensity, setGlowIntensity] = useState(Math.random() * 30);
    const [customCursorVisible, setCustomCursorVisible] = useState(false);
    const [customCursorImg, setCustomCursorImg] = useState(cursorHover); // Default cursor
    const [isIncreasing, setIsIncreasing] = useState(false);
    const [lastIncreaseTime, setLastIncreaseTime] = useState(0);
    const [showTooltip, setShowTooltip] = useState(false);

    useEffect(() => {
        // Randomly changing intensity
        const interval = setInterval(() => {
            setGlowIntensity(Math.random() * 30);
        }, 3000); // Change every 3 seconds

        // Decrease intensity over time if not clicked
        let decreaseTimeout;
        const decreaseIntensity = () => {
            decreaseTimeout = setTimeout(() => {
                setGlowIntensity(prev => Math.max(prev - 80, 0));
            }, 3000);
        };

        document.addEventListener('click', () => {
            clearTimeout(decreaseTimeout);
            decreaseIntensity();
        });

        return () => {
            clearInterval(interval);
            clearTimeout(decreaseTimeout);
            document.removeEventListener('click', decreaseIntensity);
        };
    }, []);

    const handleCursorHover = () => {
        setCustomCursorVisible(true);
        setCustomCursorImg(cursorHover);
    };

    const handleCursorLeave = () => {
        setCustomCursorVisible(false);
    };

    // Ensure glowIntensity is zero when warmup is not active

    useEffect(() => {
        if (!isWarmupActive) {
            setGlowIntensity(0); // No glow when warmup is inactive
        }
    }, [isWarmupActive]);

    const handleMouseDown = () => {
        if (!isWarmupActive) {
            setShowTooltip(true);
            setTimeout(() => setShowTooltip(false), 5000); // Hide after 2 seconds
            return;
        }

        const newTargetIntensity = Math.min(glowIntensity + 30, 100);
        setLastIncreaseTime(Date.now());
        graduallyIncreaseIntensity(glowIntensity, newTargetIntensity);
        setCustomCursorImg(cursorClick);
    };


    const handleMouseUp = () => {
        setCustomCursorImg(cursorHover);
    };

    // gradually increase
    const graduallyIncreaseIntensity = (currentIntensity, targetIntensity) => {
        if (currentIntensity < targetIntensity) {
            setIsIncreasing(true);
            const newIntensity = Math.min(currentIntensity + 5, targetIntensity);
            setGlowIntensity(newIntensity);
            setTimeout(() => {
                graduallyIncreaseIntensity(newIntensity, targetIntensity);
            }, 100);
        } else {
            setIsIncreasing(false);
        }
    };

    // Gradually decrease intensity
    useEffect(() => {
        let decreaseTimeout;

        const decreaseIntensityGradually = () => {
            const timeSinceLastIncrease = Date.now() - lastIncreaseTime;

            // Check if warmup is active before decreasing intensity
            if (isWarmupActive && glowIntensity > 0 && !isIncreasing && timeSinceLastIncrease > 5000) {
                setGlowIntensity(prev => Math.max(prev - 0.1, 0)); // Decrease by 0.5 each time
                decreaseTimeout = setTimeout(decreaseIntensityGradually, 2000); // Decrease every 2 seconds
            }
        };

        // Only run the effect when warmup is active
        if (isWarmupActive && glowIntensity > 0 && !isIncreasing) {
            decreaseIntensityGradually();
        }

        return () => {
            clearTimeout(decreaseTimeout);
        };
    }, [isWarmupActive, glowIntensity, isIncreasing, lastIncreaseTime]);




    return (
        <Box textAlign="center" style={{ cursor: "none" }}>
            <Grid container spacing={2} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <CustomTooltip
                    title="Activate warmup first"
                    open={showTooltip}
                    disableHoverListener
                    disableFocusListener
                    disableTouchListener
                >

                    <Grid item xs={11}>

                        <GlowingIcon
                            glowIntensity={isWarmupActive ? glowIntensity : 0} // No glow if not active
                            isWarmupActive={isWarmupActive}  // Pass isWarmupActive prop
                            onMouseEnter={handleCursorHover}
                            onMouseLeave={handleCursorLeave}
                            onMouseDown={handleMouseDown}
                            onMouseUp={handleMouseUp}
                            style={{ cursor: "none" }}
                            isDisabled={!isWarmupActive}
                        />


                    </Grid>
                </CustomTooltip>

                <Grid item xs={1}>

                </Grid>
            </Grid>

            <CustomCursor imgSrc={customCursorImg} isVisible={customCursorVisible} />

        </Box>
    );
};

export default GlowingFireIcon;

