// src\app\pages\LeadGenAdvanced\DetailedView\ProfilesComponent.tsx
import React from 'react';
import { Box, Typography, Card, CardContent, Avatar, Link, Grid, List, ListItem } from '@mui/material';
import { styled } from '@mui/system';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import GitHubIcon from '@mui/icons-material/GitHub';

const ProfileAvatar = styled(Avatar)(({ theme }) => ({
    width: theme.spacing(7),
    height: theme.spacing(7),
    marginBottom: theme.spacing(2),
    backgroundColor: theme.palette.secondary.main,
}));

const ArtisticCard = styled(Card)(({ theme }) => ({
    background: theme.palette.background.paper,
    color: theme.palette.text.primary,
    padding: theme.spacing(3),
    marginBottom: theme.spacing(2),
    borderRadius: theme.shape.borderRadius * 2,
}));

const ProfilesComponent = ({ leadData }) => {
    const getNetworkIcon = (network) => {
        switch (network.toLowerCase()) {
            case 'linkedin':
                return <LinkedInIcon sx={{ fontSize: "45px" }} />;
            case 'facebook':
                return <FacebookIcon sx={{ fontSize: "45px" }} />;
            case 'twitter':
                return <TwitterIcon sx={{ fontSize: "45px" }} />;
            case 'github':
                return <GitHubIcon sx={{ fontSize: "45px" }} />;
            default:
                return <Avatar sx={{ fontSize: "45px" }} />;
        }
    };

    const formatUrl = (url) => {
        try {
            return new URL(url).href;
        } catch {
            return url.startsWith('http') ? url : `http://${url}`;
        }
    };

    return (
        <Box>
            <Typography variant="h4" gutterBottom align="center" sx={{ fontWeight: 'bold', color: 'primary.main' }}>
                Profiles
            </Typography>
            <List>
                {leadData.profiles.map((profile, index) => (
                    <ListItem key={index} disableGutters>
                        <ArtisticCard>
                            <CardContent>
                                <Grid container spacing={3} alignItems="center">
                                    <Grid item>
                                        <ProfileAvatar>
                                            {getNetworkIcon(profile.network)}
                                        </ProfileAvatar>
                                    </Grid>
                                    <Grid item xs>
                                        <Typography variant="h6" gutterBottom>
                                            {profile.network}
                                        </Typography>
                                        {profile.url && (
                                            <Typography variant="body1" component={Link} href={formatUrl(profile.url)} target="_blank" rel="noopener noreferrer" sx={{ color: 'inherit', textDecoration: 'underline' }}>
                                                {profile.url}
                                            </Typography>
                                        )}
                                        {profile.username && (
                                            <Typography variant="body1" sx={{ fontStyle: 'italic' }}>
                                                {profile.username}
                                            </Typography>
                                        )}
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </ArtisticCard>
                    </ListItem>
                ))}
            </List>
        </Box>
    );
};

export default ProfilesComponent;
