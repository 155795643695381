// recommendedSettingsConfig.js

const recommendedSettingsConfig = {
    newAccount: {
      settings: {
        activeDays: ["Saturday", "Sunday"],
        startingEmailCount: 2,
        targetMaxEmailCount: 15,
        includeSignature: true,
        growthRate: 1, // Increase by one email per day
        notes: {
          activeDays: "Start on weekends when it's less busy.",
          startingEmailCount: "Keep it low at first to not overwhelm.",
          targetMaxEmailCount: "Aim for no more than 15 emails a day.",
          includeSignature: "Always good to have your signature in emails.",
          growthRate: "Gradually increase emails to get used to it."
        }
      },
      info: "For new accounts, it's recommended to start with a maximum of 2 emails per day on weekends and increase slowly, reaching a maximum of 15 emails. Including a signature is recommended."
    },
    warmedUpAccount: {
      settings: {
        activeDays: ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"],
        startingEmailCount: 10,
        targetMaxEmailCount: 50,
        includeSignature: true,
        growthRate: 5, // More aggressive growth rate
        notes: {
          activeDays: "Weekdays are best for regular communication.",
          startingEmailCount: "Start with 10 emails, since you're more used to emailing.",
          targetMaxEmailCount: "Grow to 50 emails, a good number for busy accounts.",
          includeSignature: "Keep using your signature for professionalism.",
          growthRate: "Increase quickly, you're ready for more emails."
        }
      },
      info: "For warmed-up accounts, a more aggressive approach is recommended. Start with 10 emails on weekdays and gradually increase to a maximum of 50 emails."
    }
  };
  
  export default recommendedSettingsConfig;
  