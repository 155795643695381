// src\app\pages\1-DashBoard\Charts\ConnectedAccountsChart.tsx
import { SentimentDissatisfiedSharp } from '@mui/icons-material';
import EmailSharpIcon from '@mui/icons-material/EmailSharp';
import WhatshotSharpIcon from '@mui/icons-material/WhatshotSharp';
import {
    Box,
    Button,
    Chip,
    FormControl,
    Grid,
    ListItemIcon,
    ListItemText,
    MenuItem,
    Select,
    Skeleton,
    Typography,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import { useTheme } from '@mui/material/styles';
import { styled } from '@mui/system';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';



interface UserStats {
    sendingAccount: string;
    isWarmupActive: boolean;
    warmupStats: {
        past7Days: {
            sentWarmupEmails: number;
            movedFromSpam: number;
            //  receivedWarmupEmails: number;
        };
        pastMonth: {
            sentWarmupEmails: number;
            movedFromSpam: number;
            //  receivedWarmupEmails: number;
        };
    };
}

const Container = styled('div')({
    margin: '16px',
});

const Dropdown = styled(Select)({
    marginRight: '16px',
    minWidth: '120px', // Added to set a minimum width
});

const ButtonsContainer = styled('div')({
    marginBottom: '16px',
});

const ChartContainer = styled('div')({
    marginBottom: '16px',
});

const ConnectedAccountsInfoChart: React.FC = () => {
    const [chartData, setChartData] = useState<any>(null);
    const [selectedAccount, setSelectedAccount] = useState<string | null>(null);
    const [showWeek, setShowWeek] = useState<boolean>(false);
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
    const [loading, setLoading] = useState(true);

    const theme = useTheme();

    useEffect(() => {
        setLoading(true);
        axios.get(`${process.env.REACT_APP_API_URL}/account/connectedaccountsinfo`)
            .then(response => {
                setChartData(response.data);
                setSelectedAccount(response.data.userStats[0]?.sendingAccount || null);
                setLoading(false);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
                setLoading(false);
            });
    }, []);


    const handleAccountChange = (account: string) => {
        setSelectedAccount(account);
    };

    const handleShowWeek = () => {
        setShowWeek(true);
    };

    const handleShowMonth = () => {
        setShowWeek(false);
    };

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    // Render loading or chart based on the data

    if (loading) {
        return (
            <Container>
                <FormControl fullWidth variant='standard'>
                    <Skeleton variant="rectangular" height={56} />
                </FormControl>
                <Box sx={{ padding: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Skeleton variant="rectangular" width={210} height={60} />
                    <Skeleton variant="rectangular" width={210} height={60} />
                </Box>
                <ButtonsContainer>
                    <Skeleton variant="text" width={100} />
                    <Skeleton variant="text" width={100} />
                </ButtonsContainer>
                <ChartContainer>
                    <Skeleton variant="rectangular" width="100%" height={350} />
                </ChartContainer>
            </Container>
        );
    }

    if (!selectedAccount) {
        return (
            <Container>
                <FormControl fullWidth={true} variant='standard'>
                    <Dropdown
                        value={selectedAccount || ''}
                        onChange={(e) => handleAccountChange(e.target.value as string)}
                        displayEmpty
                        renderValue={selectedAccount !== null ? undefined : () => <Typography>No Accounts</Typography>}
                    >
                        <MenuItem>
                            <ListItemIcon>
                                <SentimentDissatisfiedSharp color='secondary' style={{ fontSize: "45px", paddingRight: "10px" }} />
                            </ListItemIcon>

                            <ListItemText primary="No Email Accounts Connected Yet" />
                        </MenuItem>
                    </Dropdown>
                </FormControl>


                <Grid container direction="column" alignItems="center" style={{ width: '100%', maxWidth: '800px', margin: 'auto' }}>
                    <Grid item container direction="row" style={{ width: '100%', height: 350, marginTop: "35px" }}>
                        {/* Y-axis */}
                        <Grid item style={{ width: '30px', height: '100%' }}>
                            <Box display="flex" flexDirection="column" justifyContent="space-between" height="100%">
                                <Box>100</Box>
                                <Box>80</Box>
                                <Box>60</Box>
                                <Box>40</Box>
                                <Box>20</Box>
                                <Box>0</Box>
                            </Box>
                        </Grid>
                        {/* Vertical bars */}
                        <Grid item container justifyContent="space-around" alignItems="flex-end" style={{ width: 'calc(100% - 30px)', height: 350 }}>
                            <Grid item container direction="column" xs={5} alignItems="center" justifyContent="flex-end" style={{ height: '100%' }}>
                                <Skeleton animation={false} variant="rectangular" width="80%" height="70%" />
                            </Grid>
                            <Grid item container direction="column" xs={5} alignItems="center" justifyContent="flex-end" style={{ height: '100%' }}>
                                <Skeleton animation={false} variant="rectangular" width="80%" height="40%" />
                            </Grid>
                        </Grid>
                    </Grid>
                    {/* Horizontal line to simulate the X-axis */}
                    <Skeleton animation={false} variant="rectangular" width="100%" height={10} />
                </Grid>




                <ChartContainer>

                </ChartContainer>
            </Container>
        );
    }



    // If there is no data, create an empty structure
    if (!chartData || !chartData.userStats) {
        return (
            <Container>
                <FormControl fullWidth={true} variant='standard'>
                    <Dropdown
                        value={selectedAccount || ''}
                        onChange={(e) => handleAccountChange(e.target.value as string)}
                        displayEmpty
                        renderValue={selectedAccount !== null ? undefined : () => <Typography>No Accounts</Typography>}
                    >
                        <MenuItem disabled>
                            <ListItemIcon>
                                <WhatshotSharpIcon style={{ color: grey[400], fontSize: "25px", paddingRight: "10px" }} />
                            </ListItemIcon>
                            <ListItemText primary="No Accounts" />
                        </MenuItem>
                    </Dropdown>
                </FormControl>
                <Box sx={{ padding: "10px", display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: "10px", marginBottom: "10px" }}>
                    <Skeleton variant="rectangular" width={210} height={60} />
                    <Skeleton variant="rectangular" width={210} height={60} />
                </Box>
                <ButtonsContainer sx={{ justifyContent: "right", alignItems: "right", display: "flex" }}>
                    <Skeleton variant="text" width={100} />
                    <Skeleton variant="text" width={100} />
                </ButtonsContainer>
                <ChartContainer>
                    <Skeleton variant="rectangular" width="100%" height={350} />
                </ChartContainer>
            </Container>
        );
    }




    const selectedUser: UserStats | undefined = chartData.userStats.find(
        (user: UserStats) => user.sendingAccount === selectedAccount
    );


    return (
        <Container>
            <Box sx={{ padding: "10px", marginTop: "10px", marginBottom: "10px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                <Typography fontSize={30} >
                    Warmup Summary
                </Typography>
            </Box>
            {/* Dropdown to select sending account */}
            <FormControl
                fullWidth={true}
                variant='standard'
            >
                <Dropdown
                    labelId="select-label"
                    id="select"
                    value={selectedAccount || ''}
                    onChange={(e) => handleAccountChange(e.target.value as string)}
                    onClick={handleClick}
                >
                    {chartData.userStats.map((user: UserStats) => (
                        <MenuItem key={user.sendingAccount} value={user.sendingAccount}>
                            <ListItemIcon>
                                {user.isWarmupActive ? (
                                    <WhatshotSharpIcon color='secondary' style={{ fontSize: "25px", paddingRight: "10px" }} />
                                ) : (
                                    <WhatshotSharpIcon style={{ color: grey[400], fontSize: "25px", paddingRight: "10px" }} />

                                )}

                                <ListItemText primary={user.sendingAccount} />
                            </ListItemIcon>
                        </MenuItem>
                    ))}
                </Dropdown>
            </FormControl>

            {selectedUser && (

                <div>

                    {/* Display warmup stats based on selected account and time period */}
                    <div>

                        {/* Box container for the information */}
                        <Box
                            sx={{
                                padding: "10px",
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                marginTop: "10px",
                                marginBottom: "10px"
                            }}
                        >
                            <Chip
                                label={
                                    <div>
                                        <Typography variant="h1">Connected Accounts: {chartData.totalConnectedAccounts}</Typography>
                                    </div>
                                }
                                color="primary"
                                icon={<EmailSharpIcon />}
                                variant="outlined"
                            />
                            <Chip
                                label={
                                    <div>
                                        <Typography variant="h1">Warming Up: {chartData.activeWarmupAccounts}</Typography>
                                    </div>
                                }
                                color="secondary"
                                icon={<WhatshotSharpIcon />}
                                variant="outlined"
                            />
                        </Box>

                    </div>


                    {/* Buttons to show emails for the past week and month */}
                    <ButtonsContainer sx={{ justifyContent: "right", alignItems: "right", display: "flex" }} >
                        <Button color='info' sx={{ fontSize: "10px" }} variant="text" onClick={handleShowWeek}>
                            Past Week
                        </Button>
                        <Button color='info' sx={{ fontSize: "10px" }} variant="text" onClick={handleShowMonth}>
                            Past Month
                        </Button>
                    </ButtonsContainer>

                    {/* Render the chart here using ApexCharts */}
                    <ChartContainer>
                        <Chart
                            options={{
                                chart: {
                                    type: 'bar' as const,
                                    height: 350,
                                    toolbar: {
                                        show: false,
                                    },
                                },
                                xaxis: {
                                    categories: [
                                        'Sent',
                                        // 'Received',
                                        'Rescued from Junk Folders'
                                    ],
                                    labels: {
                                        style: {
                                            colors: theme.palette.info.main,
                                            fontSize: "10px",
                                        },
                                    },
                                },
                                yaxis: [{
                                    labels: {
                                        style: {
                                            colors: theme.palette.info.main,
                                            fontSize: "15px",
                                        },
                                    },
                                }],
                                grid: {
                                    show: false, // Hide horizontal grid lines
                                },
                                plotOptions: {

                                    bar: {
                                        colors: {
                                            ranges: [{
                                                from: 0,
                                                to: 1000,
                                                color: theme.palette.secondary.main,
                                            }],
                                        },
                                        columnWidth: '70%',
                                    },
                                },
                                dataLabels: {
                                    style: {
                                        colors: ['#fff'],
                                        fontSize: "20px",
                                    },
                                },
                                theme: {
                                    mode: 'light',
                                },
                            }}
                            series={[
                                {
                                    name: 'Emails',
                                    data: [
                                        showWeek
                                            ? selectedUser.warmupStats.past7Days.sentWarmupEmails
                                            : selectedUser.warmupStats.pastMonth.sentWarmupEmails,
                                        //   showWeek
                                        // ? selectedUser.warmupStats.past7Days.receivedWarmupEmails
                                        // : selectedUser.warmupStats.pastMonth.receivedWarmupEmails,
                                        showWeek
                                            ? selectedUser.warmupStats.past7Days.movedFromSpam
                                            : selectedUser.warmupStats.pastMonth.movedFromSpam,
                                    ],
                                },
                            ]}
                            type="bar"
                            height={350}
                        />
                    </ChartContainer>

                </div>
            )}
        </Container>
    );
};

export default ConnectedAccountsInfoChart;
