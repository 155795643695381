import React from 'react';
import InboxPanel from './InboxPanel';
import ComposeInbox from './ComposeInbox';
const Inbox: React.FC = () => {
    return (
        <>
            <div
                id="kt_content_container"
                className="d-flex flex-column-fluid align-items-start container-xxl"
            >
                <div className="content flex-row-fluid" id="kt_content">
                    <div className="d-flex flex-column flex-lg-row">
                        <InboxPanel />
                        <ComposeInbox />
                    </div>
                </div>
            </div>
        </>
    );
};

export default Inbox;
