import { RefreshSharp } from '@mui/icons-material';
import FolderIcon from '@mui/icons-material/Folder';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import axios from 'axios';
import * as React from 'react';
import { useState } from 'react';
import Swal from 'sweetalert2';

export default function CreateListFolder({ RefreshLists }) {
    const [listName, setListName] = useState('');
    const [open, setOpen] = useState(false);
    const [actionType, setActionType] = useState(''); // 'list' or 'folder'

    const handleClickOpen = (type) => {
        setActionType(type);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSubmit = () => {
        if (!listName) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: `Please enter a ${actionType} name`,
            });
            return;
        }

        const url = actionType === 'list' ?
            `${process.env.REACT_APP_API_URL}/leads/create-list` :
            `${process.env.REACT_APP_API_URL}/leads/folders/create-folder`;

        axios.post(url, { name: listName })
            .then(response => {
                Swal.fire({
                    icon: 'success',
                    title: 'Success!',
                    text: `${actionType.charAt(0).toUpperCase() + actionType.slice(1)} created successfully`,
                });
                handleClose();
            })
            .catch(error => {
                Swal.fire({
                    icon: 'error',
                    title: 'Error!',
                    text: `Error creating ${actionType}`,
                });
                console.error(`Error creating ${actionType}:`, error);
            });
    };

    return (
        <>
            {/* <IconButton aria-label="add" size="large" onClick={() => handleClickOpen('list')}>
                <PlaylistAddIcon fontSize="inherit" />
            </IconButton> */}
            <IconButton aria-label="add-folder" size="large" onClick={() => handleClickOpen('folder')}>
                <FolderIcon fontSize="inherit" />
            </IconButton>
            <IconButton onClick={RefreshLists} sx={{ mx: 'auto' }}>
                <RefreshSharp />
            </IconButton>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Create New {actionType.charAt(0).toUpperCase() + actionType.slice(1)}</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label={actionType.charAt(0).toUpperCase() + actionType.slice(1) + ' Name'}
                        type="text"
                        fullWidth
                        variant="standard"
                        value={listName}
                        onChange={(e) => setListName(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={handleSubmit}>Create</Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
