//src\app\pages\LeadGenAdvanced\Parent-LeadGenAdvanced.tsx
import './AdvancedLeadGenParent.css';

import { useAuth } from '@/src/app/modules/auth';
import { CheckSharp, CleanHandsSharp, CloudDoneSharp, DriveFolderUploadSharp, PersonSearchSharp, SaveSharp } from '@mui/icons-material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Box, Button, Card, CardContent, CardHeader, Chip, CircularProgress, Drawer, Fab, Grid, IconButton, Paper, Toolbar, Tooltip, Typography, useTheme } from '@mui/material';
import { GridPaginationModel } from '@mui/x-data-grid-premium';
import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';

import DraggableDialog from '../../../modules/CustomMaterialUiComponents/DraggableDialog';
// import Collections from './Collections/CollectionsParent';
import ContentArea from './ContentArea';
import LeadDetailDialog from './LeadDetailDialog';
import LeadImportComponent from './LeadImportComponent';
import SaveSearchComponent from './SaveSearchComponent';
import SelectedLeadsDisplay from './SelectedLeadsDisplay';
import SidePanel from './SidePanel';
import { green, orange } from '@mui/material/colors';
import CustomTooltip from '@/src/app/modules/CustomMaterialUiComponents/CustomTooltip';
import { motion } from 'framer-motion';

interface AdvancedLeadGenParentProps {
    selectedCampaignId?: any;
}

const AdvancedLeadGenParent: React.FC<AdvancedLeadGenParentProps> = ({ selectedCampaignId }) => {

    const { currentUser } = useAuth();
    const theme = useTheme();

    const [selectedLeadId, setSelectedLeadId] = useState<string | null>(null);
    const [isFilterPanelVisible, setIsFilterPanelVisible] = useState(true);
    const [drawerOpen, setDrawerOpen] = useState(false); // State to control the drawer
    const [isSaveDialogOpen, setIsSaveDialogOpen] = useState(false);
    const [activeSearchId, setActiveSearchId] = useState("");
    const [activeSearchName, setActiveSearchName] = useState("");
    const [isUpdating, setIsUpdating] = useState(false); // State to track updating status
    const [success, setSuccess] = useState(false);
    const [updateLoading, setUpdateLoading] = useState(false);
    const [exactJobTitle, setExactJobTitle] = useState(false); // New state

    const [searchQuery, setSearchQuery] = useState('');
    const [leads, setLeads] = useState([]);
    const [pageSize, setPageSize] = useState(10);
    const [actualPage, setActualPage] = useState(Math.floor(Math.random() * 100)); // Random starting page
    const [userVisiblePage, setUserVisiblePage] = useState(0); // Starts at 0, representing page 1 for the user
    const [rowCount, setRowCount] = useState(0);
    const [selectedIndustry, setSelectedIndustry] = useState<string[]>([]);
    const [totalPages, setTotalPages] = useState(0);
    const [loading, setLoading] = useState(false);
    const [excludedJobTitles, setExcludedJobTitles] = useState<string[]>([]);
    const [selectedJobTitle, setSelectedJobTitle] = useState<string[]>([]);
    const [selectedCountries, setSelectedCountries] = useState<{ code: string; name: string; }[]>([]);
    const [selectedContinent, setSelectedContinent] = useState<string[]>([]);
    const [selectedRegion, setSelectedRegion] = useState<string[]>([]);
    const [excludedRegion, setExcludedRegion] = useState<string[]>([]);
    const [selectedGender, setSelectedGender] = useState<string>('');
    const [hobbies, setHobbies] = useState<string[]>([]);
    const [skills, setSkills] = useState<string[]>([]);
    const [foundedBefore, setFoundedBefore] = useState('');
    const [foundedAfter, setFoundedAfter] = useState('');
    const [selectedLeads, setSelectedLeads] = useState<string[]>([]);

    // const [isCollectionsDialogOpen, setIsCollectionsDialogOpen] = useState(false);
    const [isLeadImportDialogOpen, setIsLeadImportDialogOpen] = useState(false);

    const [isIndustrySelected, setIsIndustrySelected] = useState(false);
    const [isCountrySelected, setIsCountrySelected] = useState(false);

    const [hasPhone, setHasPhone] = useState(false);
    const [hasPersonalEmail, setHasPersonalEmail] = useState(false);
    const [hasProfessionalEmail, setHasProfessionalEmail] = useState(false);



    const [leadImportInfo, setLeadImportInfo] = useState({
        remainingLeadCredits: 0,
        totalLeads: 0,
        revealedLeadsCount: 0,
    });

    const [debugQuery, setDebugQuery] = useState('');

    const [filtersApplied, setFiltersApplied] = useState(false);
    const [filtersChanged, setFiltersChanged] = useState(false);

    const handleSelectedLeadsChange = (newSelectedLeads) => {
        setSelectedLeads(newSelectedLeads);
    };

    const handleOpenSaveDialog = () => {
        setIsSaveDialogOpen(true);
    };

    const handleCloseSaveDialog = () => {
        setIsSaveDialogOpen(false);
    };

    const toggleLeadImportDialog = () => {
        setIsLeadImportDialogOpen(!isLeadImportDialogOpen);
    };

    // Callback function to update activeSearchId and name
    const handleActiveSearchIdChange = (id) => {
        setActiveSearchId(id);
    };
    const handleActiveSearchNameChange = (name: string) => {
        setActiveSearchName(name);
    };

    // const handleOpenCollectionsDialog = () => {
    //     setIsCollectionsDialogOpen(true);
    // };



    const timerRef = useRef<number>();


    useEffect(() => {
        return () => {
            if (timerRef.current) {
                clearTimeout(timerRef.current);
            }
        };
    }, []);

    useEffect(() => {
        setIsIndustrySelected(selectedIndustry.length > 0);
        setIsCountrySelected(
            selectedCountries.length > 0 ||
            selectedContinent.length > 0 ||
            selectedRegion.length > 0
        );
    }, [selectedIndustry, selectedCountries, selectedContinent, selectedRegion]);

    const filters = {
        selectedIndustry,
        selectedJobTitle,
        selectedCountries,
        selectedContinent,
        selectedRegion,
        excludedRegion,
        selectedGender,
        hobbies,
        skills,
        foundedBefore,
        foundedAfter,
        hasPhone,
        hasPersonalEmail,
        hasProfessionalEmail,
        exactJobTitle,
        excludedJobTitles
    };

    useEffect(() => {
        const fetchLeadImportInfo = async () => {
            try {
                // Prepare the filters in the expected format
                const preparedFilters = {
                    ...(filters.selectedJobTitle && { job_title: filters.selectedJobTitle.join(';'), exact_job_title: filters.exactJobTitle }),
                    ...(filters.excludedJobTitles && { exclude_job_title: filters.excludedJobTitles.join(';') }),
                    ...(filters.selectedIndustry && { industry: filters.selectedIndustry.join(';') }),
                    ...(filters.selectedCountries && { include_country: filters.selectedCountries.map(c => c.name).join(';') }),
                    ...(filters.selectedContinent && { include_continent: filters.selectedContinent.join(';') }),
                    ...(filters.selectedRegion && { include_region: filters.selectedRegion.join(';') }),
                    ...(filters.excludedRegion && { exclude_region: filters.excludedRegion.join(';') }),
                    ...(filters.selectedGender && { gender: filters.selectedGender }),
                    ...(filters.hobbies && { hobbies: filters.hobbies.join(';') }),
                    ...(filters.skills && { skills: filters.skills.join(';') }),
                    ...(filters.foundedBefore && { founded_before: filters.foundedBefore }),
                    ...(filters.foundedAfter && { founded_after: filters.foundedAfter }),
                    ...(filters.hasPhone && { has_phone: 'true' }),
                    ...(filters.hasPersonalEmail && { has_personal_email: 'true' }),
                    ...(filters.hasProfessionalEmail && { has_professional_email: 'true' }),
                };


                const response = await axios.get(`${process.env.REACT_APP_API_URL}/advancedleadgen/lead-import-info`, {
                    params: { filters: preparedFilters }
                });

                setLeadImportInfo(response.data);
            } catch (error: any) {
                // setSnackbarMessage(error.response?.data?.message || 'Error fetching lead import info');
                // setSnackbarSeverity('error');
                // setSnackbarOpen(true);
                console.error('Error fetching lead import info:', error);
            }
        };

        fetchLeadImportInfo();
    }, [filtersApplied]);


    useEffect(() => {
        const fetchLeads = async () => {

            if (!filtersApplied) return; // Only proceed if filters are applied


            if (!isIndustrySelected || !isCountrySelected) {
                setLeads([]);
                setRowCount(0);
                setTotalPages(0);
                setLoading(false);
                return;
            }

            setLoading(true); // Start loading

            // Ensure actualPage is within valid range before making API call
            const validPage = Math.max(0, Math.min(actualPage, totalPages - 1));

            // Construct queryParams dynamically based on whether the filters have values
            const queryParams = {
                // page: validPage + 1,
                page: 1,
                limit: pageSize,
                ...(selectedIndustry.length > 0 && { industry: selectedIndustry.join(';') }),
                ...(selectedJobTitle.length > 0 && { job_title: selectedJobTitle.join(';'), exact_job_title: exactJobTitle }),
                ...(excludedJobTitles.length > 0 && { exclude_job_title: excludedJobTitles.join(';') }),
                ...(selectedCountries.length > 0 && { include_country: selectedCountries.map(c => c.name).join(';') }),
                ...(selectedContinent.length > 0 && { include_continent: selectedContinent.join(';') }),
                ...(selectedRegion.length > 0 && { include_region: selectedRegion.join(';') }),
                ...(excludedRegion.length > 0 && { exclude_region: excludedRegion.join(';') }),
                ...(selectedGender && { gender: selectedGender }),
                ...(hobbies.length > 0 && { hobbies: hobbies.join(';') }),
                ...(skills.length > 0 && { skills: skills.join(';') }),
                ...(foundedBefore && { founded_before: foundedBefore }),
                ...(foundedAfter && { founded_after: foundedAfter }),
                ...(hasPhone && { has_phone: 'true' }),
                ...(hasPersonalEmail && { has_personal_email: 'true' }),
                ...(hasProfessionalEmail && { has_professional_email: 'true' }),
            };

            const queryString = Object.keys(queryParams)
                .map(key => `${key}=${queryParams[key]}`)
                .join('&');

            setDebugQuery(queryString);

            if (filtersApplied) { // Only fetch data if filtersApplied is true
                try {
                    const response = await axios.get(`${process.env.REACT_APP_API_URL}/advancedleadgen/search`, { params: queryParams });
                    if (response.data) {
                        setLeads(response.data.leads);
                        setRowCount(response.data.total);
                        setTotalPages(Math.ceil(response.data.total / pageSize));
                    }
                } catch (error) {
                    console.error('Error fetching leads:', error);
                    setLeads([]);
                    setRowCount(0);
                    setTotalPages(0);
                } finally {
                    setLoading(false); // Set loading to false regardless of success or error
                }
            }
        };

        fetchLeads();
    }, [
        filtersApplied,  // Fetch leads when filters are applied

        // actualPage,       // Uncomment if pagination is needed
        // pageSize,         // Uncomment if page size changes should trigger fetching
    ]);



    useEffect(() => {
        // Mark filters as changed whenever any of the filter dependencies change
        setFiltersChanged(true);
        setFiltersApplied(false);
    }, [
        selectedIndustry,
        selectedJobTitle,
        selectedCountries,
        selectedContinent,
        selectedRegion,
        excludedRegion,
        selectedGender,
        hobbies,
        skills,
        foundedBefore,
        foundedAfter,
        hasPhone,
        hasPersonalEmail,
        hasProfessionalEmail,
        exactJobTitle,
        excludedJobTitles
    ]);


    // Adjust actualPage and userVisiblePage when totalPages changes
    useEffect(() => {
        if (totalPages > 1) {
            setActualPage(Math.floor(Math.random() * (totalPages - 1)));
            setUserVisiblePage(0);
        } else if (totalPages === 1) {
            setActualPage(0);
            setUserVisiblePage(0);
        }
    }, [totalPages]); // Only depend on totalPages


    const handlePaginationModelChange = (model: GridPaginationModel) => {
        let newActualPage = model.page + actualPage - userVisiblePage;

        // Ensure newActualPage is within valid range
        newActualPage = Math.max(0, Math.min(newActualPage, totalPages - 1));

        setUserVisiblePage(model.page);
        setActualPage(newActualPage);
        setPageSize(model.pageSize);
    };

    const paginationModel = {
        page: userVisiblePage,
        pageSize: pageSize,
    };

    const handleSelectionModelChange = (newSelection: string[]) => {
        setSelectedLeads(newSelection);
    };


    const handleViewLead = (leadId: string) => {
        setSelectedLeadId(leadId);
    };


    // const toggleDrawer = () => {
    //     setDrawerOpen(!drawerOpen);
    // };

    // Function to clear the selection
    const handleClearSelection = () => {
        handleSelectionModelChange([]);
        handleSelectedLeadsChange([]);
    };

    const circularDialogStyles = {
        style: {
            borderRadius: '10%',
            width: '600px', // Fixed width
            height: '600px', // Fixed height to maintain circle shape
            overflow: 'hidden', // Prevent content from spilling out
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
    };

    const handleUpdateSearch = async () => {
        setSuccess(false);
        setUpdateLoading(true);

        const updateData = {
            searchId: activeSearchId,
            searchName: activeSearchName,
            searchParams: {
                selectedIndustry: selectedIndustry,
                excludedJobTitles: excludedJobTitles,
                selectedJobTitle: selectedJobTitle,
                selectedCountries: selectedCountries,
                selectedContinent: selectedContinent,
                selectedRegion: selectedRegion,
                excludedRegion: excludedRegion,
                selectedGender: selectedGender,
                hobbies: hobbies,
                skills: skills,
                foundedBefore: foundedBefore,
                foundedAfter: foundedAfter
            },
            actualPage: actualPage,
            userVisiblePage: userVisiblePage,
            selectedLeads: selectedLeads
        };
        try {
            const response = await axios.put(`${process.env.REACT_APP_API_URL}/advancedleadgen/update-search`, updateData);
            console.log('Search Updated:', response.data);

            // Ensure loading state is shown for at least 1 second
            timerRef.current = window.setTimeout(() => {
                setSuccess(true); // Set success true here, after the fake loading time

                // Reset success state after an additional delay
                timerRef.current = window.setTimeout(() => {
                    setSuccess(false);
                }, 3000); // Time for sucess message
                setUpdateLoading(false); // End the updating process
            }, 4000); // Fake loading time

        } catch (error) {
            console.error('Error updating search:', error);
            setSuccess(false); // Optionally set success to false in case of an error
            setUpdateLoading(false); // End the updating process immediately in case of an error
        }
    };

    // Animations

    const variants = {
        hidden: { opacity: 0, x: -100 },
        visible: { opacity: 1, x: 0 },
        exit: { opacity: 0, x: 100 }
    };

    const handleDeleteNoEmailLeads = async () => {
        try {
            const response = await axios.delete(`${process.env.REACT_APP_API_URL}/advancedleadgen/delete-no-emails`);
            console.log('Deleted:', response.data); // Or handle this response in a more user-friendly way
            // Optionally refresh your leads list or show a success message
        } catch (error) {
            console.error('Error deleting leads with no emails:', error);
            // Optionally handle error, e.g., show an error message
        }
    };

    const buttonSx = {
        ...(success && {
            bgcolor: green[500],
            '&:hover': {
                bgcolor: green[700],
            },
        }),
    };

    const buttonVariants = {
        hover: {
            scale: 1.1,
            transition: {
                type: 'spring',
                stiffness: 300,
                damping: 10
            }
        },
        tap: {
            scale: 0.95,
            transition: {
                type: 'spring',
                stiffness: 300,
                damping: 10
            }
        }
    };

    return (
        <div className="root">

            <Grid container>


                {/* Drawer component
                <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer}>
                    <Box sx={{ maxWidth: '500px', width: '100%', padding: '16px' }}>
                        <SelectedLeadsDisplay
                            selectedLeads={selectedLeads}
                            onSelectionModelChange={handleSelectionModelChange}
                            onSelectedLeadsChange={handleSelectedLeadsChange}
                        />
                    </Box>
                </Drawer> */}


                {/* Save Search Dialog */}
                <DraggableDialog
                    open={isSaveDialogOpen}
                    onClose={handleCloseSaveDialog}
                    title="Save Search"
                    enableExternalWindow={false}
                    backgroundBlur={false}
                    content={
                        <SaveSearchComponent
                            selectedIndustry={selectedIndustry}
                            setSelectedIndustry={setSelectedIndustry}
                            excludedJobTitles={excludedJobTitles}
                            setExcludedJobTitles={setExcludedJobTitles}
                            selectedJobTitle={selectedJobTitle}
                            setSelectedJobTitle={setSelectedJobTitle}
                            selectedCountries={selectedCountries}
                            setSelectedCountries={setSelectedCountries}
                            selectedContinent={selectedContinent}
                            setSelectedContinent={setSelectedContinent}
                            selectedRegion={selectedRegion}
                            setSelectedRegion={setSelectedRegion}
                            excludedRegion={excludedRegion}
                            setExcludedRegion={setExcludedRegion}
                            selectedGender={selectedGender}
                            setSelectedGender={setSelectedGender}
                            hobbies={hobbies}
                            setHobbies={setHobbies}
                            skills={skills}
                            setSkills={setSkills}
                            foundedBefore={foundedBefore}
                            setFoundedBefore={setFoundedBefore}
                            foundedAfter={foundedAfter}
                            setFoundedAfter={setFoundedAfter}
                            actualPage={actualPage}
                            setActualPage={setActualPage}
                            userVisiblePage={userVisiblePage}
                            setUserVisiblePage={setUserVisiblePage}
                            selectedLeads={selectedLeads}
                            setSelectedLeads={setSelectedLeads}
                            activeSearchId={activeSearchId}
                            activeSearchName={activeSearchName}
                            onActiveSearchIdChange={setActiveSearchId}
                            onActiveSearchNameChange={setActiveSearchName}

                        />
                    }
                />


                {/* <DraggableDialog
                    open={isLeadImportDialogOpen}
                    onClose={toggleLeadImportDialog}
                    title="Import Leads"
                    content={<LeadImportComponent
                        selectedLeads
                        filters
                        leadImportInfo
                        setLeadImportInfo
                    />}
                /> */}


                {/* <DraggableDialog
                    open={isCollectionsDialogOpen}
                    onClose={() => setIsCollectionsDialogOpen(false)}
                    title="Lead Collections"
                    content={<Collections />}
                /> */}


                {/* Side Panel and Content Area wrapped in one Paper */}
                <Grid item xs={12}>
                    <Paper elevation={0} sx={{ background: "transparent" }} className="sidePanelAndContent">





                        <Grid container spacing={2}>
                            {/* Side Panel */}
                            {isFilterPanelVisible && (

                                <Grid item xs={isFilterPanelVisible ? 3 : 0} className={isFilterPanelVisible ? 'sidePanel' : 'sidePanelHidden'}>
                                    {/* 
                                    <IconButton onClick={() => setIsFilterPanelVisible(!isFilterPanelVisible)}>
                                        {isFilterPanelVisible ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                                    </IconButton> */}


                                    <SidePanel
                                        selectedIndustry={selectedIndustry}
                                        setSelectedIndustry={(value) => { setSelectedIndustry(value); setIsIndustrySelected(value.length > 0); }}
                                        selectedJobTitle={selectedJobTitle}
                                        setSelectedJobTitle={setSelectedJobTitle}
                                        excludedJobTitles={excludedJobTitles}
                                        setExcludedJobTitles={setExcludedJobTitles}
                                        selectedCountries={selectedCountries}
                                        setSelectedCountries={(value) => { setSelectedCountries(value); setIsCountrySelected(value.length > 0); }}
                                        setSelectedContinent={setSelectedContinent}
                                        selectedContinent={selectedContinent}
                                        selectedRegion={selectedRegion}
                                        setSelectedRegion={setSelectedRegion}
                                        excludedRegion={excludedRegion}
                                        setExcludedRegion={setExcludedRegion}
                                        selectedGender={selectedGender}
                                        setSelectedGender={setSelectedGender}
                                        hobbies={hobbies}
                                        setHobbies={setHobbies}
                                        skills={skills}
                                        setSkills={setSkills}
                                        foundedBefore={foundedBefore}
                                        setFoundedBefore={setFoundedBefore}
                                        foundedAfter={foundedAfter}
                                        setFoundedAfter={setFoundedAfter}
                                        hasPhone={hasPhone}
                                        setHasPhone={setHasPhone}
                                        hasPersonalEmail={hasPersonalEmail}
                                        setHasPersonalEmail={setHasPersonalEmail}
                                        hasProfessionalEmail={hasProfessionalEmail}
                                        setHasProfessionalEmail={setHasProfessionalEmail}
                                        exactJobTitle={exactJobTitle}
                                        setExactJobTitle={setExactJobTitle}
                                        filtersApplied={filtersApplied}
                                        setFiltersApplied={setFiltersApplied}
                                    />
                                </Grid>
                            )}

                            <Grid item xs={isLeadImportDialogOpen ? 5 : 9}>
                                <>
                                    <Toolbar>
                                        <Box sx={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                            {/* Left side button (My Searches or Save - depending on search loaded state) */}
                                            <Box sx={{ display: 'flex', gap: '8px', alignContent: "center", alignItems: "center" }}>
                                                {/* If search is loaded, show My Searches button */}
                                                {activeSearchId && activeSearchName && (
                                                    <CustomTooltip title="My Searches">
                                                        <motion.div
                                                            className="no-global-transition"
                                                            whileHover="hover" whileTap="tap" variants={buttonVariants}>
                                                            <IconButton onClick={handleOpenSaveDialog} color="primary" sx={{ ml: 2 }}>
                                                                <PersonSearchSharp sx={{ fontSize: "25px" }} />
                                                            </IconButton>
                                                        </motion.div>
                                                    </CustomTooltip>
                                                )}


                                            </Box>

                                            {/* Right side button (Save button - only if search is loaded) */}
                                            <Box>

                                                {/* If search is NOT loaded, show Save button acting as My Searches button */}
                                                {!activeSearchId && !activeSearchName && (
                                                    <CustomTooltip title="My Searches">
                                                        <Box sx={{ m: 1, position: 'relative' }}>
                                                            <Fab
                                                                aria-label="save"
                                                                color="primary"
                                                                sx={buttonSx}
                                                                onClick={handleOpenSaveDialog} // Call handleOpenSaveDialog instead of handleUpdateSearch
                                                            >
                                                                <DriveFolderUploadSharp sx={{ fontSize: "35px" }} />
                                                            </Fab>
                                                        </Box>
                                                    </CustomTooltip>
                                                )}

                                                {activeSearchId && activeSearchName && (
                                                    <CustomTooltip title={`Save Changes to ${activeSearchName}`}>
                                                        <Box sx={{ m: 1, position: 'relative' }}>
                                                            <Fab
                                                                aria-label="save"
                                                                color="primary"
                                                                sx={buttonSx}
                                                                onClick={handleUpdateSearch}
                                                            >
                                                                {success ? <CloudDoneSharp sx={{ fontSize: "35px" }} /> : <SaveSharp sx={{ fontSize: "35px" }} />}
                                                            </Fab>
                                                            {updateLoading && (
                                                                <CircularProgress
                                                                    size={68}
                                                                    color='secondary'
                                                                    sx={{
                                                                        position: 'absolute',
                                                                        top: -6,
                                                                        left: -6,
                                                                        zIndex: 1,
                                                                    }}
                                                                />
                                                            )}
                                                        </Box>
                                                    </CustomTooltip>
                                                )}
                                            </Box>
                                        </Box>
                                    </Toolbar>
                                    {/* <CustomTooltip title="My Leads">
          <IconButton onClick={handleOpenCollectionsDialog} color="primary">
              <CollectionsBookmarkSharpIcon />
          </IconButton>
      </CustomTooltip> */}
                                    {/* Boxes Above Content Area */}

                                    <Card elevation={0} style={{ borderRadius: '16px', background: "transparent" }}>
                                        <CardContent>
                                            <Grid container spacing={2}>
                                                {/* Available Credits */}
                                                <Grid item xs={12} sm={3}>
                                                    <Tooltip title="Remaining credits you can use to import leads" arrow>
                                                        <Card elevation={1} style={{ padding: '16px', textAlign: 'center', borderRadius: '8px', height: '120px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                                            <Typography variant="subtitle1" color="textSecondary" style={{ marginBottom: '8px' }}>
                                                                Available Credits
                                                            </Typography>
                                                            <Chip label={leadImportInfo.remainingLeadCredits} color="primary" style={{ fontSize: '1.5rem' }} />
                                                        </Card>
                                                    </Tooltip>
                                                </Grid>

                                                {/* Total Matches */}
                                                <Grid item xs={12} sm={3}>
                                                    <Tooltip title="Total leads matching the current filter" arrow>
                                                        <Card elevation={1} style={{ padding: '16px', textAlign: 'center', borderRadius: '8px', height: '120px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                                            <Typography variant="subtitle1" color="textSecondary" style={{ marginBottom: '8px' }}>
                                                                Total Matches
                                                            </Typography>
                                                            <Chip label={leadImportInfo.totalLeads} color="secondary" style={{ fontSize: '1.5rem' }} />
                                                        </Card>
                                                    </Tooltip>
                                                </Grid>

                                                {/* Already Imported */}
                                                <Grid item xs={12} sm={3}>
                                                    <Tooltip title="Leads matching the current filter that you have already Imported" arrow>
                                                        <Card elevation={1} style={{ padding: '16px', textAlign: 'center', borderRadius: '8px', height: '120px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                                            <Typography variant="subtitle1" color="textSecondary" style={{ marginBottom: '8px' }}>
                                                                Already Imported
                                                            </Typography>
                                                            <Chip label={leadImportInfo.revealedLeadsCount} color="default" style={{ fontSize: '1.5rem' }} />
                                                        </Card>
                                                    </Tooltip>
                                                </Grid>

                                                {/* Ready? */}
                                                <Grid item xs={12} sm={3}>
                                                    <Tooltip title="Import Leads" arrow>
                                                        <Card elevation={1} style={{ padding: '16px', textAlign: 'center', borderRadius: '8px', height: '120px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                                            <Typography variant="subtitle1" color="textSecondary" style={{ marginBottom: '8px' }}>
                                                                Ready?
                                                            </Typography>
                                                            <Chip label="Import Leads" color="primary" clickable onClick={toggleLeadImportDialog} />
                                                        </Card>
                                                    </Tooltip>
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                    </Card>
                                </>


                                <ContentArea
                                    leads={leads}
                                    paginationModel={paginationModel}
                                    setPaginationModel={handlePaginationModelChange}
                                    rowCount={rowCount}
                                    selectedLeads={selectedLeads}
                                    onSelectionModelChange={handleSelectionModelChange}
                                    onSelectedLeadsChange={handleSelectedLeadsChange}
                                    loading={loading}
                                    onViewLead={handleViewLead}
                                    handleUpdateSearch={handleUpdateSearch}
                                    handleOpenSaveDialog={handleOpenSaveDialog}
                                    // toggleDrawer={toggleDrawer}
                                    success={success}
                                    activeSearchId={activeSearchId}
                                    activeSearchName={activeSearchName}
                                    updateLoading={updateLoading}
                                    // handleOpenCollectionsDialog={handleOpenCollectionsDialog}
                                    toggleLeadImportDialog={toggleLeadImportDialog}
                                    isIndustrySelected={isIndustrySelected} // Pass industry selected state
                                    isCountrySelected={isCountrySelected} // Pass country selected state
                                    onIndustryClick={() => setIsFilterPanelVisible(true)} // Add callback to show filter panel
                                    onCountryClick={() => setIsFilterPanelVisible(true)} // Add callback to show filter panel                                
                                />

                            </Grid>

                            <Grid item xs={isLeadImportDialogOpen ? 4 : 12}>
                                {isLeadImportDialogOpen && (
                                    <LeadImportComponent
                                        selectedLeads={selectedLeads}
                                        filters={filters}
                                        leadImportInfo={leadImportInfo}
                                        setLeadImportInfo={setLeadImportInfo}
                                        selectedCampaignId={selectedCampaignId}
                                    />
                                )}

                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>

                {
                    selectedLeadId && (
                        <LeadDetailDialog
                            leadId={selectedLeadId}
                            onClose={() => setSelectedLeadId(null)}
                        />
                    )
                }



                {currentUser && currentUser.admin && (
                    <>
                        {/* Debug Info */}
                        <Grid item xs={12}>
                            <Paper className="content" sx={{ padding: "30px", marginTop: "100px" }}>
                                <strong>Debug Info:</strong>
                                <div>User Visible Page (Fake Page): {userVisiblePage + 1}</div>
                                <div>Actual Page (API Request Page): {actualPage + 1}</div>
                                <div>Page Size: {pageSize}</div>
                                <div>Row Count: {rowCount}</div>
                                <div>Total Pages: {totalPages}</div>
                                <div>Debug Query: {debugQuery}</div> {/* Display the query here */}
                                {/*         <div>Leads: {JSON.stringify(leads)}</div> */}
                            </Paper>
                        </Grid>

                        <Button
                            variant="contained"
                            color="error"
                            onClick={handleDeleteNoEmailLeads}
                            style={{ margin: '10px' }} // Adjust styling as needed
                        >
                            <CleanHandsSharp />

                        </Button>
                    </>

                )}

            </Grid>
        </div>
    );
};

export default AdvancedLeadGenParent;
