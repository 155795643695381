// src\app\pages\WarmupV2\Warmup\Helper\HelpMeSettings.tsx
import React, { useEffect, useState, ReactElement } from 'react'; // Corrected import
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import recommendedSettingsConfig from './recommendedSettingsConfig';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import EmailIcon from '@mui/icons-material/Email';
import WeekendIcon from '@mui/icons-material/Weekend';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer'; // For detecting when elements enter the viewport
import NetworkCheckSharpIcon from '@mui/icons-material/NetworkCheckSharp';
import AcUnitSharpIcon from '@mui/icons-material/AcUnitSharp';
import CheckBoxSharpIcon from '@mui/icons-material/CheckBoxSharp';

const variants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1 }
};
const iconAnimation = {
  hidden: { opacity: 0, x: -20 },
  visible: { opacity: 1, x: 0, transition: { delay: 0.2 } },
};

const listItemAnimation = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0, transition: { delay: 0.4 } },
};


const HelpMeSettings = ({ applyRecommendedSettings, currentSettings }) => {
  const [selectedPlan, setSelectedPlan] = useState('newAccount');
  const [ulRef, inView] = useInView({ triggerOnce: true });
  const controls = useAnimation();

  useEffect(() => {
    if (inView) {
      controls.start('visible');
    }
  }, [controls, inView]);

  const handlePlanChange = (event) => {
    setSelectedPlan(event.target.value);
  };

  const applySettings = () => {
    const settingsToApply = recommendedSettingsConfig[selectedPlan].settings;
    applyRecommendedSettings(settingsToApply);
  };


  useEffect(() => {
    controls.start(selectedPlan);
  }, [selectedPlan, controls]);


  const renderSettingValue = (value: unknown): ReactElement | string => {
    if (typeof value === 'string') {
      return <>{value}</>; // Wrap string in a fragment
    }
    // Type guard for objects including React elements
    if (typeof value === 'object' && value !== null && React.isValidElement(value)) {
      return value as ReactElement;
    }
    if (Array.isArray(value)) {
      return value.join(', '); // Convert array to string
    }
    return 'Invalid value'; // Fallback for other types
  };


  const getIconColor = (plan) => plan === 'newAccount' ? '#793A99' : '#FAAF00';


  const iconStyle = {
    fontSize: '2rem', // Larger icon size
    verticalAlign: 'middle',
    marginRight: '10px'
  };

  const getIcon = (setting) => {
    switch (setting) {
      case 'activeDays':
        return <WeekendIcon />;
      case 'startingEmailCount':
        return <EmailIcon />;
      // Add cases for other settings
      default:
        return <AccessTimeIcon />;
    }
  };
  const iconColorVariants = {
    newAccount: { color: '#793A99' },
    warmedUpAccount: { color: '#FAAF00' }
  };



  return (
    <div>
      <Paper style={{ padding: '20px', marginTop: '20px', background: getIconColor(selectedPlan), fontSize: '2rem' }}
      >
        <FormControl component="fieldset">
          <RadioGroup
            row
            aria-label="account-plan"
            name="account-plan"
            value={selectedPlan}
            onChange={handlePlanChange}
          >
            <Radio
              value="newAccount"
              style={{ justifyContent: "center", margin: "0 auto" }}
              icon={<AcUnitSharpIcon color='primary' sx={{ color: "#fff", fontSize: 80 }} />}
              checkedIcon={<AcUnitSharpIcon color='primary' sx={{ borderRadius: "50%", padding: "5px", background: "#ffffff", fontSize: 80 }} />}
            />

            <Radio
              value="warmedUpAccount"
              checkedIcon={<NetworkCheckSharpIcon color='secondary' sx={{ borderRadius: "50%", padding: "5px", background: "#ffffff", fontSize: 80 }} />}
              icon={<NetworkCheckSharpIcon sx={{ color: "#fff", fontSize: 80 }} />}
              style={{ justifyContent: "center", margin: "0 auto" }}
            />
          </RadioGroup>

        </FormControl>

      </Paper>

      <Paper style={{ padding: '20px', marginTop: '20px' }}>

        <Button
          onClick={applySettings}
          color='primary'

        >
          <CheckBoxSharpIcon sx={{ fontSize: "25px" }} />
          Apply
        </Button>
      </Paper>

      <Paper style={{ padding: '20px', marginTop: '20px' }}>

        <Typography variant='h6'>Recommended Settings for {selectedPlan === 'newAccount' ? 'New Accounts' : 'Warmed Up Accounts'}:


        </Typography>
        <ul ref={ulRef}>
          {Object.entries(recommendedSettingsConfig[selectedPlan].settings).map(([setting, value]) => {
            if (setting === 'notes') return null;

            return (
              <motion.li
                key={setting}
                className="animated-list-item"
                initial="hidden"
                animate={controls}
                variants={listItemAnimation} // Apply the animation to list items
                transition={{ duration: 0.5 }}
              >
                <li key={setting} className="animated-list-item">
                  <motion.span
                    style={{ color: getIconColor(selectedPlan), fontSize: '2rem' }}
                    initial="hidden"
                    animate={controls}
                    variants={iconAnimation}
                  >
                    {getIcon(setting)}
                  </motion.span>
                  {setting}: {renderSettingValue(value)}
                  <div style={{ margin: '10px 0', padding: '10px' }}>
                    <Typography variant='body2'>
                      {recommendedSettingsConfig[selectedPlan].settings.notes[setting]}
                    </Typography>
                  </div>
                </li>
              </motion.li>
            );
          })}
        </ul>
        <Divider />

      </Paper>
    </div>
  );
};

export default HelpMeSettings;
