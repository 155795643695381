import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import React, { useState } from 'react';

interface ConfirmDeletionDialogProps {
    open: boolean;
    onClose: () => void;
    onConfirm: () => void;
}

export const ConfirmDeletionDialog: React.FC<ConfirmDeletionDialogProps> = ({ open, onClose, onConfirm }) => {
    const [confirmationText, setConfirmationText] = useState('');

    const isConfirmDisabled = confirmationText !== 'Yes, delete everything';

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Confirm Deletion</DialogTitle>
            <DialogContent>
                <p>Deleting email accounts deletes all associated settings and pauses all associated campaigns. Type the confirmation phrase to proceed.
                    Confirmation Phrase: "Yes, delete everything"
                </p>
                <TextField
                    autoFocus
                    margin="dense"
                    id="confirmationText"
                    label="Confirmation Phrase"
                    type="text"
                    fullWidth
                    variant="outlined"
                    value={confirmationText}
                    onChange={(e) => setConfirmationText(e.target.value)}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button color='error' onClick={onConfirm} disabled={isConfirmDisabled}>Delete</Button>
            </DialogActions>
        </Dialog>
    );
};
