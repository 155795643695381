// src\app\pages\0-Account\EmailAccounts\EmailConnection\ConnectionMethods\GoogleWorkspaceForm.tsx
import SettingsEthernetIcon from '@mui/icons-material/SettingsEthernet';
import { Box, Button, List, ListItem, ListItemIcon, ListItemText, Paper, Typography } from '@mui/material';
import React from 'react';

const GoogleWorkspaceForm: React.FC = () => {
    const handleAuthClick = () => {
        // Configure the new window's features
        // Open a new window with specified features
        window.location.href = `${process.env.REACT_APP_API_URL}/account/auth/google`;
    };




    return (
        <Paper elevation={3} sx={{ padding: 3, margin: 2 }}>
            <Typography variant="h6" gutterBottom>
                Connect to Google Workspace
            </Typography>
            <Box sx={{ marginBottom: 2 }}>
                <Typography variant="body1">
                    Before connecting, please ensure your IMAP settings are correctly configured in your Google Workspace account.
                </Typography>
                <List>
                    <ListItem>
                        <ListItemIcon>
                            <SettingsEthernetIcon />
                        </ListItemIcon>
                        <ListItemText primary="Navigate to your Google Workspace account settings." />
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <SettingsEthernetIcon />
                        </ListItemIcon>
                        <ListItemText primary="Locate and access the IMAP settings." />
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <SettingsEthernetIcon />
                        </ListItemIcon>
                        <ListItemText primary="Enable IMAP access for your account." />
                    </ListItem>
                </List>
            </Box>
            <Button variant="contained" color="primary" onClick={handleAuthClick}>
                Connect Now
            </Button>
        </Paper>
    );
};

export default GoogleWorkspaceForm;
