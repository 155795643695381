import { Box, Grid } from '@mui/material';
import React, { ReactNode } from 'react';

interface RandomBoxBackgroundProps {
    numberOfBoxes: number;
    minSize: number; // Minimum size for the random boxes
    maxSize: number; // Maximum size for the random boxes
    colors?: string[]; // Array of colors to randomly assign
    background?: string;
    children?: ReactNode;
}

const RandomBoxBackgroundComponent: React.FC<RandomBoxBackgroundProps> = ({
    numberOfBoxes,
    minSize,
    maxSize,
    colors = ['#DA9A05'], // Default colors
    background = 'transparent',
    children,
}) => {
    const containerStyle = {
        position: 'relative',
        backgroundColor: background,
        minHeight: '100vh', // Ensure full height
    };

    // Generate random position, size, and animation for each box
    const boxes = Array.from({ length: numberOfBoxes }, (_, index) => {
        const randomColor = colors[Math.floor(Math.random() * colors.length)];
        const randomPositionX = Math.random() * 100; // Random horizontal position
        const randomPositionY = Math.random() * 100; // Random vertical position
        const randomWidth = Math.floor(Math.random() * (maxSize - minSize + 1)) + minSize; // Random width within the specified range
        const randomHeight = Math.floor(Math.random() * (maxSize - minSize + 1)) + minSize; // Random height within the specified range
        return (
            <Box
                key={index}
                sx={{
                    position: 'absolute',
                    backgroundColor: randomColor,
                    width: `${randomWidth}px`, // Random width
                    height: `${randomHeight}px`, // Random height
                    left: `${randomPositionX}%`,
                    top: `${randomPositionY}%`,
                    zIndex: 1, // Ensure boxes are above the overlay
                }}
            />
        );
    });

    return (
        <Grid container sx={containerStyle}>
            <Box
                sx={{
                    position: 'relative',
                    zIndex: 0,
                    width: '100%',
                    height: '100%',
                }}
            >
                {/* Glass overlay */}
                <Box
                    sx={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        backgroundColor: 'rgba(255, 255, 255, 0.2)', // Transparent white color
                        backdropFilter: 'blur(40px)', // Apply backdrop filter
                        WebkitBackdropFilter: 'blur(40px)',
                        zIndex: 2, // Ensure overlay is above the boxes
                    }}
                />
                {boxes}
            </Box>
            {children}
        </Grid>
    );
};

export default RandomBoxBackgroundComponent;
