// src\app\pages\0-Account\ProfilePage\ProfileTabs\admin\systeminfo.tsx
import RefreshIcon from '@mui/icons-material/Refresh';
import {
    Box,
    Button,
    Chip,
    Container,
    Grid,
    Paper,
    Skeleton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react';

interface SystemHealth {
    cpuLoad: {
        '1 min': string;
        '5 min': string;
        '15 min': string;
    };
    memoryUsage: {
        total: string;
        free: string;
        used: string;
    };
    uptime: string;
    diskUsage: DiskUsage[];
}

interface DiskUsage {
    filesystem: string;
    type: string;
    size: string;
    used: string;
    avail: string;
    pcent: string;
    mountedOn: string;
}

const SystemHealth: React.FC = () => {
    const [systemHealth, setSystemHealth] = useState<SystemHealth | null>(null);
    const [loading, setLoading] = useState(true);

    const fetchSystemHealth = async () => {
        setLoading(true);
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/admin/systemHealth`);
            setSystemHealth(response.data);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching system health:', error);
            setSystemHealth({
                cpuLoad: {
                    '1 min': '',
                    '5 min': '',
                    '15 min': '',
                },
                memoryUsage: {
                    total: '',
                    free: '',
                    used: '',
                },
                uptime: '',
                diskUsage: [],
            });
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchSystemHealth();
    }, []);

    return (
        <Container>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                <Typography variant="h4" gutterBottom>
                    System Health
                </Typography>
                <Button
                    variant="contained"
                    color="primary"
                    startIcon={<RefreshIcon />}
                    onClick={fetchSystemHealth}
                >
                    Refresh
                </Button>
            </Box>
            <Grid container spacing={3}>
                <Grid item xs={12} md={4}>
                    <Paper sx={{ p: 2 }}>
                        <Typography variant="h6">CPU Load</Typography>
                        <Box>
                            {loading ? (
                                <Skeleton variant="rectangular" width={210} height={30} />
                            ) : (
                                <>
                                    <Chip label={`1 min: ${systemHealth?.cpuLoad['1 min'] || ''}`} color="primary" sx={{ m: 0.5 }} />
                                    <Chip label={`5 min: ${systemHealth?.cpuLoad['5 min'] || ''}`} color="secondary" sx={{ m: 0.5 }} />
                                    <Chip label={`15 min: ${systemHealth?.cpuLoad['15 min'] || ''}`} color="success" sx={{ m: 0.5 }} />
                                </>
                            )}
                        </Box>
                    </Paper>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Paper sx={{ p: 2 }}>
                        <Typography variant="h6">Memory Usage</Typography>
                        <Box>
                            {loading ? (
                                <Skeleton variant="rectangular" width={210} height={30} />
                            ) : (
                                <>
                                    <Chip label={`Total: ${systemHealth?.memoryUsage.total || ''}`} color="primary" sx={{ m: 0.5 }} />
                                    <Chip label={`Free: ${systemHealth?.memoryUsage.free || ''}`} color="secondary" sx={{ m: 0.5 }} />
                                    <Chip label={`Used: ${systemHealth?.memoryUsage.used || ''}`} color="success" sx={{ m: 0.5 }} />
                                </>
                            )}
                        </Box>
                    </Paper>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Paper sx={{ p: 2 }}>
                        <Typography variant="h6">Uptime</Typography>
                        {loading ? (
                            <Skeleton variant="rectangular" width={210} height={30} />
                        ) : (
                            <Typography>{systemHealth?.uptime || ''}</Typography>
                        )}
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Filesystem</TableCell>
                                    <TableCell>Type</TableCell>
                                    <TableCell>Size</TableCell>
                                    <TableCell>Used</TableCell>
                                    <TableCell>Available</TableCell>
                                    <TableCell>Usage</TableCell>
                                    <TableCell>Mounted On</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {loading ? (
                                    <TableRow>
                                        <TableCell colSpan={7}>
                                            <Skeleton variant="rectangular" width="100%" height={30} />
                                        </TableCell>
                                    </TableRow>
                                ) : (
                                    systemHealth?.diskUsage.map((disk, index) => (
                                        <TableRow key={index}>
                                            <TableCell>{disk.filesystem}</TableCell>
                                            <TableCell>{disk.type}</TableCell>
                                            <TableCell>{disk.size}</TableCell>
                                            <TableCell>{disk.used}</TableCell>
                                            <TableCell>{disk.avail}</TableCell>
                                            <TableCell>{disk.pcent}</TableCell>
                                            <TableCell>{disk.mountedOn}</TableCell>
                                        </TableRow>
                                    )) || (
                                        <TableRow>
                                            <TableCell colSpan={7}>
                                                <Typography>No data available</Typography>
                                            </TableCell>
                                        </TableRow>
                                    )
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
        </Container>
    );
};

export default SystemHealth;
