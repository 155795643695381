// src\app\pages\0-Account\EmailAccounts\EmailAccountsPage.tsx
import { LinkSharp } from '@mui/icons-material';
import AddSharpIcon from '@mui/icons-material/AddSharp';
import DensitySmallSharpIcon from '@mui/icons-material/DensitySmallSharp';
import DrawSharpIcon from '@mui/icons-material/DrawSharp';
import EditNoteSharpIcon from '@mui/icons-material/EditNoteSharp';
import EditSharpIcon from '@mui/icons-material/EditSharp';
import FileCopySharpIcon from '@mui/icons-material/FileCopySharp';
import FormatAlignJustifySharpIcon from '@mui/icons-material/FormatAlignJustifySharp';
import PlaylistAddSharpIcon from '@mui/icons-material/PlaylistAddSharp';
import QuestionMarkSharpIcon from '@mui/icons-material/QuestionMarkSharp';
import RefreshSharpIcon from '@mui/icons-material/RefreshSharp';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import React, { useEffect, useState } from 'react';

import DraggableDialog from '../../../modules/CustomMaterialUiComponents/DraggableDialog';
import MenuBar from '../../../modules/CustomMaterialUiComponents/MenuBar';
import ConnectPhoneNumbers from './ConnectPhones/ConnectPhoneNumbers';
import { TwilioIntegration } from './ConnectPhones/connectTwilioAccount';
import EmailConnectionMethodsGuide from './Guides/EmailConnectionMethodsGuide';
import EmailSetupGuide from './Guides/Guide-SMTP-IMAP';

const EmailAccountsPageTopMenu = ({ onNewAccount, onConnectMultiple, onCompactView, onLargeView, RefreshAccountList, onSignatureEdit, onChangeNames, onLinkTracking }) => {

  const [open, setOpen] = useState(false);
  const toggleDialog = () => setOpen(!open);
  const [open2, setOpen2] = useState(false);
  const toggleDialog2 = () => setOpen2(!open2);
  const [openEmailConnectonMethodsGuide, setOpenEmailConnectonMethodsGuide] = useState(false);
  const toggleEmailConnectonMethodsGuide = () => setOpenEmailConnectonMethodsGuide(!openEmailConnectonMethodsGuide);
  const [openTwilioIntegration, setOpenTwilioIntegration] = useState(false);
  const toggleTwilioIntegration = () => setOpenTwilioIntegration(!openTwilioIntegration);
  const [openConnectPhoneNumbers, setConnectPhoneNumbers] = useState(false);
  const toggleConnectPhoneNumbers = () => setConnectPhoneNumbers(!openConnectPhoneNumbers);


  const menuItems = {
    Connect: {
      icon: <FileCopySharpIcon style={{ fontSize: "20px" }} />,
      items: [
        { text: "New Email Account", icon: <AddSharpIcon />, action: onNewAccount },
        { text: "Multiple Email Accounts", icon: <PlaylistAddSharpIcon />, action: onConnectMultiple },
        { add: "Divider" },

        { text: "SMS Provider", icon: <AddSharpIcon />, action: toggleTwilioIntegration },
        { text: "Manage Phone Numbers", icon: <PlaylistAddSharpIcon />, action: toggleConnectPhoneNumbers },

        // { add: "Divider" },
        // { add: "Divider" },
        // { add: "Divider" },
        // { add: "Divider" },
        // { add: "Divider" },
        // { text: "Perform Connection Check", icon: <GppGoodSharpIcon />, action: () => setOpen(!open) },
      ],
    },

    Edit: {
      icon: <EditSharpIcon style={{ fontSize: "20px" }} />,
      items: [
        { text: "Edit Accounts Info", icon: <EditNoteSharpIcon />, action: onChangeNames },
        { text: "Edit Signatures", icon: <DrawSharpIcon />, action: onSignatureEdit },
        { text: "Link Tracking Domains", icon: <LinkSharp />, action: onLinkTracking }, // Use an appropriate icon

      ],
    },
    View: {
      icon: <ZoomInIcon style={{ fontSize: "20px" }} />, // Assuming ZoomInIcon as the icon for the View menu button for example
      items: [
        { text: "Compact", icon: <FormatAlignJustifySharpIcon />, action: onCompactView },
        { text: "Large", icon: < DensitySmallSharpIcon />, action: onLargeView },
        { add: "Divider" },
        { add: "Divider" },
        { add: "Divider" },
        { add: "Divider" },
        { add: "Divider" },
        { text: "Refresh List", icon: < RefreshSharpIcon />, action: RefreshAccountList },
      ],
    },
    // Fix: {
    //   icon: <BuildSharpIcon />, // Assuming ZoomOutMapIcon as the icon for the Fix menu button for example
    //   items: [
    //   ],
    // },
    "Help": {
      icon: <QuestionMarkSharpIcon style={{ fontSize: "20px" }} />, // Assuming ZoomOutMapIcon as the icon for the Fix menu button for example
      items: [
        { text: "Email Connection Methods", icon: <QuestionMarkSharpIcon />, action: toggleEmailConnectonMethodsGuide },
        { text: "How-To: Connect Your Email Using SMTP & IMAP", icon: <QuestionMarkSharpIcon />, action: () => setOpen2(!open2) },
        { text: "More Guides Coming Soon", icon: <QuestionMarkSharpIcon />, action: () => (!open2) },

      ],
    },
  };


  return (
    <div>
      <MenuBar menus={menuItems} />

      {/* <DraggableDialog
        open={open}
        onClose={toggleDialog}
        content={<CakeTutorial />}
        title="Cake Making Tutorial"
        enableExternalWindow={true}
      /> */}

      <DraggableDialog
        open={open2}
        onClose={toggleDialog2}
        content={<EmailSetupGuide />}
        title="How-To: Connect Your Email Using SMTP & IMAP"
        enableExternalWindow={true}
      />

      <DraggableDialog
        open={openEmailConnectonMethodsGuide}
        onClose={toggleEmailConnectonMethodsGuide}
        content={<EmailConnectionMethodsGuide />}
        title="Email Connection Methods"
        enableExternalWindow={true}
      />
      <DraggableDialog
        open={openTwilioIntegration}
        onClose={toggleTwilioIntegration}
        content={<TwilioIntegration />}
        title="Connect SMS Provider"
        enableExternalWindow={true}
      />

      <DraggableDialog
        open={openConnectPhoneNumbers}
        onClose={toggleConnectPhoneNumbers}
        content={<ConnectPhoneNumbers />}
        title="Manage Phone Numbers"
        enableExternalWindow={true}
      />
    </div>
  );
};

export default EmailAccountsPageTopMenu;
