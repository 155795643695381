// GlassEffectContext.js
import React, { createContext, useContext, useState } from 'react';

const GlassEffectContext = createContext({
  isGlassEffectEnabled: true,
  toggleGlassEffect: () => { }
});

export const useGlassEffect = () => useContext(GlassEffectContext);

export const GlassEffectProvider = ({ children }) => {
  const [isGlassEffectEnabled, setIsGlassEffectEnabled] = useState(true);

  const toggleGlassEffect = () => {
    setIsGlassEffectEnabled(prevState => !prevState);
  };

  return (
    <GlassEffectContext.Provider value={{ isGlassEffectEnabled, toggleGlassEffect }}>
      {children}
    </GlassEffectContext.Provider>
  );
};
