// src/app/modules/auth/components/EmailVerifiedPage.tsx
import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Typography, Container } from '@mui/material';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';



const EmailVerifiedPage = () => {
    return (

        <>




            <Container
                component='main'
                maxWidth='xs'
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    marginTop: 8,
                    textAlign: 'center',
                }}
            >
                <VerifiedUserIcon
                    sx={{
                        fontSize: '64px',
                        color: (theme) => theme.palette.secondary.main,
                        marginBottom: 2,
                    }}
                />
                <Typography
                    variant='h1'
                    sx={{
                        fontSize: '32px',
                        fontWeight: 'bold',
                        marginBottom: 2,
                    }}
                >
                    Email Verified
                </Typography>
                <Typography
                    variant='body1'
                    sx={{
                        fontSize: '18px',
                        color: (theme) => theme.palette.text.secondary,
                        marginBottom: 4,
                    }}
                >
                    Your email has been successfully verified. You can now login.
                </Typography>

                <Link to='/auth/login'>
                    <Button
                        variant='contained'
                        color='primary'
                        size='large'
                        sx={{
                            fontSize: '20px',
                        }}
                    >
                        Go to Login
                    </Button>
                </Link>
            </Container>
        </>
    );
};

export default EmailVerifiedPage;
