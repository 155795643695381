import { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import Popper from '@mui/material/Popper';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import HomeSharpIcon from '@mui/icons-material/HomeSharp';
import Groups3SharpIcon from '@mui/icons-material/Groups3Sharp';
import { motion } from 'framer-motion';
import QuestionAnswerSharpIcon from '@mui/icons-material/QuestionAnswerSharp';
import SendSharpIcon from '@mui/icons-material/SendSharp';
import SettingsSharpIcon from '@mui/icons-material/SettingsSharp';
import { Drawer, Tooltip, useMediaQuery } from '@mui/material';
import { WidgetsSharp } from '@mui/icons-material';
import { styled } from '@mui/material/styles';

export function MenuInner() {
  const location = useLocation();
  const [anchorEl, setAnchorEl] = useState(null);
  const [popperLabel, setPopperLabel] = useState('');
  const [open, setOpen] = useState(false);
  const [submenuAnchorEl, setSubmenuAnchorEl] = useState(null);
  const isSubmenuOpen = Boolean(submenuAnchorEl);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const isMobile = useMediaQuery('(max-width: 991px)');

  const spring = {
    type: "spring",
    stiffness: 700,
    damping: 30
  };

  const handleSubmenuOpen = (event) => {
    setSubmenuAnchorEl(event.currentTarget);
  };

  const handleSubmenuClose = () => {
    setSubmenuAnchorEl(null);
  };

  const handleMouseEnter = (event, label) => {
    setAnchorEl(event.currentTarget);
    setPopperLabel(label);
    setOpen(true);
  };

  const handleMouseLeave = () => {
    setOpen(false);
    setAnchorEl(null);
  };

  const isPathForLeads = (currentPath) => {
    const leadsPaths = ["/my-leads", "/local-lead-search", "/advanced-lead-search"];
    return leadsPaths.includes(currentPath);
  };

  const getLeadsIconColor = () => {
    return isPathForLeads(location.pathname) ? "#F79010" : "var(--bs-primary)";
  };

  const getIconColor = (path) => {
    return location.pathname === path ? "#F79010" : "var(--bs-primary)";
  };


  const CustomTooltip = ({ title, children, ...props }) => (
    <Tooltip
      followCursor
      title={<span style={{ fontSize: '1rem', color: '#fff' }}>{title}</span>}
      arrow
      componentsProps={{
        tooltip: {
          sx: {
            bgcolor: '#793A99',
            color: '#fff',
            fontSize: '0.875rem',
            padding: '10px 15px',
            borderRadius: '8px',
            boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
          },
        },
        arrow: {
          sx: {
            color: '#793A99',
          },
        },
      }}
      {...props}
    >
      {children}
    </Tooltip>
  );


  return (
    <>
      {!isMobile && (
        <>
          <CustomTooltip

            title="Dashboard"

          >
            <IconButton component={Link} size='large' to="/dashboard" color="primary">
              <motion.div
                className="no-global-transition"
                whileHover={{ scale: 1.2, rotate: 10, transition: spring }}
                whileTap={{ scale: 0.9, rotate: -10, transition: spring }}
              >
                <HomeSharpIcon sx={{ margin: "10px 20px", fontSize: "35px", color: getIconColor("/dashboard") }} />
              </motion.div>
            </IconButton>
          </CustomTooltip>

          <CustomTooltip title="Connections" >
            <IconButton component={Link} to="/email-accounts" color="primary">
              <motion.div
                className="no-global-transition"
                whileHover={{ scale: 1.2, rotate: 10, transition: spring }}
                whileTap={{ scale: 0.9, rotate: -10, transition: spring }}
              >
                <SettingsSharpIcon sx={{ margin: '10px 20px', fontSize: '35px', color: getIconColor('/email-accounts') }} />
              </motion.div>
            </IconButton>
          </CustomTooltip>

          <CustomTooltip title="Campaigns">
            <IconButton component={Link} to="/my-campaigns" color="primary">
              <motion.div
                className="no-global-transition"
                whileHover={{ scale: 1.2, rotate: 10, transition: spring }}
                whileTap={{ scale: 0.9, rotate: -10, transition: spring }}
              >
                <SendSharpIcon sx={{ margin: "10px 20px", fontSize: "35px", color: getIconColor("/my-campaigns") }} />
              </motion.div>
            </IconButton>
          </CustomTooltip>

          <CustomTooltip title="Inbox">
            <IconButton component={Link} to="/inbox" color="primary">
              <motion.div
                className="no-global-transition"
                whileHover={{ scale: 1.2, rotate: 10, transition: spring }}
                whileTap={{ scale: 0.9, rotate: -10, transition: spring }}
              >
                <QuestionAnswerSharpIcon sx={{ margin: "10px 20px", fontSize: "35px", color: getIconColor("/inbox") }} />
              </motion.div>
            </IconButton>
          </CustomTooltip>


          <CustomTooltip title="Leads" >
            <IconButton color="primary" onClick={handleSubmenuOpen}>
              <motion.div
                className="no-global-transition"
                whileHover={{ scale: 1.2, rotate: 10, transition: spring }}
                whileTap={{ scale: 0.9, rotate: -10, transition: spring }}
              >
                <Groups3SharpIcon sx={{ margin: '10px 20px', fontSize: '35px', color: getLeadsIconColor() }} />
              </motion.div>
            </IconButton>
          </CustomTooltip>

        </>
      )}

      <Menu
        anchorEl={submenuAnchorEl}
        open={isSubmenuOpen}
        onClose={handleSubmenuClose}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      >
        <MenuItem component={Link} to="/my-leads" onClick={handleSubmenuClose}>
          My Lists
        </MenuItem>
        {/* <MenuItem>
          Local Leads (SOON)
        </MenuItem> */}
        <MenuItem component={Link} to="/advanced-lead-search" onClick={handleSubmenuClose}>
          Advanced Lead Search
        </MenuItem>
      </Menu>

      {isMobile && (
        <>
          <IconButton
            color="primary"
            onClick={() => setDrawerOpen(true)}
            size="large"
            sx={{ width: '100%', justifyContent: 'flex-start' }}
          >
            <WidgetsSharp color='info' sx={{ fontSize: "35px" }} />
          </IconButton>
          <Drawer
            anchor="left"
            open={drawerOpen}
            onClose={() => setDrawerOpen(false)}
          >
            <IconButton component={Link} size='large' to="/dashboard" color="primary"
              onMouseEnter={(e) => handleMouseEnter(e, 'Dashboard')}
              onMouseLeave={handleMouseLeave}
              onClick={() => setDrawerOpen(false)}
            >
              <motion.div
                className="no-global-transition"
                whileHover={{ scale: 1.2, rotate: 10, transition: spring }}
                whileTap={{ scale: 0.9, rotate: -10, transition: spring }}
              >
                <HomeSharpIcon sx={{ margin: "10px 20px", fontSize: "35px", color: getIconColor("/dashboard") }} />
              </motion.div>
              Dashboard
            </IconButton>

            <IconButton component={Link} to="/email-accounts" color="primary"
              onMouseEnter={(e) => handleMouseEnter(e, 'Connections')}
              onMouseLeave={handleMouseLeave}
              onClick={() => setDrawerOpen(false)}
            >
              <motion.div
                className="no-global-transition"
                whileHover={{ scale: 1.2, rotate: 10, transition: spring }}
                whileTap={{ scale: 0.9, rotate: -10, transition: spring }}
              >
                <SettingsSharpIcon sx={{ margin: "10px 20px", fontSize: "35px", color: getIconColor("/email-accounts") }} />
              </motion.div>
              Connections
            </IconButton>


            <IconButton component={Link} to="/my-campaigns" color="primary"
              onMouseEnter={(e) => handleMouseEnter(e, 'Campaigns')}
              onMouseLeave={handleMouseLeave}
              onClick={() => setDrawerOpen(false)}
            >
              <motion.div
                className="no-global-transition"
                whileHover={{ scale: 1.2, rotate: 10, transition: spring }}
                whileTap={{ scale: 0.9, rotate: -10, transition: spring }}
              >
                <SendSharpIcon sx={{ margin: "10px 20px", fontSize: "35px", color: getIconColor("/my-campaigns") }} />
              </motion.div>
              My Campaigns
            </IconButton>

            <IconButton component={Link} to="/inbox" color="primary"
              onMouseEnter={(e) => handleMouseEnter(e, 'Inbox')}
              onMouseLeave={handleMouseLeave}
              onClick={() => setDrawerOpen(false)}
            >
              <motion.div
                className="no-global-transition"
                whileHover={{ scale: 1.2, rotate: 10, transition: spring }}
                whileTap={{ scale: 0.9, rotate: -10, transition: spring }}
              >
                <QuestionAnswerSharpIcon sx={{ margin: "10px 20px", fontSize: "35px", color: getIconColor("/inbox") }} />
              </motion.div>
              Replies
            </IconButton>

            <IconButton color="primary"
              onMouseEnter={(e) => handleMouseEnter(e, 'Leads')}
              onMouseLeave={handleMouseLeave}
              onClick={handleSubmenuOpen}>
              <motion.div
                className="no-global-transition"
                whileHover={{ scale: 1.2, rotate: 10, transition: spring }}
                whileTap={{ scale: 0.9, rotate: -10, transition: spring }}
              >
                <Groups3SharpIcon sx={{ margin: "10px 20px", fontSize: "35px", color: getLeadsIconColor() }} />
              </motion.div>
            </IconButton>
          </Drawer>
        </>
      )}
    </>
  );
}
