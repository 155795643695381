import React, { useEffect, useRef } from 'react';

const EmailIframe: React.FC<{ html: string }> = ({ html }) => {
    const iframeRef = useRef<HTMLIFrameElement>(null);

    useEffect(() => {
        if (iframeRef.current) {
            const doc = iframeRef.current.contentDocument || iframeRef.current.contentWindow?.document;
            if (doc) {
                // Create a temporary DOM element to manipulate the HTML
                const tempDiv = document.createElement('div');
                tempDiv.innerHTML = html;

                // Find all anchor tags and set target="_blank"
                const anchors = tempDiv.getElementsByTagName('a');
                for (let i = 0; i < anchors.length; i++) {
                    anchors[i].setAttribute('target', '_blank');
                    anchors[i].setAttribute('rel', 'noopener noreferrer'); // Adds security
                }

                // Write the modified HTML back to the iframe's document
                doc.open();
                doc.write(tempDiv.innerHTML);
                doc.close();
            }
        }
    }, [html]);

    return (
        <iframe
            ref={iframeRef}
            style={{ width: '100%', height: '450px', border: 'none' }}
            title="Email Content"
        />
    );
};

export default EmailIframe;
