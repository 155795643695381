import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Typography, Container, Box } from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

const PaymentCancelPage = () => {
    return (
        <Container component="main" maxWidth="xs" sx={{ mt: 8 }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <ErrorOutlineIcon sx={{ m: 1, fontSize: 60, color: 'error.main' }} />
                <Typography component="h1" variant="h5" sx={{ mb: 2 }}>
                    Payment Canceled
                </Typography>
                <Typography variant="body1" sx={{ textAlign: 'center', mb: 3 }}>
                    You have canceled the payment process. If this was a mistake, you can attempt to make a payment again.
                </Typography>
                <Link to="/pricing" style={{ textDecoration: 'none' }}>
                    <Button type="button" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
                        View Pricing Plans
                    </Button>
                </Link>
            </Box>
        </Container>
    );
};

export default PaymentCancelPage;
