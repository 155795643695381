// src\app\pages\3-CRM\ListQueueProgressBar.tsx
import { AccessTime, CheckCircle, ErrorOutline, HourglassEmpty } from '@mui/icons-material';
import { Box, Chip, LinearProgress, Stack, Typography } from '@mui/material';
import { Hourglass } from 'lucide-react';
import React from 'react';

/**
 * Component that renders a visually enhanced progress bar and job status for a list queue.
 * 
 * @param {Object} jobCounts Object containing counts of jobs in different states.
 * @param {string} title Title of the progress bar section.
 */
const ListQueueProgressBar = ({ jobCounts: { active, waiting, completed, delayed, failed, totalScheduled }, title }) => {
    // Calculate the total number of jobs and the number of remaining jobs.
    const totalJobs = active + waiting + completed + delayed;
    const remainingJobs = active + waiting + delayed;

    // Compute the percentage of progress.
    const progressPercent = totalJobs === 0 ? 0 : ((totalJobs - remainingJobs) / totalJobs) * 100;

    return (
        <Box width="100%" my={2}>
            <Typography variant="h6" gutterBottom component="div" sx={{ fontWeight: 'medium' }}>
                {title}
            </Typography>
            <Stack direction="row" spacing={1} alignItems="center" mb={1}>
                <Chip icon={<HourglassEmpty />} label={`Active: ${active}`} color="primary" />
                <Chip icon={<AccessTime />} label={`Waiting: ${waiting}`} color="warning" />
                <Chip icon={<CheckCircle />} label={`Completed: ${completed}`} color="success" />
                <Chip icon={<Hourglass />} label={`Delayed: ${delayed}`} color="secondary" />
                <Chip icon={<ErrorOutline />} label={`Failed: ${failed}`} color="error" />
            </Stack>
            <LinearProgress variant="determinate" value={progressPercent} sx={{ height: 20, borderRadius: 5 }} />
            <Typography variant="subtitle1" align="center" mt={2}>
                Total: {totalScheduled}
            </Typography>
        </Box>
    );
};

export default ListQueueProgressBar;
