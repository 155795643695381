import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { DataGridPremium } from '@mui/x-data-grid-premium';
import { Lead } from './CollectionsDataStructure/Lead';
import EmailSharpIcon from '@mui/icons-material/EmailSharp';
import PhoneAndroidSharpIcon from '@mui/icons-material/PhoneAndroidSharp';

interface CollectionLeadsProps {
    collectionId: string;
}

function CollectionLeads({ collectionId }: CollectionLeadsProps) {
    const [leads, setLeads] = useState<Lead[]>([]);
    const [expandedRow, setExpandedRow] = useState<string | null>(null);

    const renderDetails = (leadId: string) => {
        const lead = leads.find(l => l.id === leadId);
        if (!lead) return null;

        return (
            <div style={{ padding: '10px', margin: '10px' }}>
                {lead.emails && lead.emails.length > 0 && (
                    <div>
                        {lead.emails.map(e => (
                            <p key={e.address}>
                                <EmailSharpIcon color='secondary' sx={{ fontSize: "25px", margin: "10px" }} />   {e.address} <em>  {e.type}</em>
                            </p>
                        ))}
                    </div>
                )}
                {lead.phone_numbers && lead.phone_numbers.length > 0 && (
                    <div>
                        {lead.phone_numbers.map((p, index) => (
                            <p key={index}>
                                < PhoneAndroidSharpIcon color='secondary' sx={{ fontSize: "25px", margin: "10px" }} /> {p}
                            </p>
                        ))}
                    </div>
                )}
            </div>
        );
    };


    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/advancedleadgen/collection/${collectionId}/user/653c691c473ac7461e603d59/full-leads`)
            .then((response) => {
                const formattedLeads = response.data.leads.map((lead: any) => ({
                    ...lead,
                    id: lead._id,
                }));
                setLeads(formattedLeads);
            })
            .catch((error) => {
                console.error('Error fetching leads:', error);
            });
    }, [collectionId]);


    const columns = [
        { field: 'full_name', headerName: 'Full Name', width: 200 },
        { field: 'job_title', headerName: 'Job Title', width: 150 },
        { field: 'job_company_name', headerName: 'Company Name', width: 200 },
        { field: 'gender', headerName: 'Gender', width: 100 },
        { field: 'birth_year', headerName: 'Birth Year', width: 120 },
        { field: 'birth_date', headerName: 'Birth Date', width: 150 },
        { field: 'linkedin_url', headerName: 'LinkedIn URL', width: 250 },
        { field: 'linkedin_username', headerName: 'LinkedIn Username', width: 200 },
        { field: 'linkedin_id', headerName: 'LinkedIn ID', width: 150 },
        { field: 'facebook_url', headerName: 'Facebook URL', width: 250 },
        { field: 'facebook_username', headerName: 'Facebook Username', width: 200 },
        { field: 'facebook_id', headerName: 'Facebook ID', width: 150 },
        { field: 'twitter_url', headerName: 'Twitter URL', width: 250 },
        { field: 'twitter_username', headerName: 'Twitter Username', width: 200 },
        { field: 'github_url', headerName: 'GitHub URL', width: 250 },
        { field: 'github_username', headerName: 'GitHub Username', width: 200 },
        { field: 'work_email', headerName: 'Work Email', width: 200 },
        { field: 'mobile_phone', headerName: 'Mobile Phone', width: 150 },
        { field: 'industry', headerName: 'Industry', width: 150 },
        { field: 'job_title_role', headerName: 'Job Title Role', width: 200 },
        { field: 'job_title_sub_role', headerName: 'Job Title Sub-Role', width: 200 },
        { field: 'job_title_levels', headerName: 'Job Title Levels', width: 200 },
        { field: 'job_company_id', headerName: 'Company ID', width: 150 },
        { field: 'job_company_name', headerName: 'Company Name', width: 200 },
        { field: 'job_company_website', headerName: 'Company Website', width: 200 },
        { field: 'job_company_size', headerName: 'Company Size', width: 150 },
        { field: 'job_company_founded', headerName: 'Company Founded', width: 150 },
        { field: 'job_company_industry', headerName: 'Company Industry', width: 200 },
        { field: 'job_company_linkedin_url', headerName: 'Company LinkedIn URL', width: 250 },
        { field: 'job_company_linkedin_id', headerName: 'Company LinkedIn ID', width: 150 },
        { field: 'job_company_facebook_url', headerName: 'Company Facebook URL', width: 250 },
        { field: 'job_company_twitter_url', headerName: 'Company Twitter URL', width: 250 },
        { field: 'job_company_location_name', headerName: 'Company Location', width: 200 },
        { field: 'job_company_location_locality', headerName: 'Company Locality', width: 150 },
        { field: 'job_company_location_metro', headerName: 'Company Metro', width: 150 },
        { field: 'job_company_location_region', headerName: 'Company Region', width: 150 },
        { field: 'job_company_location_geo', headerName: 'Company Geo', width: 150 },
        { field: 'job_company_location_street_address', headerName: 'Company Street Address', width: 200 },
        { field: 'job_company_location_address_line_2', headerName: 'Company Address Line 2', width: 200 },
        { field: 'job_company_location_postal_code', headerName: 'Company Postal Code', width: 150 },
        { field: 'job_company_location_country', headerName: 'Company Country', width: 150 },
        { field: 'job_company_location_continent', headerName: 'Company Continent', width: 150 },
        { field: 'job_last_updated', headerName: 'Last Updated', width: 150 },
        { field: 'job_start_date', headerName: 'Job Start Date', width: 150 },
        { field: 'job_summary', headerName: 'Job Summary', width: 200 },
        { field: 'location_name', headerName: 'Location Name', width: 150 },
        { field: 'location_locality', headerName: 'Location Locality', width: 150 },
        { field: 'location_metro', headerName: 'Location Metro', width: 150 },
        { field: 'location_region', headerName: 'Location Region', width: 150 },
        { field: 'location_country', headerName: 'Location Country', width: 150 },
        { field: 'location_continent', headerName: 'Location Continent', width: 150 },
        { field: 'location_street_address', headerName: 'Location Street Address', width: 200 },
        { field: 'location_address_line_2', headerName: 'Location Address Line 2', width: 200 },
        { field: 'location_postal_code', headerName: 'Location Postal Code', width: 150 },
        { field: 'location_geo', headerName: 'Location Geo', width: 150 },
        { field: 'location_last_updated', headerName: 'Location Last Updated', width: 150 },
        { field: 'linkedin_connections', headerName: 'LinkedIn Connections', width: 180 },
        { field: 'inferred_salary', headerName: 'Inferred Salary', width: 150 },
        { field: 'inferred_years_experience', headerName: 'Inferred Years of Experience', width: 250 },
        { field: 'summary', headerName: 'Summary', width: 200 },

        // Add other columns as needed
    ]

    return (
        <div style={{ height: 600 }}>
            <DataGridPremium
                rows={leads}
                columns={columns}
                getRowHeight={(params) => (expandedRow === params.id.toString() ? 200 : 52)}
                getDetailPanelContent={({ id }) => renderDetails(id.toString())}
                getDetailPanelHeight={() => 100}
                checkboxSelection
                sx={{ border: "0px" }}
            />
        </div>
    );
}

export default CollectionLeads;
