import { CheckCircleSharp, Circle, RadioButtonUncheckedSharp } from '@mui/icons-material';
import {
    Alert,
    Button,
    Card,
    CardContent,
    CardHeader,
    Chip,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    Snackbar,
    TextField,
    ToggleButton,
    ToggleButtonGroup,
    Tooltip,
    Typography,
} from '@mui/material';
import { green, orange } from '@mui/material/colors';
import { useTheme } from '@mui/material/styles';
import axios from 'axios';
import React, { useEffect, useState } from 'react';

interface LeadImportComponentProps {
    selectedLeads: any;
    filters: any; // Add this prop to receive filters from the parent
    leadImportInfo: any;
    setLeadImportInfo: any;
    selectedCampaignId?: any;

}

interface CampaignInfo {
    campaignName: string;
    listId: string;
    listName: string;
}

interface IList {
    id: string;
    name: string;
    campaigns: ICampaign[];
}

interface ICampaign {
    name: string;
    isActive: boolean;
}

interface IListField {
    fieldId: string;
    name: string;
    type: string; // Consider using a union type if there are a fixed set of possible types
    personalization: string;
    isCustom?: boolean; // Marking the property as optional
}

interface IMapping {
    existingFields: Record<string, string>;
    newFields: Record<string, string>;
}

const linkedInFields = [
    'full_name', 'first_name', 'last_name', 'gender', 'birth_year',
    'birth_date', 'linkedin_url', 'industry', 'job_title', 'job_company_name',
    'job_company_website', 'location_name', 'summary'
];

const getLinkedInFieldForType = (fieldType: string): string => {
    switch (fieldType) {
        case "companyname":
            return "job_company_name";
        case "fullname":
            return "full_name";
        case "firstname":
            return "first_name";
        case "lastname":
            return "last_name";
        case "location":
            return "location_name";
        case "jobtitle":
            return "job_title";
        default:
            return ""; // Return an empty string or any placeholder for unmapped types
    }
};

const LeadImportComponent: React.FC<LeadImportComponentProps> = ({ selectedLeads, filters, leadImportInfo, setLeadImportInfo, selectedCampaignId }) => {
    const theme = useTheme();
    const [lists, setLists] = useState<IList[]>([]);
    const [fields, setFields] = useState<IListField[]>([]);
    const [selectedListId, setSelectedListId] = useState('');
    const [mapping, setMapping] = useState<IMapping>({ existingFields: {}, newFields: {} });
    const [linkedinIds, setLinkedinIds] = useState<string[]>([]);
    const [numberOfLeads, setNumberOfLeads] = useState<number>(10); // New state for number of leads to import

    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');

    const [createFieldDialogOpen, setCreateFieldDialogOpen] = useState(false);
    const [tempFieldForCreation, setTempFieldForCreation] = useState('');

    const [campaignInfo, setCampaignInfo] = useState<CampaignInfo | null>(null);
    const [isLoadingCampaignInfo, setIsLoadingCampaignInfo] = useState(false); // Add loading state

    const [importType, setImportType] = useState('oneClick'); // New state for import type



    useEffect(() => {
        const fetchCampaignInfo = async () => {
            if (selectedCampaignId) {
                setIsLoadingCampaignInfo(true); // Set loading to true before fetching
                try {
                    const response = await axios.get<CampaignInfo>(`${process.env.REACT_APP_API_URL}/campaign/${selectedCampaignId}/basicInfo`);
                    setCampaignInfo(response.data);
                    setSelectedListId(response.data.listId);
                } catch (error) {
                    console.error('Error fetching campaign info:', error);
                } finally {
                    setIsLoadingCampaignInfo(false); // Set loading to false after fetching
                }
            }
        };

        fetchCampaignInfo();
    }, [selectedCampaignId]);

    useEffect(() => {
        setLinkedinIds(selectedLeads);
    }, [selectedLeads]);


    useEffect(() => {
        const fetchLists = async () => {
            try {
                const response = await axios.get<{ lists: IList[] }>(`${process.env.REACT_APP_API_URL}/advancedleadgen/user/lists`);
                setLists(response.data.lists);
            } catch (error: any) {
                setSnackbarMessage(error.response?.data?.message || 'Error fetching lists');
                setSnackbarSeverity('error');
                setSnackbarOpen(true);
                console.error('Error fetching lists:', error);
            }
        };

        fetchLists();
    }, []);

    useEffect(() => {
        const fetchFields = async () => {
            if (selectedListId) {
                try {
                    const response = await axios.get<{ fields: IListField[] }>(`${process.env.REACT_APP_API_URL}/advancedleadgen/list/${selectedListId}/fields`);
                    const initialMapping = response.data.fields.reduce((acc, field) => {
                        const linkedInField = getLinkedInFieldForType(field.type);
                        if (linkedInField) {
                            acc.existingFields[field.fieldId] = linkedInField;
                        }
                        return acc;
                    }, { existingFields: {}, newFields: {} });
                    setFields(response.data.fields);
                    setMapping(initialMapping);
                } catch (error: any) {
                    setSnackbarMessage(error.response?.data?.message || 'Error fetching Fields');
                    setSnackbarSeverity('error');
                    setSnackbarOpen(true);
                    console.error('Error fetching fields for list:', error);
                }
            }
        };

        fetchFields();
    }, [selectedListId]);

    const getUnusedLinkedInFields = () => {
        const usedFields = new Set([...Object.values(mapping.existingFields), ...Object.keys(mapping.newFields)]);
        return linkedInFields.filter(field => !usedFields.has(field));
    };

    const handleListChange = (event) => {
        setSelectedListId(event.target.value);
    };

    const handleFieldMappingChange = (listFieldId: string, linkedInField: string) => {
        if (linkedInField === 'Create Field') {
            const unusedFields = getUnusedLinkedInFields();
            if (unusedFields.length > 0) {
                setTempFieldForCreation(unusedFields[0]);
                setCreateFieldDialogOpen(true);
            }
        } else {
            setMapping(prevMapping => ({
                ...prevMapping,
                existingFields: {
                    ...prevMapping.existingFields,
                    [listFieldId]: linkedInField,
                },
            }));
        }
    };

    const submitMapping = async () => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/advancedleadgen/addLinkedInLeadsToList`, {
                linkedInIds: linkedinIds,
                listId: selectedListId,
                mapping,
            });
            setSnackbarMessage(response.data.message || 'Mapping submitted successfully');
            setSnackbarSeverity('success');
            setSnackbarOpen(true);
        } catch (error: any) {
            let errorMessage = 'Error submitting mapping';
            if (error.response && error.response.data && error.response.data.message) {
                errorMessage = error.response.data.message;
            } else if (error.message) {
                errorMessage = error.message;
            }
            setSnackbarMessage(errorMessage);
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
        }
    };

    const submitOneClickImport = async () => {
        // Prepare the filters in the expected format
        const preparedFilters = {
            ...(filters.selectedJobTitle && { job_title: filters.selectedJobTitle.join(';'), exact_job_title: filters.exactJobTitle }),
            ...(filters.excludedJobTitles && { exclude_job_title: filters.excludedJobTitles.join(';') }),
            ...(filters.selectedIndustry && { industry: filters.selectedIndustry.join(';') }),
            ...(filters.selectedCountries && { include_country: filters.selectedCountries.map(c => c.name).join(';') }),
            ...(filters.selectedContinent && { include_continent: filters.selectedContinent.join(';') }),
            ...(filters.selectedRegion && { include_region: filters.selectedRegion.join(';') }),
            ...(filters.excludedRegion && { exclude_region: filters.excludedRegion.join(';') }),
            ...(filters.selectedGender && { gender: filters.selectedGender }),
            ...(filters.hobbies && { hobbies: filters.hobbies.join(';') }),
            ...(filters.skills && { skills: filters.skills.join(';') }),
            ...(filters.foundedBefore && { founded_before: filters.foundedBefore }),
            ...(filters.foundedAfter && { founded_after: filters.foundedAfter }),
            ...(filters.hasPhone && { has_phone: 'true' }),
            ...(filters.hasPersonalEmail && { has_personal_email: 'true' }),
            ...(filters.hasProfessionalEmail && { has_professional_email: 'true' }),
        };


        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/advancedleadgen/one-click-lead-import`, {
                filters: preparedFilters,
                listId: selectedListId,
                numberOfLeads,
                mapping,
            });
            setSnackbarMessage(response.data.message || 'Leads imported successfully');
            setSnackbarSeverity('success');
            setSnackbarOpen(true);
        } catch (error: any) {
            let errorMessage = 'Error importing leads';
            if (error.response && error.response.data && error.response.data.message) {
                errorMessage = error.response.data.message;
            } else if (error.message) {
                errorMessage = error.message;
            }
            setSnackbarMessage(errorMessage);
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
        }
    };

    const resetFieldMapping = (fieldId: string) => {
        const updatedExistingFields = { ...mapping.existingFields };
        const updatedNewFields = { ...mapping.newFields };

        if (updatedExistingFields[fieldId]) {
            delete updatedExistingFields[fieldId];
        }
        if (updatedNewFields[fieldId]) {
            delete updatedNewFields[fieldId];
        }

        setMapping({
            existingFields: updatedExistingFields,
            newFields: updatedNewFields,
        });
    };

    const handleAddNewField = (fieldName: string) => {
        if (!Object.values(mapping.existingFields).includes(fieldName) && !Object.values(mapping.newFields).includes(fieldName)) {
            const newFieldMapping = {
                ...mapping,
                newFields: {
                    ...mapping.newFields,
                    [fieldName]: fieldName,
                },
            };
            setMapping(newFieldMapping);
        }

        const newFieldsList = [...fields, { fieldId: fieldName, name: fieldName, type: "custom", personalization: "", isCustom: true }];
        setFields(newFieldsList);

        setCreateFieldDialogOpen(false);
        setTempFieldForCreation('');
    };

    const deleteCustomField = (fieldId: string) => {
        const updatedFields = fields.filter(field => field.fieldId !== fieldId);
        setFields(updatedFields);

        const { [fieldId]: _, ...updatedExistingFields } = mapping.existingFields;
        const { [fieldId]: __, ...updatedNewFields } = mapping.newFields;
        setMapping({
            existingFields: updatedExistingFields,
            newFields: updatedNewFields,
        });
    };

    const handleToggleChange = (event, newImportType) => {
        if (newImportType !== null) {
            setImportType(newImportType);
        }
    };

    return (
        <div>
            {/* <ToggleButtonGroup
                value={importType}
                exclusive
                onChange={handleToggleChange}
                fullWidth
                style={{ padding: 0 }}
            >
                <ToggleButton value="oneClick" style={{ width: '50%', padding: 0, borderRadius: 0 }}>
                    <Typography variant="h6">One Click Import</Typography>
                </ToggleButton>
                <ToggleButton value="selectedLeads" style={{ width: '50%', padding: 0, borderRadius: 0 }}>
                    <Typography variant="h6">Only Selected Leads</Typography>
                </ToggleButton>
            </ToggleButtonGroup> */}

            {/* {importType === 'selectedLeads' && (
                <>
                    <Paper elevation={2} style={{ padding: '16px', marginBottom: '24px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Typography variant="h6">Leads Selected</Typography>
                        <Typography variant="h6" style={{ marginLeft: 'auto' }}>{linkedinIds.length} Leads</Typography>
                    </Paper>
                </>
            )} */}

            {/* {importType === 'oneClick' && (
                <>
                    <Card elevation={3} style={{ marginBottom: '24px', borderRadius: '16px' }}>
                        <CardHeader
                            title="Import Leads"
                            titleTypographyProps={{ variant: 'h5', align: 'center' }}
                        />
                        <CardContent>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={4}>
                                    <Tooltip title="Remaining credits you can use to import leads" arrow>
                                        <Card elevation={1} style={{ padding: '16px', textAlign: 'center', borderRadius: '8px' }}>
                                            <Typography variant="h6" color="textSecondary">
                                                Remaining Lead Credits
                                            </Typography>
                                            <Typography variant="h4" style={{ color: green[500] }}>
                                                {leadImportInfo.remainingLeadCredits}
                                            </Typography>
                                        </Card>
                                    </Tooltip>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <Tooltip title="Total leads matching the current filter" arrow>
                                        <Card elevation={1} style={{ padding: '16px', textAlign: 'center', borderRadius: '8px' }}>
                                            <Typography variant="h6" color="textSecondary">
                                                Total Leads Matching Filter
                                            </Typography>
                                            <Typography variant="h4" style={{ color: orange[500] }}>
                                                {leadImportInfo.totalLeads}
                                            </Typography>
                                        </Card>
                                    </Tooltip>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <Tooltip title="Leads matching the current filter that you have already revealed" arrow>
                                        <Card elevation={1} style={{ padding: '16px', textAlign: 'center', borderRadius: '8px' }}>
                                            <Typography variant="h6" color="textSecondary">
                                                Leads You Already Revealed
                                            </Typography>
                                            <Typography variant="h4" style={{ color: theme.palette.info.main }}>
                                                {leadImportInfo.revealedLeadsCount}
                                            </Typography>
                                        </Card>
                                    </Tooltip>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </>
            )} */}

            {!selectedCampaignId && (
                <FormControl fullWidth>
                    <InputLabel id="select-list-label">Select List</InputLabel>
                    <Select
                        labelId="select-list-label"
                        id="select-list"
                        value={selectedListId}
                        label="Select List"
                        onChange={handleListChange}
                        MenuProps={{
                            style: { zIndex: 999999999999999 }
                        }}
                    >
                        {lists.map((list) => (
                            <MenuItem key={list.id} value={list.id} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <span>{list.name}</span>
                                <div>
                                    {list.campaigns.map((campaign, index) => (
                                        <Chip
                                            key={index}
                                            label={campaign.name}
                                            icon={
                                                <Circle
                                                    style={{ color: campaign.isActive ? green[500] : orange[500] }}
                                                />
                                            }
                                            variant="outlined"
                                            size="small"
                                            style={{ marginLeft: 4 }}
                                        />
                                    ))}
                                </div>
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            )}

            {/* Display campaign info if selectedCampaignId is provided */}

            {/* Conditionally render based on loading and data */}
            {selectedCampaignId && (
                <>
                    {isLoadingCampaignInfo && (
                        <Paper elevation={2} style={{ padding: '16px', marginBottom: '24px', display: 'flex', alignItems: 'center' }}>
                            <CircularProgress size={24} style={{ marginRight: '8px' }} />
                            <Typography variant="body1">Loading campaign information...</Typography>
                        </Paper>
                    )}

                    {!isLoadingCampaignInfo && campaignInfo && (
                        <Paper elevation={2} style={{ padding: '16px', marginBottom: '24px', display: 'flex', flexWrap: 'wrap', alignItems: 'center' }}>
                            <Typography variant="h6" style={{ marginRight: '16px' }}>Importing Leads to:</Typography>
                            <div style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center' }}>
                                <Typography variant="body1" style={{ marginRight: '8px' }}>Campaign:</Typography>
                                <Chip label={campaignInfo.campaignName} color="primary" variant="outlined" style={{ marginRight: '8px', marginBottom: '8px' }} />
                                <Typography variant="body1" style={{ marginRight: '8px' }}>List:</Typography>
                                <Chip label={campaignInfo.listName} color="secondary" variant="outlined" style={{ marginBottom: '8px' }} />
                            </div>
                        </Paper>
                    )}

                    {!isLoadingCampaignInfo && !campaignInfo && (
                        <Alert severity="error">Campaign not found.</Alert>
                    )}
                </>
            )}
            {importType === 'oneClick' && (
                <TextField
                    label="Number of Leads to Import"
                    type="number"
                    fullWidth
                    value={numberOfLeads}
                    onChange={(e) => setNumberOfLeads(parseInt(e.target.value, 10))}
                    style={{ marginTop: 16 }}
                />
            )}


            {fields.map((field) => (
                <div key={field.fieldId} style={{ display: 'flex', alignItems: 'center', margin: '8px 0' }}>
                    <FormControl fullWidth style={{ flex: 1 }}>
                        <InputLabel>{field.name}</InputLabel>
                        <Select
                            value={mapping.existingFields[field.fieldId] || mapping.newFields[field.fieldId] || ''}
                            onChange={(e) => handleFieldMappingChange(field.fieldId, e.target.value)}
                            displayEmpty
                            disabled={field.isCustom === true}
                            MenuProps={{
                                style: { zIndex: 999999999999999 }
                            }}
                        >
                            {getUnusedLinkedInFields().map(unusedField => (
                                <MenuItem key={unusedField} value={unusedField}>{unusedField}</MenuItem>
                            ))}
                            {mapping.existingFields[field.fieldId] && (
                                <MenuItem key={mapping.existingFields[field.fieldId]} value={mapping.existingFields[field.fieldId]}>
                                    {mapping.existingFields[field.fieldId]}
                                </MenuItem>
                            )}
                            {mapping.newFields[field.fieldId] && (
                                <MenuItem key={mapping.newFields[field.fieldId]} value={mapping.newFields[field.fieldId]}>
                                    {mapping.newFields[field.fieldId]}
                                </MenuItem>
                            )}
                        </Select>
                    </FormControl>

                    {!field.isCustom && (
                        <Button
                            style={{ marginLeft: 8 }}
                            variant="outlined"
                            size="small"
                            onClick={() => resetFieldMapping(field.fieldId)}
                        >
                            Reset
                        </Button>
                    )}

                    {field.isCustom && (
                        <Button
                            style={{ marginLeft: 8 }}
                            variant="outlined"
                            size="small"
                            color="error"
                            onClick={() => deleteCustomField(field.fieldId)}
                        >
                            Delete
                        </Button>
                    )}
                    {mapping.existingFields[field.fieldId] || mapping.newFields[field.fieldId] ? (
                        <CheckCircleSharp style={{ color: theme.palette.success.main, marginLeft: 8 }} />
                    ) : (
                        <RadioButtonUncheckedSharp style={{ color: theme.palette.secondary.main, marginLeft: 8 }} />
                    )}
                </div>
            ))}

            <Button
                fullWidth
                variant="contained"
                color="primary"
                onClick={() => setCreateFieldDialogOpen(true)}
                style={{ marginTop: 16 }}
            >
                Create New Field
            </Button>

            {importType === 'selectedLeads' && (
                <Button
                    fullWidth
                    variant="contained"
                    color='secondary'
                    onClick={submitMapping}
                    style={{ marginTop: 16 }}
                >
                    Import Selected Leads
                </Button>
            )}

            {importType === 'oneClick' && (
                <Button
                    fullWidth
                    variant="contained"
                    color="secondary"
                    onClick={submitOneClickImport}
                    style={{ marginTop: 16 }}
                >
                    One-Click Import
                </Button>
            )}

            <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={() => setSnackbarOpen(false)}>
                <Alert
                    onClose={() => setSnackbarOpen(false)}
                    //@ts-ignore
                    severity={snackbarSeverity}
                    sx={{ width: '100%' }}
                >
                    {snackbarMessage}
                </Alert>
            </Snackbar>
            <Dialog sx={{ zIndex: 999999999999999 }} open={createFieldDialogOpen} onClose={() => setCreateFieldDialogOpen(false)}>
                <DialogTitle>Create New Field</DialogTitle>
                <DialogContent>
                    <FormControl fullWidth>
                        <InputLabel>Field</InputLabel>
                        <Select
                            value={tempFieldForCreation}
                            onChange={(e) => setTempFieldForCreation(e.target.value)}
                            label="Field"
                            MenuProps={{
                                style: { zIndex: 999999999999999 }
                            }}
                        >
                            {getUnusedLinkedInFields().concat(Object.keys(mapping.newFields)).map(field => (
                                <MenuItem key={field} value={field}>{field}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setCreateFieldDialogOpen(false)}>Cancel</Button>
                    <Button onClick={() => {
                        if (tempFieldForCreation && getUnusedLinkedInFields().includes(tempFieldForCreation)) {
                            handleAddNewField(tempFieldForCreation);
                            setCreateFieldDialogOpen(false);
                        }
                    }}>Add</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default LeadImportComponent;
