import {
    AddSharp,
    CircleSharp,
    CloseSharp,
    DeleteSharp,
    EditSharp,
    ExpandMoreSharp,
    ReplayCircleFilledSharp,
    SaveSharp,
} from '@mui/icons-material';
import CancelSharp from '@mui/icons-material/CancelSharp';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import EmailSharpIcon from '@mui/icons-material/EmailSharp';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import HelpSharpIcon from '@mui/icons-material/HelpSharp';
import PhoneIcon from '@mui/icons-material/Phone';
import ThumbDownAltSharpIcon from '@mui/icons-material/ThumbDownAltSharp';
import ThumbUpAltSharpIcon from '@mui/icons-material/ThumbUpAltSharp';
import ViewListSharpIcon from '@mui/icons-material/ViewListSharp';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Alert,
    alpha,
    Badge,
    Box,
    Button,
    Chip,
    darken,
    Divider,
    Grid,
    InputBase,
    InputBaseProps,
    lighten,
    Paper,
    Popper,
    Rating,
    Skeleton,
    Snackbar,
    TextField,
    ToggleButton,
    ToggleButtonGroup,
    Typography,
    useTheme,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import {
    DataGridPremium,
    GridActionsCellItem,
    gridClasses,
    GridColDef,
    GridColTypeDef,
    GridRenderEditCellParams,
    GridRowModes,
    useGridApiContext,
    useGridApiRef,
} from '@mui/x-data-grid-premium';
import axios from 'axios';
import * as React from 'react';
import { Suspense, useEffect, useState } from 'react';

import CustomTooltip from '../../modules/CustomMaterialUiComponents/CustomTooltip';
import { ITag, Lead, TableData } from './CRM-Table-Interfaces';
import CustomToolbar from './CustomToolbar';
import { EmailPhoneMenu } from './Email&PhoneMenus';
import { TagMenu } from './LeadTagsMenu';


// src\app\pages\3-CRM\TableRender.tsx
const Flag = React.lazy(() => import('react-world-flags'));

// Interfaces to model the data structures for emails and phones


interface TableComponentProps {
    data: TableData;
    headerMappings: { [key: string]: string };
    visibility: boolean[];
    selectedListId: string | null;
    refreshData: () => void;
    selectedLeadIds: any; // Consider specifying a more precise type
    setSelectedLeadIds: (value: any) => void; // Consider specifying a more precise type
    togglePanelVisibility: () => void;  // Added prop for toggling panel visibility
    uploadSingleLead: () => void;
    uploadFileList: () => void;

}



const StyledGridOverlay = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    '& .ant-empty-img-1': {
        fill: theme.palette.mode === 'light' ? '#aeb8c2' : '#262626',
    },
    '& .ant-empty-img-2': {
        fill: theme.palette.mode === 'light' ? '#f5f5f7' : '#595959',
    },
    '& .ant-empty-img-3': {
        fill: theme.palette.mode === 'light' ? '#dce0e6' : '#434343',
    },
    '& .ant-empty-img-4': {
        fill: theme.palette.mode === 'light' ? '#fff' : '#1c1c1c',
    },
    '& .ant-empty-img-5': {
        fillOpacity: theme.palette.mode === 'light' ? '0.8' : '0.08',
        fill: theme.palette.mode === 'light' ? '#f5f5f5' : '#fff',
    },
}));

function CustomNoRowsOverlay() {
    return (
        <StyledGridOverlay>
            <svg
                style={{ flexShrink: 0 }}
                width="240"
                height="200"
                viewBox="0 0 184 152"
                aria-hidden
                focusable="false"
            >
                <g fill="none" fillRule="evenodd">
                    <g transform="translate(24 31.67)">
                        <ellipse
                            className="ant-empty-img-5"
                            cx="67.797"
                            cy="106.89"
                            rx="67.797"
                            ry="12.668"
                        />
                        <path
                            className="ant-empty-img-1"
                            d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
                        />
                        <path
                            className="ant-empty-img-2"
                            d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
                        />
                        <path
                            className="ant-empty-img-3"
                            d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
                        />
                    </g>
                    <path
                        className="ant-empty-img-3"
                        d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
                    />
                    <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
                        <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
                        <path d="M5.698 5.63H0L2.898 .704zM9.259 .704h4.985V5.63H9.259z" />
                    </g>
                </g>
            </svg>
            <Box sx={{ mt: 1 }}>No Rows</Box>
        </StyledGridOverlay>
    );
}


function CustomNoRowsOverlay2() {
    return (
        <Grid sx={{ display: "flex", justifyContent: "center", alignItems: "center" }} >
            <ViewListSharpIcon color='info' style={{ fontSize: "155px" }} />
        </Grid>
    );
}





const TableComponent: React.FC<TableComponentProps> = ({
    data,
    headerMappings,
    visibility,
    selectedListId,
    refreshData,
    selectedLeadIds,
    setSelectedLeadIds,
    togglePanelVisibility,
    uploadSingleLead,
    uploadFileList,
}) => {

    const theme = useTheme();

    const [filters, setFilters] = React.useState({});

    const [columns, setColumns] = useState<GridColDef[]>([]);


    const [openDetailPanelRowIds, setOpenDetailPanelRowIds] = React.useState([]);
    const [isFixingCompanyName, setIsFixingCompanyName] = React.useState(false);
    const [isValidatingEmails, setIsValidatingEmails] = React.useState(false);
    const [isReoonValidatingEmails, setIsReoonValidatingEmails] = React.useState(false);
    const [isClassifyingEmails, setIsClassifyingEmails] = React.useState(false);
    const [rowModesModel, setRowModesModel] = React.useState({});

    const [columnWidths, setColumnWidths] = useState(() => {
        const savedWidths = localStorage.getItem('columnWidths');
        return savedWidths ? JSON.parse(savedWidths) : {};
    });

    const [columnVisibilityModel, setColumnVisibilityModel] = useState(() => {
        const visibility = {};
        columns.forEach(column => {
            visibility[column.field] = true; // Initialize all columns as visible
        });
        return visibility;
    });

    const gridApiRef = useGridApiRef();



    const [emailFilter, setEmailFilter] = useState({
        hasAny: false,
        doesntHaveAny: false,
        textSearch: [] // Initialize as an empty array
    });
    const [phoneFilter, setPhoneFilter] = useState({
        hasAny: false,
        doesntHaveAny: false,
    });
    const [emailVerificationFilter, setEmailVerificationFilter] = useState('all');

    const [countries, setCountries] = useState<string[]>([]);
    const [selectedCountries, setSelectedCountries] = useState<string[]>([]);

    const [tagMenuAnchorEl, setTagMenuAnchorEl] = useState(null);
    const [selectedLeadId, setSelectedLeadId] = useState(null);

    const [gridDensity, setGridDensity] = useState(() => {
        return localStorage.getItem('gridDensity') || 'compact'; // Default to 'compact' if not found
    });



    const handleExtraCondensedView = () => setGridDensity('extraCompact');
    const handleCondensedView = () => setGridDensity('compact');
    const handleRelaxedView = () => setGridDensity('comfortable');
    const handleExtraRelaxedView = () => setGridDensity('extraComfortable');


    useEffect(() => {
        const initialVisibility = columns.reduce((acc, column) => ({
            ...acc,
            //@ts-ignore
            [column.field]: column.hide ? false : true
        }), {});
        setColumnVisibilityModel(initialVisibility);
    }, [columns]);

    // Update visibility model in the grid when it changes
    useEffect(() => {
        gridApiRef.current.setColumnVisibilityModel(columnVisibilityModel);
    }, [columnVisibilityModel, gridApiRef]);

    // Detect external changes and update local model
    useEffect(() => {
        //@ts-ignore
        return gridApiRef.current.subscribeEvent('columnVisibilityChange', (params) => {
            setColumnVisibilityModel(prev => ({
                ...prev,
                //@ts-ignore
                [params.field]: params.isVisible
            }));
        });
    }, [gridApiRef]);


    useEffect(() => {
        localStorage.setItem('gridDensity', gridDensity);
    }, [gridDensity]);

    const handleColumnWidthChange = React.useCallback((params) => {
        console.log('Column width change params:', params);
        const fieldName = params.colDef.field; // Extract field name from colDef
        const newWidths = { ...columnWidths, [fieldName]: params.width };
        setColumnWidths(newWidths);
        localStorage.setItem('columnWidths', JSON.stringify(newWidths));
        console.log("New column widths:", newWidths);
    }, [columnWidths, setColumnWidths]);


    const extractCountries = (leads) => {
        const countrySet = new Set();
        leads.forEach(lead => {
            lead.Phones.forEach(phone => {
                if (phone.country) {
                    countrySet.add(phone.country);
                }
            });
        });
        return Array.from(countrySet);
    };

    const handleCountryChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        const value = event.target.value;
        setSelectedCountries(
            typeof value === 'string' ? value.split(',') : value as string[]
        );
    };


    useEffect(() => {
        const uniqueCountries = extractCountries(data.leads);
        //@ts-ignore
        setCountries(uniqueCountries);
    }, [data.leads]);


    const handleRowDoubleClick = (params: { id: any; }, event: { target: { closest: (arg0: string) => any; }; }) => {
        // Check if the double-click event is on an editable cell. This example checks for a specific class but adapt as needed.
        const isEditableCellClick = event.target.closest('.MuiDataGrid-cell--editable');
        if (isEditableCellClick) {
            // Prevent expanding the detail panel if clicked inside an editable cell
            return;
        }

        // Your existing logic to toggle detail panel
        //@ts-ignore
        const newOpenDetailPanelRowIds = openDetailPanelRowIds.includes(params.id)
            ? openDetailPanelRowIds.filter((id) => id !== params.id)
            : [...openDetailPanelRowIds, params.id];
        //@ts-ignore
        setOpenDetailPanelRowIds(newOpenDetailPanelRowIds);
    };

    const toggleDetailPanel = (rowId: any) => {
        // Check if the rowId is already in the array
        //@ts-ignore
        const isRowCurrentlyOpen = openDetailPanelRowIds.includes(rowId);
        const newOpenDetailPanelRowIds = isRowCurrentlyOpen
            ? openDetailPanelRowIds.filter(id => id !== rowId) // Remove rowId if it's already open
            : [...openDetailPanelRowIds, rowId]; // Add rowId if it's not open
        //@ts-ignore

        setOpenDetailPanelRowIds(newOpenDetailPanelRowIds);
    };

    // Function to handle menu close
    const handleMenuClose = (columnName) => {
        // Find the index of the column to update
        const columnIndex = columns.findIndex((col) => col.field === columnName);

        // Update the column definition to remove the menu anchor element
        if (columnIndex !== -1) {
            //@ts-ignore
            columns[columnIndex] = { ...columns[columnIndex], menuAnchorEl: null };
        }
    };


    // Function to handle email filter changes
    const handleEmailFilterChange = (filterType, value, columnName) => {
        if (filterType === 'all') {
            setEmailFilter({
                hasAny: false,
                doesntHaveAny: false,
                textSearch: [],
            });
        } else if (filterType === 'hasAny' || filterType === 'doesntHaveAny') {
            setEmailFilter(prev => ({
                ...prev,
                [filterType]: value,
            }));
        } else {
            setEmailFilter(prev => ({
                ...prev,
                [filterType]: value
            }));
        }
        handleMenuClose(columnName); // Close the menu after applying the filter
    };


    // Function to handle email verification filter changes
    const handleEmailVerificationFilterChange = (filterType, columnName) => {
        setEmailVerificationFilter(filterType);
        handleMenuClose(columnName); // Close the menu after applying the filter
    };

    // Function to handle phone filter changes
    const handlePhoneFilterChange = (filterType, columnName) => {
        if (filterType === 'all') {
            setPhoneFilter({ hasAny: false, doesntHaveAny: false });
        } else if (filterType === 'hasAny') {
            setPhoneFilter({ hasAny: true, doesntHaveAny: false });
        } else if (filterType === 'doesntHaveAny') {
            setPhoneFilter({ hasAny: false, doesntHaveAny: true });
        }
        handleMenuClose(columnName); // Close the menu after applying the filter
    };


    const isFilterActive = (columnField) => {
        let active = false;
        if (columnField === 'Emails') {
            active = emailFilter.hasAny || emailFilter.doesntHaveAny || emailVerificationFilter !== 'all' || emailFilter.textSearch.length > 0;
        } else if (columnField === 'Phones') {
            active = phoneFilter.hasAny || phoneFilter.doesntHaveAny || selectedCountries.length > 0;
        }
        console.log(`${columnField} Filter Active: ${active}`);
        return active;
    };

    const renderTags = (tags: ITag[]) => {
        return (
            <Box sx={{ display: 'flex', gap: 1 }}>
                {tags.map((tag, index) => (
                    <Chip
                        key={index}
                        label={tag.name}
                        style={{ backgroundColor: tag.color }}
                    />
                ))}
            </Box>
        );
    };



    // Generate columns dynamically from data
    const generateColumns = (): GridColDef[] => {

        // Initialize baseColumns array to store the column definitions
        let baseColumns = data.listDetails.headers.map(header => {
            console.log('Generating column for:', header.name);  // Debug statement

            const columnWidth = columnWidths[header.name] || 150; // Default to 150 if not specified

            // Special handling for 'Emails' and 'Phones'
            // Email Column Configuration
            if (header.type === 'email') {
                return {
                    field: header.name,
                    headerName: headerMappings[header.name] || header.name,
                    width: columnWidth,
                    filterable: false,
                    disableColumnGrouping: true,
                    disableColumnMenu: true,
                    disableColumnPinning: true,
                    sortable: false,
                    renderHeader: (params) => (
                        <EmailPhoneMenu
                            columnField={header.name}
                            handleMenuClose={handleMenuClose}
                            handleEmailFilterChange={handleEmailFilterChange}
                            handlePhoneFilterChange={handlePhoneFilterChange}
                            handleEmailVerificationFilterChange={handleEmailVerificationFilterChange}
                            emailFilter={emailFilter}
                            phoneFilter={phoneFilter}
                            emailVerificationFilter={emailVerificationFilter}
                            countries={countries}
                            selectedCountries={selectedCountries}
                            handleCountryChange={handleCountryChange}
                            showFilterIcon={isFilterActive(header.name)}
                        />
                    ),
                    renderCell: (params) => {
                        const total = params.value.length;
                        const validCount = params.value.filter(email => email.validationStatus === 'valid').length;
                        const invalidCount = params.value.filter(email => email.validationStatus === 'invalid').length;
                        const unknownCount = params.value.filter(email => email.validationStatus === 'unknown').length;
                        const catchallCount = params.value.filter(email => email.validationStatus === 'catchall').length;
                        const failedCount = params.value.filter(email => email.validationStatus === 'failed').length;

                        return (
                            <>
                                {total > 0 && (
                                    <Badge badgeContent={total} color="primary" sx={{ mr: 1 }} />
                                )}
                                {validCount > 0 && (
                                    <CustomTooltip title="Valid">
                                        <CheckCircleIcon color="success" sx={{ ml: 0.5 }} />

                                    </CustomTooltip>

                                )}
                                {invalidCount > 0 && (
                                    <CustomTooltip title="Invalid">

                                        <CancelSharp color="error" sx={{ ml: 0.5 }} />
                                    </CustomTooltip>

                                )}
                                {unknownCount > 0 && (
                                    <CustomTooltip title="Unknown">

                                        <HelpSharpIcon color="warning" sx={{ ml: 0.5 }} />
                                    </CustomTooltip>

                                )}
                                {catchallCount > 0 && (
                                    <CustomTooltip title="Catch All">

                                        <CircleSharp sx={{ color: "#54e19e", ml: 0.5 }} />
                                    </CustomTooltip>

                                )}
                                {failedCount > 0 && (
                                    <CustomTooltip title="Validation Failed">

                                        <ReplayCircleFilledSharp color="error" sx={{ ml: 0.5 }} />
                                    </CustomTooltip>

                                )}
                            </>
                        );
                    },
                };
            } else if (header.type === 'phone') {
                return {
                    field: header.name,
                    headerName: headerMappings[header.name] || header.name,
                    width: columnWidth,
                    filterable: false,
                    disableColumnGrouping: true,
                    disableColumnMenu: true,
                    disableColumnPinning: true,
                    sortable: false,
                    renderHeader: (params) => (
                        <EmailPhoneMenu
                            columnField={header.name}
                            handleMenuClose={handleMenuClose}
                            handleEmailFilterChange={handleEmailFilterChange}
                            handlePhoneFilterChange={handlePhoneFilterChange}
                            handleEmailVerificationFilterChange={handleEmailVerificationFilterChange}
                            emailFilter={emailFilter}
                            phoneFilter={phoneFilter}
                            emailVerificationFilter={emailVerificationFilter}
                            countries={countries}
                            selectedCountries={selectedCountries}
                            handleCountryChange={handleCountryChange}
                            showFilterIcon={isFilterActive(header.name)}
                        />
                    ),
                    renderCell: (params) => {
                        const total = params.value.length;
                        const validCount = params.value.filter(phone => phone.validationStatus === 'valid').length;
                        const invalidCount = params.value.filter(phone => phone.validationStatus === 'invalid').length;
                        const unknownCount = params.value.filter(phone => phone.validationStatus === 'unknown').length;
                        return (
                            <>
                                {total > 0 && (
                                    <Badge badgeContent={total} color="primary" sx={{ mr: 1 }} />
                                )}
                                {validCount > 0 && (
                                    <CheckCircleIcon color="success" sx={{ ml: 0.5 }} />
                                )}
                                {invalidCount > 0 && (
                                    <CancelSharp color="error" sx={{ ml: 0.5 }} />
                                )}
                                {unknownCount > 0 && (
                                    <HelpSharpIcon color="warning" sx={{ ml: 0.5 }} />
                                )}
                            </>
                        );

                    },
                };
            } else if (header.name === 'rating') {
                // Specific handling for 'rating' if needed
                return {
                    field: header.name,
                    headerName: headerMappings[header.name] || header.name,
                    width: columnWidth, // Use saved width or default
                    renderCell: (params: { value: string; }) => (
                        <Rating value={params.value ? parseFloat(params.value) : 0} precision={0.1} readOnly />
                    ),
                    // 'rating' is not editable, rendered as is
                };
            } else if (header.name === 'timezone') {
                return {
                    field: 'timezone',
                    headerName: 'Timezone',
                    width: columnWidth, // Use saved width or default
                    renderCell: (params) => {
                        // Check if the timezone value exists and is not an empty string
                        const hasTimezone = params.value && params.value.trim() !== "";
                        return hasTimezone ? (
                            <CheckCircleIcon style={{ color: 'green' }} />
                        ) : (
                            <CancelSharp style={{ color: 'red' }} />
                        );
                    }

                };
            } else if (header.type === 'icebreaker') {
                // Specific handling for 'rating' if needed
                return {
                    field: header.name,
                    headerName: headerMappings[header.name] || header.name,
                    width: columnWidth,
                    editable: true,
                    ...multilineColumn,
                };
            } else {

                // General case for other fields
                return {
                    field: header.name,

                    headerName: headerMappings[header.name] || header.name.replace(/_/g, ' ').replace(/^\w/, c => c.toUpperCase()),
                    width: columnWidth, // Use saved width or default
                    disableColumnGrouping: true,  // Disables grouping for this column
                    disableColumnMenu: false,  // Disables the column menu, often used for pinning and other column options
                    disableColumnPinning: true,  // Disables the ability to pin the column
                    editable: true,

                    filterable: true,

                    // Making fields editable by default, adjust as necessary
                };
            }
        });



        // Actions column for edit and delete functionality
        baseColumns.push({
            field: 'actions',
            headerName: 'Actions',
            width: columnWidths['actions'] || 150, // Use saved width for 'actions' column or default
            //@ts-ignore
            type: 'actions',
            //@ts-ignore
            getActions: (params: { id: any; row: any; }) => {
                const isInEditMode = rowModesModel[params.id]?.mode === 'edit';
                return isInEditMode ? [
                    <GridActionsCellItem
                        icon={<SaveSharp sx={{ fontSize: "25px" }} color='info' />}
                        label="Save"
                        onClick={() => handleSave(params.id, params.row)}
                    />,
                    <GridActionsCellItem
                        icon={<CloseSharp sx={{ fontSize: "25px" }} color='info' />}
                        label="Cancel"
                        onClick={() => handleCancelEdit(params.id)}
                    />,
                ] : [
                    // <GridActionsCellItem
                    //     icon={<EditSharp sx={{ fontSize: "25px" }} color='info' />}
                    //     label="Edit"
                    //     onClick={() => handleEdit(params.id)}
                    // />,

                    <GridActionsCellItem
                        icon={<AddSharp />}
                        label="Add Tag"
                        onClick={(event) => handleOpenTagMenu(params.id, event)}
                    />,
                    <GridActionsCellItem
                        icon={<DeleteSharp sx={{ fontSize: "25px" }} color='info' />}
                        label="Delete"
                        onClick={() => handleDelete(params.id)}
                    />,
                ];
            },
        },
            {
                field: 'tags',
                headerName: 'Tags',
                width: 200,
                //@ts-ignore
                renderCell: (params) => renderTags(params.value || []),
            }
        );

        // Assuming detail panel toggle is still needed and should be included
        const detailPanelToggleColumn = {
            field: '__detail_panel_toggle__',
            headerName: '',
            width: 0,
            renderCell: () => <div></div>,
        };
        baseColumns.push(detailPanelToggleColumn);
        //@ts-ignore
        return baseColumns;
    };



    // const handleEdit = (id: any) => {
    //     // Retrieve the full row data
    //     const row = data.leads.find(lead => lead._id === id);

    //     // Set all fields except 'icebreaker' to edit mode
    //     //@ts-ignore
    //     const newFieldsMode = Object.keys(row).reduce((acc, key) => {
    //         if (key !== 'icebreaker') {
    //             acc[key] = { mode: GridRowModes.Edit };
    //         }
    //         return acc;
    //     }, {});

    //     setRowModesModel(prev => ({ ...prev, [id]: newFieldsMode }));
    // };


    const handleSave = async (id: string | number, row: { [x: string]: any; }) => {
        // Step 1: Create a mapping from field names to field IDs
        //@ts-ignore
        const fieldNameToFieldIdMapping = data.listDetails.headers.reduce((acc: { [x: string]: any; }, header: { name: string | number; fieldId: any; }) => {
            acc[header.name] = header.fieldId;
            return acc;
        }, {});

        // Step 2: Transform the row data to use field IDs instead of field names
        const updatedFieldsWithFieldId = Object.keys(row).reduce((acc, key) => {
            // Skip non-relevant keys
            if (!['_id', 'userId', 'listId'].includes(key)) {
                // Find the fieldId for the current key and use it if available
                const fieldId = fieldNameToFieldIdMapping[key];
                if (fieldId) {
                    acc[fieldId] = row[key];
                } else {
                    console.warn(`No fieldId found for key: ${key}`);
                }
            }
            return acc;
        }, {});

        // Step 3: Send the update request with the transformed data
        try {
            const url = `${process.env.REACT_APP_API_URL}/leads/update/${id}`;
            const response = await axios.put(url, updatedFieldsWithFieldId);

            console.log("fieldss:", updatedFieldsWithFieldId)
            console.log('Update successful:', response.data);

            setRowModesModel((prevModel) => ({
                ...prevModel,
                [id]: { mode: GridRowModes.View }, // Ensure the row is set to view mode
            }));

            // Optionally, refresh the data to reflect the updated information
            //@ts-ignore
            refreshData({ noSkeleton: true });

        } catch (error) {
            console.error('Update failed:', error);
        }
    };

    const processRowUpdate = async (newRow, oldRow) => {
        try {
            await handleSave(newRow._id, newRow); // No need to directly setRowModesModel here
            console.log("Row saved successfully");
            return newRow;
        } catch (error) {
            console.error("Failed to save row changes:", error);
            throw error; // Rethrow or handle as needed
        }
    };


    const handleCancelEdit = (id: any) => {
        // Simply switch back to view mode without saving
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    };

    const handleDelete = async (id) => {
        // Prepare the payload as per backend expectation
        const payload = {
            leadIds: [id], // Since you're deleting one lead at a time, wrap the id in an array
        };

        try {
            // Send the delete request to the backend
            const url = `${process.env.REACT_APP_API_URL}/leads/delete`;
            await axios.delete(url, { data: payload }); // Note: axios.delete sends data in the config object

            console.log('Lead deleted successfully');

            // Refresh the data to update the UI
            //@ts-ignore
            refreshData({ noSkeleton: true });
            setSelectedLeadIds([]); // Reset selected leads
        } catch (error) {
            console.error('Error deleting lead:', error);
            // Handle the error, e.g., show an error message to the user
        }
    };

    const handleOpenTagMenu = (leadId, event) => {
        setTagMenuAnchorEl(event.currentTarget);
        setSelectedLeadId(leadId); // Store the leadId for tagging purposes
    };

    const handleCloseTagMenu = () => {
        setTagMenuAnchorEl(null);
    };


    //@ts-ignore
    // const columns: GridColDef[] = generateColumns(); // Call the function to generate columns ******* old
    // const columns = React.useMemo(() => generateColumns(), [columnWidths, data.listDetails.headers]); **** / before column manager

    useEffect(() => {
        const newColumns: GridColDef[] = generateColumns(); // Make sure this function returns CustomColumn[]
        setColumns(newColumns);
    }, [columnWidths, data.listDetails.headers]); // Dependencies that trigger recalculation of columns



    // Filter rows based on filters
    // Filter rows based on filters including nested emails and phones
    // Assuming 'filters' might have keys like 'name', 'Emails', 'Phones', etc.
    const filteredRows = data.leads.filter((row) => {
        // Check top-level properties
        const matchesTopLevel = Object.keys(filters).filter(k => k !== 'Emails' && k !== 'Phones').every((filterKey) => {
            const filterValue = filters[filterKey]?.toLowerCase();
            const rowValue = row[filterKey]?.toString().toLowerCase() || "";
            return rowValue.includes(filterValue);
        });

        // Email filtering
        const hasEmail = row.Emails.length > 0;
        const matchesEmailFilter = (
            (emailFilter.hasAny && hasEmail) ||
            (emailFilter.doesntHaveAny && !hasEmail) ||
            (!emailFilter.hasAny && !emailFilter.doesntHaveAny) // No email filter applied
        );

        // Email verification filtering
        const hasVerifiedEmail = row.Emails.some(email => email.validated === true);
        const hasUnverifiedEmail = row.Emails.some(email => email.validated === false);

        // Add country filter logic
        const matchesCountryFilter = selectedCountries.length === 0 || row.Phones.some(phone => selectedCountries.includes(phone.country));

        let matchesEmailVerificationFilter = true;
        switch (emailVerificationFilter) {
            case 'verified':
                matchesEmailVerificationFilter = hasVerifiedEmail;
                break;
            case 'unverified':
                matchesEmailVerificationFilter = hasUnverifiedEmail;
                break;
            default:
                // 'all' or any other case, no filtering needed
                break;
        }

        const emailConditionsMet = emailFilter.textSearch.length === 0 ? true :
            row.Emails.some(email =>
                emailFilter.textSearch.some(searchText =>
                    //@ts-ignore
                    email.emailaddress.toLowerCase().includes(searchText.toLowerCase())
                )
            );


        // Phone filtering
        const hasPhone = row.Phones.length > 0;
        const matchesPhoneFilter = (
            (phoneFilter.hasAny && hasPhone) ||
            (phoneFilter.doesntHaveAny && !hasPhone) ||
            (!phoneFilter.hasAny && !phoneFilter.doesntHaveAny) // No phone filter applied
        );


        // A row matches if it matches top-level properties AND (emails OR phones)
        return matchesTopLevel && matchesEmailFilter && matchesPhoneFilter && matchesEmailVerificationFilter && matchesCountryFilter && emailConditionsMet;
    });



    return (
        <>
            {/* <ColumnManager
                    columns={columns}
                    setColumns={setColumns}
                    columnVisibilityModel={columnVisibilityModel}
                    setColumnVisibilityModel={setColumnVisibilityModel}

                /> */}

            <Box style={{ height: '100vh', width: '100%' }}>

                <StyledDataGridPremium
                    apiRef={gridApiRef}
                    rows={filteredRows}
                    columns={columns}
                    onColumnWidthChange={handleColumnWidthChange}
                    getRowId={(row) => row._id}
                    loading={data.loading}
                    columnVisibilityModel={columnVisibilityModel}
                    onColumnVisibilityModelChange={setColumnVisibilityModel}

                    checkboxSelection
                    cellSelection
                    keepNonExistentRowsSelected

                    slots={{
                        loadingOverlay: TableSkeletonLoader.bind(null, { columnCount: columns.length, rowHeight: gridDensity }),
                        noRowsOverlay: CustomNoRowsOverlay2,
                        toolbar: CustomToolbar,
                    }}
                    disableAggregation={true}
                    disableRowGrouping
                    pagination
                    pageSize={10}
                    pageSizeOptions={[10, 100, { value: 1000, label: '1,000' }]}
                    rowHeight={
                        gridDensity === 'extraCompact' ? 24 :
                            gridDensity === 'compact' ? 35 :
                                gridDensity === 'comfortable' ? 50 :
                                    gridDensity === 'extraComfortable' ? 60 : // Default or standard height
                                        undefined // Default or standard height
                    }

                    slotProps={{
                        toolbar: {
                            selectedLeadIds,
                            setSelectedLeadIds,
                            selectedListId,
                            refreshData,
                            isFixingCompanyName,
                            setIsFixingCompanyName,
                            isValidatingEmails,
                            setIsValidatingEmails,
                            isReoonValidatingEmails,
                            setIsReoonValidatingEmails,
                            isClassifyingEmails,
                            setIsClassifyingEmails,
                            handleExtraCondensedView,
                            handleCondensedView,
                            handleRelaxedView,
                            handleExtraRelaxedView,
                            togglePanelVisibility,
                            uploadSingleLead,
                            uploadFileList


                        },
                    }}


                    getDetailPanelContent={({ row }) =>
                        <DetailPanelContent
                            //@ts-ignore
                            row={row}
                            gridDensity={gridDensity}
                            isValidatingEmails={isValidatingEmails} // Make sure this state is available and passed down
                            isReoonValidatingEmails={isReoonValidatingEmails}
                            isClassifyingEmails={isClassifyingEmails}
                            refreshData={refreshData}
                        />
                    }
                    getDetailPanelHeight={({ row }) => 'auto'}
                    // getDetailPanelHeight={({ row }) => {
                    //     switch (gridDensity) {
                    //         case 'extraCompact':
                    //             return 100; // Adjust these values as needed
                    //         case 'compact':
                    //             return 120;
                    //         case 'comfortable':
                    //             return 200;
                    //         case 'extraComfortable':
                    //             return 250;
                    //         default:
                    //             return 150; // Fallback height
                    //     }
                    // }}

                    //@ts-ignore
                    onRowDoubleClick={handleRowDoubleClick}
                    detailPanelExpandedRowIds={openDetailPanelRowIds}
                    onRowSelectionModelChange={(newSelection) => {
                        setSelectedLeadIds(newSelection);
                    }}
                    rowSelectionModel={selectedLeadIds}
                    rowModesModel={rowModesModel}

                    onRowModesModelChange={setRowModesModel}
                    pinnedColumns={{ right: ['actions'] }}

                    disableRowSelectionOnClick={true} // Disables row selection on click

                    processRowUpdate={processRowUpdate}



                    // pinnedColumns={{ right: ['view', "contactInfo"], left: ['__check__', 'full_name'] }}

                    sx={{
                        border: "0px",
                        height: "90%",
                        '--DataGrid-overlayHeight': '300px',
                        //checkbox:
                        '& .MuiCheckbox-root svg': {
                            width: 16,
                            height: 16,
                            backgroundColor: 'action.hover',
                        },
                        '& .MuiCheckbox-root svg path': {
                            display: 'none',
                        },
                        '& .MuiCheckbox-root.Mui-checked:not(.MuiCheckbox-indeterminate) svg': {
                            backgroundColor: `${theme.palette.mode === 'light' ? `${theme.palette.secondary.main}` : `${theme.palette.primary.main}`}`,
                        },
                        '& .MuiCheckbox-root.Mui-checked .MuiIconButton-label:after': {
                            position: 'absolute',
                            display: 'table',
                            opacity: 1,
                            transition: 'all .2s cubic-bezier(.12,.4,.29,1.46) .1s',
                            content: '""',
                            top: '50%',
                            left: '39%',
                            width: 5.71428571,
                            height: 9.14285714,
                        },
                        '& .MuiCheckbox-root.MuiCheckbox-indeterminate .MuiIconButton-label:after': {
                            width: 8,
                            height: 8,
                            backgroundColor: `${theme.palette.mode === 'light' ? `${theme.palette.secondary.main}` : `${theme.palette.primary.main}`}`,
                            transform: 'none',
                            top: '39%',
                        },

                        //ActiveCell
                        '.MuiDataGrid-cell:focus': {
                            outline: 'solid transparent 1px!important',
                        },

                    }}


                />

                <TagMenu
                    anchorEl={tagMenuAnchorEl}
                    handleClose={handleCloseTagMenu}
                    leadId={selectedLeadId}
                    refreshData={refreshData}
                />

            </Box>
        </>

    );
};

export default TableComponent;

interface DetailPanelContentProps {
    row: Lead;
    gridDensity: string;
    isValidatingEmails: boolean; // Add this line
    isReoonValidatingEmails: boolean;
    isClassifyingEmails: boolean;
    refreshData: () => void;
}

const DetailPanelContent: React.FC<DetailPanelContentProps> = ({ row, gridDensity, isValidatingEmails, isReoonValidatingEmails, isClassifyingEmails, refreshData }) => {
    // Columns definition
    const [detailRowModesModel, setDetailRowModesModel] = React.useState({});

    const [version, setVersion] = useState(0); // State to force rerender by changing its value

    const [showAddForm, setShowAddForm] = useState(false);
    const [contactType, setContactType] = React.useState<'email' | 'phone'>('email');
    const [contactValue, setContactValue] = useState('');

    const [isEditing, setIsEditing] = useState(false);
    const [aboutText, setAboutText] = useState(row.enrichment?.aboutText || "");
    const [postExpanded, setPostExpanded] = useState<string | false>(false);

    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('info'); // can be 'error', 'warning', 'info', 'success'


    const handleDetailEdit = (id) => {
        setDetailRowModesModel({ ...detailRowModesModel, [id]: { mode: GridRowModes.Edit } });
    };

    const handlePostAccordionChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
        setPostExpanded(isExpanded ? panel : false);
    };


    const handleDetailSave = async (id, editedRow) => {
        // Here you would implement logic to save the edited row, possibly sending an update request to your backend
        console.log("Saving detail row:", id, editedRow);
        // After saving, switch back to view mode
        setDetailRowModesModel({ ...detailRowModesModel, [id]: { mode: GridRowModes.View } });
    };

    const handleDetailCancelEdit = (id) => {
        setDetailRowModesModel({ ...detailRowModesModel, [id]: { mode: GridRowModes.View, ignoreModifications: true } });
    };

    ///////////////
    const toggleAddForm = () => {
        setShowAddForm(!showAddForm);
    };

    const handleContactTypeChange = (event, newType) => {
        if (newType) {
            setContactType(newType);
        }
    };

    const handleContactValueChange = (event) => {
        setContactValue(event.target.value);
    };

    const handleSubmitContact = async () => {
        const apiBaseUrl = process.env.REACT_APP_API_URL;
        let payload = {};
        if (contactType === 'email') {
            payload = { newEmail: contactValue };
        } else if (contactType === 'phone') {
            payload = { newPhone: contactValue };
        }

        try {
            await axios.post(`${apiBaseUrl}/leads/add-contact/lead/${row._id}`, payload);
            //@ts-ignore
            refreshData({ noSkeleton: true });
            setContactValue('');
            setShowAddForm(false); // Optionally close form on success
            handleSnackbarOpen('Contact added successfully', 'success');
        } catch (error: any) {
            handleSnackbarOpen(error.response.data.error || 'Failed to add contact', 'error');
        }
    };


    //////////

    const handleSnackbarOpen = (message, severity) => {
        setSnackbarMessage(message);
        setSnackbarSeverity(severity);
        setSnackbarOpen(true);
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };

    // Optional: Placeholder component or simply null while loading
    const FlagPlaceholder = () => <div style={{ width: '24px', height: '16px' }}></div>;

    const renderPhoneCell = (params) => (
        <>
            <PhoneIcon color="secondary" sx={{ mr: 1, ml: 1 }} />
            {params.row.country && (
                <Suspense fallback={<FlagPlaceholder />}>
                    <Box sx={{ width: 24, height: 16, display: 'inline-flex', alignItems: 'center', mr: 2, ml: 1 }}>
                        <Flag code={params.row.country} height="16" />
                    </Box>
                </Suspense>
            )}

        </>
    );




    const columns: GridColDef[] = [
        {
            field: 'value',
            headerName: '',
            width: 300,
            editable: true,
            renderCell: (params) => (
                <>
                    {params.row.itemType === 'email' ? (
                        <EmailSharpIcon color="primary" sx={{ mr: 1 }} />
                    ) : renderPhoneCell(params)
                    }

                    {params.row.value}

                </>
            ),

        },

        {
            field: 'type',
            headerName: 'Type',
            width: 120,
            editable: false,
            renderCell: (params) => {
                if (isClassifyingEmails) {
                    // If so, render the Skeleton
                    return <Skeleton animation="wave" width="80%" />;
                }
                switch (params.value) {
                    case 'unknown':
                        return <HelpSharpIcon color="secondary" />;
                }
            },

        },
        {
            field: 'validated',
            headerName: 'Validated',
            width: 100,
            renderCell: (params) => (
                params.value ? <CheckCircleIcon color="success" /> : <CancelSharp color="secondary" />
            ),
        },
        {
            field: 'validationAttempts',
            headerName: 'Validation Attempts',
            width: 180,
            renderCell: (params) => (
                <>
                    {params.row.itemType === 'email' ? (
                        <Typography>{params.value}</Typography>
                    ) : null}
                </>
            ),
        },
        {
            field: 'validationstatus',
            headerName: 'Validation Result',
            width: 200,
            renderCell: (params) => {
                if (isValidatingEmails || isReoonValidatingEmails) {
                    // If so, render the Skeleton
                    return <Skeleton animation="wave" width="80%" />;
                }
                switch (params.value) {
                    case 'valid':
                        return <ThumbUpAltSharpIcon color="success" />;
                    case 'invalid':
                        return <ThumbDownAltSharpIcon color="error" />;
                    case 'unknown':
                        return <HelpSharpIcon color="secondary" />;
                    case 'failed':
                        return <ReplayCircleFilledSharp color="warning" />;
                    case 'catchall':
                        return <CircleSharp sx={{ color: "#54e19e" }} />;
                    default:
                        return null;
                }
            },
        }

    ];


    // Append an actions column for edit/save/cancel buttons
    columns.push({
        field: 'actions',
        headerName: 'Actions',
        width: 150,
        type: 'actions',
        getActions: (params) => {
            const isInEditMode = detailRowModesModel[params.id]?.mode === 'edit';
            if (isInEditMode) {
                return [
                    <GridActionsCellItem
                        icon={<DeleteSharp />}
                        label="Delete"
                        onClick={() => handleDeleteContact(params.row.itemType, params.row.value, params.id)}
                        showInMenu
                    />,
                    <GridActionsCellItem
                        icon={<SaveSharp />}
                        label="Save"
                        onClick={() => handleDetailSave(params.id, params.row)}
                    />,
                    <GridActionsCellItem
                        icon={<CloseSharp />}
                        label="Cancel"
                        onClick={() => handleDetailCancelEdit(params.id)}
                    />,
                ];
            }
            return [
                <GridActionsCellItem
                    icon={<EditSharp />}
                    label="Edit"
                    onClick={() => handleDetailEdit(params.id)}
                />,
            ];
        },
    });


    // Combine Emails and Phones into one array
    const combinedRows = [
        ...row.Emails.map((email: { emailaddress: any; type: any; validated: any; validationStatus: any; validations: any }, index: any) => ({
            id: `email-${index}`,
            value: email.emailaddress,
            type: email.type, // This remains as is, representing the type of email
            validated: email.validated,
            validationstatus: email.validationStatus,
            validationAttempts: email.validations ? email.validations.length : 0,
            itemType: 'email' // Add itemType to distinguish between emails and phones
        })),
        ...row.Phones.map((phone: {
            country: any; number: any; type: any; validated: any; validationStatus: any;
        }, index: any) => ({
            id: `phone-${index}`,
            value: phone.number,
            type: phone.type, // This remains as is, representing the type of phone
            validated: phone.validated,
            validationstatus: phone.validationStatus,
            itemType: 'phone', // Add itemType to distinguish between emails and phones
            country: phone.country // ISO 3166-1 alpha-2 code
        })),
    ];

    const getRowHeight = () => {
        switch (gridDensity) {
            case 'extraCompact':
                return 24; // Smaller height for extra condensed view
            case 'compact':
                return 30; // Slightly larger height for condensed view
            case 'comfortable':
                return 40; // More space for comfortable view
            case 'extraComfortable':
                return 50; // Maximum space for extra relaxed view
            default:
                return 36; // Default row height
        }
    };


    const getHeaderHeight = (gridDensity) => {
        switch (gridDensity) {
            case 'extraCompact':
                return '32px';
            case 'compact':
                return '36px';
            case 'comfortable':
                return '48px';
            case 'extraComfortable':
                return '56px';
            default:
                return '40px';
        }
    };

    const makeProcessRowUpdate = (leadId, refreshData) => async (newRow, oldRow) => {
        const apiBaseUrl = process.env.REACT_APP_API_URL; // Ensure this is set in your environment
        let url = `${apiBaseUrl}/leads/update-lead-phone-or-email/${leadId}`;
        let updatePayload = {};

        // Determine whether the row is an email or phone and prepare the payload accordingly
        if (newRow.itemType === 'email') {
            updatePayload = {
                emailToUpdate: oldRow.value,
                newEmail: newRow.value
            };
        } else if (newRow.itemType === 'phone') {
            updatePayload = {
                phoneToUpdate: oldRow.value,
                newPhone: newRow.value
            };
        }

        try {
            // Sending the update request
            const response = await axios.put(url, updatePayload);
            console.log('Update success:', response.data);

            // Call refreshData after a successful update
            //@ts-ignore
            refreshData({ noSkeleton: true });

            return newRow; // Return the updated row on success
        } catch (error: any) {
            handleSnackbarOpen(error.response.data.error || 'Failed to update contact', 'error');
            throw new Error('Update failed'); // Prevent DataGrid from updating the internal state
        }
    };



    const forceRerender = () => {
        setVersion(v => v + 1);
    };

    const handleDeleteContact = async (itemType, value, rowId) => {
        try {
            const apiBaseUrl = process.env.REACT_APP_API_URL;
            let payload = itemType === 'email' ? { emailToRemove: value } : { phoneToRemove: value };
            await axios.delete(`${apiBaseUrl}/leads/remove-contact/lead/${row._id}`, { data: payload });

            // Update the row modes model to ensure no residual edit state
            setDetailRowModesModel(prevModel => {
                const newModel = { ...prevModel };
                delete newModel[rowId]; // This removes any edit mode related to the deleted row
                return newModel;
            });

            //@ts-ignore
            refreshData({ noSkeleton: true });
            forceRerender();
            handleSnackbarOpen('Contact deleted successfully', 'success');
        } catch (error: any) {
            handleSnackbarOpen(error.response.data.error || 'Failed to delete contact', 'error');
        }
    };


    const handleAboutEditClick = () => {
        setIsEditing(true);
    };

    const handleAboutSaveClick = async () => {
        try {
            const response = await axios.patch(`${process.env.REACT_APP_API_URL}/leads/enrichment/about/${row._id}`, {
                aboutText: aboutText
            });
            setSnackbarMessage('About text updated successfully');
            setSnackbarSeverity('success');
            setIsEditing(false);
        } catch (error) {
            setSnackbarMessage('Failed to update about text');
            setSnackbarSeverity('error');
        }
        setSnackbarOpen(true);
    };

    useEffect(() => {
        if (row.enrichment?.aboutText) {
            setAboutText(row.enrichment.aboutText);
        }
    }, [row.enrichment?.aboutText]);

    return (
        <Box sx={{ margin: 0 }}>

            <DataGridPremium
                key={version}  // This forces the DataGrid to rerender when the version changes
                rows={combinedRows} // This should be dynamically generated based on the row data
                columns={columns}
                autoHeight
                hideFooter
                //@ts-ignore
                cellSelection
                rowModesModel={detailRowModesModel}
                onRowModesModelChange={setDetailRowModesModel}
                rowHeight={getRowHeight()}
                sx={{

                    border: "0px",
                    '& .css-yrdy0g-MuiDataGrid-columnHeaderRow': {
                        height: getHeaderHeight,
                        minHeight: getHeaderHeight, // Ensure the header height is enforced
                        maxHeight: "fit-content",
                        fontSize: "12px", // Adjust font size dynamically
                    },


                }}
                processRowUpdate={makeProcessRowUpdate(row._id, refreshData)}

            />
            <Box sx={{ mt: 2 }}>
                <Button size='small' fullWidth variant="text" onClick={toggleAddForm}>
                    {showAddForm ? <CancelSharp /> : <AddSharp />}
                </Button>
                {showAddForm && (
                    <Box sx={{ mt: 2, display: 'flex', alignItems: 'center', justifyContent: "center", gap: 2 }}>
                        <ToggleButtonGroup
                            color="primary"
                            value={contactType}
                            exclusive
                            onChange={handleContactTypeChange}
                            aria-label="Contact Type"
                        >
                            <ToggleButton value="email">Email</ToggleButton>
                            <ToggleButton value="phone">Phone</ToggleButton>
                        </ToggleButtonGroup>
                        <TextField
                            label={contactType === 'email' ? 'Email Address' : 'Phone Number'}
                            variant="outlined"
                            value={contactValue}
                            size='small'
                            onChange={handleContactValueChange}
                        />
                        <Button size='small' onClick={handleSubmitContact} variant="text">
                            <AddSharp />
                        </Button>
                    </Box>
                )}
            </Box>
            <Box>


                <Box sx={{ margin: 2 }}>
                    <Box>
                        <Box sx={{ border: '1px', p: 2, borderRadius: '0px' }}>
                            {isEditing ? (
                                <TextField
                                    fullWidth
                                    disabled={!isEditing}
                                    multiline
                                    variant="outlined"
                                    value={aboutText}
                                    onChange={(e) => setAboutText(e.target.value)}
                                />
                            ) : (
                                <Typography variant="body1" sx={{ whiteSpace: 'pre-line', overflow: 'hidden', textOverflow: 'ellipsis', display: '-webkit-box', WebkitLineClamp: 3, WebkitBoxOrient: 'vertical' }}>
                                    {aboutText}
                                </Typography>
                            )}
                        </Box>
                        {!isEditing ? (
                            <Button onClick={handleAboutEditClick} variant="contained" sx={{ mt: 2 }}>
                                Edit
                            </Button>
                        ) : (
                            <Button onClick={handleAboutSaveClick} variant="contained" color="primary" sx={{ mt: 2 }}>
                                Save
                            </Button>
                        )}
                    </Box>
                    <Typography variant="h6" sx={{ mt: 2 }}>Posts:</Typography>
                    {
                        //@ts-ignore
                        row.enrichment?.posts
                            .sort((a, b) => new Date(b.postDate).getTime() - new Date(a.postDate).getTime()) // Sort posts by date, latest first
                            .slice(0, 2) // Take only the first two posts
                            .map((post, index) => (
                                <Accordion key={index} expanded={postExpanded === `panel${index}`} onChange={handlePostAccordionChange(`panel${index}`)}>
                                    <AccordionSummary expandIcon={<ExpandMoreSharp />} sx={{ alignItems: 'center' }}>
                                        <Typography sx={{ flexShrink: 1, flexGrow: 0, flexBasis: '60%', mr: 1 }}>
                                            {post.postText.split(" ").slice(0, 7).join(" ")}...
                                            <Typography component="span" variant="caption" sx={{ ml: 1, color: 'text.secondary' }}>
                                                {new Date(post.postDate).toLocaleDateString()}
                                            </Typography>
                                        </Typography>
                                        {/* <Box sx={{ display: 'flex', gap: 1 }}>
                                                    <Badge badgeContent={post.reactions} color="primary">
                                                        <ThumbUpAltIcon />
                                                    </Badge>
                                                    <Badge badgeContent={post.comments} color="secondary">
                                                        <CommentIcon />
                                                    </Badge>
                                                    <Badge badgeContent={post.shares} color="error">
                                                        <ShareIcon />
                                                    </Badge>
                                                </Box> */}
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography>{post.postText}</Typography>
                                        {/* <Typography variant="caption" display="block">{new Date(post.postDate).toLocaleDateString()}</Typography> */}
                                        {/* <Typography variant="caption">Reactions: {post.reactions}</Typography>
                                                <Typography variant="caption">Comments: {post.comments}</Typography>
                                                <Typography variant="caption">Shares: {post.shares}</Typography> */}
                                    </AccordionDetails>
                                </Accordion>
                            ))
                    }

                </Box>

            </Box>
            <Divider sx={{ height: "4px" }} />

            <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
                <Alert
                    //@ts-ignore
                    onClose={handleSnackbarClose} severity={snackbarSeverity}
                    sx={{ width: '100%' }}
                >
                    {snackbarMessage}
                </Alert>
            </Snackbar>

        </Box>
    );
};


function isKeyboardEvent(event: any): event is React.KeyboardEvent {
    return !!event.key;
}

const multilineColumn: GridColTypeDef = {
    type: 'string',
    renderEditCell: (params) => <EditTextarea {...params} />,
};

function EditTextarea(props: GridRenderEditCellParams<any, string>) {
    const theme = useTheme()

    const { id, field, value, colDef, hasFocus } = props;
    const [valueState, setValueState] = React.useState(value);
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>();
    const [inputRef, setInputRef] = React.useState<HTMLInputElement | null>(null);
    const apiRef = useGridApiContext();

    React.useLayoutEffect(() => {
        if (hasFocus && inputRef) {
            inputRef.focus();
        }
    }, [hasFocus, inputRef]);

    const handleRef = React.useCallback((el: HTMLElement | null) => {
        setAnchorEl(el);
    }, []);

    const handleChange = React.useCallback<NonNullable<InputBaseProps['onChange']>>(
        (event) => {
            const newValue = event.target.value;
            setValueState(newValue);
            apiRef.current.setEditCellValue(
                { id, field, value: newValue, debounceMs: 200 },
                event,
            );
        },
        [apiRef, field, id],
    );

    const DeleteQuotationMarks = () => {
        //@ts-ignore
        const trimmedValue = valueState.replace(/^"(.*)"$/, '$1');
        setValueState(trimmedValue);
        apiRef.current.setEditCellValue({ id, field, value: trimmedValue });
    };

    return (
        <div style={{ position: 'relative', alignSelf: 'flex-start' }}>
            <div
                ref={handleRef}
                style={{
                    height: 1,
                    width: colDef.computedWidth,
                    display: 'block',
                    position: 'absolute',
                    top: 0,
                }}
            />
            {anchorEl && (
                <Popper open anchorEl={anchorEl} placement="bottom-start">
                    <Paper elevation={1} sx={{ p: 1, minWidth: colDef.computedWidth }}>
                        <InputBase
                            multiline
                            rows={4}
                            value={valueState}
                            sx={{ textarea: { resize: 'both' }, width: '100%' }}
                            onChange={handleChange}
                            inputRef={(ref) => setInputRef(ref)}
                        />
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 1 }}>
                            <CustomTooltip
                                title="Remove Quotation Marks: sometimes AI generated IceBreakers start and end with Quotation Marks"
                            >

                                <Button onClick={DeleteQuotationMarks} variant="text" color="primary" size="small">

                                    <FormatQuoteIcon />
                                </Button>
                            </CustomTooltip>
                        </Box>
                    </Paper>
                </Popper>
            )}
        </div>
    );
}


const StyledDataGridPremium = styled(DataGridPremium)(({ theme }) => {
    const borderColor =
        theme.palette.mode === 'light'
            ? lighten(alpha(theme.palette.divider, 1), 0.88)
            : darken(alpha(theme.palette.divider, 1), 0.68);

    const selectedCellBorder = alpha(theme.palette.secondary.main, 0.5);

    return {
        [`& .${gridClasses.cell}`]: {
            border: `1px solid transparent`,
            borderRight: `1px solid ${borderColor}`,
            borderBottom: `1px solid ${borderColor}`,
        },
        [`& .${gridClasses.cell}.Mui-selected`]: {
            borderColor: alpha(theme.palette.primary.main, 0.1),
        },
        [`& .${gridClasses.cell}.Mui-selected.${gridClasses['cell--rangeTop']}`]: {
            borderTopColor: "#222222!important",
        },
        [`& .${gridClasses.cell}.Mui-selected.${gridClasses['cell--rangeBottom']}`]: {
            borderTopColor: "#222222!important",
        },
        [`& .${gridClasses.cell}.Mui-selected.${gridClasses['cell--rangeLeft']}`]: {
            borderTopColor: "#222222!important",
        },
        [`& .${gridClasses.cell}.Mui-selected.${gridClasses['cell--rangeRight']}`]: {
            borderTopColor: "#222222!important",
        },
    };
});


const TableSkeletonLoader = ({ columnCount, rowHeight }) => {
    const theme = useTheme()
    return (
        <Box sx={{ width: '100%', overflow: 'auto' }}>
            {Array.from(new Array(10)).map((_, index) => (
                <Grid
                    container
                    key={index}
                //   sx={{ borderBottom: '1px solid rgba(224, 224, 224, 1)' }}
                >
                    {Array.from(new Array(columnCount)).map((_, colIndex) => (
                        <Grid item xs={Math.floor(12 / columnCount)} key={colIndex}>
                            <Skeleton
                                variant="text"
                                animation="wave"
                                height={
                                    rowHeight === 'extraCompact' ? 24 :
                                        rowHeight === 'compact' ? 35 :
                                            rowHeight === 'comfortable' ? 50 :
                                                rowHeight === 'extraComfortable' ? 60 : // Default or standard height
                                                    undefined // Default or standard height
                                }


                                sx={{ m: 1, bgcolor: theme.palette.action.hover }}
                            />
                        </Grid>
                    ))}
                </Grid>
            ))}
        </Box>
    );
};
