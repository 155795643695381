// src\app\pages\4-Campaigns\3-CampaignSequence\SequenceComponents\NewEditor\components\LexicalEditorWrapper\index.tsx
import { $getRoot, $getSelection, $insertNodes } from "lexical";
import { useCallback, useEffect } from "react";

import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import { OnChangePlugin } from "@lexical/react/LexicalOnChangePlugin";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { MuiContentEditable, placeHolderSx } from "./styles";
import { Box, Divider } from "@mui/material";
import { lexicalEditorConfig } from "../../config/lexicalEditorConfig";
import LexicalEditorTopBar from "../LexicalEditorTopBar";
import TreeViewPlugin from "../CustomPlugins/TreeViewPlugin";
import { ListPlugin } from "@lexical/react/LexicalListPlugin";
import { LinkPlugin } from "@lexical/react/LexicalLinkPlugin";
import ImagesPlugin from "../CustomPlugins/ImagePlugin";
import FloatingTextFormatToolbarPlugin from "../CustomPlugins/FloatingTextFormatPlugin";
import InitialStatePlugin from "../CustomPlugins/InitialStatePlugin/Initialstateplugin";
import { $generateHtmlFromNodes, $generateNodesFromDOM } from "@lexical/html";
import CustomOnChangePlugin from "../CustomPlugins/MyCustomOnChangePlugin.tsx/onChangePlugin";
import { AutoDetectMentionPlugin } from "../CustomPlugins/MentionPlugin/AutoDetectMentionPlugin";
import { MentionsPlugin } from "../CustomPlugins/MentionPlugin/MentionPlugin";


interface LexicalEditorWrapperProps {
  initialContent: string;
  onContentChange: (content: string) => void;
  contentKey: string;
  emailType: string;
  personalizationHeaders?: any; // Adjust the type as per your requirements
}



function LexicalEditorWrapper({
  initialContent,
  onContentChange,
  contentKey,
  emailType,
  personalizationHeaders,
}: LexicalEditorWrapperProps) {

  return (
    //@ts-ignore
    <LexicalComposer initialConfig={lexicalEditorConfig}>
      {emailType !== 'sms' && emailType !== 'plain' && <LexicalEditorTopBar />}
      <Divider />
      <Box sx={{ position: "relative", background: "white" }}>
        <RichTextPlugin // #312D4B
          contentEditable={<MuiContentEditable />}
          placeholder={<Box sx={placeHolderSx}>Enter some text...</Box>}
          ErrorBoundary={LexicalErrorBoundary}
        />
        {/* <OnChangePlugin onChange={onChange} /> */}
        <HistoryPlugin />
        {/* <TreeViewPlugin /> */}
        {personalizationHeaders && <MentionsPlugin personalizationHeaders={personalizationHeaders} />}
        <ListPlugin />
        <LinkPlugin />
        <ImagesPlugin captionsEnabled={false} />
        {/* <FloatingTextFormatToolbarPlugin /> */}
        <InitialStatePlugin initialContent={initialContent} contentKey={contentKey} emailType={emailType} />
        <CustomOnChangePlugin onContentChange={onContentChange} emailType={emailType} />
        {/* <AutoDetectMentionPlugin /> */}
      </Box>
    </LexicalComposer>
  );
}

// When the editor changes, you can get notified via the
// LexicalOnChangePlugin!
// function onChange(editorState) {
//   editorState.read(() => {
//     // Read the contents of the EditorState here.
//     const root = $getRoot();
//     const selection = $getSelection();

//     console.log(root, selection);
//   });
// }

// Lexical React plugins are React components, which makes them
// highly composable. Furthermore, you can lazy load plugins if
// desired, so you don't pay the cost for plugins until you
// actually use them.
// function MyCustomAutoFocusPlugin() {
//   const [editor] = useLexicalComposerContext();

//   useEffect(() => {
//     // Focus the editor when the effect fires!
//     editor.focus();
//   }, [editor]);

//   return null;
// }



export default LexicalEditorWrapper;

