// src\app\pages\uploadcsv2\CsvTable.tsx
import React, { useState, useEffect } from 'react';
import { DataGridPro, GridColDef } from '@mui/x-data-grid-pro';
import { Box, Paper } from '@mui/material';
import EditableHeader from './EditableHeader';

interface CsvTableProps {
    selectedFile: File | null;
    previewData: string[][];
    editableHeaders: string[];
    visibility: boolean[];
    filters: { [key: string]: string };
    setFilters: React.Dispatch<React.SetStateAction<{ [key: string]: string }>>;
    onHeaderChange: (index: number, newHeader: string) => void; // New callback prop
}

const CsvTable: React.FC<CsvTableProps> = ({
    selectedFile,
    previewData,
    editableHeaders,
    visibility,
    filters,
    setFilters,
    onHeaderChange
}) => {
    const [columns, setColumns] = useState<GridColDef[]>([]);
    const [filteredRows, setFilteredRows] = useState<any[]>([]);
    const [numRowsToShow, setNumRowsToShow] = useState<number>(10); // Number of rows to initially show

    useEffect(() => {
        // Update columns whenever visibility or editableHeaders change
        const columns: GridColDef[] = editableHeaders.map((editableHeader, index) => ({
            field: `field${index}`,
            headerName: editableHeader,
            width: 150,
            filterable: false,
            sortable: false,
            hideable: false,
            filterValue: filters[`field${index}`] || '',
            filterInputProps: {
                name: `field${index}`,
                onChange: (event) => {
                    const { name, value } = event.target;
                    setFilters({
                        ...filters,
                        [name]: value,
                    });
                },
            },
            renderHeader: () => (
                <EditableHeader
                    originalHeader={previewData[0][index]}
                    editableHeader={editableHeader}
                    onChange={(newHeader) => onHeaderChange(index, newHeader)}
                />
            ),
        })).filter((_, index) => visibility[index]);

        setColumns(columns);
    }, [editableHeaders, visibility, filters, setFilters, previewData, onHeaderChange]);

    useEffect(() => {
        // Update filteredRows whenever filters or numRowsToShow change
        const filteredRows = previewData.slice(1).map((row, index) => {
            const rowData = {};
            row.forEach((cell, cellIndex) => {
                rowData[`field${cellIndex}`] = cell;
            });
            return { id: index, ...rowData };
        }).filter(row => {
            return Object.keys(filters).every(key => {
                const filterValue = (filters[key] || '').toLowerCase();
                const rowValue = String(row[key]).toLowerCase();
                return rowValue.includes(filterValue);
            });
        }).slice(0, numRowsToShow); // This slice limits the number of rows displayed

        setFilteredRows(filteredRows);
    }, [filters, previewData, numRowsToShow]);

    return (
        <Box>
            <Paper style={{ height: 400, width: '100%' }}>
                <DataGridPro
                    rows={filteredRows}
                    columns={columns}
                    getRowId={row => row.id}
                    hideFooter={true} // Disable the footer
                    rowCount={5}
                    sx={{ border: "0px" }}
                />
            </Paper>
        </Box>
    );
};

export default CsvTable;