import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { List, ListItem, ListItemText, TextField, Button, Typography, Collapse } from '@mui/material';
import { useAuth } from '@/src/app/modules/auth'; // Ensure path is correct

interface IFeedback {
    _id: string;
    userId: string;
    title: string;
    content: string;
    createdAt: string;
    responses?: IResponse[];
    seen: boolean;
}

interface IResponse {
    _id: string;
    respondent: string;
    message: string;
    timestamp: Date;
    seen: boolean;
}

const isAdmin = true; // Simulate an admin check, replace with actual auth check later

const FeedbackViewer: React.FC = () => {
    const { currentUser } = useAuth();
    const [feedbacks, setFeedbacks] = useState<IFeedback[]>([]);
    const [newFeedbackTitle, setNewFeedbackTitle] = useState('');
    const [newFeedback, setNewFeedback] = useState('');
    const [loading, setLoading] = useState(false);
    const [expandedFeedbackId, setExpandedFeedbackId] = useState<string | null>(null);
    const [responseText, setResponseText] = useState('');

    useEffect(() => {
        fetchFeedbacks();
    }, []);

    const fetchFeedbacks = async () => {
        try {
            setLoading(true);
            const url = currentUser && currentUser.admin ? `${process.env.REACT_APP_API_URL}/notifications/feedback/all` : `${process.env.REACT_APP_API_URL}/notifications/feedback/summaries`;
            const response = await axios.get<IFeedback[]>(url);
            setFeedbacks(response.data);
            setLoading(false);
        } catch (error) {
            console.error('Failed to fetch feedback:', error);
            setLoading(false);
        }
    };

    // const markAsSeen = async (feedbackId: string, responseId: string | null = null) => {
    //     try {
    //         const response = await axios.post(`${process.env.REACT_APP_API_URL}/notifications/feedback/markSeen`, {
    //             feedbackId,
    //             responseId
    //         });
    //         if (response.status === 200) {
    //             // Update the local state to reflect the change
    //             const updatedFeedbacks = feedbacks.map(feedback => {
    //                 if (feedback._id === feedbackId) {
    //                     if (responseId) {
    //                         // Update the specific response as seen

    //                         const updatedResponses = feedback.responses?.map(res => {
    //                             if (res._id === responseId) {
    //                                 return { ...res, seen: true };
    //                             }
    //                             return res;
    //                         });
    //                         return { ...feedback, responses: updatedResponses };
    //                     } else {
    //                         // Mark the entire feedback as seen
    //                         return { ...feedback, seen: true };
    //                     }
    //                 }
    //                 return feedback;
    //             });
    //             setFeedbacks(updatedFeedbacks);
    //         }
    //     } catch (error) {
    //         console.error('Error marking as seen:', error);
    //     }
    // };


    const handleFeedbackChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setNewFeedback(event.target.value);
    };

    const handleSubmitFeedback = async () => {
        if (!newFeedback.trim() || !newFeedbackTitle.trim()) return;
        try {
            setLoading(true);
            const response = await axios.post<IFeedback>(`${process.env.REACT_APP_API_URL}/notifications/feedback`, {
                title: newFeedbackTitle,
                content: newFeedback
            });
            setFeedbacks([...feedbacks, response.data]);
            await fetchFeedbacks(); // Refresh feedbacks from server
            setNewFeedback('');
            setNewFeedbackTitle('');  // Reset title
            setLoading(false);
        } catch (error) {
            console.error('Error posting feedback:', error);
            setLoading(false);
        }
    };


    const handleExpandClick = async (id: string) => {
        if (expandedFeedbackId === id) {
            setExpandedFeedbackId(null);
        } else {
            setExpandedFeedbackId(id);
            const response = await axios.get<IFeedback>(`${process.env.REACT_APP_API_URL}/notifications/feedback/full/${id}`);
            const updatedFeedbacks = feedbacks.map(f => f._id === id ? response.data : f);
            setFeedbacks(updatedFeedbacks);
            await fetchFeedbacks(); // Refresh feedbacks to include new data when expanded
        }
    };

    const handleResponseChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setResponseText(event.target.value);
    };

    const submitResponse = async (feedbackId: string) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/notifications/feedback/respond`, {
                feedbackId,
                message: responseText
            });
            setResponseText('');
            await fetchFeedbacks(); // Refresh feedbacks after responding
            handleExpandClick(feedbackId);  // Refresh the expanded feedback view
        } catch (error) {
            console.error('Error responding to feedback:', error);
        }
    };

    return (
        <>
            <Typography variant="h6" gutterBottom>User Feedback</Typography>
            <List>
                {feedbacks.map(feedback => (
                    <React.Fragment key={feedback._id}>
                        <ListItem button onClick={() => handleExpandClick(feedback._id)}>
                            <ListItemText primary={feedback.title} secondary={`Created: ${new Date(feedback.createdAt).toLocaleDateString()}`} />
                            {/* {currentUser && currentUser.admin && !feedback.seen && (
                                <Button
                                    color="secondary"
                                    variant="outlined"
                                    onClick={() => markAsSeen(feedback._id, null)}
                                    style={{ marginLeft: 'auto' }}
                                >
                                    Mark Feedback as Seen
                                </Button>
                            )}
                            <Typography
                                component="span"
                                style={{ marginLeft: '10px', fontWeight: 'bold', color: feedback.seen ? 'green' : 'red' }}>
                                {feedback.seen ? 'Seen' : 'Unseen'}
                            </Typography> */}
                        </ListItem>

                        <Collapse in={expandedFeedbackId === feedback._id} timeout="auto" unmountOnExit>

                            <Typography style={{ margin: '10px', padding: '10px' }}>{feedback.content}</Typography>

                            <List component="div" disablePadding>
                                {feedback.responses?.map(response => (
                                    <ListItem key={response._id} style={{
                                        backgroundColor: '#f4f4f9', // Light grayish background
                                        borderLeft: '5px solid #F79010', // Blue left border
                                        marginBottom: '2px',
                                        padding: '10px'
                                    }}>
                                        <ListItemText
                                            primary={response.message}
                                            //@ts-ignore
                                            secondary={`Responded by: ${response.firstname} ${response.lastname} at ${new Date(response.timestamp).toLocaleString()}`}
                                            primaryTypographyProps={{ style: { color: '#333', fontWeight: 'bold' } }}
                                            secondaryTypographyProps={{ style: { color: '#555' } }}

                                        />

                                        {/* {currentUser && currentUser.admin && (
                                            <Button
                                                color="secondary"
                                                variant="outlined"
                                                onClick={() => markAsSeen(feedback._id, response._id)}
                                                disabled={response.seen}
                                            >
                                                Mark as Seen
                                            </Button>
                                        )}
                                        <Typography
                                            component="span"
                                            style={{ marginLeft: '10px', fontWeight: 'bold', color: response.seen ? 'green' : 'red' }}>
                                            {response.seen ? 'Seen' : 'Unseen'}
                                        </Typography> */}
                                    </ListItem>
                                ))}

                                <ListItem>
                                    <TextField
                                        fullWidth
                                        label="Response"
                                        value={responseText}
                                        onChange={handleResponseChange}
                                        variant="outlined"
                                        margin="normal"
                                    />
                                    <Button color="primary" variant="contained" onClick={() => submitResponse(feedback._id)}>Respond</Button>
                                </ListItem>
                            </List>
                        </Collapse>
                    </React.Fragment>
                ))}
            </List>
            <TextField
                fullWidth
                label="Feedback Title"
                value={newFeedbackTitle}
                onChange={(event) => setNewFeedbackTitle(event.target.value)}
                variant="outlined"
                margin="normal"
            />
            <TextField
                fullWidth
                label="New Feedback"
                value={newFeedback}
                onChange={handleFeedbackChange}
                variant="outlined"
                margin="normal"
            />
            <Button
                color="primary"
                variant="contained"
                onClick={handleSubmitFeedback}
                disabled={loading}
            >
                {loading ? 'loading...' : 'Add Feedback'}
            </Button>
        </>
    );
};

export default FeedbackViewer;
