import React, { useRef } from 'react';
import { Box, styled, Tooltip } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArchiveIcon from '@mui/icons-material/Archive';
import DeleteIcon from '@mui/icons-material/Delete';
import MarkunreadIcon from '@mui/icons-material/Markunread';
import SnoozeIcon from '@mui/icons-material/Snooze';
import LabelIcon from '@mui/icons-material/Label';
import ReplyIcon from '@mui/icons-material/Reply';
import ForwardIcon from '@mui/icons-material/Forward';
import { motion } from 'framer-motion';

const ToolbarContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '20px',
    width: '100%',
    maxWidth: '100%',
}));

const Toolbar = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: theme.palette.background.paper,
    padding: '3px 16px',
    borderRadius: '28px',
    boxShadow: '0 4px 20px rgba(0, 0, 0, 0.08)',
    transition: 'all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1)',
    width: '100%',
    '&:hover': {
        boxShadow: '0 8px 30px rgba(0, 0, 0, 0.12)',
    },
}));

const ToolbarButton = styled(motion.div)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    border: 'none',
    borderRadius: '50%',
    width: '40px',
    height: '40px',
    margin: '0 4px',
    cursor: 'pointer',
    position: 'relative',
    overflow: 'visible',
    '&::before': {
        content: '""',
        position: 'absolute',
        top: '50%',
        left: '50%',
        width: '0',
        height: '0',
        backgroundColor: theme.palette.action.hover,
        borderRadius: '50%',
        transform: 'translate(-50%, -50%) scale(0)',
        transition: 'transform 0.3s ease',
    },
    '&:hover::before': {
        transform: 'translate(-50%, -50%) scale(1)',
    },
    '& svg': {
        width: '20px',
        height: '20px',
        fill: theme.palette.text.secondary,
        zIndex: 1,
    },
}));

interface EmailActionsToolbarProps {
    onBack: () => void;
    onArchive: () => void;
    onDelete: () => void;
    onMarkUnread: () => void;
    onSnooze: () => void;
    onLabel: () => void;
    onReply: () => void;
    onForward: () => void;
}

const EmailActionsToolbar: React.FC<EmailActionsToolbarProps> = ({
    onBack,
    onArchive,
    onDelete,
    onMarkUnread,
    onSnooze,
    onLabel,
    onReply,
    onForward,
}) => {
    const toolbarRef = useRef<HTMLDivElement>(null);

    return (
        <ToolbarContainer>
            <Toolbar ref={toolbarRef} role="toolbar" aria-label="Email actions">
                <Box display="flex" alignItems="center">
                    <Tooltip followCursor title="Back to Inbox" arrow>
                        <ToolbarButton
                            whileHover={{ scale: 1.1 }}
                            whileTap={{ scale: 0.9 }}
                            className="toolbar-button"
                            aria-label="Back to Inbox"
                            onClick={onBack}
                        >
                            <ArrowBackIcon />
                        </ToolbarButton>
                    </Tooltip>
                    <Tooltip followCursor title="Archive" arrow>
                        <ToolbarButton
                            whileHover={{ scale: 1.1 }}
                            whileTap={{ scale: 0.9 }}
                            className="toolbar-button"
                            aria-label="Archive"
                            onClick={onArchive}
                        >
                            <ArchiveIcon />
                        </ToolbarButton>
                    </Tooltip>
                    <Tooltip followCursor title="Delete" arrow>
                        <ToolbarButton
                            whileHover={{ scale: 1.1 }}
                            whileTap={{ scale: 0.9 }}
                            className="toolbar-button"
                            aria-label="Delete"
                            onClick={onDelete}
                        >
                            <DeleteIcon />
                        </ToolbarButton>
                    </Tooltip>
                </Box>
                <Box display="flex" alignItems="center">
                    <Tooltip followCursor title="Mark as unread" arrow>
                        <ToolbarButton
                            whileHover={{ scale: 1.1 }}
                            whileTap={{ scale: 0.9 }}
                            className="toolbar-button"
                            aria-label="Mark as unread"
                            onClick={onMarkUnread}
                        >
                            <MarkunreadIcon />
                        </ToolbarButton>
                    </Tooltip>
                    <Tooltip followCursor title="Snooze" arrow>
                        <ToolbarButton
                            whileHover={{ scale: 1.1 }}
                            whileTap={{ scale: 0.9 }}
                            className="toolbar-button"
                            aria-label="Snooze"
                            onClick={onSnooze}
                        >
                            <SnoozeIcon />
                        </ToolbarButton>
                    </Tooltip>
                    <Tooltip followCursor title="Labels" arrow>
                        <ToolbarButton
                            whileHover={{ scale: 1.1 }}
                            whileTap={{ scale: 0.9 }}
                            className="toolbar-button"
                            aria-label="Labels"
                            onClick={onLabel}
                        >
                            <LabelIcon />
                        </ToolbarButton>
                    </Tooltip>
                </Box>
                <Box display="flex" alignItems="center">
                    <Tooltip followCursor title="Reply" arrow>
                        <ToolbarButton
                            whileHover={{ scale: 1.1 }}
                            whileTap={{ scale: 0.9 }}
                            className="toolbar-button"
                            aria-label="Reply"
                            onClick={onReply}
                        >
                            <ReplyIcon />
                        </ToolbarButton>
                    </Tooltip>
                    <Tooltip followCursor title="Forward" arrow>
                        <ToolbarButton
                            whileHover={{ scale: 1.1 }}
                            whileTap={{ scale: 0.9 }}
                            className="toolbar-button"
                            aria-label="Forward"
                            onClick={onForward}
                        >
                            <ForwardIcon />
                        </ToolbarButton>
                    </Tooltip>
                </Box>
            </Toolbar>
        </ToolbarContainer>
    );
};

export default EmailActionsToolbar;
