import React from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { Button, Typography, Container, Box } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

const PaymentSuccessPage = () => {
    const [searchParams] = useSearchParams();
    const sessionId = searchParams.get('session_id');

    return (
        <Container component="main" maxWidth="xs" sx={{ mt: 8 }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <CheckCircleOutlineIcon sx={{ m: 1, fontSize: 60, color: 'success.main' }} />
                <Typography component="h1" variant="h5" sx={{ mb: 2 }}>
                    Payment Successful!
                </Typography>
                <Typography variant="body1" sx={{ textAlign: 'center', mb: 3 }}>
                    Thank you for your purchase. Your payment has been processed successfully.
                </Typography>
                <Link to="/dashboard" style={{ textDecoration: 'none' }}>
                    <Button type="button" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
                        Go to Dashboard
                    </Button>
                </Link>
            </Box>
        </Container>
    );
};

export default PaymentSuccessPage;
