// src\app\pages\0-Account\ProfilePage\ProfileTabs\AdminArea.tsx
import {
    Button,
    FormControl,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography,
} from '@mui/material';
import axios from 'axios';
import React, { useState } from 'react';

interface IRegistrationCode {
    _id: string;
    code: string;
    forUser: string;
    isUsed: boolean;
    creationDate: Date;
    usedDate?: Date;
    userId?: string; // ID of the user who used this code
    userEmail?: string; // Email of the user who used this code
    planType: string; // Type of plan associated with the code
}


export const AdminArea = () => {
    const [numCodes, setNumCodes] = useState('');
    const [forUser, setForUser] = useState('');
    const [planType, setPlanType] = useState('');
    const [registrationCodes, setRegistrationCodes] = useState<IRegistrationCode[]>([]);
    const [fetchError, setFetchError] = useState('');

    const handleGenerateCodes = async () => {
        if (parseInt(numCodes) > 0 && forUser && planType) {
            try {
                const response = await axios.post(`${process.env.REACT_APP_API_URL}/auth/admin/generate-codes`, {
                    numCodes: parseInt(numCodes),
                    forUser,
                    planType,
                });
                setRegistrationCodes(response.data);
                setFetchError('');
            } catch (error: any) {
                setFetchError(error.response?.data?.message || 'Error generating codes');
            }
        } else {
            setFetchError('Please fill all fields correctly.');
        }
    };

    const handleViewCodes = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/auth/admin/view-codes`);
            setRegistrationCodes(response.data);
            setFetchError('');
        } catch (error: any) {
            setFetchError(error.response?.data?.message || 'Error fetching codes');
        }
    };

    return (
        <div style={{ margin: '20px' }}>
            <Typography variant="h4" gutterBottom>Admin Area</Typography>
            <Typography variant="h6" gutterBottom>Generate Registration Codes</Typography>
            <TextField
                label="Number of Codes"
                type="number"
                value={numCodes}
                onChange={(e) => setNumCodes(e.target.value)}
                margin="normal"
            />
            <TextField
                label="User's First Name"
                type="text"
                value={forUser}
                onChange={(e) => setForUser(e.target.value)}
                margin="normal"
            />
            <FormControl fullWidth margin="normal">
                <InputLabel>Plan Type</InputLabel>
                <Select
                    value={planType}
                    label="Plan Type"
                    onChange={(e) => setPlanType(e.target.value)}
                >
                    <MenuItem value="">Select Plan Type</MenuItem>
                    <MenuItem value="trial">Trial</MenuItem>
                    <MenuItem value="professional">Professional</MenuItem>
                    <MenuItem value="master">Master</MenuItem>
                    <MenuItem value="lifetimetier1">Lifetime Tier 1</MenuItem>
                    <MenuItem value="lifetimetier2">Lifetime Tier 2</MenuItem>
                    <MenuItem value="earlyaccess">Early Access</MenuItem>
                </Select>
            </FormControl>
            <Button variant="contained" color="primary" onClick={handleGenerateCodes} style={{ margin: '10px 0' }}>
                Generate Codes
            </Button>
            <Typography variant="h6" gutterBottom>View Registration Codes</Typography>
            <Button variant="contained" color="primary" onClick={handleViewCodes}>
                View Codes
            </Button>
            {fetchError && <Typography color="error">{fetchError}</Typography>}
            {registrationCodes.length > 0 && (
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Code</TableCell>
                                <TableCell align="right">For User</TableCell>
                                <TableCell align="right">Used</TableCell>
                                <TableCell align="right">Creation Date</TableCell>
                                <TableCell align="right">Used Date</TableCell>
                                <TableCell align="right">User ID</TableCell>
                                <TableCell align="right">Plan Type</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {registrationCodes.map((row) => (
                                <TableRow key={row._id}>
                                    <TableCell component="th" scope="row">{row.code}</TableCell>
                                    <TableCell align="right">{row.forUser}</TableCell>
                                    <TableCell align="right">{row.isUsed ? 'Yes' : 'No'}</TableCell>
                                    <TableCell align="right">{row.creationDate.toLocaleString()}</TableCell>
                                    <TableCell align="right">{row.usedDate ? row.usedDate.toLocaleString() : 'N/A'}</TableCell>
                                    <TableCell align="right">{row.userId}</TableCell>
                                    <TableCell align="right">{row.planType}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
        </div>
    );
};
