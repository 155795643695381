export default [
    {
      'title': 'All',
    },
    {
      'title': 'None',
    },
    {
      'title': 'Read',
    },
    {
      'title': 'Unread',
    },
    {
      'title': 'Starred',
    },
    {
      'title': 'Unstarred',
    },
    {
      'title': 'Important',
    },
    {
      'title': 'Unimportant',
    }
  ];