// src\_metronic\partials\layout\theme-mode\ThemeModeSwitcher.tsx
import React from 'react';
import IconButton from '@mui/material/IconButton';
import Brightness4SharpIcon from '@mui/icons-material/Brightness4Sharp'; // Dark mode icon
import Brightness5SharpIcon from '@mui/icons-material/Brightness5Sharp'; // Light mode icon
import BrightnessAutoSharpIcon from '@mui/icons-material/BrightnessAutoSharp'; // Auto mode icon
import { useThemeMode } from './ThemeModeProvider';
import { Box } from '@mui/material';
import GlassIcon from '../../../layout/components/header/GlassIcon/GlassIcon';
const ThemeModeSwitcher = ({ toggleBtnClass = '' }) => {
  const { mode, updateMode } = useThemeMode();

  const toggleThemeMode = () => {
    switch (mode) {
      case 'light':
        updateMode('dark');
        break;
      case 'dark':
        updateMode('system');
        break;
      default:
        updateMode('light');
        break;
    }
  };

  const getModeIcon = () => {
    switch (mode) {
      case 'dark':
        return <Brightness4SharpIcon sx={{
          fontSize: "25px",
          color: 'var(--bs-dark-inverse)'
        }} />

          ;
      case 'light':
        return <Brightness5SharpIcon sx={{
          fontSize: "25px",
          color: 'var(--bs-dark-inverse)'
        }} />;
      default:
        return <BrightnessAutoSharpIcon sx={{
          fontSize: "25px",
          color: 'var(--bs-dark-inverse)'
        }} />;
    }
  };

  return (
    <Box>
      <IconButton
        onClick={toggleThemeMode}
        className={toggleBtnClass}
        size='small'
        sx={{
          backgroundColor: '#793A99',
          '&:hover': {
            backgroundColor: '#F79010', // Set the hover color to the same static color
          },
        }}
      >
        {getModeIcon()}
      </IconButton>
    </Box>
  );
};

export { ThemeModeSwitcher };
