import { FC, useEffect, useState } from 'react';
import { MenuInner } from './MenuInner';
import { SwapperComponent } from '../../../assets/ts/components';

const Header: FC = () => {
  useEffect(() => {
    SwapperComponent.reinitialization();
  }, []);

  // Define the inline styles for the fixed header



  return (
    <div id='kt_header_menu' className='header-menu align-items-stretch'>
      {/* Toggle button */}

      {/* Animated menu */}
      <div
        className='menu menu-lg-rounded menu-column menu-lg-row menu-state-bg menu-title-gray-700 menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-400 fw-bold my-5 my-lg-0 align-items-stretch'
        id='#kt_header_menu'
        data-kt-menu='true'
      >
        <MenuInner />
      </div>
    </div>
  );
};

export { Header };
