// src\app\pages\1-DashBoard\GetStartedInfo\whatIsGetaClientai.tsx
import React from 'react';
import { Box, Typography, Card, CardContent, List, ListItem, ListItemIcon, ListItemText, Divider } from '@mui/material';
import { CircleSharp } from '@mui/icons-material';

const paperairplane = '/getstarted/paperairplane.svg';
const businessmantalking = '/getstarted/businessmantalking.svg';

const WhatIsGetAClientAI: React.FC = () => {
    return (
        <Box sx={{ maxWidth: 600, mx: 'auto', p: 2 }}>
            <Card sx={{ mb: 2, background: "transparent" }}>
                <CardContent>
                    <Typography variant="h4" component="h1" gutterBottom>
                        What is Get a Client.ai?
                    </Typography>
                    <Typography variant="body1" paragraph>
                        Get a Client.ai helps you attract and connect with new customers easily. Here’s how:
                    </Typography>
                    <List>
                        <ListItem style={{ height: '200px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Box
                                component="div"
                                sx={{
                                    width: '200px',
                                    height: '200px',
                                    backgroundImage: `url(${businessmantalking})`,
                                    backgroundSize: 'cover',
                                    backgroundRepeat: 'no-repeat',
                                }}
                            />
                        </ListItem>

                        <ListItem>
                            <ListItemIcon>
                                <CircleSharp sx={{ color: "#747477" }} />
                            </ListItemIcon>
                            <ListItemText
                                primary="Create a list of people who fit your targeted audience"
                                secondary="You can upload a list that you already have or create a list from the databases we provide for you."
                            />
                        </ListItem>
                        <Divider />
                        <ListItem>
                            <ListItemIcon>
                                <CircleSharp sx={{ color: "#747477" }} />
                            </ListItemIcon>
                            <ListItemText
                                primary="Make them interested in what you can help them with"
                                secondary="Our platform provides you with all you need to automatically reach out to them and convey your message an a way that uses the latest modern technologies in a very efficient organic way."
                            />
                        </ListItem>
                        <Divider />
                        <ListItem>
                            <Box
                                component="div"
                                sx={{
                                    width: '100%',
                                    height: '100px',
                                    backgroundImage: `url(${paperairplane})`,
                                    backgroundSize: 'cover',
                                    backgroundRepeat: 'no-repeat',
                                }}
                            />
                        </ListItem>

                    </List>
                </CardContent>
            </Card>

            <Card sx={{ mb: 2, background: "transparent" }}>
                <CardContent>
                    <Typography variant="h5" component="h2" gutterBottom>
                        The Results
                    </Typography>
                    <Typography variant="body1" paragraph>
                        Prepare for a mix of responses, like:
                    </Typography>
                    <List>
                        <ListItem>
                            <ListItemText primary="'Tell me more!'" />
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="'I'm interested!'" />
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="'Not for me, thanks'" />
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="'Please, no more emails'" />
                        </ListItem>
                    </List>
                    <Typography variant="body1" paragraph>
                        Remember, every response counts. We'll handle the 'not interested' folks gracefully, and for those interested, it's a chance to build something great together!
                    </Typography>
                </CardContent>
            </Card>


            <Card sx={{ mb: 2, background: "transparent" }}>
                <CardContent>
                    <Typography variant="body1" paragraph sx={{ mt: 2 }}>
                        Spend less time on outreach and more time talking to interested prospects. Get a Client.ai makes it easy.
                    </Typography>
                </CardContent>
            </Card>
        </Box>
    );
};

export default WhatIsGetAClientAI;
