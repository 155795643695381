// src\app\pages\WarmupV2\Warmup\GlowingFireIcon\LighterCursor.tsx

import { useState, useEffect } from "react";

export const CustomCursor = ({ imgSrc, isVisible }) => {
    const [position, setPosition] = useState({ x: 0, y: 0 });

    useEffect(() => {
        const handleMouseMove = (e) => {
            setPosition({ x: e.clientX, y: e.clientY });
        };

        document.addEventListener('mousemove', handleMouseMove);

        return () => {
            document.removeEventListener('mousemove', handleMouseMove);
        };
    }, []);

    if (!isVisible) return null;

    return (
        <div
            style={{
                position: 'fixed',
                left: position.x,
                top: position.y,
                pointerEvents: 'none', // Ensures the cursor doesn't interfere with other elements
                zIndex: 9999,
                width: '150px', // Set the size you want
                height: '150px',
                backgroundImage: `url(${imgSrc})`,
                backgroundSize: 'cover',
            }}
        />
    );
};



