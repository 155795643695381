// src/components/VariationSettings.tsx
import CustomTooltip from '@/src/app/modules/CustomMaterialUiComponents/CustomTooltip';
import { Box, IconButton, Typography, useTheme } from '@mui/material';
import React, { useState } from 'react';
import Draggable from 'react-draggable';
import { PiHandTapFill } from 'react-icons/pi';

interface Variation {
    label: string;
    percentage: number;
    _id: string;
}

interface VariationSettingsProps {
    variations: Variation[];
    defaultPercentage: number;
    onChange: (value: number[], index: number) => void
}

const VariationSettings: React.FC<VariationSettingsProps> = ({
    variations,
    defaultPercentage,
    onChange,
}) => {
    const initialSegments: Variation[] = [
        { label: 'Default', percentage: defaultPercentage, _id: 'default' },
        ...variations.map(varia => ({ ...varia, color: 'green' })),
    ];

    const [segments, setSegments] = useState(initialSegments);
    const containerRef = React.useRef<HTMLDivElement>(null);
    const [positions, setPositions] = useState<{ [key: string]: { x: number, y: number } }>({});

    const theme = useTheme();

    const adjustPercentage = (index: number, deltaPercentage: number) => {
        let newSegments = [...segments];
        const nextIndex = index + 1;

        if (nextIndex < newSegments.length) {
            // Calculate the new percentages with delta and round them
            let newPercentageForCurrent = Math.round(newSegments[index].percentage + deltaPercentage);
            let newPercentageForNext = Math.round(newSegments[nextIndex].percentage - deltaPercentage);

            // Check if the new percentages are within valid range (0 to 100)
            if (newPercentageForCurrent > 0 && newPercentageForNext > 0 && newPercentageForCurrent <= 100 && newPercentageForNext <= 100) {
                newSegments[index].percentage = newPercentageForCurrent;
                newSegments[nextIndex].percentage = newPercentageForNext;
            }
        }

        setSegments(newSegments);
    };


    const renderSegments = () => {
        let content: JSX.Element[] = [];

        segments.forEach((segment, index) => {
            content.push(
                <CustomTooltip title={`${segment.label}: ${segment.percentage}%`} key={'segment' + segment._id}>
                    <Box
                        sx={{
                            display: 'flex',
                            height: '100%',
                            width: `${segment.percentage}%`,
                            backgroundColor: theme.palette.info.main,
                            justifyContent: 'center',
                            alignItems: 'center',
                            transition: 'width 0.3s ease',
                        }}
                    >
                        <Typography variant="body2" sx={{ color: theme.palette.background.default, fontSize: '1rem' }}>
                            {`${segment.label}: ${segment.percentage}%`}
                        </Typography>
                    </Box>
                </CustomTooltip>
            );

            if (index < segments.length - 1) {
                content.push(
                    <Draggable
                        axis="x"
                        onDrag={(e, data) => {
                            if (containerRef.current) {
                                const containerWidth = containerRef.current.offsetWidth;
                                // Calculate the percentage change based on the container's width
                                const deltaPercentage = (data.deltaX / containerWidth) * 100;
                                adjustPercentage(index, deltaPercentage);
                            }
                        }}
                        key={'draggable' + segment._id}
                        position={{ x: 0, y: 0 }} // Reset position on drag end
                    >
                        <IconButton
                            sx={{ color: 'rgba(0, 0, 0, 0.54)', cursor: 'grab' }}
                        >
                            <PiHandTapFill style={{ fontSize: "30px", color: theme.palette.info.main }} />
                        </IconButton>
                    </Draggable>
                );
            }
        });

        return content;
    };


    return (
        <Box>
            <Typography gutterBottom>Variation Percentages</Typography>
            <Box ref={containerRef} display="flex" alignItems="center" height="40px" mt={2} color={"GrayText"} borderRadius="4px" overflow="hidden">
                {renderSegments()}
            </Box>
        </Box>
    );
};

export default VariationSettings;



