// src/app/pages/1-DashBoard/DashboardFAQs.tsx
import { Box, ImageList, ImageListItem, Paper, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { motion } from 'framer-motion';
import React, { useState } from 'react';
import Typical from 'react-typical';

import DraggableDialog from '../../modules/CustomMaterialUiComponents/DraggableDialog';
import FindNewPeople from './GetStartedInfo/FindPeopleDetails';
import WhatIsGetAClientAI from './GetStartedInfo/whatIsGetaClientai';

const approve = '/getstarted/coffee.svg';
const leadlists = '/getstarted/handwriting.svg';
const Prospecting = '/getstarted/prospecting.svg';
const questionMark = '/getstarted/questionmark.svg';

const DashboardFAQs: React.FC = () => {
    const theme = useTheme();
    const isDarkMode = theme.palette.mode === 'dark';

    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogContent, setDialogContent] = useState<React.ReactNode>(null);
    const [dialogTitle, setDialogTitle] = useState('');
    const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);

    const faqs = [
        {
            question: 'What is Get a Client.ai?',
            icon: questionMark,
            content: <WhatIsGetAClientAI />,
        },
        {
            question: 'How can I make sure people read my messages?',
            icon: leadlists,
            content: '<div></div>',
        },
        {
            question: 'How do I find potential clients?',
            icon: Prospecting,
            content: <FindNewPeople />,
        },
        {
            question: 'How do I start a campaign?',
            icon: approve,
            content: '<div></div>',
        }
        // {
        //     question: 'How can I make sure people read my messages?',
        //     icon: leadlists,
        //     content: <PersonalizeIt />,
        // },

    ];

    const handleOpenDialog = (question: string, content: React.ReactNode) => {
        setDialogTitle(question);
        setDialogContent(content);
        setDialogOpen(true);
    };

    const handleCloseDialog = () => {
        setDialogOpen(false);
        setDialogContent(null);
        setDialogTitle('');
    };

    return (
        <Box sx={{ flexGrow: 1, p: 3 }}>
            <Typography variant="h4" gutterBottom align="center">
                Frequently Asked Questions
            </Typography>
            <ImageList variant="masonry" cols={2} gap={20} sx={{ overflow: 'hidden' }}>
                {faqs.map((faq, index) => (
                    <ImageListItem
                        key={index}
                        onMouseEnter={() => setHoveredIndex(index)}
                        onMouseLeave={() => setHoveredIndex(null)}
                    >
                        <motion.div
                            whileHover={{ scale: 1.05 }}
                            whileTap={{ scale: 0.95 }}
                        >
                            <Paper
                                elevation={1}
                                sx={{
                                    p: 2,
                                    textAlign: 'center',
                                    cursor: 'pointer',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'space-between',
                                    height: 'auto', // Remove fixed height
                                    minHeight: '250px', // Ensure a minimum height
                                    overflow: 'hidden' // Ensure text doesn't overflow the Paper
                                }}
                                onClick={() => handleOpenDialog(faq.question, faq.content)}
                            >
                                <motion.div
                                    style={{ width: '100%', height: '150px' }}
                                    whileHover={{ rotate: 10 }}
                                >
                                    <Box
                                        component="div"
                                        sx={{
                                            width: '100%',
                                            height: '150px',
                                            backgroundImage: `url(${faq.icon})`,
                                            backgroundSize: 'cover',
                                            backgroundRepeat: 'no-repeat',
                                            marginBottom: '16px'
                                        }}
                                    />
                                </motion.div>
                                {hoveredIndex === index ? (
                                    <Typical
                                        steps={[faq.question, 3000]}
                                        loop={1}
                                        wrapper="p"
                                    // fontSize={45}
                                    // fontStyle="inherit"
                                    // fontWeight="inherit"
                                    />
                                ) : (
                                    <Typography variant="h6" gutterBottom>
                                        {faq.question}
                                    </Typography>
                                )}

                            </Paper>
                        </motion.div>
                    </ImageListItem>
                ))}
            </ImageList>
            <DraggableDialog
                open={dialogOpen}
                onClose={handleCloseDialog}
                content={dialogContent}
                title={dialogTitle}
                enableExternalWindow
            />
        </Box>
    );
};

export default DashboardFAQs;
