// src\_metronic\layout\components\header\HeaderWrapper.tsx
import clsx from 'clsx'
import React from 'react';
import { Link } from 'react-router-dom'
import { KTIcon, toAbsoluteUrl } from '../../../helpers'
import { useLayout } from '../../core'
import { Header } from './Header'
import { DefaultTitle } from './page-title/DefaultTitle'
import { Topbar } from './Topbar'
import { useGlassEffect } from './GlassEffectContext';
import "./glass.css";

export function HeaderWrapper() {
  const { config, classes, attributes } = useLayout()
  const { header, aside } = config

  const { isGlassEffectEnabled } = useGlassEffect();

  // Conditional style
  const fixedHeaderStyle: React.CSSProperties = {

    position: 'fixed',
    top: 0,
    width: '100%',
    height: "70px",
    zIndex: 1000,
    // backgroundColor: isGlassEffectEnabled ? 'transparent' : "var(--bs-page-bg)",
    backgroundColor: "var(--bs-page-bg)",
    // borderBottom: isGlassEffectEnabled ? "solid 2px rgba(var(--bs-gray-200-rgb), 0.3)" : "solid 2px rgba(var(--bs-gray-200-rgb), 0.3)",
    backdropFilter: undefined,
    WebkitBackdropFilter: undefined,
    // backdropFilter: isGlassEffectEnabled ? 'blur(100px)' : undefined,
    // WebkitBackdropFilter: isGlassEffectEnabled ? 'blur(100px)' : undefined, // Add this line
    transition: "0.4s",
  };

  return (
    <>

      <div
        id='kt_header'
        className={clsx('header', classes.header.join(' '), 'align-items-stretch')}
        style={fixedHeaderStyle}
        data-kt-sticky='false'
        data-kt-sticky-name='header'
        data-kt-sticky-offset="{default: '200px', lg: '300px'}"
        {...attributes.headerMenu}
      >



        <div className={clsx(classes.headerContainer.join(' '), 'd-flex align-items-center')}>
          {/* begin::Aside mobile toggle */}



          {/* end::Aside mobile toggle */}


          <div style={{ marginLeft: "20px" }} className='header-logo me-5 me-md-10 flex-grow-1 flex-lg-grow-0'>
            {/* <img
              alt='Logo'
              src={toAbsoluteUrl('/media/logos/demo2.svg')}
              className='logo-default h-45px'
            /> */}
            <div
              style={{
                backgroundImage: `url(${toAbsoluteUrl('/media/logos/demo2.svg')})`,
                width: '60px', // Set your desired width
                height: '60px', // Set your desired height
              }}
            ></div>
            {/* <img
              alt='Logo'
              src={toAbsoluteUrl('/media/logos/demo2-sticky.svg')}
              className='logo-sticky h-25px'
            /> */}
          </div>

          {/* begin::Wrapper */}
          <div className='d-flex align-items-stretch justify-content-between flex-lg-grow-1'>
            {/* begin::Navbar */}
            {header.left === 'menu' && (
              <div className='d-flex align-items-stretch' id='kt_header_nav'>
                <Header />
              </div>
            )}



            {header.left === 'page-title' && (
              <div className='d-flex align-items-center' id='kt_header_nav'>
                <DefaultTitle />
              </div>
            )}

            <div className='d-flex align-items-stretch flex-shrink-0'>
              <Topbar />
            </div>

          </div>

          {/* end::Wrapper */}
        </div>

      </div>

    </>
  )
}
