// src\app\pages\WarmupV2\Warmup\DataView\Bubble\BubbleSkeleton.tsx
import React from 'react';
import { Box, Skeleton } from '@mui/material';

const WarmupSkeleton: React.FC = () => {
    return (

        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2, padding: 3, alignContent: "center", alignItems: 'center', justifyContent: "center" }}>

            <Box sx={{
                display: 'flex', flexWrap: 'wrap', gap: 2, padding: 2, justifyContent: 'center',
                alignItems: 'center',
            }}>
                {Array.from(new Array(16)).map((_, index) => (
                    <Skeleton key={index} variant="circular" width={80} height={80} />
                ))}
            </Box>
        </Box>

    );
};

export default WarmupSkeleton;
