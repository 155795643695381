// src\app\modules\auth\core\_requests.ts
import axios from 'axios'
import {AuthModel, User} from './_models'

export const GET_USER_BY_ACCESSTOKEN_URL = `${process.env.REACT_APP_API_URL}/account/profile-as-admin`
export const LOGIN_URL = `${process.env.REACT_APP_API_URL}/auth/login`
export const REGISTER_URL = `${process.env.REACT_APP_API_URL}/auth/register`
export const REQUEST_PASSWORD_URL = `${process.env.REACT_APP_API_URL}/auth/forgot_password`
export const RESET_PASSWORD_URL = `${process.env.REACT_APP_API_URL}/auth/reset_password`
export const REFRESH_TOKEN_URL = `${process.env.REACT_APP_API_URL}/auth/refresh_token`

// Server should return AuthModel
export function login(email: string, password: string) {
  console.log(`Attempting to login with email: ${email}`)
  return axios
    .post<AuthModel>(LOGIN_URL, {
      email,
      password,
    })
    .then((response) => {
      console.log('Login response:', response.data)
      return response
    })
    .catch((error) => {
      console.error('Login error:', error)
      throw error // Re-throw the error for further handling
    })
}

// Server should return AuthModel
export function register(
  email: string,
  firstname: string,
  lastname: string,
  password: string,
  password_confirmation: string
) {
  return axios.post(REGISTER_URL, {
    email,
    firstname: firstname,
    lastname: lastname,
    password,
    password_confirmation,
  })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post<{result: boolean; message?: string}>(REQUEST_PASSWORD_URL, {
    email,
  })
}

// Server should return object => { message: string } (success or error message)
export function resetPassword(token: string, password: string) {
  return axios.post<{message: string}>(RESET_PASSWORD_URL, {
    token,
    password,
  })
}

export function refreshToken() {
  return axios
    .post<{message: string}>(REFRESH_TOKEN_URL)
    .then((response) => {
      console.log('Refresh token response:', response.data)
      return response
    })
    .catch((error) => {
      console.error('Refresh token error:', error)
      throw error // Re-throw the error for further handling
    })
}

export function getUserByToken() {
  return axios.get<User>(GET_USER_BY_ACCESSTOKEN_URL)
}
