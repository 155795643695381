import React from 'react';
import MailListItem from './MailListItem';
import CustomScrollbars from '../util/CustomScrollbars';

const MailList = ({ mails, onMailSelect, onMailChecked, onStartSelect, width }) => {
  const moduleListStyle: React.CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    borderRadius: '8px',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)', // Add elevation
    border: `1px solid `, // Use primary color as border
    padding: "30px",
  };


  const customScrollbarsStyle: React.CSSProperties = {
    height: width >= 1200 ? 'calc(100vh - 259px)' : 'calc(100vh - 238px)',
    overflowY: 'scroll',
    scrollbarWidth: 'thin',
    scrollbarColor: ` transparent`, // Use secondary color for scrollbar

  };

  return (
    <div style={moduleListStyle} className="module-list mail-list">
      <CustomScrollbars
        style={customScrollbarsStyle}
        className="module-list-scroll scrollbar"
      >
        {mails.map((mail, index) => (
          <MailListItem
            key={index}
            mail={mail}
            onMailSelect={onMailSelect}
            onMailChecked={onMailChecked}
            onStartSelect={onStartSelect}
          />
        ))}
      </CustomScrollbars>
    </div>
  );
};

export default MailList;
