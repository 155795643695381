import React from 'react';

const Contacts: React.FC = () => {
    // ... your contacts code here ...

    return (
        <div className="card-body p-0">
            {/*begin::Table*/}
            <table
                className="table table-hover table-row-dashed fs-6 gy-5 my-0"
                id="kt_inbox_listing"
            >
                <thead className="d-none">
                    <tr>
                        <th>Checkbox</th>
                        <th>Actions</th>
                        <th>Author</th>
                        <th>Title</th>
                        <th>Date</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td className="ps-9">
                            <div className="form-check form-check-sm form-check-custom form-check-solid mt-3">
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    defaultValue={1}
                                />
                            </div>
                        </td>
                        <td className="min-w-80px">
                            {/*begin::Star*/}
                            <a
                                href="#"
                                className="btn btn-icon btn-color-gray-400 btn-active-color-primary w-35px h-35px"
                                data-bs-toggle="tooltip"
                                data-bs-placement="right"
                                title="Star"
                            >
                                <i className="ki-duotone ki-star fs-3" />
                            </a>
                            {/*end::Star*/}
                            {/*begin::Important*/}
                            <a
                                href="#"
                                className="btn btn-icon btn-color-gray-400 btn-active-color-primary w-35px h-35px"
                                data-bs-toggle="tooltip"
                                data-bs-placement="right"
                                title="Mark as important"
                            >
                                <i className="ki-duotone ki-save-2 fs-4 mt-1">
                                    <span className="path1" />
                                    <span className="path2" />
                                </i>
                            </a>
                            {/*end::Important*/}
                        </td>
                        <td className="w-150px w-md-175px">
                            <a
                                href="../../demo2/dist/apps/inbox/reply.html"
                                className="d-flex align-items-center text-dark"
                            >
                                {/*begin::Avatar*/}
                                <div className="symbol symbol-35px me-3">
                                    <div className="symbol-label bg-light-danger">
                                        <span className="text-danger">M</span>
                                    </div>
                                </div>
                                {/*end::Avatar*/}
                                {/*begin::Name*/}
                                <span className="fw-semibold">Melody Macy</span>
                                {/*end::Name*/}
                            </a>
                        </td>
                        <td>
                            <div className="text-dark gap-1 pt-2">
                                {/*begin::Heading*/}
                                <a
                                    href="../../demo2/dist/apps/inbox/reply.html"
                                    className="text-dark"
                                >
                                    <span className="fw-bold">
                                        Digital PPV Customer Confirmation
                                    </span>
                                    <span className="fw-bold d-none d-md-inine">-</span>
                                    <span className="d-none d-md-inine text-muted">
                                        Thank you for ordering UFC 240 Holloway vs Edgar Alternate
                                        camera angles...
                                    </span>
                                </a>
                                {/*end::Heading*/}
                            </div>
                            {/*begin::Badges*/}
                            <div className="badge badge-light-primary">inbox</div>
                            <div className="badge badge-light-warning">task</div>
                            {/*end::Badges*/}
                        </td>
                        <td className="w-100px text-end fs-7 pe-9">
                            <span className="fw-semibold">8:30 PM</span>
                        </td>
                    </tr>
                    <tr>
                        <td className="ps-9">
                            <div className="form-check form-check-sm form-check-custom form-check-solid mt-3">
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    defaultValue={1}
                                />
                            </div>
                        </td>
                        <td className="min-w-80px">
                            {/*begin::Star*/}
                            <a
                                href="#"
                                className="btn btn-icon btn-color-gray-400 btn-active-color-primary w-35px h-35px"
                                data-bs-toggle="tooltip"
                                data-bs-placement="right"
                                title="Star"
                            >
                                <i className="ki-duotone ki-star fs-3" />
                            </a>
                            {/*end::Star*/}
                            {/*begin::Important*/}
                            <a
                                href="#"
                                className="btn btn-icon btn-color-gray-400 btn-active-color-primary w-35px h-35px"
                                data-bs-toggle="tooltip"
                                data-bs-placement="right"
                                title="Mark as important"
                            >
                                <i className="ki-duotone ki-save-2 fs-4 mt-1">
                                    <span className="path1" />
                                    <span className="path2" />
                                </i>
                            </a>
                            {/*end::Important*/}
                        </td>
                        <td className="w-150px w-md-175px">
                            <a
                                href="../../demo2/dist/apps/inbox/reply.html"
                                className="d-flex align-items-center text-dark"
                            >
                                {/*begin::Avatar*/}
                                <div className="symbol symbol-35px me-3">
                                    <span
                                        className="symbol-label"
                                        style={{
                                            backgroundImage: "url(assets/media/avatars/300-1.jpg)"
                                        }}
                                    />
                                </div>
                                {/*end::Avatar*/}
                                {/*begin::Name*/}
                                <span className="fw-semibold">Max Smith</span>
                                {/*end::Name*/}
                            </a>
                        </td>
                        <td>
                            <div className="text-dark gap-1 pt-2">
                                {/*begin::Heading*/}
                                <a
                                    href="../../demo2/dist/apps/inbox/reply.html"
                                    className="text-dark"
                                >
                                    <span className="fw-bold">
                                        Your iBuy.com grocery shopping confirmation
                                    </span>
                                    <span className="fw-bold d-none d-md-inine">-</span>
                                    <span className="d-none d-md-inine text-muted">
                                        Please make sure that you have one of the following cards
                                        with you when we deliver your order...
                                    </span>
                                </a>
                                {/*end::Heading*/}
                            </div>
                        </td>
                        <td className="w-100px text-end fs-7 pe-9">
                            <span className="fw-semibold">day ago</span>
                        </td>
                    </tr>
                </tbody>
            </table>
            {/*end::Table*/}
        </div>
    );
};

export default Contacts;
