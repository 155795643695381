import React, { useEffect, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { TwitterPicker } from 'react-color';
import axios from 'axios';
import { Button, Menu, MenuItem, Chip, Divider, Grid, TextField, Stack, IconButton } from '@mui/material';
import LocalOfferSharpIcon from '@mui/icons-material/LocalOfferSharp';
import CloseIcon from '@mui/icons-material/Close';

interface Tag {
    name: string;
    color: string;
}

interface TagsDropdownProps {
    messageId: string;
    existingTags: Tag[];
    selectedAccount: string;
}

const commonTags = [
    { name: 'booked', color: '#FFC0CB' },
    { name: 'interested', color: '#90EE90' },
    { name: 'not interested', color: '#FFA07A' },
];

export const TagsDropdown: React.FC<TagsDropdownProps> = ({ messageId, existingTags, selectedAccount }) => {
    const queryClient = useQueryClient();
    const [tags, setTags] = useState<Tag[]>(existingTags);
    const [newTagName, setNewTagName] = useState('');
    const [newTagColor, setNewTagColor] = useState('#FFF');
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    useEffect(() => {
        // Update the tags state whenever the existingTags prop changes
        setTags(existingTags);
    }, [existingTags]); // Re-run the effect when existingTags changes


    const handleAddTagMutation = async ({ tagName, tagColor }: { tagName: string; tagColor: string }) => {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/inbox/messages/addTag`, {
            messageId,
            tagName,
            tagColor,
        });
        return response.data;
    };

    const handleRemoveTagMutation = async (tagName: string) => {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/inbox/messages/removeTag`, {
            messageId,
            tagName,
        });
        return response.data;
    };

    // Inside TagsDropdown component:
    const addTag = useMutation(handleAddTagMutation, {
        onSuccess: () => {
            // Here we invalidate the query for all emails to ensure the email list is updated
            queryClient.invalidateQueries(['items', selectedAccount]);
        },
    });

    const removeTag = useMutation(handleRemoveTagMutation, {
        onSuccess: () => {
            // Similarly, invalidate the query for all emails when a tag is successfully removed
            queryClient.invalidateQueries(['items', selectedAccount]);
        },
    });


    const handleAddTag = (tagName: string, tagColor: string) => {
        addTag.mutate({ tagName, tagColor });
    };

    const handleRemoveTag = (tagName: string) => {
        removeTag.mutate(tagName);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    // Utility function to determine if the color is light or dark
    function isLight(color) {
        const hex = color.replace('#', '');
        const r = parseInt(hex.substr(0, 2), 16);
        const g = parseInt(hex.substr(2, 2), 16);
        const b = parseInt(hex.substr(4, 2), 16);
        // Using the YIQ equation to determine the brightness of the color
        const yiq = (r * 299 + g * 587 + b * 114) / 1000;
        return yiq >= 128; // Light colors will return true, dark colors will return false
    }


    return (
        <div>
            <Stack direction="row" spacing={1} marginBottom={2}>
                {tags.map((tag) => (
                    <Chip
                        key={tag.name}
                        label={tag.name}
                        onDelete={() => handleRemoveTag(tag.name)}
                        deleteIcon={<CloseIcon />}
                        style={{ backgroundColor: tag.color, color: isLight(tag.color) ? 'black' : 'white', }}
                    />
                ))}
            </Stack>
            <IconButton aria-label="tags" aria-controls="tags-menu" aria-haspopup="true" onClick={handleClick}>
                <LocalOfferSharpIcon />
            </IconButton>
            <Menu id="tags-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
                {commonTags.map((tag) => (
                    <MenuItem key={tag.name} onClick={() => handleAddTag(tag.name, tag.color)}>
                        <Chip label={tag.name} style={{ backgroundColor: tag.color, color: 'white' }} />
                    </MenuItem>
                ))}
                <Divider />
                <MenuItem disableRipple  >
                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                value={newTagName}
                                onChange={(e) => setNewTagName(e.target.value)}
                                placeholder="Custom Tag Name"
                                variant="standard"
                                onKeyDown={(e) => {
                                    // Stop the propagation for specific key presses, if necessary
                                    // For example, to prevent propagation for all keys, just call e.stopPropagation();
                                    // Adjust the condition below according to your needs
                                    e.stopPropagation();
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TwitterPicker width={450} color={newTagColor} onChangeComplete={(color) => setNewTagColor(color.hex)} />
                        </Grid>
                        <Grid item xs={12}>
                            <Button color="info" fullWidth onClick={() => handleAddTag(newTagName, newTagColor)} variant="text">Add Custom Tag</Button>
                        </Grid>
                    </Grid>
                </MenuItem>

            </Menu>
        </div>
    );
};
