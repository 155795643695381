// src\app\pages\LeadGenAdvanced\DetailedView\TabPanelComponent.tsx
import React from 'react';
import { Box, Typography } from '@mui/material';

const TabPanelComponent = ({ value, index, children }) => {
    return (
        <div role="tabpanel" hidden={value !== index}>
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
};

export default TabPanelComponent;
