// src\app\pages\4-Campaigns\3-CampaignSequence\LiquidSyntax\PersonalizationPlayground.tsx
import CustomTooltip from '@/src/app/modules/CustomMaterialUiComponents/CustomTooltip';
import { FileCopy } from '@mui/icons-material';
import { Box, Button, FormControl, IconButton, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import axios from 'axios';
import { CheckCircleOutline } from 'mdi-material-ui';
import React, { useEffect, useState } from 'react';

interface Lead {
    leadId: string;
    name: string;
}

interface Props {
    campaignId: string;
}

const timezones = [
    { name: "Tokyo (Japan)", identifier: "Asia/Tokyo" },
    { name: "Dubai (United Arab Emirates)", identifier: "Asia/Dubai" },
    { name: "Mumbai (India)", identifier: "Asia/Kolkata" },
    { name: "London (United Kingdom)", identifier: "Europe/London" },
    { name: "Berlin (Germany)", identifier: "Europe/Berlin" },
    { name: "Istanbul (Turkey)", identifier: "Europe/Istanbul" },
    { name: "New York (USA)", identifier: "America/New_York" },
    { name: "Chicago (USA)", identifier: "America/Chicago" },
    { name: "Denver (USA)", identifier: "America/Denver" },
    { name: "Los Angeles (USA)", identifier: "America/Los_Angeles" },
    { name: "Sydney (Australia)", identifier: "Australia/Sydney" },
    { name: "Auckland (New Zealand)", identifier: "Pacific/Auckland" },
    { name: "São Paulo (Brazil)", identifier: "America/Sao_Paulo" },
    { name: "Shanghai (China)", identifier: "Asia/Shanghai" },
    { name: "Jerusalem (Israel)", identifier: "Asia/Jerusalem" },
    { name: "Cairo (Egypt)", identifier: "Africa/Cairo" },
    { name: "Johannesburg (South Africa)", identifier: "Africa/Johannesburg" },
    { name: "Moscow (Russia)", identifier: "Europe/Moscow" },
    { name: "Seoul (South Korea)", identifier: "Asia/Seoul" },
    { name: "Jakarta (Indonesia)", identifier: "Asia/Jakarta" }
];

const PersonalizationPlayground: React.FC<Props> = ({ campaignId }) => {
    const [leads, setLeads] = useState<Lead[]>([]);
    const [selectedLeadId, setSelectedLeadId] = useState<string>('');
    const [sampleMessage, setSampleMessage] = useState<string>('');
    const [personalizedMessage, setPersonalizedMessage] = useState<string>('');
    const [timezone, setTimezone] = useState<string>('');
    const [sendingTime, setSendingTime] = useState<string>('');
    const [copied, setCopied] = useState<boolean>(false);

    useEffect(() => {
        const fetchLeads = async () => {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/campaign/personalization/sample-leads/${campaignId}`);
            setLeads(response.data);
        };

        fetchLeads();
    }, [campaignId]); // Added campaignId as a dependency


    const handleSubmit = async () => {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/campaign/personalization/test`, {
            leadId: selectedLeadId,
            campaignId,
            sampleMessage,
            timezone,
            sendingTimeISO: sendingTime
        });
        setPersonalizedMessage(response.data.personalizedMessage);
    };

    const copyToClipboard = (text: string) => {
        navigator.clipboard.writeText(text).then(() => {
            setCopied(true);
            setTimeout(() => setCopied(false), 3000); // Reset after 3 seconds
        });
    };


    return (
        <div>
            <FormControl fullWidth>
                <InputLabel id="lead-select-label">Select Lead</InputLabel>
                <Select
                    labelId="lead-select-label"
                    value={selectedLeadId}
                    label="Select Lead"
                    onChange={(e) => setSelectedLeadId(e.target.value as string)}
                >
                    {leads.map((lead) => (
                        <MenuItem key={lead.leadId} value={lead.leadId}>{lead.name}</MenuItem>
                    ))}
                </Select>
            </FormControl>
            <TextField
                label="Sample Message"
                fullWidth
                margin="normal"
                value={sampleMessage}
                onChange={(e) => setSampleMessage(e.target.value)}
            />
            <FormControl fullWidth margin="normal">
                <InputLabel id="timezone-label">Timezone</InputLabel>
                <Select
                    labelId="timezone-label"
                    value={timezone}
                    onChange={(e) => setTimezone(e.target.value)}
                >
                    {timezones.map((zone) => (
                        <MenuItem key={zone.identifier} value={zone.identifier}>{zone.name}</MenuItem>
                    ))}
                </Select>
            </FormControl>
            <TextField
                label="Sending Time"
                type="datetime-local"
                fullWidth
                margin="normal"
                InputLabelProps={{
                    shrink: true,
                }}
                value={sendingTime}
                onChange={(e) => setSendingTime(e.target.value)}
            />
            <Button onClick={handleSubmit} variant="contained" style={{ marginTop: '10px' }}>Test Personalization</Button>
            {personalizedMessage && (
                <Box style={{ marginTop: '10px', width: '100%', maxHeight: '200px', overflow: 'auto' }}>
                    <strong>Personalized Message:</strong>
                    <div>{personalizedMessage}</div>
                    <CustomTooltip title="Copy to clipboard">
                        <IconButton onClick={() => copyToClipboard(personalizedMessage)} size="large">
                            {copied ? <CheckCircleOutline color="success" /> : <FileCopy />}
                        </IconButton>
                    </CustomTooltip>
                </Box>
            )}
        </div>
    );
};

export default PersonalizationPlayground;
