//src\app\pages\0-Account\EmailAccounts\ManageEmailAccounts\EmailAccountsParent.tsx
import { HealthAndSafetySharp } from '@mui/icons-material';
import SettingsIcon from '@mui/icons-material/Settings';
import WhatshotIcon from '@mui/icons-material/Whatshot';
import { Divider, Grid, Snackbar, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import axios from 'axios';
import { motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';

import DraggableDialog from '../../../../modules/CustomMaterialUiComponents/DraggableDialog';
import MenuBarDivider from '../../../../modules/CustomMaterialUiComponents/MenuBarDivider';
import WarmupParentComponent from '../../../WarmupV2/WarmupParent';
import EmailAccountsPageTopMenu from '../EmailAccountsTopMenu';
import EmailConnectionModal from '../EmailConnection/ConnectionForm/EmailConnectionModal';
import EmailConnectionModalMulti from '../EmailConnection/ConnectionForm/EmailConnectionModal-Multi';
import LinkTrackingDomains from '../LinkTrackingDomains';
import UpdateAccountNames from '../NameEditorComponent';
import SignatureComponent from '../SignatureEditor';
import { Account } from './AccountInterface';
import EmailAccountDetails from './EmailAccountDetails';
import EmailAccountsList from './EmailAccountsList';

const containerVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1, transition: { duration: 0.2, staggerChildren: 0.1 } },
};

const childVariants = {
    hidden: { opacity: 0, x: -50 },
    visible: { opacity: 1, x: 0, transition: { type: 'spring', duration: 0.4, damping: 7, stiffness: 200 } },
};

const EmailAccountsParent = () => {
    const [accounts, setAccounts] = useState<Account[]>([]);
    const [selectedAccountId, setSelectedAccountId] = useState<string>('');
    const [showConnectionModal, setShowConnectionModal] = useState(false);
    const [showMultipleAccountsDialog, setShowMultipleAccountsDialog] = useState(false);
    const [listItemHeight, setListItemHeight] = useState('normal');
    const [modalShow, setModalShow] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [openEditNamesDialog, setOpenEditNamesDialog] = useState(false);
    const [openEditSignaturesDialog, setEditSignaturesDialog] = useState(false);
    const [openLinkTrackingDialog, setOpenLinkTrackingDialog] = useState(false); // New state variable
    const [view, setView] = useState<'settings' | 'warmup'>('settings');

    const toggleEditNAmesDialog = () => setOpenEditNamesDialog(!openEditNamesDialog);
    const toggleEditSignaturesDialog = () => setEditSignaturesDialog(!openEditSignaturesDialog);
    const toggleLinkTrackingDialog = () => setOpenLinkTrackingDialog(!openLinkTrackingDialog); // New toggle function

    const selectedAccount = accounts.find(account => account._id === selectedAccountId);

    const [checkedAccountIds, setCheckedAccountIds] = useState<string[]>([]);
    const selectedAccountEmails = accounts.filter(account => checkedAccountIds.includes(account._id)).map(account => account.sendingAccount);

    const handleNewAccountOpen = () => setModalShow(true);
    const handleConnectMultipleOpen = () => setShowMultipleAccountsDialog(true);
    const handleCompactView = () => setListItemHeight('compact');
    const handleLargeView = () => setListItemHeight('large');
    const handleCheckedAccountsChange = (checkedAccountIds: string[]) => setCheckedAccountIds(checkedAccountIds);

    useEffect(() => {
        fetchAccounts();
    }, []);

    const fetchAccounts = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/account/accounts`);
            setAccounts(response.data.connectedAccounts as Account[]);
        } catch (error) {
            console.error('Error fetching accounts:', error);
        }
    };

    const handleListItemClick = (accountId: string) => setSelectedAccountId(accountId);
    const handleRefresh = () => fetchAccounts();
    const handleSnackbarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') return;
        setSnackbarOpen(false);
    };

    const onDeletionSuccess = (message: string) => {
        setSnackbarMessage(message);
        setSnackbarOpen(true);
    };

    const onDeletionFailure = (message: string) => {
        setSnackbarMessage(message);
        setSnackbarOpen(true);
    };

    const springVariants = {
        hidden: { scale: 0, opacity: 0 },
        show: { scale: 1, opacity: 1, transition: { type: 'spring', stiffness: 400, damping: 100 } }
    };

    const renderWhenNoAccountSelected = (
        <Grid
            style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '20px',
                cursor: 'pointer',
                height: 'auto',
                marginTop: "30px"
            }}
        >
            <HealthAndSafetySharp
                color='secondary'
                sx={{
                    fontSize: "340px",
                    padding: "15px",
                    borderRadius: "0%",
                    transition: "1s",
                    "&:hover": {
                        fontSize: "320px",
                        opacity: 0.7
                    }
                }}
            />
            <Typography variant="body1" sx={{ textAlign: 'center', marginTop: 1, color: "#793A99" }}>
                Here you will see your connected accounts for email warmup.
                This page allows you to manage and activate email warmup.
            </Typography>
        </Grid>
    );

    return (
        <>
            <EmailAccountsPageTopMenu
                onNewAccount={handleNewAccountOpen}
                onConnectMultiple={handleConnectMultipleOpen}
                onSignatureEdit={toggleEditSignaturesDialog}
                onCompactView={handleCompactView}
                onLargeView={handleLargeView}
                RefreshAccountList={fetchAccounts}
                onChangeNames={toggleEditNAmesDialog}
                onLinkTracking={toggleLinkTrackingDialog}
            />
            <MenuBarDivider />
            <ToggleButtonGroup
                value={view}
                exclusive
                fullWidth
                onChange={(_, newView) => {
                    if (newView !== null) {
                        setView(newView);
                    }
                }}
                sx={{ marginBottom: 2, justifyContent: 'center' }}
            >
                <ToggleButton
                    value="settings"
                    aria-label="settings"
                    sx={{
                        fontSize: '24px',
                        color: 'info.main',
                        height: '60px',
                        borderRadius: 0,
                        '&.Mui-selected': {
                            color: 'info.main',
                        },
                        borderBottom: 0,
                        borderTop: 0,
                    }}
                >
                    <SettingsIcon sx={{ fontSize: '32px' }} />
                </ToggleButton>
                <ToggleButton
                    value="warmup"
                    aria-label="warmup"
                    sx={{
                        fontSize: '24px',
                        color: 'info.main',
                        height: '60px',
                        borderRadius: 0,
                        '&.Mui-selected': {
                            color: 'info.main',
                        },
                        borderBottom: 0,
                        borderTop: 0,
                    }}
                >
                    <WhatshotIcon sx={{ fontSize: '32px' }} />
                </ToggleButton>
            </ToggleButtonGroup>
            <Divider />
            <motion.div
                className="no-global-transition"
                variants={containerVariants}
                initial="hidden"
                animate="visible"
                exit="hidden"
            >
                <Grid container spacing={2} justifyContent="center">
                    <EmailConnectionModal
                        show={modalShow}
                        onHide={() => setModalShow(false)}
                        RefreshAccountList={fetchAccounts}
                    />
                    <EmailConnectionModalMulti
                        show={showMultipleAccountsDialog}
                        onHide={() => setShowMultipleAccountsDialog(false)}
                        RefreshAccountList={fetchAccounts}
                    />
                    <DraggableDialog
                        open={openEditSignaturesDialog}
                        onClose={toggleEditSignaturesDialog}
                        content={
                            <SignatureComponent
                                selectedAccountEmails={selectedAccountEmails}
                                accountIds={checkedAccountIds}
                            />
                        }
                        title="Change Sender Names"
                        showCloseWarning
                        closeWarningMessage='Unsaved Changes Will Be Lost'
                    />
                    <DraggableDialog
                        open={openEditNamesDialog}
                        onClose={toggleEditNAmesDialog}
                        content={
                            <UpdateAccountNames
                                selectedAccountEmails={selectedAccountEmails}
                                accountIds={checkedAccountIds}
                                RefreshAccountList={fetchAccounts}
                            />
                        }
                        title="Change Sender Names"
                        showCloseWarning
                        closeWarningMessage='Unsaved Changes Will Be Lost'
                    />
                    <DraggableDialog
                        open={openLinkTrackingDialog}
                        onClose={toggleLinkTrackingDialog}
                        content={
                            <LinkTrackingDomains
                                selectedAccountEmails={selectedAccountEmails}
                                accountIds={checkedAccountIds}
                            />
                        }
                        title="Link Tracking Domains"
                        showCloseWarning
                        closeWarningMessage='Unsaved Changes Will Be Lost'
                        width={1300}
                        height={700}
                    />
                    <Grid item xs={4}>
                        <motion.div
                            className="no-global-transition"
                            variants={childVariants}>
                            <EmailAccountsList
                                accounts={accounts}
                                selectedAccountId={selectedAccountId}
                                handleListItemClick={handleListItemClick}
                                listItemHeight={listItemHeight}
                                onCheckedAccountsChange={handleCheckedAccountsChange}
                                onDeletionSuccess={onDeletionSuccess}
                                onDeletionFailure={onDeletionFailure}
                                refreshAccountsList={fetchAccounts}
                            />
                        </motion.div>
                    </Grid>
                    <Grid item xs={8}>
                        <motion.div
                            className="no-global-transition"
                            variants={childVariants}>
                            {view === 'settings' ? (
                                <EmailAccountDetails
                                    account={selectedAccount || null}
                                    refreshAccountsList={fetchAccounts}
                                    toggleLinkTrackingDialog={toggleLinkTrackingDialog}
                                />
                            ) : (
                                <>
                                    {selectedAccount ? (
                                        <WarmupParentComponent selectedAccount={selectedAccount?._id.toString()} />
                                    ) : (
                                        <motion.div
                                            className="no-global-transition"
                                            initial="hidden"
                                            animate="show"
                                            variants={springVariants}
                                        >
                                            {renderWhenNoAccountSelected}
                                        </motion.div>

                                    )}
                                </>
                            )}
                        </motion.div>
                    </Grid>
                </Grid>
                <Snackbar
                    open={snackbarOpen}
                    autoHideDuration={6000}
                    onClose={handleSnackbarClose}
                    message={snackbarMessage}
                />
            </motion.div>
        </>
    );
};

export default EmailAccountsParent;
