// src\app\pages\4-Campaigns\2-CampaignLeads\CampaignLeadsPreview.tsx
import { HelpSharp } from '@mui/icons-material';
import CancelSharp from '@mui/icons-material/CancelSharp';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import EmailSharp from '@mui/icons-material/EmailSharp';
import ErrorIcon from '@mui/icons-material/Error';
import PhoneIcon from '@mui/icons-material/Phone';
import { Badge, Box, Paper, Rating, Skeleton, Typography } from '@mui/material';
import { DataGridPremium, GridColDef } from '@mui/x-data-grid-premium';
import axios from 'axios';
import React, { useEffect, useState } from 'react';

import { Lead, TableData } from '../../3-CRM/CRM-Table-Interfaces';


interface LeadsGridProps {
    selectedListId: string;
}

const LeadsGrid: React.FC<LeadsGridProps> = ({ selectedListId }) => {
    const [leads, setLeads] = useState<Lead[]>([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [openDetailPanelRowIds, setOpenDetailPanelRowIds] = React.useState([]);

    const [Data, setData] = useState<TableData>({
        listName: '',
        listDetails: {
            _id: '',
            userId: '',
            name: '',
            headers: [],
            __v: 0,
        },
        leads: [],
        loading: true,
    });

    const toggleDetailPanel = (rowId) => {
        // Check if the rowId is already in the array
        //@ts-ignore
        const isRowCurrentlyOpen = openDetailPanelRowIds.includes(rowId);
        const newOpenDetailPanelRowIds = isRowCurrentlyOpen
            ? openDetailPanelRowIds.filter(id => id !== rowId) // Remove rowId if it's already open
            : [...openDetailPanelRowIds, rowId]; // Add rowId if it's not open
        //@ts-ignore

        setOpenDetailPanelRowIds(newOpenDetailPanelRowIds);
    };


    const handleRowDoubleClick = (params) => {
        //@ts-ignore
        const newOpenDetailPanelRowIds = openDetailPanelRowIds.includes(params.id)
            ? openDetailPanelRowIds.filter((id) => id !== params.id)
            : [...openDetailPanelRowIds, params.id];
        //@ts-ignore

        setOpenDetailPanelRowIds(newOpenDetailPanelRowIds);
    };

    const fetchData = async () => {
        if (selectedListId) {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/leads/lists/leads/${selectedListId}`);
                setData({ ...response.data, loading: false });
            } catch (error: any) {
                console.error('Error fetching table data:', error);
                setError(error.response?.data?.message || 'An unknown error occurred'); // Displaying specific error message from Axios
                setData({
                    listName: '', // Assuming you want to reset this as well
                    listDetails: {
                        _id: '',
                        userId: '',
                        name: '',
                        headers: [], // This now matches the expected Header[] type
                        __v: 0,
                    },
                    leads: [],
                    loading: false
                });


            }
        }
    };

    useEffect(() => {
        if (selectedListId) {
            setLoading(true);
            setError('');
            axios.get(`${process.env.REACT_APP_API_URL}/leads/lists/leads/${selectedListId}`)
                .then(response => {
                    setLeads(response.data.leads);
                    setData({ ...response.data, loading: false });

                    setLoading(false);
                })
                .catch(error => {
                    console.error('Error fetching leads:', error);
                    setData({
                        listName: '', // Assuming you want to reset this as well
                        listDetails: {
                            _id: '',
                            userId: '',
                            name: '',
                            headers: [], // This now matches the expected Header[] type
                            __v: 0,
                        },
                        leads: [],
                        loading: false
                    });


                    setError('Failed to fetch leads');
                    setLoading(false);
                });
        }
    }, [selectedListId]);

    useEffect(() => {
        console.log("Headers:", Data.listDetails.headers); // Check what headers are available
        console.log("Leads:", leads); // Inspect the leads data structure
    }, [Data, leads]);


    const generateColumns = (): GridColDef[] => {

        // Initialize baseColumns array to store the column definitions
        let baseColumns = Data.listDetails.headers.map(header => {
            console.log('Generating column for:', header.name);  // Debug statement


            // Special handling for 'Emails' and 'Phones'
            // Email Column Configuration
            if (header.type === 'email') {
                return {
                    field: header.name,
                    headerName: header.name,
                    filterable: false,
                    disableColumnGrouping: true,
                    disableColumnMenu: true,
                    disableColumnPinning: true,
                    sortable: false,

                    renderCell: (params) => {
                        const total = params.value.length;
                        const validCount = params.value.filter(email => email.validationStatus === 'valid').length;
                        const invalidCount = params.value.filter(email => email.validationStatus === 'invalid').length;
                        const unknownCount = params.value.filter(email => email.validationStatus === 'unknown').length;
                        return (
                            <>
                                {total > 0 && (
                                    <Badge badgeContent={total} color="primary" sx={{ mr: 1 }} />
                                )}
                                {validCount > 0 && (
                                    <CheckCircleIcon color="success" sx={{ ml: 0.5 }} />
                                )}
                                {invalidCount > 0 && (
                                    <CancelSharp color="error" sx={{ ml: 0.5 }} />
                                )}
                                {unknownCount > 0 && (
                                    <HelpSharp color="warning" sx={{ ml: 0.5 }} />
                                )}
                            </>
                        );
                    },
                };
            } else if (header.type === 'phone') {
                return {
                    field: header.name,
                    headerName: header.name,
                    filterable: false,
                    disableColumnGrouping: true,
                    disableColumnMenu: true,
                    disableColumnPinning: true,
                    sortable: false,
                    renderCell: (params) => {
                        const total = params.value.length;
                        const validCount = params.value.filter(phone => phone.validationStatus === 'valid').length;
                        const invalidCount = params.value.filter(phone => phone.validationStatus === 'invalid').length;
                        const unknownCount = params.value.filter(phone => phone.validationStatus === 'unknown').length;
                        return (
                            <>
                                {total > 0 && (
                                    <Badge badgeContent={total} color="primary" sx={{ mr: 1 }} />
                                )}
                                {validCount > 0 && (
                                    <CheckCircleIcon color="success" sx={{ ml: 0.5 }} />
                                )}
                                {invalidCount > 0 && (
                                    <CancelSharp color="error" sx={{ ml: 0.5 }} />
                                )}
                                {unknownCount > 0 && (
                                    <HelpSharp color="warning" sx={{ ml: 0.5 }} />
                                )}
                            </>
                        );

                    },
                };
            } else if (header.name === 'rating') {
                // Specific handling for 'rating' if needed
                return {
                    field: header.name,
                    headerName: header.name,
                    renderCell: (params: { value: string; }) => (
                        <Rating value={params.value ? parseFloat(params.value) : 0} precision={0.1} readOnly />
                    ),
                    // 'rating' is not editable, rendered as is
                };
            } else if (header.type === 'timezone') {
                // Specific handling for 'rating' if needed
                return {
                    field: 'timezone',
                    headerName: 'Timezone',
                    renderCell: (params) => {
                        return params.value ? (
                            <CheckCircleIcon style={{ color: 'green' }} />
                        ) : (
                            <CancelSharp style={{ color: 'red' }} />
                        );
                    }
                };
            } else if (header.type === 'icebreaker') {
                // Specific handling for 'rating' if needed
                return {
                    field: header.name,
                    headerName: header.name,
                    editable: false,
                };
            } else {

                // General case for other fields
                return {
                    field: header.name,

                    headerName: header.name.replace(/_/g, ' ').replace(/^\w/, c => c.toUpperCase()),
                    disableColumnGrouping: true,  // Disables grouping for this column
                    disableColumnMenu: false,  // Disables the column menu, often used for pinning and other column options
                    disableColumnPinning: true,  // Disables the ability to pin the column
                    editable: false,

                    filterable: true,

                    // Making fields editable by default, adjust as necessary
                };
            }
        });
        const detailPanelToggleColumn = {
            field: '__detail_panel_toggle__',
            headerName: '',
            width: 0,
            renderCell: () => <div></div>,
        };
        baseColumns.push(detailPanelToggleColumn);

        //@ts-ignore
        return baseColumns;
    };


    // Use the generated columns for the DataGrid
    const columns = generateColumns();

    return (
        <div style={{ height: 400, width: '100%' }}>
            {loading ? (
                <Skeleton variant="rectangular" width="100%" height="100%" />
            ) : error ? (
                <ErrorDisplay error={error} clearError={() => setError('')} />
            ) : (
                <DataGridPremium
                    rows={leads}
                    columns={columns}
                    disableRowSelectionOnClick

                    getRowId={(row) => row._id} // Use the _id property as the unique identifier for each row
                    loading={loading}

                    pagination
                    //@ts-ignore
                    pageSize={10}
                    pageSizeOptions={[10, 100, { value: 1000, label: '1,000' }]}


                    getDetailPanelContent={({ row }) => <DetailPanelContent row={row} />}
                    getDetailPanelHeight={({ row }) => 'auto'}
                    onRowDoubleClick={handleRowDoubleClick}
                    detailPanelExpandedRowIds={openDetailPanelRowIds}
                    sx={{ border: "0px", height: "530px", '--DataGrid-overlayHeight': '300px' }}

                />
            )}
        </div>
    );
};

export default LeadsGrid;


const DetailPanelContent = ({ row }) => {
    // Unified columns definition
    const columns = [
        {
            field: 'value',
            headerName: '',
            width: 200,
            renderCell: (params) => (
                <>
                    {params.row.itemType === 'email' ? (
                        <EmailSharp color="primary" sx={{ mr: 1 }} />
                    ) : (
                        <PhoneIcon color="secondary" sx={{ mr: 1 }} />
                    )}
                    {params.row.value}
                </>
            ),
        },
        {
            field: 'type',
            headerName: 'Type',
            width: 120,
        },
        {
            field: 'validated',
            headerName: 'Validated',
            width: 100,
            renderCell: (params) => (
                params.value ? <CheckCircleIcon color="success" /> : <CancelSharp color="error" />
            ),
        },
        {
            field: 'validationstatus',
            headerName: 'Validation Status',
            width: 100,
        },
    ];

    // Combine Emails and Phones into one array
    const combinedRows = [
        ...row.Emails.map((email, index) => ({
            id: `email-${index}`,
            value: email.emailaddress,
            type: email.type, // This remains as is, representing the type of email
            validated: email.validated,
            validationstatus: email.validationStatus,
            itemType: 'email' // Add itemType to distinguish between emails and phones
        })),
        ...row.Phones.map((phone, index) => ({
            id: `phone-${index}`,
            value: phone.number,
            type: phone.type, // This remains as is, representing the type of phone
            validated: phone.validated,
            validationstatus: phone.validationStatus,
            itemType: 'phone' // Add itemType to distinguish between emails and phones
        })),
    ];


    return (
        <Box sx={{ margin: 2 }}>
            <DataGridPremium
                rows={combinedRows}
                columns={columns}
                autoHeight
                hideFooter
                sx={{ border: "0px" }}
            />
        </Box>
    );
};



const ErrorDisplay = ({ error, clearError }) => (
    <Paper elevation={6} sx={{ p: 2, bgcolor: 'secondary.main', color: 'white', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Typography variant="body1" sx={{ color: "white", display: 'flex', alignItems: 'center' }}>
            <ErrorIcon sx={{ color: "white", mr: 1 }} />
            {error}
        </Typography>
        {/* <IconButton onClick={clearError} sx={{ color: 'white' }}>
            <CloseIcon />
        </IconButton> */}
    </Paper>
);
