import React from 'react';
import {
  HeaderUserMenu,
  ThemeModeSwitcher,
} from '../../../partials';
// import CrispChatDialog from './ChatComponnet';
import RandomTipButtom from "./RandomTipButton"
import { FacebookSharp } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import FeedbackManager from './FeedBackManager/FeedbackManager';
const Topbar: React.FC = () => {

  return (
    <div className='d-flex align-items-stretch flex-shrink-0'>
      <div className='topbar d-flex align-items-stretch flex-shrink-0'>

        {/* Feedback Manager */}
        <div className='d-flex align-items-center ms-1 ms-lg-3'>
          <FeedbackManager />
        </div>


        <div className='d-flex align-items-center ms-1 ms-lg-3'>
          <a href="https://www.facebook.com/groups/949179640032237" target="_blank" rel="noopener noreferrer" title="Join our Facebook Group">

            <IconButton
              size='small'
            // sx={{
            //   backgroundColor: '#793A99',
            //   '&:hover': {
            //     backgroundColor: '#F79010', // Set the hover color to the same static color
            //   },
            // }}
            >
              <FacebookSharp sx={{
                fontSize: "25px", color: '#793A99',
                '&:hover': {
                  color: '#F79010', // Set the hover color to the same static color
                },
              }} />
            </IconButton>

          </a>
        </div>

        <div className='d-flex align-items-center ms-1 ms-lg-3'>
          <RandomTipButtom />
        </div>

        {/* Theme mode */}
        <div className='d-flex align-items-center ms-1 ms-lg-3'>
          <ThemeModeSwitcher />
        </div>


        {/* User */}
        <div className='d-flex align-items-center ms-1 ms-lg-3'>
          <HeaderUserMenu />
        </div>

        <div className='d-flex align-items-center ms-1 ms-lg-3'>
          {/* <CrispChatDialog /> */}
        </div>

      </div>
    </div >
  );
};

export { Topbar };
