// src\app\pages\4-Campaigns\3-CampaignSequence\LiquidSyntax\LiquidSyntaxGenerator.tsx
import CustomTooltip from '@/src/app/modules/CustomMaterialUiComponents/CustomTooltip';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import React, { useEffect, useState } from 'react';

interface PlaceholderOption {
    value: string;
    label: string;
}

interface FormatOption {
    value: string;
    label: string;
}

const placeholderOptions: PlaceholderOption[] = [
    { value: 'Today', label: 'Today' },
    { value: 'Tomorrow', label: 'Tomorrow' },
    { value: 'InXDays', label: 'In X Days' },
    { value: 'Year', label: 'Year' },
    { value: 'Month', label: 'Month' },
    { value: 'TimeOfDay', label: 'Time of Day' }, // Assuming you want a placeholder for this
];


const generalFormatOptions: FormatOption[] = [
    { value: 'YYYY-MM-DD', label: 'YYYY-MM-DD (Year-Month-Day)' },
    { value: 'dddd', label: 'dddd (Day of the Week)' },
    { value: 'MMM Do YY', label: 'MMM Do YY (Month Day, Year)' },
    { value: 'YYYY', label: 'YYYY (4 digit year)' },
    { value: 'YY', label: 'YY (2 digit year)' },
    { value: 'MMMM', label: 'MMMM (Full month name)' },
    { value: 'MMM', label: 'MMM (Short month name)' },
    { value: 'MM', label: 'MM (Month with leading zero)' },
    { value: 'M', label: 'M (Month without leading zero)' },
    { value: 'DD', label: 'DD (Day of the month with leading zero)' },
    { value: 'D', label: 'D (Day of the month without leading zero)' },
    { value: 'dddd', label: 'dddd (Full day of the week name)' },
    { value: 'ddd', label: 'ddd (Short day of the week name)' },
    { value: 'HH', label: 'HH (24 hour time with leading zero)' },
    { value: 'H', label: 'H (24 hour time without leading zero)' },
    { value: 'hh', label: 'hh (12 hour time with leading zero)' },
    { value: 'h', label: 'h (12 hour time without leading zero)' },
    { value: 'mm', label: 'mm (Minutes with leading zero)' },
    { value: 'ss', label: 'ss (Seconds with leading zero)' },
    { value: 'A', label: 'A (AM/PM uppercase)' },
    { value: 'a', label: 'a (am/pm lowercase)' },
    { value: 'Z', label: 'Z (Offset from UTC as +-HH:mm)' },
    { value: 'ZZ', label: 'ZZ (Offset from UTC as +-HHmm)' },
    { value: 'YYYY-MM-DDTHH:mm:ssZ', label: 'ISO 8601 Date' },
];

const timeOfDayFormatOptions: FormatOption[] = [
    { value: 'default', label: 'Default Capitalization' },
    { value: 'uppercase', label: 'UPPERCASE' },
    { value: 'lowercase', label: 'lowercase' },
];

const LiquidSyntaxGenerator: React.FC = () => {
    const [selectedPlaceholder, setSelectedPlaceholder] = useState<string>('');
    const [numberOfDays, setNumberOfDays] = useState<string>(''); // State to hold the number of days
    const [selectedFormat, setSelectedFormat] = useState<string>('');
    const [generatedPlaceholder, setGeneratedPlaceholder] = useState<string>('');
    const [currentFormatOptions, setCurrentFormatOptions] = useState<FormatOption[]>(generalFormatOptions);
    const [copied, setCopied] = useState<boolean>(false);


    useEffect(() => {
        if (selectedPlaceholder !== 'InXDays') {
            setNumberOfDays(''); // Reset numberOfDays if "In X Days" is not selected
        }
    }, [selectedPlaceholder]);


    useEffect(() => {
        // Update format options based on the selected placeholder
        if (selectedPlaceholder === 'TimeOfDay') {
            setCurrentFormatOptions(timeOfDayFormatOptions);
        } else {
            setCurrentFormatOptions(generalFormatOptions);
        }
        // Reset selected format when changing placeholder
        setSelectedFormat('');
    }, [selectedPlaceholder]);


    const handlePlaceholderChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedPlaceholder(event.target.value);
    };


    const handleFormatChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedFormat(event.target.value);
    };

    const handleNumberOfDaysChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setNumberOfDays(value.replace(/[^0-9]/g, '')); // Allow only numeric values
    };


    const generatePlaceholder = () => {
        let placeholder = selectedPlaceholder;
        if (placeholder === 'InXDays' && numberOfDays) {
            placeholder = `In${numberOfDays}Days`;
        }

        if (placeholder && selectedFormat) {
            setGeneratedPlaceholder(`[--${placeholder}|${selectedFormat}--]`);
        } else if (placeholder) {
            setGeneratedPlaceholder(`[--${placeholder}--]`);
        } else {
            setGeneratedPlaceholder('');
        }
    };


    const copyToClipboard = (text: string) => {
        navigator.clipboard.writeText(text).then(() => {
            setCopied(true);
            setTimeout(() => setCopied(false), 3000); // Reset after 3 seconds
        });
    };


    return (
        <Box sx={{ width: 300, marginTop: 2 }}>
            <TextField
                select
                label="Placeholder"
                value={selectedPlaceholder}
                onChange={handlePlaceholderChange}
                fullWidth
            >
                {placeholderOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                        {option.label}
                    </MenuItem>
                ))}
            </TextField>
            <TextField
                select
                label="Format"
                value={selectedFormat}
                onChange={handleFormatChange}
                fullWidth
                sx={{ marginTop: 2 }}
            >
                {currentFormatOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                        {option.label}
                    </MenuItem>
                ))}
            </TextField>
            {selectedPlaceholder === 'InXDays' && (
                <TextField
                    label="Number of Days"
                    type="text" // Set to text and manually enforce numeric input for better control
                    value={numberOfDays}
                    onChange={handleNumberOfDaysChange}
                    fullWidth
                    sx={{ marginTop: 2 }}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">days</InputAdornment>,
                    }}
                />
            )}

            <Button
                variant="contained"
                sx={{ marginTop: 2 }}
                onClick={generatePlaceholder}
            >
                Generate Placeholder
            </Button>
            {generatedPlaceholder && (
                <Typography sx={{ marginTop: 2, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    {generatedPlaceholder}
                    <CustomTooltip title="Copy to clipboard">
                        <IconButton onClick={() => copyToClipboard(generatedPlaceholder)}>
                            {copied ? <CheckCircleOutlineIcon color="success" /> : <FileCopyIcon />}
                        </IconButton>
                    </CustomTooltip>
                </Typography>
            )}
        </Box>
    );
};

export default LiquidSyntaxGenerator;
