import React, { useState, useRef } from 'react';
import { Box, CircularProgress, Button, useTheme } from '@mui/material';
import ApexCharts from 'react-apexcharts';
import { DayScheduleInterface } from '../../InterfacesWarmupSettings';
import ZoomOutMapIcon from '@mui/icons-material/ZoomOutMap';
import ChartSkeleton from './ChartSkeleton';
interface Props {
    daySchedules: DayScheduleInterface[];
    isLoading: boolean;
}

const ChartComponent: React.FC<Props> = ({ daySchedules, isLoading }) => {
    const theme = useTheme();
    const [zoomed, setZoomed] = useState(false);
    const chartRef = useRef(null);
    const [cursorStyle, setCursorStyle] = useState('crosshair');

    if (isLoading) {
        return <ChartSkeleton />;
    }

    const today = new Date();
    const todayFormatted = `${today.getFullYear()}-${('0' + (today.getMonth() + 1)).slice(-2)}-${('0' + today.getDate()).slice(-2)}`;

    // Calculate the index of today's date or the nearest future date
    let todayIndex = daySchedules.findIndex(schedule => schedule.Date === todayFormatted);
    if (todayIndex === -1) { // If today is not found, focus on the first future date
        const futureDates = daySchedules.filter(schedule => !schedule.Date || new Date(schedule.Date) > today);
        todayIndex = daySchedules.indexOf(futureDates[0]);
    }
    const startIndex = Math.max(0, todayIndex - 3); // Adjust to focus 7 days before today
    const endIndex = Math.min(daySchedules.length, todayIndex + 4); // Adjust to focus 7 days after today


    const chartData = {
        series: [{
            name: "Email Count",
            data: daySchedules.map(schedule => ({
                x: `Day ${schedule.dayNumber}`,
                y: schedule.emailCount,
                fillColor: schedule.Date === todayFormatted ? theme.palette.secondary.main : (!schedule.Date ? theme.palette.action.focus : theme.palette.primary.main),
            }))
        }],
        options: {
            chart: {
                id: 'emailCountChart', // Add an ID to your chart
                type: 'histogram' as const,
                height: 350,
                stacked: true,
                toolbar: {
                    show: false,
                    tools: {
                        download: false, // Enables the download option
                        selection: false, // Enables the selection tool
                        zoom: true,      // Enables zoom option
                        zoomin: false,    // Enables zoom in option
                        zoomout: false,   // Enables zoom out option
                        pan: false,       // Enables panning tool
                        customIcons: []  // Custom icons, if any
                    },
                    autoSelected: 'zoom' as const
                },
            },

            markers: {
                size: 0,

            },
            zoom: {
                enabled: false,
                zoomedArea: {
                    fill: {
                        color: '#222222',
                        opacity: 0.4
                    },
                    stroke: {
                        color: '#222222',
                        opacity: 0.4,
                        width: 8
                    }
                }
            },

            title: {
                text: '',
                style: {
                    color: theme.palette.text.primary,
                    fontSize: '16px',
                }
            },
            xaxis: {
                categories: daySchedules.map(schedule => `${schedule.dayNumber}`),
                tickPlacement: 'on',
                labels: {
                    style: {
                        colors: theme.palette.text.secondary,
                        fontSize: '12px',
                    }
                }
            },
            yaxis: {
                labels: {
                    style: {
                        colors: theme.palette.text.secondary,
                        fontSize: '12px',
                    }
                }
            },
            selection: {
                enabled: true,
                type: 'x',
                fill: {
                    color: '#222222',
                    opacity: 0.1
                }
            },



            responsive: [
                {
                    breakpoint: 1000,
                    options: {
                        plotOptions: {
                            bar: {
                                horizontal: false
                            }
                        },
                        legend: {
                            position: "bottom"
                        }
                    }
                }
            ]
        }
    };

    // Function to reset zoom
    const resetZoom = () => {
        // Since direct control over zoom level isn't provided by ApexCharts configuration,
        // you might need to implement a workaround or adjust UI elements accordingly.
        // The following is a placeholder for logic to adjust the chart view or data.
        console.log("Reset Zoom functionality needs a specific implementation.");
        setZoomed(prevZoomed => !prevZoomed);
    };

    const toggleCursorStyle = () => {
        setCursorStyle(prevCursorStyle =>
            prevCursorStyle === 'crosshair' ? 'auto' : 'crosshair'
        );
    };


    return (
        <Box>
            <Button onClick={resetZoom} variant="text" color="info" style={{ marginTop: '10px' }}>
                <ZoomOutMapIcon sx={{ fontSize: "35px" }} />
            </Button>

            <ApexCharts
                ref={chartRef}
                type="histogram"
                series={chartData.series}
                options={chartData.options}
                height={350}
                style={{ cursor: cursorStyle }} // Set the cursor style inline
            />

        </Box>
    );
};

export default ChartComponent;
