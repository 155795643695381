// File: ProviderSelectionList.tsx
import Tagify from '@yaireo/tagify';
import React, { useEffect, useRef } from 'react';

interface ProviderSelectionListProps {
    emailProviders: { name: string, smtpHost: string, imapHost: string, smtpPort: string, imapPort: string }[];
    onProviderSelect: (provider: any) => void;
}

const ProviderSelectionList: React.FC<ProviderSelectionListProps> = ({ emailProviders, onProviderSelect }) => {
    const inputRef = useRef(null);

    useEffect(() => {
        const tagify = new Tagify(inputRef.current, {
            whitelist: emailProviders.map(provider => provider.name),
            maxTags: 1,
        });

        tagify.on('add', (e) => {
            const selectedProvider = emailProviders.find(provider => provider.name === e.detail.data.value);
            if (selectedProvider) onProviderSelect(selectedProvider);
        });

        return () => {
            tagify.destroy();
        };
    }, [emailProviders, onProviderSelect]);

    return (
        <input ref={inputRef} placeholder="Search for a provider..." />
    );
};

export default ProviderSelectionList;
