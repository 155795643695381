import { Grid } from '@mui/material';
import React, { ReactNode } from 'react';

interface GridBackgroundProps {
    pattern: 'grid' | 'dots' | 'squares' | 'triangles'; // Added shapes for dots
    color?: string;
    size?: number;
    gap?: number;
    opacity?: number;
    lineWidth?: number;
    angle?: number;
    background?: string;
    gradientDirection?: string; // New: For background gradient direction
    patternOffset?: number; // New: Pattern offset
    shadow?: string; // New: Shadow for pattern
    patternFrequency?: number; // New: Control pattern density
    children?: ReactNode;
}

const GridBackgroundComponent: React.FC<GridBackgroundProps> = ({
    pattern,
    color = 'lightgrey',
    size = 8,
    gap = 2,
    opacity = 0.5,
    lineWidth = 1,
    angle = 0,
    background = 'transparent',
    gradientDirection = 'to bottom', // Default linear gradient direction
    patternOffset = 0,
    shadow = 'none',
    patternFrequency = 1,
    children,
}) => {
    // Adjust backgroundPattern generation based on new props
    const backgroundPattern = (() => {
        switch (pattern) {
            case 'grid':
                return `linear-gradient(${angle}deg, ${color} ${lineWidth}px, transparent ${lineWidth}px), linear-gradient(${angle + 90}deg, ${color} ${lineWidth}px, transparent ${lineWidth}px)`;
            case 'dots':
                return `radial-gradient(circle, ${color} ${lineWidth}px, transparent 1px)`;
            case 'squares':
            case 'triangles': // Placeholder for actual implementation
                // Implement different shapes logic here
                return ''; // Update with actual CSS pattern
            default:
                return '';
        }
    })();

    const containerStyle = {
        position: 'relative',
        backgroundColor: background,
        background: `linear-gradient(${gradientDirection}, ${background})`, // Apply gradient direction
        '::before': {
            content: '""',
            position: 'absolute',
            top: patternOffset, // Apply offset
            left: patternOffset, // Apply offset
            right: 0,
            bottom: 0,
            backgroundImage: backgroundPattern,
            backgroundSize: `${(size + gap) * patternFrequency}px ${(size + gap) * patternFrequency}px`, // Apply frequency
            opacity: opacity,
            boxShadow: shadow, // Apply shadow
            pointerEvents: 'none',
            zIndex: -1,
        }
    };

    return (
        <Grid sx={containerStyle}>
            {children}
        </Grid>
    );
};

export default GridBackgroundComponent;
