// theme.tsx
import { createTheme } from '@mui/material/styles';

const transitionDuration = 1000; // 1 second transition duration

export const lightTheme = createTheme({
    transitions: {
        duration: {
            standard: transitionDuration,
        },
    },
    palette: {
        primary: {
            main: '#793A99',
        },
        background: {
            default: "#ebebeb"
        },
        secondary: {
            main: '#FAAF00',
        },
        error: {
            main: '#DD1C1A',
        },
        warning: {
            main: '#FFEE00',
        },
        info: {
            main: '#793A99',
        },
        success: {
            main: '#8adc75',
        },
        purplefordark: {
            main: '#DD1C1A',
        },
        text: {
            primary: '#666666',
        },
    },
    typography: {
        allVariants: {
            fontFamily: 'Montserrat, sans-serif',
            textTransform: 'none',
            fontSize: 18,
        },
        h1: {
            fontFamily: 'Playfair Display, serif',
        },
        h2: {
            fontFamily: 'Playfair Display, serif',
        },
        h3: {
            fontFamily: 'Playfair Display, serif',
        },
        h4: {
            fontFamily: 'Playfair Display, serif',
        },
        h5: {
            fontFamily: 'Playfair Display, serif',
        },
        h6: {
            fontFamily: 'Playfair Display, serif',
        },
    },
    components: {
        MuiTypography: {
            styleOverrides: {
                root: {
                    color: '#666666', // Set text color for all typography components
                },
            },
        },
        MuiTouchRipple: {
            styleOverrides: {
                //@ts-ignore
                '@keyframes mui-ripple-enter': {
                    '0%': {
                        opacity: 0.1,
                        transform: 'scale(0)',
                    },
                    '100%': {
                        opacity: 0.3,
                        transform: 'scale(1)',
                    },
                },
                root: {
                    color: '#793A99', // Set ripple color for light mode
                },
            },
        },
        MuiCssBaseline: {
            styleOverrides: {
                '@global': {
                    '*': {
                        transition: '0.5s',
                    },
                    '.custom-popper': {
                        backgroundColor: '#793A99',
                        padding: '10px',
                        borderRadius: '20px',
                        fontSize: '16px',
                        margin: '20px',
                        color: '#fff',
                    },

                },
            },
        },
    },
});

export const darkTheme = createTheme({
    transitions: {
        duration: {
            standard: transitionDuration,
        },
    },
    palette: {
        mode: 'dark',
        primary: {
            main: '#793A99',
        },
        background: {
            default: "#252525"
        },
        secondary: {
            main: '#FAAF00',
        },
        error: {
            main: '#DD1C1A',
        },
        warning: {
            main: '#FFEE00',
        },
        info: {
            main: '#ffffff',
        },
        success: {
            main: '#8adc75',
        },
        purplefordark: {
            main: '#793A99',
        },
    },
    typography: {
        allVariants: {
            fontFamily: 'Montserrat, sans-serif',
            textTransform: 'none',
            fontSize: 18,
        },
        h1: {
            fontFamily: 'Playfair Display, serif',
        },
        h2: {
            fontFamily: 'Playfair Display, serif',
        },
        h3: {
            fontFamily: 'Playfair Display, serif',
        },
        h4: {
            fontFamily: 'Playfair Display, serif',
        },
        h5: {
            fontFamily: 'Playfair Display, serif',
        },
        h6: {
            fontFamily: 'Playfair Display, serif',
        },
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: {
                '@global': {
                    '*, *::before, *::after': {
                        transition: '0.5s',
                    },
                    '.custom-popper': {
                        backgroundColor: '#793A99',
                        padding: '10px',
                        borderRadius: '20px',
                        fontSize: '16px',
                        margin: '20px',
                        color: '#fff',
                    },

                },
            },
        },
    },
});
