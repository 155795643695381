import React, { useState, useEffect } from 'react';
import { Device } from 'twilio-client';
import {
    Button,
    TextField,
    Select,
    Input,
    MenuItem,
    FormControl,
    InputLabel,
    Box,
    Grid,
    Paper,
    Typography,
    IconButton,
    InputAdornment,
} from '@mui/material';
import PhoneIcon from '@mui/icons-material/Phone'; // You can import icons as needed
import ClearIcon from '@mui/icons-material/Clear'; // Import the clear icon
import SendIcon from '@mui/icons-material/Send'; // Import an appropriate icon

const TwilioVoiceComponent: React.FC = () => {
    const [device, setDevice] = useState<Device | null>(null);
    const [phoneNumber, setPhoneNumber] = useState<string>('');
    const [verifiedNumbers, setVerifiedNumbers] = useState<string[]>([]);
    const [selectedCallerId, setSelectedCallerId] = useState<string>('');
    const [extension, setExtension] = useState<string>('');
    const [showExtensionInput, setShowExtensionInput] = useState<boolean>(false);
    const [incomingCall, setIncomingCall] = useState<any>(null);
    const [showIncomingCallModal, setShowIncomingCallModal] = useState<boolean>(false);

    const handleDialPadClick = (digit: string) => {
        setExtension((prev) => prev + digit);
    };

    const renderDialPad = () => {
        const digits = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '*', '0', '#'];
        return (
            <Grid container spacing={1}>
                {digits.map((digit) => (
                    <Grid item xs={3} key={digit}>
                        <IconButton
                            color="primary"
                            onClick={() => handleDialPadClick(digit)}
                        >
                            {digit}
                        </IconButton>
                    </Grid>
                ))}
                <Grid item xs={3}>
                    <IconButton
                        color="secondary"
                        onClick={() => setExtension('')}
                    >
                        <ClearIcon />
                    </IconButton>
                </Grid>
            </Grid>
        );
    };

    const sendExtension = () => {
        const activeConnection = device?.activeConnection();
        if (activeConnection) {
            activeConnection.sendDigits(extension);
            setExtension('');
        } else {
            console.error('No active connection to send digits to');
        }
    };

    const hangUpCall = () => {
        const activeConnection = device?.activeConnection();
        if (activeConnection) {
            activeConnection.disconnect();
        } else {
            console.error('No active connection to disconnect');
        }
    };

    const handleIncomingCall = (call: any) => {
        setIncomingCall(call);
        setShowIncomingCallModal(true);
    };

    const acceptIncomingCall = () => {
        if (incomingCall) {
            incomingCall.accept();
            setIncomingCall(null);
            setShowIncomingCallModal(false);
        }
    };

    const rejectIncomingCall = () => {
        if (incomingCall) {
            incomingCall.reject();
            setIncomingCall(null);
            setShowIncomingCallModal(false);
        }
    };

    const fetchVerifiedNumbers = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/phone/numberslist`);
            const data = await response.json();
            setVerifiedNumbers(data);
        } catch (error) {
            console.error('Error fetching verified numbers:', error);
        }
    };

    useEffect(() => {
        fetchToken();
        fetchVerifiedNumbers();
    }, []);

    const fetchToken = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/phone/token`);
            const data = await response.json();
            initializeDevice(data.token);
            console.log(data.token)
        } catch (error) {
            console.error('Error fetching token:', error);
        }
    };

    const initializeDevice = (token: string) => {
        const newDevice = new Device(token, {
            logLevel: 1,
            codecPreferences: ['opus', 'pcmu'] as any,
        });

        newDevice.on('registered', () => {
            console.log('Twilio.Device Ready to make and receive calls!');
        });

        newDevice.on('incoming', (call) => {
            console.log('Incoming call from:', call.from);
            handleIncomingCall(call);
        });

        newDevice.on('error', (error) => {
            console.error('Twilio.Device Error:', error.message);
        });

        setDevice(newDevice);
    };

    const makeOutgoingCall = () => {
        if (device && phoneNumber) {
            console.log(`Attempting to call ${phoneNumber} ...`);
            device.connect({
                To: phoneNumber,
                CallerId: selectedCallerId,
            });
        } else {
            console.log('Device not initialized or phone number missing');
        }
    };

    const toggleExtensionInput = () => {
        setShowExtensionInput((prev) => !prev);
    };

    const startDevice = () => {
        fetchToken(); // Fetch token and initialize the device
        console.log('Device started.');
    };

    // Function to end the Twilio device
    const endDevice = () => {
        setDevice(null); // Set the device to null to end it
        console.log('Device ended.');
    };

    return (
        <Box p={2}>
            <Typography variant="h4">Twilio Voice JavaScript SDK Quickstart</Typography>

            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <FormControl fullWidth>
                        <InputLabel>Select Caller ID</InputLabel>
                        <Select
                            value={selectedCallerId}
                            onChange={(e) => setSelectedCallerId(e.target.value as string)}
                        >
                            {verifiedNumbers.map((number, index) => (
                                <MenuItem key={index} value={number}>
                                    {number}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={6}>
                    <IconButton color="primary" onClick={makeOutgoingCall}>
                        <PhoneIcon />
                    </IconButton>
                </Grid>
            </Grid>

            <TextField
                fullWidth
                label="Enter a phone number or client name"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                placeholder="+15552221234"
            />
            <Button variant="contained" onClick={makeOutgoingCall}>
                Call
            </Button>

            <Box mt={2}>
                {showExtensionInput ? (
                    <Grid item xs={12} sm={6}>
                        <Paper elevation={3} style={{ padding: '36px', maxWidth: '200px' }}>
                            <FormControl fullWidth>
                                <InputLabel>Dial</InputLabel>
                                <Input
                                    type="text"
                                    value={extension}
                                    readOnly
                                    style={{ fontSize: '1rem' }}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton onClick={sendExtension} color="primary">
                                                <SendIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />
                            </FormControl>
                            <Box mt={2}>
                                {renderDialPad()}
                            </Box>
                        </Paper>
                    </Grid>
                ) : (
                    <Button variant="contained" onClick={toggleExtensionInput}>
                        In Call Dialer
                    </Button>
                )}
            </Box>

            <Button variant="contained" onClick={hangUpCall}>
                Hang Up
            </Button>

            {showIncomingCallModal && incomingCall && (
                <div className="incoming-call-modal">
                    <p>Incoming Call from: {incomingCall.from}</p>
                    <button onClick={acceptIncomingCall}>Accept</button>
                    <button onClick={rejectIncomingCall}>Reject</button>
                </div>
            )}

            {/* Start Device and End Device Buttons */}
            <Button variant="contained" onClick={startDevice}>
                Start Device
            </Button>

            <Button variant="contained" onClick={endDevice}>
                End Device
            </Button>

        </Box>
    );
};

export default TwilioVoiceComponent;
