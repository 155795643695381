// CustomToolbar.tsx
import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import axios from 'axios';
import { Card, CardContent, Typography, LinearProgress, Grid, Badge } from '@mui/material';
import DeleteOutlineSharpIcon from '@mui/icons-material/DeleteOutlineSharp';
import MenuBar from '../../modules/CustomMaterialUiComponents/MenuBar';
import VerifiedSharpIcon from '@mui/icons-material/VerifiedSharp';
import SmartButtonSharpIcon from '@mui/icons-material/SmartButtonSharp';
import CleaningServicesSharpIcon from '@mui/icons-material/CleaningServicesSharp';
import AvTimerSharpIcon from '@mui/icons-material/AvTimerSharp';
import { SiGmail } from "react-icons/si";
import CorporateFareSharpIcon from '@mui/icons-material/CorporateFareSharp';
import {
    AddCircleSharp,
    ArticleSharp,
    EmailSharp,
    FileCopySharp,
    ImportContactsSharp,
    LocationOnSharp,
    MarkEmailReadSharp,
    MoreHorizSharp,
    PlumbingSharp,
    RefreshSharp,
} from '@mui/icons-material';
import LocationOnSharpIcon from '@mui/icons-material/LocationOnSharp';
import AutoFixHighSharpIcon from '@mui/icons-material/AutoFixHighSharp';
import BusinessCenterSharpIcon from '@mui/icons-material/BusinessCenterSharp';
import ViewQuiltSharpIcon from '@mui/icons-material/ViewQuiltSharp';
import VerticalSplitSharpIcon from '@mui/icons-material/VerticalSplitSharp';
import CompareSharpIcon from '@mui/icons-material/CompareSharp';
import ContactMailSharpIcon from '@mui/icons-material/ContactMailSharp';
import HelpSharpIcon from '@mui/icons-material/HelpSharp';
import ErrorSharpIcon from '@mui/icons-material/ErrorSharp';
import { io } from 'socket.io-client';
import { FormatAlignJustifySharp, DensitySmallSharp, DensityMediumSharp, DensityLargeSharp } from '@mui/icons-material';
import { RiFileExcel2Fill } from "react-icons/ri";
import PersonAddSharpIcon from '@mui/icons-material/PersonAddSharp';
import ListQueueChip from './ListQueueChip';
import { GrLinkedin } from "react-icons/gr";
import { useTheme } from '@mui/material';
import PendingSharpIcon from '@mui/icons-material/PendingSharp';
import LinkedInPriorityModal from './CustomToolbarComponents/PrioritySelectionModal';
import FileDownloadSharpIcon from '@mui/icons-material/FileDownloadSharp';
import CreateEmptyListModal from './CreateEmptyList';
import IcebreakerModal from './CustomToolbarComponents/IcebreakerModal';
// Define a props interface for clarity and type safety





interface CustomToolbarProps {
    selectedLeadIds: string[];
    setSelectedLeadIds: (value: any) => void;
    selectedListId?: string | null; // Add this line
    refreshData: () => void; // Add this line
    isFixingCompanyName: boolean;
    setIsFixingCompanyName: (isFixing: boolean) => void;
    isValidatingEmails: boolean;
    setIsValidatingEmails: (isValidating: boolean) => void;
    isReoonValidatingEmails: boolean;
    setIsReoonValidatingEmails: (isReoonValidating: boolean) => void;
    isClassifyingEmails: boolean;
    setIsClassifyingEmails: (isClassifyingEmails: boolean) => void;
    handleExtraCondensedView: () => void;
    handleCondensedView: () => void;
    handleRelaxedView: () => void;
    handleExtraRelaxedView: () => void;
    togglePanelVisibility: () => void;
    uploadSingleLead: () => void;
    uploadFileList: () => void;

}

const CustomToolbar: React.FC<CustomToolbarProps> = ({
    selectedLeadIds,
    setSelectedLeadIds,
    selectedListId,
    refreshData,
    isFixingCompanyName,
    setIsFixingCompanyName,
    isValidatingEmails,
    setIsValidatingEmails,
    isReoonValidatingEmails,
    setIsReoonValidatingEmails,
    isClassifyingEmails,
    setIsClassifyingEmails,
    handleExtraCondensedView,
    handleCondensedView,
    handleRelaxedView,
    handleExtraRelaxedView,
    togglePanelVisibility,
    uploadSingleLead,
    uploadFileList,

}) => {

    const theme = useTheme()
    const [priorityModalOpen, setPriorityModalOpen] = useState(false);
    const [isPreparingDownload, setIsPreparingDownload] = useState(false);
    const [progress, setProgress] = useState({ active: 0, totalScheduled: 0 });
    const [socket, setSocket] = useState(null);
    const [createListModalOpen, setCreateListModalOpen] = useState(false);

    const [icebreakerModalOpen, setIcebreakerModalOpen] = useState(false);


    const [classificationJobCounts, setClassificationJobCounts] = useState({
        active: 0, waiting: 0, completed: 0, delayed: 0, failed: 0, totalScheduled: 0
    });
    const [companyNameFixJobCounts, setCompanyNameFixJobCounts] = useState({
        active: 0, waiting: 0, completed: 0, delayed: 0, failed: 0, totalScheduled: 0
    });
    const [linkedInEnrichmentJobCounts, setLinkedInEnrichmentJobCounts] = useState({
        active: 0, waiting: 0, completed: 0, delayed: 0, failed: 0, totalScheduled: 0
    });
    const [emailValidationJobCounts, setEmailValidationJobCounts] = useState({
        active: 0, waiting: 0, completed: 0, delayed: 0, failed: 0, totalScheduled: 0
    });
    const [timezoneJobCounts, setTimezoneJobCounts] = useState({
        active: 0, waiting: 0, completed: 0, delayed: 0, failed: 0, totalScheduled: 0
    });
    const [icebreakerJobCounts, setIcebreakerJobCounts] = useState({
        active: 0, waiting: 0, completed: 0, delayed: 0, failed: 0, totalScheduled: 0
    });

    // State to manage visibility of ListQueueChip components
    const [showQueueChips, setShowQueueChips] = useState(false);

    // Toggle function for ListQueueChip visibility
    const toggleQueueChipsVisibility = () => {
        setShowQueueChips(!showQueueChips);
    };

    //@ts-ignore

    useEffect(() => {
        //@ts-ignore
        const socket = io(process.env.REACT_APP_API_URL);
        socket.on('connect', () => {
            console.log('Connected to server');
            socket.emit('joinListUpdatesRoom', { listId: `${selectedListId}` });
        });

        socket.on('classificationJobCounts', (data) => {
            setClassificationJobCounts(data);
        });

        socket.on('companyNameFixJobCounts', (data) => {
            setCompanyNameFixJobCounts(data);
        });
        socket.on('linkedInEnrichmentJobCounts', (data) => {
            setLinkedInEnrichmentJobCounts(data);
        });
        socket.on('emailValidationJobCounts', (data) => {
            setEmailValidationJobCounts(data);
        });

        socket.on('timezoneJobCounts', (data) => {
            setTimezoneJobCounts(data);  // Assuming you have a state setup similar to other job counts
        });


        socket.on('icebreakerJobCounts', (data) => {
            setIcebreakerJobCounts(data);  // Assuming you have a state setup similar to other job counts
        });

        // Reset state when switching lists
        setClassificationJobCounts({
            active: 0,
            waiting: 0,
            completed: 0,
            delayed: 0,
            failed: 0,
            totalScheduled: 0,
        });

        setCompanyNameFixJobCounts({
            active: 0,
            waiting: 0,
            completed: 0,
            delayed: 0,
            failed: 0,
            totalScheduled: 0,
        });

        setLinkedInEnrichmentJobCounts({
            active: 0,
            waiting: 0,
            completed: 0,
            delayed: 0,
            failed: 0,
            totalScheduled: 0,
        });

        setEmailValidationJobCounts({
            active: 0,
            waiting: 0,
            completed: 0,
            delayed: 0,
            failed: 0,
            totalScheduled: 0,
        });

        setTimezoneJobCounts({
            active: 0,
            waiting: 0,
            completed: 0,
            delayed: 0,
            failed: 0,
            totalScheduled: 0,
        });

        setIcebreakerJobCounts({
            active: 0,
            waiting: 0,
            completed: 0,
            delayed: 0,
            failed: 0,
            totalScheduled: 0,
        });




        return () => {
            socket.emit('leaveListUpdatesRoom', { listId: `${selectedListId}` });
            socket.close();
        };
    }, [selectedListId]);



    const handleFixCompanyName = async () => {
        if (!selectedListId || selectedLeadIds.length === 0) {
            alert('No list selected or no leads selected');
            return;
        }
        setIsFixingCompanyName(true); // Start loading indicator
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/leads/clean-company-name`, {
                leadIds: selectedLeadIds,
                listId: selectedListId,
            });
            console.log('Company name fixing initiated:', response.data.message);
        } catch (error) {
            console.error('Error fixing company names:', error);
            alert('Error fixing company names. Please try again.');
        }
        setIsFixingCompanyName(false); // Stop loading indicator
    };


    const handleValidateEmails = async () => {
        if (!selectedListId) {
            alert('No list selected');
            return;
        }
        setIsValidatingEmails(true); // Start loading indicator

        try {
            // Assuming you want to send all selectedLeadIds in one request for efficiency
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/leads/validate-emails`, {
                leadIds: selectedLeadIds,  // Sending an array of lead IDs
                listId: selectedListId,   // Optional, if you need to pass list ID
            });
            console.log('Email validation success:', response.data);
        } catch (error) {
            console.error('Error validating emails:', error);
        }

        setIsValidatingEmails(false); // Stop loading indicator
    };



    const classifyEmails = (classificationType) => async () => {
        if (!selectedListId || selectedLeadIds.length === 0) {
            alert('No list selected or no leads selected');
            return;
        }

        setIsClassifyingEmails(true);

        try {
            // Send a single request with all selected lead IDs
            await axios.post(`${process.env.REACT_APP_API_URL}/leads/classify-email`, {
                leadIds: selectedLeadIds, // This is an array of lead IDs
                classificationType,
                listId: selectedListId,
            });
            // Success handling: Perhaps update some state or trigger a UI refresh
            console.log(`${classificationType} email classification initiated for multiple leads.`);
        } catch (error) {
            console.error(`Error classifying ${classificationType} emails:`, error);
            // Error handling: Display an error message or log the error
        } finally {
            setIsClassifyingEmails(false);
        }
    };

    const handleEnrichLeads = async (priority) => {
        if (!selectedListId || selectedLeadIds.length === 0) {
            alert('No list selected or no leads selected');
            return;
        }

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/leads/updateLeadsWithLinkedIn`, {
                leadIds: selectedLeadIds,
                listId: selectedListId,
                priority
            });
            console.log('Enrichment started:', response.data);
        } catch (error) {
            console.error('Failed to enrich leads:', error);
            alert('Failed to start enrichment process.');
        }
    };




    const generateIcebreakers = async (contentType) => {
        if (selectedLeadIds.length === 0) {
            alert('No leads selected');
            return;
        }

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/leads/generate-icebreaker`, {
                leadIds: selectedLeadIds,
                contentType: contentType,
                listId: selectedListId,
            });
            console.log('Icebreakers generated:', response.data);
        } catch (error) {
            console.error('Failed to generate icebreakers:', error);
            alert('Failed to generate icebreakers. Please try again.');
        }
    };


    // Add this new function to handle the deletion of selected leads
    const handleDeleteSelectedLeads = async () => {
        if (selectedLeadIds.length === 0) {
            alert('No leads selected');
            return;
        }

        const confirmation = window.confirm("Are you sure you want to delete the selected leads?");
        if (!confirmation) {
            return; // If the user cancels, exit the function
        }

        const payload = { leadIds: selectedLeadIds };
        try {
            await axios.delete(`${process.env.REACT_APP_API_URL}/leads/delete`, { data: payload });
            console.log('Selected leads deleted successfully');
            setSelectedLeadIds([]); // IMPORTANT: Reset selected leads to clear the selection
            //@ts-ignore
            refreshData({ noSkeleton: true });

        } catch (error) {
            console.error('Error deleting selected leads:', error);
            // Handle error (e.g., display an error message)
        }
    };

    // Leads Operations :

    const handleadOperation = (operation) => async () => {
        if (selectedLeadIds.length === 0) {
            alert('No leads selected');
            return;
        }

        try {
            // Execute the operation with axios post request
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/leads/operation`, {
                leadIds: selectedLeadIds,
                operation: operation
            });
            console.log('Operation success:', response.data);
            //@ts-ignore
            refreshData({ noSkeleton: true });
        } catch (error) {
            console.error('Error during operation:', error);
        }
    };

    const detectLeadTimezone = async () => {
        if (selectedLeadIds.length === 0) {
            alert('No leads selected');
            return;
        }

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/leads/detect-timezone`, {
                leadIds: selectedLeadIds,
            });
            console.log('Timezone detection for leads:', response.data);
        } catch (error) {
            console.error('Error detecting timezones for leads:', error);
        }
    };

    const detectTimeZoneForList = async () => {
        if (!selectedListId) {
            alert('No list selected');
            return;
        }

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/leads/detect-timezone`, {
                listId: selectedListId,
            });
            console.log('Timezone detection for list:', response.data);
        } catch (error) {
            console.error('Error detecting timezones for list:', error);
        }
    };


    const handleRemoveDuplicatesFromList = () => {
        if (!selectedListId) {
            alert('No list selected');
            return;
        }
        axios.post(`${process.env.REACT_APP_API_URL}/leads/lists/${selectedListId}/duplicates`)
            .then(response => {
                console.log('Duplicates removed:', response.data);
                refreshData();
            })
            .catch(error => {
                console.error('Error removing duplicates:', error);
            });
    };

    const downloadFile = (response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const contentDisposition = response.headers['content-disposition'];
        console.log('Content-Disposition header:', contentDisposition); // Log the header

        let filename = 'downloaded_file';
        if (contentDisposition) {
            const filenameMatch = contentDisposition.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/);
            console.log('Filename match:', filenameMatch); // Log the match result

            if (filenameMatch != null && filenameMatch[1]) {
                filename = filenameMatch[1].replace(/['"]/g, ''); // Remove quotes
                console.log('Extracted filename:', filename); // Log the extracted filename
            }
        }

        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const exportListToCSV = async () => {
        if (!selectedListId) {
            alert('No list selected');
            return;
        }
        setIsPreparingDownload(true);  // Start loading indicator
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/leads/list/${selectedListId}/export/csv`, {
                responseType: 'blob',
            });
            console.log('CSV Export response:', response); // Log the response
            downloadFile(response);
        } catch (error) {
            console.error('Error exporting list to CSV:', error);
            alert('Failed to export list to CSV.');
        }
        setIsPreparingDownload(false);  // Stop loading indicator
    };

    const exportListToXLSX = async () => {
        if (!selectedListId) {
            alert('No list selected');
            return;
        }
        setIsPreparingDownload(true);  // Start loading indicator
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/leads/list/${selectedListId}/export/xlsx`, {
                responseType: 'blob',
            });
            console.log('XLSX Export response:', response); // Log the response
            downloadFile(response);
        } catch (error) {
            console.error('Error exporting list to XLSX:', error);
            alert('Failed to export list to XLSX.');
        }
        setIsPreparingDownload(false);  // Stop loading indicator
    };

    const menuItems = {

        "File": {
            icon: <FileCopySharp style={{ fontSize: "20px" }} />, // Assuming ZoomOutMapIcon as the icon for the Fix menu button for example
            items: [

                { text: "Refresh Data", icon: <RefreshSharp />, action: refreshData },


                { add: "Divider" },

                { text: "Create Empty List", icon: <AddCircleSharp />, action: () => setCreateListModalOpen(true) }, // This line is new

                { text: "Upload Excel File", icon: <RiFileExcel2Fill />, action: uploadFileList },
                { text: "New Lead", icon: <PersonAddSharpIcon />, action: uploadSingleLead },

                { add: "Divider" },

                { text: "Export to CSV", icon: <FileDownloadSharpIcon />, action: exportListToCSV },
                { text: "Export to XLSX", icon: <FileDownloadSharpIcon />, action: exportListToXLSX },


                { add: "Divider" },

                { text: "Delete Selected Leads", icon: <DeleteOutlineSharpIcon />, action: handleDeleteSelectedLeads },


            ],
        },
        "View": {
            icon: <ViewQuiltSharpIcon style={{ fontSize: "20px" }} />,
            items: [
                { text: "Show / Hide Lists", icon: <VerticalSplitSharpIcon />, action: togglePanelVisibility }, // New
                { add: "Divider" },
                { add: "Divider" },
                { add: "Divider" },
                { text: "Extra Condensed View", icon: <FormatAlignJustifySharp />, action: handleExtraCondensedView }, // New
                { text: "Condensed View", icon: <DensitySmallSharp />, action: handleCondensedView },
                { text: "Relaxed View", icon: <DensityMediumSharp />, action: handleRelaxedView },
                { text: "Extra Relaxed View", icon: <DensityLargeSharp />, action: handleExtraRelaxedView }, // New
            ],
        },
        // File: {
        //   icon: <FileCopySharpIcon style={{ fontSize: "20px" }} />,
        //   items: [
        //     { text: "New Account", icon: <AddSharpIcon />, action: onNewAccount },
        //     { text: "Connect Multiple Accounts", icon: <PlaylistAddSharpIcon />, action: onConnectMultiple },
        //     { add: "Divider" },
        //     { add: "Divider" },
        //     { add: "Divider" },
        //     { add: "Divider" },
        //     { add: "Divider" },
        //     { text: "Perform Connection Check", icon: <GppGoodSharpIcon />, action: () => setOpen(!open) },
        //   ],
        // },

        "Cleaning": {
            icon: <CleaningServicesSharpIcon style={{ fontSize: "20px" }} />, // Assuming ZoomOutMapIcon as the icon for the Fix menu button for example
            items: [
                { text: "Clean Duplicate Emails for Lead ", icon: <ContactMailSharpIcon />, action: handleadOperation('delete_duplicate_emails') },
                { text: "Clean Invalid Emails for Lead ", icon: <ErrorSharpIcon />, action: handleadOperation('delete_invalid_emails') },
                { text: "Clean Unknown Emails for Lead ", icon: <HelpSharpIcon />, action: handleadOperation('delete_unknown_emails') },

                { add: "Divider" },

                { text: "Process Duplicates in List ", icon: <CompareSharpIcon />, action: handleRemoveDuplicatesFromList },

            ],
        },

        // "Classification": {
        //     icon: <SmartButtonSharpIcon style={{ fontSize: "20px" }} />, // Assuming ZoomOutMapIcon as the icon for the Fix menu button for example
        //     items: [

        //     ],
        // },

        "Validation": {
            icon: <VerifiedSharpIcon style={{ fontSize: "20px" }} />, // Assuming ZoomOutMapIcon as the icon for the Fix menu button for example
            items: [
                { text: "Detect Free Emails ", icon: <SiGmail />, action: classifyEmails('personal') },
                { text: "Detect Generic Emails ", icon: <CorporateFareSharpIcon />, action: classifyEmails('generic') },
                { text: "Detect Temporary Emails ", icon: <AvTimerSharpIcon />, action: classifyEmails('disposable') },
                { text: "Detect Professional Emails ", icon: <BusinessCenterSharpIcon />, action: classifyEmails('professional') },
                { text: "Detect All Types", icon: <EmailSharp />, action: classifyEmails('all') },

                { add: "Divider" },
                { add: "Divider" },
                { add: "Divider" },

                { text: " Validate Emails ", icon: <VerifiedSharpIcon />, action: handleValidateEmails },

            ],
        },

        "AI Enrichment": {
            icon: <SmartButtonSharpIcon style={{ fontSize: "25px" }} />,
            items: [
                { text: "Detect Lead TimeZone", icon: <LocationOnSharpIcon />, action: detectLeadTimezone },
                { text: "Detect TimeZone for List", icon: <LocationOnSharpIcon />, action: detectTimeZoneForList },
                { add: "Divider" },
                { text: " Fix Company Names ", icon: <CleaningServicesSharpIcon />, action: handleFixCompanyName },
                // { text: " Enrich Linkedin Info ", icon: <CleaningServicesSharpIcon />, action: () => setPriorityModalOpen(true) },
                { add: "Divider" },
                // { text: "Generate Icebreaker from About", icon: <AutoFixHighSharpIcon />, action: () => generateIcebreakers('about') },
                // { text: "Generate Icebreaker from Latest Post", icon: <AutoFixHighSharpIcon />, action: () => generateIcebreakers('latestPost') }
                { text: "Generate Icebreaker", icon: <AutoFixHighSharpIcon />, action: () => setIcebreakerModalOpen(true) }

            ],
        },




    };

    const getTotalPendingJobs = () => {
        return [
            classificationJobCounts,
            companyNameFixJobCounts,
            linkedInEnrichmentJobCounts,
            emailValidationJobCounts,
            timezoneJobCounts,
            icebreakerJobCounts
        ].reduce((acc, jobCount) => acc + jobCount.waiting + jobCount.delayed, 0);
    };
    const totalPendingJobs = getTotalPendingJobs();



    return (
        <>
            {/* <GridToolbarContainer > */}
            <Grid container justifyContent="space-between" alignItems="center" sx={{ width: '100%' }}>
                <Grid item>
                    <Grid container alignItems="center">
                        <Button onClick={togglePanelVisibility} startIcon={<VerticalSplitSharpIcon />} />
                        <MenuBar menus={menuItems} />
                    </Grid>
                </Grid>

                {isPreparingDownload && (
                    <Card sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, zIndex: 1400, backgroundColor: 'secondary.main' }}>
                        <CardContent>
                            <Typography variant="h6" color="white">
                                Preparing your file...
                            </Typography>
                            <LinearProgress color="primary" />
                        </CardContent>
                    </Card>
                )}

                <Grid item>
                    <Grid container alignItems="center">
                        {showQueueChips && (
                            <Grid item>
                                <Grid container spacing={1}>
                                    <ListQueueChip jobCounts={classificationJobCounts} title="Email Classification" icon={<ImportContactsSharp color='info' sx={{ fontSize: "25px" }} />} />
                                    <ListQueueChip jobCounts={companyNameFixJobCounts} title="Company Name Fixing" icon={<PlumbingSharp color='info' sx={{ fontSize: "25px" }} />} />
                                    <ListQueueChip jobCounts={linkedInEnrichmentJobCounts} title="LinkedIn Enrichment" icon={<GrLinkedin style={{ fontSize: "25px", color: theme.palette.info.main }} />} />
                                    <ListQueueChip jobCounts={emailValidationJobCounts} title="Email Validation" icon={<MarkEmailReadSharp color='info' sx={{ fontSize: "25px" }} />} />
                                    <ListQueueChip jobCounts={timezoneJobCounts} title="TimeZone Detection" icon={<LocationOnSharp color='info' sx={{ fontSize: "25px" }} />} />
                                    <ListQueueChip jobCounts={icebreakerJobCounts} title="Icebreaker Generation" icon={<ArticleSharp color='info' sx={{ fontSize: "25px" }} />} />
                                </Grid>
                            </Grid>
                        )}
                        <Badge badgeContent={totalPendingJobs} color="error">
                            <Button onClick={toggleQueueChipsVisibility} variant="text">
                                {showQueueChips ? <MoreHorizSharp color='info' /> : <PendingSharpIcon color='info' />}
                            </Button>
                        </Badge>
                    </Grid>
                </Grid>
            </Grid>


            {/* </GridToolbarContainer> */}

            <div>
                <LinkedInPriorityModal
                    open={priorityModalOpen}
                    onClose={() => setPriorityModalOpen(false)}
                    onSubmit={handleEnrichLeads}
                    selectedLeadCount={selectedLeadIds.length}
                />
                <IcebreakerModal
                    open={icebreakerModalOpen}
                    onClose={() => setIcebreakerModalOpen(false)}
                    selectedLeadIds={selectedLeadIds}
                    selectedListId={selectedListId}
                />

            </div>
            <div>

                <CreateEmptyListModal open={createListModalOpen} onClose={() => setCreateListModalOpen(false)} />
            </div>

        </>
    );

};

export default CustomToolbar;
