// src\_metronic\layout\components\header\FeedBackManager\FeedbackManager.tsx
import React, { useState } from 'react';
import { IconButton, Menu, MenuItem, ListItemText } from '@mui/material';
import FeedbackIcon from '@mui/icons-material/Feedback';
import DraggableDialog from '@/src/app/modules/CustomMaterialUiComponents/DraggableDialog';
import FeedbackViewer from './FeedbackViewer'; // Import the new component

const FeedbackManager: React.FC = () => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [dialogOpen, setDialogOpen] = useState(false);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleViewFeedback = () => {
        setDialogOpen(true);
        handleClose();
    };

    const handleDialogClose = () => {
        setDialogOpen(false);
    };

    return (
        <>

            <IconButton onClick={handleViewFeedback}>
                <FeedbackIcon />
            </IconButton>
            {/* <IconButton onClick={handleClick}>
                <FeedbackIcon />
            </IconButton> */}
            {/* <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem onClick={handleViewFeedback}>
                    <ListItemText primary="View Feedback" />
                </MenuItem>
                <MenuItem onClick={handleClose}>
                    <ListItemText primary="New Feedback" />
                </MenuItem>
            </Menu> */}
            <DraggableDialog
                open={dialogOpen}
                onClose={handleDialogClose}
                content={<FeedbackViewer />}
                title="Feedback Viewer"
                backgroundBlur={false}
            />
        </>
    );
};

export default FeedbackManager;
