//src\app\pages\4-Campaigns\1-CampaignStatistics\Charts\UpcomingMessagesChart\CampaignContactedLeadsTable.tsx
import { ExpandMoreSharp, OpenInFullSharp } from '@mui/icons-material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ClearIcon from '@mui/icons-material/Clear';
import EmailIcon from '@mui/icons-material/Email';
import ErrorIcon from '@mui/icons-material/Error';
import LinkIcon from '@mui/icons-material/Link';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PendingIcon from '@mui/icons-material/Pending';
import ReplyIcon from '@mui/icons-material/Reply';
import SearchIcon from '@mui/icons-material/Search';
import SmsIcon from '@mui/icons-material/Sms';
import VisibilityIcon from '@mui/icons-material/Visibility';
import {
    Avatar,
    Box,
    Button,
    Chip,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    InputAdornment,
    LinearProgress,
    Paper,
    TextField,
    Typography,
    useTheme,
} from '@mui/material';
import { blue, green, grey, red, yellow } from '@mui/material/colors';
import { styled } from '@mui/material/styles';
import {
    DataGridPremium,
    GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
    GridColDef,
    gridDetailPanelExpandedRowsContentCacheSelector,
    GridRenderCellParams,
    GridRowParams,
    useGridApiContext,
    useGridSelector,
} from '@mui/x-data-grid-premium';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import ReactFlow, { Background, Controls, useEdgesState, useNodesState } from 'react-flow-renderer';
import { ReactFlowProvider } from 'reactflow';

// import { sampleSequenceDetails } from './sampledata';
import TurboEdge from './TurboNode/TurboEdge';
import TurboNode from './TurboNode/TurboNode';
import axios from 'axios';
import DOMPurify from 'dompurify';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { useAuth } from '@/src/app/modules/auth';
import CustomTooltip from '@/src/app/modules/CustomMaterialUiComponents/CustomTooltip';

dayjs.extend(utc);
dayjs.extend(timezone);

// Define TypeScript interfaces

interface GeoLocation {
    country: string;
    region: string;
    city: string;
}

interface Device {
    isMobile: boolean;
    isTablet: boolean;
    isDesktop: boolean;
    browser: string;
    os: string;
}

interface OpenTracking {
    campaignSentEmailId: string;
    leadId: string;
    campaignId: string;
    openDate: string;
    ipAddress: string;
    userAgent: string;
    geoLocation: GeoLocation;
    device: Device;
}

interface LinkTracking {
    uniqueLinkId: string;
    campaignSentEmailId: string;
    leadId: string;
    campaignId: string;
    clickedDate: string;
    originalUrl: string;
    redirectUrl: string;
    ipAddress: string;
    userAgent: string;
    geoLocation: GeoLocation;
    device: Device;
}

interface SequenceDetail {
    type: string;
    status: string;
    sentAt: string | null;
    subject: string | null;
    content: string | null;
    openTracking: OpenTracking[];
    linkTracking: LinkTracking[];
}

interface LeadInfo {
    personalizedmessageId: string;
    fullname: string;
    companyname: string;
    email: string;
    phone: string;
    location: string;
    timezone: string;
}

interface Tracking {
    plannedContactPoints: number;
    performedContactPoints: number;
    opens: number;
    clicks: number;
    replied: boolean;
}

interface ContactedLead {
    leadInfo: LeadInfo;
    tracking: Tracking;
}

export interface DetailedSequence {
    [key: string]: SequenceDetail[];
}



const getStatusIcon = (status: string) => {
    switch (status) {
        case 'sent':
            return <CheckCircleIcon sx={{ color: green[500] }} />;
        case 'pending':
            return <PendingIcon sx={{ color: yellow[700] }} />;
        case 'failed':
            return <ErrorIcon sx={{ color: red[500] }} />;
        case 'queued':
            return <PendingIcon sx={{ color: grey[500] }} />;
        default:
            return null;
    }
};

const getTypeIcon = (type: string) => {
    switch (type) {
        case 'email':
            return <EmailIcon sx={{ color: blue[500] }} />;
        case 'sms':
            return <SmsIcon sx={{ color: green[500] }} />;
        default:
            return null;
    }
};

const ProgressWrapper = styled(Box)(({ theme }) => ({
    position: 'relative',
    marginTop: theme.spacing(2),
}));

const ProgressLabel = styled(Typography)(({ theme }) => ({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    color: theme.palette.text.secondary,
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: theme.palette.primary.main,
        },
        '&:hover fieldset': {
            borderColor: theme.palette.primary.dark,
        },
        '&.Mui-focused fieldset': {
            borderColor: theme.palette.primary.main,
        },
    },
}));

const MetricChip = styled(Chip)(({ theme }) => ({
    margin: theme.spacing(0.5),
    '& .MuiChip-icon': {
        color: 'inherit',
    },
}));

const StyledControls = styled(Controls)(({ theme }) => ({
    '& button': {
        backgroundColor: theme.palette.background.default,
        color: theme.palette.text.primary,
        border: `1px solid ${theme.palette.divider}`,
    },
    '& button:hover': {
        backgroundColor: theme.palette.action.hover,
    },
    '& button:first-child': {
        borderRadius: '5px 5px 0 0',
    },
    '& button:last-child': {
        borderBottom: `1px solid ${theme.palette.divider}`,
        borderRadius: '0 0 5px 5px',
    },
    '& button path': {
        fill: theme.palette.text.primary,
    },
}));

interface CustomDetailPanelToggleProps extends Pick<GridRenderCellParams, 'id' | 'value'> {
    expandedRows: Set<string>;
    setExpandedRows: React.Dispatch<React.SetStateAction<Set<string>>>;
}

function CustomDetailPanelToggle(props: CustomDetailPanelToggleProps) {
    const { id, value: isExpanded, expandedRows, setExpandedRows } = props;
    const theme = useTheme();
    const apiRef = useGridApiContext();

    // To avoid calling ´getDetailPanelContent` all the time, the following selector
    // gives an object with the detail panel content for each row id.
    const contentCache = useGridSelector(
        apiRef,
        gridDetailPanelExpandedRowsContentCacheSelector,
    );

    // If the value is not a valid React element, it means that the row has no detail panel.
    const hasDetail = React.isValidElement(contentCache[id]);

    const handleClick = () => {
        setExpandedRows((prevExpandedRows) => {
            const newExpandedRows = new Set(prevExpandedRows);
            if (newExpandedRows.has(id.toString())) {
                newExpandedRows.delete(id.toString());
            } else {
                newExpandedRows.add(id.toString());
            }
            return newExpandedRows;
        });
    };


    return (
        <Box
            sx={{
                backgroundColor: isExpanded
                    ? theme.palette.mode === 'dark'
                        ? theme.palette.action.selected
                        : "#CCB4D8"
                    : theme.palette.background.paper,
                padding: '0.5rem',
                borderRadius: '4px', // Optional: for rounded corners 
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
            onClick={handleClick}
        >
            <Button
                size="small"
                variant='text'
                tabIndex={-1}
                disabled={!hasDetail}
                aria-label={isExpanded ? 'Close' : 'Open'}
            >
                <ExpandMoreSharp
                    color='info'
                    sx={{
                        transform: `rotateZ(${isExpanded ? 180 : 0}deg)`,
                        transition: (theme) =>
                            theme.transitions.create('transform', {
                                duration: theme.transitions.duration.shortest,
                            }),
                        fontSize: "35px",
                        marginRight: "20px"
                    }}
                />
            </Button>
        </Box>
    );
}




// const CustomToolbar = () => (
//     <GridToolbarContainer>
//         <GridToolbarExport />
//     </GridToolbarContainer>
// );

const CampaignContactedLeadsTable: React.FC<{ contactedLeads: ContactedLead[] }> = ({ contactedLeads }) => {
    const { currentUser } = useAuth();

    const [searchTerm, setSearchTerm] = useState('');
    const [nodes, setNodes, onNodesChange] = useNodesState([]);
    const [edges, setEdges, onEdgesChange] = useEdgesState([]);
    const [detailedSequence, setDetailedSequence] = useState<DetailedSequence>({});
    const [dialogOpen, setDialogOpen] = useState(false);
    const [emailContent, setEmailContent] = useState('');
    const [openDetailPanelRowIds, setOpenDetailPanelRowIds] = useState<string[]>([]);
    const [expandedRows, setExpandedRows] = useState<Set<string>>(new Set());
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);



    const mainGridColumns: GridColDef[] = [
        {
            field: 'fullname',
            headerName: 'Full Name',
            renderCell: (params) => (
                <Box display="flex" alignItems="center">
                    <Avatar sx={{ bgcolor: blue[500], mr: 2 }}>
                        {params.row.leadInfo.fullname.charAt(0)}
                    </Avatar>
                    {params.row.leadInfo.fullname}
                </Box>
            ),
            flex: 1,
            editable: false

        },
        {
            field: 'companyname',
            headerName: 'Company Name',
            editable: false,
            flex: 1,
            valueGetter: (params) => params.row.leadInfo.companyname,
        },
        {
            field: 'email',
            headerName: 'Email',
            editable: false,
            flex: 1,
            valueGetter: (params) => params.row.leadInfo.email,
        },
        {
            field: 'phone',
            headerName: 'Phone',
            editable: false,
            flex: 1,
            valueGetter: (params) => params.row.leadInfo.phone,
        },
        {
            field: 'location',
            headerName: 'Location',
            renderCell: (params) => (
                <Chip icon={<LocationOnIcon />} label={params.row.leadInfo.location} variant="filled" />
            ),
            valueGetter: (params) => params.row.leadInfo.location,
            flex: 1,
            editable: false,
        },
        {
            field: 'progress',
            headerName: 'Progress',
            renderCell: (params) => {
                const progressPercentage =
                    (params.row.tracking.performedContactPoints / params.row.tracking.plannedContactPoints) * 100;
                return (
                    <CustomTooltip title={`Planned: ${params.row.tracking.plannedContactPoints}, Performed: ${params.row.tracking.performedContactPoints}`}>
                        <Box alignItems="center" width="100%" sx={{ position: 'relative', display: 'inline-flex' }}>
                            <LinearProgress
                                variant="determinate"
                                value={progressPercentage}
                                sx={{ height: 10, width: '100%', borderRadius: 5 }}
                            />
                            <ProgressLabel variant="caption" sx={{ position: 'absolute' }}>
                                {`${Math.round(progressPercentage)}%`}
                            </ProgressLabel>
                        </Box>
                    </CustomTooltip>
                );
            },
            valueGetter: (params) =>
                (params.row.tracking.performedContactPoints / params.row.tracking.plannedContactPoints) * 100,
            flex: 1,
            editable: false,
        },
        {
            field: 'opens',
            headerName: 'Opens',
            renderCell: (params) => (
                <MetricChip
                    icon={<VisibilityIcon />}
                    label={params.row.tracking.opens}
                    color="primary"
                    variant="filled"
                />
            ),
            valueGetter: (params) => params.row.tracking.opens,
            flex: 1,
            editable: false,
        },
        {
            field: 'clicks',
            headerName: 'Clicks',
            renderCell: (params) => (
                <MetricChip
                    icon={<LinkIcon />}
                    label={params.row.tracking.clicks}
                    color="secondary"
                    variant="filled"
                />
            ),
            valueGetter: (params) => params.row.tracking.clicks,
            flex: 1,
            editable: false,
        },
        {
            field: 'replied',
            headerName: 'Replied',
            renderCell: (params) => (
                <MetricChip
                    icon={<ReplyIcon />}
                    label={params.row.tracking.replied ? 'Yes' : 'No'}
                    color={params.row.tracking.replied ? 'success' : 'default'}
                    variant="filled"
                />
            ),
            valueGetter: (params) => (params.row.tracking.replied ? 1 : 0),
            flex: 1,
            editable: false,
        },
        {
            ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
            width: 100,
            renderCell: (params) => (
                <CustomDetailPanelToggle
                    id={params.id}
                    value={params.value}
                    expandedRows={expandedRows}
                    setExpandedRows={setExpandedRows}
                />
            ),
        },

    ];

    const handleDialogOpen = (content) => {
        setEmailContent(content);
        setDialogOpen(true);
    };

    const handleDialogClose = () => {
        setDialogOpen(false);
        setEmailContent('');
    };


    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value);
    };

    const clearSearch = () => {
        setSearchTerm('');
    };

    const filteredRows = useMemo(() => {
        return contactedLeads.filter((row: ContactedLead) =>
            Object.values(row.leadInfo).some((value) =>
                //@ts-ignore
                value.toString().toLowerCase().includes(searchTerm.toLowerCase())
            )
        );
    }, [contactedLeads, searchTerm]);

    const theme = useTheme();

    const fetchSequenceDetails = async (personalizedMessageId: string) => {
        setLoading(true);
        setError(null);
        try {
            const response = await axios.get<{ sequence: { details: SequenceDetail[] } }>(`${process.env.REACT_APP_API_URL}/campaign/personalizedmessage/${personalizedMessageId}/sequence`);
            const details = response.data.sequence.details;
            setDetailedSequence((prev) => ({ ...prev, [personalizedMessageId]: details }));
        } catch (error) {
            console.error('Error fetching sequence details:', error);
            setError('Failed to load sequence details. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    const convertToUserTimezone = (time: string) => {
        if (!currentUser) return time;
        return dayjs(time).tz(currentUser.timezone).format('YYYY-MM-DDTHH:mm:ssZ');
    };


    const DetailPanelContent = ({ row }) => {
        const personalizedMessageId = row.leadInfo.personalizedmessageId;

        useEffect(() => {
            if (!detailedSequence[personalizedMessageId]) {
                fetchSequenceDetails(personalizedMessageId);
            }
        }, [personalizedMessageId, detailedSequence]);

        const sequenceDetails = detailedSequence[personalizedMessageId] || [];

        const initialNodes = useMemo(() =>
            sequenceDetails.map((detail, index) => ({
                id: `node-${index}`,
                data: {
                    title: `${detail.type} - ${detail.status}`,
                    subline: detail.status,
                    icon: getTypeIcon(detail.type),
                    date: detail.sentAt ? convertToUserTimezone(detail.sentAt).split('T')[0] : 'Not Sent',
                    sendingTime: detail.sentAt ? convertToUserTimezone(detail.sentAt).split('T')[1] : 'Not Sent',
                },
                position: { x: 0, y: index * 150 },
                type: 'turbo',
            }))
            , [sequenceDetails]);

        const initialEdges = useMemo(() =>
            sequenceDetails.map((detail, index) => ({
                id: `edge-${index}`,
                source: index === 0 ? 'start' : `node-${index - 1}`,
                target: `node-${index}`,
                type: 'turbo',
                style: { stroke: theme.palette.divider },
                animated: true,
            }))
            , [sequenceDetails, theme.palette.divider]);

        useEffect(() => {
            setNodes(initialNodes);
            setEdges(initialEdges);
        }, [initialNodes, initialEdges]);

        return (
            <Box>
                <Box sx={{ display: 'flex' }}>
                    <Box sx={{ flex: 1, height: '300px', position: 'relative' }}>
                        {loading ? (
                            <Box display="flex" alignItems="center" justifyContent="center" height="100%">
                                <CircularProgress />
                            </Box>
                        ) : error ? (
                            <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" height="100%">
                                <Typography color="error" gutterBottom>{error}</Typography>
                                <Button variant="contained" color="primary" onClick={() => fetchSequenceDetails(personalizedMessageId)}>Retry</Button>
                            </Box>
                        ) : (
                            <ReactFlowProvider>
                                <ReactFlow
                                    nodes={initialNodes}
                                    edges={initialEdges}
                                    fitView
                                    fitViewOptions={{
                                        padding: 0.3,
                                        minZoom: 0.2,
                                        maxZoom: 1,
                                        duration: 500
                                    }}
                                    onNodesChange={onNodesChange}
                                    onEdgesChange={onEdgesChange}
                                    nodeTypes={{ turbo: TurboNode }}
                                    edgeTypes={{ turbo: TurboEdge }}
                                    style={{ height: "100%", cursor: "grab" }}
                                    nodesDraggable={false}
                                    nodesConnectable={false}
                                    elementsSelectable={false}
                                    panOnScroll={false}
                                    zoomOnScroll
                                    zoomOnPinch
                                    panOnDrag
                                    zoomOnDoubleClick
                                >
                                    <StyledControls showInteractive={false} />
                                    <Background
                                        color={theme.palette.primary.main}
                                        style={{ backgroundColor: theme.palette.background.default }}
                                    />
                                </ReactFlow>
                            </ReactFlowProvider>
                        )}
                    </Box>
                    <Box sx={{ flex: 1, height: '300px', position: 'relative' }}>
                        <DataGridPremium
                            density="compact"
                            rowSelection={false}
                            sx={{ zIndex: 2147483647, height: "100%", borderRadius: "0px", border: "solid 0px" }}
                            columns={[
                                {
                                    field: 'type',
                                    headerName: 'Type',
                                    resizable: true,
                                    renderCell: (params) => (
                                        <CustomTooltip title={params.value}>
                                            <Box display="flex" alignItems="center">
                                                {getTypeIcon(params.value)}
                                                <Typography sx={{ ml: 1 }}>{params.value}</Typography>
                                            </Box>
                                        </CustomTooltip>
                                    ),
                                },
                                {
                                    field: 'status',
                                    headerName: 'Status',
                                    resizable: true,
                                    renderCell: (params) => (
                                        <CustomTooltip title={params.value}>
                                            <Box display="flex" alignItems="center">
                                                {getStatusIcon(params.value)}
                                                <Typography sx={{ ml: 1 }}>{params.value}</Typography>
                                            </Box>
                                        </CustomTooltip>
                                    ),
                                },
                                {
                                    field: 'sentAt',
                                    headerName: 'Sent At',
                                    resizable: true,
                                    renderCell: (params) => (
                                        <CustomTooltip title={params.value ? dayjs(convertToUserTimezone(params.value)).format('MMMM D, YYYY h:mm A') : 'Not Sent'}>
                                            <Typography>
                                                {params.value ? dayjs(convertToUserTimezone(params.value)).format('MMMM D, YYYY h:mm A') : 'Not Sent'}
                                            </Typography>
                                        </CustomTooltip>
                                    ),
                                },
                                {
                                    field: 'subject',
                                    headerName: 'Subject',
                                    resizable: true,
                                    renderCell: (params) => (
                                        <CustomTooltip title={params.value || 'N/A'}>
                                            <Typography>{params.value || 'N/A'}</Typography>
                                        </CustomTooltip>
                                    ),
                                },
                                {
                                    field: 'content',
                                    headerName: 'Content',
                                    resizable: true,
                                    renderCell: (params) => (
                                        <>
                                            <IconButton onClick={() => handleDialogOpen(params.value)} aria-label="expand">
                                                <OpenInFullSharp />
                                            </IconButton>
                                            <CustomTooltip title={<span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(params.value) }} />}>
                                                <Typography noWrap>
                                                    <span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(params.value) }} />
                                                </Typography>
                                            </CustomTooltip>
                                        </>
                                    ),
                                },
                            ]}
                            rows={sequenceDetails.map((detail, index) => ({ id: `${row.leadInfo.email}-detail-${index}`, ...detail }))}
                            getRowId={(row) => row.id}
                            hideFooter
                        />
                    </Box>
                </Box>
            </Box>
        );
    };

    const getDetailPanelContent = useCallback((params) => <DetailPanelContent {...params} />, [detailedSequence, loading, error, onNodesChange, onEdgesChange, setNodes, setEdges, theme.palette.divider, theme.palette.primary.main, theme.palette.background.default]);

    const handleRowDoubleClick = useCallback((params: GridRowParams) => {
        setExpandedRows((prevExpandedRows) => {
            const newExpandedRows = new Set(prevExpandedRows);
            if (newExpandedRows.has(params.id.toString())) {
                newExpandedRows.delete(params.id.toString());
            } else {
                newExpandedRows.add(params.id.toString());
            }
            return newExpandedRows;
        });
    }, []);



    return (
        <Box sx={{ p: 3 }}>
            <Typography
                variant="h4"
                gutterBottom
                sx={{ mb: 3, fontWeight: 'bold', color: (theme) => theme.palette.primary.main }}
            >
                Campaign Contacted Leads
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                <StyledTextField
                    fullWidth
                    variant="outlined"
                    placeholder="Search leads..."
                    value={searchTerm}
                    onChange={handleSearch}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                        endAdornment: searchTerm && (
                            <InputAdornment position="end">
                                <IconButton onClick={clearSearch} edge="end" aria-label="clear search">
                                    <ClearIcon />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
            </Box>
            {filteredRows.length === 0 ? (
                <Box sx={{ textAlign: 'center', mt: 5 }}>
                    <Typography variant="h6" color="textSecondary">
                        No leads found.
                    </Typography>
                </Box>
            ) : (
                <Paper>
                    <DataGridPremium
                        columns={mainGridColumns}
                        rows={filteredRows.map((row, index) => ({ id: row.leadInfo.personalizedmessageId, ...row }))}
                        getRowId={(row) => row.id}
                        sx={{
                            border: "solid 0px",
                            '& .MuiDataGrid-columnHeaders, & .MuiDataGrid-footerContainer': {
                                backgroundColor: (theme) => theme.palette.background.paper,
                            },
                        }}
                        getDetailPanelContent={getDetailPanelContent}
                        pinnedColumns={{ right: [GRID_DETAIL_PANEL_TOGGLE_COL_DEF.field] }}
                        getDetailPanelHeight={() => 300}
                        onRowDoubleClick={handleRowDoubleClick}
                        detailPanelExpandedRowIds={Array.from(expandedRows)}
                        rowSelection={false}
                        pagination
                        paginationModel={{
                            page: page,
                            pageSize: pageSize,
                        }}
                        onPaginationModelChange={(model) => {
                            setPage(model.page);
                            setPageSize(model.pageSize);
                        }}
                        pageSizeOptions={[5, 10, 25]}
                    />
                </Paper>
            )}

            <Dialog open={dialogOpen} onClose={handleDialogClose}>
                <DialogTitle>Email Content</DialogTitle>
                <DialogContent>
                    <DialogContentText>

                        <span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(emailContent) }} />

                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>

        </Box>
    );
};

export default CampaignContactedLeadsTable;
