import React from 'react';
import { useLocation } from 'react-router-dom';
import { Button, Typography, Container, Box } from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { Link } from 'react-router-dom';

const parseQuery = (query) => {
    return new URLSearchParams(query);
};

const GoogleAuthFailurePage = () => {
    const { search } = useLocation();
    const query = parseQuery(search);
    const reason = query.get('reason') || 'Unknown error occurred';

    return (
        <Container component="main" maxWidth="xs" sx={{ mt: 8 }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <ErrorOutlineIcon sx={{ m: 1, fontSize: 60, color: 'error.main' }} />
                <Typography component="h1" variant="h5" sx={{ mb: 2 }}>
                    Google Workspace Connection Failed
                </Typography>
                <Typography variant="body1" sx={{ textAlign: 'center', mb: 3 }}>
                    {decodeURIComponent(reason)}
                </Typography>
                <Link to="/email-accounts" style={{ textDecoration: 'none' }}>
                    <Button type="button" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
                        Try Again
                    </Button>
                </Link>
            </Box>
        </Container>
    );
};

export default GoogleAuthFailurePage;
