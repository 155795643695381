// src\app\pages\4-Campaigns\3-CampaignSequence\SequenceComponents\NewEditor\components\LexicalEditorWrapper\stateplugin.tsx
import { useEffect, useRef } from "react";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { $getRoot, $createParagraphNode, $createTextNode, $insertNodes } from "lexical";
import { $generateNodesFromDOM } from "@lexical/html";
import { $createMentionNode } from "../MentionPlugin/MentionNode";

// Mock function to simulate fetching data from an API


export default function InitialStatePlugin({ initialContent, contentKey, emailType }) {
    const [editor] = useLexicalComposerContext();
    const lastInitializedContentKey = useRef(null);

    useEffect(() => {
        if (contentKey !== lastInitializedContentKey.current) {
            editor.update(() => {
                const root = $getRoot();
                root.clear();

                if (emailType === 'html') {
                    // Directly manipulate the HTML string to identify mention placeholders
                    // and replace them with a unique span that can be recognized later.
                    // Note: This assumes the HTML content does not already contain spans
                    // with the class 'mention-placeholder' for other purposes.
                    const updatedHtmlContent = initialContent.replace(/(\{\{\{([^}]+)\}\}\})/g, (match, p1, p2) => {
                        return `<span data-lexical-mention="true">${p1}</span>`;
                    });

                    const parser = new DOMParser();
                    const dom = parser.parseFromString(updatedHtmlContent, "text/html");

                    // Convert updated DOM back to HTML string if necessary
                    // or directly generate Lexical nodes from the updated DOM.
                    // This step depends on how $generateNodesFromDOM is implemented and used.
                    const nodes = $generateNodesFromDOM(editor, dom);

                    // Assuming $generateNodesFromDOM can handle the updated DOM correctly,
                    // and you have a way to recognize 'mention-placeholder' spans as mentions.
                    $getRoot().select();
                    $insertNodes(nodes);
                } else {
                    // For plain text or SMS, detect and transform placeholders into mention nodes
                    // including the triple curly braces in the mention node text
                    const regex = /(\{\{\{[^}]+\}\}\})/g;
                    let lastIndex = 0;
                    let match;

                    const paragraph = $createParagraphNode();

                    while ((match = regex.exec(initialContent)) !== null) {
                        if (match.index > lastIndex) {
                            const textBefore = initialContent.slice(lastIndex, match.index);
                            paragraph.append($createTextNode(textBefore));
                        }

                        // Adjust the mention text to include the curly braces
                        const mentionTextWithBraces = match[0]; // Entire match including braces
                        const mentionNode = $createMentionNode(mentionTextWithBraces);
                        if (mentionNode) {
                            paragraph.append(mentionNode);
                        }

                        lastIndex = regex.lastIndex;
                    }

                    if (lastIndex < initialContent.length) {
                        const textAfter = initialContent.slice(lastIndex);
                        paragraph.append($createTextNode(textAfter));
                    }

                    root.append(paragraph);
                }
            });

            lastInitializedContentKey.current = contentKey;
            // Optionally, focus the editor after setting initial content
            editor.focus();
        }
    }, [editor, initialContent, contentKey, emailType]);

    return null;
}
