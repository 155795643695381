/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState, useRef } from 'react'
import { getCSS, getCSSVariableValue } from '../../../../../_metronic/assets/ts/_utils'
import { useThemeMode } from '../../../../../_metronic/partials/layout/theme-mode/ThemeModeProvider'
import axios from 'axios';
import ReactApexChart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
/*
// ---------  MOCKKKKKKKKKKK ------------------- --- 
// from here to the next stop with MOCK -------------
//  delete this to stop the mock engine -------------

import MockAdapter from 'axios-mock-adapter';

// Create a new instance of MockAdapter for axios
const mock = new MockAdapter(axios);

// Mock data for different time frames
const mockData = {
    lifetime: [
        { netProfit: 1000, revenue: 2000, expenses: 500 },
        { netProfit: 1100, revenue: 2100, expenses: 550 },
        { netProfit: 1200, revenue: 2200, expenses: 600 },
        { netProfit: 1300, revenue: 2300, expenses: 650 },
        { netProfit: 1400, revenue: 2400, expenses: 700 },
    ],
    year: [
        { netProfit: 100, revenue: 200, expenses: 50 },
        { netProfit: 2, revenue: 210, expenses: 55 },
        { netProfit: 120, revenue: 1, expenses: 60 },
        { netProfit: 130, revenue: 230, expenses: 65 },
        { netProfit: 140, revenue: 240, expenses: 0 },
    ],
    month: [
        { netProfit: 50, revenue: 100, expenses: 25 },
        { netProfit: 55, revenue: 105, expenses: 27 },
        { netProfit: 60, revenue: 110, expenses: 30 },
        { netProfit: 65, revenue: 115, expenses: 32 },
        { netProfit: 70, revenue: 120, expenses: 35 },
    ],
    week: [
        { netProfit: 10, revenue: 20, expenses: 5 },
        { netProfit: 11, revenue: 21, expenses: 5.5 },
        { netProfit: 12, revenue: 22, expenses: 6 },
        { netProfit: 13, revenue: 23, expenses: 6.5 },
        { netProfit: 14, revenue: 24, expenses: 7 },        // ... add more mock data for week
    ],
};

// Set up mock responses for the different time frames
Object.keys(mockData).forEach((timeFrame) => {
    mock.onGet(`YOUR_API_ENDPOINT_HERE?timeFrame=${timeFrame}`).reply(200, mockData[timeFrame]);
});
// MOCK MOCKKKKKKKKKKKKK --------------------------------------------
// MOCK MOCKKKKKKKKKKKKK --------------------------------------------
// MOCK MOCKKKKKKKKKKKKK --------------------------------------------
// Delete Previous // @Somar ----------------------------------------
*/
type Props = {
    className: string
}

const CampaignTracking: React.FC<Props> = ({ className }) => {
    const { mode } = useThemeMode()
    const [chartData, setChartData] = useState<any[]>([]); // State to store the fetched data

    const [timeFrame, setTimeFrame] = useState<string>('lifetime'); // State to store the selected time frame

    const fetchData = async (timeFrame: string) => {
        try {
            const response = await axios.get(`YOUR_API_ENDPOINT_HERE?timeFrame=${timeFrame}`);
            setChartData(response.data);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }


    useEffect(() => {
        fetchData(timeFrame); // Fetch the data when the component mounts or timeFrame changes
    }, [timeFrame]);

    return (
        <div className={`card ${className}`}>

            {/* begin::Header */}
            <div className='card-header border-0 pt-5'>
                <h3 className='card-title align-items-start flex-column'>
                    <span className='card-label fw-bold fs-3 mb-1'>Recent Users</span>

                    <span className='text-muted fw-semibold fs-7'>More than 500 new users</span>
                </h3>

                {/* begin::Toolbar */}
                <div className='card-toolbar' data-kt-buttons='true'>
                    <a
                        className='btn btn-sm btn-color-muted btn-active btn-active-primary px-4 me-1'
                        id='kt_charts_widget_7_year_btn'
                        onClick={() => setTimeFrame('year')}
                    >
                        Year
                    </a>
                    <a
                        className='btn btn-sm btn-color-muted btn-active btn-active-primary px-4 me-1'
                        id='kt_charts_widget_7_month_btn'
                        onClick={() => setTimeFrame('month')}
                    >
                        Month
                    </a>
                    <a
                        className='btn btn-sm btn-color-muted btn-active btn-active-primary px-4 me-1'
                        id='kt_charts_widget_7_week_btn'
                        onClick={() => setTimeFrame('week')}
                    >
                        Week
                    </a>
                    <a
                        className='btn btn-sm btn-color-muted btn-active btn-active-primary px-4'
                        id='kt_charts_widget_7_lifetime_btn'
                        onClick={() => setTimeFrame('lifetime')}
                    >
                        Lifetime
                    </a>
                </div>
                {/* end::Toolbar */}
            </div>
            {/* end::Header */}

            {/* begin::Body */}
            <div className='card-body'>
                {/* begin::Chart */}
                <ReactApexChart
                    options={getChartOptions(350, chartData, timeFrame)}
                    series={getChartOptions(350, chartData, timeFrame).series}
                    type="area"
                    height={350}
                    className='card-rounded-bottom'
                />
                {/* end::Chart */}
            </div>
            {/* end::Body */}
        </div>
    )
}

export default CampaignTracking

// Modify this function to structure the fetched data as required by the chart
function getChartOptions(height: number, data: any[], timeFrame: string): ApexOptions {
    let categories: string[] = [];
    switch (timeFrame) {
        case 'year':
            categories = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
            break;
        case 'month':
            categories = ['Week 1', 'Week 2', 'Week 3', 'Week 4'];
            break;
        case 'week':
            categories = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
            break;
        case 'lifetime':
            categories = ['Year 1', 'Year 2', 'Year 3', 'Year 4', 'Year 5']; // or any other appropriate labels
            break;
        default:
            categories = [];
    }

    const labelColor = getCSSVariableValue('--bs-gray-500')
    const borderColor = getCSSVariableValue('--bs-gray-200')
    const strokeColor = getCSSVariableValue('--bs-gray-300')

    const color1 = getCSSVariableValue('--bs-warning')
    const color1Light = getCSSVariableValue('--bs-warning-light')

    const color2 = getCSSVariableValue('--bs-success')
    const color2Light = getCSSVariableValue('--bs-success-light')

    const color3 = getCSSVariableValue('--bs-primary')
    const color3Light = getCSSVariableValue('--bs-primary-light')

    return {
        series: [
            {
                name: 'Net Profit',
                data: data.map(item => item.netProfit), // Example mapping
            },
            {
                name: 'Revenue',
                data: data.map(item => item.revenue), // Example mapping
            },
            {
                name: 'Expenses',
                data: data.map(item => item.expenses), // Example mapping
            },
        ],

        chart: {
            fontFamily: 'inherit',
            type: 'area',
            height: height,
            toolbar: {
                show: false,
            },
            zoom: {
                enabled: false,
            },
            sparkline: {
                enabled: true,
            },
        },
        plotOptions: {},
        legend: {
            show: false,
        },
        dataLabels: {
            enabled: false,
        },
        fill: {
            type: 'solid',
            opacity: 1,
        },
        stroke: {
            curve: 'smooth',
            show: true,
            width: 2,
            colors: [color1, 'transparent', 'transparent'],
        },
        xaxis: {
            categories: categories,
            axisBorder: {
                show: false,
            },
            axisTicks: {
                show: false,
            },
            labels: {
                show: false,
                style: {
                    colors: labelColor,
                    fontSize: '12px',
                },
            },
            crosshairs: {
                show: false,
                position: 'front',
                stroke: {
                    color: strokeColor,
                    width: 1,
                    dashArray: 3,
                },
            },
            tooltip: {
                enabled: true,
                offsetY: 0,
                style: {
                    fontSize: '12px',
                },
            },
        },

        yaxis: {
            labels: {
                show: false,
                style: {
                    colors: labelColor,
                    fontSize: '12px',
                },
                formatter: function (value: number) {
                    return '$' + value + ' thousands';
                },
            },
        },
        states: {
            normal: {
                filter: {
                    type: 'none',
                    value: 0,
                },
            },
            hover: {
                filter: {
                    type: 'none',
                    value: 0,
                },
            },
            active: {
                allowMultipleDataPointsSelection: false,
                filter: {
                    type: 'none',
                    value: 0,
                },
            },
        },
        tooltip: {
            style: {
                fontSize: '12px',
            },
        },
        colors: [color1, color2, color3],
        grid: {
            borderColor: borderColor,
            strokeDashArray: 4,
            yaxis: {
                lines: {
                    show: true,
                },
            },
        },
        markers: {
            colors: [color1Light, color2Light, color3Light],
            strokeColors: [color1, color2, color3],
            strokeWidth: 3,
        },
    }
}

