import TextField from '@mui/material/TextField';
import React, { useEffect, useState } from 'react';
import { IMaskInput } from 'react-imask';

interface CustomProps {
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    name: string;
    label: string;
    value: string;
}

const MaskedInput = React.forwardRef<HTMLInputElement, CustomProps>(
    function MaskedInput(props, ref) {
        const { onChange, name, label, value, ...other } = props;
        const [actualValue, setActualValue] = useState('');
        const [formattedValue, setFormattedValue] = useState('');

        const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
            const { name, value } = event.target;

            // Remove spaces from the value and update the actualValue state
            const actualValueWithoutSpaces = value.replace(/ /g, '');
            setActualValue(actualValueWithoutSpaces);

            // Update the formattedValue state with the value containing spaces
            setFormattedValue(value);

            // Create a synthetic event object
            const syntheticEvent = {
                ...event,
                target: { ...event.target, value: actualValueWithoutSpaces }
            };

            // Call the parent onChange function with the synthetic event
            onChange(syntheticEvent as React.ChangeEvent<HTMLInputElement>);
        };

        return (
            <TextField
                {...other}
                label={label}
                value={formattedValue}
                name={name}
                onChange={handleInputChange}
                margin="normal"
                fullWidth
                variant="outlined"
                helperText="Enter the app password you generated in your Google account settings."
                InputProps={{
                    inputComponent: IMaskInput as any,
                    inputProps: {
                        mask: '**** **** **** ****',
                        definitions: {
                            '*': /[A-Za-z0-9]/
                        },
                        inputRef: ref,
                        onAccept: (value: string) => {
                            // Create a synthetic event object for onAccept
                            const syntheticEvent = {
                                target: { name, value }
                            };
                            handleInputChange(syntheticEvent as unknown as React.ChangeEvent<HTMLInputElement>);
                        }
                    }
                }}
            />
        );
    },
);

export default MaskedInput;
