import * as React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';

interface MoveListDialogProps {
    open: boolean;
    folders: Array<{ _id: string; name: string }>;
    onClose: () => void;
    listId: string;
    onMoveList: (listId: string, folderId: string) => void;
}

const MoveListDialog: React.FC<MoveListDialogProps> = ({ open, folders, onClose, listId, onMoveList }) => {
    const [selectedFolder, setSelectedFolder] = React.useState<string>(''); // Specify the type for selectedFolder

    const handleFolderChange = (event: SelectChangeEvent<string>) => { // Use SelectChangeEvent<string> type
        setSelectedFolder(event.target.value); // No need for type assertion here
    };

    const handleMoveClick = () => {
        if (selectedFolder) {
            onMoveList(listId, selectedFolder);
            onClose(); // Ensure dialog is closed after initiating the move
        } else {
            console.log("No folder selected");
        }
    };


    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Move List to Folder</DialogTitle>
            <DialogContent>
                <FormControl fullWidth>
                    <InputLabel id="folder-select-label">Select Folder</InputLabel>
                    <Select
                        labelId="folder-select-label"
                        id="folder-select"
                        value={selectedFolder}
                        label="Select Folder"
                        onChange={handleFolderChange}
                    >
                        {folders.map((folder) => (
                            <MenuItem key={folder._id} value={folder._id}>
                                {folder.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button onClick={handleMoveClick} color="primary">
                    Move
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default MoveListDialog;
