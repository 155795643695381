import './PlaceholderCards.css';

import CustomTooltip from '@/src/app/modules/CustomMaterialUiComponents/CustomTooltip';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import {
    Button,
    ButtonBase,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Menu,
    MenuItem,
    TextField,
} from '@mui/material';
import React, { useEffect, useState } from 'react';

import { PersonalizationHeader } from './SequenceSteps';


interface PlaceholderCardsProps {
    selectedCampaignId: any;
    headers: PersonalizationHeader[];
    fetchHeaders: (campaignId: string) => void;
}



const PlaceholderCards: React.FC<PlaceholderCardsProps> = ({ selectedCampaignId, headers, fetchHeaders }) => {
    const [copiedKey, setCopiedKey] = useState<string | null>(null);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [selectedKey, setSelectedKey] = useState<string>('');
    const [infoDialogOpen, setInfoDialogOpen] = useState(false);
    const [infoData, setInfoData] = useState<string>(''); // Store detailed info data
    const [searchQuery, setSearchQuery] = useState<string>(''); // State for the search query
    const [filteredHeaders, setFilteredHeaders] = useState<PersonalizationHeader[]>([]); // State for filtered headers


    // Use useEffect to fetch data when selectedCampaignId changes
    useEffect(() => {
        fetchHeaders(selectedCampaignId);
    }, [selectedCampaignId]);

    // Function to copy to clipboard
    const copyToClipboard = (text: string, key: string) => {
        navigator.clipboard.writeText(text).then(() => {
            setCopiedKey(key);
            setTimeout(() => setCopiedKey(null), 3000); // Reset after 3 seconds
            handleCloseMenu(); // Close the menu
        });
    };

    // Open menu handler
    const handleRightClick = (event: React.MouseEvent<HTMLDivElement>, key: string) => {
        event.preventDefault(); // Prevent the default context menu
        setAnchorEl(event.currentTarget);
        setSelectedKey(key);
    };

    // Close menu handler
    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    // Handle closing the info dialog
    const handleCloseInfoDialog = () => {
        setInfoDialogOpen(false);
    };

    // Handle search input changes
    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(event.target.value);
    };

    // Update filteredHeaders based on searchQuery
    useEffect(() => {
        if (searchQuery.trim() === '') {
            setFilteredHeaders(headers);
        } else {
            const filtered = headers.filter(header =>
                header.name.toLowerCase().includes(searchQuery.toLowerCase())
            );
            setFilteredHeaders(filtered);
        }
    }, [searchQuery, headers]);

    return (
        <div>
            <TextField
                label="Search"
                variant="filled"
                value={searchQuery}
                onChange={handleSearchChange}
                fullWidth
                style={{ marginBottom: '10px' }}
            />
            {filteredHeaders.map((header) => (
                <div onContextMenu={(e) => handleRightClick(e, header.fieldId)} key={header.fieldId}>
                    <ButtonBase
                        style={{ margin: '10px', padding: '10px', display: 'flex', alignItems: 'center', justifyContent: 'space-between', cursor: 'pointer', width: '100%' }}
                        onClick={() => copyToClipboard(`${header.personalization}`, header.fieldId)}
                        className="copyCard"
                    >
                        <span>
                            {header.name}
                        </span>
                        <CustomTooltip title="Copy to clipboard" className="copyIcon">
                            <IconButton>
                                {copiedKey === header.fieldId ? <CheckCircleOutlineIcon sx={{ fontSize: "35px" }} color="success" /> : <FileCopyIcon sx={{ fontSize: "25px" }} />}
                            </IconButton>
                        </CustomTooltip>
                    </ButtonBase>
                </div>
            ))}

            {/* Expanded right-click context menu */}
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleCloseMenu}
                keepMounted
            >
                <MenuItem onClick={() => copyToClipboard(`${headers.find(h => h.fieldId === selectedKey)?.personalization}`, selectedKey)}>
                    <IconButton>
                        <FileCopyIcon sx={{ fontSize: "35px" }} />
                    </IconButton>
                    Copy
                </MenuItem>
            </Menu>
            {/* Info Dialog */}
            <Dialog open={infoDialogOpen} onClose={handleCloseInfoDialog}>
                <DialogTitle>Info</DialogTitle>
                <DialogContent>
                    {infoData}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseInfoDialog} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default PlaceholderCards;
