// src\app\pages\0-Account\EmailAccounts\ConnectPhones\connectTwilioAccount.tsx
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button, TextField, Snackbar, CircularProgress, Alert, Box, Grid, Typography } from '@mui/material';
import { TbBrandTwilio } from "react-icons/tb";
import { MdRefresh } from "react-icons/md";

export function TwilioIntegration() {
    const [accountSid, setAccountSid] = useState('');
    const [authToken, setAuthToken] = useState('');
    const [balance, setBalance] = useState('');
    const [loading, setLoading] = useState(false);
    const [loadingInfo, setLoadingInfo] = useState(true);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('info');
    const [isConnected, setIsConnected] = useState(false);

    const fetchTwilioAccountInfo = async () => {
        setLoadingInfo(true);
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/phone/twilio-account-info`);
            setAccountSid(response.data.accountSid);
            setBalance(response.data.balance);
            setIsConnected(response.data.connected);
        } catch (error) {
            console.error('Failed to fetch Twilio account info:', error);
        } finally {
            setLoadingInfo(false);
        }
    };

    useEffect(() => {
        fetchTwilioAccountInfo();
    }, []);

    const handleConnectTwilio = async () => {
        setLoading(true);
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/phone/connect-twilio-account`, {
                accountSid,
                authToken,
            });
            setSnackbarSeverity('success');
            setSnackbarMessage(response.data.message);
            setIsConnected(true);
            fetchTwilioAccountInfo();
        } catch (error: any) {
            setSnackbarSeverity('error');
            setSnackbarMessage(error.response?.data.message || 'Failed to connect Twilio account');
        } finally {
            setLoading(false);
            setSnackbarOpen(true);
        }
    };

    const handleDisconnectTwilio = async () => {
        setLoading(true);
        try {
            const response = await axios.delete(`${process.env.REACT_APP_API_URL}/phone/delete-twilio-account`);
            setSnackbarSeverity('success');
            setSnackbarMessage(response.data.message);
            setIsConnected(false);
            setAccountSid('');
            setBalance('');
        } catch (error: any) {
            setSnackbarSeverity('error');
            setSnackbarMessage(error.response?.data.message || 'Failed to disconnect Twilio account');
        } finally {
            setLoading(false);
            setSnackbarOpen(true);
        }
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: "center", m: 4 }}>
            <Grid container direction="column" alignItems="center" spacing={2}>
                <Grid item>
                    <TbBrandTwilio style={{ fontSize: '5rem' }} />
                </Grid>
                <Grid item>
                    <Typography variant="h4" gutterBottom>
                        Twilio Integration
                    </Typography>
                </Grid>
                <Grid item>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={fetchTwilioAccountInfo}
                        disabled={loadingInfo}
                        startIcon={<MdRefresh />}
                    >
                        {loadingInfo ? <CircularProgress size={24} /> : 'Refresh'}
                    </Button>
                </Grid>
            </Grid>

            <Grid container spacing={2} justifyContent="center" marginTop={2}>
                <Grid item xs={12} md={8}>
                    <TextField
                        label="Account SID"
                        variant="outlined"
                        value={accountSid}
                        onChange={(e) => setAccountSid(e.target.value)}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} md={8}>
                    <TextField
                        label="Auth Token"
                        variant="outlined"
                        value={authToken}
                        onChange={(e) => setAuthToken(e.target.value)}
                        fullWidth
                        sx={{ mt: 2 }}
                    />
                </Grid>
                <Grid item xs={12} md={8} sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
                    {isConnected ? (
                        <Button variant="text" color="info" onClick={handleDisconnectTwilio} disabled={loading}>
                            {loading ? <CircularProgress size={24} /> : 'Disconnect Twilio Account'}
                        </Button>
                    ) : (
                        <Button variant="text" color="info" onClick={handleConnectTwilio} disabled={loading}>
                            {loading ? <CircularProgress size={24} /> : 'Connect Twilio Account'}
                        </Button>
                    )}
                </Grid>
            </Grid>
            <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={() => setSnackbarOpen(false)}>
                <Alert onClose={() => setSnackbarOpen(false)}
                    //@ts-ignore
                    severity={snackbarSeverity}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Box>
    );
}
