// src\app\pages\0-Account\EmailAccounts\LinkTrackingDomains.tsx
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CheckIcon from '@mui/icons-material/Check';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import QuestionMarkSharpIcon from '@mui/icons-material/QuestionMarkSharp';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Alert,
    Box,
    Button,
    Chip,
    CircularProgress,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    Snackbar,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Tooltip,
    Typography,
} from '@mui/material';
import axios from 'axios';
import React, { useState } from 'react';

interface LinkTrackingDomainsProps {
    selectedAccountEmails: string[];
    accountIds: string[];
}

interface TrackingDomainRow {
    domain: string;
    selectedEmails: string[];
    status: 'pending' | 'success' | 'error' | '';
    message: string;
}

const LinkTrackingDomains: React.FC<LinkTrackingDomainsProps> = ({ selectedAccountEmails, accountIds }) => {
    const [rows, setRows] = useState<TrackingDomainRow[]>([{ domain: '', selectedEmails: [], status: '', message: '' }]);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error'>('success');
    const [copied, setCopied] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleCopy = (text: string) => {
        navigator.clipboard.writeText(text).then(() => setCopied(true));
    };

    const handleAddRow = () => {
        if (rows.length < 10) {
            setRows([...rows, { domain: '', selectedEmails: [], status: '', message: '' }]);
        }
    };

    const handleRemoveRow = (index: number) => {
        const newRows = rows.filter((_, i) => i !== index);
        setRows(newRows);
    };

    const handleDomainChange = (index: number, value: string) => {
        const newRows = rows.map((row, i) => (i === index ? { ...row, domain: value } : row));
        setRows(newRows);
    };

    const handleEmailsChange = (index: number, value: string[]) => {
        const newRows = rows.map((row, i) => (i === index ? { ...row, selectedEmails: value } : row));
        setRows(newRows);
    };

    const handleSubmit = async () => {
        setLoading(true);
        for (let i = 0; i < rows.length; i++) {
            const { domain, selectedEmails } = rows[i];
            const emailAccountIds = selectedEmails.map(email => accountIds[selectedAccountEmails.indexOf(email)]);
            setRows(prevRows => prevRows.map((row, index) => index === i ? { ...row, status: 'pending' } : row));

            try {
                const response = await axios.post(`${process.env.REACT_APP_API_URL}/auth/tracking-domain`, {
                    emailAccountIds,
                    domain
                });
                setRows(prevRows => prevRows.map((row, index) => index === i ? { ...row, status: 'success', message: response.data.message } : row));
            } catch (error: any) {
                setRows(prevRows => prevRows.map((row, index) => index === i ? { ...row, status: 'error', message: error.response?.data?.message || 'An error occurred' } : row));
            }
        }
        setLoading(false);
    };

    const handleCloseSnackbar = () => {
        setSnackbarOpen(false);
    };

    const getAvailableEmails = (currentIndex: number) => {
        const selectedEmails = rows.flatMap(row => row.selectedEmails);
        return selectedAccountEmails.filter(email => !selectedEmails.includes(email) || rows[currentIndex].selectedEmails.includes(email));
    };

    return (
        <Box>
            <Box mb={2}>
                <Paper elevation={3} style={{ padding: 16 }}>
                    <Typography variant="h6">
                        {selectedAccountEmails.length > 0 ? (
                            `Selected Email Accounts: ${selectedAccountEmails.length}`
                        ) : (
                            'No email accounts selected. Please select email accounts from the left panel.'
                        )}
                    </Typography>
                </Paper>
            </Box>
            <Box mb={2}>
                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography variant="body1" align="center">
                            <QuestionMarkSharpIcon /> Instructions
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography variant="body1" gutterBottom>
                            To connect your tracking domain, please ensure your DNS records are pointed to connectmailer.org.
                        </Typography>
                        <TableContainer component={Paper}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Host</TableCell>
                                        <TableCell>Type</TableCell>
                                        <TableCell>Value</TableCell>
                                        <TableCell>
                                            TTL
                                            <Tooltip title="Some domain providers might have different formats like 'day' or others. You can use their default value.">
                                                <QuestionMarkSharpIcon fontSize="small" style={{ marginLeft: 4 }} />
                                            </Tooltip>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell>@</TableCell>
                                        <TableCell>CNAME</TableCell>
                                        <TableCell>
                                            connectmailer.org
                                            <Tooltip title="Copy">
                                                <IconButton onClick={() => handleCopy('connectmailer.org')}>
                                                    {copied ? <CheckIcon color="success" /> : <ContentCopyIcon />}
                                                </IconButton>
                                            </Tooltip>
                                        </TableCell>
                                        <TableCell>3600</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <Accordion>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography variant="body2">More Details</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography variant="body2">
                                    Please refer to your hosting provider's documentation for detailed steps on how to update your DNS records. Typically, you will need to log in to your hosting account, navigate to the DNS management section, and add the CNAME record as specified above.
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                    </AccordionDetails>
                </Accordion>
            </Box>
            <Grid container spacing={2} justifyContent="flex-end" style={{ marginBottom: 10 }}>
                <Grid item>
                    <Button variant="contained" color="primary" onClick={handleAddRow} startIcon={<AddCircleOutlineIcon />}>
                        Add Another Row
                    </Button>
                </Grid>
                <Grid item>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSubmit}
                        disabled={loading}
                        startIcon={loading ? <CircularProgress size={20} /> : null}
                    >
                        {loading ? 'Connecting...' : 'Connect All'}
                    </Button>
                </Grid>
            </Grid>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Tracking Domain</TableCell>
                            <TableCell>Select Emails</TableCell>
                            <TableCell>Actions</TableCell>
                            <TableCell>Status</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row, index) => (
                            <TableRow key={index}>
                                <TableCell>
                                    <TextField
                                        label="Tracking Domain"
                                        variant="standard"
                                        fullWidth
                                        value={row.domain}
                                        onChange={(e) => handleDomainChange(index, e.target.value)}
                                    />
                                </TableCell>
                                <TableCell>
                                    <FormControl fullWidth variant="standard">
                                        <InputLabel>Select Emails</InputLabel>
                                        <Select
                                            multiple
                                            value={row.selectedEmails}
                                            onChange={(e) => handleEmailsChange(index, e.target.value as string[])}
                                            renderValue={(selected) => (
                                                <Box>
                                                    {selected.map((value) => (
                                                        <Chip key={value} label={value} style={{ margin: 2 }} />
                                                    ))}
                                                </Box>
                                            )}
                                            MenuProps={{
                                                style: { zIndex: 999999999999999 }
                                            }}

                                        >
                                            {getAvailableEmails(index).map((email) => (
                                                <MenuItem key={email} value={email}>
                                                    {email}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </TableCell>
                                <TableCell>
                                    <Button variant="contained" color="secondary" onClick={() => handleRemoveRow(index)}>
                                        Remove
                                    </Button>
                                </TableCell>
                                <TableCell>
                                    {row.status === 'pending' ? (
                                        <CircularProgress size={24} />
                                    ) : (
                                        row.status && (
                                            <Alert severity={row.status === 'success' ? 'success' : 'error'}>
                                                {row.message}
                                            </Alert>
                                        )
                                    )}
                                </TableCell>

                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleCloseSnackbar}>
                <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default LinkTrackingDomains;
