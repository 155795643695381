import './CampaignLeads.css';

import CheckBoxOutlineBlankSharpIcon from '@mui/icons-material/CheckBoxOutlineBlankSharp';
import CheckBoxSharpIcon from '@mui/icons-material/CheckBoxSharp';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import FolderIcon from '@mui/icons-material/Folder';
import Search from '@mui/icons-material/Search';
import { Skeleton, TextField } from '@mui/material';
import Collapse from '@mui/material/Collapse';
import Divider from '@mui/material/Divider';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Radio from '@mui/material/Radio';
import axios from 'axios';
import * as React from 'react';




interface ListItem {
    _id: string;
    name: string;
}

interface FolderItem {
    folder: {
        _id: string;
        name: string;
    };
    lists: {
        _id: string;
        name: string;
    }[];
}

const CampaignLeadsSidePanel = ({ selectedCampaignId, selectedList, setSelectedList }) => {
    const [foldersWithLists, setFoldersWithLists] = React.useState<FolderItem[]>([]);
    const [listsWithoutFolder, setListsWithoutFolder] = React.useState<ListItem[]>([]);
    const [open, setOpen] = React.useState<Record<string, boolean>>({});
    const [loading, setLoading] = React.useState(false);
    const [searchTerm, setSearchTerm] = React.useState('');
    const [showSkeleton, setShowSkeleton] = React.useState(true);


    const fetchAssociatedList = (campaignId: string | null) => {
        if (campaignId) {
            axios.get(`${process.env.REACT_APP_API_URL}/campaign/${campaignId}`)
                .then(response => {
                    const associatedListId = response.data.listId;
                    setSelectedList(associatedListId);
                })
                .catch(error => {
                    console.error('Error fetching associated list:', error);
                });
        }
    };

    const fetchData = () => {
        setLoading(true); // Set loading to true when fetching data
        setShowSkeleton(true); // Always show the skeleton initially

        axios.get(`${process.env.REACT_APP_API_URL}/leads/folders-with-lists`)
            .then(response => {
                setFoldersWithLists(response.data.foldersWithLists);
                setListsWithoutFolder(response.data.listsWithoutFolder);

                // Use setTimeout to hide the skeleton after 2 seconds
                setShowSkeleton(false);

                setLoading(false); // Set loading to false when data is fetched
            })
            .catch(error => {
                console.error('There was an error fetching the data:', error);

                // Use setTimeout to hide the skeleton after 2 seconds even in case of an error
                setShowSkeleton(false);


                setLoading(false); // Set loading to false in case of an error
            });
    };


    React.useEffect(() => {
        fetchData();
        fetchAssociatedList(selectedCampaignId);
    }, [selectedCampaignId]);

    const handleClick = (id: string, isFolder: boolean) => {
        if (!isFolder) {
            console.log("List item clicked:", id);
            setSelectedList(id);
            updateCampaign(selectedCampaignId, id);
        } else {
            setOpen((prevOpen) => ({
                ...prevOpen,
                [id]: !prevOpen[id],
            }));
        }
    };


    const updateCampaign = (campaignId: string | null, listId: string) => {
        if (campaignId) {
            axios.put(`${process.env.REACT_APP_API_URL}/campaign/update/${campaignId}`, {
                listId: listId
            })
                .then(response => {
                    console.log("Campaign updated with selected list:", response.data);
                })
                .catch(error => {
                    console.error('Error updating campaign:', error);
                });
        }
    };

    // New function to handle search input change
    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value);
    };

    // Filter logic for folders and lists based on search term
    const filteredFoldersWithLists = foldersWithLists.filter(folderItem =>
        folderItem.folder.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        folderItem.lists.some(listItem => listItem.name.toLowerCase().includes(searchTerm.toLowerCase()))
    );

    const filteredListsWithoutFolder = listsWithoutFolder.filter(listItem =>
        listItem.name.toLowerCase().includes(searchTerm.toLowerCase())
    );



    return (
        <>
            <Grid item xs={12} md={4}>
                {/* Search Field */}
                <TextField
                    fullWidth
                    label="Search Folders and Lists"
                    variant="standard"
                    value={searchTerm}
                    onChange={handleSearchChange}
                    sx={{ mb: 2 }}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Search />
                            </InputAdornment>
                        ),
                    }}
                    className={searchTerm ? 'active-search-field' : ''}
                />

                {showSkeleton ? (
                    <List
                        component="nav"
                        aria-labelledby="nested-list-subheader"
                        style={{ height: 400, width: '100%', maxWidth: 360, overflowX: 'auto', padding: '20px' }}
                    >
                        {/* Use Skeleton component to display loading UI */}
                        {Array.from({ length: 10 }).map((_, index) => (
                            <React.Fragment key={index}>
                                <Skeleton animation="wave" height={80} />
                                <Divider />
                            </React.Fragment>
                        ))}
                    </List>
                ) : (


                    <List
                        component="nav"
                        aria-labelledby="nested-list-subheader"
                        style={{ height: 400, width: '100%', maxWidth: 360, overflowX: 'auto' }}
                    >
                        {/* Using filteredFoldersWithLists instead of foldersWithLists */}
                        {filteredFoldersWithLists.map((folderItem: FolderItem, index) => (
                            <React.Fragment key={folderItem.folder._id}>
                                <ListItemButton onClick={() => handleClick(folderItem.folder._id, true)}>
                                    <ListItemIcon>
                                        <FolderIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={folderItem.folder.name} />
                                    {folderItem.lists.length > 0 ? (
                                        open[folderItem.folder._id] ? <ExpandLess /> : <ExpandMore />
                                    ) : null}
                                </ListItemButton>
                                <Collapse in={open[folderItem.folder._id]} timeout="auto" unmountOnExit>
                                    <List component="div" disablePadding>
                                        {folderItem.lists.map((listItem) => (
                                            <ListItemButton
                                                key={listItem._id}
                                                sx={{ pl: 4 }}
                                                onClick={() => handleClick(listItem._id, false)}
                                                className={
                                                    searchTerm && listItem.name.toLowerCase().includes(searchTerm.toLowerCase())
                                                        ? 'matched-list'
                                                        : ''
                                                }
                                            >
                                                <FormControlLabel
                                                    control={
                                                        <Radio
                                                            checked={selectedList === listItem._id}
                                                            onChange={() => handleClick(listItem._id, false)}
                                                            value={listItem._id}
                                                            checkedIcon={<CheckBoxSharpIcon />}
                                                            icon={<CheckBoxOutlineBlankSharpIcon />}
                                                        />
                                                    }
                                                    label={listItem.name}
                                                />
                                            </ListItemButton>
                                        ))}
                                    </List>
                                </Collapse>
                                {index < foldersWithLists.length - 1 && <Divider />}
                            </React.Fragment>
                        ))}
                        {/* Using filteredListsWithoutFolder instead of listsWithoutFolder */}
                        {filteredListsWithoutFolder.map((listItem: ListItem) => (
                            <ListItemButton
                                key={listItem._id}
                                sx={{ pl: 4 }}
                                onClick={() => handleClick(listItem._id, false)}
                                className={
                                    searchTerm && listItem.name.toLowerCase().includes(searchTerm.toLowerCase())
                                        ? 'matched-list'
                                        : ''
                                }
                            >
                                <FormControlLabel
                                    control={
                                        <Radio
                                            checked={selectedList === listItem._id}
                                            onChange={() => handleClick(listItem._id, false)}
                                            value={listItem._id}
                                            checkedIcon={<CheckBoxSharpIcon />}
                                            icon={<CheckBoxOutlineBlankSharpIcon />}
                                        />
                                    }
                                    label={listItem.name}
                                />
                            </ListItemButton>
                        ))}
                    </List>
                )}
            </Grid>
        </>
    );
}

export default CampaignLeadsSidePanel;
