// src/app/pages/0-Account/EmailAccounts/ManageEmailAccounts/UpdateAccountNames.tsx
import { Button, Chip, Grid, Snackbar, TextField, Typography } from '@mui/material';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import axios from 'axios';
import React, { useState } from 'react';

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

interface UpdateAccountNamesProps {
    selectedAccountEmails: string[];
    accountIds: string[];
    RefreshAccountList: () => void;

}

const UpdateAccountNames: React.FC<UpdateAccountNamesProps> = ({ selectedAccountEmails, accountIds, RefreshAccountList }) => {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error'>('success');


    const handleUpdateNames = async () => {
        try {

            const response = await axios.post(`${process.env.REACT_APP_API_URL}/account/emailaccounts/update-names`, {
                accountIds,
                firstName,
                lastName
            });
            setSnackbarMessage(response.data.message);
            setSnackbarSeverity('success');
            RefreshAccountList();
        } catch (error: any) {
            setSnackbarMessage(error.response?.data?.message || 'An error occurred');
            setSnackbarSeverity('error');
            RefreshAccountList();

        } finally {
            setSnackbarOpen(true);
            RefreshAccountList();
        }
    };

    const handleCloseSnackbar = () => {
        setSnackbarOpen(false);
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={6}>
                <Typography variant="h6">Update Names</Typography>
                <TextField
                    label="First Name"
                    variant="standard"
                    fullWidth
                    margin="normal"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                />
                <TextField
                    label="Last Name"
                    variant="standard"
                    fullWidth
                    margin="normal"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                />
                <Button variant="contained" color="primary" onClick={handleUpdateNames}>
                    Update Names
                </Button>
            </Grid>
            <Grid item xs={6}>
                <Typography variant="h6">Selected Accounts</Typography>
                {selectedAccountEmails.map((email, index) => (
                    <Chip key={index} label={email} style={{ margin: 2 }} />
                ))}
            </Grid>
            <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleCloseSnackbar}>
                <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Grid>
    );
};

export default UpdateAccountNames;
