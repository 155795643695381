// src\app\pages\3-CRM\CRMPage.tsx
import React from 'react';
import CRMParentComponent from './LeadlistsParent';


function LeadsPage() {


    return (
        <div className='full-width-container' >
            <CRMParentComponent />
        </div>
    );
}

export default LeadsPage;
