import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Tabs, Tab, Grid, Paper, Checkbox, Box, Button } from '@mui/material';
import BubbleChartIcon from '@mui/icons-material/BubbleChart';
import SsidChartSharpIcon from '@mui/icons-material/SsidChartSharp';
import ChartComponent from './Warmup/DataView/Chart/ChartView';
import BubblesComponent from './Warmup/DataView/Bubble/BubblesView';
import GlowingFireIcon from './Warmup/GlowingFireIcon/GlowingFireIcon';
import { DayScheduleInterface, WarmupSettingsInterface } from './Warmup/InterfacesWarmupSettings';
import WarmupSettingsComponent from './WarmupSettings';
import PlayCircleSharpIcon from '@mui/icons-material/PlayCircleSharp';
import PauseCircleSharpIcon from '@mui/icons-material/PauseCircleSharp';
import AutoGraphSharpIcon from '@mui/icons-material/AutoGraphSharp';
import { MarkEmailReadSharp } from '@mui/icons-material';



const WarmupParentComponent: React.FC<{ selectedAccount: string | null }> = ({ selectedAccount }) => {
    const [daySchedules, setDaySchedules] = useState<DayScheduleInterface[]>([]);
    const [selectedTab, setSelectedTab] = useState<number>(0);
    const [warmupSettings, setWarmupSettings] = useState<WarmupSettingsInterface | null>(null);
    const [isLoadingAfterUpdate, setIsLoadingAfterUpdate] = useState(false);

    useEffect(() => {
        const fetchData = async () => {

            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/warmup/warmup-schedule/account/${selectedAccount}`);
                setDaySchedules(response.data.daySchedules);
            } catch (error) {
                console.error('Error fetching data: ', error);
            }
            setIsLoadingAfterUpdate(false);
        };

        if (selectedAccount !== null && selectedAccount !== '') {
            setDaySchedules([]); // Reset day schedules when account changes
            fetchData();
        }
    }, [selectedAccount]);


    useEffect(() => {
        const fetchWarmupSettings = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/warmup/settings/account/${selectedAccount}`);
                setWarmupSettings(response.data);
            } catch (error) {
                console.error('Error fetching warmup settings: ', error);
            }
        };

        if (selectedAccount !== null && selectedAccount !== '') {
            setWarmupSettings(null);
            fetchWarmupSettings();
        }
    }, [selectedAccount, setWarmupSettings]);

    const updateWarmupSettings = async (updatedSettings) => {

        const updateUrl = `${process.env.REACT_APP_API_URL}/warmup/warmup-settings/account/${selectedAccount}/update-schedule`;
        try {
            await axios.put(updateUrl, updatedSettings);
            console.log('Warmup settings updated successfully');
            // Call fetchWarmupSettings to refresh the data
            fetchWarmupSettings();
            fetchData();
        } catch (error) {
            console.error('Error updating warmup settings: ', error);
        }
    };

    const handleUpdateWarmupSettings = async (settings) => {
        setIsLoadingAfterUpdate(true); // Start loading
        try {
            await updateWarmupSettings(settings); // Perform the update operation
            // Optionally, show a success message or perform other actions on success
        } catch (error) {
            console.error('Error updating warmup settings:', error);
            // Optionally, show an error message
        } finally {
            setIsLoadingAfterUpdate(false); // Stop loading regardless of outcome
        }
    };


    const fetchWarmupSettings = async () => {
        if (selectedAccount !== null && selectedAccount !== '') {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/warmup/settings/account/${selectedAccount}`);
                setWarmupSettings(response.data);
            } catch (error) {
                console.error('Error fetching warmup settings: ', error);
            }
        }
    };

    const fetchData = async () => {
        if (selectedAccount !== null && selectedAccount !== '') {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/warmup/warmup-schedule/account/${selectedAccount}`);
                setDaySchedules(response.data.daySchedules);
            } catch (error) {
                console.error('Error fetching data: ', error);
            }
        }
    };

    const toggleWarmupActive = async () => {

        const toggleUrl = `${process.env.REACT_APP_API_URL}/warmup/toggle-warmup/account/${selectedAccount}`;
        try {
            await axios.put(toggleUrl);
            console.log('Warmup active state toggled successfully');
            // Optionally, refresh the warmup settings to reflect changes
            fetchWarmupSettings();
            fetchData();
        } catch (error) {
            console.error('Error toggling warmup active state:', error);
        }
    };


    if (!warmupSettings) {
        return null;
    }

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setSelectedTab(newValue);
    };

    const handleWarmupActiveChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        await toggleWarmupActive(); // Call the function directly instead of updating the state here
        // Optionally, update the state to reflect the new value
        setWarmupSettings({ ...warmupSettings, isWarmupActive: event.target.checked });
    };


    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={6}>

                    <Grid sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <GlowingFireIcon isWarmupActive={warmupSettings.isWarmupActive} />
                    </Grid>
                    <Tabs value={selectedTab} onChange={handleTabChange} centered>
                        <Tab
                            icon={<BubbleChartIcon sx={{ fontSize: "45px", marginLeft: "50px", marginRight: "50px" }} />}
                            aria-label="Bubbles View"
                        />
                        <Tab
                            icon={<SsidChartSharpIcon sx={{ fontSize: "45px", marginLeft: "50px", marginRight: "50px" }} />}
                            aria-label="Chart View"
                        />
                    </Tabs>
                    {selectedTab === 0 ? <BubblesComponent daySchedules={daySchedules} isLoading={isLoadingAfterUpdate} /> : <ChartComponent daySchedules={daySchedules} isLoading={isLoadingAfterUpdate} />}
                </Grid>
                <Grid item xs={6}>

                    <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '20vh' }}>
                        <Checkbox
                            checked={warmupSettings.isWarmupActive}
                            onChange={handleWarmupActiveChange}
                            name="Start/Pause Warmup"
                            checkedIcon={<PauseCircleSharpIcon color='secondary' sx={{ fontSize: "80px" }} />}
                            icon={<PlayCircleSharpIcon color='primary' sx={{ fontSize: "80px" }} />}
                            disabled={!warmupSettings}

                        />
                    </Box>

                    <WarmupSettingsComponent
                        warmupSettings={warmupSettings}
                        setWarmupSettings={setWarmupSettings}
                        updateWarmupSettings={updateWarmupSettings}
                        toggleWarmupActive={toggleWarmupActive}
                    />

                    <Button
                        variant="contained"
                        fullWidth
                        color="primary"
                        style={{ marginTop: '20px', fontSize: "25px" }}
                        disabled={!warmupSettings || isLoadingAfterUpdate} // Disable button when loading
                        onClick={() => handleUpdateWarmupSettings(warmupSettings)}
                    >
                        {isLoadingAfterUpdate ? "Updating..." : "Update"}
                        <AutoGraphSharpIcon sx={{ fontSize: '35px' }} />
                    </Button>

                </Grid>


            </Grid>

        </>
    );
};

export default WarmupParentComponent;
