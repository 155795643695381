// src\app\pages\0-Account\EmailAccounts\Guides\EmailConnectionMethodsGuide.tsx
import { Email, Settings } from '@mui/icons-material';
import { Box, Container, Divider, Grid, Typography, useTheme } from '@mui/material';
import React from 'react';
import { FaGoogle, FaMicrosoft } from 'react-icons/fa';

import GridBackgroundComponent from '../../../2-GetLeads/AdvancedSearch/GridBackgroundComponent';

const EmailConnectionMethodsGuide = () => {
    const theme = useTheme();
    return (
        <>
            <GridBackgroundComponent
                pattern='dots'
                gap={15}
                color={theme.palette.action.disabled}
                opacity={0.4}
                lineWidth={2}
            >
                <Container maxWidth="md">
                    <Box textAlign="center" marginBottom={4}>
                        <Typography variant="h4" gutterBottom>
                            Connect Your Email Accounts
                        </Typography>
                        <Typography variant="subtitle1" color="textSecondary">
                            Explore multiple secure methods to connect seamlessly
                        </Typography>
                    </Box>
                    <Grid container spacing={3} justifyContent="center">
                        <Grid item xs={12} md={8}>
                            <MethodSection
                                icon={<Email fontSize="large" />}
                                title="SMTP and IMAP"
                                description="Connect any email provider using SMTP and IMAP protocols. Easily manage multiple accounts at once or add them individually."
                            />
                            <Divider />
                            <MethodSection
                                icon={<FaGoogle size={32} />}
                                title="Free Gmail Accounts"
                                description="Enable two-factor authentication in Google Account settings and generate an app password for secure connection."
                            />
                            <Divider />
                            <MethodSection
                                icon={<Settings fontSize="large" />}
                                title="Google Workspace"
                                description="Quickly set up using OAuth authentication for Google Workspace accounts."
                            />
                            <Divider />
                            <MethodSection
                                icon={<FaMicrosoft size={32} />}
                                title="Microsoft Accounts"
                                description="Sign in securely with your Microsoft account using OAuth authentication."
                            />
                        </Grid>
                    </Grid>
                </Container>
            </GridBackgroundComponent>
        </>
    );
};

const MethodSection = ({ icon, title, description }) => {
    return (
        <Box marginBottom={3}>
            <Grid container spacing={2} alignItems="center">
                <Grid item>{icon}</Grid>
                <Grid item xs={10}>
                    <Typography variant="h6" gutterBottom>{title}</Typography>
                    <Typography variant="body1" gutterBottom>{description}</Typography>
                </Grid>
            </Grid>
        </Box>
    );
};

export default EmailConnectionMethodsGuide;
