import { Box, Container, Grid, Paper, Typography } from '@mui/material';
import { motion } from 'framer-motion';
import React from 'react';

import DashboardImage from '../../Images/LightCampaigns.svg';
import ConnectedAccountsInfoChart from './Charts/ConnectedAccountsChart';
import DashboardFAQs from './DashboardFAQs';

function DashboardPage() {
    const hoverEffect = {
        hover: {
            scale: 1.1, // Increased scale for more pronounced effect
            rotate: 0.1, // Adding a slight rotation
            transition: {
                type: 'spring',
                stiffness: 300, // More stiffness for a springy feel
                damping: 10,
            },
        },
    };

    const springTransition = {
        type: 'spring',
        damping: 12, // Adjusted for a springier feel
        stiffness: 150, // Higher stiffness
    };

    const svgImageStyle = {
        width: 'auto', // Adjust the width as needed
        height: 'auto', // Maintain the aspect ratio
        userSelect: 'none', // Disable text selection
    };

    const svgVariants = {
        initial: { opacity: 0, scale: 0 },
        animate: { opacity: 1, scale: 1 },
        exit: { opacity: 0, scale: 0 },
    };

    const preventDefault = (event) => {
        event.preventDefault();
    };
    return (
        <Container maxWidth="lg">

            {/* // <Container className='full-width-container' > */}
            <Grid container spacing={3}>
                {/* Element 1 */}
                <Grid item xs={6}>
                    <motion.div
                        className="no-global-transition"
                        initial={{ opacity: 0, x: -50 }}
                        animate={{ opacity: 1, x: 0 }}
                        exit={{ opacity: 0, x: -50 }}
                        transition={springTransition}
                        whileHover="hover"
                        variants={hoverEffect}
                    >
                        <Paper elevation={3} style={{ padding: '20px' }}>
                            <Typography variant="h6" component="div">
                                <img
                                    src={DashboardImage}
                                    alt="Element 1"
                                    //@ts-ignore
                                    style={svgImageStyle}
                                    onContextMenu={preventDefault}
                                    onDragStart={preventDefault}
                                    onMouseDown={preventDefault}
                                />
                            </Typography>
                        </Paper>
                    </motion.div>
                </Grid>

                {/* Element 2 */}
                <Grid item xs={6}>
                    <Box mt={4}>
                        <DashboardFAQs />
                    </Box>
                </Grid>

                {/* Connected Accounts Info Chart */}
                <Grid item xs={12}>
                    <Box mt={4}>
                        <ConnectedAccountsInfoChart />
                    </Box>
                </Grid>

            </Grid>
        </Container>
    );
}

export default DashboardPage;
