import React, { useEffect } from 'react';
import $ from 'jquery';
import 'datatables.net';
import Contacts from './inboxlistingscontacts/contacts';
const InboxListing: React.FC = () => {

    useEffect(() => {
        init();
    }, []);

    const initDatatable = () => {
        const table = document.querySelector('#kt_inbox_listing');
        if (!table) {
            return;
        }

        const datatable = $(table).DataTable({
            "info": false,
            'order': [],
            "dom": 'ltipr'
        });

        datatable.on('draw', function () {
            handleDatatableFooter();
        });

        return datatable;
    }

    const handleDatatableFooter = () => {
        const footerElement = document.querySelector('#kt_inbox_listing_wrapper > .row');
        const spacingClasses = ['px-9', 'pt-3', 'pb-5'];
        footerElement?.classList.add(...spacingClasses);
    }

    const handleSearchDatatable = (datatable: any) => {
        console.log("Datatable: ", datatable); // Add this line to check the datatable
        const filterSearch = document.querySelector('[data-kt-inbox-listing-filter="search"]');
        filterSearch?.addEventListener('keyup', function (e) {
            datatable.search((e.target as HTMLInputElement).value).draw();
            console.log(datatable);  // Check if datatable is defined

        });
    }

    const init = () => {
        const table = document.querySelector('#kt_inbox_listing');

        if (!table) {
            return;
        }

        const datatable = initDatatable();
        handleSearchDatatable(datatable);
        handleDatatableFooter();
    }

    return (
        <div>
            <>
                {/*begin::Content*/}
                <div className="flex-lg-row-fluid ms-lg-7 ms-xl-10">
                    {/*begin::Card*/}
                    <div className="card">
                        <div className="card-header align-items-center py-5 gap-2 gap-md-5">
                            {/*begin::Actions*/}
                            <div className="d-flex flex-wrap gap-2">
                                {/*begin::Checkbox*/}
                                <div className="form-check form-check-sm form-check-custom form-check-solid me-4 me-lg-7">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        data-kt-check="true"
                                        data-kt-check-target="#kt_inbox_listing .form-check-input"
                                        defaultValue={1}
                                    />
                                </div>
                                {/*end::Checkbox*/}
                                {/*begin::Reload*/}
                                <a
                                    href="#"
                                    className="btn btn-sm btn-icon btn-light btn-active-light-primary"
                                    data-bs-toggle="tooltip"
                                    data-bs-dismiss="click"
                                    data-bs-placement="top"
                                    title="Reload"
                                >
                                    <i className="ki-duotone ki-arrows-circle fs-2">
                                        <span className="path1" />
                                        <span className="path2" />
                                    </i>
                                </a>
                                {/*end::Reload*/}
                                {/*begin::Archive*/}
                                <a
                                    href="#"
                                    className="btn btn-sm btn-icon btn-light btn-active-light-primary"
                                    data-bs-toggle="tooltip"
                                    data-bs-dismiss="click"
                                    data-bs-placement="top"
                                    title="Archive"
                                >
                                    <i className="ki-duotone ki-sms fs-2">
                                        <span className="path1" />
                                        <span className="path2" />
                                    </i>
                                </a>
                                {/*end::Archive*/}
                                {/*begin::Delete*/}
                                <a
                                    href="#"
                                    className="btn btn-sm btn-icon btn-light btn-active-light-primary"
                                    data-bs-toggle="tooltip"
                                    data-bs-dismiss="click"
                                    data-bs-placement="top"
                                    title="Delete"
                                >
                                    <i className="ki-duotone ki-trash fs-2">
                                        <span className="path1" />
                                        <span className="path2" />
                                        <span className="path3" />
                                        <span className="path4" />
                                        <span className="path5" />
                                    </i>
                                </a>
                                {/*end::Delete*/}
                                {/*begin::Filter*/}
                                <div>
                                    <a
                                        href="#"
                                        className="btn btn-sm btn-icon btn-light btn-active-light-primary"
                                        data-kt-menu-trigger="click"
                                        data-kt-menu-placement="bottom-start"
                                    >
                                        <i className="ki-duotone ki-down fs-2" />
                                    </a>
                                    {/*begin::Menu*/}
                                    <div
                                        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-125px py-4"
                                        data-kt-menu="true"
                                    >
                                        {/*begin::Menu item*/}
                                        <div className="menu-item px-3">
                                            <a
                                                href="#"
                                                className="menu-link px-3"
                                                data-kt-inbox-listing-filter="show_all"
                                            >
                                                All
                                            </a>
                                        </div>
                                        {/*end::Menu item*/}
                                        {/*begin::Menu item*/}
                                        <div className="menu-item px-3">
                                            <a
                                                href="#"
                                                className="menu-link px-3"
                                                data-kt-inbox-listing-filter="show_read"
                                            >
                                                Read
                                            </a>
                                        </div>
                                        {/*end::Menu item*/}
                                        {/*begin::Menu item*/}
                                        <div className="menu-item px-3">
                                            <a
                                                href="#"
                                                className="menu-link px-3"
                                                data-kt-inbox-listing-filter="show_unread"
                                            >
                                                Unread
                                            </a>
                                        </div>
                                        {/*end::Menu item*/}
                                        {/*begin::Menu item*/}
                                        <div className="menu-item px-3">
                                            <a
                                                href="#"
                                                className="menu-link px-3"
                                                data-kt-inbox-listing-filter="show_starred"
                                            >
                                                Starred
                                            </a>
                                        </div>
                                        {/*end::Menu item*/}
                                        {/*begin::Menu item*/}
                                        <div className="menu-item px-3">
                                            <a
                                                href="#"
                                                className="menu-link px-3"
                                                data-kt-inbox-listing-filter="show_unstarred"
                                            >
                                                Unstarred
                                            </a>
                                        </div>
                                        {/*end::Menu item*/}
                                    </div>
                                    {/*end::Menu*/}
                                </div>
                                {/*end::Filter*/}
                                {/*begin::Sort*/}
                                <span>
                                    <a
                                        href="#"
                                        className="btn btn-sm btn-icon btn-light btn-active-light-primary"
                                        data-bs-toggle="tooltip"
                                        data-bs-dismiss="click"
                                        data-bs-placement="top"
                                        title="Sort"
                                    >
                                        <i className="ki-duotone ki-dots-square fs-3 m-0">
                                            <span className="path1" />
                                            <span className="path2" />
                                            <span className="path3" />
                                            <span className="path4" />
                                        </i>
                                    </a>
                                    {/*begin::Menu*/}
                                    <div
                                        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-125px py-4"
                                        data-kt-menu="true"
                                    >
                                        {/*begin::Menu item*/}
                                        <div className="menu-item px-3">
                                            <a
                                                href="#"
                                                className="menu-link px-3"
                                                data-kt-inbox-listing-filter="filter_newest"
                                            >
                                                Newest
                                            </a>
                                        </div>
                                        {/*end::Menu item*/}
                                        {/*begin::Menu item*/}
                                        <div className="menu-item px-3">
                                            <a
                                                href="#"
                                                className="menu-link px-3"
                                                data-kt-inbox-listing-filter="filter_oldest"
                                            >
                                                Oldest
                                            </a>
                                        </div>
                                        {/*end::Menu item*/}
                                        {/*begin::Menu item*/}
                                        <div className="menu-item px-3">
                                            <a
                                                href="#"
                                                className="menu-link px-3"
                                                data-kt-inbox-listing-filter="filter_unread"
                                            >
                                                Unread
                                            </a>
                                        </div>
                                        {/*end::Menu item*/}
                                    </div>
                                    {/*end::Menu*/}
                                </span>
                                {/*end::Sort*/}
                            </div>
                            {/*end::Actions*/}
                            {/*begin::Actions*/}
                            <div className="d-flex align-items-center flex-wrap gap-2">
                                {/*begin::Search*/}
                                <div className="d-flex align-items-center position-relative">
                                    <i className="ki-duotone ki-magnifier fs-3 position-absolute ms-4">
                                        <span className="path1" />
                                        <span className="path2" />
                                    </i>
                                    <input
                                        type="text"
                                        data-kt-inbox-listing-filter="search"
                                        className="form-control form-control-sm form-control-solid mw-100 min-w-125px min-w-lg-150px min-w-xxl-200px ps-11"
                                        placeholder="Search inbox"
                                    />
                                </div>
                                {/*end::Search*/}
                                {/*begin::Toggle*/}
                                <a
                                    href="#"
                                    className="btn btn-sm btn-icon btn-color-primary btn-light btn-active-light-primary d-lg-none"
                                    data-bs-toggle="tooltip"
                                    data-bs-dismiss="click"
                                    data-bs-placement="top"
                                    title="Toggle inbox menu"
                                    id="kt_inbox_aside_toggle"
                                >
                                    <i className="ki-duotone ki-burger-menu-2 fs-3 m-0">
                                        <span className="path1" />
                                        <span className="path2" />
                                        <span className="path3" />
                                        <span className="path4" />
                                        <span className="path5" />
                                        <span className="path6" />
                                        <span className="path7" />
                                        <span className="path8" />
                                        <span className="path9" />
                                        <span className="path10" />
                                    </i>
                                </a>
                                {/*end::Toggle*/}
                            </div>
                            {/*end::Actions*/}
                        </div>
                        <Contacts />
                    </div>
                    {/*end::Card*/}
                </div>
                {/*end::Content*/}
            </>
        </div>
    );
};

export default InboxListing;
