// src\app\pages\4-Campaigns\1-CampaignStatistics\Charts\LeadEmailVerificationChart.tsx
import { useTheme } from '@mui/material';
import React from 'react';
import Chart from 'react-apexcharts';

interface LeadEmailVerificationChartProps {
    series: number[];
    campaignStatsAvailable: boolean;
}

const LeadEmailVerificationChart: React.FC<LeadEmailVerificationChartProps> = ({ series, campaignStatsAvailable }) => {
    const theme = useTheme();

    const emailValidationOptions: ApexCharts.ApexOptions = {
        chart: {
            height: 390,
            type: 'radialBar',
        },
        plotOptions: {
            radialBar: {
                offsetY: 0,
                startAngle: 0,
                endAngle: 270,
                hollow: {
                    margin: 5,
                    size: '30%',
                    background: 'transparent',
                },
                dataLabels: {
                    name: {
                        show: false,
                    },
                    value: {
                        show: false,
                    }
                }
            }
        },
        colors: [`${theme.palette.primary.main}`, `${theme.palette.secondary.main}`, `${theme.palette.primary.main}`, `${theme.palette.secondary.main}`, `${theme.palette.primary.main}`],
        labels: ['Valid', 'Invalid', 'Unknown', 'Failed', 'catchall'],
        legend: {
            show: true,
            floating: true,
            fontSize: '16px',
            position: 'left',
            offsetX: 160,
            offsetY: 15,
            labels: {
                useSeriesColors: true,
            },
            markers: {
                //@ts-ignore
                size: 0
            },
            formatter: function (seriesName, opts) {
                return seriesName + ":  " + opts.w.globals.series[opts.seriesIndex];
            },
            itemMargin: {
                vertical: 3
            }
        },
        responsive: [{
            breakpoint: 480,
            options: {
                legend: {
                    show: false
                }
            }
        }]
    };

    return (
        <Chart
            options={emailValidationOptions}
            series={series}
            type="radialBar"
            height={390}
        />
    );
}

export default LeadEmailVerificationChart;
