// src/app/pages/0-Account/EmailAccounts/ConnectPhones/ConnectPhoneNumbers.tsx
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button, CircularProgress, Typography, List, ListItem, Snackbar, Alert, Grid, IconButton, TextField, Collapse, Paper, Box, FormControl, FormControlLabel, Radio, RadioGroup, Dialog, DialogContent, DialogTitle, DialogActions } from '@mui/material';
import BuyPhoneNumbers from './BuyPhoneNumbers';
import DeleteSharpIcon from '@mui/icons-material/DeleteSharp';
import { ExpandLessSharp, ExpandMoreSharp, CloseSharp } from '@mui/icons-material';

interface Campaign {
    campaignId: string;
    campaignName: string;
    dailyLimit: number;
}

const ConnectPhoneNumbers = () => {
    const [phoneNumbers, setPhoneNumbers] = useState<[]>([]);
    const [connectedPhoneNumbers, setConnectedPhoneNumbers] = useState<[]>([]);
    const [loading, setLoading] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('info');
    const [showBuyDialog, setShowBuyDialog] = useState(false);
    const [showReleaseDialog, setShowReleaseDialog] = useState(false);
    const [selectedPhoneNumber, setSelectedPhoneNumber] = useState('');
    const [campaigns, setCampaigns] = useState<Campaign[]>([]);
    const [expandedPhoneNumber, setExpandedPhoneNumber] = useState<string | null>(null);
    const [configuringPhoneNumber, setConfiguringPhoneNumber] = useState<string | null>(null);
    const [configurationType, setConfigurationType] = useState('');
    const [voicemailUrl, setVoicemailUrl] = useState('');
    const [forwardTo, setForwardTo] = useState('');

    const fetchTwilioAccountInfo = async () => {
        setLoading(true);
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/phone/twilio-account-info`);
            setPhoneNumbers(response.data.phoneNumbers);
        } catch (error: any) {
            setSnackbarSeverity('error');
            setSnackbarMessage(`Failed to fetch phone numbers: ${error.response?.data?.message || error.message}`);
            setSnackbarOpen(true);
        } finally {
            setLoading(false);
        }
    };

    const fetchConnectedPhoneNumbers = async () => {
        setLoading(true);
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/phone/connected-phone-numbers`);
            setConnectedPhoneNumbers(response.data.phoneNumbers);
        } catch (error: any) {
            setSnackbarSeverity('error');
            setSnackbarMessage(`Failed to fetch connected phone numbers: ${error.response?.data?.message || error.message}`);
            setSnackbarOpen(true);
        } finally {
            setLoading(false);
        }
    };

    const handleConfigureNumber = async (phoneNumber) => {
        setLoading(true);
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/phone/configure-number`, { phoneNumber, voicemailUrl, forwardTo });
            setSnackbarSeverity('success');
            setSnackbarMessage('Phone number configured successfully');
            fetchTwilioAccountInfo();
            fetchConnectedPhoneNumbers();
        } catch (error: any) {
            setSnackbarSeverity('error');
            setSnackbarMessage(error.response?.data.message || 'Failed to configure phone number');
        } finally {
            setLoading(false);
            setSnackbarOpen(true);
            setConfiguringPhoneNumber(null);
        }
    };

    const fetchCampaignsForPhoneNumber = async (phoneNumber) => {
        setLoading(true);
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/phone/campaigns-for-phone/${phoneNumber}`);
            setCampaigns(response.data);
            setExpandedPhoneNumber(phoneNumber);
        } catch (error: any) {
            setSnackbarSeverity('error');
            setSnackbarMessage(`Failed to fetch campaigns for phone number: ${error.response?.data?.message || error.message}`);
            setSnackbarOpen(true);
        } finally {
            setLoading(false);
        }
    };

    const handleSetDailyLimit = async (campaignId, phoneNumber, dailyLimit) => {
        setLoading(true);
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/campaign/set-phone-daily-limit`, {
                campaignId,
                phoneNumber,
                dailyLimit
            });
            setSnackbarSeverity('success');
            setSnackbarMessage(response.data.message);
            fetchCampaignsForPhoneNumber(phoneNumber); // Refresh campaigns to show updated limits
        } catch (error: any) {
            setSnackbarSeverity('error');
            setSnackbarMessage(`Failed to set daily limit: ${error.response?.data?.message || error.message}`);
            setSnackbarOpen(true);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchTwilioAccountInfo();
        fetchConnectedPhoneNumbers();
    }, []);

    const handleDeletePhoneNumber = async (phoneNumber) => {
        setLoading(true);
        try {
            const response = await axios.delete(`${process.env.REACT_APP_API_URL}/phone/delete-phone-number`, { data: { phoneNumber } });
            setSnackbarSeverity('success');
            setSnackbarMessage('Phone number deleted successfully');
            fetchTwilioAccountInfo();
            fetchConnectedPhoneNumbers();
        } catch (error: any) {
            setSnackbarSeverity('error');
            setSnackbarMessage(error.response?.data.message || 'Failed to delete phone number');
        } finally {
            setLoading(false);
            setSnackbarOpen(true);
        }
    };

    const handleReleaseNumber = async () => {
        setLoading(true);
        try {
            await axios.post(`${process.env.REACT_APP_API_URL}/phone/release-number`, { phoneNumberSid: selectedPhoneNumber });
            setSnackbarSeverity('success');
            setSnackbarMessage('Phone number released successfully');
            fetchTwilioAccountInfo();
            fetchConnectedPhoneNumbers();
        } catch (error: any) {
            setSnackbarSeverity('error');
            setSnackbarMessage(`Failed to release phone number: ${error.response?.data.message || error.message}`);
        } finally {
            setLoading(false);
            setShowReleaseDialog(false);
            setSnackbarOpen(true);
        }
    };

    const toggleCampaignsVisibility = (phoneNumber) => {
        if (expandedPhoneNumber === phoneNumber) {
            setExpandedPhoneNumber(null);
        } else {
            fetchCampaignsForPhoneNumber(phoneNumber);
        }
    };

    const handleOpenConfigure = (phoneNumber) => {
        setSelectedPhoneNumber(phoneNumber);
        setConfigurationType('');
        setVoicemailUrl('');
        setForwardTo('');
        setConfiguringPhoneNumber(phoneNumber);
    };

    const handleCloseConfigure = () => {
        setConfiguringPhoneNumber(null);
    };

    const handleConfigureSubmit = (phoneNumber) => {
        handleConfigureNumber(phoneNumber);
    };

    return (
        <div>
            <Typography variant="h6" style={{ marginTop: '10px' }}>Phone numbers in your Twilio account:</Typography>
            {loading ? <CircularProgress /> : (
                <List>
                    {connectedPhoneNumbers && connectedPhoneNumbers.length > 0 ? connectedPhoneNumbers.map(({ phoneNumber, active, smsCount }, index) => (
                        <ListItem key={index} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Grid container spacing={2} alignItems="center">
                                <Grid item>
                                    <Typography variant="body1">{phoneNumber}</Typography>
                                </Grid>
                                {configuringPhoneNumber === phoneNumber ? (
                                    <Grid item>
                                        <IconButton color="secondary" onClick={handleCloseConfigure}>
                                            <CloseSharp />
                                        </IconButton>
                                    </Grid>
                                ) : (
                                    <Grid item>
                                        <Button variant="text" color="info" onClick={() => handleOpenConfigure(phoneNumber)}>
                                            Configure
                                        </Button>
                                    </Grid>
                                )}
                                <Grid item>
                                    <IconButton color="secondary" onClick={() => { setSelectedPhoneNumber(phoneNumber); setShowReleaseDialog(true); }}>
                                        <DeleteSharpIcon />
                                    </IconButton>
                                </Grid>
                            </Grid>
                            {configuringPhoneNumber === phoneNumber && (
                                <Grid container spacing={2} style={{ marginTop: '10px' }}>
                                    <Grid item xs={12}>
                                        <FormControl component="fieldset">
                                            <RadioGroup
                                                aria-label="configuration-type"
                                                name="configuration-type"
                                                value={configurationType}
                                                onChange={(e) => setConfigurationType(e.target.value)}
                                            >
                                                <FormControlLabel value="voicemail" control={<Radio />} label="Voicemail" />
                                                <FormControlLabel value="forward" control={<Radio />} label="Forward to another number" />
                                            </RadioGroup>
                                        </FormControl>
                                    </Grid>
                                    {configurationType === 'voicemail' && (
                                        <Grid item xs={12}>
                                            <TextField
                                                label="Voicemail URL"
                                                fullWidth
                                                margin="normal"
                                                value={voicemailUrl}
                                                onChange={(e) => setVoicemailUrl(e.target.value)}
                                                helperText="Enter the URL of the MP3 file to play when a call comes in."
                                            />
                                        </Grid>
                                    )}
                                    {configurationType === 'forward' && (
                                        <Grid item xs={12}>
                                            <TextField
                                                label="Forward To"
                                                fullWidth
                                                margin="normal"
                                                value={forwardTo}
                                                onChange={(e) => setForwardTo(e.target.value)}
                                                helperText="Enter the phone number to forward incoming calls to."
                                            />
                                        </Grid>
                                    )}
                                    <Grid item xs={12}>
                                        <Button variant="contained" color="secondary" onClick={() => handleConfigureSubmit(phoneNumber)}>
                                            Submit
                                        </Button>
                                    </Grid>
                                </Grid>
                            )}
                        </ListItem>
                    )) : (
                        <Typography variant="body1">No phone numbers available.</Typography>
                    )}
                </List>
            )}

            <Button variant="contained" color="primary" onClick={() => setShowBuyDialog(true)} style={{ marginTop: '20px' }}>
                Buy Number
            </Button>

            <Dialog sx={{ zIndex: 2147483647 }} open={showBuyDialog} onClose={() => setShowBuyDialog(false)} maxWidth="md" fullWidth>
                <DialogContent>
                    <BuyPhoneNumbers onClose={() => setShowBuyDialog(false)} />
                </DialogContent>
            </Dialog>

            <Dialog sx={{ zIndex: 2147483647 }} open={showReleaseDialog} onClose={() => setShowReleaseDialog(false)}>
                <DialogTitle>Warning</DialogTitle>
                <DialogContent>
                    <Typography>Are you sure you want to release this phone number?</Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setShowReleaseDialog(false)} color="primary">Cancel</Button>
                    <Button onClick={handleReleaseNumber} color="secondary">Release</Button>
                </DialogActions>
            </Dialog>

            <Typography variant="h6" style={{ marginTop: '20px' }}>Connected Phone Numbers for Use:</Typography>
            {loading ? <CircularProgress /> : (
                <List>
                    {connectedPhoneNumbers.map(({ phoneNumber, active, smsCount }, index) => (
                        <Paper
                            elevation={3}
                            key={index}
                            sx={{ marginBottom: 2, padding: 2, borderRadius: 2 }}
                        >
                            <ListItem sx={{ display: 'flex', flexDirection: 'column', alignItems: 'stretch' }}>
                                <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                                    <Typography variant="h6">{phoneNumber} (SMS Count: {smsCount})</Typography>
                                    <Box>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={() => toggleCampaignsVisibility(phoneNumber)}
                                            endIcon={expandedPhoneNumber === phoneNumber ? <ExpandLessSharp /> : <ExpandMoreSharp />}
                                            sx={{ marginRight: 1 }}
                                        >
                                            {expandedPhoneNumber === phoneNumber ? 'Hide Campaigns' : 'Show Campaigns'}
                                        </Button>
                                        <IconButton
                                            color="secondary"
                                            onClick={() => handleDeletePhoneNumber(phoneNumber)}
                                        >
                                            <DeleteSharpIcon />
                                        </IconButton>
                                    </Box>
                                </Box>
                                <Collapse in={expandedPhoneNumber === phoneNumber} timeout="auto" unmountOnExit>
                                    <List sx={{ marginTop: 2 }}>
                                        {campaigns.length === 0 ? (
                                            <ListItem>No campaigns found for this phone number.</ListItem>
                                        ) : (
                                            campaigns.map((campaign) => (
                                                <ListItem key={campaign.campaignId} sx={{ display: 'flex', alignItems: 'center' }}>
                                                    <Typography variant="body1" sx={{ flex: 1 }}>
                                                        {campaign.campaignName}
                                                    </Typography>
                                                    <TextField
                                                        label="Daily Limit"
                                                        type="number"
                                                        defaultValue={campaign.dailyLimit}
                                                        onChange={(e) =>
                                                            setCampaigns((prevCampaigns) =>
                                                                prevCampaigns.map((c) =>
                                                                    c.campaignId === campaign.campaignId
                                                                        ? { ...c, dailyLimit: Number(e.target.value) }
                                                                        : c
                                                                )
                                                            )
                                                        }
                                                        sx={{ marginRight: 2, width: 100 }}
                                                    />
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        onClick={() =>
                                                            handleSetDailyLimit(
                                                                campaign.campaignId,
                                                                phoneNumber,
                                                                campaign.dailyLimit
                                                            )
                                                        }
                                                    >
                                                        Set Limit
                                                    </Button>
                                                </ListItem>
                                            ))
                                        )}
                                    </List>
                                </Collapse>
                            </ListItem>
                        </Paper>
                    ))}
                </List>
            )}

            <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={() => setSnackbarOpen(false)}>
                <Alert onClose={() => setSnackbarOpen(false)}
                    //@ts-ignore
                    severity={snackbarSeverity}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </div>
    );
};

export default ConnectPhoneNumbers;
