// src\app\pages\0-Account\EmailAccounts\EmailConnection\ConnectionForm\EmailConnectionModal.tsx
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import GridOnSharpIcon from '@mui/icons-material/GridOnSharp';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import FormControlLabel from '@mui/material/FormControlLabel';
import IconButton from '@mui/material/IconButton';
import Modal from '@mui/material/Modal';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Typography from '@mui/material/Typography';
import React from 'react';

import MultipleSMTP_IMAPForm from '../ConnectionMethods/MultiConnect';


interface EmailConnectionModalProps {
    show: boolean;
    onHide: () => void;
    RefreshAccountList: () => void;
}

const EmailConnectionModalMulti: React.FC<EmailConnectionModalProps> = ({
    show,
    onHide,
    RefreshAccountList
}) => {
    const [selectedOption, setSelectedOption] = React.useState<string | null>(null);
    const [isConfirmDialogOpen, setIsConfirmDialogOpen] = React.useState(false);

    const handleOptionSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedOption(event.target.value);
    };

    const handleBackButtonClick = () => {
        setSelectedOption(null);
    };
    const modalStyle = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '80%',
        boxShadow: 24,
        p: 4,
    };
    const backdropStyle = {
        color: 'var(--bs-body-bg)',
        backgroundColor: 'var(--bs-body-bg)', // White background with 80% opacity
    };


    const handleDialogOpen = () => {
        setIsConfirmDialogOpen(true);
        RefreshAccountList();
    };

    const modifiedOnHide = () => {
        handleDialogOpen();
    };

    const handleConfirmClose = () => {
        setIsConfirmDialogOpen(false);
        RefreshAccountList();
        onHide(); // This calls the original onHide prop, closing the modal
    };

    const handleCancelClose = () => {
        setIsConfirmDialogOpen(false);
    };

    return (
        <Modal
            open={show}
            onClose={modifiedOnHide}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            BackdropComponent={Backdrop}
            BackdropProps={{
                sx: backdropStyle
            }}
        >
            <Box sx={modalStyle}>


                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        mt: 2,
                    }}
                >
                    {selectedOption ? (
                        <>
                            <IconButton onClick={handleBackButtonClick} color="primary">
                                <ArrowBackIcon />
                            </IconButton>
                            {selectedOption === 'MultiConnect' && <MultipleSMTP_IMAPForm />}

                        </>
                    ) : (
                        <RadioGroup
                            aria-labelledby="email-option-group-label"
                            name="email_options"
                            onChange={handleOptionSelect}
                        >

                            <FormControlLabel
                                value="MultiConnect"
                                control={<Radio />}
                                label={
                                    <Box sx={{ display: 'flex', padding: "30px", alignItems: 'center' }}>
                                        <GridOnSharpIcon color='secondary' style={{ fontSize: "80px" }} />
                                        <Box sx={{ paddingLeft: "30px", paddingRight: "30px", alignItems: 'center' }}>
                                            <Typography sx={{ fontSize: "30px" }} component="div">
                                                CONNECT MULTIPLE ACCOUNTS
                                            </Typography>
                                            <Typography variant="body2" color="text.secondary">
                                                Connect Multiple Accounts Using SMTP and IMAP.
                                            </Typography>
                                        </Box>
                                    </Box>
                                }
                            />


                        </RadioGroup>
                    )}
                </Box>
                <Dialog
                    open={isConfirmDialogOpen}
                    onClose={handleCancelClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            All changes will be lost if closed. Are you sure you want to close?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCancelClose}>Cancel</Button>
                        <Button onClick={handleConfirmClose} autoFocus>
                            Confirm
                        </Button>
                    </DialogActions>
                </Dialog>
            </Box>
        </Modal>

    );
};

export default EmailConnectionModalMulti;

