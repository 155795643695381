import React, { useState, useEffect } from 'react';
import { Box, Button, Typography, useTheme, Divider } from '@mui/material';
import { motion } from 'framer-motion';
import WarmupSkeleton from './BubbleSkeleton';
import { DayScheduleInterface } from '../../InterfacesWarmupSettings';
import ArrowDropDownCircleSharpIcon from '@mui/icons-material/ArrowDropDownCircleSharp';
import CustomTooltip from '@/src/app/modules/CustomMaterialUiComponents/CustomTooltip';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { useAuth } from '@/src/app/modules/auth'; // Ensure path is correct

dayjs.extend(utc);
dayjs.extend(timezone);

interface Props {
    daySchedules: DayScheduleInterface[];
    isLoading: boolean;
}

const BubblesComponent: React.FC<Props> = ({ daySchedules, isLoading }) => {
    const [isTimeoutElapsed, setIsTimeoutElapsed] = useState(false);
    const [visibleBubbles, setVisibleBubbles] = useState(15);
    const theme = useTheme();
    const { currentUser } = useAuth();

    useEffect(() => {
        const timer = setTimeout(() => {
            setIsTimeoutElapsed(true);
        }, 0);

        return () => clearTimeout(timer);
    }, []);

    const findCenterBubbleIndex = () => {
        if (!currentUser) return 0; // Early return if currentUser is not valid
        const today = dayjs().tz(currentUser.timezone);
        const dates = daySchedules.map(schedule => schedule.Date ? dayjs(schedule.Date).tz(currentUser.timezone) : null);

        // If no dates exist, return 0
        if (dates.every(date => date === null)) {
            return 0;
        }

        // Convert dates to timestamps and find the closest date to today
        let closest = dates.reduce((a, b) => {
            const aTime = a ? a.valueOf() : Number.MAX_VALUE;
            const bTime = b ? b.valueOf() : Number.MAX_VALUE;
            const todayTime = today.valueOf();

            return Math.abs(bTime - todayTime) < Math.abs(aTime - todayTime) ? b : a;
        });

        return dates.indexOf(closest);
    };

    const getBubbleColor = (date: string | null | undefined) => {
        if (!currentUser) return 'var(--bs-dark-dark)'; // Early return if currentUser is not valid
        const today = dayjs().tz(currentUser.timezone);
        const dayScheduleDate = date ? dayjs(date).tz(currentUser.timezone) : null;

        if (dayScheduleDate) {
            if (
                dayScheduleDate.date() === today.date() &&
                dayScheduleDate.month() === today.month() &&
                dayScheduleDate.year() === today.year()
            ) {
                return 'secondary.main'; // Color for today
            }
            return 'primary.main'; // Color for past dates
        }
        return 'var(--bs-dark-dark)'; // Color for null or undefined dates
    };

    const getFontColor = (date: string | null | undefined) => {
        if (date === null || date === undefined) {
            return 'var(--bs-text-dark)'; // Primary color for null or undefined dates
        }
        return 'white'; // White color for existing dates
    };

    const getInitialRotation = () => ({
        rotate: Math.floor(Math.random() * (360 - (-360) + 1)) + (-360),
        scale: 0.5,  // start smaller
    });

    const getControlledRotation = () => ({
        rotate: Math.floor(Math.random() * (30 - (-30) + 1)) + (-30),
        scale: 1,
    });

    if (isLoading) {
        return <WarmupSkeleton />;
    }

    const centerIndex = findCenterBubbleIndex();
    const start = Math.max(centerIndex - Math.floor(visibleBubbles / 2), 0);
    const end = Math.min(start + visibleBubbles, daySchedules.length);
    const visibleSchedules = daySchedules.slice(start, end);

    const toggleView = () => {
        setVisibleBubbles(visibleBubbles === 15 ? daySchedules.length : 15);
    };

    const bubbleVariants = {
        visible: { opacity: 1, scale: 1 },
        hidden: { opacity: 0, scale: 0 }
    };

    return (
        <>
            {isLoading ? (
                <WarmupSkeleton />
            ) : isTimeoutElapsed ? (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2, padding: 3, alignContent: "center", alignItems: 'center', justifyContent: "center" }}>
                    {visibleSchedules.map((schedule, index) => (
                        <CustomTooltip
                            title={
                                <React.Fragment>
                                    <Box>
                                        <Typography fontSize={35} sx={{ display: "flex", alignItems: "center", justifyContent: "center" }} color="inherit">Day {schedule.dayNumber}</Typography>
                                        <Divider />
                                        <Typography color="inherit">Emails: {schedule.emailCount}</Typography>
                                        <Typography color="inherit">Moved from Spam: {schedule.movedFromSpam}</Typography>
                                        <Typography color="inherit">Sent Warmup Emails: {schedule.sentWarmupEmails}</Typography>
                                        <Typography color="inherit">Sent Replies: {schedule.sentReplies}</Typography>
                                        <Typography color="inherit">Planned Replies: {schedule.plannedReplies}</Typography>
                                        <Divider />
                                        {schedule.Date && <Typography color="inherit" >Date: {schedule.Date}</Typography>}
                                    </Box>
                                </React.Fragment>
                            }
                            sx={{ height: "100%" }}
                            key={schedule.dayNumber}
                        >
                            <motion.div
                                className="no-global-transition"
                                initial={getInitialRotation()}
                                animate={getControlledRotation()}
                                whileHover={{ rotate: 0, scale: 1.5 }}
                                whileTap={{ scale: 0.7 }}
                                transition={{
                                    type: "spring",
                                    stiffness: 400,
                                    damping: 10
                                }}
                                variants={bubbleVariants} // Add this for the fade in/out animation
                                custom={index} // Pass index for staggered animation
                            >
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        width: 80,
                                        height: 80,
                                        borderRadius: '50%',
                                        backgroundColor: getBubbleColor(schedule.Date),
                                        boxShadow: "0px 3px 3px -2px rgba(0,0,0,0.2),0px 3px 4px 0px rgba(0,0,0,0.14),0px 1px 8px 0px rgba(0,0,0,0.12)"
                                    }}
                                >
                                    <Typography variant="h6" fontSize={24} sx={{ color: getFontColor(schedule.Date) }}>
                                        {schedule.dayNumber}
                                    </Typography>
                                </Box>
                            </motion.div>
                        </CustomTooltip>
                    ))}

                    <motion.div
                        className="no-global-transition"
                        variants={bubbleVariants}
                        initial="hidden"
                        animate="visible"
                        transition={{ duration: 0.5 }}
                    >
                        {daySchedules.length > 15 && (
                            <Button onClick={toggleView}>
                                {visibleBubbles === 15 ? <ArrowDropDownCircleSharpIcon sx={{ fontSize: "80px" }} /> : <ArrowDropDownCircleSharpIcon sx={{ fontSize: "80px", transform: "rotate(180deg)" }} />}
                            </Button>
                        )}
                    </motion.div>
                </Box>
            ) : (
                <WarmupSkeleton />
            )}
        </>
    );
};

export default BubblesComponent;
