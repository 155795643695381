// src\app\pages\0-Account\EmailAccounts\EmailConnection\ConnectionMethods\PersonalGmailForm.tsx
import { Box, Button, Paper, TextField, Typography } from '@mui/material';
import axios from 'axios';
import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import Swal from 'sweetalert2';
import * as Yup from 'yup';

import MaskedInput from './MaskedInputAppPassword';

const PersonalGmailForm: React.FC = () => {
    const [isEmailComplete, setIsEmailComplete] = useState(false);

    const initialValues = {
        email: '',
        firstName: '',
        lastName: '',
        appPassword: ''
    };

    const validationSchema = Yup.object({
        email: Yup.string().email('Invalid email address').required('Email is required'),
        firstName: Yup.string().required('First name is required'),
        lastName: Yup.string().required('Last name is required'),
        appPassword: Yup.string().required('App password is required')
    });

    const handleAddEmailAccount = async (values, { resetForm }) => {

        try {
            await axios.post(`${process.env.REACT_APP_API_URL}/account/emailaccounts/connect/`, {
                sendingAccount: values.email,
                password: values.appPassword,
                smtpHost: 'smtp.gmail.com',
                smtpPort: 587,
                imapHost: 'imap.gmail.com',
                imapPort: 993,
                firstName: values.firstName,
                lastName: values.lastName
            });

            Swal.fire('Success', 'Gmail account connected successfully!', 'success');
            resetForm();
        } catch (error) {
            Swal.fire('Error', 'Failed to connect Gmail account. Please try again.', 'error');
        }
    };


    return (
        <Paper elevation={3} sx={{ padding: 3, margin: 2 }}>
            <Typography variant="h6" gutterBottom>
                Connect to Personal Gmail
            </Typography>
            <Typography variant="body1" sx={{ marginBottom: 2 }}>
                To connect your Gmail account, please enable two-step verification and create an app password in your Google account settings.
            </Typography>

            <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleAddEmailAccount}>
                {({ values, handleChange, handleBlur }) => (
                    <Form>
                        <TextField
                            fullWidth
                            label="Email"
                            name="email"
                            value={values.email}
                            onChange={e => {
                                handleChange(e);
                                setIsEmailComplete(e.target.value.includes('@'));
                            }}
                            onBlur={handleBlur}
                            margin="normal"
                            variant="outlined"
                        />
                        {isEmailComplete && (
                            <>
                                <TextField
                                    fullWidth
                                    label="First Name"
                                    name="firstName"
                                    value={values.firstName}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    margin="normal"
                                    variant="outlined"
                                />
                                <TextField
                                    fullWidth
                                    label="Last Name"
                                    name="lastName"

                                    value={values.lastName}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    margin="normal"
                                    variant="outlined"
                                />
                                <MaskedInput
                                    label="App Password"
                                    name="appPassword"
                                    value={values.appPassword}
                                    onChange={handleChange}
                                />
                                <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: 2 }}>
                                    <Button type="submit" variant="contained" color="primary">
                                        Connect Account
                                    </Button>
                                </Box>

                            </>
                        )}

                    </Form>

                )}

            </Formik>

        </Paper>

    );
};

export default PersonalGmailForm;

