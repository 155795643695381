// src\app\pages\4-Campaigns\1-CampaignStatistics\Charts\LeadStatusChart.tsx
import { useTheme } from '@mui/material/styles';
import React from 'react';
import Chart from 'react-apexcharts';

interface LeadStatusChartProps {
    series: number[];
}

const LeadStatusChart: React.FC<LeadStatusChartProps> = ({ series }) => {
    const theme = useTheme();

    const leadStatusOptions: ApexCharts.ApexOptions = {
        chart: {
            type: 'donut',
            width: '100%'
        },
        labels: ['Pending', 'Queued', 'In Progress', 'Completed'], // Custom labels for the chart segments
        colors: [`${theme.palette.primary.main}`, `${theme.palette.secondary.main}`, `${theme.palette.primary.main}`, `${theme.palette.secondary.main}`], // Array of colors for the chart segments, alternating purple and orange
        dataLabels: {
            enabled: false
        },
        plotOptions: {
            pie: {
                donut: {
                    labels: {
                        show: false
                    },
                    size: '65%',
                    //@ts-ignore
                    dataLabels: {
                        enabled: false
                    }
                }
            }
        },
        stroke: {
            show: false  // Set to false to remove the border around each segment
        },
        responsive: [{
            breakpoint: 480,
            options: {
                chart: {
                    width: 200
                },
                legend: {
                    show: false
                }
            }
        }],
        legend: {
            position: 'right',
            offsetY: 0,
            height: 230,
        }
    };

    const customStyles = `
    .apexcharts-legend .apexcharts-legend-series:nth-of-type(1) .apexcharts-legend-text,
    .apexcharts-legend .apexcharts-legend-series:nth-of-type(3) .apexcharts-legend-text {
        color: ${theme.palette.primary.main}!important; /* Purple */
    }
    .apexcharts-legend .apexcharts-legend-series:nth-of-type(2) .apexcharts-legend-text,
    .apexcharts-legend .apexcharts-legend-series:nth-of-type(4) .apexcharts-legend-text {
        color: ${theme.palette.secondary.main}!important; /* Orange */
    }
`;


    return (
        <div>
            <style>{customStyles}</style>
            <Chart
                options={leadStatusOptions}
                series={series}
                type="donut"
            />
        </div>
    );
}

export default LeadStatusChart;
