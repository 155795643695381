// src\app\modules\campaign\CampaignWizard\Steps\CampaignReview.tsx
import { AutoModeSharp, LocalShippingSharp, StopCircleSharp } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import { Button, CircularProgress, Grid, IconButton, Snackbar, Tooltip, Typography } from '@mui/material';
import { green, red } from '@mui/material/colors';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import {
    PlaceholderLeadReviewInfo,
    PlaceholderLeadsReviewList,
    PlaceholderLeadsReviewSequence,
} from './CamapignReviewPlaceholders';
import LeadReviewInfo from './LeadReviewInfo';
import LeadsReviewList from './LeadsReviewList';
import LeadsReviewSequence from './LeadsReviewSequence';
import { IMessage, IPersonalizedMessage } from './PersonalizedMessageInterface';
import CampaignStatusBar from '../0-CampaignParent/CampaignStatusBar';

interface CampaignReviewProps {
    selectedCampaignId: string | null;
}


export interface ILeadReviewInfo {
    personalizedMessageId: string;
    leadId: string;
    leadStatus: "pending" | "queued" | "completed" | "inprogress";
    approved: boolean;
    replied: boolean;
    fullname?: string;
    firstname?: string;
    lastname?: string;
    email?: string;
    companyname?: string;
    phone?: string;
    location?: string;
}



const CampaignReview: React.FC<CampaignReviewProps> = ({ selectedCampaignId }) => {
    const queryClient = useQueryClient();

    const approveLeadsMutation = useMutation(
        ({ personalizedMessageIds, approve }: { personalizedMessageIds: string[]; approve: boolean }) =>
            axios.put(`${process.env.REACT_APP_API_URL}/campaign/leads/approve`, { personalizedMessageIds, approve }),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(['leadReviewInfo', selectedCampaignId]);
                setSuccessMessage('Leads processed successfully!');
            },
            onError: () => {
                setErrorMessage('Error processing leads');
            },
        }
    );


    const unqueueMessagesMutation = useMutation(
        ({ personalizedMessageIds }: { personalizedMessageIds: string[] }) =>
            axios.post(`${process.env.REACT_APP_API_URL}/campaign/${selectedCampaignId}/unqueue`, { personalizedMessageIds }),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(['leadReviewInfo', selectedCampaignId]);
                setSuccessMessage('Messages unqueued successfully!');
            },
            onError: () => {
                setErrorMessage('Error unqueuing messages');
            },
        }
    );


    const processQueueMutation = useMutation(
        () => axios.post(`${process.env.REACT_APP_API_URL}/campaign/${selectedCampaignId}/queue-process`),
        {
            onSuccess: () => {
                setSuccessMessage('Campaign queue processing initiated successfully!');
            },
            onError: (error: any) => {
                const errorMessage = error.response?.data?.message || 'Error initiating campaign queue processing';
                setErrorMessage(errorMessage);
            },
        }
    );

    const refreshPersonalizationMutation = useMutation(
        () => axios.post(`${process.env.REACT_APP_API_URL}/campaign/${selectedCampaignId}/refresh-personalization`),
        {
            onSuccess: () => {
                setSuccessMessage('Personalization refreshed successfully!');
            },
            onError: (error: any) => {
                const errorMessage = error.response?.data?.message || 'Error refreshing personalization';
                setErrorMessage(errorMessage);
            },
        }
    );

    const stopMessageGenerationMutation = useMutation(
        () => axios.post(`${process.env.REACT_APP_API_URL}/campaign/${selectedCampaignId}/stop-personalization`),
        {
            onSuccess: () => {
                setSuccessMessage('Message generation stopped successfully!');
            },
            onError: (error: any) => {
                const errorMessage = error.response?.data?.message || 'Error stopping message generation';
                setErrorMessage(errorMessage);
            },
        }
    );


    const fetchLeadReviewInfo = async ({ queryKey }): Promise<ILeadReviewInfo[]> => {
        const [_key, selectedCampaignId] = queryKey;
        const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/leads/campaign/${selectedCampaignId}/leadreviewinfo`);
        return data;
    };

    const { data: leadReviewInfo, isLoading, isError, error } = useQuery(['leadReviewInfo', selectedCampaignId], fetchLeadReviewInfo, {
        enabled: !!selectedCampaignId,
    });

    const [successMessage, setSuccessMessage] = useState<string | null>(null);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [selectedLead, setSelectedLead] = useState<string | null>(null);
    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(10);
    const [personalizedMessage, setPersonalizedMessage] = useState<IPersonalizedMessage | null>(null);
    const [messages, setMessages] = useState<IMessage[]>([]);

    const fetchPersonalizedMessage = async (campaignId: string, leadId: string) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/campaign/${campaignId}/lead/${leadId}/personalizedmessage`);
            setPersonalizedMessage(response.data);
            setMessages(response.data.messages)
        } catch (error) {
            console.error('Error fetching personalized messages:', error);
        }
    };

    useEffect(() => {
        if (selectedCampaignId && selectedLead) {
            fetchPersonalizedMessage(selectedCampaignId, selectedLead);
        }
    }, [selectedCampaignId, selectedLead]);



    if (isLoading) {
        return (


            <Grid container spacing={3}>



                <Grid item xs={4}>
                    <CircularProgress />

                    <Typography variant="h6" color="text.secondary" style={{ marginBottom: '20px' }}>
                        Loading...
                    </Typography>

                    <PlaceholderLeadsReviewList isLoading={true} />
                </Grid>
                <Grid item xs={4}>
                    <PlaceholderLeadsReviewSequence isLoading={true} />
                </Grid>
                <Grid item xs={4}>
                    <PlaceholderLeadReviewInfo isLoading={true} />
                </Grid>
            </Grid>
        );
    }


    if (!leadReviewInfo || leadReviewInfo.length === 0) {
        return (
            <>

                <CampaignStatusBar
                    selectedCampaignId={selectedCampaignId}
                />



                <Grid container spacing={3}>


                    <Grid item xs={4}>
                        <Typography variant="h6" color="text.secondary" style={{ marginBottom: '20px' }}>
                            Activate Campaign to Generate Personalized Messages for Leads
                        </Typography>

                        <PlaceholderLeadsReviewList isLoading={false} />
                    </Grid>
                    <Grid item xs={4}>
                        <PlaceholderLeadsReviewSequence isLoading={false} />
                    </Grid>
                    <Grid item xs={4}>
                        <PlaceholderLeadReviewInfo isLoading={false} />
                    </Grid>
                </Grid>
            </>
        );
    }

    if (isError || !leadReviewInfo) {
        return (



            <Grid container spacing={3}>



                <Grid item xs={4}>
                    <Typography variant="h6" color="text.secondary" style={{ marginBottom: '20px' }}>

                        Reload
                    </Typography>
                    <PlaceholderLeadsReviewList isLoading={false} />
                </Grid>
                <Grid item xs={4}>
                    <PlaceholderLeadsReviewSequence isLoading={false} />
                </Grid>
                <Grid item xs={4}>
                    <PlaceholderLeadReviewInfo isLoading={false} />
                </Grid>
            </Grid>
        );
    }

    const handleLeadClick = (leadId: string) => {
        setSelectedLead(leadId);
    };

    return (
        <>
            <Snackbar
                open={!!successMessage}
                autoHideDuration={6000}
                message={
                    <div style={{ backgroundColor: green[400], color: '#fff', padding: '8px', borderRadius: '4px' }}>
                        {successMessage}
                        <IconButton
                            size="small"
                            aria-label="close"
                            color="inherit"
                            onClick={() => setSuccessMessage(null)}
                        >
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    </div>
                }
                ContentProps={{
                    style: { backgroundColor: green[400], color: '#fff' },
                }}
            />
            <Snackbar
                open={!!errorMessage}
                autoHideDuration={6000}
                message={
                    <div style={{ backgroundColor: red[600], color: '#fff', padding: '8px', borderRadius: '4px' }}>
                        {errorMessage}
                        <IconButton
                            size="small"
                            aria-label="close"
                            color="inherit"
                            onClick={() => setErrorMessage(null)}
                        >
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    </div>
                }
                ContentProps={{
                    style: { backgroundColor: red[600], color: '#fff' },
                }}
            />

            <Grid container spacing={3}>
                <Grid item xs={12}

                    style={{ padding: '3px', overflow: 'auto', maxHeight: "75vh" }}
                >
                    <CampaignStatusBar
                        selectedCampaignId={selectedCampaignId}
                    />

                </Grid>

                <Grid item xs={4}

                    style={{ padding: '3px', overflow: 'auto', maxHeight: "75vh" }}
                >
                    <LeadsReviewList
                        leadReviewInfo={leadReviewInfo}
                        page={page}
                        rowsPerPage={rowsPerPage}
                        handleLeadClick={handleLeadClick}
                        selectedLead={selectedLead}
                        setPage={setPage}
                        setRowsPerPage={setRowsPerPage}
                        selectedCampaignId={selectedCampaignId}
                        approveLeads={approveLeadsMutation}
                        unqueueMessages={unqueueMessagesMutation}
                    />
                </Grid>
                <Grid item xs={4}
                    style={{ padding: '3px', overflow: 'auto', maxHeight: "75vh" }}
                >
                    {selectedLead ? (
                        <LeadsReviewSequence
                            leadReviewInfo={leadReviewInfo}
                            selectedLead={selectedLead}
                            selectedCampaignId={selectedCampaignId}
                            personalizedMessage={personalizedMessage}
                            //@ts-ignore
                            messages={messages}
                        />
                    ) : (
                        <PlaceholderLeadsReviewSequence isLoading={false} />
                    )}
                </Grid>
                <Grid item xs={4}
                    style={{ padding: '3px', overflow: 'auto', maxHeight: "75vh" }}
                >
                    <Tooltip title={"Add Possible Leads to Sending Queue"} >
                        <Button
                            variant="text"
                            color="primary"
                            onClick={() => processQueueMutation.mutate()}
                            style={{ marginBottom: '20px' }}
                        >
                            <LocalShippingSharp sx={{ fontSize: "35px" }} />

                        </Button>
                    </Tooltip>
                    <Tooltip title={"Refresh Personalization"} >
                        <Button
                            variant="text"
                            color="secondary"
                            onClick={() => refreshPersonalizationMutation.mutate()}
                            style={{ marginBottom: '20px' }}
                        >
                            <AutoModeSharp sx={{ fontSize: "35px" }} />

                        </Button>
                    </Tooltip>
                    <Tooltip title={"Stop Message Generation"} >
                        <Button
                            variant="text"
                            color="error"
                            onClick={() => stopMessageGenerationMutation.mutate()}
                            style={{ marginBottom: '20px' }}
                        >
                            <StopCircleSharp sx={{ fontSize: "35px" }} />
                        </Button>
                    </Tooltip>


                    {selectedLead ? (
                        <LeadReviewInfo
                            selectedCampaignId={selectedCampaignId}
                            selectedLead={selectedLead}
                            personalizedMessage={personalizedMessage}
                        />
                    ) : (
                        <PlaceholderLeadReviewInfo isLoading={false} />
                    )}
                </Grid>
            </Grid>

        </>
    );
};

export default CampaignReview;
