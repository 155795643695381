// src\app\pages\4-Campaigns\5-CampaignReview\ModalDialog.tsx
import React from 'react';
import { useState } from 'react';
import {
    Typography,
    Paper,
    Box,
    TextField,
    Button,
    CircularProgress,
} from '@mui/material';
import DraggableDialog from '../../../modules/CustomMaterialUiComponents/DraggableDialog';
import LexicalEditorWrapper from '../3-CampaignSequence/SequenceComponents/NewEditor/components/LexicalEditorWrapper/LexicalEditorWrapper';
import axios from 'axios';
import SaveIcon from '@mui/icons-material/Save';
import SparklesIcon from '@mui/icons-material/AutoFixHigh';
import { green, red } from '@mui/material/colors';
import { IMessage } from './PersonalizedMessageInterface';
import { formatDelay } from './LeadsReviewSequence';

interface MessageDialogProps {
    message: IMessage;
    open: boolean;
    onClose: () => void;
    selectedCampaignId: string | null; // Add this line
    personalizedMessageId: string; // Added this prop
    leadReviewInfo: any[];
}

const MessageDialog: React.FC<MessageDialogProps> = ({
    message,
    open,
    onClose,
    selectedCampaignId,
    personalizedMessageId,
    leadReviewInfo,
}) => {
    const [editorContent, setEditorContent] = useState(message.content || '');
    const [saveLoading, setSaveLoading] = useState(false);
    const [saveSuccess, setSaveSuccess] = useState(false);
    const [saveError, setSaveError] = useState(false);
    const [showPrompt, setShowPrompt] = useState(false);
    const [rewriteLoading, setRewriteLoading] = useState(false);
    const [rewriteSuccess, setRewriteSuccess] = useState(false);
    const [rewriteError, setRewriteError] = useState(false);
    const [generatedContent, setGeneratedContent] = useState('');
    const [customPrompt, setCustomPrompt] = useState('Please rewrite this message to make it more engaging and friendly.');

    // Define the function to save changes
    const saveChanges = async () => {
        setSaveLoading(true);
        setSaveSuccess(false);
        setSaveError(false);

        try {
            await axios.put(
                `${process.env.REACT_APP_API_URL}/campaign/update-specific-message`,
                {
                    personalizedMessageId, // Ensure to use the correct ID field
                    messageId: message._id, // This might be the same or different ID based on your data structure
                    content: editorContent,
                    subject: message.subject,
                    // Include any other fields that might be updated
                }
            );

            setSaveLoading(false);
            setSaveSuccess(true);
        } catch (error) {
            console.error('Error saving message:', error);
            setSaveLoading(false);
            setSaveError(true);
        }
    };

    // Define the function to rewrite the message content using AI
    const rewriteContent = async () => {
        setRewriteLoading(true);
        setRewriteSuccess(false);
        setRewriteError(false);

        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/campaign/personalization/rewrite-single-message`,
                {
                    personalizedMessageId,
                    messageId: message._id,
                    instructions: customPrompt,
                }
            );

            const newContent = response.data.newContent;
            setGeneratedContent(newContent);
            setRewriteLoading(false);
            setRewriteSuccess(true);
        } catch (error) {
            console.error('Error rewriting message:', error);
            setRewriteLoading(false);
            setRewriteError(true);
        }
    };

    // Handle content changes from the editor
    const handleEditorContentChange = (newContent: string) => {
        setEditorContent(newContent);
    };

    const handlePromptChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCustomPrompt(event.target.value);
    };

    const determinedEmailType = message.type === 'sms' ? 'plain' : message.emailType || 'plain';

    const dialogContent = (
        <div>
            <Typography variant="subtitle1">{message.type}</Typography>
            <Typography variant="body2">
                <strong>Subject:</strong> {message.subject}
            </Typography>
            <Typography variant="body2">
                <strong>Delay:</strong>
                {formatDelay(
                    //@ts-ignore
                    message.sendAfterDelay
                )}
            </Typography>
            <LexicalEditorWrapper
                initialContent={editorContent}
                onContentChange={handleEditorContentChange}
                contentKey={message._id}
                emailType={determinedEmailType}
            />
            <div style={{ marginTop: '20px', display: 'flex', gap: '10px' }}>
                <Button
                    variant="contained"
                    startIcon={saveLoading ? <CircularProgress size={20} /> : <SaveIcon />}
                    onClick={saveChanges}
                    disabled={saveLoading}
                    style={{
                        backgroundColor: saveSuccess ? green[500] : saveError ? red[500] : undefined,
                        color: '#fff',
                    }}
                >
                    Save Changes
                </Button>
                <Button
                    variant="contained"
                    startIcon={<SparklesIcon />}
                    onClick={() => setShowPrompt(!showPrompt)}
                >
                    {showPrompt ? 'Hide Prompt' : 'Rewrite with AI'}
                </Button>
            </div>
            {showPrompt && (
                <div style={{ marginTop: '20px' }}>
                    <TextField
                        fullWidth
                        multiline
                        rows={4}
                        variant="outlined"
                        value={customPrompt}
                        onChange={handlePromptChange}
                        label="Custom Prompt"
                        helperText="Modify the prompt to guide the AI"
                    />
                    <Button
                        variant="contained"
                        startIcon={rewriteLoading ? <CircularProgress size={20} /> : <SparklesIcon />}
                        onClick={rewriteContent}
                        disabled={rewriteLoading}
                        style={{ marginTop: '10px' }}
                    >
                        Generate
                    </Button>
                    {rewriteSuccess && (
                        <div style={{ marginTop: '10px' }}>
                            <Typography variant="body2">
                                <strong>Generated Content:</strong>
                            </Typography>
                            <Paper style={{ padding: '10px', marginTop: '10px' }}>
                                <Typography variant="body2">{generatedContent}</Typography>
                            </Paper>
                            <div style={{ display: 'flex', gap: '10px', marginTop: '10px' }}>
                                <Button
                                    variant="outlined"
                                    onClick={() => navigator.clipboard.writeText(generatedContent)}
                                >
                                    Copy
                                </Button>
                            </div>
                        </div>
                    )}
                    {rewriteError && (
                        <Typography variant="body2" color="error" style={{ marginTop: '10px' }}>
                            An error occurred while generating content.
                        </Typography>
                    )}
                </div>
            )}
        </div>
    );

    return (
        <Box sx={{ zIndex: '99999999999999' }}>
            <DraggableDialog
                open={open}
                onClose={onClose}
                content={dialogContent}
                title={message.subject || message.type} // You can dynamically set this based on your message or keep it static
                enableExternalWindow={false} // If you want to enable opening in a new tab
                width={400} // Set initial width
                height={400} // Set initial height
                showCloseWarning={true} // Enable the warning dialog
                closeWarningMessage="⚠ Warning: Unsaved Changes Will Be Lost" // Custom message
            />
        </Box>
    );
};

export default MessageDialog;
