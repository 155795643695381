// GmailClone.tsx
import {
    CreateSharp,
    ExpandLessSharp,
    ExpandMoreSharp,
    LabelSharp,
    MailOutlineSharp,
    MeetingRoomSharp,
    StyleSharp,
    ThumbDownSharp,
    ThumbUpSharp,
} from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import {
    Alert,
    Box,
    Button,
    Checkbox,
    Chip,
    CircularProgress,
    IconButton,
    InputBase,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    MenuItem,
    Select,
    Typography,
    useTheme
} from '@mui/material';
import Collapse from '@mui/material/Collapse';
import { styled } from '@mui/material/styles';
import axios from 'axios';
import { formatDistanceToNow, isValid, parseISO } from 'date-fns';
import React, { useEffect, useMemo, useRef, useState } from 'react';

import AccountSelectorDropdown from './AccountSelectorDropdown';
import EmailIframe from './EmailHTMLIframe';
import EmptyEmailListPlaceholder from './EmptyEmailListPlaceholder';
import LoadingEmailListPlaceholder from './LoadingEmailListPlaceholder';
import { TagsDropdown } from './TagsDropdown';
import EmailActionsToolbar from './EmailActionsToolbar';
import PaginationToolbar from './PaginationToolbar';


interface Tag {
    name: string;
    color: string;
}

interface Email {
    to?: any;
    id: string;
    messageId: string;
    name: string;
    email: string;
    subject: string;
    text: string;
    date: string;
    read: boolean;
    tags: Tag[];
    number1?: string;
    number2?: string;
    type?: string;
    toEmailAccountId: string;
}

interface EmailWithContent extends Email {
    content?: string;
}

interface Account {
    type: string;
    value: string;
    label?: string;
    email?: string;
    emailAccountId?: string; // Add this property
}

const lightPrimaryBg = "transparent";
const lightSecondaryBg = '#F2F2F2';
const darkPrimaryBg = 'transparent';
const darkSecondaryBg = '#353535';

const Container = styled(Box)({
    display: 'flex',
    height: '87vh', // Set height to 100vh to use the full viewport height
});

const Sidebar = styled(Box)(({ theme }) => ({
    width: 256,
    backgroundColor: theme.palette.mode === 'dark' ? darkPrimaryBg : lightPrimaryBg,
    padding: 8,
    overflowY: 'auto',
    transition: 'width 0.3s ease',
    display: 'flex',
    flexDirection: 'column',
    borderTopLeftRadius: '8px',
    borderBottomLeftRadius: '8px',
    '&.collapsed': {
        width: "85px",
        overflowX: "hidden"
    },
}));

const MenuItemStyled = styled(ListItem)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: '8px 24px',
    height: 40, // Fixed height for all list items
    cursor: 'pointer',
    borderRadius: '25px 10px 25px 10px',
    margin: '0 0 1px 0',
    fontSize: 14,
    lineHeight: '1.5', // Ensure the text aligns properly within the fixed height
    transition: 'background-color 0.2s ease',
    '&:hover': {
        backgroundColor: theme.palette.action.hover,
    },
    '&.active': {
        backgroundColor: theme.palette.mode === 'dark' ? '#454545' : '#dcdcdc',
        fontWeight: 'bold',
    },
}));

const ExpandedBox = styled(Box)(({ theme }) => ({
    paddingLeft: '16px',
    paddingRight: '6px',
    paddingTop: '10px',
    paddingBottom: '17px',
    marginTop: '8px',
    backgroundColor: theme.palette.mode === 'dark' ? '#333' : '#f5f5f5',
    borderRadius: '20px',
}));
const MainContent = styled(Box)(({ theme }) => ({
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    backgroundColor: theme.palette.mode === 'dark' ? darkPrimaryBg : lightPrimaryBg,
    padding: '0px',
    borderRadius: '8px',
    margin: '16px',
    height: '100%', // Ensure it uses the full available height
}));

const ContentContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row', // Ensure items are aligned in a row
    backgroundColor: theme.palette.mode === 'dark' ? darkPrimaryBg : lightPrimaryBg,
    padding: '16px',
    width: '100%', // Ensure it uses the full available width
    height: '100%', // Ensure it uses the full available height
}));

const ContentWrapper = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    backgroundColor: theme.palette.mode === 'dark' ? "#2E2E2E" : "#ffffff",
    borderRadius: '16px',
    padding: '16px',
    maxHeight: '80vh', // Limit the height to ensure it doesn't grow too tall
    overflowY: 'auto', // Add scrolling if the content exceeds the max height
}));

const Toolbar = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: 0,
    backgroundColor: 'inherit',
}));

const SearchBar = styled(Box)(({ theme }) => ({
    flex: 1,
    margin: '0 16px',
    position: 'relative',
    backgroundColor: theme.palette.mode === 'dark' ? darkSecondaryBg : lightSecondaryBg,
    borderRadius: '25px 10px 25px 10px',
    padding: '0 12px',
}));

const SearchInput = styled(InputBase)(({ theme }) => ({
    width: '100%',
    padding: '0px 40px 0px 40px', // Added padding-left to prevent overlap
    border: 'none',
    borderRadius: '24px',
    backgroundColor: 'transparent',
    color: theme.palette.mode === 'dark' ? '#e8eaed' : '#3c4043',
    transition: 'box-shadow 0.2s ease',
    '&:focus': {
        outline: 'none',
        boxShadow: theme.palette.mode === 'dark' ? '0 1px 1px rgba(255,255,255,0.1)' : '0 1px 1px rgba(0,0,0,0.1)',
        borderColor: theme.palette.mode === 'dark' ? '#8ab4f8' : '#1a73e8',
    },
    '& .MuiInputBase-input::placeholder': {
        fontFamily: 'Playfair Display, serif',
        fontSize: '18px', // Adjust based on your theme's h5 settings
        fontWeight: 200, // Adjust based on your theme's h5 settings
        color: theme.palette.mode === 'dark' ? '#e8eaed' : '#666666', // Adjust based on your theme's placeholder color
        // textAlign: 'center', // Center the placeholder text
    },
}));

const SearchIconWrapper = styled(Box)(({ theme }) => ({
    position: 'absolute',
    left: 12,
    top: '50%',
    transform: 'translateY(-50%)',
    color: theme.palette.mode === 'dark' ? '#9aa0a6' : '#5f6368',
}));

const ComposeButton = styled(Button)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? darkSecondaryBg : lightSecondaryBg,
    border: 'none',
    borderRadius: '5006px !important',
    cursor: 'pointer',
    fontWeight: 500,
    height: 48,
    padding: '0 24px 0 0',
    marginBottom: 16,
    display: 'flex',
    alignItems: 'center',
    transition: 'box-shadow 0.15s cubic-bezier(0.4,0.0,0.2,1)',
    '&:hover': {
        boxShadow: theme.palette.mode === 'dark'
            ? '0 1px 3px 0 rgba(255,255,255,0.302), 0 4px 8px 3px rgba(255,255,255,0.149)'
            : '0 1px 3px 0 rgba(60,64,67,0.302), 0 4px 8px 3px rgba(60,64,67,0.149)',
    },
    '.compose-text': {
        fontFamily: 'Playfair Display, serif',
        fontSize: '20px', // Adjust based on your theme's h5 settings
        fontWeight: 400, // Adjust based on your theme's h5 settings
        marginLeft: '8px', // Adjust the spacing as needed
    },
    '&.collapsed': {
        justifyContent: 'center',
        padding: 0,
        width: '48px', // Adjust width for collapsed state
    }
}));





const LabelItemStyled = styled(MenuItemStyled)(({ theme, color }) => ({
    position: 'relative',
    // paddingLeft: '40px',
    height: 40, // Fixed height for all list items
    // '&::before': {
    //     content: '""',
    //     position: 'absolute',
    //     left: '16px',
    //     top: '50%',
    //     transform: 'translateY(-50%)',
    //     width: '12px',
    //     height: '12px',
    //     backgroundColor: color,
    //     borderRadius: '50%',
    // },
}));



const EmailItem = styled(ListItem)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: '0px 16px',
    borderBottom: `1px solid ${theme.palette.mode === 'dark' ? darkSecondaryBg : '#e0e0e0'}`,
    cursor: 'pointer',
    transition: 'background-color 0.2s ease, box-shadow 0.15s cubic-bezier(0.4,0.0,0.2,1)',
    '&:hover': {
        backgroundColor: theme.palette.mode === 'dark' ? darkSecondaryBg : lightSecondaryBg,
        boxShadow: theme.palette.mode === 'dark'
            ? '0 1px 2px 0 rgba(255,255,255,0.1), 0 1px 3px 1px rgba(255,255,255,0.1)'
            : '0 1px 2px 0 rgba(0,0,0,0.1), 0 1px 3px 1px rgba(0,0,0,0.1)',
    },
    '&.unread': {
        backgroundColor: theme.palette.mode === 'dark' ? darkPrimaryBg : lightSecondaryBg,
        fontWeight: 500,
    },
    '& .MuiListItemText-primary': {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
    },
    '& .MuiListItemText-secondary': {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        marginTop: '4px',
        color: theme.palette.mode === 'dark' ? '#9aa0a6' : '#5f6368',
    },
}));

const EmailLabels = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexWrap: 'wrap',
    gap: 4,
    marginLeft: 8,
}));

const EmailList = styled(List)(({ theme }) => ({
    padding: 0,
    margin: 0,
    overflowY: 'auto',
    flex: 1,
    backgroundColor: theme.palette.mode === 'dark' ? '#2e2e2e' : "#fff", // Adjust based on theme mode
    borderRadius: '16px',
}));




const ComposeModal = styled(Box)<{ open: boolean }>(({ theme, open }) => ({
    position: 'fixed',
    bottom: 0,
    right: 20,
    width: 560,
    height: 500,
    backgroundColor: theme.palette.mode === 'dark' ? '#1e1e1e' : 'white',
    boxShadow: theme.palette.mode === 'dark' ? '0 -1px 5px rgba(255, 255, 255, 0.2)' : '0 -1px 5px rgba(0, 0, 0, 0.2)',
    borderRadius: '30px 8px 0 0',
    display: 'flex', // Always use flex
    flexDirection: 'column',
    zIndex: 1001,
    visibility: open ? 'visible' : 'hidden', // Use visibility to control appearance
}));

const ComposeField = styled(Box)({
    marginBottom: 16,
});

const ComposeActions = styled(Box)(({ theme }) => ({
    padding: 16,
    display: 'flex',
    justifyContent: 'space-between',
    borderTop: `1px solid ${theme.palette.mode === 'dark' ? '#3c4043' : '#dadce0'}`,
    backgroundColor: theme.palette.mode === 'dark' ? '#1e1e1e' : 'white', // Ensure it matches the modal
}));


const GmailClone: React.FC = () => {
    const [sidebarCollapsed, setSidebarCollapsed] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [previewEmail, setPreviewEmail] = useState<EmailWithContent | null>(null);
    const [composeOpen, setComposeOpen] = useState(false);
    const [emails, setEmails] = useState<Email[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const [accounts, setAccounts] = useState<{ label: string, emailAccountId: string, icon: React.ReactNode, type: string, email: string }[]>([]);
    const [selectedAccount, setSelectedAccount] = useState<Account>({ type: 'all', value: 'all', label: 'All Accounts', email: 'All Accounts' });
    const [filteredEmails, setFilteredEmails] = useState<Email[]>([]);
    const [isEmailLoading, setIsEmailLoading] = useState(false);
    const [emailContentError, setEmailContentError] = useState<string | null>(null);
    const [open, setOpen] = useState(false);
    const [selectedFilter, setSelectedFilter] = useState<string>('all'); // default to 'all'

    const [currentPage, setCurrentPage] = useState(1);
    const [emailsPerPage, setEmailsPerPage] = useState(20); // Make emailsPerPage a state variable

    const totalPages = Math.ceil(filteredEmails.length / emailsPerPage);
    const totalItems = filteredEmails.length;

    const indexOfLastEmail = currentPage * emailsPerPage;
    const indexOfFirstEmail = indexOfLastEmail - emailsPerPage;
    const currentEmails = filteredEmails.slice(indexOfFirstEmail, indexOfLastEmail);

    useEffect(() => {
        const fetchAccounts = async () => {
            try {
                const accountsResponse = await axios.get(`${process.env.REACT_APP_API_URL}/inbox/accounts`);
                let updatedAccounts = accountsResponse.data.map(account => ({
                    ...account,
                    type: 'email',
                    icon: account.icon
                }));
                setAccounts(updatedAccounts);
                setSelectedAccount({ type: 'all', value: 'all' });
            } catch (error) {
                console.error('Error fetching accounts:', error);
            }
        };

        fetchAccounts();
        fetchEmailsForAccount('all', 'all');
    }, []);

    const fetchEmailsForAccount = async (accountType: string, accountId: string) => {
        setIsLoading(true);
        setIsError(false);
        try {
            let url = `${process.env.REACT_APP_API_URL}/inbox/emails`;
            if (accountType === 'email') {
                url = `${process.env.REACT_APP_API_URL}/inbox/emails/${accountId}`;
            }
            const response = await axios.get<Email[]>(url);
            setEmails(response.data);
            setFilteredEmails(response.data);
            setIsLoading(false);
        } catch (error) {
            console.error('Error fetching emails:', error);
            setIsError(true);
            setIsLoading(false);
        }
    };

    const handleClick = () => {
        setOpen(!open);
    };

    const handleSelectFilter = (filter: string) => () => {
        setSelectedFilter(filter);
        filterEmails(selectedAccount.type, selectedAccount.value, filter);
    };





    const handleAccountChange = async (account: Account) => {
        const selectedValue = account.value;

        let accountType = "all";
        let accountId = "all";

        if (selectedValue !== "all") {
            accountType = account.type;
            accountId = account.emailAccountId!;
        }

        setSelectedAccount({ type: accountType, value: accountId, label: account.label, email: account.email });
        await fetchEmailsForAccount(accountType, accountId);
    };

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(e.target.value.toLowerCase());
        filterEmails(selectedAccount.type, selectedAccount.value, selectedFilter);
    };


    const filterEmails = (accountType: string, accountId: string, filter: string = selectedFilter) => {
        const filtered = emails.filter((email: Email) =>
            (accountType === 'all' || email.toEmailAccountId === accountId) &&
            (filter === 'all' || email.tags.some(tag => tag.name === filter)) &&
            (
                email.subject.toLowerCase().includes(searchTerm) ||
                email.name.toLowerCase().includes(searchTerm) ||
                email.text.toLowerCase().includes(searchTerm)
            )
        );
        setFilteredEmails(filtered);
    };



    const openPreview = async (email: Email) => {
        setPreviewEmail(email);
        email.read = true;
        setIsEmailLoading(true);
        setEmailContentError(null);

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/inbox/email-thread`, {
                emailAccountId: email.toEmailAccountId, // Replace with actual email account field
                messageId: email.messageId, // Replace with actual message ID field
            });
            setPreviewEmail({ ...email, content: response.data.html });
        } catch (error) {
            console.error('Failed to fetch email content:', error);
            setEmailContentError('Failed to fetch email content');
        } finally {
            setIsEmailLoading(false);
        }
    };


    const toggleComposeModal = () => {
        setComposeOpen((prevOpen) => !prevOpen);
    };

    function isLight(color: string): boolean {
        const hex = color.replace('#', '');
        const r = parseInt(hex.substr(0, 2), 16);
        const g = parseInt(hex.substr(2, 2), 16);
        const b = parseInt(hex.substr(4, 2), 16);
        const yiq = (r * 299 + g * 587 + b * 114) / 1000;
        return yiq >= 128;
    }


    const renderEmails = (emailsToRender: Email[] = currentEmails) => {


        if (isLoading) {
            return (

                <LoadingEmailListPlaceholder />

            );
        }

        if (isError) {
            return (
                <Box display="flex" justifyContent="center" alignItems="center" height="100%">

                    <Alert severity="error" style={{ width: '100%' }}>
                        Error loading emails. Please try again.
                    </Alert>
                </Box>

            );
        }

        if (emailsToRender.length === 0) {
            return (
                // <Box display="flex" justifyContent="center" alignItems="center" height="100%">

                //     <Typography variant="body1" style={{ padding: '16px', textAlign: 'center' }}>
                //         No emails found.
                //     </Typography>
                // </Box>
                < EmptyEmailListPlaceholder></EmptyEmailListPlaceholder>
            );
        }

        return emailsToRender.map((email: Email) => (
            <EmailItem
                key={email.id}
                className={!email.read ? 'unread' : ''}
                onClick={() => openPreview(email)}
            >
                <ListItemIcon onClick={(e) => e.stopPropagation()}>
                    <Checkbox />
                </ListItemIcon>
                <ListItemIcon onClick={(e) => e.stopPropagation()}>
                    <IconButton>
                        {email.tags.some(tag => tag.name === 'interested') ? (
                            <ThumbUpSharp style={{ color: '#90EE90' }} />
                        ) : email.tags.some(tag => tag.name === 'not interested') ? (
                            <ThumbDownSharp style={{ color: '#FCB900' }} />
                        ) : (
                            <></>
                        )}
                    </IconButton>
                </ListItemIcon>

                <ListItemText
                    primary={
                        <>
                            <Typography component="span" variant="body2" noWrap style={{ width: '200px' }}>
                                {email.name}
                            </Typography>
                            <Typography component="span" variant="body2" noWrap style={{ flex: 1, marginLeft: '8px' }}>
                                <strong>{email.subject}</strong> - {email.text ? email.text.substring(0, 50) : ''}...
                            </Typography>
                            <EmailLabels>
                                {email.tags.map((tag: Tag) => (
                                    <Chip
                                        key={tag.name}
                                        label={tag.name}
                                        size='small'
                                        style={{
                                            backgroundColor: tag.color,
                                            color: isLight(tag.color) ? 'black' : 'white',
                                        }}
                                    />
                                ))}
                            </EmailLabels>
                            <Typography component="span" variant="body2" noWrap style={{ width: '100px', textAlign: 'right' }}>
                                {
                                    email.date && isValid(parseISO(email.date))
                                        ? formatDistanceToNow(parseISO(email.date), { addSuffix: true })
                                        : 'Date unknown'
                                }
                            </Typography>
                        </>
                    }
                />
            </EmailItem>
        ));
    };



    const PaginationControls = styled(Box)(({ theme }) => ({
        display: 'flex',
        justifyContent: 'center',
        padding: '10px 0px',
    }));

    const handleNextPage = () => {
        if (currentPage < Math.ceil(filteredEmails.length / emailsPerPage)) {
            setCurrentPage(prevPage => prevPage + 1);
        }
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(prevPage => prevPage - 1);
        }
    };


    const uniqueTags = useMemo(() => {
        const filteredEmailsByAccount = selectedAccount.value === 'all'
            ? emails
            : emails.filter(email => email.toEmailAccountId === selectedAccount.value);

        const tags = filteredEmailsByAccount.flatMap(email => email.tags || []);
        const unique = Array.from(new Set(tags.map(tag => tag.name))).map(name => {
            return {
                name,
                color: tags.find(tag => tag.name === name)?.color,
            };
        });
        return unique;
    }, [emails, selectedAccount]);



    const filterByLabel = (labelName: string) => {
        setSearchTerm(labelName);
        filterEmails(selectedAccount.type, selectedAccount.value);
    };

    return (
        <Container>
            <ContentContainer>
                <Sidebar className={sidebarCollapsed ? 'collapsed' : ''}>

                    <AccountSelectorDropdown
                        accounts={accounts}
                        selectedAccount={selectedAccount}
                        handleAccountChange={handleAccountChange}

                    />

                    <ComposeButton color='info' onClick={toggleComposeModal} className={sidebarCollapsed ? 'collapsed' : ''}>
                        <CreateSharp />
                        {!sidebarCollapsed && <span className="compose-text">Compose</span>}
                    </ComposeButton>


                    {[
                        { filter: "all", text: "All Replies", icon: <MailOutlineSharp color="primary" /> },
                        { filter: "booked", text: "Meetings", icon: <MeetingRoomSharp color="success" /> },
                        { filter: "interested", text: "Interested", icon: <ThumbUpSharp color="secondary" /> },
                        { filter: "not interested", text: "Not Interested", icon: <ThumbDownSharp color="error" /> },
                    ].map(item => (
                        <MenuItemStyled
                            onClick={handleSelectFilter(item.filter)}
                            selected={selectedFilter === item.filter}
                            className={selectedFilter === item.filter ? 'active' : ''}
                            key={item.filter}
                        >
                            <ListItemIcon>
                                {item.icon}
                            </ListItemIcon>
                            <ListItemText
                                primaryTypographyProps={{
                                    component: 'h5',
                                    variant: 'h5',
                                    style: {
                                        fontFamily: 'Playfair Display, serif',
                                    },
                                }}
                                primary={item.text}
                            />
                        </MenuItemStyled>
                    ))}

                    <MenuItemStyled onClick={handleClick}>
                        <ListItemIcon>
                            <StyleSharp color='secondary' />
                        </ListItemIcon>
                        <ListItemText
                            primaryTypographyProps={{
                                component: 'h5',
                                variant: 'h5',
                                style: {
                                    fontFamily: 'Playfair Display, serif',
                                },
                            }}
                            primary="Other"
                        />
                        {open ? <ExpandLessSharp /> : <ExpandMoreSharp />}
                    </MenuItemStyled>

                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <ExpandedBox>

                            <List component="div" disablePadding>
                                {uniqueTags.map((tag) => (
                                    <LabelItemStyled
                                        onClick={handleSelectFilter(tag.name)}
                                        key={tag.name}
                                        selected={selectedFilter === tag.name}
                                        color={tag.color}
                                        className={selectedFilter === tag.name ? 'active' : ''}
                                    >
                                        <ListItemIcon>
                                            <LabelSharp style={{ color: tag.color }} />
                                        </ListItemIcon>
                                        <ListItemText
                                            primaryTypographyProps={{
                                                component: 'h5',
                                                variant: 'h5',
                                                style: {
                                                    fontFamily: 'Playfair Display, serif',
                                                },
                                            }}
                                            primary={tag.name}
                                        />
                                    </LabelItemStyled>
                                ))}
                            </List>
                        </ExpandedBox>

                    </Collapse>
                </Sidebar>






                <MainContent>
                    <Toolbar>
                        <IconButton onClick={() => setSidebarCollapsed(!sidebarCollapsed)}>
                            <MenuIcon />
                        </IconButton>
                        <SearchBar>
                            <SearchIconWrapper>
                                <SearchIcon />
                            </SearchIconWrapper>
                            <SearchInput placeholder="Search mail" value={searchTerm} onChange={handleSearch} />
                        </SearchBar>
                    </Toolbar>
                    {previewEmail ? (
                        <>
                            <PaginationControls>

                                <EmailActionsToolbar
                                    onBack={() => setPreviewEmail(null)}
                                    onArchive={function (): void {
                                        throw new Error('Function not implemented.');
                                    }}
                                    onDelete={function (): void {
                                        throw new Error('Function not implemented.');
                                    }}
                                    onMarkUnread={function (): void {
                                        throw new Error('Function not implemented.');
                                    }}
                                    onSnooze={function (): void {
                                        throw new Error('Function not implemented.');
                                    }}
                                    onLabel={function (): void {
                                        throw new Error('Function not implemented.');
                                    }}
                                    onReply={function (): void {
                                        throw new Error('Function not implemented.');
                                    }}
                                    onForward={function (): void {
                                        throw new Error('Function not implemented.');
                                    }}
                                />

                            </PaginationControls>
                            <ContentWrapper>
                                <Typography variant="h5" className="preview-subject">
                                    {previewEmail.subject}
                                </Typography>
                                <Box display="flex" justifyContent="space-between" className="preview-details" mb={2}>
                                    <Typography variant="subtitle1" className="preview-sender">
                                        From: {previewEmail.name} &lt;{accounts.find(account => account.emailAccountId === previewEmail.email)?.email}&gt;
                                    </Typography>
                                    <Typography variant="subtitle2" className="preview-date">
                                        {previewEmail.date}
                                    </Typography>
                                </Box>
                                <Box display="flex" justifyContent="space-between" alignItems="center">
                                    <TagsDropdown messageId={previewEmail.messageId} existingTags={previewEmail.tags} selectedAccount={selectedAccount.value} />
                                </Box>
                                <Box p={2} overflow="auto" className="preview-body">
                                    {isEmailLoading ? (
                                        <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                                            <CircularProgress />
                                            <Typography ml={2}>Connecting...</Typography>
                                        </Box>
                                    ) : emailContentError ? (
                                        <Alert severity="error">{emailContentError}</Alert>
                                    ) : previewEmail.content ? (
                                        <EmailIframe html={previewEmail.content} />
                                    ) : (
                                        <Typography>No content found</Typography>
                                    )}
                                </Box>
                            </ContentWrapper>

                        </>
                    ) : (
                        <>
                            <PaginationControls>

                                <PaginationToolbar
                                    onPrevious={handlePreviousPage}
                                    onNext={handleNextPage}
                                    onFirstPage={() => setCurrentPage(1)}
                                    onLastPage={() => setCurrentPage(totalPages)}
                                    onSettings={() => { /* Implement settings functionality */ }}
                                    currentPage={currentPage}
                                    totalPages={totalPages}
                                    perPage={emailsPerPage}
                                    totalItems={totalItems}
                                    onChangePerPage={(newPerPage) => {
                                        setEmailsPerPage(newPerPage);
                                        setCurrentPage(1); // Reset to page 1 when perPage changes
                                    }}
                                />

                            </PaginationControls>
                            <EmailList>{renderEmails(currentEmails)}</EmailList>

                        </>
                    )}



                </MainContent>
            </ContentContainer>

            <ComposeModal className={composeOpen ? 'open' : ''} open={composeOpen}>
                <Box p={2} color="white" display="flex" justifyContent="space-between" alignItems="center">
                    <Typography>New Message</Typography>
                    <IconButton onClick={toggleComposeModal}>
                        <CloseIcon />
                    </IconButton>
                </Box>
                <Box p={2} flex={1} display="flex" flexDirection="column" overflow="auto">
                    <ComposeField>
                        <Select defaultValue="personal" fullWidth id="compose-from">
                            {accounts.map((account: any) => (
                                <MenuItem key={account.emailAccountId} value={account.emailAccountId}>
                                    {account.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </ComposeField>
                    <ComposeField>
                        <InputBase placeholder="To" fullWidth id="compose-to" />
                    </ComposeField>
                    <ComposeField>
                        <InputBase placeholder="Subject" fullWidth id="compose-subject" />
                    </ComposeField>
                    <ComposeField flex={1} display="flex">
                        <InputBase placeholder="Compose your message..." multiline rows={8} fullWidth id="compose-message" />
                    </ComposeField>
                </Box>
                <ComposeActions className="compose-actions">
                    <Button variant="contained" color="primary" className="send-btn" id="send-btn">
                        Send
                    </Button>
                    <Button variant="contained" className="discard-btn" id="discard-btn">
                        Discard
                    </Button>
                </ComposeActions>
            </ComposeModal>
        </Container >
    );
};

export default GmailClone;
